import { useMemo } from 'react';
import { User, UserItem } from './user-item';

export type UsersListProps = {
  users: User[];
};

export const UsersList: React.FC<UsersListProps> = ({ users }) => {
  const sortedList = useMemo(() => {
    return users?.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      return nameA < nameB ? -1 : 1;
    });
  }, [users]);
  return (
    <div
      data-cy="users-list"
      className="grid grid-cols-1 space-y-4 md:grid-cols-2 lg:grid-cols-1"
    >
      {sortedList?.map((user) => (
        <UserItem
          key={user.id}
          user={user}
        />
      ))}
    </div>
  );
};
