import { ReactNode } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { Active, UniqueIdentifier } from '@dnd-kit/core';

export type DragItemProps = {
  setActivatorNodeRef: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
  dragActive: Active | null;
};

export type DragSortableItemProps = {
  id: UniqueIdentifier;
  children: (props: DragItemProps) => ReactNode;
};

export const DragSortableItem: React.FC<DragSortableItemProps> = ({ children, id }) => {
  const { attributes, listeners, setNodeRef, transform, transition, active, setActivatorNodeRef } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <li
      {...attributes}
      ref={setNodeRef}
      style={style}
      className="cursor-default"
      tabIndex={-1}
    >
      {children({ setActivatorNodeRef, listeners, dragActive: active })}
    </li>
  );
};
