import { DataCy } from '@stellar-lms-frontend/common-utils';
import { Badge, ChevronRightIcon, IconItem } from '@stellar-lms-frontend/ui-components';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { useWBAStatus } from '../../../hooks/use-wba-status';
import { useDueDate } from '../../../hooks/use-due-date';
import { useNavigate, useParams } from 'react-router-dom';
import { MENTOR_ACTIONS_BASE_ROUTE } from '../../../constants/routes';
import { useTranslation } from 'react-i18next';
import {
  LearnerWorkBasedAction,
  searchUsersLightById,
  USERS_LIGHT,
} from '@stellar-lms-frontend/lms-api';
import { useCurrentCompany } from '@stellar-lms-frontend/lms-graphql';
import { graphQLClient } from '../../../lib/graphql';

export type WBAItemProps = DataCy & {
  wba: LearnerWorkBasedAction;
};

export const WBAItem: React.FC<WBAItemProps> = ({ wba, 'data-cy': dataCy }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'mentees-overview' });
  const { courseId = '' } = useParams();
  const {
    query: { data: company },
  } = useCurrentCompany(graphQLClient('learner'));
  const userQuery = useQuery(
    [USERS_LIGHT, wba.userId],
    () => (company ? searchUsersLightById(graphQLClient(), company.id, [wba.userId || '']) : []),
    {
      select: (data) => data.at(0),
    }
  );
  const getDue = useDueDate();
  const navigate = useNavigate();

  const { label: badgeLabel, color: badgeColor } = useWBAStatus(wba);

  const badge = useMemo(() => {
    if (badgeColor === 'red') {
      return (
        <Badge
          color={badgeColor}
          className="capitalize"
        >
          {t('overdue')}
        </Badge>
      );
    }

    if (badgeColor === 'purple') {
      return (
        <Badge
          color={badgeColor}
          className="capitalize"
        >
          {badgeLabel}
        </Badge>
      );
    }

    return <p>{wba.learnerDueDate ? getDue(wba.learnerDueDate).label : ''}</p>;
  }, [badgeColor, badgeLabel, getDue, wba.learnerDueDate, t]);

  if (!userQuery.data) return null;

  return (
    <IconItem
      onClick={() => navigate(`${MENTOR_ACTIONS_BASE_ROUTE(courseId)}/${wba.id}`)}
      className="border-border-01 text-text-01 border-b"
      icon={<ChevronRightIcon className="fill-text-03" />}
      title={wba.title}
      subtitleList={[badge, userQuery.data?.name]}
      data-cy={dataCy}
    />
  );
};
