import { faFileLines, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import { ToolTip } from '@stellar-lms-frontend/ui-components';

export const RelatedStepItem = ({
  title,
  description,
  action,
}: {
  title: string;
  description: string;
  action: VoidFunc;
}) => (
  <ToolTip
    isEnabled
    placement="right"
    color="dark"
    size="small"
    wrappedComponent={
      <div
        className="hover:bg-surface-02-hover group -ml-2 -mr-2 flex cursor-pointer items-center rounded-xl p-2"
        onKeyDown={action}
        onClick={action}
      >
        <FontAwesomeIcon
          icon={faFileLines}
          className="group-hover:text-primary-01 mr-2 shrink-0 grow-0 text-xl"
        />
        <span className="type-body-medium text-text-01 line-clamp-3 group-hover:text-primary-01 mr-2 grow">
          {title}
        </span>
        <FontAwesomeIcon
          icon={faPlus}
          className="text-text-02 group-hover:text-primary-01 shrink-0 grow-0 px-2 text-xl"
        />
      </div>
    }
  >
    <span className="max-w-xs whitespace-normal">{description}</span>
  </ToolTip>
);
