import { AutoResizeTextArea, EditableSelect, Input, Radio, Switch } from '../form';
import {
  QuizMultipleChoiceAnswerSetting,
  QuizMultipleChoiceFormErrors,
  QuizMultipleChoiceSettings,
} from './quiz-multiple-choice-settings-card';
import { useTranslation } from 'react-i18next';

type RadioOptions = 'multiple' | 'single';

export type QuizMultipleChoiceSettingsFormI18N = {
  form: {
    titlePlaceholder: string;
    titleLabel: string;
    selectionTypeLabel: string;
    selectionMultipleChoiceLabel: string;
    selectionSingleChoiceLabel: string;
    addAnswerLabel: string;
    feedbackLabel: string;
    spacedRepetitionEnabledLabel: string;
  };
};

export type QuizMultipleChoiceSettingsFormProps = {
  onChange: (value: QuizMultipleChoiceSettings) => void;
  value: QuizMultipleChoiceSettings;
  errors?: QuizMultipleChoiceFormErrors;
};
export const QuizMultipleChoiceSettingsForm: React.FC<QuizMultipleChoiceSettingsFormProps> = ({
  value,
  onChange,
  errors,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'assessment-step-view.question-types.choice',
  });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const updateTitleValue = (text: string) => onChange({ ...value, text });
  const updateFeedbackValue = (feedback: string) => onChange({ ...value, feedback });
  const updateRadioValue = (newValue: RadioOptions) =>
    onChange({ ...value, multiple: newValue === 'multiple' });

  const updateAnswers = (newOptions: QuizMultipleChoiceAnswerSetting[]) =>
    onChange({ ...value, answers: newOptions });

  const updateSpacedRepetitionEnabled = (spacedRepetitionEnabled: boolean) =>
    onChange({ ...value, spacedRepetitionEnabled });

  return (
    <div className="text-text-02 space-y-4">
      <Input
        required
        htmlId="fitb-blank-text-input"
        label={t('title')}
        onChange={(event) => updateTitleValue(event.target.value)}
        text={value.text}
        placeholder={t('title-placeholder')}
        error={errors?.text?.message}
      />
      <AutoResizeTextArea
        required
        minRows={1}
        maxRows={3}
        id="fitb-blank-text-input"
        label={tGeneral('feedback')}
        placeholder={tGeneral('feedback')}
        onChange={(event) => updateFeedbackValue(event.target.value)}
        value={value.feedback}
        error={errors?.feedback?.message}
      />
      <Radio
        legend={t('selection')}
        onChange={(newValue) => updateRadioValue(newValue as RadioOptions)}
        value={value.multiple ? 'multiple' : 'single'}
        options={[
          { label: t('multiple-select'), value: 'multiple' },
          { label: t('single-select'), value: 'single' },
        ]}
        uniqueId="question-multiline-option"
      />
      <div className={'-mr-12'}>
        <EditableSelect
          options={value.answers}
          placeholder={t('answer-placeholder')}
          onChange={(newAnswers) =>
            updateAnswers(newAnswers.map((a) => ({ ...a, correct: !!a.correct })))
          }
          showCorrectAnswerSelectors
          isMultiSelect={value.multiple}
          ctaLabel={t('add-answer')}
        />
      </div>
      <div className="flex items-center space-x-4">
        <Switch
          label={t('spaced-repetition-enabled')}
          value={value.spacedRepetitionEnabled}
          onChange={(checked) => updateSpacedRepetitionEnabled(checked)}
        />
      </div>
    </div>
  );
};
