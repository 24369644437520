import { ReactNode, useRef, useState } from 'react';

export type ScrollContainerProps = {
  children: ReactNode;
  scrollOnDesktop?: boolean;
  addPadding?: boolean;
  htmlId?: string;
  isFullHeight?: boolean;
};

export const ScrollContainer = ({
  htmlId = 'scroll-container',
  children,
  scrollOnDesktop = false,
  addPadding = true,
  isFullHeight = false,
}: ScrollContainerProps) => {
  const [hasScroll, setHasScroll] = useState<boolean>(false);
  const scrollContainerRef = useRef<HTMLDivElement>();

  const resizeListener = () => {
    if (scrollContainerRef.current) {
      const element = scrollContainerRef.current;
      setHasScroll(element.offsetHeight < element.scrollHeight);
    }
  };

  const registerContainerRef = (ref: HTMLDivElement) => {
    if (ref) {
      scrollContainerRef.current = ref;
    }
  };

  const registerContentRef = (ref: HTMLDivElement) => {
    if (ref) {
      resizeListener();
    }
  };

  return (
    <div className="relative h-full w-full overflow-hidden">
      <div
        id={htmlId}
        ref={registerContainerRef}
        className={`scrollbar-hide relative h-full w-full overflow-y-scroll ${
          scrollOnDesktop ? '' : 'lg:overflow-hidden'
        }`}
      >
        <div
          ref={registerContentRef}
          className={`
          ${addPadding ? 'py-8 lg:p-0' : ''}
          ${isFullHeight ? 'h-full' : ''}`}
        >
          {children}
        </div>
      </div>
      {hasScroll && (
        <div className="bg-shadow-gradient absolute -bottom-2 h-4 w-full opacity-10"></div>
      )}
    </div>
  );
};
