import { useContext, useEffect } from 'react';
import { LearningActivityContext } from '../context/learning-activity-context';

type UseLearningActivityStateArgs = {
  isDirty: boolean;
  isValid: boolean;
};

export const useLearningActivityState = ({ isDirty, isValid }: UseLearningActivityStateArgs) => {
  const { setIsDirty, setIsValid } = useContext(LearningActivityContext);

  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty, setIsDirty]);

  useEffect(() => {
    setIsValid?.(isValid);
  }, [isValid, setIsValid]);
};
