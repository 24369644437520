import { useEffect } from 'react';

export const useEventListener = <T extends keyof WindowEventMap>(
  eventName: T,
  handler: (event: WindowEventMap[T]) => void,
  enabled: boolean
) => {
  useEffect(() => {
    const listener = (event: WindowEventMap[T]) => handler(event);
    if (enabled) {
      window.addEventListener(eventName, listener);
    }
    return () => {
      if (enabled) {
        window.removeEventListener(eventName, listener);
      }
    };
  }, [eventName, handler, enabled]);
};

export default useEventListener;
