import React from 'react';
import { GraphQLClient } from 'graphql-request';
import { Route, Routes } from 'react-router-dom';
import { Sidebar } from './components/sidebar';
import { CompaniesTab } from './components/companies-tab/companies-tab';
import { SupportedLanguagesOptions } from '../types/types';

export type AdministrationPageProps = {
  graphQLClient: GraphQLClient;
  defaultLanguage: string;
  supportedLanguages: SupportedLanguagesOptions;
};

export const AdministrationPage: React.FC<AdministrationPageProps> = ({
  graphQLClient,
  defaultLanguage,
  supportedLanguages,
}) => {
  return (
    <div className="grid w-full grid-cols-[424px,2fr]">
      <Sidebar />
      <Routes>
        <Route
          path=""
          element={
            <CompaniesTab
              graphQLClient={graphQLClient}
              defaultLanguage={defaultLanguage}
              supportedLanguages={supportedLanguages}
            />
          }
        />
      </Routes>
    </div>
  );
};
