import { ReactNode, MouseEvent, ForwardedRef, forwardRef } from 'react';

export type BadgeColors =
  | 'red'
  | 'green'
  | 'grey'
  | 'purple'
  | 'outline-grey'
  | 'dark-purple'
  | 'yellow'
  | 'dark-blue'
  | 'light-blue';

export type BadgeProps = {
  color?: BadgeColors;
  children: ReactNode;
  size?: 'normal' | 'lg' | 'full-width' | 'xl';
  className?: string;
  isActive?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
};
export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  (
    { color = 'grey', children, size = 'normal', className = '', isActive, onClick },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const colorClasses = {
      red: 'bg-red-100 text-red-800 border-red-100',
      yellow: 'bg-yellow-100 text-yellow-800 border-yellow-100',
      green: 'bg-green-100 text-green-800 border-green-100',
      grey: 'bg-gray-100 text-gray-800 border-gray-100',
      purple: 'bg-purple-100 text-purple-800 border-purple-100',
      'dark-purple': 'bg-purple-200 text-purple-900 border-purple-200',
      'outline-grey': isActive
        ? 'bg-surface-02-hover border-border-03 text-text-04'
        : 'bg-surface-01 border-border-02 text-text-01',
      'dark-blue': 'bg-blue-800 text-primary-03 hover:text-surface-01 border-blue-800',
      'light-blue':
        'bg-blue-100 text-primary-01 hover:text-blue-700 hover:bg-blue-200 border-blue-100',
    };

    const interactiveClasses = {
      yellow:
        'cursor-pointer hover:bg-yellow-200 hover:text-yellow-900 active:bg-yellow-50 active:text-yellow-800 focus:bg-yellow-100 focus:text-yellow-800  focus:border-primary-01 focus:outline focus:outline-2 focus:outline-white',
      red: '',
      green: '',
      grey: '',
      'dark-purple': '',
      purple: '',
      'outline-grey': '',
      'dark-blue': '',
      'light-blue': '',
    };

    const sizeClasses = {
      normal: 'py-1 px-3 text-xs',
      lg: 'py-1 px-3 text-sm',
      xl: 'h-[36px] px-4',
      'full-width': 'w-full py-2 text-sm',
    };

    const baseClass = `font-lexend font-regular inline-block whitespace-nowrap rounded-full text-center leading-normal border-1`;

    return (
      <div
        ref={ref}
        role={onClick ? 'button' : undefined}
        onClick={onClick}
        className={`${baseClass} ${colorClasses[color]} ${sizeClasses[size]} ${className} ${
          onClick ? interactiveClasses[color] : ''
        }`}
      >
        {children}
      </div>
    );
  }
);

Badge.displayName = 'Badge';
