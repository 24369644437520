import KeyBoardShortcut from '../../../keyboard-shortcut/keyboard-shortcut';
import TextArea from '../../text-area/text-area';
import { SelectCheckBox } from '../edit-select/components/select-checkbox';
import { SelectOptionType } from '../types';
import { SelectBox } from '../components/select-box/select-box';
import { useTranslation } from 'react-i18next';

export type SingleSelectProps = {
  options: SelectOptionType[];
  showCorrectAnswerSelectors?: boolean;
  theme?: 'blue' | 'normal' | 'dark';
} & (
  | {
      isDisabled?: false;
      onChange: (selectedValue: SelectOptionType | null) => void;
      value: SelectOptionType | null;
    }
  | {
      isDisabled: true;
      onChange?: never;
      value?: never;
    }
);

export const SingleSelect: React.FC<SingleSelectProps> = ({
  options,
  onChange,
  value,
  isDisabled,
  theme,
  showCorrectAnswerSelectors,
}) => {
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const onChangeToggle = isDisabled
    ? undefined
    : (option: SelectOptionType) => {
        value?.id === option.id ? onChange(null) : onChange({ ...option, userInput: option.label });
      };

  const renderInputForOther = (option: SelectOptionType) => (
    <div className="space-y-2">
      <TextArea
        label={tGeneral('other')}
        htmlId="input-other"
        onChange={({ target: { value } }) => onChange?.({ ...option, userInput: value })}
        initialText={value?.userInput}
        onKeyUp={(e) => e.stopPropagation()}
      />
    </div>
  );

  const renderInput = (option: SelectOptionType, index: number) => (
    <div className="flex flex-row">
      <input
        name={`option-${index}`}
        type="radio"
        className="hidden"
        checked={option.id === value?.id}
        readOnly
      />
      <label
        htmlFor={`option-${index}`}
        className={`mr-4 grow select-none whitespace-pre-wrap ${
          isDisabled ? 'cursor-[inherit]' : 'cursor-pointer'
        }`}
      >
        {option.other ? tGeneral('other') : option.label}
      </label>

      {onChangeToggle && (
        <KeyBoardShortcut
          className="mr-4 shrink-0"
          index={index}
          action={(shouldTrigger) => shouldTrigger && onChangeToggle(option)}
        />
      )}
    </div>
  );

  const renderSelectOptionBox = (option: SelectOptionType, index: number) => {
    const isSelected = value?.id === option.id;

    return (
      <div
        className={`flex w-full items-center gap-4  ${isDisabled ? '' : 'cursor-pointer'}`}
        onClick={() => onChangeToggle?.(option)}
      >
        {isSelected ? (
          <SelectBox
            type="selected"
            data-cy={`single-select-option-selected-${index}`}
          >
            {renderInput(option, index)}
          </SelectBox>
        ) : (
          <SelectBox
            type={theme === 'dark' ? 'neutral-dark' : theme === 'blue' ? 'blue' : 'neutral-white'}
            data-cy={`single-select-option-neutral-${index}`}
          >
            {renderInput(option, index)}
          </SelectBox>
        )}
        {showCorrectAnswerSelectors && (
          <SelectCheckBox
            theme={theme === 'dark' ? 'grey' : theme === 'blue' ? 'blue' : 'colored'}
            isDisabled={isDisabled}
            checked={option.correct ?? false}
            hasAtLeastOneChecked={options.filter((o) => o.correct).length > 0}
            isMultiSelect={false}
          />
        )}
      </div>
    );
  };

  return (
    <ul className="flex flex-col space-y-4">
      {options.map((option, index) => (
        <li
          key={`option-${index}`}
          className="space-y-4"
        >
          {renderSelectOptionBox(option, index)}
          {option.other && value?.other && renderInputForOther(option)}
        </li>
      ))}
    </ul>
  );
};

export default SingleSelect;
