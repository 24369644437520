import { Placement } from '@floating-ui/react-dom';
import { LearningActivityStepSourceType } from '@stellar-lms-frontend/lms-api';
import {
  FloatingOptions,
  NoteTextIcon,
  Link2Icon,
  ChartPie24Icon,
  CheckBoxRectIcon,
  Comment2QuestionIcon,
  GlassesVRIcon,
} from '@stellar-lms-frontend/ui-components';
import { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export type FloatingStepOptionsI18n = {
  block?: string;
  url?: string;
  survey?: string;
  assessment?: string;
  action?: string;
  scenario?: string;
};

export type FloatingStepOptionsProps = {
  wrappedComponent: ReactElement;
  isOpen: boolean;
  onClose: () => void;
  stepCreateUrl: string;
  i18n?: FloatingStepOptionsI18n;
  placement?: Placement;
  onSelectStepType?: () => void;
};

export const FloatingStepOptions: React.FC<FloatingStepOptionsProps> = ({
  wrappedComponent,
  isOpen,
  onClose,
  stepCreateUrl,
  i18n,
  placement = 'top-start',
  onSelectStepType,
}) => {
  const navigate = useNavigate();

  const handleStepClick = useCallback(() => {
    onClose();
    onSelectStepType?.();
  }, [onClose, onSelectStepType]);

  return (
    <FloatingOptions
      placement={placement}
      widthClassName="w-[400px]"
      heightClassName="max-h-[75vh]"
      wrappedComponent={wrappedComponent}
      isOpen={isOpen}
      onClose={onClose}
      options={[
        {
          left: <NoteTextIcon />,
          label: i18n?.block ?? '',
          onClick: () => {
            handleStepClick();
            navigate(stepCreateUrl, {
              state: {
                stepType: LearningActivityStepSourceType.BLOCK,
                isEditing: true,
              },
            });
          },
        },
        {
          left: <Link2Icon />,
          label: i18n?.url ?? '',
          onClick: () => {
            handleStepClick();
            navigate(stepCreateUrl, {
              state: {
                stepType: LearningActivityStepSourceType.IFRAME,
                isEditing: true,
              },
            });
          },
        },
        {
          left: <ChartPie24Icon />,
          label: i18n?.survey ?? '',
          onClick: () => {
            handleStepClick();
            navigate(stepCreateUrl, {
              state: {
                stepType: LearningActivityStepSourceType.SURVEY,
                isEditing: true,
              },
            });
          },
        },
        {
          left: <CheckBoxRectIcon />,
          label: i18n?.action ?? '',
          onClick: () => {
            handleStepClick();
            navigate(stepCreateUrl, {
              state: {
                stepType: LearningActivityStepSourceType.WBA,
                isEditing: true,
              },
            });
          },
        },
        {
          left: <Comment2QuestionIcon />,
          label: i18n?.assessment ?? '',
          onClick: () => {
            handleStepClick();
            navigate(stepCreateUrl, {
              state: {
                stepType: LearningActivityStepSourceType.ASSESSMENT,
                isEditing: true,
              },
            });
          },
        },
        {
          left: <GlassesVRIcon className="text-text-01 h-6 w-6" />,
          label: i18n?.scenario ?? '',
          onClick: () => {
            handleStepClick();
            navigate(stepCreateUrl, {
              state: {
                stepType: LearningActivityStepSourceType.SCENARIO,
                isEditing: true,
              },
            });
          },
        },
      ]}
    />
  );
};
