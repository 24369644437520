import { BrowserRouter, Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import 'react-toastify/dist/ReactToastify.css';

import { Banner, ErrorView, LogoLoader } from '@stellar-lms-frontend/ui-components';
import {
  ACTION_EDIT_URI_PART,
  ACTION_OLD_REFLECTION_URI_PART,
  ACTION_OLD_REVIEW_URI_PART,
  ACTION_REFLECTION_RESULT_URI_PART,
  ACTION_REFLECTION_URI_PART,
  ACTION_REVIEW_RESULT_URI_PART,
  ACTION_REVIEW_URI_PART,
  ACTION_URI_ID_PARAM_NAME,
  COURSE_URI,
  DESIGNER_COURSE_URI,
  DESIGNER_SURVEY_QUESTION_URI,
  DESIGNER_SURVEY_URI,
  JOURNEY_CREATE_URI,
  LEARNER_ACTIONS_BASE_URI,
  LEARNER_COURSE_ACTIONS_BASE_ROUTE,
  LEARNING_ACTIVITY_DETAIL_URI,
  LEGACY_MENTOR_ACTIONS_BASE_URI,
  MENTOR_ACTIONS_BASE_ROUTE,
  MENTOR_ACTIONS_BASE_URI,
  MENTOR_BASE_URI,
  MENTOR_COURSE_INSIGHTS_URI,
  MENTOR_COURSE_URI,
  MENTOR_JOURNEY_URI,
  MENTOR_LEARNERS_URI,
  MENTOR_SURVEYS_URI,
  MENTOR_SURVEY_QUESTION_URI,
  MENTOR_SURVEY_URI,
  NOTIFICATIONS_URI,
  REDIRECT_HOME_URI,
  SPACED_REPETITION_URI,
} from '../constants/routes';

import { useTranslation } from 'react-i18next';
import { handleUserInformation } from '../features/auth/auth.service';
import ActionsPage from '../features/actions';
import ProfileOverview from '../features/profile/profile-overview';
import SpacedRepetitionPage from '../features/spaced-repetition';
import { WorkBasedActionLearnerSurveyView } from '../features/workbasedactions/work-based-action-learner-survey-view';

import CourseOverview from '../features/course-overview/course-overview';
import { MentorDashboardPage } from '../features/dashboard/mentor-dashboard-page';
import { LegacyActionsOverviewPage } from '../features/actions/legacy-actions-overview-page';
import { MenteeActionsOverviewPage } from '../features/mentors/mentee-actions-overview-page';
import { MenteeTable } from '../features/mentors/mentee-table';
import { WorkBasedActionMentorSurveyView } from '../features/workbasedactions/work-based-action-mentor-survey-view';
import { Error404 } from '../features/error/error-404';
import { useAppVersion } from '@stellar-lms-frontend/lms-api';
import {
  HubspotContainer,
  LearningActivity,
  ORGANIZATION_URI,
  ADMINISTRATION_URI,
  PROFILE_URI,
  RequireRole,
  RequireSubscription,
  RequireUser,
  Toasts,
  AblyChannelSubscriber,
} from '@stellar-lms-frontend/lms-components';
import { SurveyListContainer } from '../features/survey-summary/survey-list-container';
import { SurveyOverviewContainer } from '../features/survey-summary/survey-overview-container';
import React from 'react';
import { LearnerDashboardPage } from '../features/dashboard/learner-dashboard-page';

import NotificationPage from '../features/notifications/notification-page';
import { MentorLearningActivityDetailPage } from '../features/learning-activity/mentor-learning-activity-detail-page';
import { LearnerLearningActivityDetailPage } from '../features/learning-activity/learner-learning-activity-detail-page';
import { MentorInsightsPage } from '../features/insights/mentor-insights-page';
import {
  CompanyRole,
  getLightCourseInfo,
  useCurrentCompany,
  useCurrentCourse,
} from '@stellar-lms-frontend/lms-graphql';
import { graphQLClient } from '../lib/graphql';
import { OrganizationOverview } from '../features/organization-overview/organization-overview';
import { MentorJourneyPage } from '../features/journey/mentor-journey-page';
import { Home } from '../features/home/home';
import { CreateJourneyPage } from '../features/designer/create-journey/create-journey-page';
import { DesignerJourneyPage } from '../features/designer/journey/designer-journey-page';
import { DesignerLearningActivityDetailPage } from '../features/designer/learning-activity-detail/designer-learning-activity-detail-page';
import { DesignerDashboard } from '../features/designer/dashboard/designer-dashboard';
import { DesignerInsightsPage } from '../features/designer/insights/designer-insights-page';
import { DesignerLearnersTable } from '../features/designer/learners/learners-table';
import { CourseDetails } from '../features/designer/course-details';
import { WorkBasedActionCourseListView } from '../features/designer/work-based-action/work-based-action-course-list-view';
import { WorkBasedActionCourseDetailView } from '../features/designer/work-based-action/work-based-action-course-detail-view';
import { MobileError } from '../features/designer/errors/mobile-error';
import { SubscriptionExpiredPage } from '../features/designer/subscription-expired/subscription-expired-page';
import { TrialRegistrationPage } from '../features/trial/trial-registration-page';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import {
  RedirectExternal,
  defaultGraphqlClient,
  getEnvironment,
  getOnsophicApi,
} from '@stellar-lms-frontend/common-utils';
import { AdministrationOverview } from '../features/administration-overview/administration-overview';
import { ChannelProvider } from 'ably/react';
import { Message } from 'ably';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const environment = getEnvironment();
let stripePromise: Promise<Stripe | null>;
if (environment.isProduction) {
  stripePromise = loadStripe(
    'pk_live_51MpE4hKw24acqbYKwTwrnE4lNZ8Cq5YZv8n5vyffnQ5EEuaO7Bc1nN4GCAisFR4iVzOy4EqbOTJGiYBYCbta6Q7S00Gfitv8Fl',
  );
} else {
  stripePromise = loadStripe(
    'pk_test_51MpE4hKw24acqbYKeRMCybjs6jgPQScvChmiMQ3AnKlYPK3yQvHCMtalxkwKE4cjn7bts61vNO9dNRBtsWm0xsG000mlBU9u41',
  );
}

function App() {
  const { t } = useTranslation('translation');
  const appIsUpdated = useAppVersion();

  return (
    <BrowserRouter>
      {appIsUpdated && (
        <Banner position="top">
          <p>
            {t('alert.version.message')}
            <span
              className="cursor-pointer pl-2 underline"
              onClick={() => window.location.reload()}
            >
              {t('alert.version.cta')}
            </span>
          </p>
        </Banner>
      )}
      <Toasts />
      <div className="hidden h-full w-full lg:block">
        <SentryRoutes>
          {/*
          TODO Add extra authentication, user need to be authenticated but getLoggedInUser (used in RequireAuth) doesn't work yet
          */}
          <Route
            path="/signup"
            element={<RedirectExternal url={getOnsophicApi() + '/user/auth0/signup'} />}
          />
          <Route
            path="/trial"
            element={<TrialRegistrationPage />}
          ></Route>
          {/*
           * Everything that needs to be authenticated goes in here!
           */}
          <Route
            element={
              <RequireUser userCallback={handleUserInformation}>
                <CurrentCompanyPrefetcher>
                  <HubspotContainer graphQLClient={graphQLClient('learner')} />
                </CurrentCompanyPrefetcher>
              </RequireUser>
            }
          >
            <Route element={<RequireSubscription />}>
              {/* The routes without required role go in here */}
              <Route
                path=""
                element={<Home />}
              />
              <Route
                path={PROFILE_URI}
                element={<ProfileOverview />}
              />
              <Route
                path={ORGANIZATION_URI + '/*'}
                element={<OrganizationOverview stripePromise={stripePromise} />}
              />
              <Route
                path={ADMINISTRATION_URI + '/*'}
                element={<AdministrationOverview />}
              />
              <Route
                path={NOTIFICATIONS_URI}
                element={<NotificationPage />}
              />
              {/* pretty much unused (was for mobile)
              <Route
                path={MORE_URI}
                element={
                  <LearnerMore
                    type={'page'}
                    BlueUIShell={UIShell}
                  />
                }
              /> */}

              <Route path="/designer/">
                <Route
                  index
                  element={<Navigate to="/" />}
                />
                <Route
                  path={JOURNEY_CREATE_URI}
                  element={<CreateJourneyPage />}
                />
                <Route
                  path={DESIGNER_COURSE_URI}
                  element={<CourseAblyWrapper />}
                >
                  <Route
                    index
                    element={<DesignerDashboard />}
                  />
                  <Route
                    path="insights"
                    element={<DesignerInsightsPage />}
                  />
                  <Route path="actions">
                    <Route
                      index
                      element={<WorkBasedActionCourseListView />}
                    />
                    <Route
                      path=":actionId"
                      element={<WorkBasedActionCourseDetailView />}
                    />
                  </Route>
                  <Route
                    path="learners"
                    element={<DesignerLearnersTable />}
                  />
                  <Route
                    path={'about'}
                    element={<CourseDetails />}
                  />
                  <Route path="journey">
                    <Route
                      index
                      element={<DesignerJourneyPage />}
                    />
                    <Route
                      path={`${LEARNING_ACTIVITY_DETAIL_URI}/:${LearningActivity.LEARNING_ACTIVITY_URI_ID_PARAM_NAME}/*`}
                      element={<DesignerLearningActivityDetailPage />}
                    />
                  </Route>
                  <Route path="surveys">
                    <Route
                      index
                      element={<SurveyOverviewContainer />} // TODO check if this is the same for designer and learner
                    />
                    <Route
                      path={DESIGNER_SURVEY_URI}
                      element={<SurveyListContainer />} // TODO check if this is the same for designer and learner
                    />
                    <Route
                      path={DESIGNER_SURVEY_QUESTION_URI}
                      element={<SurveyListContainer />} // TODO check if this is the same for designer and learner
                    />
                  </Route>
                </Route>
              </Route>

              {/* The mentor routes go in here */}
              <Route
                element={
                  <RequireRole
                    acceptedRoles={Object.values(CompanyRole)}
                    graphQLClient={graphQLClient()}
                    loaderComponent={<LogoLoader />}
                    unAuthorizedView={
                      <ErrorView
                        type="unauthorized"
                        redirectUrl={REDIRECT_HOME_URI}
                      />
                    }
                  />
                }
              >
                <Route
                  path={MENTOR_BASE_URI}
                  element={<Navigate to="/" />}
                />

                <Route
                  path={MENTOR_COURSE_URI}
                  element={<CourseAblyWrapper />}
                >
                  <Route
                    index
                    element={<MentorDashboardPage />}
                  />

                  <Route
                    path="learners"
                    element={<MenteeTable />}
                  />

                  <Route
                    path="insights"
                    element={<MentorInsightsPage />}
                  />

                  {/* This can be modularized and be shifted to the actions root */}
                  {actionRoutes('actions', 'mentor', <MenteeActionsOverviewPage />)}

                  <Route path="surveys">
                    <Route
                      index
                      element={<SurveyOverviewContainer />}
                    />
                    <Route
                      path=":surveyId"
                      element={<SurveyListContainer />}
                    />
                    <Route
                      path=":surveyId/question/:questionId"
                      element={<SurveyListContainer />}
                    />
                  </Route>

                  <Route path="journey">
                    <Route
                      index
                      element={<MentorJourneyPage />}
                    />
                    <Route
                      path={`${LEARNING_ACTIVITY_DETAIL_URI}/:${LearningActivity.LEARNING_ACTIVITY_URI_ID_PARAM_NAME}/*`}
                      element={<MentorLearningActivityDetailPage />}
                    />
                  </Route>
                </Route>

                {/* Support for legacy emails/notifications of actions */}
                <Route
                  path={LEGACY_MENTOR_ACTIONS_BASE_URI}
                  element={
                    <LegacyActionsOverviewPage
                      baseCourseUri={MENTOR_COURSE_URI}
                      baseRoute={MENTOR_ACTIONS_BASE_ROUTE}
                    />
                  }
                />
              </Route>

              {/* The student routes go in here */}
              <Route
                path={'/learner/'}
                element={
                  <RequireRole
                    acceptedRoles={Object.values(CompanyRole)}
                    graphQLClient={graphQLClient()}
                    loaderComponent={<LogoLoader />}
                    unAuthorizedView={
                      <ErrorView
                        type="unauthorized"
                        redirectUrl={REDIRECT_HOME_URI}
                      />
                    }
                  />
                }
              >
                {/* MENTOR redirect */}
                <Route
                  path="mentor/*"
                  element={<Navigate to="/mentor" />}
                />

                <Route
                  index
                  element={<Navigate to="/" />}
                />
                {/* All course specific pages */}
                <Route path={COURSE_URI}>
                  {/* Course/module overview */}
                  <Route
                    index
                    element={<CourseOverview />}
                  />

                  {/* Course dashboard */}
                  <Route
                    path={'overview'}
                    element={<LearnerDashboardPage />}
                  ></Route>

                  {/* Actions pages for learner for a specific course */}
                  {actionRoutes('actions', 'learner', <ActionsPage />)}

                  <Route
                    path={`${LEARNING_ACTIVITY_DETAIL_URI}/:${LearningActivity.LEARNING_ACTIVITY_URI_ID_PARAM_NAME}/*`}
                    element={<LearnerLearningActivityDetailPage />}
                  />
                </Route>
                {/* Old action urls
                staying to support mobile
                (This can be modularized and be shifted to the actions root) */}
                <Route
                  path={`${LEARNER_ACTIONS_BASE_URI}/:${ACTION_URI_ID_PARAM_NAME}/*`}
                  element={
                    <LegacyActionsOverviewPage
                      baseCourseUri={COURSE_URI}
                      baseRoute={LEARNER_COURSE_ACTIONS_BASE_ROUTE}
                    />
                  }
                />
              </Route>
              <Route
                path={SPACED_REPETITION_URI}
                element={<SpacedRepetitionPage />}
              />
              <Route
                path="*"
                element={<Error404 />}
              />
            </Route>

            <Route
              path="subscription-expired"
              element={<SubscriptionExpiredPage stripePromise={stripePromise} />}
            />
          </Route>

          <Route
            path="*"
            element={<Navigate to="/" />}
          />
        </SentryRoutes>
      </div>
      <div className="block h-full w-full lg:hidden">
        <SentryRoutes>
          <Route
            path="*"
            element={<MobileError />}
          />
        </SentryRoutes>
      </div>
    </BrowserRouter>
  );
}

const actionRoutes = (baseUrl: string, role: 'learner' | 'mentor', basePage: React.ReactNode) => {
  return (
    <Route path={`${baseUrl}`}>
      <Route
        index
        element={basePage}
      />
      <Route path={`:${ACTION_URI_ID_PARAM_NAME}`}>
        <Route
          index
          element={basePage}
        />
        <Route
          path={`${ACTION_REFLECTION_RESULT_URI_PART}`}
          element={basePage}
        />
        <Route
          path={`${ACTION_REVIEW_RESULT_URI_PART}`}
          element={basePage}
        />
        {role === 'learner' && (
          <Route
            path={`${ACTION_OLD_REFLECTION_URI_PART}`}
            element={basePage}
          />
        )}
        {role === 'learner' && (
          <Route
            path={`${ACTION_REFLECTION_URI_PART}`}
            element={<WorkBasedActionLearnerSurveyView />}
          />
        )}
        {role === 'mentor' && (
          <Route
            path={`${ACTION_OLD_REVIEW_URI_PART}`}
            element={<ActionsPage />}
          />
        )}
        {role === 'mentor' && (
          <Route
            path={ACTION_REVIEW_URI_PART}
            element={<WorkBasedActionMentorSurveyView />}
          />
        )}
        {role === 'learner' && (
          <Route
            path={`${ACTION_EDIT_URI_PART}`}
            element={basePage}
          />
        )}
      </Route>
    </Route>
  );
};

const CurrentCompanyPrefetcher = ({ children }: { children?: JSX.Element }) => {
  const {
    query: { data: company },
    invalidateCache,
  } = useCurrentCompany(defaultGraphqlClient);

  return company?.realtimeChannel.id ? (
    <ChannelProvider channelName={company.realtimeChannel.id}>
      <AblyChannelSubscriber
        channelId={company.realtimeChannel.id}
        callback={(message: Message) => {
          if (
            message.name === 'update' &&
            message.data.type === 'Company' &&
            message.data.id === company.id
          ) {
            invalidateCache(true);
          }
        }}
      >
        {children ?? <Outlet />}
      </AblyChannelSubscriber>
    </ChannelProvider>
  ) : (
    <LogoLoader />
  );
};

const CourseAblyWrapper = () => {
  const { courseId } = useParams();

  const {
    query: { data: currentCourse },
  } = useCurrentCourse(
    (courseId: string) => getLightCourseInfo(graphQLClient(), { courseId }),
    courseId,
  );

  return currentCourse?.realtimeChannel.id ? (
    <ChannelProvider channelName={currentCourse.realtimeChannel.id}>
      {currentCourse.aiProject ? (
        <ChannelProvider channelName={currentCourse.aiProject.realtimeChannel.id}>
          <Outlet />
        </ChannelProvider>
      ) : (
        <Outlet />
      )}
    </ChannelProvider>
  ) : (
    <LogoLoader />
  );
};

export default App;
