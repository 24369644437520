import { Heading4 } from '@stellar-lms-frontend/ui-components';
import { BoxColorDefinition } from '../../types';

export const Title = ({ title, colorDef }: { title: string; colorDef: BoxColorDefinition }) => (
  <>
    {/* mobile title */}
    <span
      className={`${
        colorDef.titleClassName ?? 'text-text-01'
      } font-lexend overflow-hidden text-ellipsis whitespace-nowrap pb-1 text-base font-medium leading-normal lg:hidden lg:leading-[137%]`}
    >
      {title}
    </span>

    {/* desktop title */}
    <Heading4
      className={`${
        colorDef.titleClassName ?? 'text-text-01'
      } hidden overflow-hidden text-ellipsis whitespace-nowrap pb-1 lg:block`}
    >
      {title}
    </Heading4>
  </>
);
