import React from 'react';

import { ContentEditable } from '../../components/content-editable';
import { BriefCaseIcon } from '../../../icons';
import { ExampleData } from './example-plugin';
import { API } from '@editorjs/editorjs';

interface ExampleComponentProps {
  id: string;
  data: ExampleData;
  onChange: (newData: ExampleData) => void;
  isReadOnly: boolean;
  api: API;
  className?: string;
}

export const ExampleComponent: React.FC<ExampleComponentProps> = ({
  id,
  data,
  onChange,
  isReadOnly,
  className = '',
  api,
}) => {
  return (
    <div
      id={id}
      className="bg-surface-03 font-lexend my-4 flex items-start gap-8 rounded-xl px-8 py-10"
    >
      <BriefCaseIcon className="h-[56px] w-[56px] shrink-0 text-blue-500" />
      <div className="flex flex-col space-y-4">
        <ContentEditable
          onChange={(value) => {
            onChange({ ...data, title: value });
          }}
          value={data.title}
          contentEditable={!isReadOnly}
          placeholder={api.i18n.t('title-placeholder')}
          className={` text-text-01 text-xl outline-none`}
        />
        <ContentEditable
          onChange={(value) => {
            onChange({
              ...data,
              text: value,
            });
          }}
          value={data.text}
          placeholder={api.i18n.t('description-placeholder')}
          contentEditable={!isReadOnly}
          className={`${className} text-text-01 grow cursor-text overflow-hidden break-words outline-none`}
        />
      </div>
    </div>
  );
};
