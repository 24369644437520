import { useQuery, useQueryClient } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import {
  CourseStats,
  Language,
  UserCourseStats,
  graphql,
  CourseOutline,
  LearningActivityProgress,
  GetMentorHomeCoursesQueryVariables,
  GetStudentHomeCoursesQueryVariables,
  DesignerHomePageQueryQueryVariables,
} from '../graphql';
import { CourseDashboardListItem } from '../types';

const QUERY_KEY = 'COURSE-LIST';

export const useCourseDashboardList = (
  graphQLClient: (context: 'mentor' | 'learner' | 'designer') => GraphQLClient,
  companyId?: string,
  userId?: string
) => {
  const queryClient = useQueryClient();

  const query = useQuery<CourseDashboardListItem[]>(
    [QUERY_KEY, companyId, userId],
    async () => {
      if (!companyId || !userId) {
        return [];
      }

      const mentorCourses = await getMentorHomeCourses(graphQLClient('mentor'), { companyId });
      const learnerCourses = await getStudentHomeCourses(graphQLClient('learner'), { companyId });
      const designerCourses = await designerHomePageQuery(graphQLClient('designer'), { companyId });

      return [
        ...designerCourses.map((course) => {
          const stats = course.courseStats as CourseStats;
          return {
            __type: 'DesignerCourse' as const,
            id: course.id,
            createdAt: new Date(course.createdAt),
            updatedAt: new Date(course.updatedAt),
            title: course.title ?? '',
            language: course.language ?? Language.En,
            stats: {
              averageCompletionRate: (stats.averageCompletionRate ?? 0) * 100, // Backend returns a number between 0 and 1
              averageTimeSpentInSeconds: stats.averageTimeSpentInSeconds ?? 0,
              numberOfStudents: stats.numberOfStudents ?? 0,
              numberOfActiveStudents: stats.numberOfActiveStudents ?? 0,
            },
          };
        }),
        ...learnerCourses.map((course) => {
          const stats = course.courseStats as UserCourseStats;
          return {
            __type: 'LearnerCourse' as const,
            id: course.id,
            title: course.title ?? '',
            createdAt: new Date(course.createdAt),
            updatedAt: new Date(course.updatedAt),
            language: course.language ?? Language.En,
            stats: {
              progress: stats.progress ?? 0,
              nrOfLearningActivities: stats.nrOfLearningActivities ?? 0,
              nrOfLearningActivitiesCompleted: stats.nrOfLearningActivitiesCompleted ?? 0,
              nextActivity: getNextActivity(course.outline as CourseOutline), // This cast points out a problem!
              timeSpentInS: stats.timeSpentInS ?? 0,
              timeRemainingInS: calculateTimeRemaining(course.outline as CourseOutline), // This cast points out a problem!
            },
          };
        }),
        ...mentorCourses.map((course) => {
          return {
            __type: 'MentorCourse' as const,
            id: course.id,
            createdAt: new Date(course.createdAt),
            updatedAt: new Date(course.updatedAt),
            title: course.title ?? '',
            language: course.language ?? Language.En,
          };
        }),
      ];
    },
    { enabled: !!companyId && !!userId }
  );

  function invalidateCache() {
    queryClient.invalidateQueries([QUERY_KEY, companyId, userId]);
  }

  return {
    query: query,
    invalidateCache: invalidateCache,
  };
};

function getNextActivity(courseOutline?: CourseOutline | null) {
  let nextActivity = undefined;

  if (courseOutline) {
    for (const module of courseOutline.modules ?? []) {
      const nextLearningActivity = module.learningActivities?.find(
        (learningActivity) =>
          learningActivity.progress === LearningActivityProgress.InProgress ||
          learningActivity.progress === LearningActivityProgress.Todo
      );
      if (nextLearningActivity) {
        nextActivity = nextLearningActivity;
        break;
      }
    }
  }

  return nextActivity
    ? {
        id: nextActivity.id,
        title: nextActivity.title ?? '',
        durationInS: nextActivity.duration ?? 0,
      }
    : undefined;
}
function calculateTimeRemaining(courseOutline?: CourseOutline | null) {
  // Create a flat array of steps duration and sum it
  return (
    courseOutline?.modules
      ?.map((m) =>
        m.learningActivities
          ?.filter((la) => la.progress !== LearningActivityProgress.Done)
          .map((la) => la.steps?.map((s) => s.duration))
      )
      .flat(3)
      .reduce((acc: number, curr) => (curr ? acc + curr : acc), 0) ?? 0
  );
}

const getStudentHomeCourses = (
  client: GraphQLClient,
  variables: GetStudentHomeCoursesQueryVariables
) =>
  client
    .request(
      graphql(`
        query getStudentHomeCourses($companyId: ID!) {
          courses(companyId: $companyId) {
            items {
              courseStats {
                ... on UserCourseStats {
                  nrOfLearningActivitiesCompleted
                  progress
                  nrOfLearningActivities
                  timeSpentInS
                }
              }
              id
              createdAt
              updatedAt
              title
              language
              outline {
                id
                title
                modules {
                  learningActivities {
                    title
                    duration
                    progress
                    steps {
                      duration
                    }
                  }
                }
              }
            }
          }
        }
      `),
      variables
    )
    .then((res) => res.courses?.items ?? []);

const getMentorHomeCourses = (
  client: GraphQLClient,
  variables: GetMentorHomeCoursesQueryVariables
) =>
  client
    .request(
      graphql(`
        query getMentorHomeCourses($companyId: ID!) {
          courses(companyId: $companyId) {
            items {
              id
              createdAt
              updatedAt
              title
              language
              outline {
                id
                title
              }
            }
          }
        }
      `),
      variables
    )
    .then((res) => res.courses?.items ?? []);

const designerHomePageQuery = (
  client: GraphQLClient,
  variables: DesignerHomePageQueryQueryVariables
) =>
  client
    .request(
      graphql(`
        query DesignerHomePageQuery($companyId: ID!) {
          courses(companyId: $companyId) {
            items {
              id
              createdAt
              updatedAt
              title
              language
              courseStats {
                ... on CourseStats {
                  numberOfActiveStudents
                  numberOfStudents
                  averageTimeSpentInSeconds
                  averageCompletionRate
                }
              }
            }
          }
        }
      `),
      variables
    )
    .then((res) => res.courses?.items ?? []);
