import { useTranslation } from 'react-i18next';
import { LightBulb } from '../illustrations';
import { ErrorViewTemplate } from './error-view-template';

export type ErrorViewType = {
  type: 'unauthorized' | 'server error';
  redirectUrl: string;
};
export const ErrorView = ({ type, redirectUrl }: ErrorViewType) => {
  const { t } = useTranslation('translation', { keyPrefix: 'errors.error-view' });

  const title = type === 'unauthorized' ? t('unauthorized-error-title') : t('generic-error-title');

  return (
    <ErrorViewTemplate
      icon={<LightBulb />}
      title={title}
      callToAction={t('call-to-action')}
      action={() => (window.location.href = redirectUrl)}
    />
  );
};
