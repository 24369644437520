import {
  graphql,
  SearchObjectivesQueryVariables,
  UpdateCourseObjectivesMutationVariables,
  CreateObjectiveMutationVariables,
} from '@stellar-lms-frontend/lms-graphql';
import { GraphQLClient, gql } from 'graphql-request';

import { Course, ListResource } from '../types';

export const searchObjectives = (
  client: GraphQLClient,
  variables: SearchObjectivesQueryVariables
) =>
  client
    .request(
      graphql(`
        query SearchObjectives($companyId: ID!, $query: String!, $limit: Int!) {
          searchObjectives(companyId: $companyId, query: $query, limit: $limit) {
            id
            name
          }
        }
      `),
      variables
    )
    .then((res) => res.searchObjectives);

export const updateCourseObjectives = (
  client: GraphQLClient,
  variables: UpdateCourseObjectivesMutationVariables
) =>
  client.request(
    graphql(`
      mutation UpdateCourseObjectives($courseId: ID!, $objectiveIds: [ID!]!) {
        updateCourseObjectives(courseId: $courseId, objectiveIds: $objectiveIds)
      }
    `),
    variables
  );

export const designerCourses = (client: GraphQLClient) =>
  client
    .request(
      graphql(`
        query DesignerCourses {
          designerCourses {
            count
            items {
              id
              title
              learningActivities {
                id
                title
              }
              enabled
              chaptersOn
              progress
              locked
              description
              skills
              objectives {
                id
                name
              }
            }
          }
        }
      `)
    )
    .then((res) => res.designerCourses?.items);

// Keeping this one in here for the typing, later on we'll have to start using the generated objects from Codegen
export const designerCoursesGraphQL = (client: GraphQLClient) =>
  client
    .request<{ designerCourses: ListResource<Course[]> }>(
      gql`
        query DesignerCourses {
          designerCourses {
            count
            items {
              id
              title
              learningActivities {
                id
                title
              }
              enabled
              chaptersOn
              progress
              locked
              description
              skills
              objectives {
                id
                name
              }
            }
          }
        }
      `
    )
    .then((res) => res.designerCourses?.items);

export const createObjective =
  (client: GraphQLClient) => (variables: CreateObjectiveMutationVariables) =>
    client.request(
      graphql(`
        mutation CreateObjective($companyId: ID!, $name: String!) {
          createObjective(companyId: $companyId, name: $name) {
            id
            name
          }
        }
      `),
      variables
    );
