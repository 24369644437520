import { ReactElement, cloneElement } from 'react';
import * as Sentry from '@sentry/react';

export const truncate = (s: string, max: number): string => {
  if (s.length > max) {
    return `${s.slice(0, max)}...`;
  } else {
    return s;
  }
};

export const sanitizeHtml = (s: string | undefined): string => {
  if (typeof s === 'string') {
    const txt = new DOMParser().parseFromString(s, 'text/html');
    return txt.documentElement.textContent || '';
  } else {
    return '';
  }
};

export const copyToClipboard = (text: string, callback?: () => void) => {
  navigator.clipboard
    .writeText(text)
    .then(() => callback?.())
    .catch((error) => {
      Sentry.captureException(error);
    });
};

export const getNumber = (value: string) => {
  return parseInt(value.replace(/\D/g, ''));
};

type TagMapping = {
  [tag: string]: ReactElement;
};

const replaceTagInText = (
  text: string,
  tag: string,
  element: ReactElement
): (string | ReactElement)[] => {
  const openTag = `<${tag}>`;
  const closeTag = `</${tag}>`;
  const parts = text.split(new RegExp(`${openTag}|${closeTag}`, 'g'));

  return parts.map((part, index) =>
    index % 2 === 0 ? part : cloneElement(element, { key: index, children: part })
  );
};

const replaceTagsInTextRecursive = (
  textParts: (string | ReactElement)[],
  tagMapping: TagMapping
): (string | ReactElement)[] => {
  if (!Object.keys(tagMapping).length) {
    return textParts;
  }

  const tagEntries = Object.entries(tagMapping);
  const [tag, element] = tagEntries[0];
  const remainingTags = Object.fromEntries(tagEntries.slice(1));

  const replacedTextParts = textParts.flatMap((part) =>
    typeof part === 'string'
      ? replaceTagInText(part, tag, element)
      : cloneElement(
          part,
          part.props,
          replaceTagsInTextRecursive(
            Array.isArray(part.props.children) ? part.props.children : [part.props.children],
            tagMapping
          )
        )
  );

  return replaceTagsInTextRecursive(replacedTextParts, remainingTags);
};

export const replaceTagsInText = (
  text: string,
  tagMapping: TagMapping
): (string | ReactElement)[] => {
  return replaceTagsInTextRecursive([text], tagMapping);
};

export const addProtocol = (link: string, protocol = 'http://'): string => {
  if (/^(\w+):\/\//.test(link)) {
    return link;
  }

  const isInternal = link.startsWith('/');
  const isAnchor = link.startsWith('#');
  const isProtocolRelative = link.startsWith('//');

  if (!isInternal && !isAnchor && !isProtocolRelative) {
    return protocol + link;
  }

  return link;
};
