import { LearnerWorkBasedActionWithUser } from '@stellar-lms-frontend/lms-api';
import {
  Badge,
  BookIcon,
  CalendarIcon,
  ClockIcon,
  CommentIcon,
  LogoLoader,
  Heading4,
  LeftArrowIcon,
  ProfileIcon,
  TextIcon,
} from '@stellar-lms-frontend/ui-components';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { destructureSeconds } from '@stellar-lms-frontend/common-utils';
import { getCourseTitle } from './work-based-action.api';
import { graphQLClient } from '../../../lib/graphql';
import { COURSE_ACTION, COURSE_TITLE } from '../constants/query-constants';
import { DESIGNER_ACTIONS_ROUTE } from '../constants/routes';
import { UIShell } from '../../shell/ui-shell';
import { getLearnerWorkBasedAction } from '../../workbasedactions/work-based-actions.api';

// TODO copied over
type DueType = 'overdue' | 'future' | 'soon' | 'today';
type Due = {
  type: DueType;
  label: string;
  date: moment.Moment;
};

export const WorkBasedActionCourseDetailView = () => {
  const { courseId, actionId } = useParams();

  const { t } = useTranslation('translation', { keyPrefix: 'course-action-detail' });
  const { t: wbaT } = useTranslation('translation', { keyPrefix: 'work-based-actions' });
  const { t: genT } = useTranslation('translation', { keyPrefix: 'general' });
  const navigate = useNavigate();
  const prevCourseIdRef = useRef(courseId);

  const [showMore, setShowMore] = useState<boolean>(false);
  const [showDescriptionTruncateButton, setShowDescriptionTruncateButton] = useState<
    boolean | undefined
  >();

  const { data: courseTitle } = useQuery(
    [COURSE_TITLE, courseId],
    () => (courseId ? getCourseTitle(graphQLClient('designer'), { id: courseId }) : null),
    { enabled: !!courseId }
  );

  const { data: action } = useQuery(
    [COURSE_ACTION, actionId],
    () => (actionId ? getLearnerWorkBasedAction(graphQLClient('designer'), actionId) : null),
    {
      enabled: !!actionId,
    }
  );

  useEffect(() => {
    if (courseId !== prevCourseIdRef.current) navigate(DESIGNER_ACTIONS_ROUTE(courseId));
  }, [courseId, navigate]);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const descriptionDivRefCallback = (element: HTMLDivElement) => {
    // only update this value once
    if (element && !showDescriptionTruncateButton) {
      setShowDescriptionTruncateButton(element.offsetHeight < element.scrollHeight);
    }
  };

  /* COPY from work-based-action-read-only TODO */
  const determineDueType = (date: Date): Due => {
    const dueDate = moment(date);
    const now = moment(new Date()).startOf('day');
    const daysToGo = Math.trunc(moment.duration(dueDate.diff(now)).asDays());

    if (daysToGo > 2) {
      return {
        type: 'future',
        label: t('due-date', { date: dueDate.format('D MMM yyyy') }),
        date: dueDate,
      };
    } else if (daysToGo > 0) {
      return { type: 'soon', label: t('due-days', { count: daysToGo }), date: dueDate };
    } else if (daysToGo === 0) {
      return { type: 'today', label: t('due-today'), date: dueDate };
    } else {
      return { type: 'overdue', label: t('overdue-days', { count: daysToGo * -1 }), date: dueDate };
    }
  };

  const renderBlueDueDate = (due: Omit<Due, 'type' | 'date'>) => (
    <div className="flex flex-row ">
      <CalendarIcon className="text-primary-01 my-4 shrink-0 " />
      <div className="border-b-1 border-border-01 text-text-02 font-lexend font-regular ml-4 grow py-4">
        <span
          className={`text-text-02 font-lexend font-regular inline-block text-base leading-normal`}
        >
          {due.label}
        </span>
      </div>
    </div>
  );

  const renderRedDueDate = (due: Omit<Due, 'type'>) => (
    <div className="flex flex-row ">
      <CalendarIcon className="text-negative-01 my-4 shrink-0 " />
      <div className="border-b-1 border-border-01 text-negative-01 font-lexend font-regular ml-4 flex grow flex-row justify-between py-4 text-base">
        {t('due-date', { date: due.date.format('D MMM yyyy') })}
        <Badge color="red">{due.label}</Badge>
      </div>
    </div>
  );

  const renderDueDate = (action: LearnerWorkBasedActionWithUser) => {
    if (!action.learnerDueDate) {
      return null;
    }
    const due = determineDueType(action.learnerDueDate);

    if (action.completed) {
      return renderBlueDueDate({ label: t('due-date', { date: due.date.format('D MMM yyyy') }) });
    }

    switch (due.type) {
      // beware of the fallthrough
      case 'future':
      case 'soon':
      case 'today': {
        return renderBlueDueDate(due);
      }
      case 'overdue': {
        return renderRedDueDate(due);
      }
    }
  };

  const { hours, minutes } = destructureSeconds(action?.timeSpent ?? 0);
  /* END COPY  from work-based-action-read-only TODO */

  return (
    <UIShell
      subNavTitle={action?.learnerShortDescription ?? ''}
      subNavSubtitle={courseTitle ?? ''}
      leftButton={{
        leftIcon: <LeftArrowIcon className="fill-on-primary-01 h-5 w-5" />,
        action: () => navigate(DESIGNER_ACTIONS_ROUTE(courseId)),
        label: '',
      }}
    >
      {action ? (
        <div className="max-w-content w-full">
          <div className="hidden flex-row items-start justify-between pb-5 lg:flex">
            <Heading4>{action.learnerShortDescription}</Heading4>
          </div>
          <div className="pb-5">
            <div className="flex flex-row ">
              <TextIcon className="text-primary-01 my-4 shrink-0 " />
              <div className="border-b-1 border-border-01 ml-4 grow py-4 ">
                <div className="font-lexend text-text-04 text-base font-semibold leading-normal lg:hidden">
                  {action.learnerShortDescription}
                </div>
                <div
                  onClick={toggleShowMore}
                  className={`${
                    showMore ? '' : 'line-clamp-5'
                  } font-lexend font-regular text-text-01 whitespace-pre-wrap text-base`}
                  ref={descriptionDivRefCallback}
                >
                  {action.learnerDescription}
                </div>
                <button
                  onClick={toggleShowMore}
                  className={`lexend text-primary-01 font-regular pt-1 text-sm ${
                    showDescriptionTruncateButton && !showMore ? 'block' : 'hidden'
                  }`}
                  id="show-more-button"
                >
                  {genT('show-more')}
                </button>
              </div>
            </div>
            {action.user && (
              <div className="flex flex-row">
                <ProfileIcon className="text-primary-01 my-4 shrink-0" />
                <div className="border-b-1 border-border-01 text-text-02 font-lexend font-regular ml-4 grow py-4">
                  {`${action.user.name}`}
                </div>
              </div>
            )}
            {renderDueDate(action)}
            <div className="flex flex-row ">
              <BookIcon className="text-primary-01 my-4 shrink-0 " />
              <div className="border-b-1 border-border-01 text-text-02 font-lexend font-regular ml-4 grow py-4">
                {`${action.courseTitle} > ${action.moduleTitle}`}
              </div>
            </div>
            {action.completed && (
              <>
                {action.timeSpent && (
                  <div className="flex flex-row ">
                    <ClockIcon className="text-primary-01 my-4 shrink-0 " />
                    <div className="border-b-1 border-border-01 text-text-02 font-lexend font-regular ml-4 flex grow flex-row justify-between py-4">
                      {`${wbaT('time-spent-hours', { count: hours })} ${genT('and')} ${wbaT(
                        'time-spent-minutes',
                        {
                          count: minutes,
                        }
                      )}`}
                    </div>
                  </div>
                )}
                {action.learnerFeedback && (
                  <div className="flex flex-row ">
                    <CommentIcon className="text-primary-01 my-4 shrink-0 " />
                    <div className="border-b-1 border-border-01 text-text-02 font-lexend font-regular ml-4 flex grow flex-row justify-between py-4">
                      {action.learnerFeedback}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <LogoLoader />
      )}
    </UIShell>
  );
};
