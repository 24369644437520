import { VoidFunc, useOnClickOutside } from '@stellar-lms-frontend/common-utils';
import { FloatingOptions, MoreVertIcon } from '@stellar-lms-frontend/ui-components';
import { useState, useRef } from 'react';

// TODO The state that is maintained here (+ the preventing of event propagation can probably be built in the FloatingOptions component)
export const OptionMenu = ({
  actions,
}: {
  actions: {
    key: string;
    click: VoidFunc;
    label: string;
    colorClassName?: string;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
  }[];
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  useOnClickOutside(() => setIsOptionsOpen(false), [ref]);

  return (
    <div className="w-fit self-end">
      <FloatingOptions
        wrappedComponent={
          <button
            ref={ref}
            onClick={(e) => {
              e.stopPropagation();
              document.body.click();
              setIsOptionsOpen(true);
            }}
          >
            <MoreVertIcon className="text-gray-700" />
          </button>
        }
        isOpen={isOptionsOpen}
        onClose={() => setIsOptionsOpen(false)}
        options={actions.map((action) => ({
          onClick: (e) => {
            e.stopPropagation();
            setIsOptionsOpen(false);
            action.click();
          },
          left: action.leftIcon,
          label: action.label,
          className: action.colorClassName,
        }))}
      />
    </div>
  );
};
