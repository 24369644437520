import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';
import { graphql } from '@stellar-lms-frontend/lms-graphql';
import { useQuery } from '@tanstack/react-query';
import { BarChart } from '@stellar-lms-frontend/ui-components';

const getWBAState = async (companyId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query GetCompanyDashboardWBAState($companyId: ID!) {
        companyWbaState(companyId: $companyId) {
          rows {
            category
            count
          }
        }
      }
    `),
    { companyId: companyId }
  );
};

const chartConfig = {
  OPEN: {
    label: 'Open',
    color: 'var(--chart-1)',
  },
  SUBMITTED: {
    label: 'Submitted',
    color: 'var(--chart-2)',
  },
  EXECUTED: {
    label: 'Executed',
    color: 'var(--chart-3)',
  },
  REVIEWED: {
    label: 'Reviewed',
    color: 'var(--chart-4)',
  },
  COMPLETED: {
    label: 'Completed',
    color: 'var(--chart-5)',
  },
  count: {
    label: 'Amount',
    color: 'var(--chart-2)',
  },
};

export const WBAStateChart = ({ companyId }: { companyId: string }) => {
  const { data, isLoading } = useQuery(['wbaState', companyId], () => getWBAState(companyId), {
    refetchOnWindowFocus: false,
  });

  return (
    <BarChart
      isLoading={isLoading}
      rows={data?.companyWbaState.rows}
      dataColumns={['count']}
      chartConfig={chartConfig}
      stacked={false}
      showValueInBar
      showYaxis={false}
      showTooltip={false}
      showTooltipIndicator={false}
      showTooltipLabel={false}
    />
  );
};
