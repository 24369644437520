export const DESIGNER_COURSE_ROUTE = (courseId = '') => `/designer/course/${courseId}`;
export const DESIGNER_EMPTY_URI = '/designer/empty-courses';

export const DESIGNER_COURSE_INSIGHTS_ROUTE = (courseId: string) =>
  `/designer/course/${courseId}/insights`;

export const DESIGNER_JOURNEY_ROUTE = (courseId = '') => `/designer/course/${courseId}/journey`;

export const DESIGNER_ACTIONS_ROUTE = (courseId = '') => `/designer/course/${courseId}/actions`;
export const DESIGNER_ACTION_ROUTE = (courseId = '', actionId = '') =>
  `/designer/course/${courseId}/actions/${actionId}`;

export const DESIGNER_LEARNERS_ROUTE = (courseId = '') => `/designer/course/${courseId}/learners`;

export const DESIGNER_COURSE_ABOUT_ROUTE = (courseId: string) =>
  `/designer/course/${courseId}/about`;
