import { API } from '@editorjs/editorjs';
import { KeyTakeawayData } from './key-takeaways-plugin';
import React from 'react';
import { ListComponent } from '../lists/components/list-component';
import { KeyTakeawayBullet } from '../lists/components/list-bullets';
import { ContentEditable } from '../../components/content-editable';

interface KeyTakeawayComponentProps {
  id: string;
  data: KeyTakeawayData;
  onChange: (data: KeyTakeawayData) => void;
  isReadOnly: boolean;
  api: API;
}

export const KeyTakeawayComponent: React.FC<KeyTakeawayComponentProps> = ({
  id,
  data,
  onChange,
  isReadOnly,
  api,
}) => {
  return (
    <div
      id={id}
      className="my-4 space-y-6 rounded-xl bg-blue-50 p-8"
    >
      <div className="flex flex-col space-y-4">
        <ContentEditable
          onChange={(value) => {
            onChange({ ...data, title: value });
          }}
          value={data.title}
          contentEditable={!isReadOnly}
          placeholder={api.i18n.t('title-placeholder')}
          className={` text-text-01 text-xl outline-none`}
        />
        <ListComponent
          isReadOnly={isReadOnly}
          data={{ items: data.items, style: 'unordered' }}
          onChange={(value) => {
            onChange({ ...data, items: value.items });
          }}
          api={api}
          Bullet={KeyTakeawayBullet}
        />
      </div>
    </div>
  );
};
