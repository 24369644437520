import {
  DragVerticalList,
  CardDuringDrag,
  MultipleChoiceSettingsCard,
  ScaleSettingsCard,
  FreeTextEditCard,
  FloatingOptions,
  AddCard,
  TIcon,
  ListUnordered3Icon,
  ThermometerIcon,
} from '@stellar-lms-frontend/ui-components';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
  SurveyChoiceErrorType,
  SurveyFreeTextErrorType,
  SurveyQuestionTypeNamesEnum,
  SurveyScaleErrorType,
} from './survey-functions';
import { useState } from 'react';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export type SurveyEditQuestionsI18N = {
  addQuestion: string;
  freeText: {
    name: string;
  };
  choice: { name: string };
  scale: { name: string };
};

export type SurveyEditQuestionsProps = {
  name: string;
  alwaysShowQuestionsToMentor?: boolean;
};

export const SurveyEditQuestions: React.FC<SurveyEditQuestionsProps> = ({
  name,
  alwaysShowQuestionsToMentor = false,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'survey-step-view' });

  const [isSelectingQuestionType, setIsSelectingQuestionType] = useState(false);
  const [openCardIndex, setOpenCardIndex] = useState<number | undefined>(undefined);
  const { control } = useFormContext(); // TODO: add type for context or pass the control as a prop as type it
  const { stepId } = useParams();

  // TODO: using replace because remove it's not working
  const { fields, append, move, replace } = useFieldArray({
    control,
    name,
  });

  return (
    <div>
      <DragVerticalList
        items={fields}
        listClassName="space-y-3"
        onChange={(_, prevPos, newPos) => {
          move(prevPos, newPos);
          setOpenCardIndex(undefined);
        }}
      >
        {({ item: q, setActivatorNodeRef, listeners, dragActive, index }) => (
          <Controller
            key={q.id}
            control={control}
            name={`${name}.${index}`}
            render={({ field, fieldState }) => {
              if (dragActive) {
                return <CardDuringDrag title={field.value.text ?? ''} />;
              }

              // Error type casting was necessary because this component it's not properly typed on useContext and useFieldArray
              switch (field.value.__typename) {
                case SurveyQuestionTypeNamesEnum.SURVEY_CHOICE_QUESTION: {
                  const error = fieldState.error as SurveyChoiceErrorType;

                  return (
                    <MultipleChoiceSettingsCard
                      renderShowToMentorsOption={!alwaysShowQuestionsToMentor}
                      value={{ ...field.value, answers: field.value.answers ?? [] }}
                      onChange={(value) =>
                        field.onChange({
                          __typename: field.value.__typename,
                          ...value,
                          visibleToMentors: alwaysShowQuestionsToMentor || value.visibleToMentors,
                        })
                      }
                      isOpen={openCardIndex === index}
                      onToggleOpenState={() =>
                        setOpenCardIndex(() => (openCardIndex === index ? undefined : index))
                      }
                      dragProps={{ setActivatorNodeRef, listeners, dragActive }}
                      onDelete={() => replace(fields.filter((f) => f.id !== q.id))}
                      errors={error ? { text: error?.text } : undefined}
                    />
                  );
                }

                case SurveyQuestionTypeNamesEnum.SURVEY_SCALE_QUESTION: {
                  const error = fieldState.error as SurveyScaleErrorType;

                  return (
                    <ScaleSettingsCard
                      renderShowToMentorsOption={!alwaysShowQuestionsToMentor}
                      value={{
                        ...field.value,
                        title: field.value.text,
                        showToMentors: field.value.visibleToMentors,
                      }}
                      onChange={(value) =>
                        field.onChange({
                          __typename: field.value.__typename,
                          ...value,
                          text: value.title,
                          visibleToMentors: alwaysShowQuestionsToMentor || value.showToMentors,
                        })
                      }
                      isOpen={openCardIndex === index}
                      onToggleOpenState={() =>
                        setOpenCardIndex(() => (openCardIndex === index ? undefined : index))
                      }
                      dragProps={{ setActivatorNodeRef, listeners, dragActive }}
                      onDelete={() => replace(fields.filter((f) => f.id !== q.id))}
                      errors={
                        error
                          ? {
                              title: error?.text,
                              rightLabel: error?.rightLabel,
                              leftLabel: error?.leftLabel,
                            }
                          : undefined
                      }
                    />
                  );
                }
                case SurveyQuestionTypeNamesEnum.SURVEY_FREE_TEXT_QUESTION: {
                  const error = fieldState.error as SurveyFreeTextErrorType;

                  return (
                    <FreeTextEditCard
                      renderShowToMentorsOption={!alwaysShowQuestionsToMentor}
                      value={{
                        ...field.value,
                        isMultiline: field.value.multiline,
                        questionTitle: field.value.text,
                        showToMentors: field.value.visibleToMentors,
                      }}
                      onChange={(value) =>
                        field.onChange({
                          __typename: field.value.__typename,
                          ...value,
                          multiline: value.isMultiline,
                          text: value.questionTitle,
                          visibleToMentors: alwaysShowQuestionsToMentor || value.visibleToMentors,
                        })
                      }
                      isOpen={openCardIndex === index}
                      onToggleOpenState={() =>
                        setOpenCardIndex(() => (openCardIndex === index ? undefined : index))
                      }
                      dragProps={{ setActivatorNodeRef, listeners, dragActive }}
                      onDelete={() => replace(fields.filter((f) => f.id !== q.id))}
                      errors={error ? { questionTitle: error?.text } : undefined}
                    />
                  );
                }
                default:
                  Sentry.captureException(new Error('Survey question without __typename'), {
                    extra: {
                      stepId: stepId,
                      questionId: q.id,
                    },
                  });
                  return <> </>;
              }
            }}
          />
        )}
      </DragVerticalList>
      <FloatingOptions
        isOpen={isSelectingQuestionType}
        onClose={() => setIsSelectingQuestionType(false)}
        placement="bottom-start"
        widthClassName="w-[600px] lg:w-[640px] 2xl:w-[720px]"
        wrappedComponent={
          <AddCard
            isEnabled
            onClick={() => setIsSelectingQuestionType(true)}
            label={t('edit.add-question')}
            className="mt-3"
          />
        }
        options={[
          {
            label: t('question-types.free-text.name'),
            left: <TIcon className="text-text-01" />,
            onClick: () => {
              setOpenCardIndex(fields.length);
              append({
                __typename: SurveyQuestionTypeNamesEnum.SURVEY_FREE_TEXT_QUESTION,
                multiline: true,
                text: undefined,
                required: false,
                visibleToMentors: alwaysShowQuestionsToMentor,
              });
              setIsSelectingQuestionType(false);
            },
          },
          {
            label: t('question-types.choice.name'),
            left: <ListUnordered3Icon className="text-text-01" />,
            onClick: () => {
              setOpenCardIndex(fields.length);
              append({
                __typename: SurveyQuestionTypeNamesEnum.SURVEY_CHOICE_QUESTION,
                answers: [],
                multiple: false,
                required: false,
                text: undefined,
                visibleToMentors: alwaysShowQuestionsToMentor,
              });
              setIsSelectingQuestionType(false);
            },
          },
          {
            label: t('question-types.scale.name'),
            left: <ThermometerIcon className="text-text-01" />,
            onClick: () => {
              setOpenCardIndex(fields.length);
              append({
                __typename: SurveyQuestionTypeNamesEnum.SURVEY_SCALE_QUESTION,
                text: undefined,
                leftLabel: undefined,
                rightLabel: undefined,
                required: false,
                visibleToMentors: alwaysShowQuestionsToMentor,
              });
              setIsSelectingQuestionType(false);
            },
          },
        ]}
      />
    </div>
  );
};
