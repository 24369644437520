import { useQuery } from '@tanstack/react-query';
import { ArticleSuggestionCard } from './article-suggestion-card';
import { Article } from '@stellar-lms-frontend/lms-graphql';
import { ArticleLoading, Heading3, MagnifyingLight } from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';

const MIN_SEARCH_LENGTH = 3;
const article_SEARCH_QUERY_KEY = 'ARTICLE_SEARCH_QUERY';

export const SuggestedArticles = ({
  query,
  onSelectArticle,
  onDeselectArticle,
  findArticles,
}: {
  query: string;
  findArticles: (query: string) => Promise<Article[] | undefined>;
  onSelectArticle: (article: Article) => void;
  onDeselectArticle: (article: Article) => void;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'block-step-view.article-ai-sidebar' });

  const {
    isLoading,
    fetchStatus,
    data: articleSuggestions,
  } = useQuery([article_SEARCH_QUERY_KEY, query.trim()], () => findArticles(query.trim()), {
    enabled: query.trim().length > MIN_SEARCH_LENGTH,
    refetchOnWindowFocus: false,
  });

  // Rendering
  if (articleSuggestions && articleSuggestions.length > 0) {
    return (
      <>
        {articleSuggestions.map((article) => (
          <ArticleSuggestionCard
            key={article.url}
            article={article}
            onDeselectArticle={onDeselectArticle}
            onSelectArticle={onSelectArticle}
          />
        ))}
      </>
    );
  } else if (fetchStatus !== 'idle' && isLoading) {
    return (
      <>
        {[...Array(10)].map((_, index) => (
          <ArticleLoading key={index} />
        ))}
      </>
    );
  } else {
    return (
      <div className="mt-[100px] flex flex-col items-center space-y-6">
        <MagnifyingLight />
        <Heading3 className="text-text-01">{t('empty.title')}</Heading3>
        <p className="type-body text-text-02">{t('empty.description')}</p>
      </div>
    );
  }
};
