import { GraphQLClient } from 'graphql-request';
import { GraphQLClientResponse } from 'graphql-request/build/esm/types';
import { getGraphQLApi, getOnsophicApi } from './api';

export function getGraphQLClient(apiUrl: string, context: string, onsophicApiUrl: string) {
  return new GraphQLClient(apiUrl, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'stellar-lms-context': context,
    },
    responseMiddleware: resMiddleware(onsophicApiUrl),
  });
}

const resMiddleware = (apiUrl: string) => (res: any) => {
  const response = res.response as GraphQLClientResponse<unknown> | Error;
  if (response && 'errors' in response && response.errors) {
    if (response.errors.some((e) => e.extensions['code'] === 401)) {
      window.location.href =
        apiUrl + '/user/auth0/login?redirectUrl=' + encodeURIComponent(window.location.href);
    }
  }
};

export const defaultGraphqlClient = new GraphQLClient(getGraphQLApi(), {
  credentials: 'include',
  mode: 'cors',
  responseMiddleware: resMiddleware(getOnsophicApi()),
});
