import {
  BookIcon,
  BookIconFilled,
  BurgerMenu,
  BurgerMenuFilled,
  ButtonConfig,
  ChevronDownIcon,
  NotificationIcon,
  NotificationIconFilled,
  NotificationUnreadIcon,
  NotificationUnreadIconFilled,
  ProfileIcon,
  ProfileIconFilled,
  Sidebar,
  UIShell,
  UserFilledIcon,
  UserIcon,
  RealUIShellProps,
  Heading3,
  NavButton,
  SecondaryButton,
  Edit3Icon,
  MoreVertIcon,
  LogoLoader,
} from '@stellar-lms-frontend/ui-components';

import { Dispatch, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ChangeState,
  VoidFunc,
  hasPermission,
  isMatch,
  navigation,
  useNavigateExternal,
  useScreenType,
} from '@stellar-lms-frontend/common-utils';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { useLocation, useParams } from 'react-router-dom';
import {
  CurrentUser,
  Course,
  CurrentCompany,
  ScormPackage,
  ScormVersion,
} from '@stellar-lms-frontend/lms-graphql';
import { OverviewNavButton } from './components/overview-nav-button';
import { JourneyNavButton } from './components/journey-nav-button';
import { ActionsNavButton } from './components/actions-nav-button';
import { MembersNavButton } from './components/members-nav-button';
import { SurveysNavButton } from './components/surveys-nav-button';
import { ThemableNavEntriesFunc } from '@stellar-lms-frontend/ui-components';
import { AppLogo } from '../app-logo/app-logo';
import * as More from '../more';
import { CourseContextMenu } from '../course-context-menu/course-context-menu';
import * as Notifications from '../notifications';
import {
  ADMINISTRATION_URI,
  LEARNER_URI,
  MORE_URI,
  NOTIFICATIONS_URI,
  ORGANIZATION_URI,
  PROFILE_URI,
  getDesignerCourseActionsRoute,
  getDesignerCourseMembersRoute,
  getDesignerCourseRoute,
  getDesignerCourseSurveysRoute,
  getDesignerModulesRoute,
  getLearnerCourseActionsRoute,
  getLearnerCourseDashboardRoute,
  getLearnerCourseModulesRoute,
  getMentorCourseActionsRoute,
  getMentorCourseDashboardRoute,
  getMentorCourseMembersRoute,
  getMentorCourseModulesRoute,
  getMentorCourseSurveysRoute,
} from '../constants';
import { CompanySelector } from '../company-selector/company-selector';
import { GraphQLClient } from 'graphql-request';
import { SupportedLanguagesOptions } from '../types/types';
import { TrialBadge } from './components/trial-badge';

type Actions = {
  unreadNotificationsCount: () => number;
  logout: VoidFunc;
  deleteCourse: Dispatch<string>;
  duplicateCourse: Dispatch<string>;
  createScormPackage: (courseId: string, version: ScormVersion) => Promise<ScormPackage>;
  getScormPackage: (courseId: string, scormPackageId: string) => Promise<ScormPackage>;
  fetchNotifications: (from?: Date) => Promise<Notifications.Notification[]>;
  handleNotificationClick: (notifications: Notifications.Notification) => Promise<void>;
  markTipAsViewed: (id: string) => void;
};
export type BlueUIShellProps = RealUIShellProps & {
  pageTitle?: ReactNode;
  graphQLClient: GraphQLClient;
  actions: Actions;
  currentUser?: CurrentUser;
  currentCompany?: CurrentCompany;
  currentCourse?: Course;
  hasCompanySelector?: boolean;
  supportedLanguages: SupportedLanguagesOptions;
  defaultLanguage: string;
};

export const BlueUIShell: React.FC<BlueUIShellProps> = ({
  navHeaderTitle,
  subNavTitle,
  subNavSubtitle,
  children,
  showBottomNav = true,
  autoScrollTop = true,
  leftSideBar,
  rightSideBar,
  useContentContainer = true,
  leftButton,
  rightButton,
  scrollOnDesktop = false,
  graphQLClient,
  actions,
  currentUser,
  currentCompany,
  currentCourse,
  defaultLanguage,
  supportedLanguages,
  headerTheme,
}) => {
  const { t: tNotifications } = useTranslation('translation', { keyPrefix: 'notifications' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const { t: tNavigation } = useTranslation('translation', { keyPrefix: 'navigation' });

  const fetchUnreadNotification = useQuery(
    ['UNREAD_NOTIFICATIONS'], // CLEANUP
    () => {
      return actions.unreadNotificationsCount(); // Should be role agnostic, might be already in the backend
    },
    {
      refetchInterval: 60000,
    }
  );

  const { isTailwindLg } = useScreenType();
  const { navigateExternal } = useNavigateExternal();
  const { courseId } = useParams();

  const [rightPrimary, setRightPrimary] = useState<ButtonConfig>();
  const [leftPrimary, setLeftPrimary] = useState<ButtonConfig>();
  const [title, setTitle] = useState<string>('');
  const navigate = useNavigate();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const onCloseNotifications = useCallback(() => setIsNotificationOpen(false), []);

  const location = useLocation();
  const isMentor = location.pathname.startsWith('/mentor/');
  const isDesigner = window.location.pathname.startsWith('/designer/');

  const isSubPage = !!courseId;

  const changeButtons = useCallback((changeState: ChangeState) => {
    setLeftPrimary(changeState.leftPrimary);
    setRightPrimary(changeState.rightPrimary);
    setTitle(changeState.title ?? '');
  }, []);

  const unsubscribe = useMemo(() => {
    changeButtons(navigation.getChangeState());
    return navigation.subscribe(changeButtons);
  }, [changeButtons]);

  useEffect(() => {
    return () => {
      unsubscribe();
    };
  }, [unsubscribe]);

  const renderFilledNotificationIcon = useCallback(() => {
    if ((fetchUnreadNotification.data ?? 0) > 0) {
      return NotificationUnreadIconFilled;
    } else {
      return NotificationIconFilled;
    }
  }, [fetchUnreadNotification.data]);

  const renderUnfilledNotificationIcon = useCallback(() => {
    if ((fetchUnreadNotification.data ?? 0) > 0) {
      return NotificationUnreadIcon;
    } else {
      return NotificationIcon;
    }
  }, [fetchUnreadNotification.data]);

  const leftTopNavEntries: ThemableNavEntriesFunc = (theme) => [
    <AppLogo
      key="app-logo"
      className="h-6 cursor-pointer"
      type="icon"
      companyStyle={currentCompany?.styleProperties ?? undefined}
      theme={theme}
    />,
    <TrialBadge
      key="trial-badge"
      markTipAsViewed={actions.markTipAsViewed}
    />,
  ];

  const centerTopNavEntries: ThemableNavEntriesFunc = (theme) => {
    return currentCourse?.id === undefined
      ? []
      : // FUTURE: Instead of basing ourselves on the role we can look at the permissions and only show some links based on the permission.
      // .       This will also make it so much easier to render the buttons in the header and just pass the course permissions (or fetch them in the header)
      isMentor
      ? [
          <OverviewNavButton
            key="overview-nav-button"
            theme={theme}
            title={tNavigation('overview')}
            url={getMentorCourseDashboardRoute(currentCourse?.id)}
          />,
          <JourneyNavButton
            key="journey-nav-button"
            theme={theme}
            title={tNavigation('journey')}
            url={getMentorCourseModulesRoute(currentCourse?.id)}
          />,
          <ActionsNavButton
            key="actions-nav-button"
            theme={theme}
            title={tNavigation('actions')}
            url={getMentorCourseActionsRoute(currentCourse?.id)}
          />,
          <MembersNavButton
            key="members-nav-button"
            theme={theme}
            title={tNavigation('members')}
            url={getMentorCourseMembersRoute(currentCourse?.id)}
          />,
          <SurveysNavButton
            key="surveys-nav-button"
            theme={theme}
            title={tNavigation('surveys')}
            url={getMentorCourseSurveysRoute(currentCourse?.id)}
          />,
        ]
      : isDesigner
      ? [
          <OverviewNavButton
            key="overview-nav-button"
            theme={theme}
            title={tNavigation('overview')}
            url={getDesignerCourseRoute(currentCourse?.id)}
          />,
          <JourneyNavButton
            key="journey-nav-button"
            theme={theme}
            title={tNavigation('journey')}
            url={getDesignerModulesRoute(currentCourse?.id)}
          />,
          <ActionsNavButton
            key="actions-nav-button"
            theme={theme}
            title={tNavigation('actions')}
            url={getDesignerCourseActionsRoute(currentCourse?.id)}
          />,
          <MembersNavButton
            key="members-nav-button"
            theme={theme}
            title={tNavigation('members')}
            url={getDesignerCourseMembersRoute(currentCourse?.id)}
          />,
          <SurveysNavButton
            key="surveys-nav-button"
            theme={theme}
            title={tNavigation('surveys')}
            url={getDesignerCourseSurveysRoute(currentCourse?.id)}
          />,
        ]
      : [
          <OverviewNavButton
            key="overview-nav-button"
            theme={theme}
            title={tNavigation('overview')}
            url={getLearnerCourseDashboardRoute(currentCourse?.id)}
          />,
          <JourneyNavButton
            key="journey-nav-button"
            theme={theme}
            title={tNavigation('journey')}
            url={getLearnerCourseModulesRoute(currentCourse?.id)}
          />,
          <ActionsNavButton
            key="actions-nav-button"
            theme={theme}
            title={tNavigation('actions')}
            url={getLearnerCourseActionsRoute(currentCourse?.id)}
          />,
        ];
  };

  const rightTopNavEntries: ThemableNavEntriesFunc = (theme) => [
    ...(currentCourse && hasPermission(currentCourse.permissions, 'update')
      ? [
          ...(isSubPage
            ? [
                <SecondaryButton
                  key="edit-button"
                  leftIcon={<Edit3Icon className="h-6 w-6" />}
                  label={tGeneral('edit')}
                  theme={theme === 'blue' ? 'white' : 'blue'}
                  buttonStyle="small"
                  onClick={() =>
                    navigateExternal(getDesignerModulesRoute(currentCourse?.id) + '?state=edit')
                  }
                />,
                <CourseContextMenu
                  currentCourse={currentCourse}
                  wrappedComponent={
                    <NavButton
                      theme={theme}
                      navEntry={{
                        filledIcon: [MoreVertIcon],
                        icon: [MoreVertIcon],
                      }}
                      type="icon"
                    />
                  }
                  actions={{
                    deleteCourse: actions.deleteCourse,
                    duplicateCourse: actions.duplicateCourse,
                    getScormPackage: actions.getScormPackage,
                    createScormPackage: actions.createScormPackage,
                  }}
                />,
              ]
            : []),
        ]
      : []),
    ...(!isSubPage
      ? [
          <CompanySelector
            key="company-selector"
            client={graphQLClient}
            color={theme === 'blue' ? 'dark-blue' : 'light-blue'}
            defaultLanguage={defaultLanguage}
            supportedLanguages={supportedLanguages}
          />,
          <NavButton
            key="notifications-button"
            theme={theme}
            navEntry={{
              title: tNavigation('notifications'),
              action: () => setIsNotificationOpen(true),
              filledIcon: [renderFilledNotificationIcon()],
              icon: [renderUnfilledNotificationIcon()],
              isActive: isMatch(NOTIFICATIONS_URI),
            }}
            type={'icon'}
          />,
          <More.More
            key="more-button"
            type={'box'}
            title={tNavigation('more')}
            currentUser={currentUser}
            currentCompany={currentCompany}
            actions={{
              logout: actions.logout,
            }}
            wrappedComponent={(clickAction) => (
              <NavButton
                theme={theme}
                navEntry={{
                  action: clickAction,
                  title: tNavigation('profile'),
                  filledIcon: [ProfileIconFilled],
                  icon: [ProfileIcon, ChevronDownIcon],
                  isActive:
                    isMatch(PROFILE_URI) ||
                    isMatch(ORGANIZATION_URI) ||
                    isMatch(ADMINISTRATION_URI),
                }}
                type="icon"
              />
            )}
          />,
        ]
      : []),
  ];

  const mobileNavEntries = [
    ...(isMentor
      ? [
          <OverviewNavButton
            key="overview-bottom-nav-button"
            theme={'white'}
            title={tNavigation('overview')}
            url={getMentorCourseDashboardRoute(currentCourse?.id)}
          />,
          <NavButton
            key="learners-bottom-nav-button"
            theme={'white'}
            navEntry={{
              title: tNavigation('members'),
              filledIcon: [UserFilledIcon],
              icon: [UserIcon],
              action: () => navigateExternal(getMentorCourseMembersRoute(currentCourse?.id)),
              isActive: isMatch(getMentorCourseMembersRoute(currentCourse?.id), 'contains'),
            }}
            type={'icon-text'}
          />,
        ]
      : isDesigner
      ? [
          <OverviewNavButton
            key="overview-bottom-nav-button"
            theme={'white'}
            title={tNavigation('overview')}
            url={getDesignerCourseRoute(currentCourse?.id)}
          />,
          <NavButton
            key="learners-bottom-nav-button"
            theme={'white'}
            navEntry={{
              title: tNavigation('members'),
              filledIcon: [UserFilledIcon],
              icon: [UserIcon],
              action: () => navigateExternal(getDesignerCourseMembersRoute(currentCourse?.id)),
              isActive: isMatch(getDesignerCourseMembersRoute(currentCourse?.id), 'contains'),
            }}
            type={'icon-text'}
          />,
        ]
      : [
          <NavButton
            key="programmes-bottom-nav-button"
            theme={'white'}
            navEntry={{
              title: tNavigation('journey'),
              filledIcon: [BookIconFilled],
              icon: [BookIcon],
              action: () => navigate('/'),
              isActive: isMatch(LEARNER_URI + '/'), //TODO || isMatch(LEARNER_URI) || isMatch(LEARNER_URI + PROGRAMME_URI, 'contains'),
            }}
            type={'icon-text'}
          />,
        ]),
    <NavButton
      key="notifications-bottom-nav-button"
      theme={'white'}
      navEntry={{
        title: tNavigation('notifications'),
        filledIcon: [renderFilledNotificationIcon()],
        icon: [renderUnfilledNotificationIcon()],
        action: () => navigate(NOTIFICATIONS_URI),
        isActive: isMatch(NOTIFICATIONS_URI),
      }}
      type={'icon-text'}
    />,
    <NavButton
      key="more-bottom-nav-button"
      theme={'white'}
      navEntry={{
        title: tNavigation('more'),
        filledIcon: [BurgerMenuFilled],
        icon: [BurgerMenu],
        action: () => navigateExternal(MORE_URI),
        isActive: isMatch(MORE_URI) || isMatch(PROFILE_URI),
      }}
      type={'icon-text'}
    />,
  ];

  useEffect(() => {
    const reactNativeWebView = window.ReactNativeWebView;
    if (reactNativeWebView) {
      reactNativeWebView.postMessage(
        JSON.stringify({
          header_background: '#2551DA',
          header_style: 'light',
          footer_background: '#FFFFFF',
        })
      );
    }
  }, []);

  // TODO go to login?
  if (!currentUser) {
    return <LogoLoader />;
  }

  return (
    <>
      <UIShell
        headerTheme={headerTheme}
        navHeaderTitle={
          navHeaderTitle || <span className="line-clamp-3">{currentCourse?.title ?? ''}</span>
        }
        subNavTitle={subNavTitle || title}
        subNavSubtitle={subNavSubtitle}
        autoScrollTop={autoScrollTop}
        bottomNavEntries={mobileNavEntries}
        leftTopNavEntries={leftTopNavEntries}
        centerTopNavEntries={centerTopNavEntries}
        rightTopNavEntries={rightTopNavEntries}
        showBottomNav={showBottomNav}
        leftButton={leftPrimary ?? leftButton}
        rightButton={rightPrimary ?? rightButton}
        leftSideBar={leftSideBar}
        rightSideBar={rightSideBar}
        useContentContainer={useContentContainer}
        scrollOnDesktop={scrollOnDesktop}
      >
        {children}
      </UIShell>
      <Sidebar
        header={<Heading3>{tNotifications('title')}</Heading3>}
        contentClassName="max-w-[600px]"
        isOpen={isNotificationOpen && !isMatch(LEARNER_URI + NOTIFICATIONS_URI) && isTailwindLg}
        position={'right'}
        onClose={onCloseNotifications}
      >
        <Notifications.NotificationComponent
          fetchNotificationFunc={actions.fetchNotifications}
          handleNotificationClick={(notification) => {
            setIsNotificationOpen(false);
            actions.handleNotificationClick(notification);
          }}
        />
      </Sidebar>
    </>
  );
};
