import { Controller, useForm } from 'react-hook-form';

import {
  Sidebar,
  Dropdown,
  PrimaryButton,
  LinkButton,
  ChevronLeftIcon,
  ChevronRightIcon,
  MoreVertIcon,
  FloatingOptions,
  FloatingOptionsOption,
  TrashIcon,
  InputAutoComplete,
  Suggestion,
} from '@stellar-lms-frontend/ui-components';
import { useCallback, useEffect, useState } from 'react';
import { AccessTypes } from './access-cell';

export type EditLearnerFormData = {
  access: AccessTypes;
  mentor: string;
};

export type EditLearnerSidebarI18N = {
  forms: {
    access: {
      label: string;
      options: {
        author: string;
        collaborator: string;
        learner: string;
      };
    };
    mentor: {
      label: string;
      emptyLabel: string;
    };
  };
  buttons: {
    save: string;
    cancel: string;
  };
  menu: {
    delete: string;
  };
};

type Member = {
  id: string;
  name: string;
};

export type EditLearnerSidebarProps = {
  i18n: EditLearnerSidebarI18N;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: EditLearnerFormData) => void;
  onDelete: () => void;
  onNavigateLeft: () => void;
  onNavigateRight: () => void;
  learner: EditLearnerFormData;
  user: {
    id: string;
    name: string;
    mentor?: {
      id: string;
      name: string;
    };
  };
  membersList: Member[];
};

export const EditLearnerSidebar: React.FC<EditLearnerSidebarProps> = ({
  i18n,
  isOpen,
  onClose,
  onSubmit,
  onDelete,
  onNavigateLeft,
  onNavigateRight,
  learner,
  user,
  membersList,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { isValid },
  } = useForm<EditLearnerFormData>({
    mode: 'all',
    defaultValues: learner,
  });

  useEffect(() => {
    reset(learner);
  }, [reset, learner]);

  const [isOptionMenuOpen, setIsOptionMenuOpen] = useState(false);
  const [filteredMemberList, setFilteredMemberList] = useState(membersList);

  const menuOptions: FloatingOptionsOption[] = [
    {
      label: i18n.menu.delete,
      onClick: () => {
        onDelete();
        setIsOptionMenuOpen(false);
        onClose();
      },
      className: 'text-negative-01',
      left: <TrashIcon className="h-4 w-4" />,
    },
  ];

  const selectedAccesss = watch('access');

  useEffect(() => {
    setFilteredMemberList(membersList.filter((member) => user.id !== member.id));
  }, [membersList, user.id]);

  useEffect(() => {
    if (selectedAccesss !== 'learner') {
      setValue('mentor', '');
    }
  }, [selectedAccesss, setValue]);

  const renderMentorField = useCallback(() => {
    if (selectedAccesss === 'learner') {
      return (
        <Controller
          control={control}
          name="mentor"
          render={({ field }) => (
            <InputAutoComplete
              emptyLabel={i18n.forms.mentor.emptyLabel}
              suggestions={filteredMemberList.map((member) => ({
                id: member.id,
                label: member.name,
              }))}
              defaultSuggestion={
                user.mentor ? { id: user.mentor?.id, label: user.mentor?.name } : undefined
              }
              label={i18n.forms.mentor.label}
              onChangeInput={() => field.onChange(undefined)}
              onSuggestionSelected={(s) => field.onChange(s.id)}
              renderOption={(suggestion: Suggestion) => {
                return {
                  label: suggestion.label,
                  className: 'text-text-02',
                };
              }}
            />
          )}
        />
      );
    }
    return null;
  }, [
    control,
    filteredMemberList,
    i18n.forms.mentor.emptyLabel,
    i18n.forms.mentor.label,
    selectedAccesss,
    user.mentor,
  ]);

  return (
    <Sidebar
      isOpen={isOpen}
      position={'right'}
      onClose={onClose}
      contentClassName="md:max-w-[600px] md:w-[60%] font-lexend"
      hasBackdrop={false}
      buttons={{
        hasDivider: false,
        buttons: [
          <PrimaryButton
            onClick={handleSubmit(onSubmit)}
            label={i18n.buttons.save}
            key={1}
            enabled={isValid}
          />,
          <LinkButton
            label={i18n.buttons.cancel}
            key={2}
            onClick={onClose}
          />,
        ],
      }}
      header={
        <div className="flex items-center justify-between pr-4">
          <div className="flex shrink-0 gap-2">
            <button onClick={onNavigateLeft}>
              <ChevronLeftIcon className="text-text-02 h-8 w-8" />
            </button>
            <button onClick={onNavigateRight}>
              <ChevronRightIcon className="text-text-02 h-8 w-8" />
            </button>
          </div>
          <FloatingOptions
            wrappedComponent={
              <button
                className="h-fit shrink-0"
                onClick={() => setIsOptionMenuOpen(!isOptionMenuOpen)}
              >
                <MoreVertIcon className="text-text-02 mt-1 h-4 w-4 shrink-0" />
              </button>
            }
            isOpen={isOptionMenuOpen}
            onClose={() => setIsOptionMenuOpen(false)}
            options={menuOptions}
          />
        </div>
      }
    >
      <div className="space-y-8">
        <h2 className="text-text-04 type-headline-2 min-h-[36px] break-words">{user.name}</h2>
        <form className="space-y-6">
          <Dropdown
            htmlId={''}
            {...register('access')}
            label={i18n.forms.access.label}
          >
            <option value="author">{i18n.forms.access.options.author}</option>
            <option value="collaborator">{i18n.forms.access.options.collaborator}</option>
            <option value="learner">{i18n.forms.access.options.learner}</option>
          </Dropdown>
          {renderMentorField()}
        </form>
      </div>
    </Sidebar>
  );
};
