import { LearningActivityUtils } from '@stellar-lms-frontend/common-utils';
import { LearningActivityOutline } from '@stellar-lms-frontend/lms-graphql';
import { ArrowRightCircleIcon, ToolTip } from '@stellar-lms-frontend/ui-components';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export type NextLearningActivityButtonI18N = {
  next: string;
  activityAvailableWhenTooltip?: (length: number, mandatoryPrereqs: string) => string;
  activityAvailableOnTooltip?: (date: string) => string;
};

export type NextLearningActivityButtonProps = {
  i18n: NextLearningActivityButtonI18N;
  learningActivity?: LearningActivityOutline;
  className?: string;
};

export const NextLearningActivityButton: React.FC<NextLearningActivityButtonProps> = ({
  learningActivity,
  i18n,
  className = '',
}) => {
  const navigate = useNavigate();
  const isEnabled = !learningActivity?.locked;

  const onClick = useCallback(() => {
    navigate(`../learning-activity/${learningActivity?.id}`);
  }, [learningActivity?.id, navigate]);

  if (!learningActivity) return null;

  return (
    <ToolTip
      isEnabled={!!learningActivity.locked}
      placement="top-start"
      floatingOffset={4}
      color="dark"
      size="small"
      wrappedComponent={
        <div
          role="button"
          className={`${className} ${
            isEnabled ? 'cursor-pointer' : 'cursor-default'
          } font-lexend flex items-center gap-4`}
          onClick={isEnabled ? onClick : undefined}
        >
          <ArrowRightCircleIcon className="text-border-03 h-6 w-6" />
          <div>
            <p className="text-text-02 mb-1 text-sm font-medium">{i18n.next}</p>
            <p className="text-text-01 font-medium">{learningActivity.title}</p>
          </div>
        </div>
      }
    >
      {i18n.activityAvailableWhenTooltip &&
        i18n.activityAvailableOnTooltip &&
        LearningActivityUtils.getTooltipForLockedLearningActivity(
          learningActivity,
          i18n.activityAvailableWhenTooltip,
          i18n.activityAvailableOnTooltip
        )}
    </ToolTip>
  );
};
