import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { useState } from 'react';
import { MoreVertIcon } from '../../icons';
import { FloatingOptions, FloatingOptionsOption } from '../../wrappers';
import { BaseCard, ColorType } from '../base-card/base-card';
import { DragHandler } from '../../drag-handler/drag-handler';

type WithChildren = {
  children: React.ReactNode;
  label?: never;
};
type WithoutChildren = {
  label: string;
  children?: never;
};

export type ListCardProps = {
  id: string;
  isHighlighted?: boolean;
  dragProps?: {
    setActivatorNodeRef: (element: HTMLElement | null) => void;
    listeners?: SyntheticListenerMap;
  };
  options?: FloatingOptionsOption[];
  onClick?: (id: string) => void;
  color?: ColorType;
} & (WithChildren | WithoutChildren);

export const ListCard: React.FC<ListCardProps> = ({
  id,
  isHighlighted,
  label,
  dragProps,
  onClick,
  options,
  children,
  color = 'default',
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <BaseCard
      htmlId={id}
      color={color}
      isHighlighted={isHighlighted}
      className={`font-lexend w-full`}
      onClick={onClick ? () => onClick(id) : undefined}
      childrenClassName={`flex px-4 py-5 w-full gap-2 justify-between items-center`}
    >
      {dragProps && (
        <DragHandler
          className="shrink-0"
          {...dragProps}
        />
      )}
      <div className="grow">
        {!!children && children}
        {!children && <span className="min-h-[20px]">{label}</span>}
      </div>
      <div className="shrink-0">
        {options && options.length > 0 && (
          <FloatingOptions
            wrappedComponent={
              <div
                role="button"
                className="p-[7px]"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsMenuOpen(true);
                  document.body.click(); // Trick to trigger the other onClose as we are stopping the propagation
                }}
              >
                <MoreVertIcon />
              </div>
            }
            isOpen={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
            options={options.map((el) => ({
              ...el,
              onClick: (e) => {
                e.stopPropagation();
                el.onClick?.(e);
                setIsMenuOpen(false);
              },
            }))}
          />
        )}
      </div>
    </BaseCard>
  );
};
