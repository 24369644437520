import React from 'react';
import { HeaderData } from './header-plugin';
import { Alignment, Levels } from '../types';
import { ContentEditable } from '../../components/content-editable';

interface HeaderComponentProps {
  data: HeaderData;
  onChange: (newData: HeaderData) => void;
  isReadOnly: boolean;
  level: Levels;
}

export const HeaderComponent: React.FC<HeaderComponentProps> = ({
  data,
  onChange,
  isReadOnly,
  level,
}) => {
  return (
    <ContentEditable
      onChange={(value) => {
        onChange({
          ...data,
          text: value,
        });
      }}
      value={data.text}
      contentEditable={!isReadOnly}
      className={`
        ${level === Levels.ONE ? 'text-3xl' : ''}
        ${level === Levels.TWO ? 'text-2xl' : ''}
        ${level === Levels.THREE ? 'text-xl' : ''}
        ${level === Levels.FOUR ? 'text-lg' : ''}
        ${data.alignment === Alignment.LEFT ? 'text-left' : ''}
        ${data.alignment === Alignment.CENTER ? 'text-center' : ''}
        ${data.alignment === Alignment.RIGHT ? 'text-right' : ''}
        ${data.alignment === Alignment.JUSTIFY ? 'text-justify' : ''}
        text-text-01 mb-2 mt-4 outline-none`}
    />
  );
};
