import {
  ContentContainer,
  FlexTable,
  LinkButton,
  ListFilteringBar,
  LogoLoader,
  PrimaryButton,
  ScrollContainer,
  useListFilteringOptions,
} from '@stellar-lms-frontend/ui-components';
import { GraphQLClient } from 'graphql-request';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  cancelCurrentSubscription,
  deleteCompany,
  getCompanies,
  updateDocumentConfiguration,
  updateSubscription,
} from './companies-tab.api';
import { useTranslation } from 'react-i18next';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  ColumnDef,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX } from '@fortawesome/pro-regular-svg-icons';
import { SubscriptionCell } from './subscription-cell';
import Fuse from 'fuse.js';
import {
  useCurrentCompany,
  createCompany,
  SubscriptionType,
  MutationUpdateSubscriptionArgs,
  MutationCancelCurrentSubscriptionArgs,
  DeleteCompanyMutationVariables,
  UpdateDocumentConfigurationDocument,
  MutationUpdateDocumentConfigurationArgs,
} from '@stellar-lms-frontend/lms-graphql';
import { AddCompanyModal } from '../../../add-company-modal/add-company-modal';
import { SupportedLanguagesOptions } from '../../../types/types';
import { toast } from 'react-toastify';
import { transformOutgoingLanguageCode } from '../../../organization-page/components/users-tab/utils/transformers';
import { OptionsMenuCell } from './options-menu-cell';
import { UpdateSubscriptionFormData, UpdateSubscriptionModal } from './update-subscription-modal';
import moment from 'moment';
import { ConfirmationModal } from '../../../confirmation-modal/confirmation-modal';

const QUERY_KEY = 'COMPANIES';
const DEFAULT_PAGE_SIZE = 20;

export type CompaniesTabProps = {
  graphQLClient: GraphQLClient;
  defaultLanguage: string;
  supportedLanguages: SupportedLanguagesOptions;
};

enum TableColumns {
  NAME = 'NAME',
  CREATED_AT = 'CREATED_AT',
  MEMBER_COUNT = 'MEMBER_COUNT',
  JOURNEY_COUNT = 'JOURNEY_COUNT',
  SUBSCRIPTION = 'SUBSCRIPTION',
  SUBSCRIPTION_END_DATE = 'SUBSCRIPTION_END_DATE',
  INSIGHTS = 'SHOW_INSIGHTS',
  DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD',
}

export type CompanyTableRowShape = {
  id: string;
  name: string;
  memberCount: number;
  journeyCount: number;
  createdAt: Date | undefined;
  subscription:
    | {
        id: string;
        status: boolean;
        type: SubscriptionType;
        startDate: Date | undefined;
        endDate: Date | undefined;
      }
    | undefined;
  insights: boolean;
  documentUpload: boolean;
};

const columnHelper = createColumnHelper<CompanyTableRowShape>();

const mapToSubscriptionType = (
  license: UpdateSubscriptionFormData['license'],
): SubscriptionType => {
  switch (license) {
    case 'trial':
      return SubscriptionType.Trial;
    case 'stellar_labs_managed':
      return SubscriptionType.StellarLabsManaged;
    case 'internal':
      return SubscriptionType.Internal;
  }
};

export const CompaniesTab: React.FC<CompaniesTabProps> = ({
  graphQLClient,
  defaultLanguage,
  supportedLanguages,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'administration.company-tab' });
  const { t: tHeaders } = useTranslation('translation', {
    keyPrefix: 'administration.company-tab.headers',
  });
  const [isCreateCompanyModalOpen, setIsCreateCompanyModalOpen] = useState(false);
  const [isUpdateSubscriptionModalOpen, setIsUpdateSubscriptionModalOpen] = useState(false);
  const [editingCompany, setEditingCompany] = useState<undefined | CompanyTableRowShape>(undefined);
  const [deleteCompanyId, setDeleteCompanyId] = useState<undefined | string>(undefined);
  const { onSelectCompany } = useCurrentCompany(graphQLClient);
  const queryClient = useQueryClient();

  const companySearch = '';

  const options = {
    max: 1000,
    search: companySearch,
  };
  const { data, isLoading } = useQuery(
    [QUERY_KEY],
    () => getCompanies(graphQLClient, { max: options.max, query: options.search }),
    {
      staleTime: 60 * 1000, // 1 minute
      cacheTime: 5 * 60 * 10000, // 5 minutes
    },
  );

  const companies = useMemo<CompanyTableRowShape[]>(
    () =>
      data?.map((company) => {
        return {
          id: company.id,
          name: company.name ?? '',
          createdAt: company.createdAt ? new Date(company.createdAt) : undefined,
          memberCount: company.memberCount ?? 0,
          journeyCount: company.journeyCount ?? 0,
          subscription: company.subscription
            ? {
                id: company.subscription?.id,
                startDate: company.subscription.startDate
                  ? new Date(company.subscription.startDate)
                  : undefined,
                endDate: company.subscription.endDate
                  ? new Date(company.subscription.endDate)
                  : undefined,
                status: company.subscription.active,
                type: company.subscription.type,
              }
            : undefined,
          insights: company.hidePerformanceManagement ? false : true,
          documentUpload: company.documentConfiguration?.enabled ?? false,
        };
      }) ?? [],
    [data],
  );

  const { mutate: createCompanyMutate } = useMutation(
    (data: {
      name: string;
      industry: string;
      language: string;
      license: string;
      trialEndDate: string;
    }) =>
      createCompany(graphQLClient, {
        input: {
          name: data.name,
          industry: data.industry,
          language: transformOutgoingLanguageCode(data.language.toLowerCase()),
          trial: data.license === 'trial',
          subscriptionEndDate: data.license === 'trial' ? data.trialEndDate : undefined,
        },
      }),
    {
      onSuccess: (data) => {
        toast.success(t('add-company.toast.success'));
        setIsCreateCompanyModalOpen(false);
        data.createCompany?.id && onSelectCompany(data.createCompany.id);
      },
    },
  );

  const { mutate: updateSubscriptionMutation } = useMutation(
    (data: MutationUpdateSubscriptionArgs) => {
      return updateSubscription(graphQLClient, data);
    },
    {
      onSuccess: (data) => {
        toast.success(t('update-subscription.toast.success'));
        queryClient.invalidateQueries([QUERY_KEY]);
      },
    },
  );

  const { mutate: setDocumentUploadEnabledMutation } = useMutation(
    (data: MutationUpdateDocumentConfigurationArgs) => {
      return updateDocumentConfiguration(graphQLClient, data);
    },
    {
      onSuccess: (data) => {
        toast.success(t('update-document-configuration.toast.success'));
        queryClient.invalidateQueries([QUERY_KEY]);
      },
    },
  );

  const { mutate: cancelSubscriptionMutation } = useMutation(
    (data: MutationCancelCurrentSubscriptionArgs) => {
      return cancelCurrentSubscription(graphQLClient, data);
    },
    {
      onSuccess: (data) => {
        toast.success(t('cancel-subscription.toast.success'));
        queryClient.invalidateQueries([QUERY_KEY]);
      },
    },
  );

  const { mutate: deleteCompanyMutation } = useMutation(
    (data: DeleteCompanyMutationVariables) => {
      return deleteCompany(graphQLClient, data);
    },
    {
      onSuccess: (data, vars) => {
        toast.success(t('delete-company.toast.success'));
        queryClient.invalidateQueries([QUERY_KEY]);
      },
    },
  );

  const updateCompanySubscription = (data: UpdateSubscriptionFormData) => {
    if (editingCompany?.id) {
      const input = {
        companyId: editingCompany.id,
        input: {
          id: data.id,
          endDate: new Date(data.endDate),
          startDate: new Date(data.startDate),
          type: mapToSubscriptionType(data.license),
        },
      };
      updateSubscriptionMutation(input);
    }
  };

  const companyFilterOptions = useListFilteringOptions<CompanyTableRowShape>({});
  const { searchQuery } = companyFilterOptions.state;

  const filteredCompanies = useMemo(() => {
    let result = companies ?? [];

    // search
    if (companies && searchQuery.length > 0) {
      const fuse = new Fuse(companies, {
        keys: ['name'],
        ignoreLocation: false,
        distance: 100,
        threshold: 0.2,
        minMatchCharLength: Math.min(searchQuery.length, 3),
      });
      result = fuse.search(searchQuery).map((result) => result.item);
    }
    return result;
  }, [companies, searchQuery]);

  const columns = useMemo<ColumnDef<CompanyTableRowShape, any>[]>(
    () => [
      columnHelper.accessor((row) => row.name, {
        id: TableColumns.NAME,
        header: tHeaders('name'),
        minSize: 300,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row.createdAt, {
        id: TableColumns.CREATED_AT,
        header: tHeaders('created_at'),

        cell: (info) => (info.getValue() ? moment(info.getValue()).format('DD/MM/YYYY') : ''),
      }),
      columnHelper.accessor((row) => row.memberCount, {
        id: TableColumns.MEMBER_COUNT,
        header: tHeaders('memberCount'),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row.journeyCount, {
        id: TableColumns.JOURNEY_COUNT,
        header: tHeaders('journeyCount'),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row.subscription, {
        id: TableColumns.SUBSCRIPTION,
        header: tHeaders('subscription'),
        minSize: 200,
        maxSize: 200,
        size: 200,
        cell: (info) => {
          const subscription = info.getValue<CompanyTableRowShape['subscription']>();
          if (subscription) {
            return (
              <SubscriptionCell
                type={subscription.type}
                status={subscription.status}
              />
            );
          }
          return (
            <SubscriptionCell
              type={'UNKNOWN'}
              status={false}
            />
          );
        },
        sortingFn: (a, b, id) => {
          const subA = a.getValue<CompanyTableRowShape['subscription']>(id)?.type ?? 'UNKNOWN';
          const subB = b.getValue<CompanyTableRowShape['subscription']>(id)?.type ?? 'UNKNOWN';
          return subA < subB ? -1 : 1;
        },
      }),
      columnHelper.accessor((row) => row.subscription, {
        id: TableColumns.SUBSCRIPTION_END_DATE,
        header: tHeaders('subscription-end-date'),
        cell: (info) => {
          const subscription = info.getValue<CompanyTableRowShape['subscription']>();
          if (subscription && subscription.endDate) {
            return <span>{subscription.endDate.toLocaleDateString()}</span>;
          }
          return <span />;
        },
      }),
      columnHelper.accessor((row) => row.insights, {
        id: TableColumns.INSIGHTS,
        header: tHeaders('insights'),
        cell: (info) =>
          info.getValue() ? (
            <FontAwesomeIcon
              icon={faCheck}
              className="text-sm"
            />
          ) : (
            <FontAwesomeIcon
              icon={faX}
              className="text-sm"
            />
          ),
      }),
      columnHelper.accessor((row) => row.documentUpload, {
        id: TableColumns.DOCUMENT_UPLOAD,
        header: tHeaders('document-upload'),
        cell: (info) =>
          info.getValue() ? (
            <FontAwesomeIcon
              icon={faCheck}
              className="text-sm ml-center"
            />
          ) : (
            <FontAwesomeIcon
              icon={faX}
              className="text-sm"
            />
          ),
      }),
      {
        id: 'options',
        header: () => null,
        cell: ({ row }) => {
          const company = filteredCompanies?.find((c) => c.id === row.original.id);
          if (!company) return;

          return (
            <OptionsMenuCell
              subscription={company.subscription}
              documentConfigurationEnabled={company.documentUpload}
              onToggleDocumentConfiguration={() => {
                const company = filteredCompanies?.find((c) => c.id === row.original.id);
                if (!company) return;
                setDocumentUploadEnabledMutation({
                  companyId: company.id,
                  documentConfiguration: { enabled: !company.documentUpload },
                });
              }}
              onUpdateSubscription={() => {
                const company = filteredCompanies?.find((c) => c.id === row.original.id);
                if (!company) return;
                setEditingCompany(company);
                setIsUpdateSubscriptionModalOpen(true);
              }}
              onCancelSubscription={() => {
                const company = filteredCompanies?.find((c) => c.id === row.original.id);
                if (!company) return;
                cancelSubscriptionMutation({ companyId: company.id });
              }}
              onDeleteCompany={() => {
                const company = filteredCompanies?.find((c) => c.id === row.original.id);
                if (!company) return;
                setDeleteCompanyId(company.id);
              }}
            />
          );
        },
      },
    ],
    [tHeaders, filteredCompanies, setDocumentUploadEnabledMutation, cancelSubscriptionMutation],
  );

  const table = useReactTable({
    data: filteredCompanies,
    columns,
    initialState: { pagination: { pageSize: DEFAULT_PAGE_SIZE } },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    enableRowSelection: true,
    enableMultiSort: false,
  });

  const renderTable = () => {
    if (isLoading) {
      return <LogoLoader />;
    }

    if ((data?.length ?? 0) > 0) {
      return (
        <>
          <div className="flex h-full grow flex-col space-y-2 overflow-auto">
            <div className="flex flex-row space-x-4">
              <div className="w-full grow">
                <ListFilteringBar
                  options={companyFilterOptions}
                  searchPlaceholder={t('search-input-placeholder')}
                />
              </div>
              <PrimaryButton
                label={t('add-company.button')}
                onClick={() => setIsCreateCompanyModalOpen(true)}
              />
            </div>
            <FlexTable
              rowClick={(row, e) => {
                onSelectCompany(row.original.id);
              }}
              totalRows={data?.length}
              className="text-text-02 select-none"
              i18n={{
                pageSizeSelector: {
                  showing: t('page-size-selector.showing'),
                  of: (count) => t('page-size-selector.of', { count }),
                },
              }}
              table={table}
            />
          </div>
          <AddCompanyModal
            name={companySearch}
            isOpen={isCreateCompanyModalOpen}
            onClose={() => setIsCreateCompanyModalOpen(false)}
            onSubmit={createCompanyMutate}
            defaultLanguage={defaultLanguage}
            supportedLanguages={supportedLanguages}
          />
          {editingCompany?.subscription && (
            <UpdateSubscriptionModal
              subscription={editingCompany.subscription}
              isOpen={isUpdateSubscriptionModalOpen}
              onClose={() => setIsUpdateSubscriptionModalOpen(false)}
              onSubmit={(data) => {
                setIsUpdateSubscriptionModalOpen(false);
                updateCompanySubscription(data);
              }}
            />
          )}
          {deleteCompanyId !== undefined && (
            <ConfirmationModal
              title={t('delete-company.confirmation-modal.title')}
              description={t('delete-company.confirmation-modal.description')}
              buttons={{
                hasShadow: false,
                buttons: [
                  <PrimaryButton
                    key={1}
                    theme="red"
                    label={t('delete-company.confirmation-modal.remove')}
                    onClick={() => {
                      deleteCompanyMutation({ deleteCompanyId });
                      setDeleteCompanyId(undefined);
                    }}
                  />,
                  <LinkButton
                    key={2}
                    buttonStyle="fit"
                    onClick={() => setDeleteCompanyId(undefined)}
                    label={t('delete-company.confirmation-modal.cancel')}
                  />,
                ],
              }}
              isOpen={deleteCompanyId !== undefined}
              onClose={() => setDeleteCompanyId(undefined)}
            />
          )}
        </>
      );
    }
    return <div>No data</div>;
  };

  return (
    <ScrollContainer scrollOnDesktop>
      <ContentContainer>{renderTable()}</ContentContainer>
    </ScrollContainer>
  );
};
