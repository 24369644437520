import {
  Dropdown,
  Form,
  Heading3,
  Input,
  LeftArrowIcon,
  PrimaryButton,
} from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useCallback, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { fetchUserSettings, updateUserProfile, updateUserSettings } from './profile.api';
import { BasicUserSettings } from './types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getLoggedInUser, USER } from '@stellar-lms-frontend/lms-api';
import { useQuery } from '@tanstack/react-query';
import { i18next, useScreenType } from '@stellar-lms-frontend/common-utils';
import { graphQLClient } from '../../lib/graphql';
import { UIShell } from '../shell/ui-shell';
import { supportedLanguages, upperSupportedLanguage } from '../../constants/languages';

export const ProfileOverview = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'profile' });
  const { t: tLanguages } = useTranslation('translation', { keyPrefix: 'languages' });
  const navigate = useNavigate();
  const { isTailwindLg } = useScreenType();

  const spacedRepetitionTimingOptions = useMemo(
    () => [
      { key: 'morning', value: '09:00', text: t('input.spaced-repetition.timing.morning') },
      { key: 'midday', value: '12:00', text: t('input.spaced-repetition.timing.midday') },
      { key: 'afternoon', value: '14:00', text: t('input.spaced-repetition.timing.afternoon') },
      { key: 'afterwork', value: '19:00', text: t('input.spaced-repetition.timing.afterwork') },
    ],
    [t]
  );

  const spacedRepetitionNotificationOptions = useMemo(
    () => [
      {
        key: 'spaced_repetition_notifications_on',
        value: 'on',
        text: t('input.spaced-repetition.notification.on'),
      },
      {
        key: 'spaced_repetition_notifications_off',
        value: 'off',
        text: t('input.spaced-repetition.notification.off'),
      },
    ],
    [t]
  );

  type FormData = {
    name: string;
    email: string;
    language: string;
    spaced_repetition_timing: string;
    spaced_repetition_notification: string;
  };

  const schema = yup.object({
    name: yup.string().required(t('validation.name.required')),
  });

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const { data: user } = useQuery([USER], () => getLoggedInUser(graphQLClient()), {
    onSuccess: (user) => {
      if (user.language !== i18next.language) {
        i18next.changeLanguage(user.language);
      }

      reset({ ...user });
    },
  });

  const savePersonalInformation = useCallback(
    async (data: FormData) => {
      const profile = {
        name: data.name,
        email: data.email,
        language: data.language,
      };

      const settings: BasicUserSettings = {
        enabled: {
          email: true,
          push: false,
        },
        spacedRepetitionContactTime: data.spaced_repetition_timing,
        spacedRepetitionNotifications: data.spaced_repetition_notification === 'on',
      };

      user?.id &&
        Promise.all([
          updateUserProfile(graphQLClient('learner'), {
            userId: user.id,
            userProfileInput: profile,
          }),
          updateUserSettings(graphQLClient(), user.id, settings),
        ])
          .then(() => {
            toast.success(t('update.success'));
            i18next.changeLanguage(profile.language);
            moment.locale(profile.language);
          })
          .catch((error) => {
            toast.error(t('update.failure'));
          });
    },
    [t, user?.id]
  );

  useEffect(() => {
    user?.id &&
      fetchUserSettings(graphQLClient()).then((settings) => {
        const spacedRepetitionNotificationTime = settings.spacedRepetitionContactTime;
        if (
          spacedRepetitionTimingOptions.filter(
            (option) => option.value === spacedRepetitionNotificationTime
          ).length > 0
        ) {
          setValue('spaced_repetition_timing', spacedRepetitionNotificationTime ?? '9:00');
        }

        setValue(
          'spaced_repetition_notification',
          settings.spacedRepetitionNotifications ? 'on' : 'off'
        );
      });
  }, [setValue, spacedRepetitionTimingOptions, user?.id]);

  const renderContent = () => {
    return (
      <div className="max-w-content w-full">
        <Heading3>{t('personal-information')}</Heading3>
        <Form
          id="profile-form"
          className="mt-4 space-y-6"
        >
          {user && !isAliasedEmail(user.email) && (
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t('input.email.label')}
                  htmlId="email"
                  autoComplete="email"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={errors.email?.message}
                  enabled={false}
                />
              )}
            />
          )}
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={t('input.name.label')}
                htmlId="name"
                autoComplete="name"
                required
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={errors.name?.message}
              />
            )}
          />
          <Dropdown
            label={t('input.language.label')}
            htmlId="language"
            {...register('language')}
          >
            {supportedLanguages.map((language) => (
              // CLEANUP mix between uppercase and lowercase for languages
              <option
                key={'language_' + language}
                value={language}
              >
                {tLanguages(upperSupportedLanguage(language))}
              </option>
            ))}
          </Dropdown>
          <Heading3 className=" pt-4">{t('notifications')}</Heading3>
          <Dropdown
            label={t('input.spaced-repetition.notification.label')}
            htmlId="spaced-repetition-notification"
            {...register('spaced_repetition_notification')}
          >
            {spacedRepetitionNotificationOptions.map((item) => (
              <option
                key={item.key}
                value={item.value}
              >
                {item.text}
              </option>
            ))}
          </Dropdown>
          <Dropdown
            label={t('input.spaced-repetition.timing.label')}
            htmlId="spaced-repetition-timing"
            {...register('spaced_repetition_timing')}
          >
            {spacedRepetitionTimingOptions.map((item) => (
              <option
                key={item.key}
                value={item.value}
              >
                {item.text}
              </option>
            ))}
          </Dropdown>

          <div className="hidden md:flex">
            <PrimaryButton
              htmlId="submit"
              label={t('submit.default')}
              htmlType="submit"
              onClick={handleSubmit(savePersonalInformation)}
            />
          </div>
        </Form>
      </div>
    );
  };

  return (
    <UIShell
      subNavTitle={t('title')}
      rightButton={
        isTailwindLg
          ? undefined
          : {
              action: handleSubmit(savePersonalInformation),
              label: t('submit.mobile'),
            }
      }
      leftButton={{
        action: () => navigate(-1),
        leftIcon: <LeftArrowIcon className="fill-on-primary-01 h-5 w-5" />,
      }}
    >
      <div className="flex h-full w-full flex-row  justify-center">
        <div className="h-full w-full max-w-[504px]">{renderContent()}</div>
      </div>
    </UIShell>
  );
};

function isAliasedEmail(email: string): boolean {
  return email.match('@app\\.(s\\.)?stellarlabs\\.io$') !== null;
}

export default ProfileOverview;
