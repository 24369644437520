import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
export const Toasts = () => (
  <>
    <div className="hidden xl:block">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeButton={false}
        closeOnClick
        rtl={false}
        icon={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        className="!top-[96px] !p-4"
        toastClassName="!p-2 !rounded-lg !text-sm !font-semibold"
      />
    </div>

    <div className="xl:hidden">
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeButton={false}
        closeOnClick
        rtl={false}
        icon={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        className={'!p-4'}
        toastClassName="!p-2 !rounded-lg !text-sm !font-semibold"
      />
    </div>
  </>
);

export default Toasts;
