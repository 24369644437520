import { v4 as uuidv4 } from 'uuid';
import ReactDOM from 'react-dom';
import { API, BlockToolConstructorOptions, BlockToolData } from '@editorjs/editorjs';
import { renderToString } from 'react-dom/server';
import { HorizontalRuleIcon } from '../../../icons';
import { DividerComponent } from './divider-component';
import { BlockEditorTypesEnum } from '../../block-editor';

export class Divider {
  api: API;
  element: HTMLElement;

  constructor({ api }: BlockToolConstructorOptions) {
    this.api = api;
    this.element = this.createElement();
  }

  static get toolbox() {
    return {
      title: BlockEditorTypesEnum.DIVIDER,
      icon: renderToString(<HorizontalRuleIcon className="text-text-01 !h-4 !w-4" />),
    };
  }

  static get inlineToolbar() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  createElement() {
    const rootNode = document.createElement('div');
    rootNode.id = uuidv4();
    return rootNode;
  }

  updateElement() {
    // Calling ReactDOM will generate errors on the console, it should be replaced with createRoot.
    // But doing that will lead to error on EditorJs side (eg. Press Enter won't create a new block)
    ReactDOM.render(<DividerComponent />, this.element);
  }

  render() {
    this.updateElement();
    return this.element;
  }

  save(): BlockToolData {
    return {};
  }
}
