import { Badge } from '@stellar-lms-frontend/ui-components';

type Item = { label: string; value?: string }[];

export const Content = ({ items }: { items: Item }) => (
  <ul className="bg-on-primary-01 text-text-02 hidden flex-row items-start justify-between rounded-b-xl border-b border-l border-r border-gray-100 p-6 text-sm shadow-sm lg:flex">
    {items.map((item) => (
      <li key={item.label}>
        {item.value ? (
          <>
            <p className="text-text-04 text-normal font-medium">{item.value}</p>
            <p>{item.label}</p>
          </>
        ) : (
          <Badge
            color="grey"
            size="normal"
          >
            <p className="type-tiny-default">{item.label}</p>
          </Badge>
        )}
      </li>
    ))}
  </ul>
);
