import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import { CrossIcon, Heading4, Modal, TabMenu } from '@stellar-lms-frontend/ui-components';
import { useEffect, useRef, useState } from 'react';
import { Methodology } from './methodology';
import { Guide } from './guide';
import { Experiment } from './experiment';
import { Apply } from './apply';
import { Retain } from './retain';
import { useTranslation } from 'react-i18next';

export type GEARTemplateModalProps = {
  isOpen: boolean;
  onClose: VoidFunc;
  initialTab?: GEARModalTabs;
};

export enum GEARModalTabs {
  METHODOLOGY = 'METHODOLOGY',
  GUIDE = 'GUIDE',
  EXPERIMENT = 'EXPERIMENT',
  APPLY = 'APPLY',
  RETAIN = 'RETAIN',
}

export const GEARTemplateModal: React.FC<GEARTemplateModalProps> = ({
  isOpen,
  onClose,
  initialTab = GEARModalTabs.METHODOLOGY,
}) => {
  const [activeTab, setActiveTab] = useState<GEARModalTabs>(initialTab);
  const { t } = useTranslation('translation', { keyPrefix: 'gear-template-modal.sidebar' });

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [activeTab]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      variant="blank"
      className="bg-surface-01 h-[709px] w-[1024px]"
    >
      <div className="grid h-full grid-cols-[1fr_2fr]">
        <div className="from-surface-02 space-y-6 bg-gradient-to-b to-transparent px-12">
          <Heading4 className="text-text-04 mt-10">{t('about-GEAR')}</Heading4>
          <p className="text-text-02 mt-6 text-sm font-medium">{t('introduction-text')}</p>
          <TabMenu
            className="mt-4"
            type="vertical"
            tabs={[
              {
                id: GEARModalTabs.METHODOLOGY,
                label: t('tabs.methodology'),
              },
              {
                id: GEARModalTabs.GUIDE,
                label: t('tabs.guide'),
              },
              {
                id: GEARModalTabs.EXPERIMENT,
                label: t('tabs.experiment'),
              },
              {
                id: GEARModalTabs.APPLY,
                label: t('tabs.apply'),
              },
              {
                id: GEARModalTabs.RETAIN,
                label: t('tabs.retain'),
              },
            ]}
            onChange={setActiveTab}
            activeId={activeTab}
          />
        </div>
        <div className="text-text-02 flex h-full overflow-hidden p-10">
          <div
            className="h-full grow overflow-y-auto pl-10 pr-4"
            ref={contentRef}
          >
            {activeTab === GEARModalTabs.METHODOLOGY && <Methodology />}
            {activeTab === GEARModalTabs.GUIDE && <Guide />}
            {activeTab === GEARModalTabs.EXPERIMENT && <Experiment />}
            {activeTab === GEARModalTabs.APPLY && <Apply />}
            {activeTab === GEARModalTabs.RETAIN && <Retain />}
          </div>
          <button
            onClick={onClose}
            className="self-start"
          >
            <CrossIcon className="text-text-01 h-6 w-6" />
          </button>
        </div>
      </div>
    </Modal>
  );
};
