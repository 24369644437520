import { Activity } from '@stellar-lms-frontend/ui-components';
import { useQuery } from '@tanstack/react-query';
import {
  ACTION_REFLECTION_RESULT_URI_PART,
  ACTION_REVIEW_RESULT_URI_PART,
} from '../../../constants/routes';
import { useTranslation } from 'react-i18next';
import { PUBLISHED_SURVEY } from '../../../constants/query';
import {
  LearnerWorkBasedActionHistory,
  PersistedLearnerWorkBasedAction,
} from '@stellar-lms-frontend/lms-api';
import { getWbaSurveyResult } from '../work-based-actions.api';
import { graphQLClient } from '../../../lib/graphql';

type ActivityItemProps = LearnerWorkBasedActionHistory & {
  wba: PersistedLearnerWorkBasedAction;
};

export const ActivityItem: React.FC<ActivityItemProps> = ({ user, changedAt, state, wba }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'actions.activity' });

  // We need these to check if the surveys have questions, I think this needs to be either cached for a bit or we should send this info straight from the backend.
  const learnerSurveyQueryEnabled = !!wba.learnerSurveyId;
  const learnerSurveyQuery = useQuery(
    [PUBLISHED_SURVEY, wba.id, wba.learnerSurveyId],
    () => getWbaSurveyResult(graphQLClient(), wba.id, wba.learnerSurveyId),
    { enabled: learnerSurveyQueryEnabled }
  );
  const mentorSurveyQueryEnabled = !!wba.mentorSurveyId;
  const mentorSurveyQuery = useQuery(
    [PUBLISHED_SURVEY, wba.id, wba.mentorSurveyId],
    () => getWbaSurveyResult(graphQLClient(), wba.id, wba.mentorSurveyId),
    { enabled: mentorSurveyQueryEnabled }
  );

  // The open state is triggered when the user open the WBA on course part, so we ignore it here. But it may be useful in the future
  if (state === 'open') return null;

  if (!user) return null;

  let link = undefined;
  switch (state) {
    case 'completed':
    case 'executed': {
      link =
        learnerSurveyQueryEnabled &&
        !learnerSurveyQuery.isLoading &&
        learnerSurveyQuery.data &&
        (learnerSurveyQuery.data?.questions ?? []).length > 0
          ? {
              title: t('view-reflection'),
              path: ACTION_REFLECTION_RESULT_URI_PART,
            }
          : undefined;
      break;
    }
    case 'reviewed': {
      link =
        mentorSurveyQueryEnabled &&
        !mentorSurveyQuery.isLoading &&
        mentorSurveyQuery.data &&
        (mentorSurveyQuery.data?.questions ?? []).length > 0
          ? {
              title: t('show-feedback'),
              path: ACTION_REVIEW_RESULT_URI_PART,
            }
          : undefined;
      break;
    }
  }

  return (
    <Activity
      type={'state'}
      avatar={user.thumbUri}
      author={user.name}
      message={t(`state.state-change-message-${state}`)}
      link={link}
      date={changedAt}
    />
  );
};
