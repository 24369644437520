import React from 'react';

type LinkInlineToolButtonProps = {
  icon: JSX.Element;
  className: string;
};

export const LinkInlineToolButtonComponent: React.FC<LinkInlineToolButtonProps> = ({
  icon,
  className,
}) => {
  return (
    <button
      type="button"
      className={className}
    >
      {icon}
    </button>
  );
};
