import { DataCy } from '@stellar-lms-frontend/common-utils';

export type Heading4Props = DataCy & {
  children: React.ReactNode;
  className?: string;
};

export const Heading4 = ({ children, className = '', 'data-cy': dataCy }: Heading4Props) => {
  return (
    <h4
      className={`${className} font-lexend text-lg font-medium leading-snug md:text-xl`}
      data-cy={dataCy}
    >
      {children}
    </h4>
  );
};

export default Heading4;
