import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import {
  MutationDeleteLearningActivityArgs,
  MutationDuplicateLearningActivityArgs,
  MutationUpdateLearningActivityArgs,
  graphql,
} from '@stellar-lms-frontend/lms-graphql';
import { graphQLClient } from '../lib/graphql';
import { USE_COURSE_OUTLINE } from '@stellar-lms-frontend/lms-components';
import { COURSE } from '../constants/query';

type MutationOptions = {
  onSuccess?: () => void;
  onError?: () => void;
};

const useInvalidateQueries = () => {
  const queryClient = useQueryClient();

  const invalidateQueries = useCallback(
    (courseId: string) => {
      queryClient.invalidateQueries([COURSE, courseId]);
      queryClient.invalidateQueries([USE_COURSE_OUTLINE, courseId]);
    },
    [queryClient]
  );

  return invalidateQueries;
};

export const useDuplicateLearningActivity = ({ onSuccess, onError }: MutationOptions) => {
  const invalidateQueries = useInvalidateQueries();
  const mutation = useMutation(duplicateLearningActivity, {
    onSuccess: (_, variables) => {
      invalidateQueries(variables.courseId);
      onSuccess?.();
    },
    onError,
  });

  return mutation;
};

export const useUpdateLearningActivity = ({ onSuccess, onError }: MutationOptions) => {
  const invalidateQueries = useInvalidateQueries();
  const mutation = useMutation(updateLearningActivity, {
    onSuccess: (_, variables) => {
      invalidateQueries(variables.courseId);
      onSuccess?.();
    },
    onError,
  });

  return mutation;
};

export const useDeleteLearningActivity = ({ onSuccess, onError }: MutationOptions) => {
  const invalidateQueries = useInvalidateQueries();
  const mutation = useMutation(deleteLearningActivity, {
    onSuccess: (_, variables) => {
      invalidateQueries(variables.courseId);
      onSuccess?.();
    },
    onError,
  });

  return mutation;
};

const duplicateLearningActivity = (variables: MutationDuplicateLearningActivityArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation duplicateLearningActivity($courseId: ID!, $moduleId: ID!, $learningActivityId: ID!) {
        duplicateLearningActivity(
          courseId: $courseId
          moduleId: $moduleId
          learningActivityId: $learningActivityId
        )
      }
    `),
    variables
  );
};

const updateLearningActivity = (variables: MutationUpdateLearningActivityArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation updateLearningActivity(
        $courseId: ID!
        $moduleId: ID!
        $learningActivityId: ID!
        $input: LearningActivityCreateInput!
      ) {
        updateLearningActivity(
          courseId: $courseId
          moduleId: $moduleId
          learningActivityId: $learningActivityId
          input: $input
        ) {
          id
        }
      }
    `),
    variables
  );
};

const deleteLearningActivity = (variables: MutationDeleteLearningActivityArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation deleteLearningActivityMutation(
        $courseId: ID!
        $moduleId: ID!
        $learningActivityId: ID!
      ) {
        deleteLearningActivity(
          courseId: $courseId
          moduleId: $moduleId
          learningActivityId: $learningActivityId
        )
      }
    `),
    variables
  );
};
