import { CheckIcon } from '.';

export interface NumberIconProps {
  index: number;
  selected?: boolean;
  completed?: boolean;
}

export function NumberIcon({ index, selected, completed }: NumberIconProps) {
  const backgroundColor = selected
    ? 'bg-gradient-to-l from-primary-gradient-start to-primary-gradient-end'
    : 'bg-surface-01';
  const border = selected ? '' : 'border-2 border-border-01';
  const textColor = selected ? 'text-white' : 'text-text-02';

  const renderContent = () => {
    if (completed) {
      return <CheckIcon className={`w-[16px] ${textColor}`} />;
    } else {
      return (
        <span className={`font-lexend text-sm font-semibold not-italic ${textColor}`}>{index}</span>
      );
    }
  };

  return (
    <span className="flex w-6 flex-col items-center justify-center">
      <i
        className={`flex h-6 w-6 shrink-0 items-center justify-center rounded-full  ${backgroundColor} ${border} `}
      >
        {renderContent()}
      </i>
    </span>
  );
}

export default NumberIcon;
