import { ProgressCell } from './components/progress-cell';
import { LearnerCell } from './components/learner-cell';
import {
  EmptyState,
  FlexTable,
  FlexTableI18N,
  LinkButton,
  LogoLoader,
  PrimaryButton,
  UserCircleIcon,
} from '@stellar-lms-frontend/ui-components';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  createColumnHelper,
  CellContext,
  ColumnDef,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { AccessCell, AccessCellI18N, AccessTypes } from './components/access-cell';
import { LearnerTableActions, LearnerTableActionsI18N } from './components/learner-table-actions';
import { AddToJourneyModal } from './components/add-to-journey-modal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  AddMemberToCourseMutationVariables,
  CourseRole,
  DeleteMemberRegistrationFromCourseMutationVariables,
  UpdateCourseRegistrationMutationVariables,
  addMemberToCourse,
  deleteMemberRegistrationFromCourse,
  getMembersOfCourse,
  getUsersOfCompany,
  updateMemberRegistrationFromCourse,
  useCurrentCompany,
} from '@stellar-lms-frontend/lms-graphql';
import { GraphQLClient } from 'graphql-request';
import { toast } from 'react-toastify';
import { transformIncomingCourseRole, transformOutgoingCourseRole } from './utils';
import { COMPANY, COURSE, MEMBER } from '@stellar-lms-frontend/lms-api';
import { OptionsMenuCell, OptionsMenuCellI18N } from './components/options-menu-cell';
import { EditLearnerSidebar, EditLearnerSidebarI18N } from './components/edit-learner-sidebar';
import { ConfirmationModal } from '../../confirmation-modal/confirmation-modal';
import { SupportedLanguagesOptions } from '../../types/types';

const DEFAULT_PAGE_SIZE = 20;

export type LearnersTableRowShape = {
  registrationId: string;
  id: string;
  name: {
    name: string;
    thumbUri: string;
  };
  access: AccessTypes;
  // lastActivity: string;
  mentor?: {
    id: string;
    name: string;
  };
  progress: number;
};

const columnHelper = createColumnHelper<LearnersTableRowShape>();

type LearnersTableI18N = {
  table: FlexTableI18N;
  tableActions: LearnerTableActionsI18N;
  editLearnerSidebar: EditLearnerSidebarI18N;
  removeUserFromJourneyModal: {
    title: string;
    description: string;
    remove: string;
    cancel: string;
  };
  header: {
    name: string;
    access: string;
    // lastActivity: string;
    mentor: string;
    progress: string;
  };
  accessCell: AccessCellI18N;
  toasts: {
    addMember: string;
    deleteMember: string;
    updateMember: string;
  };
  empty: {
    title: string;
    description: string;
  };
  optionsMenu: OptionsMenuCellI18N;
};

export type LearnersTableProps = {
  i18n: LearnersTableI18N;
  courseId: string;
  graphQLClient: GraphQLClient;
  hasEditPermission: boolean;
  supportedLanguages: SupportedLanguagesOptions;
  defaultLanguage: string;
};

export const LearnersTable: React.FC<LearnersTableProps> = ({
  i18n,
  courseId,
  graphQLClient,
  supportedLanguages,
  defaultLanguage,
  hasEditPermission,
}) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageIndex, setPageIndex] = useState(0);
  const [editingLearner, setEditingLearner] = useState<LearnersTableRowShape | undefined>(
    undefined
  );
  const queryClient = useQueryClient();
  const [removeFromJourneyUserId, setRemoveFromJourneyUserId] = useState<string | undefined>(
    undefined
  );
  const [selectedRowId, setSelectedRowId] = useState<string | undefined>(undefined);

  const {
    query: { data: company },
  } = useCurrentCompany(graphQLClient);

  const membersQuery = useQuery(
    [COMPANY, MEMBER, company?.id],
    () =>
      company?.id
        ? getUsersOfCompany(graphQLClient, {
            companyId: company?.id,
            pageSize: 500,
            cursor: '0',
          })
        : undefined,
    {
      enabled: !!company?.id,
    }
  );

  const learnersQuery = useQuery(
    [
      COURSE,
      MEMBER,
      courseId,
      // {
      //   pageSize,
      //   pageIndex,
      // },
    ],
    () =>
      getMembersOfCourse(graphQLClient, {
        courseId,
        pageSize: 0,
        // pageSize,
        // cursor: (pageSize * pageIndex).toString(),
      })
  );

  // All members of company without those which already have a course registration for current course
  const membersToAdd = useMemo(() => {
    const existingUserIds =
      learnersQuery.data?.registrations
        ?.filter((r) => r.user && r.user.id)
        .map((r) => r.user!.id) ?? [];

    return (
      membersQuery.data?.users
        ?.filter((m) => !existingUserIds.includes(m.id))
        .map((m) => ({
          id: m.id,
          name: m.name ?? '',
        })) ?? []
    );
  }, [membersQuery.data, learnersQuery.data]);

  // All members of company properly formatted into Member object
  const membersList = useMemo(() => {
    return (
      membersQuery.data?.users?.map((m) => ({
        id: m.id,
        name: m.name ?? '',
      })) ?? []
    );
  }, [membersQuery.data]);

  const { mutate: addMemberToCourseMutation } = useMutation(
    (input: AddMemberToCourseMutationVariables) => addMemberToCourse(graphQLClient, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([COURSE, MEMBER, courseId]);
        toast.success(i18n.toasts.addMember);
      },
    }
  );

  const { mutate: deleteMemberRegistrationFromCourseMutation } = useMutation(
    (input: DeleteMemberRegistrationFromCourseMutationVariables) =>
      deleteMemberRegistrationFromCourse(graphQLClient, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([COURSE, MEMBER, courseId]);
        toast.success(i18n.toasts.deleteMember);
      },
    }
  );

  const { mutate: updateMemberRegistrationFromCourseMutation } = useMutation(
    (input: UpdateCourseRegistrationMutationVariables) =>
      updateMemberRegistrationFromCourse(graphQLClient, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([COURSE, MEMBER, courseId]);
        toast.success(i18n.toasts.updateMember);
      },
    }
  );

  const learners = useMemo<LearnersTableRowShape[]>(() => {
    return (
      learnersQuery.data?.registrations?.map((l) => {
        const courseRole = transformIncomingCourseRole(l.courseRole);
        return {
          registrationId: l.id,
          name: {
            name: l.user?.name ?? '',
            thumbUri: l.user?.thumbUri ?? '',
          },
          id: l.user?.id ?? '',
          access: courseRole,
          // lastActivity: 'Yesterday',
          mentor: l.mentor
            ? {
                id: l.mentor.id ?? '',
                name: l.mentor.name ?? '',
              }
            : undefined,
          progress: Math.round((l.userStats?.progress ?? 0) * 100),
        };
      }) ?? []
    );
  }, [learnersQuery.data]);

  const columns = useMemo<ColumnDef<LearnersTableRowShape, any>[]>(
    () => [
      columnHelper.accessor((row) => row.name, {
        id: 'name',
        header: i18n.header.name,
        sortingFn: (a, b, id) => {
          const nameA = a.getValue<LearnersTableRowShape['name']>(id).name.toLowerCase().trim();
          const nameB = b.getValue<LearnersTableRowShape['name']>(id).name.toLowerCase().trim();
          return nameA < nameB ? -1 : 1;
        },
        cell: (info: CellContext<LearnersTableRowShape, LearnersTableRowShape['name']>) => (
          <LearnerCell
            thumbUri={info.getValue().thumbUri}
            name={info.getValue().name}
          />
        ),
      }),
      columnHelper.accessor((row) => row.access, {
        id: 'access',
        header: i18n.header.access,
        cell: (info) => (
          <AccessCell
            access={info.getValue()}
            i18n={i18n.accessCell}
          />
        ),
      }),
      // columnHelper.accessor((row) => row.lastActivity, {
      //   id: 'lastActivity',
      //   header: i18n.header.lastActivity,
      //   cell: (info) => <span className="text-text-02 text-sm">{info.getValue()}</span>,
      // }),
      columnHelper.accessor((row) => row.mentor, {
        id: 'mentor',
        header: i18n.header.mentor,
        sortingFn: (a, b, id) => {
          const nameA =
            a.getValue<LearnersTableRowShape['mentor']>(id)?.name.toLowerCase().trim() ?? '';
          const nameB =
            b.getValue<LearnersTableRowShape['mentor']>(id)?.name.toLowerCase().trim() ?? '';
          return nameA < nameB ? -1 : 1;
        },
        cell: (info) => <span className="text-sm">{info.getValue()?.name ?? ''}</span>,
      }),
      columnHelper.accessor((row) => row.progress, {
        id: 'progress',
        header: i18n.header.progress,
        cell: (info: CellContext<LearnersTableRowShape, number>) =>
          info.row.original.access === 'learner' ? (
            <ProgressCell
              id={info.row.original.id}
              progress={info.getValue()}
            />
          ) : null,
      }),
      {
        id: 'options',
        header: () => null,
        cell: hasEditPermission
          ? ({ row }) => (
              <OptionsMenuCell
                i18n={i18n.optionsMenu}
                onEdit={() => {
                  const learner = learners?.find((u) => u.id === row.original.id);
                  if (!learner) return;
                  setEditingLearner(learner);
                  setSelectedRowId(row.id);
                }}
                onDelete={() => setRemoveFromJourneyUserId(row.original.registrationId)}
              />
            )
          : undefined,
      },
    ],
    [hasEditPermission, i18n, learners]
  );

  const table = useReactTable({
    data: learners,
    columns,
    enableColumnResizing: false,
    initialState: {
      sorting: [{ id: 'name', desc: false }],
      pagination: { pageSize: 20 },
      columnVisibility: { progress: !(company?.hidePerformanceManagement ?? false) },
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // manualPagination: true,
    // pageCount: Math.ceil((learnersQuery.data?.totalCount ?? 0) / pageSize),
  });

  useEffect(() => {
    setPageIndex(table.getState().pagination.pageIndex);
    // Disabled because it's a function call dependency and eslint doesn't recognize it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getState().pagination.pageIndex]);

  useEffect(() => {
    setPageSize(table.getState().pagination.pageSize);
    // Disabled because it's a function call dependency and eslint doesn't recognize it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getState().pagination.pageSize]);

  if (learnersQuery.isLoading) return <LogoLoader />;

  if (learnersQuery.isLoading && learners.length === 0)
    return (
      <EmptyState
        className="mt-16"
        icon={<UserCircleIcon />}
        title={i18n.empty.title}
        description={i18n.empty.description}
      />
    );

  const findLearnerAdjacent = (learnerId: string, location: 'prev' | 'next') => {
    return learners[learners.findIndex((u) => u.id === learnerId) + (location === 'prev' ? -1 : 1)];
  };

  const findLearnerRowId = (learnerId: string) => {
    return table.getRowModel().rows.find((row) => row.original.id === learnerId)?.id;
  };

  if (!company) {
    return null;
  }

  return (
    <div className="w-full space-y-4">
      <LearnerTableActions
        i18n={i18n.tableActions}
        onClickAdd={() => setIsAddModalOpen(true)}
        hasEditPermission={hasEditPermission}
      />
      <FlexTable
        rowClick={(row) => {
          setEditingLearner(learners.find((l) => l.id === row.original.id));
          setSelectedRowId(row.id);
        }}
        i18n={i18n.table}
        table={table}
        data-cy="learners-table"
        // totalRows={learnersQuery.data?.totalCount ?? 0}
      />
      <AddToJourneyModal
        companyId={company.id}
        onAdd={(data) => {
          const courseRole = transformOutgoingCourseRole(data.access);
          let mentorId;
          if (courseRole === CourseRole.Learner && data.mentor) {
            mentorId = data.mentor;
          }
          addMemberToCourseMutation({
            courseId,
            input: {
              courseRole: transformOutgoingCourseRole(data.access),
              userId: data.selectMember,
              mentorId: mentorId,
            },
          });
          setIsAddModalOpen(false);
        }}
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        membersToAddList={membersToAdd}
        membersList={membersList}
        supportedLanguages={supportedLanguages}
        defaultLanguage={defaultLanguage}
      />
      {editingLearner && (
        <EditLearnerSidebar
          i18n={i18n.editLearnerSidebar}
          isOpen={!!editingLearner}
          onClose={() => setEditingLearner(undefined)}
          onSubmit={(data) => {
            updateMemberRegistrationFromCourseMutation({
              courseId: courseId,
              registrationId: editingLearner.registrationId,
              input: {
                courseRole: transformOutgoingCourseRole(data.access),
                mentorId: data.mentor,
              },
            });
            setEditingLearner(undefined);
          }}
          onDelete={() => setRemoveFromJourneyUserId(editingLearner.registrationId)}
          onNavigateLeft={() => {
            const learner = findLearnerAdjacent(editingLearner.id, 'prev');
            if (!learner) return;
            setEditingLearner(learner);
            setSelectedRowId(findLearnerRowId(learner.id));
          }}
          onNavigateRight={() => {
            const learner = findLearnerAdjacent(editingLearner.id, 'next');
            if (!learner) return;
            setEditingLearner(learner);
            setSelectedRowId(findLearnerRowId(learner.id));
          }}
          learner={{ access: editingLearner.access, mentor: editingLearner.mentor?.id ?? '' }}
          user={{
            id: editingLearner.id,
            name: editingLearner.name.name,
            mentor: editingLearner.mentor,
          }}
          membersList={membersList}
        />
      )}
      {removeFromJourneyUserId !== undefined && (
        <ConfirmationModal
          title={i18n.removeUserFromJourneyModal.title}
          description={i18n.removeUserFromJourneyModal.description}
          buttons={{
            hasShadow: false,
            buttons: [
              <PrimaryButton
                key={1}
                theme="red"
                label={i18n.removeUserFromJourneyModal.remove}
                onClick={() => {
                  deleteMemberRegistrationFromCourseMutation({
                    courseId,
                    registrationId: removeFromJourneyUserId,
                  });
                  setRemoveFromJourneyUserId(undefined);
                }}
              />,
              <LinkButton
                key={2}
                buttonStyle="fit"
                onClick={() => setRemoveFromJourneyUserId(undefined)}
                label={i18n.removeUserFromJourneyModal.cancel}
              />,
            ],
          }}
          isOpen={removeFromJourneyUserId !== undefined}
          onClose={() => setRemoveFromJourneyUserId(undefined)}
        />
      )}
    </div>
  );
};
