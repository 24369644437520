import { destructureSeconds, navigation, useMediaQuery } from '@stellar-lms-frontend/common-utils';
import { LearnerWorkBasedAction } from '@stellar-lms-frontend/lms-api';
import {
  Badge,
  BookIcon,
  CalendarIcon,
  ClockIcon,
  CommentIcon,
  ContentContainer,
  LogoLoader,
  Heading4,
  LeftArrowIcon,
  MentorIcon,
  PrimaryButton,
  ScrollContainer,
  TextIcon,
} from '@stellar-lms-frontend/ui-components';
import { useQuery } from '@tanstack/react-query';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LEARNER_WBA, SURVEY } from '../../constants/query';
import {
  ACTION_EDIT_URI_PART,
  ACTION_OLD_REFLECTION_URI_PART,
  ACTION_REFLECTION_URI_PART,
  ACTION_REVIEW_URI_PART,
} from '../../constants/routes';
import { Due, useDueDate } from '../../hooks/use-due-date';
import { useWBAStatus } from '../../hooks/use-wba-status';
import { ActivitySection } from './components/activity-section';
import { graphQLClient } from '../../lib/graphql';
import { getLearnerWorkBasedAction } from './work-based-actions.api';
import { getSurveyById } from '../learning-activity/learner-learning-activity-detail-page.api';

export type WorkBasedActionReadOnlyViewProps = {
  learnerWorkBaseActionId: string;
  isUserMentor?: boolean;
};

const NAVIGATION_KEY = 'action-detail';

export const WorkBasedActionReadOnlyView = ({
  learnerWorkBaseActionId,
  isUserMentor = false,
}: WorkBasedActionReadOnlyViewProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [showDescriptionTruncateButton, setShowDescriptionTruncateButton] = useState<
    boolean | undefined
  >();
  const { t } = useTranslation('translation', { keyPrefix: 'actions' });
  const [generalT] = useTranslation('translation', { keyPrefix: 'general' });
  const isDesktop = useMediaQuery('(min-width: 1280px)');
  const navigate = useNavigate();
  const determineDueType = useDueDate();

  const { data: workBasedAction } = useQuery([LEARNER_WBA, learnerWorkBaseActionId], () =>
    getLearnerWorkBasedAction(graphQLClient(), learnerWorkBaseActionId)
  );

  const descriptionDivRefCallback = (element: HTMLDivElement) => {
    // only update this value once
    if (element && !showDescriptionTruncateButton) {
      setShowDescriptionTruncateButton(element.offsetHeight < element.scrollHeight);
    }
  };

  // We need to get the survey to make a correct decision on sending the user to the survey or to the fallback

  // As this is one of those onsophic calls that actually creates an instance of the survey (published) we cannot execute this for mentors
  // See bug: STE-983
  const learnerSurveyQueryEnabled =
    !isUserMentor && !!workBasedAction?.learnerSurveyId && workBasedAction?.state === 'submitted';
  const learnerSurveyQuery = useQuery(
    [SURVEY, workBasedAction?.id, workBasedAction?.learnerSurveyId],
    () =>
      workBasedAction && workBasedAction.learnerSurveyId
        ? getSurveyById(
            graphQLClient(),
            workBasedAction.learnerSurveyId,
            workBasedAction.activityId
          )
        : null,
    { enabled: workBasedAction && learnerSurveyQueryEnabled }
  );

  useEffect(() => {
    if (!isDesktop) {
      navigation.setTitle(NAVIGATION_KEY, t('wba-title'));
      navigation.setLeftPrimary(NAVIGATION_KEY, {
        action: () => navigate(-1),
        leftIcon: <LeftArrowIcon className="text-on-primary-01" />,
        label: '',
      });
      if (!isUserMentor && workBasedAction && !workBasedAction?.completed) {
        navigation.setRightPrimary(NAVIGATION_KEY, {
          action: () => navigate(ACTION_EDIT_URI_PART),
          label: t('edit-button'),
        });
      }
    }

    return () => {
      if (!isDesktop) {
        navigation.popLeftPrimary(NAVIGATION_KEY);
        navigation.popRightPrimary(NAVIGATION_KEY);
        navigation.popTitle(NAVIGATION_KEY);
      }
    };
  }, [navigate, t, isDesktop, workBasedAction, isUserMentor]);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const onCompleteClick = () => {
    // Mentor cannot complete
    if (isUserMentor) return;
    if (learnerSurveyQueryEnabled && (learnerSurveyQuery.data?.questions ?? []).length > 0) {
      navigate(ACTION_REFLECTION_URI_PART);
    } else {
      navigate(ACTION_OLD_REFLECTION_URI_PART);
    }
  };

  const onReviewClick = () => {
    // learners cannot review
    if (!isUserMentor) return;

    // We do not check mentor survey ID here as we don't have a fallback (yet?)
    // The button is also only visible when there is a mentor survey id.
    navigate(ACTION_REVIEW_URI_PART);
  };

  const renderBlueDueDate = (due: Omit<Due, 'type' | 'date'>) => (
    <div
      className="flex flex-row"
      data-cy="wba_duedate"
    >
      <CalendarIcon className="text-primary-01 my-4 shrink-0 " />
      <div className="border-b-1 border-border-01 text-text-02 font-lexend font-regular ml-4 grow py-4">
        <span
          className={`text-text-02 font-lexend font-regular inline-block text-base leading-normal`}
        >
          {due.label}
        </span>
      </div>
    </div>
  );

  const renderRedDueDate = (due: Omit<Due, 'type'>) => (
    <div
      className="flex flex-row "
      data-cy="wba_duedate"
    >
      <CalendarIcon className="text-negative-01 my-4 shrink-0 " />
      <div className="border-b-1 border-border-01 text-negative-01 font-lexend font-regular ml-4 flex grow flex-row justify-between py-4 text-base">
        {t('due-date', { date: due.date.format('D MMM yyyy') })}
      </div>
    </div>
  );

  const renderDueDate = (action: LearnerWorkBasedAction): ReactNode => {
    if (!action.learnerDueDate) {
      return null;
    }

    const due = determineDueType(action.learnerDueDate);
    const state = action.state;

    if (state === 'executed' || state === 'reviewed' || state === 'completed') {
      return renderBlueDueDate({ label: t('due-date', { date: due.date.format('D MMM yyyy') }) });
    }

    switch (due.type) {
      case 'overdue':
        return renderRedDueDate(due);
      default:
        return renderBlueDueDate(due);
    }
  };

  const { label: badgeLabel, color: badgeColor } = useWBAStatus(workBasedAction);

  if (!workBasedAction) return <LogoLoader />;

  const canLearnerEdit =
    !isUserMentor && (workBasedAction?.state === 'open' || workBasedAction?.state === 'submitted');
  const canLearnerComplete = workBasedAction?.state === 'submitted' && !isUserMentor;
  const canMentorReview =
    isUserMentor && workBasedAction?.state === 'executed' && workBasedAction.mentorSurveyId;
  const { hours, minutes } = workBasedAction.timeSpent
    ? destructureSeconds(workBasedAction.timeSpent)
    : { hours: 0, minutes: 0 };

  return (
    <div
      className="flex h-full w-full flex-col justify-between lg:justify-start"
      data-cy="wba-read-only-view"
    >
      <ScrollContainer>
        <ContentContainer>
          <div className="max-w-content w-full">
            <div className="hidden flex-row items-start justify-between pb-5 lg:flex">
              <Heading4 data-cy={'work-based-action-read-only-view-title'}>
                {workBasedAction.learnerShortDescription}
              </Heading4>
              {canLearnerEdit && (
                <div
                  className="hidden shrink-0  pl-2 lg:block "
                  data-cy="edit"
                >
                  <span
                    onClick={() => navigate(ACTION_EDIT_URI_PART)}
                    className={`font-lexend text-primary-01 cursor-pointer text-base font-medium underline`}
                  >
                    {t('edit-button-long')}
                  </span>
                </div>
              )}
            </div>
            <div className="pb-5">
              <div
                className="flex flex-row pb-2"
                data-cy="wba_badge"
              >
                <Badge
                  color={badgeColor}
                  size="full-width"
                >
                  {badgeLabel}
                </Badge>
              </div>
              <div
                className="flex flex-row"
                data-cy="wba_description"
              >
                <TextIcon className="text-primary-01 my-4 shrink-0 " />
                <div className="border-b-1 border-border-01 ml-4 grow py-4 ">
                  <div className="font-lexend text-text-04 text-base font-semibold leading-normal lg:hidden">
                    {workBasedAction.learnerShortDescription}
                  </div>
                  <div
                    onClick={toggleShowMore}
                    className={`${
                      showMore ? '' : 'line-clamp-5'
                    } font-lexend font-regular text-text-01 whitespace-pre-wrap text-base`}
                    ref={descriptionDivRefCallback}
                  >
                    {workBasedAction.learnerDescription}
                  </div>
                  <button
                    onClick={toggleShowMore}
                    className={`lexend text-primary-01 font-regular pt-1 text-sm ${
                      showDescriptionTruncateButton && !showMore ? 'block' : 'hidden'
                    }`}
                    id="show-more-button"
                  >
                    {generalT('show-more')}
                  </button>
                </div>
              </div>
              {renderDueDate(workBasedAction)}
              {workBasedAction.mentor?.name && (
                <div
                  className="flex items-center"
                  data-cy="wba_mentor"
                >
                  <MentorIcon className="text-primary-01 h-6 w-6" />
                  <p className="border-b-1 border-border-01 text-text-02 font-lexend ml-4 grow py-4">
                    {workBasedAction.mentor?.name}
                  </p>
                </div>
              )}
              <div
                className="flex flex-row "
                data-cy="wba_course"
              >
                <BookIcon className="text-primary-01 my-4 shrink-0 " />
                <div className="border-b-1 border-border-01 text-text-02 font-lexend font-regular ml-4 grow py-4">
                  {`${workBasedAction.courseTitle} > ${workBasedAction.moduleTitle}`}
                </div>
              </div>
              {workBasedAction.timeSpent !== undefined && workBasedAction.timeSpent > 0 && (
                <div
                  className="flex flex-row "
                  data-cy="wba_timespent"
                >
                  <ClockIcon className="text-primary-01 my-4 shrink-0 " />
                  <div className="border-b-1 border-border-01 text-text-02 font-lexend font-regular ml-4 flex grow flex-row justify-between py-4">
                    {`${t('time-spent-hours', { count: hours })} ${t('and')} ${t(
                      'time-spent-minutes',
                      {
                        count: minutes,
                      }
                    )}`}
                  </div>
                </div>
              )}
              {workBasedAction.learnerFeedback && (
                <div
                  className="flex flex-row "
                  data-cy="wba_feedback"
                >
                  <CommentIcon className="text-primary-01 my-4 shrink-0 " />
                  <div className="border-b-1 border-border-01 text-text-02 font-lexend font-regular ml-4 flex grow flex-row justify-between py-4">
                    {workBasedAction.learnerFeedback}
                  </div>
                </div>
              )}
            </div>
            <ActivitySection wba={workBasedAction} />
            {canLearnerComplete && (
              <div className="mt-8 hidden lg:block">
                <PrimaryButton
                  data-cy="wba-read-only-view-complete-button"
                  htmlId="complete-button"
                  label={t('cta-complete')}
                  onClick={onCompleteClick}
                ></PrimaryButton>
              </div>
            )}
            {canMentorReview && (
              <div className="mt-8 hidden lg:block">
                <PrimaryButton
                  data-cy="wba-read-only-view-review-button"
                  htmlId="review-button"
                  label={t('cta-review')}
                  onClick={onReviewClick}
                ></PrimaryButton>
              </div>
            )}
          </div>
        </ContentContainer>
      </ScrollContainer>
      {canLearnerComplete && (
        <ContentContainer className="shrink-0 grow-0 py-8 lg:hidden lg:p-0">
          <PrimaryButton
            htmlId="complete-button"
            label={t('cta-complete')}
            onClick={onCompleteClick}
          ></PrimaryButton>
        </ContentContainer>
      )}
      {canMentorReview && (
        <ContentContainer className="shrink-0 grow-0 py-8 lg:hidden lg:p-0">
          <PrimaryButton
            htmlId="review-button"
            label={t('cta-review')}
            onClick={onReviewClick}
          ></PrimaryButton>
        </ContentContainer>
      )}
    </div>
  );
};

export default WorkBasedActionReadOnlyView;
