// TODO (STE-1779): Refactor component to avoid using translations to decide which items should be displayed
import {
  ChevronRightIcon,
  FloatingOptions,
  LogOut,
  QuestionIcon,
} from '@stellar-lms-frontend/ui-components';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Item, ItemProps } from './item';
import {
  VoidFunc,
  conditionalArrayItem,
  hasAnyPermission,
  hasPermission,
} from '@stellar-lms-frontend/common-utils';
import { PROFILE_URI, PRIVACY_POLICY, ORGANIZATION_URI, ADMINISTRATION_URI } from '../constants';
import { openHubspotChat } from '../hubspot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faShieldQuartered, faUserLarge } from '@fortawesome/pro-light-svg-icons';
import { CurrentUser, CurrentCompany } from '@stellar-lms-frontend/lms-graphql';
import { useTranslation } from 'react-i18next';

export type Actions = {
  logout: () => void;
};

export type MoreType =
  | {
      type: 'box';
      wrappedComponent: (action: VoidFunc) => ReactNode;
      BlueUIShell?: never;
    }
  | {
      type: 'page';
      wrappedComponent?: never;
      BlueUIShell: React.ElementType;
    };

export type MoreProps = {
  title?: string;
  currentUser?: CurrentUser;
  currentCompany?: CurrentCompany;
  actions: Actions;
} & MoreType;

export const More: React.FC<MoreProps> = ({
  type,
  BlueUIShell,
  title,
  wrappedComponent,
  currentUser,
  currentCompany,
  actions,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'more-menu' });
  const [isOptionsOpen, setIsOptionsOpen] = React.useState(false);

  const navigate = useNavigate();

  const showCompanyPermissions = hasAnyPermission(currentCompany?.permissions, [
    'update',
    'member_management',
  ]);

  const showAdministration = hasPermission(currentUser?.permissions, 'administration');

  const items: ItemProps[] = [
    ...conditionalArrayItem(type === 'page', {
      id: 'profile',
      title: t('profile'),
      icon: <ChevronRightIcon className={'text-text-01 h-4 w-4'} />,
      onClick: () => navigate(PROFILE_URI),
    }),
    ...conditionalArrayItem(type === 'box', {
      id: 'profile',
      title: currentUser?.name ?? '',
      icon: null,
      subtitle: currentCompany?.name ?? '',
      onClick: () => navigate(PROFILE_URI),
      className: 'text-text-01',
    }),
    ...conditionalArrayItem(type === 'box', {
      id: 'personal-account',
      title: t('personal-account'),
      icon: (
        <FontAwesomeIcon
          icon={faUserLarge}
          className={'text-text-01 h-4 w-4'}
        />
      ),
      onClick: () => navigate(PROFILE_URI),
    }),
    ...conditionalArrayItem(showCompanyPermissions && type === 'box', {
      id: 'organization-settings',
      title: t('organization-settings'),
      icon: (
        <FontAwesomeIcon
          icon={faBuilding}
          className={'text-text-01 h-4 w-4'}
        />
      ),
      onClick: () => navigate(ORGANIZATION_URI),
    }),
    ...conditionalArrayItem(showAdministration && type === 'box', {
      id: 'administration-overview',
      title: t('administration-overview'),
      icon: (
        <FontAwesomeIcon
          icon={faBuilding}
          className={'text-text-01 h-4 w-4'}
        />
      ),
      onClick: () => navigate(ADMINISTRATION_URI),
    }),
    {
      id: 'privacy',
      title: t('privacy'),
      icon: (
        <FontAwesomeIcon
          icon={faShieldQuartered}
          className={'text-text-01 h-4 w-4'}
        />
      ),
      onClick: () => {
        const reactNativeWebView = window.ReactNativeWebView;
        if (reactNativeWebView) {
          reactNativeWebView.postMessage(
            JSON.stringify({
              navigate: { url: PRIVACY_POLICY },
            })
          );
        } else {
          window.open(PRIVACY_POLICY, '_blank');
        }
      },
    },
    {
      id: 'help',
      title: t('help'),
      icon: <QuestionIcon className={'text-text-01 h-4 w-4'} />,
      onClick: openHubspotChat,
    },
    {
      id: 'logout',
      title: t('sign-out'),
      icon: <LogOut className={'text-negative-01 h-4 w-4'} />,
      className: 'text-negative-01',
      onClick: actions.logout,
    },
  ];

  if (type === 'page') {
    return (
      <BlueUIShell
        useContentContainer={false}
        subNavTitle={title}
      >
        <div className={'flex w-full flex-col pl-8 pt-8'}>
          {items.map((item, index) => (
            <Item
              data-cy={`more-${index}`}
              key={item.id}
              {...item}
            />
          ))}
        </div>
      </BlueUIShell>
    );
  } else {
    return (
      <FloatingOptions
        data-cy={`more`}
        isOpen={isOptionsOpen}
        onClose={() => setIsOptionsOpen(false)}
        wrappedComponent={<div>{wrappedComponent(() => setIsOptionsOpen(true))}</div>}
        heightClassName="max-h-[80vh]"
        options={items.map((item, index) => ({
          key: item.id,
          dataCy: index,
          left: item.icon,
          label: item.subtitle ? (
            <div>
              <p className="font-medium">{item.title}</p>
              <p className="font-regular text-sm">{item.subtitle}</p>
            </div>
          ) : (
            item.title
          ),
          onClick: item.onClick,
          className: item.className,
        }))}
      />
    );
  }
};
