type EmptyCardSectionProps = {
  emptyText: string;
  icon?: React.ReactElement;
};
export const EmptyCardSection: React.FC<EmptyCardSectionProps> = ({ emptyText, icon }) => (
  <div className="text-text-03 flex flex-row items-center space-x-2">
    {icon && <span className="shrink-0">{icon}</span>}
    <span className="grow text-sm font-medium">{emptyText}</span>
  </div>
);
