import { Heading4, DragVerticalList, AddCard } from '@stellar-lms-frontend/ui-components';
import { LoadingMoreModules, ModuleSidebarActions } from './module-sidebar';
import { ModuleSidebarLoadingCard } from './module-sidebar-loading-card';
import { CourseModuleOutline } from '@stellar-lms-frontend/lms-graphql';
import { ModuleStore } from '../store';
import { Dispatch, useCallback } from 'react';
import { EditListItem } from './edit-list-item';
import { swapElements } from '@stellar-lms-frontend/common-utils';
import { useTranslation } from 'react-i18next';

type EditProps = {
  selectModule: Dispatch<string>;
  loadingMoreModules: LoadingMoreModules;
  highlightedModuleId?: string;
  actions?: ModuleSidebarActions;
  courseId: string;
  isAiCourse: boolean;
  isAIGenerating: boolean;
};

export const Edit: React.FC<EditProps> = ({
  selectModule,
  loadingMoreModules,
  highlightedModuleId,
  actions,
  courseId, // Move to a store at some point??
  isAiCourse,
  isAIGenerating,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'module-overview.module-list' });
  const { t: tLoading } = useTranslation('translation', { keyPrefix: 'dashboard.course.loading' });

  const [setModules, modules] = ModuleStore.useStore((state) => [state.setModules, state.modules]);

  const updateOrder = useCallback(
    (modules: CourseModuleOutline[]) => {
      setModules(modules);
      actions?.module.updateOrder?.({
        input: { courseId, moduleIds: modules.map((m) => m.id) },
      });
    },
    [actions?.module, courseId, setModules]
  );

  return (
    <div className="h-full w-full flex-col lg:flex">
      <div className="flex w-full flex-col self-start px-12 pt-16 pb-4 ">
        <Heading4 className="text-text-04 mb-4">{t('create-modules')}</Heading4>
        {loadingMoreModules.loading && (
          <ModuleSidebarLoadingCard
            title={tLoading('title')}
            description={tLoading('description')}
            className="mt-2 mb-6"
          />
        )}
        <div className="font-lexend mb-6 flex grow flex-col gap-4 text-base font-medium">
          <DragVerticalList
            listClassName="gap-4"
            items={modules}
            onChange={(modules) => updateOrder(modules)}
          >
            {({ item: module, setActivatorNodeRef, listeners, index }) => (
              <EditListItem
                key={module.id}
                setActivatorNodeRef={setActivatorNodeRef}
                listeners={listeners}
                module={module}
                actions={{
                  // For future refactorings we can think about putting these actions in the store instead.
                  moveUp: () => updateOrder(swapElements(modules, index, index - 1)),
                  moveDown: () => updateOrder(swapElements(modules, index, index + 1)),
                  duplicate: () => actions?.module.duplicate?.(module.id),
                  delete: () => actions?.module.delete?.(module.id),
                }}
                isHighlighted={highlightedModuleId === module.id}
                onClick={() => selectModule(module.id)}
              />
            )}
          </DragVerticalList>
          <AddCard
            isEnabled={!isAIGenerating}
            onClick={() => actions?.module.handleCreate?.()}
            label={isAiCourse ? t('add-behaviour') : t('add')}
          />
        </div>
      </div>
    </div>
  );
};
