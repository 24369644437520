import {
  CourseDetailsSection,
  LearnersList,
  CourseModuleList,
  Section,
  UsersList,
  DashboardLayout,
} from '@stellar-lms-frontend/lms-components';
import {
  CourseModuleOutline,
  mapLearnersToUsersList,
  mapUsersToUsersList,
  UserCourseStats,
} from '@stellar-lms-frontend/lms-graphql';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { LEARNER_COURSE_DASHBOARD } from '../../constants/query';
import { COURSE_ROUTE } from '../../constants/routes';
import { graphQLClient } from '../../lib/graphql';
import { getLearnerDashboard } from './learner-dashboard.api';
import { LogoLoader } from '@stellar-lms-frontend/ui-components';
import { MentorSection } from './components/mentor-section';
import { LearnerInsightsSection } from './components/learner-insights-section';
import { UIShell } from '../shell/ui-shell';

const MAX_LEARNERS = 6;

export const LearnerDashboardPage = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();

  const { t: tDashboard } = useTranslation('translation', {
    keyPrefix: 'learner-course-dashboard',
  });

  const courseDashboard = useQuery(
    [LEARNER_COURSE_DASHBOARD, courseId],
    () => (courseId ? getLearnerDashboard(graphQLClient('learner'), { id: courseId }) : null),
    { enabled: !!courseId }
  );

  const courseDesigners = useMemo(
    () => mapUsersToUsersList(courseDashboard.data?.designers),
    [courseDashboard.data?.designers]
  );
  const courseLearners = useMemo(
    () => mapLearnersToUsersList(courseDashboard.data?.learners, MAX_LEARNERS),
    [courseDashboard.data?.learners]
  );

  const courseMentors = useMemo(
    () => mapUsersToUsersList(courseDashboard.data?.mentors),
    [courseDashboard.data?.mentors]
  );

  const courseModuleOverview = useMemo(() => {
    if (!courseDashboard.data?.outline?.modules) {
      return undefined;
    }

    return calculateModuleStats(courseDashboard.data.outline.modules);
  }, [courseDashboard.data?.outline?.modules]);

  const keyInsights = useMemo(() => {
    const stats = courseDashboard.data?.courseStats as UserCourseStats;
    return stats
      ? {
          percentageCompleted: (stats.progress ?? 0) * 100,
          timeSpentInS: stats.timeSpentInS ?? 0,
          totalNumberOfActions: stats.totalNrOfActions ?? 0,
          completedActions: stats.nrOfActionsCompleted ?? 0,
        }
      : null;
  }, [courseDashboard.data?.courseStats]);

  const moduleClick = (moduleId: string) => {
    navigate(COURSE_ROUTE(courseId ?? '') + '?moduleId=' + moduleId);
  };

  return (
    <UIShell>
      {courseDashboard.data ? (
        <DashboardLayout
          left={
            <CourseDetailsSection
              course={courseDashboard.data}
              i18n={{
                title: tDashboard('course-details.title'),
                readLess: tDashboard('course-details.read-less'),
                readMore: tDashboard('course-details.read-more'),
                skills: {
                  label: tDashboard('course-details.skills.label'),
                  empty: tDashboard('course-details.skills.empty'),
                },
                objectives: {
                  label: tDashboard('course-details.business-objectives.label'),
                  empty: tDashboard('course-details.business-objectives.empty'),
                },
                transferObjectives: {
                  label: tDashboard('course-details.transfer-objectives.label'),
                  empty: tDashboard('course-details.transfer-objectives.empty'),
                },
                languages: {
                  label: tDashboard('course-details.language'),
                },
                documents: tDashboard('course-details.documents'),
              }}
            />
          }
          center={
            <>
              {keyInsights && <LearnerInsightsSection stats={keyInsights} />}
              {courseModuleOverview && (
                <Section title={tDashboard('course.title')}>
                  <CourseModuleList
                    modules={courseModuleOverview}
                    moduleClick={moduleClick}
                  />
                </Section>
              )}
            </>
          }
          right={
            <>
              {courseDashboard.data && courseDashboard.data.designers && (
                <Section
                  title={tDashboard('designer.label')}
                  data-cy={'designer-list'}
                >
                  {courseDesigners.length > 0 ? (
                    <UsersList users={courseDesigners} />
                  ) : (
                    <p className="text-text-02 text-sm">{tDashboard('designer.empty')}</p>
                  )}
                </Section>
              )}
              {courseDashboard.data && courseDashboard.data.mentors && (
                <MentorSection
                  invites={courseDashboard.data.invites}
                  data-cy={'mentor-list'}
                  mentors={courseMentors}
                />
              )}
              {courseDashboard.data && courseDashboard.data.learners && (
                <LearnersList
                  title={tDashboard('learners.title')}
                  learners={courseLearners}
                  dataCy="learners-list"
                />
              )}
            </>
          }
        />
      ) : (
        <LogoLoader />
      )}
    </UIShell>
  );
};

// Calculate the module stats based on learning activity level
function calculateModuleStats(modules: CourseModuleOutline[]) {
  return modules.map((module) => {
    const startedLearningActivityCount =
      module.learningActivities?.reduce(
        (prev, curr) => (curr.steps?.some((step) => step.visited) ? prev + 1 : prev),
        0
      ) ?? 0;
    const completedLearningActivityCount =
      module.learningActivities?.reduce((prev, curr) => {
        if (curr.steps && curr.steps.length > 0) {
          return curr.steps.every((step) => step.completed) ? prev + 1 : prev;
        } else {
          return prev;
        }
      }, 0) ?? 0;

    const learningActivityCount = module.learningActivities?.length ?? 0;
    return {
      ...module,
      stats: {
        startedPercentage: (startedLearningActivityCount / learningActivityCount) * 100,
        completedPercentage: (completedLearningActivityCount / learningActivityCount) * 100,
      },
    };
  });
}
