import { DisabledCross, NegativeCross, PositiveCheck } from '../../../icons';
import { SelectOptionAnswer } from '../types';
import { SelectBox } from '../components/select-box/select-box';

export type EvaluatedSelectProps = {
  answers: SelectOptionAnswer[];
  withCheckbox?: boolean;
  isCollapsed?: boolean;
};

export const EvaluatedSelect: React.FC<EvaluatedSelectProps> = ({
  answers,
  withCheckbox = false,
  isCollapsed,
}) => {
  const renderCorrectionIcon = (
    userAnswered: boolean,
    correctAnswer: boolean,
    answeredCorrect: boolean
  ) => {
    if (!userAnswered) {
      if (correctAnswer) {
        return <PositiveCheck />;
      } else {
        return <DisabledCross />;
      }
    }

    if (answeredCorrect === true) {
      return <PositiveCheck />;
    } else {
      return <NegativeCross />;
    }
  };

  const filteredAnswers = isCollapsed ? answers.filter((answer) => answer.userAnswered) : answers;

  const renderInput = (answer: SelectOptionAnswer, index: number) => {
    return (
      <div className="flex flex-row">
        {withCheckbox && (
          <input
            name={`option-${index}`}
            type="checkbox"
            className={`
                    ${
                      answer.userAnswered
                        ? answer.userAnsweredCorrect
                          ? 'positive '
                          : 'negative '
                        : ''
                    }
                    border-border-01 checked:border-border-01 hover:border-border-01 checked:hover:border-border-01 focus:border-border-01 checked:focus:border-border-01 bg-surface-01
                    checked:bg-surface-01 hover:bg-surface-01 checked:hover:bg-surface-01 focus:bg-surface-01  checked:focus:bg-surface-01 mr-4
                    mt-[2px] h-5 w-5 rounded-md
                    focus:shadow-none focus:outline-none focus:ring-0 focus:ring-offset-0`}
            disabled
            checked
          ></input>
        )}
        <label
          htmlFor={`answer-${index}`}
          className="mr-4 grow select-none whitespace-pre-wrap"
        >
          {answer.answerText}
        </label>

        <div className="h-6 w-6 shrink-0">
          {renderCorrectionIcon(
            answer.userAnswered,
            answer.answerCorrect,
            answer.userAnsweredCorrect
          )}
        </div>
      </div>
    );
  };

  const renderSelectOptionBox = (answer: SelectOptionAnswer, index: number) => {
    if (answer.userAnswered === false) {
      if (answer.answerCorrect) {
        return <SelectBox type="unselected-positive">{renderInput(answer, index)}</SelectBox>;
      } else {
        return <SelectBox type="disabled">{renderInput(answer, index)}</SelectBox>;
      }
    }

    if (answer.userAnsweredCorrect === true) {
      return <SelectBox type="positive">{renderInput(answer, index)}</SelectBox>;
    } else {
      return <SelectBox type="negative">{renderInput(answer, index)}</SelectBox>;
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      {filteredAnswers.map((answer, index) => (
        <div key={`option-${index}`}>{renderSelectOptionBox(answer, index)}</div>
      ))}
    </div>
  );
};

export default EvaluatedSelect;
