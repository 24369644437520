import {
  ChevronDownIcon,
  FloatingOptions,
  LinkButton,
  PrimaryButton,
  VerticalSeparator,
} from '@stellar-lms-frontend/ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClose,
  faPersonWalkingArrowRight,
  faTrashCan,
  faPen,
  faUserPlus,
  faFileImport,
} from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import { faLanguage, faLockKeyhole } from '@fortawesome/pro-light-svg-icons';
import { MemberEditActionType } from '../../edit-members-sidebar';
import { SupportedLanguagesOptions } from '../../../../../../types/types';
import { useTranslation } from 'react-i18next';

export type MembersTableActionsProps = {
  selectedRowsIds: string[];
  onFilterChange: (filter: string) => void;
  onSearchChange: (search: string) => void;
  onDeselectAll: () => void;
  onDeleteAll: () => void;
  onEditSelected: (type: MemberEditActionType) => void;
  onAddToJourney: () => void;
  onAddToOrganization: () => void;
  onBulkAddToOrganization: () => void;
  supportedLanguages: SupportedLanguagesOptions;
  defaultLanguage: string;
};

export const MembersTableActions: React.FC<MembersTableActionsProps> = ({
  selectedRowsIds,
  onDeselectAll,
  onDeleteAll,
  onEditSelected,
  onAddToJourney,
  onAddToOrganization,
  onBulkAddToOrganization,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'organization.members-tab.actions' });

  const [isActionsOptionsOpen, setIsActionsOptionsOpen] = useState(false);
  const [isAddOptionsOpen, setIsAddOptionsOpen] = useState(false);

  return (
    <div className="font-lexend">
      {selectedRowsIds.length === 0 ? (
        <div className="flex justify-end gap-4">
          <FloatingOptions
            wrappedComponent={
              <button onClick={() => setIsAddOptionsOpen(true)}>
                <PrimaryButton
                  className="shrink-0 grow-0"
                  label={t('add.label')}
                  rightIcon={<ChevronDownIcon className="text-on-primary-01 h-6 w-6" />}
                />
              </button>
            }
            options={[
              {
                label: t('add.options.invite'),
                left: <FontAwesomeIcon icon={faUserPlus} />,
                onClick: onAddToOrganization,
              },
              {
                label: t('add.options.bulk'),
                left: <FontAwesomeIcon icon={faFileImport} />,
                onClick: onBulkAddToOrganization,
              },
            ]}
            isOpen={isAddOptionsOpen}
            onClose={() => setIsAddOptionsOpen(false)}
          />
        </div>
      ) : (
        <div className="bg-primary-03 flex items-center justify-between rounded-lg p-2 pl-6">
          <div className="flex items-center gap-4">
            <p className="type-body-medium text-primary-01">
              {t('users-selected', { count: selectedRowsIds.length })}
            </p>
            <VerticalSeparator />
            <LinkButton
              label={t('deselect')}
              leftIcon={<FontAwesomeIcon icon={faClose} />}
              buttonStyle="fit"
              onClick={onDeselectAll}
            />
          </div>
          <FloatingOptions
            wrappedComponent={
              <button onClick={() => setIsActionsOptionsOpen(true)}>
                <PrimaryButton
                  className="shrink-0 grow-0 px-4 py-2"
                  label={t('actions.label')}
                  rightIcon={<ChevronDownIcon className="text-on-primary-01 h-6 w-6" />}
                  buttonStyle="fit_small_padding"
                  enterListener={false}
                />
              </button>
            }
            options={[
              {
                label: t('actions.options.add-to-journey'),
                left: <FontAwesomeIcon icon={faPersonWalkingArrowRight} />,
                onClick: onAddToJourney,
                type: 'option',
              },
              {
                type: 'divider',
              },
              {
                type: 'title',
                label: t('actions.options.edit.title'),
              },
              {
                label: t('actions.options.edit.role'),
                left: <FontAwesomeIcon icon={faPen} />,
                onClick: () => onEditSelected('jobTitle'),
              },
              {
                label: t('actions.options.edit.language'),
                left: <FontAwesomeIcon icon={faLanguage} />,
                onClick: () => onEditSelected('language'),
              },
              {
                label: t('actions.options.edit.access'),
                left: <FontAwesomeIcon icon={faLockKeyhole} />,
                onClick: () => onEditSelected('access'),
              },
              {
                type: 'divider',
              },
              {
                label: t('actions.options.delete'),
                left: <FontAwesomeIcon icon={faTrashCan} />,
                className: 'text-negative-01',
                onClick: onDeleteAll,
                type: 'option',
              },
            ]}
            isOpen={isActionsOptionsOpen}
            onClose={() => setIsActionsOptionsOpen(false)}
          />
        </div>
      )}
    </div>
  );
};
