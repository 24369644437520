import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';
import { graphql } from '@stellar-lms-frontend/lms-graphql';
import { useQuery } from '@tanstack/react-query';
import { SingleNumberChart } from '@stellar-lms-frontend/ui-components';

const getCompletedSRQs = async (companyId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query completedSRQs($companyId: ID!) {
        companyCompletedSRQs(companyId: $companyId)
      }
    `),
    { companyId: companyId }
  );
};

export const CompletedSRQsChart = ({ companyId }: { companyId: string }) => {
  const { data, isLoading } = useQuery(
    ['completed-srqs', companyId],
    () => getCompletedSRQs(companyId),
    { refetchOnWindowFocus: false }
  );

  return (
    <SingleNumberChart
      value={data?.companyCompletedSRQs}
      isLoading={isLoading}
    />
  );
};
