export type ReadOnlyInputProps = {
  label: string;
  value: string;
};
export const ReadOnlyInput = ({ label, value }: ReadOnlyInputProps) => {
  return (
    <div className="w-full">
      <label className={'font-lexend text-text-04 block text-sm font-medium '}>{label}</label>
      <div className={'font-lexend font-regular text-text-01 mt-1 mb-[1px] w-full text-base'}>
        {value}
      </div>
    </div>
  );
};
