import {
  Badge,
  CheckSmallLight,
  Heading1,
  Heading4,
  PrimaryButton,
} from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ContactSalesModal } from './contact-sales-modal';

export const EnterprisePlanCard = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.enterprise-plan-card' });
  const [isContactSalesModalOpen, setIsContactSalesModalOpen] = useState(false);

  // Some fun to get an actual array from returnObjects..
  const includedFeatures = t<
    'included-features',
    { returnObjects: true; defaultValue: [] },
    string[]
  >('included-features', { returnObjects: true, defaultValue: [] });

  return (
    <>
      <div className="bg-blue-gradient text-on-primary-01 flex w-[380px] flex-col items-start gap-6 rounded-xl p-6 text-left shadow-md">
        <Badge color={'light-blue'}>{t('badge')}</Badge>
        <div className="space-y-2">
          <Heading1>{t('title')}</Heading1>
          <Heading4 className="text-blue-100">{t('subTitle')}</Heading4>
        </div>
        <div className="grow space-y-3">
          <span className="primary-primary-03 type-small-medium">{t('features')}</span>
          <div className="grid-cols-icon-content-large grid items-center gap-5">
            {includedFeatures.map((feature) => (
              <>
                <CheckSmallLight /> <span>{feature}</span>
              </>
            ))}
          </div>
        </div>
        <PrimaryButton
          theme="dark"
          onClick={() => setIsContactSalesModalOpen(true)}
          label={t('call-to-action')}
        />
      </div>
      <ContactSalesModal
        isOpen={isContactSalesModalOpen}
        onClose={() => setIsContactSalesModalOpen(false)}
      />
    </>
  );
};
