import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { LogoLoader } from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { COURSE } from '../../constants/query';
import { Error404 } from '../error/error-404';
import { Error403 } from '../error/error-403';
import { getLearnerJourneyOutline } from './course-overview.api';
import {
  CourseOutline,
  LearningActivityProgress,
  useCurrentCompany,
  useCurrentUser,
} from '@stellar-lms-frontend/lms-graphql';
import {
  defaultGraphqlClient,
  navigation,
  RequestError,
  RequestStatus,
} from '@stellar-lms-frontend/common-utils';
import { ModuleList } from '@stellar-lms-frontend/lms-components';
import { UIShell } from '../shell/ui-shell';

const NAVIGATION_KEY = 'course-overview';
export function CourseOverview() {
  const [searchParams] = useSearchParams();
  const [nextModuleIdToSelect, setNextModuleIdToSelect] = useState<string | undefined>(
    () => searchParams.get('moduleId') ?? undefined
  );
  const [forcedModuleId, setForcedModuleId] = useState<string | undefined>(undefined);
  const { courseId } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: 'course-overview' });
  const { t: tModuleOverview } = useTranslation('translation', { keyPrefix: 'module-overview' });
  const { t: t403 } = useTranslation('translation', { keyPrefix: '403' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const {
    query: { data: currentCompany },
  } = useCurrentCompany(defaultGraphqlClient);

  const {
    query: { data: currentUser },
  } = useCurrentUser(defaultGraphqlClient);

  const { data: courseOutline, error } = useQuery<CourseOutline | null, RequestError>(
    [COURSE, courseId],
    () => (courseId ? getLearnerJourneyOutline({ id: courseId }) : null),
    {
      onSuccess: (data) => {
        let forcedId = nextModuleIdToSelect;

        if (data && !forcedId) {
          forcedId =
            getFirstModuleWithLearningActivityInStatus(data, LearningActivityProgress.InProgress) ??
            getFirstModuleWithLearningActivityInStatus(data, LearningActivityProgress.Todo) ??
            undefined;
        }

        setForcedModuleId(forcedId);
        setNextModuleIdToSelect(undefined);
      },
    }
  );

  useEffect(() => {
    return () => navigation.clear();
  }, []);

  if (error && error.status === RequestStatus.NOT_FOUND) return <Error404 />;
  if (error && error.status === RequestStatus.NO_ACCESS) {
    navigation.setTitle(NAVIGATION_KEY, t403('title'));
    return <Error403 />;
  }

  return (
    <UIShell
      autoScrollTop={false}
      useContentContainer={false}
      scrollOnDesktop={true}
    >
      {courseId && courseOutline ? (
        <ModuleList.ModuleList
          currentCompanyId={currentCompany?.id ?? undefined}
          currentUserId={currentUser?.id ?? undefined}
          userRole="learner"
          forcedModuleId={forcedModuleId}
          initialModules={courseOutline.modules ?? []}
          courseId={courseId}
          i18n={{
            learningActivityList: {
              activityAvailableOnTooltip: (date: string) =>
                tModuleOverview('activity-available-on', { date: date }),
              activityAvailableWhenTooltip: (count: number, prerequisites: string) =>
                tModuleOverview('activity-available-when', {
                  count: count,
                  prerequisites: prerequisites,
                }),
              activityCommentCount: (count: number) =>
                tModuleOverview('activity-comment-count', { count: count }),
              lockedActivityText: tModuleOverview('locked-activity'),
              emptyStateMentorLearnerMessage: t('empty-learning-activities'),
              aiBadgeLabel: tGeneral('ai-badge-label'),
              descriptionSection: {
                duration: {
                  timeHourShort: tGeneral('time-hour-short'),
                  timeMinuteShort: tGeneral('time-minute-short'),
                  hour: (count) => tGeneral('hour', { count }),
                  min: (count) => tGeneral('min', { count }),
                },
              },
            },
          }}
        />
      ) : (
        <LogoLoader />
      )}
    </UIShell>
  );
}

const getFirstModuleWithLearningActivityInStatus = (
  data: CourseOutline,
  status: LearningActivityProgress
) => {
  for (const module of data.modules ?? []) {
    for (const learningActivity of module.learningActivities ?? []) {
      if (learningActivity.progress === status) {
        return module.id;
      }
    }
  }
  return null;
};

export default CourseOverview;
