import { Nullable } from '@stellar-lms-frontend/common-utils';
import React from 'react';
import { Dispatch, SetStateAction } from 'react';

type WizardState = {
  activeStepIndex: number;
  setActiveStepIndex: Dispatch<SetStateAction<number>>;
  isCurrentStepValid: boolean;
  setIsCurrentStepValid: Dispatch<SetStateAction<boolean>>;
  nextFunc: Nullable<() => Promise<boolean>>;
  setNextFunc: Dispatch<Nullable<() => Promise<boolean>>>;
  prevFunc: Nullable<() => Promise<boolean>>;
  setPrevFunc: Dispatch<Nullable<() => Promise<boolean>>>;
  enterFunc: Nullable<() => void>;
  setEnterFunc: Dispatch<Nullable<() => void>>;
  goToStep: (newStepIndex: number) => void;
};

export const WizardContext = React.createContext<WizardState>({
  activeStepIndex: 0,
  setActiveStepIndex: () => null,
  isCurrentStepValid: true,
  setIsCurrentStepValid: () => null,
  nextFunc: () => Promise.resolve(true),
  setNextFunc: () => null,
  prevFunc: () => Promise.resolve(true),
  setPrevFunc: () => null,
  enterFunc: () => Promise.resolve(undefined),
  setEnterFunc: () => null,
  goToStep: () => null,
});
