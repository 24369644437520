import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  ColumnDef,
  SortingState,
  Row,
} from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CheckBox,
  FlexTable,
  LinkButton,
  LogoLoader,
  PrimaryButton,
} from '@stellar-lms-frontend/ui-components';
import { StatusCell, StatusType } from './components/users-table/components/status-cell';
import { AccessCell, AccessType } from './components/users-table/components/access-cell';
import { OptionsMenuCell } from './components/users-table/components/options-menu-cell';

import { GraphQLClient } from 'graphql-request';
import {
  CompanyRole,
  CourseRole,
  InputLanguage,
  SortDirection,
  SubscriptionType,
  UserInput,
  UserSort,
  UserSortField,
  UserUpdateInput,
  addUserToCompany,
  bulkAddToJourney,
  bulkDeleteUserFromCompany,
  bulkUpdateUsersFromCompany,
  bulkUploadUsersToCompany,
  getUploadUrl,
  resendCompanyMemberInvite,
  transferToInputLanguage,
  updateUserFromCompany,
  uploadFile,
  useCurrentCompany,
} from '@stellar-lms-frontend/lms-graphql';
import { useMutation } from '@tanstack/react-query';
import { MembersTableActions } from './components/users-table/components/members-table-actions';
import {
  transformOutgoingCompanyRole,
  transformOutgoingLanguageCode,
  transformIncomingUser,
  transformOutgoingCourseRole,
} from './utils/transformers';
import { useUsersOfCompany } from './utils/use-users-of-company';
import { toast } from 'react-toastify';
import { EditMemberSidebar } from './components/edit-member-sidebar';
import { ResendMemberInviteSidebar } from './components/resend-member-invite-sidebar';
import {
  ErrorMessageTableRowShape,
  BulkErrorModal,
} from './components/bulk-error-modal/bulk-error-modal';
import { ConfirmationModal } from '../../../confirmation-modal/confirmation-modal';
import { EditMembersSidebar, MemberEditActionType } from './components/edit-members-sidebar';
import { AddToJourneySidebar } from './components/add-to-journey-sidebar';
import { SearchItem, SupportedLanguagesOptions } from '../../../types/types';
import {
  BulkAddToJourneyErrorItem,
  BulkAddToJourneyErrorModal,
} from './components/bulk-add-to-journey-error-modal/bulk-add-to-journey-error-modal';
import { InviteMemberModal } from './components/invite-member-modal';
import { MemberBulkImportModal } from './components/member-bulk-import-modal';
import { NoMembersView } from './components/no-members-view';
import { useTranslation } from 'react-i18next';

const DEFAULT_PAGE_SIZE = 20;

export type UserTableRowShape = {
  id: string;
  name: string;
  role: string;
  status: StatusType;
  manager: string;
  access: AccessType;
  email: string;
  language: string;
};

const columnHelper = createColumnHelper<UserTableRowShape>();

export type MembersTabProps = {
  companyId: string;
  graphQLClient: GraphQLClient;
  supportedLanguages: SupportedLanguagesOptions;
  defaultLanguage: string;
  searchableCourses: SearchItem[];
};

enum TableColumns {
  NAME = 'NAME',
  STATUS = 'STATUS',
  ROLE = 'ROLE',
  ACCESS = 'ACCESS',
}

const mapSorting = (sort: SortingState): UserSort | undefined => {
  if (sort.length > 0) {
    const direction = sort[0].desc ? SortDirection.Desc : SortDirection.Asc;
    switch (sort[0].id) {
      case TableColumns.NAME:
        return { field: UserSortField.Name, direction };
      case TableColumns.ROLE:
        return { field: UserSortField.Jobtitle, direction };
      case TableColumns.STATUS:
        return { field: UserSortField.Status, direction };
      case TableColumns.ACCESS:
        return { field: UserSortField.CompanyRole, direction };
    }
  }
  return undefined;
};

export const MembersTab: React.FC<MembersTabProps> = ({
  companyId,
  graphQLClient,
  supportedLanguages,
  defaultLanguage,
  searchableCourses,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organization.members-tab',
  });
  const { t: tToast } = useTranslation('translation', {
    keyPrefix: 'organization.members-tab.toast',
  });
  const { t: tHeaders } = useTranslation('translation', {
    keyPrefix: 'organization.members-tab.headers',
  });
  const { t: tdeleteUserModal } = useTranslation('translation', {
    keyPrefix: 'organization.members-tab.delete-user-modal',
  });

  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageIndex, setPageIndex] = useState(0);
  const [editingUser, setEditingUser] = useState<undefined | UserTableRowShape>(undefined);
  const [bulkUploadErrors, setBulkUploadErrors] = useState<ErrorMessageTableRowShape[]>([]);
  const [isBulkErrorModalOpen, setIsBulkErrorModalOpen] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState<string[] | undefined>(undefined);
  const [deleteUserIds, setDeleteUserIds] = useState<string[] | undefined>(undefined);
  const [sorting, setSorting] = useState<SortingState>([{ id: TableColumns.NAME, desc: false }]);
  const [showMultiUserUpdateSidebar, setShowMultiUserUpdateSidebar] = useState<
    MemberEditActionType | undefined
  >(undefined);

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<undefined | number>(undefined);

  const [showAddToJourneySidebar, setShowAddToJourneySidebar] = useState<boolean>(false);
  const [bulkAddToJourneyErrors, setBulkAddToJourneyErrors] = useState<BulkAddToJourneyErrorItem[]>(
    []
  );
  const [isBulkAddToJourneyErrorModalOpen, setIsBulkAddToJourneyErrorModalOpen] =
    useState<boolean>(false);

  const [previousSelectedRow, setPreviousSelectedRow] = useState<
    Row<UserTableRowShape> | undefined
  >(undefined);

  const {
    query: { data: currentCompany },
  } = useCurrentCompany(graphQLClient);

  const {
    query: { data, isLoading },
    invalidateQueries: invalidateUsersQuery,
  } = useUsersOfCompany(graphQLClient, {
    companyId,
    pageSize: pageSize,
    cursor: (pageSize * pageIndex).toString(),
    sort: mapSorting(sorting),
  });

  const {
    query: { data: userPage },
    invalidateQueries: invalidateAllUsersQuery,
  } = useUsersOfCompany(graphQLClient, {
    companyId,
    pageSize: 0,
  });

  const invalidateUserQueries = useCallback(() => {
    invalidateUsersQuery();
    invalidateAllUsersQuery();
  }, [invalidateAllUsersQuery, invalidateUsersQuery]);

  const users = useMemo<UserTableRowShape[]>(
    () => data?.users?.map(transformIncomingUser) ?? [],
    [data]
  );

  const { mutateAsync: addUserToCompanyMutation } = useMutation(
    (user: UserInput) =>
      addUserToCompany(graphQLClient, {
        companyId,
        input: user,
      }),
    {
      onSuccess: () => {
        invalidateUserQueries();
        if (currentCompany?.subscription?.type === SubscriptionType.StripeManaged) {
          toast.success(tToast('add-user-success', { count: 1 }));
        } else {
          toast.success(tToast('add-user'));
        }
      },
    }
  );

  const { mutate: updateUserFromCompanyMutation } = useMutation(
    (user: UserUpdateInput & { id: string }) =>
      updateUserFromCompany(graphQLClient, {
        companyId,
        input: {
          jobTitle: user.jobTitle,
          language: user.language,
          name: user.name,
          role: user.role,
        },
        userId: user.id,
      }),
    {
      onSuccess: () => {
        invalidateUserQueries();
        toast.success(tToast('update-user'));
      },
    }
  );

  const { mutate: bulkAddToJourneyMutation } = useMutation(
    (data: {
      courseId: string;
      input: { userIds: string[]; courseRole: CourseRole; mentorId: string | undefined };
    }) => bulkAddToJourney(graphQLClient, data),
    {
      onSuccess: (data, variables) => {
        if (
          data.batchAddRegistrationsForCourse &&
          data.batchAddRegistrationsForCourse.errors &&
          (data.batchAddRegistrationsForCourse?.errors?.length ?? 0) > 0
        ) {
          const errors: BulkAddToJourneyErrorItem[] = [];

          const userIdToName: Record<string, string> = {};
          userPage?.users?.forEach((user) => {
            if (user.name) {
              userIdToName[user.id] = user.name ?? '';
            }
          });

          data.batchAddRegistrationsForCourse?.errors.forEach((error) => {
            if (error?.userId && error?.errorCode) {
              const userName = userIdToName[error.userId];
              errors.push({
                name: userName ?? error.userId,
                error: error.errorCode,
              });
            }
          });

          if (errors.length > 0)
            toast.error(
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setIsBulkAddToJourneyErrorModalOpen(true);
                }}
                className="text-on-inverse-01 cursor-pointer space-y-1 text-left"
              >
                <p className="type-small-emphasis">{tToast('add-to-journey-error.message')}</p>
                <p className="type-small underline">{tToast('add-to-journey-error.link')}</p>
              </button>,
              {
                autoClose: 10000,
              }
            );

          setBulkAddToJourneyErrors(errors);
        } else if (currentCompany?.subscription?.type === SubscriptionType.StripeManaged) {
          toast.success(
            tToast('add-user-success', {
              count:
                variables.input.userIds.length -
                (data.batchAddRegistrationsForCourse?.errors?.length ?? 0),
            })
          );
        } else {
          toast.success(tToast('add-user'));
        }
      },
    }
  );

  const { mutate: bulkUpdateUsersFromCompanyMutation } = useMutation(
    (input: {
      userIds: string[];
      jobTitle: string | undefined;
      role: CompanyRole | null;
      language: InputLanguage | null;
    }) =>
      bulkUpdateUsersFromCompany(graphQLClient, {
        companyId,
        input,
      }),
    {
      onSuccess: () => {
        invalidateUserQueries();
        toast.success(tToast('update-user'));
      },
    }
  );

  const { mutate: bulkDeleteUserFromCompanyMutation } = useMutation(
    (userIds: string[]) =>
      bulkDeleteUserFromCompany(graphQLClient, {
        companyId,
        userIds,
      }),
    {
      onSuccess: (data, userIds) => {
        invalidateUserQueries();
        if (
          data.bulkDeleteUsers &&
          currentCompany?.subscription?.type === SubscriptionType.StripeManaged
        ) {
          toast.success(tToast('delete-user-success', { count: userIds.length }));
        } else if (data.bulkDeleteUsers) {
          toast.success(tToast('delete-user'));
        } else {
          toast.error(tToast('delete-user-error'));
        }
      },
    }
  );

  const { mutate: resendCompanyMemberInviteMutation } = useMutation(
    (userId: string) =>
      resendCompanyMemberInvite(graphQLClient, {
        requestPasswordResetId: userId,
      }),
    {
      onSuccess: () => {
        toast.success(tToast('resend-invite'));
      },
    }
  );

  const { mutateAsync: bulkUploadUsersToCompanyMutation } = useMutation(
    (fileKey: string) =>
      bulkUploadUsersToCompany(graphQLClient, {
        companyId,
        input: {
          fileKey,
        },
      }),
    {
      onSuccess: (res) => {
        if (res.bulkUploadUsers?.items?.some((i) => !i?.success)) {
          toast.error(
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsBulkErrorModalOpen(true);
              }}
              className="text-on-inverse-01 cursor-pointer space-y-1 text-left"
            >
              <p className="type-small-emphasis">{tToast('add-users-error.message')}</p>
              <p className="type-small underline">{tToast('add-users-error.link')}</p>
            </button>,
            {
              autoClose: 10000,
            }
          );
          setBulkUploadErrors(
            res.bulkUploadUsers?.items
              .map((i, index) =>
                !i?.success && i?.id && i?.error?.errorCode
                  ? {
                      line: index,
                      email: i?.id,
                      error: i?.error?.errorCode,
                    }
                  : undefined
              )
              .flatMap((i) => (i ? [i] : []))
          );
        } else if (currentCompany?.subscription?.type === SubscriptionType.StripeManaged) {
          toast.success(tToast('add-users-stripe'));
        } else {
          toast.success(tToast('add-users'));
        }
        invalidateUserQueries();
      },
    }
  );

  const onUpload = useCallback(
    async (file: File) => {
      const { getUploadUrl: uploadUrl } = await getUploadUrl(graphQLClient, {
        fileName: file.name,
        mimeType: 'text/csv',
        companyId,
      });

      if (!uploadUrl?.url || !uploadUrl.fileId) return;

      try {
        await uploadFile(uploadUrl.url, file, (progress) => {
          setUploadProgress(progress);
        });
        await bulkUploadUsersToCompanyMutation(uploadUrl?.fileId);
        setIsBulkModalOpen(false);
      } finally {
        setUploadProgress(undefined);
        invalidateUserQueries();
      }
    },
    [graphQLClient, companyId, bulkUploadUsersToCompanyMutation, invalidateUserQueries]
  );

  const columns = useMemo<ColumnDef<UserTableRowShape, any>[]>(
    () => [
      {
        id: 'select',
        maxSize: 32,
        minSize: 32,
        size: 32,
        header: ({ table }) => (
          <CheckBox
            variant="white-check"
            value={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <CheckBox
            variant="white-check"
            value={row.getIsSelected()}
            onChange={(e) => {
              // do nothing
            }}
          />
        ),
        meta: {
          alignment: 'center' as const,
          onClick: (e, row) => {
            e.stopPropagation();
            row.getToggleSelectedHandler()(e);
          },
        },
      },
      columnHelper.accessor((row) => row.name, {
        id: TableColumns.NAME,
        header: tHeaders('name'),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row.role, {
        id: TableColumns.ROLE,
        header: tHeaders('role'),
        cell: (info) => <span className="text-sm">{info.getValue()}</span>,
      }),
      columnHelper.accessor((row) => row.status, {
        id: TableColumns.STATUS,
        header: tHeaders('status'),
        cell: (info) => <StatusCell status={info.getValue()} />,
      }),
      columnHelper.accessor((row) => row.access, {
        id: TableColumns.ACCESS,
        header: tHeaders('access'),
        cell: (info) => <AccessCell access={info.getValue()} />,
      }),
      {
        id: 'options',
        header: () => null,
        cell: ({ row }) => (
          <OptionsMenuCell
            onEdit={() => {
              const user = users?.find((u) => u.id === row.original.id);
              if (!user) return;
              setEditingUser(user);
            }}
            onAddToJourney={() => {
              setSelectedUserIds([row.original.id]);
              setShowAddToJourneySidebar(true);
            }}
            onDelete={() => setDeleteUserIds([row.original.id])}
            supportedLanguages={supportedLanguages}
          />
        ),
      },
    ],
    [supportedLanguages, tHeaders, users]
  );

  const table = useReactTable({
    data: users,
    columns,
    initialState: { pagination: { pageSize: DEFAULT_PAGE_SIZE } },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    enableRowSelection: true,
    manualPagination: true,
    manualSorting: true,
    enableMultiSort: false,
    pageCount: Math.ceil((data?.totalCount ?? 0) / pageSize),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  useEffect(() => {
    setPageIndex(table.getState().pagination.pageIndex);
    // Disabled because it's a function call dependency and eslint doesn't recognize it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getState().pagination.pageIndex]);

  useEffect(() => {
    setPageSize(table.getState().pagination.pageSize);
    // Disabled because it's a function call dependency and eslint doesn't recognize it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getState().pagination.pageSize]);

  const findUserAdjacent = (userId: string, location: 'prev' | 'next') => {
    return users[users.findIndex((u) => u.id === userId) + (location === 'prev' ? -1 : 1)];
  };

  const findUserRow = (userId: string) => {
    return table.getRowModel().rows.find((row) => row.original.id === userId);
  };

  const onNavigate = (userId: string, location: 'prev' | 'next') => {
    const user = findUserAdjacent(userId, location);
    if (!user) return;
    setEditingUser(user);
    const row = findUserRow(user.id);
    table.resetRowSelection();
    table.resetExpanded();
    row?.toggleExpanded(true);
  };

  const renderContentView = () => {
    if (isLoading) {
      return <LogoLoader />;
    }

    if ((data?.totalCount ?? 0) > 0) {
      return (
        <>
          <MembersTableActions
            selectedRowsIds={table.getSelectedRowModel().rows.map((r) => r.original.id)}
            onFilterChange={() => null}
            onSearchChange={() => null}
            onDeselectAll={() => {
              setPreviousSelectedRow(undefined);
              table.resetRowSelection(true);
            }}
            onDeleteAll={() => {
              setPreviousSelectedRow(undefined);
              setDeleteUserIds(table.getSelectedRowModel().rows.map((r) => r.original.id));
            }}
            onEditSelected={(type) => {
              setPreviousSelectedRow(undefined);
              setShowMultiUserUpdateSidebar(type);
            }}
            onAddToJourney={() => {
              setPreviousSelectedRow(undefined);
              setSelectedUserIds(table.getSelectedRowModel().rows.map((r) => r.original.id));
              setShowAddToJourneySidebar(true);
            }}
            onAddToOrganization={() => {
              setPreviousSelectedRow(undefined);
              setIsInviteModalOpen(true);
            }}
            onBulkAddToOrganization={() => {
              setPreviousSelectedRow(undefined);
              setIsBulkModalOpen(true);
            }}
            supportedLanguages={supportedLanguages}
            defaultLanguage={defaultLanguage}
          />
          <FlexTable
            rowClick={(row, e) => {
              if (e.shiftKey) {
                const pageRows = table.getPaginationRowModel().rows;
                if (previousSelectedRow !== undefined) {
                  let startIndex = row.index;
                  let endIndex = previousSelectedRow.index - 1;

                  if (previousSelectedRow.index < row.index) {
                    startIndex = previousSelectedRow.index + 1;
                    endIndex = row.index;
                  }
                  for (let i = startIndex; i <= endIndex; i++) {
                    pageRows[i].toggleSelected();
                  }
                  setPreviousSelectedRow(row);
                } else {
                  setPreviousSelectedRow(row);
                }
                row.toggleSelected();
              } else if (e.metaKey) {
                row.toggleSelected();
                setPreviousSelectedRow(undefined);
              } else {
                setPreviousSelectedRow(undefined);
                setEditingUser(users.find((u) => u.id === row.original.id));
                table.resetRowSelection();
                row.toggleExpanded();
              }
            }}
            totalRows={data?.totalCount}
            className="text-text-02 select-none"
            i18n={{
              pageSizeSelector: {
                showing: t('page-size-selector.showing'),
                of: (count) => t('page-size-selector.of', { count }),
              },
            }}
            table={table}
          />
        </>
      );
    }
    return (
      <NoMembersView
        onAddMemberClick={() => setIsInviteModalOpen(true)}
        onBulkAddMemberClick={() => setIsBulkModalOpen(true)}
      />
    );
  };

  return (
    <div className="space-y-3 overflow-auto py-14 pl-8 pr-16">
      {renderContentView()}

      {editingUser?.status === 'signed-in' && (
        <EditMemberSidebar
          isOpen={editingUser?.status === 'signed-in'}
          onClose={() => {
            setEditingUser(undefined);
            table.resetExpanded();
          }}
          onSubmit={(data) => {
            updateUserFromCompanyMutation({
              ...data,
              id: editingUser.id,
              name: data.fullName,
              language: transformOutgoingLanguageCode(data.language.toLowerCase()),
              role: transformOutgoingCompanyRole(data.access),
            });
            setEditingUser(undefined);
            table.resetExpanded();
          }}
          onDelete={() => setDeleteUserIds([editingUser?.id])}
          onNavigateLeft={() => onNavigate(editingUser.id, 'prev')}
          onNavigateRight={() => onNavigate(editingUser.id, 'next')}
          user={{
            ...editingUser,
            fullName: editingUser.name,
            jobTitle: editingUser.role,
          }}
          supportedLanguages={supportedLanguages}
        />
      )}
      {editingUser?.status === 'pending' && (
        <ResendMemberInviteSidebar
          isOpen={editingUser?.status === 'pending'}
          onClose={() => {
            setEditingUser(undefined);
          }}
          onNavigateLeft={() => onNavigate(editingUser.id, 'prev')}
          onNavigateRight={() => onNavigate(editingUser.id, 'next')}
          email={editingUser.email}
          onResendInvite={() => resendCompanyMemberInviteMutation(editingUser.id)}
        />
      )}
      {deleteUserIds !== undefined && (
        <ConfirmationModal
          title={tdeleteUserModal('title')}
          description={tdeleteUserModal('description')}
          buttons={{
            hasShadow: false,
            buttons: [
              <PrimaryButton
                key={1}
                theme="red"
                label={tdeleteUserModal('delete')}
                onClick={() => {
                  bulkDeleteUserFromCompanyMutation(deleteUserIds);
                  setDeleteUserIds(undefined);
                  table.resetRowSelection();
                }}
              />,
              <LinkButton
                key={2}
                buttonStyle="fit"
                onClick={() => setDeleteUserIds(undefined)}
                label={tdeleteUserModal('cancel')}
              />,
            ],
          }}
          isOpen={deleteUserIds !== undefined}
          onClose={() => setDeleteUserIds(undefined)}
        />
      )}
      {showMultiUserUpdateSidebar !== undefined && (
        <EditMembersSidebar
          isOpen={showMultiUserUpdateSidebar !== undefined}
          type={showMultiUserUpdateSidebar}
          onClose={() => {
            setEditingUser(undefined);
            setShowMultiUserUpdateSidebar(undefined);
          }}
          onSubmit={(data) => {
            const userIds = table.getSelectedRowModel().rows.map((r) => r.original.id);

            bulkUpdateUsersFromCompanyMutation({
              userIds,
              role: data.access !== null ? transformOutgoingCompanyRole(data.access) : null,
              jobTitle: data.jobTitle,
              language: data.language !== null ? transferToInputLanguage(data.language) : null,
            });
            setShowMultiUserUpdateSidebar(undefined);
            table.resetRowSelection();
          }}
          supportedLanguages={supportedLanguages}
          defaultLanguage={defaultLanguage}
        />
      )}
      <BulkErrorModal
        isOpen={isBulkErrorModalOpen}
        onClose={() => setIsBulkErrorModalOpen(false)}
        errors={bulkUploadErrors}
      />

      {showAddToJourneySidebar && (
        <AddToJourneySidebar
          isOpen={showAddToJourneySidebar}
          onClose={() => {
            setShowAddToJourneySidebar(false);
          }}
          onSubmit={(data) => {
            selectedUserIds &&
              bulkAddToJourneyMutation({
                courseId: data.journeyId,
                input: {
                  userIds: selectedUserIds,
                  courseRole: transformOutgoingCourseRole(data.access),
                  mentorId: data.mentorId,
                },
              });
            setShowAddToJourneySidebar(false);
            table.resetRowSelection();
            setSelectedUserIds(undefined);
          }}
          membersList={
            userPage?.users?.map((user) => {
              return {
                id: user.id,
                label: user.name ?? '',
              };
            }) ?? []
          }
          journeysList={searchableCourses}
          selectedMembersIdList={selectedUserIds ?? []}
        />
      )}

      <BulkAddToJourneyErrorModal
        isOpen={isBulkAddToJourneyErrorModalOpen}
        onClose={() => setIsBulkAddToJourneyErrorModalOpen(false)}
        errors={bulkAddToJourneyErrors}
      />
      <InviteMemberModal
        isOpen={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
        onSubmit={async (user) => {
          await addUserToCompanyMutation({
            email: user.email,
            language: transformOutgoingLanguageCode(user.language.toLowerCase()),
            name: user.name,
            role: transformOutgoingCompanyRole(user.role),
            jobTitle: user.jobTitle,
          });
          invalidateUserQueries();
        }}
        supportedLanguages={supportedLanguages}
        defaultLanguage={defaultLanguage}
      />
      <MemberBulkImportModal
        isOpen={isBulkModalOpen}
        onClose={() => setIsBulkModalOpen(false)}
        uploadProgress={uploadProgress}
        onUpload={onUpload}
      />
    </div>
  );
};
