import { API } from '@editorjs/editorjs';
import React from 'react';
import { ListComponent } from '../lists/components/list-component';
import { PracticalTipsBullet } from '../lists/components/list-bullets';
import { ContentEditable } from '../../components/content-editable';
import { PracticalTipsData } from './practical-tips-plugin';

interface PracticalTipsComponentProps {
  id: string;
  data: PracticalTipsData;
  onChange: (data: PracticalTipsData) => void;
  isReadOnly: boolean;
  api: API;
}

export const PracticalTipsComponent: React.FC<PracticalTipsComponentProps> = ({
  id,
  data,
  onChange,
  isReadOnly,
  api,
}) => {
  return (
    <div
      id={id}
      className="my-6 space-y-6 "
    >
      <div className="flex flex-col space-y-4">
        <ContentEditable
          onChange={(value) => {
            onChange({ ...data, title: value });
          }}
          value={data.title}
          contentEditable={!isReadOnly}
          placeholder={api.i18n.t('title-placeholder')}
          className={` text-text-01 text-xl outline-none`}
        />
        <ListComponent
          isReadOnly={isReadOnly}
          data={{ items: data.items, style: 'unordered' }}
          onChange={(value) => {
            onChange({ ...data, items: value.items });
          }}
          api={api}
          Bullet={PracticalTipsBullet}
          className="my-6 space-y-6"
        />
      </div>
    </div>
  );
};
