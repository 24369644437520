import {
  DocumentPDFIllustration,
  DocumentWordIllustration,
  DocumentPPTXIllustration,
  DocumentTextIllustration,
} from '.';

export const renderFileIcon = (mimeType: string) => {
  switch (mimeType) {
    case 'application/pdf':
      return <DocumentPDFIllustration />;
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <DocumentWordIllustration />;
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return <DocumentPPTXIllustration />;
    default:
      return <DocumentTextIllustration />;
  }
};
