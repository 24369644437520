import React, { useState } from 'react';
import { API } from '@editorjs/editorjs';
import { DocumentData } from './document-plugin';
import { FileInput, UploadFile } from '../../../form';
import { UploadResponse, VoidFunc } from '@stellar-lms-frontend/common-utils';
import { DocumentViewComponent } from './document-view-component';
import { DocumentNotSupportedComponent } from './document-not-supported-component';

export interface DocumentComponentProps {
  data: DocumentData;
  state: State;
  onChange: (newData: DocumentData) => void;
  api: API;
  handleUpload: (file: File, progress: (progres: number) => void) => Promise<UploadResponse>;
  onSupportClick: VoidFunc;
}

type InputState = {
  state: 'input';
  url: string | undefined;
  filename: string | undefined;
  errorMessage: string | undefined;
};
type DisplayState = { state: 'display'; data: DocumentData };
type NotSupported = { state: 'not-supported' };
type State = InputState | DisplayState | NotSupported;

export const DocumentComponent: React.FC<DocumentComponentProps> = ({
  data,
  state,
  onChange,
  api,
  handleUpload,
  onSupportClick,
}) => {
  const [file, setFile] = useState<UploadFile>({ status: 'init' });
  const [internalState, setInternalState] = useState<State>(state);
  const handleFileAdded = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile({ status: 'loading', progress: 0 });
      const file = e.target.files[0];

      try {
        const uploadUrl = await handleUpload(file, (progress) => {
          setFile({ status: 'loading', progress: progress });
        });
        setFile({ status: 'complete', file: file, fileId: uploadUrl.fileId });
        const newDataModel = {
          ...data,
          filename: file.name,
          mimeType: file.type,
          file: {
            id: uploadUrl.fileId,
            url: uploadUrl.url,
          },
        };
        onChange(newDataModel);
        setInternalState({ state: 'display', data: newDataModel });
      } catch (error) {
        setFile({ status: 'error' });
      }
    }
  };

  // Rendering
  switch (internalState.state) {
    case 'input':
      return (
        <div className="py-3">
          <FileInput
            i18n={{
              invalidFormat: api.i18n.t('invalid-file-format'),
            }}
            file={file}
            accept=".docx,.md,.pdf,.pptx,.txt"
            className="w-full"
            maxFileSize={{
              size: 512000, // 500MB
              errorMessage: api.i18n.t('file-too-large'),
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleFileAdded(e);
            }}
          />
        </div>
      );
    case 'display':
      return <DocumentViewComponent data={internalState.data} />;
    case 'not-supported':
      return (
        <DocumentNotSupportedComponent
          i18n={{
            label: api.i18n.t('not-supported-label'),
            cta: api.i18n.t('not-supported-cta'),
          }}
          onSupportClick={onSupportClick}
        />
      );
  }
};
