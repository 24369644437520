export interface Heading2Props {
  children: React.ReactNode;
  className?: string;
}

export const Heading2 = ({ children, className = '' }: Heading2Props) => {
  return (
    <h2
      className={`${className} md:font-regular font-lexend text-2xl font-medium leading-snug md:text-3xl`}
    >
      {children}
    </h2>
  );
};

export default Heading2;
