import { create } from 'zustand';

type StoreType = {
  companyId: string | undefined;
  setCompanyId: (companyId: string | undefined) => void;
};
export const useCompanyStore = create<StoreType>((set) => ({
  companyId: undefined,
  setCompanyId: (id: string | undefined) => set({ companyId: id }),
}));
