import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FloatingOptions,
  FloatingOptionsOption,
  MoreVertIcon,
  TrashIcon,
} from '@stellar-lms-frontend/ui-components';
import { useState } from 'react';
import { faFiles, faSackDollar } from '@fortawesome/pro-light-svg-icons';
import { faTimes, faBadge, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { SubscriptionType } from './types';

export type OptionsMenuCellProps = {
  subscription:
    | {
        type: SubscriptionType;
        status: boolean;
      }
    | undefined;
  documentConfigurationEnabled: boolean;
  onUpdateSubscription: () => void;
  onCancelSubscription: () => void;
  onToggleDocumentConfiguration: () => void;
  onDeleteCompany: () => void;
};

export const OptionsMenuCell: React.FC<OptionsMenuCellProps> = ({
  subscription,
  documentConfigurationEnabled,
  onToggleDocumentConfiguration,
  onUpdateSubscription,
  onCancelSubscription,
  onDeleteCompany,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'administration.company-tab.options-menu',
  });
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const buildMenuOptions = (
    subscription: OptionsMenuCellProps['subscription'] | undefined,
  ): FloatingOptionsOption[] => {
    const options = [];
    // Update/Cancel subscription: Only possible for trial, internal, stellar labs managed
    if (
      subscription &&
      (subscription.type === 'INTERNAL' ||
        subscription.type === 'STELLAR_LABS_MANAGED' ||
        subscription.type === 'TRIAL')
    ) {
      options.push({
        label: t('update-subscription'),
        onClick: (e: any) => {
          onUpdateSubscription();
          setIsOptionsOpen(false);
          e.stopPropagation();
        },
        className: '',
        left: <FontAwesomeIcon icon={faSackDollar} />,
      });
      if (subscription.status) {
        options.push({
          label: t('cancel-subscription'),
          onClick: (e: any) => {
            onCancelSubscription();
            setIsOptionsOpen(false);
            e.stopPropagation();
          },
          className: '',
          left: <TrashIcon className="text-negative-01 h-4 w-4" />,
        });
      }
    }
    options.push({
      label: documentConfigurationEnabled
        ? t('disable-document-upload')
        : t('enable-document-upload'),
      onClick: (e: any) => {
        onToggleDocumentConfiguration();
        setIsOptionsOpen(false);
        e.stopPropagation();
      },
      className: '',
      left: documentConfigurationEnabled ? (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon
            icon={faFiles}
            size="lg"
          />
          <FontAwesomeIcon
            icon={faBadge}
            transform="right-8 up-8"
            className="text-negative-02 text-xl"
          />
          <FontAwesomeIcon
            icon={faTimes}
            transform="right-8 up-8 shrink-4"
            className="text-on-primary-01 text-xl"
          />
        </span>
      ) : (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon
            icon={faFiles}
            className="text-2xl"
          />
          <FontAwesomeIcon
            icon={faBadge}
            transform="right-8 up-8"
            swapOpacity
            className="text-positive-02 text-xl"
          />
          <FontAwesomeIcon
            icon={faCheck}
            transform="right-8 up-8 shrink-4"
            className="text-on-primary-01 text-xl"
          />
        </span>
      ),
    });
    options.push({
      label: t('delete-company'),
      onClick: (e: any) => {
        onDeleteCompany();
        setIsOptionsOpen(false);
        e.stopPropagation();
      },
      className: '',
      left: <TrashIcon className="text-negative-01 h-4 w-4" />,
    });
    return options;
  };

  const options = buildMenuOptions(subscription);

  if (options.length === 0) {
    return null;
  }

  return (
    <FloatingOptions
      options={options}
      placement="bottom-end"
      wrappedComponent={
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsOptionsOpen(true);
          }}
          className="-my-3 flex w-full items-center justify-end px-3 py-3"
        >
          <MoreVertIcon className="h-4 w-4" />
        </button>
      }
      isOpen={isOptionsOpen}
      onClose={() => setIsOptionsOpen(false)}
    />
  );
};
