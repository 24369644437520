import { IconItem, Input, Typewriter, WizardContext } from '@stellar-lms-frontend/ui-components';

import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type StepChooseSkillProps = {
  onSubmit: (selectedSkill: string) => void;
  skill: string;
};

export const StepChooseSkill: React.FC<StepChooseSkillProps> = ({ onSubmit, skill }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'create-journey.steps.choose-skill' });
  const { t: tAnimation } = useTranslation('translation', {
    keyPrefix: 'create-journey.steps.animated-prompt',
  });

  const [selectedSkill, setSelectedSkill] = useState('');
  const [isFormsVisible, setIsFormsVisible] = useState(false);

  const skills = useMemo(
    () => [
      { id: '1', label: t('skills.skill1') },
      { id: '2', label: t('skills.skill2') },
      { id: '3', label: t('skills.skill3') },
      { id: '4', label: t('skills.skill4') },
      { id: '5', label: t('skills.skill5') },
    ],
    [t],
  );

  useEffect(() => {
    setSelectedSkill(skill);
  }, [skill]);

  const { setNextFunc, setIsCurrentStepValid } = useContext(WizardContext);

  useEffect(() => {
    setNextFunc(async () => {
      if (selectedSkill) {
        onSubmit(selectedSkill);
        return true;
      }

      return false;
    });
  }, [onSubmit, selectedSkill, setNextFunc]);

  useEffect(() => {
    selectedSkill.replace(/\s+/g, '') ? setIsCurrentStepValid(true) : setIsCurrentStepValid(false);
  }, [selectedSkill, setIsCurrentStepValid]);

  const isAnimationEnabled = useMemo(() => !skill, [skill]);

  return (
    <div className="max-w-createJourneyStep font-lexend mx-auto flex w-full gap-6 px-12 py-14">
      <Typewriter
        onFinishTyping={() => setIsFormsVisible(true)}
        isEnabled={isAnimationEnabled}
      >
        <p className="text-text-01 text-3xl">
          {tAnimation('part-1')}
          {tAnimation('ellipsis')}
        </p>
      </Typewriter>
      <div className="grow">
        {isFormsVisible && (
          <>
            <Input
              htmlId="input"
              autoFocus
              text={selectedSkill}
              onChange={(e) => setSelectedSkill(e.target.value)}
              placeholder={t('skill-select-placeholder')}
              className={`${isAnimationEnabled ? 'animate-fadeIn' : ''}`}
            />
            <p className="type-small text-text-02 px-4 pt-8 text-right">{t('suggestions')}</p>
            <ul className="mt-3 flex flex-col items-start">
              {skills.map((s) => (
                <IconItem
                  key={s.id}
                  onClick={() => setSelectedSkill(s.label)}
                  className={`hover:bg-surface-02 text-text-02 type-headline-4 w-full rounded-xl px-4 py-3 text-right
                  ${s.label === selectedSkill ? 'bg-surface-02-hover' : ''}`}
                  title={<p className="font-regular">{s.label}</p>}
                  size="small"
                />
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};
