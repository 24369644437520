import { flip, offset, shift, useFloating, autoUpdate, Placement } from '@floating-ui/react';
import { cloneElement, ReactElement, ReactNode, useCallback, useRef } from 'react';
import { FloatingArrow, arrow } from '@floating-ui/react';
import { Portal } from '../portal/portal';

export type FloatingProps = {
  children: ReactNode;
  placement: Placement;
  wrappedComponent?: ReactElement;
  isOpen: boolean;
  usePortal?: boolean;
  floatingOffset?: number;
  hasArrow?: boolean;
  arrowClassName?: string;
  innerRefs?: {
    setWrappedComponentRef?: (node: HTMLElement | null) => void;
    setChildrenRef?: (node: HTMLElement | null) => void;
  };
  isNearWrapperCallback?: (isNearChildren: boolean) => void;
};

const ARROW_HEIGHT = 7;
const GAP = 2;

// There is more improvements to be made, but we first need to understand Floating, Tip, FloatingBox, and ToolTip to make sure that we don't break anything.
// Right now it looks like there might be no need for the wrapped component to be optional.
//Another question to ask is if the clone can be avoided by pushing the wrapped component as children and passing the current children as a prop instead.
export const Floating: React.FC<FloatingProps> = ({
  children,
  placement,
  wrappedComponent,
  isOpen,
  usePortal = true,
  floatingOffset = 4,
  hasArrow = false,
  arrowClassName = '',
  innerRefs,
  isNearWrapperCallback,
}) => {
  const arrowRef = useRef(null);

  const { x, y, strategy, refs, context } = useFloating({
    placement,
    middleware: [
      offset(floatingOffset + ARROW_HEIGHT + GAP),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  // A render function instead of a separate component, as the amount of props to pass is very large
  const renderFloatingPart = useCallback(() => {
    return (
      <div
        className={'font-lexend z-20'}
        ref={(e) => {
          refs.setFloating(e);
          innerRefs?.setChildrenRef?.(e);
        }}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
        }}
      >
        {children}
        {hasArrow && (
          <FloatingArrow
            ref={arrowRef}
            context={context}
            height={ARROW_HEIGHT}
            className={arrowClassName}
          />
        )}
      </div>
    );
  }, [arrowClassName, children, context, hasArrow, innerRefs, refs, strategy, x, y]);

  return (
    <>
      {wrappedComponent && (
        <div className="relative min-w-0">
          <div
            onMouseEnter={() => isNearWrapperCallback?.(true)}
            onMouseLeave={() => isNearWrapperCallback?.(false)}
            className="absolute top-0 left-0 right-0 bottom-0 -z-10"
            style={{
              margin: `-${floatingOffset + ARROW_HEIGHT + GAP + 1}px`,
            }}
          />
          {cloneElement(wrappedComponent, {
            ref: (e: HTMLElement) => {
              refs.setReference(e);
              innerRefs?.setWrappedComponentRef?.(e);
            },
          })}
        </div>
      )}
      {isOpen && usePortal && <Portal>{renderFloatingPart()}</Portal>}
      {isOpen && !usePortal && renderFloatingPart()}
    </>
  );
};
