export const STEP_ITEM_ID = `STEP_ITEM_ID`;

export type StepItem = {
  id: string;
  title: string;
};

export type StepsSectionProps = {
  steps: StepItem[];
  onClick?: (stepId: string) => void;
  className?: string;
};

export const StepsSection: React.FC<StepsSectionProps> = ({ steps, onClick, className }) => {
  return (
    <ul className={`${className} ml-6 flex flex-col gap-4`}>
      {steps.map((step) => (
        <li
          id={`${STEP_ITEM_ID}_${step.id}`}
          key={step.id}
          role="button"
          onClick={onClick ? () => onClick(step.id) : undefined}
          className={`${
            onClick ? 'cursor-pointer' : ''
          } text-text-02 hover:text-text-04 w-fit list-disc font-medium`}
        >
          {step.title}
        </li>
      ))}
    </ul>
  );
};
