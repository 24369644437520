import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  Row,
  getSortedRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import moment from 'moment';
import { useMemo } from 'react';
import { Badge, FlexTable } from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PersistedLearnerWorkBasedActionWithUser } from '@stellar-lms-frontend/lms-api';
import { DESIGNER_ACTION_ROUTE } from '../constants/routes';
import { TFunction } from 'i18next';

/**
 * The columns we want our table to have
 */
type RowShape = {
  dueDate: Date | undefined;
  completed: boolean;
  learner: string;
  action: string;
  module: string;
  actionId: string;
};

/**
 * Types for the status column
 */
type StatusType = 'completed' | 'overdue' | 'incomplete';
type StatusColType = {
  dueDays: number;
  status: StatusType;
};

/**
 * Determine the status column from a row
 * @param row
 * @returns
 */
const determineStatus = (row: RowShape): StatusColType => {
  const dueDate = moment(row.dueDate);
  const now = moment(new Date()).startOf('day');
  const daysToGo = Math.trunc(moment.duration(dueDate.diff(now)).asDays());

  let status: StatusType = 'incomplete';
  if (row.completed) {
    status = 'completed';
  } else if (daysToGo < 0) {
    status = 'overdue';
  }

  return {
    status: status,
    dueDays: daysToGo,
  };
};

/**
 * Render the different statuses to html
 * TODO: These styles could be their own small components
 * @param col
 * @returns
 */
const renderStatus = (col: StatusColType, t: TFunction<'translation', 'course-action-list'>) => {
  if (col.status === 'completed') {
    return <Badge color="green">{t('table.completed')}</Badge>;
  } else if (col.dueDays < 0) {
    return <Badge color="red">{t('table.days-overdue', { count: col.dueDays * -1 })} </Badge>;
  } else {
    return <Badge color="grey">{t('table.incomplete')}</Badge>;
  }
};

/**
 * Ordering for the possible statuses of an action
 */
const statusOrdering: Map<StatusType, number> = new Map<StatusType, number>([
  ['overdue', 0],
  ['completed', 1],
  ['incomplete', 2],
]);

const transformActionsDataToRowData = (
  actions: PersistedLearnerWorkBasedActionWithUser[]
): RowShape[] => {
  return actions.map((action) => {
    return {
      completed: action.completed,
      dueDate: action.learnerDueDate,
      action: action.learnerShortDescription ?? '',
      module: action.moduleTitle ?? '',
      learner: `${action.user?.name ?? ''}`,
      actionId: action.id,
    };
  });
};

const columnHelper = createColumnHelper<RowShape>();

export type WorkbasedActionsTableProps = {
  courseId: string;
  learnerActions: PersistedLearnerWorkBasedActionWithUser[];
};

export const WorkBasedActionsTable: React.FC<WorkbasedActionsTableProps> = ({
  courseId,
  learnerActions,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'course-action-list' });
  const { t: tTable } = useTranslation('translation', { keyPrefix: 'table' });
  const navigate = useNavigate();

  const transformed = useMemo(
    () => transformActionsDataToRowData(learnerActions),
    [learnerActions]
  );
  const columns = useMemo(
    () => [
      // Data columns
      columnHelper.accessor((row) => row.learner, {
        header: t('table.learner-header') as string,
        maxSize: 300,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row.action, {
        header: t('table.action-header') as string,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row.module, {
        header: t('table.module-header') as string,
        maxSize: 400,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row.dueDate, {
        header: t('table.due-date-header') as string,
        maxSize: 150,
        cell: (info) => {
          return (
            <span className="whitespace-nowrap">
              {info ? moment(info.getValue()).format('DD MMM yyyy') : ''}
            </span>
          );
        },
        sortingFn: 'datetime',
      }),
      columnHelper.accessor((row) => determineStatus(row), {
        id: 'status',
        header: t('table.status-header') as string,
        maxSize: 180,
        cell: (info) => renderStatus(info.getValue(), t),
        sortingFn: (rowA: Row<RowShape>, rowB: Row<RowShape>, columnId: string) => {
          const aPos = statusOrdering.get(rowA.getValue<StatusColType>(columnId).status) ?? 0;
          const bPos = statusOrdering.get(rowB.getValue<StatusColType>(columnId).status) ?? 0;
          return aPos - bPos;
        },
      }),
    ],
    [t]
  );

  const onRowClick = (row: Row<RowShape>) => {
    navigate(DESIGNER_ACTION_ROUTE(courseId, row.original.actionId));
  };

  const table = useReactTable({
    data: transformed,
    columns,
    initialState: { sorting: [{ id: 'status', desc: false }], pagination: { pageSize: 20 } },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <FlexTable
      i18n={{
        pageSizeSelector: {
          showing: tTable('page-size-selector.showing'),
          of: (count) => tTable('page-size-selector.of', { count }),
        },
      }}
      table={table}
      rowClick={onRowClick}
    />
  );
};
