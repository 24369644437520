import React from 'react';
import { LogoLoader } from '@stellar-lms-frontend/ui-components';
import { useCurrentCompany } from '@stellar-lms-frontend/lms-graphql';
import { GraphQLClient } from 'graphql-request';
import { DetailsTab } from './components/details-tab';
import { MembersTab } from './components/users-tab/members-tab';
import { SearchItem, SupportedLanguagesOptions } from '../types/types';
import { BillingTab } from './components/billing-tab/billing-tab';
import { Route, Routes } from 'react-router-dom';
import { Sidebar } from './components/sidebar';
import { Stripe } from '@stripe/stripe-js';
import { InsightsTab } from './components/insights-tab';

export type OrganizationPageProps = {
  graphQLClient: GraphQLClient;
  supportedLanguages: SupportedLanguagesOptions;
  defaultLanguage: string;
  searchableCourses: SearchItem[];
  stripePromise: Promise<Stripe | null>;
};

export const OrganizationPage: React.FC<OrganizationPageProps> = ({
  graphQLClient,
  supportedLanguages,
  defaultLanguage,
  searchableCourses,
  stripePromise,
}) => {
  const {
    query: { data: company },
  } = useCurrentCompany(graphQLClient);

  return company ? (
    <div className="grid w-full grid-cols-[424px,2fr]">
      <Sidebar
        company={{
          id: company.id,
          name: company.name ?? '',
          subscriptionType: company.subscription?.type,
          permissions: company.permissions ?? [],
        }}
        graphQLClient={graphQLClient}
      />
      <Routes>
        <Route
          path=""
          element={
            <DetailsTab
              graphQLClient={graphQLClient}
              supportedLanguages={supportedLanguages}
              defaultLanguage={defaultLanguage}
            />
          }
        />
        <Route
          path="members"
          element={
            <MembersTab
              supportedLanguages={supportedLanguages}
              defaultLanguage={defaultLanguage}
              companyId={company?.id}
              graphQLClient={graphQLClient}
              searchableCourses={searchableCourses}
            />
          }
        />
        <Route
          path="billing"
          element={
            <BillingTab
              graphQLClient={graphQLClient}
              stripePromise={stripePromise}
            />
          }
        />
        <Route
          path="insights"
          element={<InsightsTab companyId={company.id} />}
        />
      </Routes>
    </div>
  ) : (
    <LogoLoader />
  );
};
