import { IconItem } from '@stellar-lms-frontend/ui-components';

export type User = { id: string; name: string; thumbUri: string };

export type UserItemProps = {
  user: User;
};

export const UserItem: React.FC<UserItemProps> = ({ user }) => {
  return (
    <IconItem
      className="text-text-01"
      size="no-padding"
      left={
        <img
          className="h-10 w-10 rounded-full border-4 border-white"
          src={user.thumbUri}
          alt="thumbnail"
        />
      }
      title={<p className="text-text-04 break-words">{user.name}</p>}
      icon={null}
    />
  );
};
