import React, { cloneElement } from 'react';

type ConditionalWrapperProps = {
  children: React.ReactElement;
  hasWrapper: boolean;
  wrapper: (children: React.ReactElement) => JSX.Element;
};

export const ConditionalWrapper: React.FC<ConditionalWrapperProps> = React.forwardRef(
  ({ hasWrapper, wrapper, children }, ref) => {
    const clone = cloneElement(children, { ref });
    return hasWrapper ? wrapper(clone) : clone;
  }
);
