import { GraphQLClient } from 'graphql-request';
import { UsersOfCompanyQueryVariables, getUsersOfCompany } from '@stellar-lms-frontend/lms-graphql';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const QUERY_KEY = 'USERS_OF_COMPANY';

export const useUsersOfCompany = (
  graphQLClient: GraphQLClient,
  { companyId, pageSize = 0, cursor, sort }: Partial<UsersOfCompanyQueryVariables>
) => {
  const queryClient = useQueryClient();

  const query = useQuery(
    [
      QUERY_KEY,
      companyId,
      {
        pageSize,
        cursor,
      },
      sort,
    ],
    () =>
      companyId
        ? getUsersOfCompany(graphQLClient, {
            companyId,
            pageSize,
            cursor,
            sort,
          })
        : undefined,
    { enabled: !!companyId }
  );

  const invalidateQueries = () => {
    queryClient.invalidateQueries([QUERY_KEY, companyId]);
  };

  return {
    invalidateQueries,
    query,
  };
};
