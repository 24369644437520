import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { Label } from '../../label/label';
import { InputError } from '../../error';

type Theme = 'default' | 'underline' | 'no-border' | 'dark';

export interface InputProps {
  label?: string;
  name?: string;
  htmlId: string;
  placeholder?: string;
  autoComplete?: AutoComplete;
  htmlType?: InputType;
  min?: string | number;
  max?: string | number;
  maxLength?: number;
  required?: boolean;
  enabled?: boolean;
  error?: string;
  text?: string;
  value?: string;
  initialText?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactElement | string;
  rightIconClick?: () => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onPaste?: (value: string) => string;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: ChangeEventHandler<HTMLInputElement>;
  labelClassName?: string;
  inputMode?: InputMode;
  inError?: boolean;
  lang?: 'en' | 'nl';
  display?: 'inline' | 'normal';
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  autoFocus?: boolean;
  theme?: Theme;
  className?: string;
  hint?: string;
}
// TODO we should remove this forward ref as we manage the internal value in setState.
// Passing the ref to the input component would mean that this internal state gets bypassed.
export const Input = React.forwardRef(
  (
    {
      label,
      name,
      htmlId,
      placeholder,
      autoComplete = 'off',
      htmlType = 'text',
      min,
      max,
      maxLength,
      required = false,
      enabled = true,
      error,
      inError = false,
      text,
      value,
      initialText,
      rightIcon,
      rightIconClick,
      onChange,
      onPaste,
      onBlur,
      onFocus,
      lang,
      labelClassName,
      inputMode = 'text',
      onKeyUp,
      onKeyDown,
      autoFocus,
      theme = 'default',
      leftIcon,
      className = '',
      hint,
    }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    // We use a local cache to avoid cursor jumps during typing (and onChange loops)
    const [internalValue, setInternalValue] = useState(value ?? text ?? initialText ?? '');

    useEffect(() => {
      setInternalValue(value ?? text ?? initialText ?? '');
    }, [text, initialText, value]);

    const [length, setLength] = useState(
      (text && text.length) || (initialText && initialText.length) || 0
    );
    const showCharacterCount = maxLength && maxLength > 0;

    const handleChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      showCharacterCount && setLength(e.target.value.length);
      setInternalValue(e.target.value);
      onChange && onChange(e);
    };

    // Only provide this function when we received an onPaste transform function.
    // Otherwise we break the behaviour of normal inputs that would get an onChange event when something gets pasted (which does not happen when we
    // provide onPaste as we don't have the correct type of changeEvent to pass to the onChange function).
    const handlePasteEvent = onPaste
      ? (e: React.ClipboardEvent<HTMLInputElement>) => {
          e.preventDefault();
          const value = e.clipboardData.getData('text/plain');
          showCharacterCount && setLength(value.length);
          setInternalValue(onPaste(value));
        }
      : undefined;

    return (
      <div
        id={htmlId + '-container'}
        className="font-lexend w-full space-y-2"
      >
        <Label
          htmlId={htmlId}
          labelClassName={`
          ${labelClassName}
          ${enabled ? '' : 'cursor-[inherit]'}`}
          label={label}
          required={required}
          length={length}
          maxLength={maxLength}
          theme={theme === 'dark' ? 'dark' : 'default'}
        />
        <div className={`text-text-01 relative mb-[1px] flex w-full items-center`}>
          <div className={`absolute left-[1px] pl-5 pr-2`}>{leftIcon ? leftIcon : null}</div>
          <input
            data-cy={`input-${name}`}
            autoFocus={autoFocus}
            type={htmlType}
            name={name ?? htmlId}
            id={htmlId}
            autoComplete={autoComplete}
            required={required}
            disabled={!enabled}
            min={min}
            max={max}
            maxLength={maxLength}
            inputMode={inputMode}
            ref={ref}
            lang={lang ?? undefined}
            className={`
            ${className}
            border-border-02 focus:border-primary-02  placeholder:text-text-03 flex w-full focus:ring-0
            disabled:cursor-[inherit]
            ${
              theme === 'default'
                ? ` text-text-01  h-14 rounded-xl border pl-4
              ${
                error || inError
                  ? 'border-negative-02 drop-shadow-sm'
                  : 'disabled:border-border-01 disabled:text-text-03'
              }`
                : ''
            }
            ${
              theme === 'underline'
                ? `h-8 border-0 border-b-2 text-center ${
                    error || inError
                      ? 'text-negative-01'
                      : 'text-primary-01 disabled:border-border-01'
                  }`
                : ''
            }
            ${
              theme === 'no-border'
                ? ` text-text-01  h-14 border-0 pl-4
              ${error || inError ? '' : 'disabled:border-border-01 disabled:text-text-03'}`
                : ''
            }
            ${
              theme === 'dark'
                ? `h-14  rounded-xl border pl-4 placeholder:text-white ${
                    error || inError
                      ? 'border-negative-02 drop-shadow-sm'
                      : 'disabled:border-border-01 disabled:text-text-03'
                  }`
                : ''
            }
            ${leftIcon ? '!pl-12' : ''}
            ${rightIcon ? '!pr-12' : ''}
            `}
            placeholder={placeholder}
            onChange={handleChangeEvent}
            onBlur={onBlur}
            onFocus={onFocus}
            onPaste={handlePasteEvent}
            value={internalValue ?? undefined}
            defaultValue={initialText}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
          />
          <div
            id={htmlId + '-right-icon'}
            className="absolute right-[1px] bg-white pl-2 pr-5"
            onClick={rightIconClick}
          >
            {rightIcon}
          </div>
        </div>
        {hint && <p className="text-text-02 text-sm">{hint}</p>}
        <InputError
          error={error}
          theme={theme === 'dark' ? 'dark' : 'default'}
        />
      </div>
    );
  }
);

export default Input;
// TODO: Remove to separate lib
type InputType =
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

export type AutoComplete =
  | 'off'
  | 'on'
  | 'name'
  | 'given-name'
  | 'honorific-prefix'
  | 'additional-name'
  | 'family-name'
  | 'honorific-suffix'
  | 'nickname'
  | 'email'
  | 'username'
  | 'new-password'
  | 'current-password'
  | 'one-time-code'
  | 'organization-title'
  | 'organization'
  | 'street-address'
  | 'address-line1'
  | 'address-line2'
  | 'address-line3'
  | 'address-level4'
  | 'address-level3'
  | 'address-level2'
  | 'address-level1'
  | 'country'
  | 'country-name'
  | 'postal-code'
  | 'cc-name'
  | 'cc-given-name'
  | 'cc-additional-name'
  | 'cc-family-name'
  | 'cc-number'
  | 'cc-exp'
  | 'cc-exp-month'
  | 'cc-exp-year'
  | 'cc-csc'
  | 'cc-type'
  | 'transaction-currency'
  | 'transaction-amount'
  | 'language'
  | 'bday'
  | 'bday-day'
  | 'bday-month'
  | 'bday-year'
  | 'sex'
  | 'tel'
  | 'tel-country-code'
  | 'tel-national'
  | 'tel-area-code'
  | 'tel-local'
  | 'tel-extension'
  | 'impp'
  | 'url'
  | 'photo';

export type InputMode =
  | 'none'
  | 'text'
  | 'decimal'
  | 'numeric'
  | 'tel'
  | 'search'
  | 'email'
  | 'url';
