import { ReactNode } from 'react';
import { Portal } from '../portal/portal';

export type MobileFullScreenProps = {
  left?: ReactNode[];
  center?: ReactNode[];
  right?: ReactNode[];
  children: ReactNode;
};

export const MobileFullScreen: React.FC<MobileFullScreenProps> = ({
  left,
  center,
  right,
  children,
}) => {
  return (
    <Portal>
      <div className="modal-bg">
        <div className="bg-surface-01 h-full w-full p-6">
          <div className="grid-cols-mobile-header grid items-center pb-6">
            <div className="justify-self-start">{left}</div>
            <div className="justify-self-center">{center}</div>
            <div className="justify-self-end">{right}</div>
          </div>
          <div className="grow overflow-scroll">{children}</div>
        </div>
      </div>
    </Portal>
  );
};
