import {
  AblyChannelSubscriber,
  CourseDetailsSection,
  DashboardLayout,
  LearnersList,
} from '@stellar-lms-frontend/lms-components';
import {
  CourseOverviewNoAccessIcon,
  EmptyState,
  IfDefinedWrapper,
  LogoLoader,
} from '@stellar-lms-frontend/ui-components';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DesignerInsightsSection } from './components/designer-insights-section';
import { getDesignerCourseDashboard, getDocument } from './designer-dashboard.api';
import { useCallback, useMemo } from 'react';
import {
  CourseStats,
  mapLearnersToUsersList,
  mapUsersToUsersList,
  useCurrentCompany,
} from '@stellar-lms-frontend/lms-graphql';
import { JourneySection } from './components/journey-section';
import { graphQLClient } from '../../../lib/graphql';
import { COURSE, DESIGNER, ID } from '../constants/query-constants';
import {
  DESIGNER_COURSE_ABOUT_ROUTE,
  DESIGNER_JOURNEY_ROUTE,
  DESIGNER_LEARNERS_ROUTE,
} from '../constants/routes';
import { UIShell } from '../../shell/ui-shell';
import { REDIRECT_HOME_URI } from '../../../constants/routes';
import { Message } from 'ably';

const MAX_LEARNERS = 5;
const MAX_DESIGNERS = 5;
const MAX_MENTORS = 5;

export const DesignerDashboard = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const { t: tCourseDetails } = useTranslation('translation', {
    keyPrefix: 'designer-overview.course-details',
  });
  const { t: t403 } = useTranslation('translation', { keyPrefix: '403' });

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { courseId } = useParams();

  const {
    query: { data: company },
  } = useCurrentCompany(graphQLClient('designer'));

  // Eventually this single API Call should replace all other calls for this dashboard
  const courseDashboard = useQuery(
    [DESIGNER, COURSE, ID, courseId],
    () =>
      courseId ? getDesignerCourseDashboard(graphQLClient('designer'), { id: courseId }) : null,
    { enabled: !!courseId }
  );

  const handleRealtimeModuleCreate = useCallback(
    (message: Message) => {
      if (message.name === 'create' && message.data.type === 'Module') {
        queryClient.invalidateQueries([DESIGNER, COURSE, ID, courseId]);
      }
    },
    [courseId, queryClient]
  );

  const courseDesigners = useMemo(
    () => mapUsersToUsersList(courseDashboard.data?.designers, MAX_DESIGNERS),
    [courseDashboard.data?.designers]
  );

  const courseLearners = useMemo(
    () => mapLearnersToUsersList(courseDashboard.data?.learners, MAX_LEARNERS),
    [courseDashboard.data?.learners]
  );

  const courseMentors = useMemo(
    () => mapUsersToUsersList(courseDashboard.data?.mentors, MAX_MENTORS),
    [courseDashboard.data?.mentors]
  );

  const moduleClick = (moduleId: string) => {
    navigate(DESIGNER_JOURNEY_ROUTE(courseId) + '?moduleId=' + moduleId);
  };

  const renderLoadingState = () => {
    if (courseDashboard.isLoading) {
      return <LogoLoader />;
    } else {
      return (
        <EmptyState
          icon={<CourseOverviewNoAccessIcon />}
          title={t403('title')}
          description={t403('description')}
          actionText={t403('button-cta')}
          verticalCenter={true}
          onActionClick={() => {
            window.location.href = REDIRECT_HOME_URI;
          }}
        />
      );
    }
  };

  if (!courseId) return null;
  return (
    <IfDefinedWrapper
      variable={courseDashboard.data?.realtimeChannel.id}
      wrapper={(children, variable) => (
        <AblyChannelSubscriber
          channelId={variable}
          callback={handleRealtimeModuleCreate}
        >
          {children}
        </AblyChannelSubscriber>
      )}
    >
      <UIShell>
        {courseDashboard.isLoading || !courseDashboard.data ? (
          renderLoadingState()
        ) : (
          <DashboardLayout
            left={
              <CourseDetailsSection
                editable
                showEmptyState
                getDocument={(courseId: string, documentId: string) =>
                  getDocument(graphQLClient('designer'), courseId, documentId)
                }
                onEditClick={() =>
                  courseDashboard.data &&
                  navigate(DESIGNER_COURSE_ABOUT_ROUTE(courseDashboard.data.id))
                }
                course={courseDashboard.data}
                i18n={{
                  edit: tGeneral('edit'),
                  title: tCourseDetails('title'),
                  readLess: tCourseDetails('read-less'),
                  readMore: tCourseDetails('read-more'),
                  skills: {
                    label: tCourseDetails('skills.label'),
                    empty: tCourseDetails('skills.empty'),
                  },
                  objectives: {
                    label: tCourseDetails('business-objectives.label'),
                    empty: tCourseDetails('business-objectives.empty'),
                  },
                  transferObjectives: {
                    label: tCourseDetails('transfer-objectives.label'),
                    empty: tCourseDetails('transfer-objectives.empty'),
                  },
                  languages: {
                    label: tCourseDetails('language'),
                  },
                  documents: tCourseDetails('documents'),
                }}
              />
            }
            center={
              <>
                {courseDashboard.data.courseStats && (
                  <DesignerInsightsSection
                    courseId={courseId}
                    stats={courseDashboard.data.courseStats as CourseStats}
                    hasDashboards={company ? !company.hidePerformanceManagement : false}
                  />
                )}

                <JourneySection
                  onClickModule={moduleClick}
                  courseDashboard={courseDashboard.data}
                />
              </>
            }
            right={
              <>
                {courseDashboard.data && courseDashboard.data.designers && (
                  <LearnersList
                    title={t('designer.label')}
                    emptyMessage={t('designer.empty')}
                    learners={courseDesigners}
                    viewAll={{
                      text: t('designer.all-designers'),
                      target: DESIGNER_LEARNERS_ROUTE(courseId),
                    }}
                    dataCy="designers-list"
                  />
                )}
                {courseDashboard.data && courseDashboard.data.mentors && (
                  <LearnersList
                    title={t('mentors.title')}
                    emptyMessage={t('mentors.empty')}
                    learners={courseMentors}
                    viewAll={{
                      text: t('mentors.all-mentors'),
                      target: DESIGNER_LEARNERS_ROUTE(courseId),
                    }}
                    dataCy="mentors-list"
                  />
                )}

                {courseDashboard.data.learners && (
                  <LearnersList
                    title={t('learners.title')}
                    emptyMessage={t('learners.empty')}
                    learners={courseLearners}
                    viewAll={{
                      text: t('learners.all-learners'),
                      target: DESIGNER_LEARNERS_ROUTE(courseId),
                    }}
                    dataCy="learners-list"
                  />
                )}
              </>
            }
          />
        )}
      </UIShell>
    </IfDefinedWrapper>
  );
};
