import {
  CompanyRole,
  CourseRole,
  InputLanguage,
  Language,
  Status,
  User,
} from '@stellar-lms-frontend/lms-graphql';
import { AccessType } from '../components/users-table/components/access-cell';
import { StatusType } from '../components/users-table/components/status-cell';
import { UserTableRowShape } from '../members-tab';

export const transformIncomingUser = (
  user: Omit<User, 'realtimeChannel' | 'manager'> & {
    manager?: { name?: string | null } | null;
  }
): UserTableRowShape => {
  let status: StatusType;
  switch (user.status) {
    case Status.Authenticated:
      status = 'signed-in';
      break;
    case Status.Pending:
      status = 'pending';
      break;
  }

  let access: AccessType;
  switch (user.role) {
    case CompanyRole.Admin:
      access = 'admin';
      break;
    case CompanyRole.CourseCreator:
      access = 'course_creator';
      break;
    case CompanyRole.Member:
      access = 'user';
      break;
  }

  return {
    id: user.id ?? '',
    name: user.name ?? '',
    role: user.jobTitle ?? '',
    status,
    manager: user.manager?.name ?? '',
    access,
    email: user.email ?? '',
    language: user.language ?? '',
  };
};

// CLEANUP move this to graphql or common utils so that it can be used everywhere
export const transformIncomingLanguageCode = (language: Language) => {
  switch (language) {
    case Language.De:
      return 'de';
    case Language.En:
      return 'en';
    case Language.Es:
      return 'es';
    case Language.Fr:
      return 'fr';
    case Language.It:
      return 'it';
    case Language.Nl:
      return 'nl';
    case Language.Pt:
      return 'pt';
    default:
      return 'en';
  }
};

export const transformOutgoingLanguageCode = (language: string) => {
  switch (language) {
    case 'de':
      return InputLanguage.De;
    case 'en':
      return InputLanguage.En;
    case 'es':
      return InputLanguage.Es;
    case 'fr':
      return InputLanguage.Fr;
    case 'it':
      return InputLanguage.It;
    case 'nl':
      return InputLanguage.Nl;
    case 'pt':
      return InputLanguage.Pt;
    default:
      return InputLanguage.En;
  }
};

export const transformOutgoingCompanyRole = (role: string) => {
  switch (role) {
    case 'admin':
      return CompanyRole.Admin;
    case 'course_creator':
      return CompanyRole.CourseCreator;
    case 'user':
      return CompanyRole.Member;
    default:
      return CompanyRole.Member;
  }
};

export const transformOutgoingCourseRole = (role: string) => {
  switch (role) {
    case 'author':
      return CourseRole.Author;
    case 'collaborator':
      return CourseRole.Collaborator;
    case 'mentor':
      return CourseRole.Mentor;
    default:
      return CourseRole.Learner;
  }
};
