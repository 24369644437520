import { useQuery } from '@tanstack/react-query';
import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';
import { graphql } from '@stellar-lms-frontend/lms-graphql';
import { StackedHalfRadialChart } from '@stellar-lms-frontend/ui-components';

const getCompanyRoles = async (companyId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query GetCompanyDashboardCompanyRoles($companyId: ID!) {
        companyRoles(companyId: $companyId) {
          rows {
            category
            count
          }
        }
      }
    `),
    { companyId: companyId }
  );
};
const chartConfig = {
  ADMIN: {
    label: 'Admin',
    color: 'var(--chart-1)',
  },
  COURSE_CREATOR: {
    label: 'Course creator',
    color: 'var(--chart-2)',
  },
  MEMBER: {
    label: 'Member',
    color: 'var(--chart-3)',
  },
};

export const CompanyRolesChart = ({ companyId }: { companyId: string }) => {
  const { data, isLoading } = useQuery(
    ['company-roles', companyId],
    () => getCompanyRoles(companyId),
    { refetchOnWindowFocus: false }
  );

  return (
    <StackedHalfRadialChart
      isLoading={isLoading}
      rows={data?.companyRoles.rows}
      chartConfig={chartConfig}
      showValueInBar={true}
    />
  );
};
