import { ChangeEventHandler, ForwardedRef, useRef, useState } from 'react';
import { FileUploadError } from './types';

export const useFileUpload = ({
  maxFileSize,
  onChange,
  accept,
  forwardedRef,
}: {
  maxFileSize?: number;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  accept?: string;
  forwardedRef?: ForwardedRef<HTMLInputElement>;
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [fileError, setFileError] = useState<FileUploadError | null>(null);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files && e.target.files.length > 0) {
      if (!maxFileSize || isValidFileSize(e.target.files[0], maxFileSize)) {
        onChange?.(e);
      } else {
        handleDeleteFile();
        setFileError('maxFileSizeExceeded');
      }
    } else {
      onChange?.(e);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLSpanElement>) => {
    e.preventDefault();

    if (!e.dataTransfer.items) {
      return;
    }

    const item = e.dataTransfer.items[0];
    if (item.kind !== 'file') {
      return;
    }

    const file = item.getAsFile();
    const input = fileInputRef.current;
    if (!input || !file) {
      return;
    }

    if (accept && !isValidFileType(file, accept)) {
      handleDeleteFile();
      setFileError('invalidFormat');
      return;
    }

    if (maxFileSize && !isValidFileSize(file, maxFileSize)) {
      handleDeleteFile();
      setFileError('maxFileSizeExceeded');
    }

    setFileError(null);

    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    input.files = dataTransfer.files;

    const changeEvent = new Event('change', { bubbles: true });
    input.dispatchEvent(changeEvent);
  };

  const handleDeleteFile = () => {
    setFileError(null);
    const input = fileInputRef.current;
    if (input) {
      input.value = '';
      const changeEvent = new Event('change', { bubbles: true });
      input.dispatchEvent(changeEvent);
    }
  };

  const registerRef = (element: HTMLInputElement) => {
    fileInputRef.current = element;
    if (typeof forwardedRef === 'function') {
      forwardedRef(element);
    } else if (forwardedRef) {
      forwardedRef.current = element;
    }
  };

  return {
    handleDrop,
    handleClick,
    fileError,
    handleDeleteFile,
    fileInputProps: {
      accept,
      onChange: handleFileChange,
      ref: registerRef,
      type: 'file',
      className: 'hidden',
    },
  };
};

const isValidFileType = (file: File, accept: string): boolean => {
  if (!accept) {
    return true;
  }

  if (accept.includes('/*')) {
    const mimeTypeGroup = accept.split('/')[0];
    return file.type.startsWith(`${mimeTypeGroup}/`);
  }

  return accept.split(',').some((acceptedType) => {
    const trimmedType = acceptedType.trim();

    if (trimmedType.startsWith('.')) {
      return file.name.endsWith(trimmedType);
    }

    return file.type === trimmedType;
  });
};

const isValidFileSize = (file: File, maxFileSize: number): boolean => {
  if (!maxFileSize) {
    return true;
  }

  if (Math.round(file.size / 1024) > maxFileSize) {
    return false;
  }
  return true;
};
