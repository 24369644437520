import { renderToString } from 'react-dom/server';
import { TriangleExclamationIcon } from '../../../icons';
import { API, BlockToolConstructorOptions, BlockToolData, ToolConfig } from '@editorjs/editorjs';
import { WarningComponent } from './warning-component';
import { v4 as uuidv4 } from 'uuid';
import { BlockEditorTypesEnum } from '../../block-editor';
import { createRoot } from 'react-dom/client';

export interface WarningData extends BlockToolData {
  title: string;
  text: string;
}

interface WarningConfig extends ToolConfig {
  placeholder: string;
}

export class Warning {
  api: API;
  data: BlockToolData<WarningData>;
  readOnly: boolean;
  element: HTMLElement;

  constructor({
    data,
    api,
    config,
    readOnly,
  }: BlockToolConstructorOptions<WarningData, WarningConfig>) {
    this.data = {
      title: data.title ?? api.i18n.t('title-placeholder'),
      text: data.text || '',
    };
    this.api = api;
    this.readOnly = readOnly;
    this.element = this.createElement();
  }

  static get toolbox() {
    return {
      title: BlockEditorTypesEnum.WARNING,
      icon: renderToString(<TriangleExclamationIcon className="text-text-01 !h-4" />),
    };
  }

  static get inlineToolbar() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  createElement() {
    const rootNode = document.createElement('div');
    rootNode.id = uuidv4();
    return rootNode;
  }

  updateElement() {
    const root = createRoot(this.element);
    root.render(
      <WarningComponent
        isReadOnly={this.readOnly}
        data={this.data}
        onChange={(newData) => (this.data = newData)}
        api={this.api}
      />
    );
  }

  render() {
    this.updateElement();
    return this.element;
  }

  save(): BlockToolData<WarningData> {
    return {
      title: this.data.title,
      text: this.data.text,
    };
  }
}
