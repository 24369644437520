import BaseButton, { BaseButtonProps } from '../base-button/base-button';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LinkButtonProps extends BaseButtonProps {
  type?: LinkButtonType;
}

type LinkButtonType = 'default' | 'negative' | 'blue';

export const LinkButton = ({
  label,
  htmlId,
  enabled = true,
  fullWidth = false,
  htmlType,
  buttonStyle,
  onClick,
  leftIcon,
  rightIcon,
  type = 'default',
}: LinkButtonProps) => (
  <BaseButton
    htmlId={htmlId}
    label={label}
    enabled={enabled}
    fullWidth={fullWidth}
    htmlType={htmlType}
    buttonStyle={buttonStyle}
    onClick={onClick}
    leftIcon={leftIcon}
    rightIcon={rightIcon}
    className={`
     ${type === 'default' ? 'text-text-01 hover:text-text-02 disabled:text-text-03' : ''}
     ${
       type === 'negative'
         ? 'text-negative-01 hover:text-negative-02 disabled:text-negative-03'
         : ''
     }
     ${type === 'blue' ? 'text-primary-01 hover:text-primary-02 disabled:text-negative-03' : ''}
       border-0 bg-none pb-[1px] underline hover:bg-none focus:bg-none  focus:ring-0`}
  />
);

export default LinkButton;
