import { CourseRole } from '@stellar-lms-frontend/lms-graphql';
import { AccessTypes } from './components/access-cell';

export const transformOutgoingCourseRole = (role: AccessTypes) => {
  switch (role) {
    case 'author':
      return CourseRole.Author;
    case 'collaborator':
      return CourseRole.Collaborator;
    case 'learner':
      return CourseRole.Learner;
    case 'mentor':
      return CourseRole.Mentor;
    default:
      return CourseRole.Learner;
  }
};

export const transformIncomingCourseRole = (role?: CourseRole | null) => {
  switch (role) {
    case CourseRole.Author:
      return 'author';
    case CourseRole.Collaborator:
      return 'collaborator';
    case CourseRole.Learner:
      return 'learner';
    case CourseRole.Mentor:
      return 'mentor';
    default:
      return 'learner';
  }
};
