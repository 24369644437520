import { RadialBarChart, RadialBar, LabelList } from 'recharts';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
} from '../shadcn';
import { LoaderWrapper } from './loader-wrapper';

type CategoryData = { category: string; count: number };

export const StackedHalfRadialChart = ({
  isLoading,
  rows,
  chartConfig,
  showValueInBar = false,
}: {
  isLoading: boolean;
  rows: CategoryData[] | undefined;
  chartConfig: ChartConfig;
  showValueInBar?: boolean;
}) => (
  <LoaderWrapper
    isLoading={isLoading}
    hasValue={rows !== undefined && rows.length > 0}
  >
    <ChartContainer
      config={chartConfig}
      className="aspect-square"
    >
      <RadialBarChart
        data={[
          rows?.reduce((acc, row) => {
            acc[row.category] = row.count;
            return acc;
          }, {} as { [key: string]: number }),
        ]}
        cy={130}
        stackOffset="expand"
        startAngle={180}
        endAngle={0}
        innerRadius={80}
        outerRadius={130}
      >
        <ChartTooltip
          cursor={false}
          content={
            <ChartTooltipContent
              hideLabel
              indicator="line"
            />
          }
        />
        <ChartLegend
          iconSize={10}
          width={120}
          height={140}
          layout="vertical"
          verticalAlign="middle"
          align="right"
          content={<ChartLegendContent />}
        />
        {rows?.map((role) => (
          <RadialBar
            key={role.category}
            dataKey={role.category}
            stackId="a"
            fill={`var(--color-${role.category})`}
            className="stroke-transparent stroke-2"
          >
            {showValueInBar && (
              <LabelList
                dataKey={role.category}
                position="outside"
                offset={12}
                className="text-on-primary-01"
                fontSize={12}
              />
            )}
          </RadialBar>
        ))}
      </RadialBarChart>
    </ChartContainer>
  </LoaderWrapper>
);
