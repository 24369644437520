import {
  BlueUIShell,
  Notifications,
  cleanUpHubspotChat,
} from '@stellar-lms-frontend/lms-components';
import { ReactElement, ReactNode } from 'react';
import { graphQLClient } from '../../lib/graphql';
import {
  getLightCourseInfo,
  useCourseMutations,
  useCurrentCompany,
  useCurrentCourse,
  useCurrentUser,
  useMarkTipViewed,
} from '@stellar-lms-frontend/lms-graphql';
import { useTranslation } from 'react-i18next';
import { useLogout } from '@stellar-lms-frontend/lms-api';
import { apiUrl } from '../../context';
import {
  getAllNotifications,
  markNotificationAsRead,
  unreadNotificationCount,
} from '../notifications/notifications.api';
import { useQuery } from '@tanstack/react-query';
import { ButtonConfig } from '@stellar-lms-frontend/common-utils';
import { useNavigate, useParams } from 'react-router-dom';
import { DEFAULT_LANGUAGE, translatedLanguages } from '../../constants/languages';
import { toast } from 'react-toastify';
import { LogoLoader } from '@stellar-lms-frontend/ui-components';

// BluiUIshell wrapper to set all the necessary props
type Props = {
  // FUTURE change children type to PropsWithChildren when possible
  children: ReactElement;

  autoScrollTop?: boolean;
  useContentContainer?: boolean;
  showBottomNav?: boolean;
  scrollOnDesktop?: boolean;

  navHeaderTitle?: ReactNode;
  subNavTitle?: string;
  subNavSubtitle?: string;
  leftSideBar?: ReactNode;

  leftButton?: ButtonConfig;
  rightButton?: ButtonConfig;

  headerTheme?: 'view' | 'edit';
};
export const UIShell = ({
  children,
  autoScrollTop,
  useContentContainer,
  showBottomNav,
  scrollOnDesktop,
  navHeaderTitle,
  subNavTitle,
  subNavSubtitle,
  leftSideBar,
  leftButton,
  headerTheme,
}: Props) => {
  const { t: tDeleteJourney } = useTranslation('translation', { keyPrefix: 'delete-journey' });
  const { t: tDuplicateJourney } = useTranslation('translation', {
    keyPrefix: 'duplicate-journey',
  });
  const { t: tNotifications } = useTranslation('translation', { keyPrefix: 'notifications' });
  const { t: tLanguages } = useTranslation('translation', { keyPrefix: 'languages' });

  const { courseId } = useParams();
  const navigate = useNavigate();

  const logout = useLogout(apiUrl, cleanUpHubspotChat);

  const {
    query: { data: currentUser },
  } = useCurrentUser(graphQLClient());

  const {
    query: { data: currentCompany },
  } = useCurrentCompany(graphQLClient());

  const {
    query: { data: currentCourse },
  } = useCurrentCourse(
    (courseId: string) => getLightCourseInfo(graphQLClient(), { courseId }),
    courseId
  );

  const fetchUnreadNotification = useQuery(
    ['UNREAD_NOTIFICATIONS'], // TODO query key
    () => unreadNotificationCount(graphQLClient('learner')),
    {
      refetchInterval: 60000,
    }
  );

  const markTipViewedMutation = useMarkTipViewed(graphQLClient());

  const {
    deleteCourseMutation,
    duplicateCourseMutation,
    getScormPackage,
    createScormPackageMutation,
  } = useCourseMutations(
    {
      successDuplicate: tDuplicateJourney('success'),
      successDelete: tDeleteJourney('success'),
    },
    graphQLClient
  );

  if (!currentUser || !currentCompany) {
    return <LogoLoader />;
  }

  return (
    <BlueUIShell
      autoScrollTop={autoScrollTop}
      useContentContainer={useContentContainer}
      showBottomNav={showBottomNav}
      scrollOnDesktop={scrollOnDesktop}
      navHeaderTitle={navHeaderTitle}
      subNavTitle={subNavTitle}
      subNavSubtitle={subNavSubtitle}
      leftSideBar={leftSideBar}
      leftButton={leftButton}
      headerTheme={headerTheme}
      currentUser={currentUser}
      currentCompany={currentCompany}
      currentCourse={currentCourse ?? undefined}
      graphQLClient={graphQLClient()}
      supportedLanguages={translatedLanguages(tLanguages)}
      defaultLanguage={currentCompany.language ?? DEFAULT_LANGUAGE.toLowerCase()}
      hasCompanySelector
      actions={{
        logout,
        duplicateCourse: (courseId) => duplicateCourseMutation.mutate({ courseId }),
        deleteCourse: (courseId) => deleteCourseMutation.mutate(courseId),
        createScormPackage: async (courseId, version) =>
          (await createScormPackageMutation.mutateAsync({ courseId, version })).createScormPackage,
        getScormPackage: async (courseId: string, id: string) =>
          (await getScormPackage(courseId, id)).scormPackage,
        fetchNotifications: getAllNotifications,
        handleNotificationClick: async (notification) => {
          await markNotificationAsRead(notification.id);
          if (Notifications.allowedNotificationTypes.includes(notification.type)) {
            navigate(notification.link);
          } else {
            toast.info(tNotifications('not-supported'));
          }
        },
        unreadNotificationsCount: () => fetchUnreadNotification.data?.unread ?? 0,
        markTipAsViewed: (id: string) =>
          markTipViewedMutation.mutate({ userId: currentUser.id, tip: id }),
      }}
    >
      {children}
    </BlueUIShell>
  );
};
