import { DataCy } from '@stellar-lms-frontend/common-utils';
import { Heading4, QuestionIcon, ToolTip } from '@stellar-lms-frontend/ui-components';
import { ReactNode } from 'react';

export type SectionProps = DataCy & {
  title: string;
  children: ReactNode;
} & (
    | {
        rightSideType?: never;
        rightSideContent?: never;
      }
    | {
        rightSideType: 'tooltip';
        rightSideContent: string;
      }
    | {
        rightSideType: 'react-element';
        rightSideContent: ReactNode;
      }
  );

export const Section: React.FC<SectionProps> = ({
  'data-cy': dataCy,
  title,
  children,
  rightSideType,
  rightSideContent,
}) => {
  return (
    <div
      data-cy={dataCy}
      className="flex flex-col gap-4"
    >
      <div className="flex flex-wrap items-center justify-between">
        <Heading4 className="text-text-04">{title}</Heading4>
        {rightSideType === 'react-element' && rightSideContent}
        {rightSideType === 'tooltip' && (
          <ToolTip
            placement="bottom"
            isEnabled
            wrappedComponent={<QuestionIcon />}
          >
            {rightSideContent}
          </ToolTip>
        )}
      </div>
      {children}
    </div>
  );
};
