import { useTranslation } from 'react-i18next';
import { EditableSelect, Input, Radio, Switch } from '../form';
import { VerticalSeparator } from '../separator/vertical-separator';
import {
  MultipleChoiceAnswerSetting,
  MultipleChoiceFormErrors,
  MultipleChoiceSettings,
} from './multiple-choice-settings-card';

type RadioOptions = 'multiple' | 'single';

export type MultipleChoiceSettingsFormProps = {
  onChange: (value: MultipleChoiceSettings) => void;
  value: MultipleChoiceSettings;
  errors?: MultipleChoiceFormErrors;
  renderShowToMentorsOption: boolean;
};

export const MultipleChoiceSettingsForm: React.FC<MultipleChoiceSettingsFormProps> = ({
  value,
  onChange,
  errors,
  renderShowToMentorsOption,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'survey-step-view.question-types.choice',
  });
  const { t: tValidation } = useTranslation('translation', { keyPrefix: 'validation' });

  const updateTitleValue = (text: string) => onChange({ ...value, text });
  const updateRadioValue = (newValue: RadioOptions) =>
    onChange({ ...value, multiple: newValue === 'multiple' });
  const updateRequiredValue = (required: boolean) => onChange({ ...value, required });
  const updateShowToMentorsValue = (visibleToMentors: boolean) =>
    onChange({ ...value, visibleToMentors });

  const updateAnswers = (newOptions: MultipleChoiceAnswerSetting[]) =>
    onChange({ ...value, answers: newOptions });

  return (
    <div className="text-text-02 space-y-4">
      <Input
        required
        htmlId="fitb-blank-text-input"
        label={t('question-input-label')}
        onChange={(event) => updateTitleValue(event.target.value)}
        text={value.text}
        error={errors?.text?.message}
        placeholder={t('question-input-label')}
      />
      <Radio
        legend={t('selection')}
        onChange={(newValue) => updateRadioValue(newValue as RadioOptions)}
        value={value.multiple ? 'multiple' : 'single'}
        options={[
          { label: t('multiple-select'), value: 'multiple' },
          { label: t('single-select'), value: 'single' },
        ]}
        uniqueId="question-multiline-option"
      />
      <EditableSelect
        options={value.answers}
        onChange={updateAnswers}
        placeholder={t('answer-placeholder')}
        ctaLabel={t('add-answer')}
        showCorrectAnswerSelectors={false}
        isMultiSelect={undefined}
      />
      <div className="flex items-center space-x-4">
        <Switch
          label={tValidation('required')}
          value={value.required}
          onChange={(checked) => updateRequiredValue(checked)}
        />
        {renderShowToMentorsOption && (
          <>
            <VerticalSeparator />
            <Switch
              label={t('show-to-mentors')}
              value={value.visibleToMentors}
              onChange={(checked) => updateShowToMentorsValue(checked)}
            />
          </>
        )}
      </div>
    </div>
  );
};
