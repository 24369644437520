import { useCallback } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Course } from '@stellar-lms-frontend/lms-graphql';

export const USE_COURSE_OUTLINE = 'USE_COURSE_OUTLINE';

export const useCourseOutline = (
  courseId?: string,
  getCourseOutlineFunc?: (input: { courseId: string }) => Promise<Course>
) => {
  const queryClient = useQueryClient();

  const useCourseOutlineQuery = useQuery(
    [USE_COURSE_OUTLINE, courseId],
    () =>
      courseId && getCourseOutlineFunc
        ? getCourseOutlineFunc({
            courseId,
          })
        : undefined,
    {
      enabled: !!courseId && !!getCourseOutlineFunc,
    }
  );

  const invalidateCache = useCallback(
    () => queryClient.invalidateQueries([USE_COURSE_OUTLINE, courseId]),
    [courseId, queryClient]
  );

  return {
    useCourseOutlineQuery,
    invalidateCache,
  };
};
