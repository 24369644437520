import { DiscussionItem, User } from '@stellar-lms-frontend/lms-api';
import {
  HeartIcon,
  HeartIconFilled,
  Form,
  Moment,
  SendIcon,
  SingleTextBalloonIcon,
  SingleTextBalloonIconFilled,
  TextArea,
} from '@stellar-lms-frontend/ui-components';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { DISCUSSIONS_QUERY_KEY } from '../query';
import { ReplyItemComponent } from './reply-item-component';
import { useTranslation } from 'react-i18next';

export const getUserRole = (user: User) => {
  // TODO Use CourseRegistration role
  return undefined;
  /*let roleLabel = '';
  if (!user.roles) {
    return roleLabel;
  }
  if (user.roles.some((role) => role.id === 'student')) {
    roleLabel = i18n.roles.learner;
  } else if (user.roles.some((role) => role.id === 'mentor')) {
    roleLabel = i18n.roles.mentor;
  } else if (user.roles.some((role) => role.id === 'designer')) {
    roleLabel = i18n.roles.designer;
  }
  return roleLabel;*/
};

export type I18N = {
  roles: {
    learner: string;
    mentor: string;
    designer: string;
  };
  like: (amount: number) => string;
  replyButton: string;
  replyPlaceholder: string;
};

type Actions = {
  like: (activityId: string, stepId: string, commentId: string) => Promise<void>;
  unlike: (activityId: string, stepId: string, commentId: string) => Promise<void>;
  reply: (activityId: string, stepId: string, reply: string, commentId: string) => Promise<void>;
};

export type ItemComponentProps = {
  companyId: string;
  item: DiscussionItem;
  size?: 'small' | 'base';
  actions: Actions;
};

export const ItemComponent: React.FC<ItemComponentProps> = ({
  companyId,
  item,
  size = 'base',
  actions,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'discussion' });

  const [showReply, setShowReply] = useState(false);

  const queryClient = useQueryClient();

  const { register, handleSubmit, reset } = useForm();
  const date = new Date();
  date.setSeconds(date.getSeconds() - item.secondsSinceLastUpdate);

  const handleLikeClick = (item: DiscussionItem) => {
    if (item.liked) {
      actions.unlike(item.activityId, item.stepId, item.id).then(() => {
        queryClient.invalidateQueries([DISCUSSIONS_QUERY_KEY]);
      });
    } else {
      actions.like(item.activityId, item.stepId, item.id).then(() => {
        item.liked = true;
        queryClient.invalidateQueries([DISCUSSIONS_QUERY_KEY]);
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (data: any) => {
    if (data.comment === '') return;

    await actions.reply(item.activityId, item.stepId, data.comment, item.id);

    reset();
    queryClient.invalidateQueries([DISCUSSIONS_QUERY_KEY]);
    setShowReply(false);
  };

  return (
    <div className="relative">
      {item.createdBy && (
        <img
          className={`absolute -left-1 -top-1 rounded-full border-4 border-white
        ${size === 'small' && 'h-8 w-8'}
        ${size === 'base' && 'h-10 w-10'}`}
          src={item.createdBy.thumbUri}
          alt="thumbnail"
        />
      )}
      <div className=" bg-surface-02 ml-4 flex flex-col rounded-xl py-4 pl-8 pr-4">
        <div className="flex flex-row justify-between">
          {item.createdBy && (
            <span className="font-lexend text-text-01 text-sm font-semibold">
              {item.createdBy.name}
            </span>
          )}
          <span className="font-lexend text-text-03 font-regular text-sm">
            <Moment date={item.createdAt} />
          </span>
        </div>
        <div
          data-cy="comment-text"
          className="font-lexend font-regular text-text-01 mb-2 whitespace-pre-wrap break-words text-sm"
        >
          {item.text}
        </div>
        <div>
          <div className="flex flex-row space-x-4">
            <div
              role="button"
              className="flex cursor-pointer flex-row items-center"
              onClick={() => handleLikeClick(item)}
            >
              {item.liked ? (
                <HeartIconFilled className="text-negative-01 h-4 w-4" />
              ) : (
                <HeartIcon className=" text-text-03 h-4 w-4" />
              )}
              <span className="font-lexend text-text-03 pl-1 text-sm font-medium">
                {t('like', { count: item.nrOfLikes })}
              </span>
            </div>
            <div
              role="button"
              data-cy="open-reply-button"
              className={`${
                showReply ? 'text-primary-01' : 'text-text-03'
              } font-lexend flex flex-row items-center space-x-1 text-sm font-medium`}
              onClick={() => setShowReply(!showReply)}
            >
              {showReply ? (
                <SingleTextBalloonIconFilled className="text-primary-01 h-4 w-4" />
              ) : (
                <SingleTextBalloonIcon className="text-text-03 h-4 w-4" />
              )}
              <span>{t('reply-button')}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-3 pl-4">
        {showReply && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <TextArea
              {...register('comment')}
              htmlId="reply-comment-input"
              heightClassName="min-h-[100px] max-h-[240px]"
              placeholder={t('reply-placeholder')}
              icon={
                <SendIcon
                  className="text-primary-01"
                  data-cy="reply-comment-button"
                />
              }
              iconClick={handleSubmit(onSubmit)}
            />
          </Form>
        )}
        {item.replies && item.replies.length > 0 && (
          <ul>
            {item.replies
              .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
              .map((reply, index) => (
                <li
                  key={reply.id}
                  data-cy={`comment-reply-${index}`}
                >
                  <ReplyItemComponent
                    companyId={companyId}
                    item={reply}
                    onLikeClick={() => handleLikeClick(reply)}
                  />
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};
