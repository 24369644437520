import { renderToString } from 'react-dom/server';
import { HandPointRightIcon } from '../../../icons';
import { BlockEditorTypesEnum } from '../../block-editor';
import { API, BlockToolConstructorOptions, BlockToolData, ToolConfig } from '@editorjs/editorjs';
import { createRoot } from 'react-dom/client';
import { v4 as uuidv4 } from 'uuid';
import { PracticalTipsComponent } from './practical-tips-component';

export interface PracticalTipsData {
  title: string;
  items: string[];
}
export class PracticalTips {
  data: PracticalTipsData;
  id: string = uuidv4();
  readOnly: boolean;
  wrapper: HTMLElement;
  api: API;

  constructor({
    data,
    api,
    config,
    readOnly,
  }: BlockToolConstructorOptions<PracticalTipsData, ToolConfig>) {
    this.data = {
      items: data.items ?? [''],
      title: data.title ?? api.i18n.t('title-placeholder'),
    };
    this.api = api;
    this.readOnly = readOnly;

    this.wrapper = this.createElement();
  }

  static get toolbox() {
    return {
      title: BlockEditorTypesEnum.PRACTICAL_TIPS,
      icon: renderToString(<HandPointRightIcon className="text-text-01 !h-4" />),
    };
  }

  static get inlineToolbar() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get enableLineBreaks() {
    return true;
  }

  updateElement() {
    const root = createRoot(this.wrapper);
    root.render(
      <PracticalTipsComponent
        id={this.id}
        data={this.data}
        isReadOnly={this.readOnly}
        onChange={(data) => (this.data = data)}
        api={this.api}
      />
    );
  }

  createElement() {
    const rootNode = document.createElement('div');
    rootNode.id = uuidv4();
    return rootNode;
  }

  save(): BlockToolData<PracticalTipsData> {
    return {
      items: this.data.items,
      title: this.data.title,
    };
  }
  render() {
    this.updateElement();
    return this.wrapper;
  }
}
