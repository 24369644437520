import { useEventListener } from '@stellar-lms-frontend/common-utils';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

type PageCoordinates = {
  pageX: number;
  pageY: number;
};

export type DragContainer = {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
};

export type DraggableWrapperProps = {
  canDragX?: boolean;
  canDragY?: boolean;
  dragContainer?: DragContainer;
  children: ReactNode;
  onChangePosition?: (x: number, y: number) => void;
  className?: string;
  x: number;
  y: number;
};

export const DraggableWrapper = React.forwardRef<HTMLDivElement, DraggableWrapperProps>(
  (
    { canDragX, canDragY, dragContainer, children, onChangePosition, className = '', x, y },
    ref
  ) => {
    const refWrapper = useRef<HTMLDivElement>(null);
    const [isDragEnable, setIsDragEnable] = useState(false);
    const previousPos = useRef<PageCoordinates | undefined>(undefined);

    const onMouseUp = useCallback(() => {
      setIsDragEnable(false);
      previousPos.current = undefined;
    }, []);

    useEffect(() => {
      if (isDragEnable) window.document.body.style.overflow = 'hidden';

      return () => {
        window.document.body.style.overflow = 'auto';
      };
    }, [isDragEnable]);

    const onDrag = useCallback(
      ({ pageX, pageY }: PageCoordinates) => {
        if (!refWrapper.current) return;
        const { offsetLeft: left, offsetTop: top, offsetWidth, offsetHeight } = refWrapper.current;
        const hasPrevious = !!previousPos.current;

        let newLeft = left;
        let newTop = top;

        if (previousPos.current) {
          newLeft = newLeft + pageX - previousPos.current.pageX;
          newTop = newTop + pageY - previousPos.current.pageY;
        }

        previousPos.current = {
          pageX,
          pageY,
        };

        if (!hasPrevious) return;

        if (dragContainer) {
          if (newLeft < dragContainer.minX) newLeft = dragContainer.minX;
          if (newLeft + offsetWidth > dragContainer.maxX)
            newLeft = dragContainer.maxX - offsetWidth;
          if (newTop < dragContainer.minY) newTop = dragContainer.minY;
          if (newTop + offsetHeight > dragContainer.maxY)
            newTop = dragContainer.maxY - offsetHeight;
        }

        if (canDragX) refWrapper.current.style.left = `${newLeft}px`;
        if (canDragY) refWrapper.current.style.top = `${newTop}px`;

        onChangePosition?.(newLeft, newTop);
      },
      [canDragX, canDragY, onChangePosition, dragContainer]
    );

    useEventListener(
      'mousemove',
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        onDrag(e);
      },
      isDragEnable
    );
    useEventListener(
      'touchmove',
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        onDrag(e.targetTouches[0]);
      },
      isDragEnable
    );
    useEventListener('mouseup', onMouseUp, true);
    useEventListener('touchend', onMouseUp, true);

    useEffect(() => {
      if (!refWrapper.current) return;

      refWrapper.current.style.left = `${x}px`;
      refWrapper.current.style.top = `${y}px`;

      onChangePosition?.(x, y);
    }, [x, y, onChangePosition]);

    return (
      <div
        className="relative h-0 w-full"
        ref={ref}
      >
        <div
          className={`${className} absolute`}
          ref={refWrapper}
          onMouseDown={(e) => {
            onDrag(e);
            setIsDragEnable(true);
          }}
          onTouchStart={(e) => {
            onDrag(e.targetTouches[0]);
            setIsDragEnable(true);
          }}
        >
          {children}
        </div>
      </div>
    );
  }
);
