import { Course, graphql, QueryCourseArgs } from '@stellar-lms-frontend/lms-graphql';
import { graphQLClient } from '../../lib/graphql';

export const getMentorJourney = (variables: QueryCourseArgs) => {
  return graphQLClient('mentor')
    .request(
      graphql(`
        query mentorJourneyPage($id: ID!) {
          course(id: $id) {
            id
            outline {
              modules {
                id
                title
                description
                learningActivities {
                  id
                  title
                  dateOpen
                  locked
                  progress
                  duration
                  description
                  type
                  steps {
                    id
                    title
                    duration
                  }
                  prerequisites {
                    mandatory
                    module {
                      title
                    }
                  }
                }
              }
            }
          }
        }
      `),
      variables
    )
    .then((res) => res.course as Course);
};
