import { ReactNode } from 'react';
import { SpinnerLoader } from '../loaders';
import { NoData } from './no-data';

export const LoaderWrapper = ({
  children,
  isLoading,
  hasValue,
}: {
  children: ReactNode;
  isLoading: boolean;
  hasValue: boolean;
}) =>
  isLoading ? (
    <div className="flex h-full w-full items-center justify-center">
      <SpinnerLoader
        color="grey"
        className="text-[0.5rem]"
      />
    </div>
  ) : !hasValue ? (
    <NoData />
  ) : (
    <>{children}</>
  );
