import { useMediaQuery } from '@stellar-lms-frontend/common-utils';
import { ConditionalWrapper, ScrollContainer } from '@stellar-lms-frontend/ui-components';

import { GetDiscussionItemsFunc, useDiscussion } from './hooks/use-discussion';
import { CommentInputComponent, EmptyStateComponent, ListComponent } from './components';

import { CommentInputComponentProps } from './components/comment-input-component';
import { ListComponentProps } from './components/list-component';

type Actions = {
  getDiscussionItems: GetDiscussionItemsFunc;
  createComment: CommentInputComponentProps['createComment'];
} & ListComponentProps['actions'];

export type DiscussionViewProps = {
  companyId: string;
  learningActivityId: string;
  stepId: string;
  actions: Actions;
};
export const DiscussionView: React.FC<DiscussionViewProps> = ({
  companyId,
  learningActivityId,
  stepId,
  actions,
}) => {
  const isDesktop = useMediaQuery('(min-width: 1028px)');

  const { data, hasComments, endOfPageRef } = useDiscussion(
    actions.getDiscussionItems,
    learningActivityId,
    stepId
  );

  return (
    <div
      className="space-between flex h-full w-full flex-col bg-white
      lg:w-[460px] lg:min-w-[460px] lg:shrink-0"
    >
      <ConditionalWrapper
        hasWrapper={isDesktop}
        wrapper={(children) => (
          <ScrollContainer scrollOnDesktop>
            <div className="lg:flex lg:flex-col-reverse">{children}</div>
          </ScrollContainer>
        )}
      >
        <>
          <div className="grow overflow-hidden">
            {data && hasComments ? (
              <ListComponent
                companyId={companyId}
                actions={actions}
                endOfPage={
                  <div
                    className="h-[1px]"
                    ref={endOfPageRef}
                  />
                }
                items={Array.prototype.concat.apply([], data.pages)}
              />
            ) : (
              <EmptyStateComponent />
            )}
          </div>
          <div
            className="shadow-floating-input shrink-0 grow-0 rounded-t-3xl px-6 pt-4
                pb-8 lg:rounded-none lg:py-4 lg:px-10 lg:shadow-none"
          >
            <CommentInputComponent
              learningActivityId={learningActivityId}
              stepId={stepId}
              createComment={actions.createComment}
            />
          </div>
        </>
      </ConditionalWrapper>
    </div>
  );
};
