import {
  Heading3,
  HintDescription,
  LinkButton,
  LoadingCard,
  PrimaryButton,
  Redo2Icon,
  Sidebar,
  SkeletonCard,
  StarIcon,
  YellowExclamationIllustration,
} from '@stellar-lms-frontend/ui-components';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getNumbersArray } from '@stellar-lms-frontend/common-utils';
import { ConfirmationModal } from '../confirmation-modal/confirmation-modal';
import { SuggestionsStatus } from '@stellar-lms-frontend/lms-graphql';

export interface AISidebarWrapperBaseSuggestionInput {
  id: string;
}

export interface AISidebarWrapperBaseSuggestionOutput {
  id?: string;
}

export interface AISidebarWrapperI18N {
  title: string;
  hint: string;
  load: {
    more: string;
    message: string;
  };
  buttons: {
    add: string;
    cancel: string;
  };
  confirmOverwriteModal?: {
    title: string;
    description: string;
    confirm: string;
    cancel: string;
  };
  retryMessage: {
    title: string;
    description: string;
    retry: string;
  };
}

export type RenderAISuggestion<
  SInput extends AISidebarWrapperBaseSuggestionInput,
  SOutput extends AISidebarWrapperBaseSuggestionOutput
> = (props: {
  suggestion: SInput;
  isSelected: boolean;
  setAddedSuggestions: Dispatch<SetStateAction<SOutput[]>>;
  setAcceptedSuggestions: Dispatch<SetStateAction<Set<string>>>;
  onRemoveSuggestion: (id: string) => void;
}) => JSX.Element;

export interface AISidebarProps<
  SInput extends AISidebarWrapperBaseSuggestionInput,
  SOutput extends AISidebarWrapperBaseSuggestionOutput
> {
  isOpen: boolean;
  onClose: () => void;
  suggestions: SInput[];
  suggestionsStatus: SuggestionsStatus;
  fetchMoreSuggestions: () => void;
  hasMoreSuggestions?: boolean;
  onAdd: (addedSuggestions: SOutput[], acceptedSuggestions: Set<string>) => void;
  startSuggestionsGeneration: () => Promise<boolean>;
  hasConfirmOverwriteModal?: boolean;
  forceReGenerate?: boolean;
}

export type AISidebarWrapperProps<
  SInput extends AISidebarWrapperBaseSuggestionInput,
  SOutput extends AISidebarWrapperBaseSuggestionOutput
> = AISidebarProps<SInput, SOutput> & {
  i18n: AISidebarWrapperI18N;
  renderSuggestion: RenderAISuggestion<SInput, SOutput>;
};

export const AISidebarWrapper = <
  SInput extends AISidebarWrapperBaseSuggestionInput,
  SOutput extends AISidebarWrapperBaseSuggestionOutput
>({
  isOpen,
  onClose,
  i18n,
  suggestions,
  suggestionsStatus,
  fetchMoreSuggestions,
  hasMoreSuggestions,
  onAdd,
  startSuggestionsGeneration,
  renderSuggestion,
  hasConfirmOverwriteModal,
  forceReGenerate = false,
}: AISidebarWrapperProps<SInput, SOutput>) => {
  const [addedSuggestions, setAddedSuggestions] = useState<SOutput[]>([]);
  const [acceptedSuggestions, setAcceptedSuggestions] = useState<Set<string>>(new Set());
  const [isConfirmOverwriteModalOpen, setIsConfirmOverwriteModalOpen] = useState(false);

  const isLoading = suggestionsStatus === SuggestionsStatus.IN_PROGRESS;
  const isDone = suggestionsStatus === SuggestionsStatus.DONE;
  const isLoadingVisible = (isLoading || suggestions.length === 0) && !isDone;
  const isRetryMessageVisible =
    (!isLoading && suggestions.length === 0 && isDone) ||
    suggestionsStatus === SuggestionsStatus.ERROR;

  useEffect(() => {
    if (isOpen && (forceReGenerate || suggestionsStatus === SuggestionsStatus.NONE)) {
      startSuggestionsGeneration();
    }
  }, [forceReGenerate, isLoading, isOpen, startSuggestionsGeneration, suggestionsStatus]);

  const onRequestClose = () => {
    setAddedSuggestions([]);
    setAcceptedSuggestions(new Set());
    onClose();
  };

  const onRemoveSuggestion = (id: string) => {
    setAcceptedSuggestions((prev) => {
      prev.delete(id);
      return new Set(prev);
    });
    setAddedSuggestions((prev) => prev.filter((q) => q.id !== id));
  };

  const handleAdd = () => {
    onAdd(
      addedSuggestions.map(({ ...rest }) => ({ ...rest, id: undefined })),
      acceptedSuggestions
    );
    onRequestClose();
  };

  return (
    <>
      <Sidebar
        isOpen={isOpen}
        onClose={onRequestClose}
        position="right"
        contentClassName="md:max-w-[600px] md:w-[60%] font-lexend"
        isDisableScrollEnabled={false}
        hasBackdrop={false}
        header={<Heading3 className="text-text-01">{i18n.title}</Heading3>}
        buttons={{
          hasDivider: true,
          buttons: [
            <PrimaryButton
              key={1}
              label={i18n.buttons.add}
              enabled={addedSuggestions.length > 0}
              onClick={() => {
                hasConfirmOverwriteModal ? setIsConfirmOverwriteModalOpen(true) : handleAdd();
              }}
            />,
            <LinkButton
              key={2}
              label={i18n.buttons.cancel}
              onClick={onRequestClose}
            />,
          ],
        }}
      >
        <div className="space-y-4 pb-4">
          {isLoadingVisible && (
            <LoadingCard
              color="dark-purple"
              title={<span className="text-sm font-medium">{i18n.load.message}</span>}
            />
          )}
          {suggestions.length > 0 && (
            <>
              <HintDescription
                description={<span className="text-sm font-medium">{i18n.hint}</span>}
                hasCloseButton={false}
              />
              {suggestions.map((s) =>
                renderSuggestion({
                  suggestion: s,
                  isSelected: acceptedSuggestions.has(s.id),
                  setAddedSuggestions,
                  setAcceptedSuggestions,
                  onRemoveSuggestion,
                })
              )}
              {hasMoreSuggestions && !isLoading && (
                <LinkButton
                  leftIcon={<StarIcon className={`text-text-01 h-6 w-6`} />}
                  buttonStyle="fit"
                  label={i18n.load.more}
                  onClick={fetchMoreSuggestions}
                />
              )}
            </>
          )}
          {isLoadingVisible &&
            getNumbersArray(0, 3, 1).map((s) => {
              return (
                <SkeletonCard
                  key={s}
                  barConfigs={[
                    { width: '50%', height: '12px' },
                    { width: '100%', height: '8px' },
                    { width: '100%', height: '8px' },
                  ]}
                />
              );
            })}
        </div>
        {isRetryMessageVisible && (
          <div className="flex h-full max-w-[525px] flex-col items-center justify-center gap-6">
            <YellowExclamationIllustration />
            <p className="text-text-04 text-center text-xl">{i18n.retryMessage.title}</p>
            <p className="text-text-02 text-center">{i18n.retryMessage.description}</p>
            <PrimaryButton
              leftIcon={<Redo2Icon className="text-surface-01 h-5 w-5" />}
              label={i18n.retryMessage.retry}
              className="mt-4"
              onClick={startSuggestionsGeneration}
            />
          </div>
        )}
      </Sidebar>
      <ConfirmationModal
        title={i18n.confirmOverwriteModal?.title ?? ''}
        description={i18n.confirmOverwriteModal?.description ?? ''}
        buttons={{
          hasShadow: false,
          buttons: [
            <PrimaryButton
              key={1}
              theme="red"
              label={i18n.confirmOverwriteModal?.confirm ?? ''}
              onClick={() => {
                handleAdd();
                setIsConfirmOverwriteModalOpen(false);
              }}
            />,
            <LinkButton
              buttonStyle="fit"
              key={2}
              onClick={() => setIsConfirmOverwriteModalOpen(false)}
              label={i18n.confirmOverwriteModal?.cancel ?? ''}
            />,
          ],
        }}
        isOpen={isConfirmOverwriteModalOpen}
        onClose={() => setIsConfirmOverwriteModalOpen(false)}
      />
    </>
  );
};
