import { DiscussionItem } from '@stellar-lms-frontend/lms-api';
import { HeartIcon, HeartIconFilled, Moment } from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';

export type ReplyItemComponentProps = {
  companyId: string;
  item: DiscussionItem;
  onLikeClick: () => void;
};

export const ReplyItemComponent: React.FC<ReplyItemComponentProps> = ({
  companyId,
  item,
  onLikeClick,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'discussion' });

  return (
    <div className="mt-4 flex flex-row">
      {item.createdBy && (
        <img
          className="h-5 w-5 shrink-0 rounded-full"
          src={item.createdBy.thumbUri}
          alt="thumbnail"
        />
      )}
      <div className="flex grow flex-col overflow-x-hidden bg-white px-3">
        <div className="flex flex-row justify-between">
          {item.createdBy && (
            <span className="font-lexend text-text-01 text-sm font-semibold">
              {item.createdBy.name}
            </span>
          )}
          <span className="font-lexend text-text-03 font-regular text-sm">
            <Moment date={item.createdAt} />
          </span>
        </div>
        <div
          data-cy="reply-text"
          className="font-lexend font-regular text-text-01 mb-2 whitespace-pre-wrap break-words text-sm"
        >
          {item.text}
        </div>
        <div>
          <div className="flex flex-row space-x-4">
            <div
              role="button"
              className="flex cursor-pointer flex-row items-center"
              onClick={onLikeClick}
            >
              {item.liked ? (
                <HeartIconFilled className="text-negative-01 h-4 w-4" />
              ) : (
                <HeartIcon className=" text-text-03 h-4 w-4" />
              )}
              <span className="font-lexend text-text-03 pl-1 text-sm font-medium">
                {t('like', { count: item.nrOfLikes })}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
