import { navigation, useScreenType } from '@stellar-lms-frontend/common-utils';
import {
  ContentContainer,
  ScrollContainer,
  Heading3,
  PrimaryButton,
  LogoLoader,
  LeftArrowIcon,
} from '@stellar-lms-frontend/ui-components';
import { useQuery } from '@tanstack/react-query';
import { PUBLISHED_SURVEY } from '../../../constants/query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReadOnlyQuestionAnswer } from './read-only-question-answer';
import { useEffect } from 'react';
import {getWbaSurveyResult} from "../work-based-actions.api";
import {graphQLClient} from "../../../lib/graphql";

const NAVIGATION_KEY = 'wba-survey-result';

type WorkBasedActionSurveyResultProps = {
  title: string;
  surveyId: string;
  learnerWorkBasedActionId: string;
};
export const WorkBasedActionSurveyResult: React.FC<WorkBasedActionSurveyResultProps> = ({
  title,
  surveyId,
  learnerWorkBasedActionId,
}) => {
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const navigate = useNavigate();
  const { isDesktop } = useScreenType();

  const publishedSurveyQuery = useQuery(
    [PUBLISHED_SURVEY, learnerWorkBasedActionId, surveyId],
    () => getWbaSurveyResult(graphQLClient(), learnerWorkBasedActionId, surveyId)
  );

  useEffect(() => {
    if (!isDesktop) {
      navigation.setTitle(NAVIGATION_KEY, title);
      navigation.setLeftPrimary(NAVIGATION_KEY, {
        action: () => navigate(-1),
        label: '',
        leftIcon: <LeftArrowIcon className="text-on-primary-01" />,
      });
    }

    return () => {
      navigation.clearLevel(NAVIGATION_KEY);
    };
  }, [isDesktop, navigate, title]);

  if (publishedSurveyQuery.isLoading) {
    return <LogoLoader />;
  }

  return (
    <ContentContainer>
      <ScrollContainer>
        <div
          className="flex h-full w-full flex-col items-center justify-between"
          data-cy="wba-result-view"
        >
          <div className="max-w-content w-full space-y-6">
            {isDesktop && <Heading3>{title}</Heading3>}
            <div className="space-y-8">
              {publishedSurveyQuery.data?.questions.map((question) => (
                <div
                  key={question.id}
                  className="space-y-2"
                >
                  <div className="text-text-04 lexend text-base font-medium">{question.text}</div>
                  <ReadOnlyQuestionAnswer question={question} />
                </div>
              ))}
            </div>
            {isDesktop && (
              <div>
                <PrimaryButton
                  data-cy={'wba-survey-result-back-button'}
                  label={tGeneral('back')}
                  onClick={() => navigate(-1)}
                ></PrimaryButton>
              </div>
            )}
          </div>
        </div>
      </ScrollContainer>
    </ContentContainer>
  );
};
