import clarityInit from './clarity';

declare global {
  interface Window {
    clarity: {
      (command: 'set', key: string, value: string): void;
      (
        command: 'identify',
        userId: string,
        sessionId?: string,
        pageId?: string,
        friendlyName?: string
      ): void;
    };
  }
}

function init(envId: string): void {
  if (!hasClarity()) {
    clarityInit(envId);
  }
}

function hasClarity(): boolean {
  return typeof window?.clarity === 'function';
}

function set(key: string, value: string): void {
  window.clarity('set', key, value);
}

function identify(
  userId: string,
  sessionId?: string,
  pageId?: string,
  friendlyName?: string
): void {
  window.clarity('identify', userId, sessionId, pageId, friendlyName);
}

export const clarity = {
  hasClarity,
  set,
  identify,
  init,
};
