import {
  PrimaryButton,
  TertiaryButton,
  UserIllustration,
} from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';

type NoMembersViewProps = {
  onAddMemberClick: () => void;
  onBulkAddMemberClick: () => void;
};

export const NoMembersView: React.FC<NoMembersViewProps> = ({
  onAddMemberClick,
  onBulkAddMemberClick,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'organization.members-tab.empty' });

  return (
    <div
      className={`mx-auto flex h-full w-[508px] justify-center pt-4 text-center`}
      data-cy="empty-state"
    >
      <div className={`flex flex-col items-center justify-start space-y-8`}>
        <UserIllustration className=" shrink-0" />
        <div className="space-y-4 px-8">
          <h3 className="type-headline-3 text-text-01">{t('title')}</h3>
          <p className=" type-body text-text-02">{t('description')}</p>
        </div>
        <div className="flex flex-col items-center space-y-4">
          <PrimaryButton
            label={t('actions.add')}
            onClick={onAddMemberClick}
            fullWidth
          />
          <TertiaryButton
            label={t('actions.bulk')}
            onClick={onBulkAddMemberClick}
            fullWidth
          />
        </div>
      </div>
    </div>
  );
};
