import {
  Heading4,
  ProgressBar,
  redBg,
  yellowBg,
  orangeBg,
  pinkBg,
  purpleBg,
  cyanBg,
  greenBg,
  indigoBg,
  grayBg,
  FloatingOptions,
  MoreVertIcon,
  TrashIcon,
  CopyIcon,
  Edit3Icon,
  ToolTip,
  BadgeLanguage,
} from '@stellar-lms-frontend/ui-components';
import { ReactNode, useMemo, useRef, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  CourseOutline,
  LearningActivityOutline,
  LearningActivityProgress,
  Maybe,
} from '@stellar-lms-frontend/lms-graphql';
import { secondsAsHours, useOnClickOutside } from '@stellar-lms-frontend/common-utils';

export type BoxColor =
  | 'pink'
  | 'red'
  | 'yellow'
  | 'purple'
  | 'orange'
  | 'cyan'
  | 'green'
  | 'indigo'
  | 'gray';

export type DashboardStatsBoxI18N = {
  next: string;
  delete?: string;
  duplicate?: string;
  edit?: string;
};

type DashboardStatsBoxProps = {
  color: BoxColor;
  containerClassName?: string;
} & (
  | {
      isPreview: true;
      title?: never;
      courseId?: never;
      courseLanguage?: never;
      progress?: never;
      stats?: never;
      courseOutline?: never;
      i18n?: never;
      options?: never;
    }
  | {
      isPreview: false;
      title: string;
      courseId: string;
      courseLanguage?: string | null;
      progress?: number;
      stats?: { info: ReactNode; name: string }[];
      courseOutline?: Maybe<CourseOutline>; // TODO: after fix the schema, define this type locally
      i18n?: DashboardStatsBoxI18N;
      options?: {
        onEdit: () => void;
        onDuplicate: () => void;
        onDelete: () => void;
      };
    }
);

export const DashboardStatsBox: React.FC<DashboardStatsBoxProps> = ({
  color,
  courseId,
  containerClassName,
  title,
  isPreview,
  progress,
  stats,
  courseOutline,
  courseLanguage,
  i18n,
  options,
}) => {
  const {
    backgroundImage,
    titleClassName,
    progressBGColorClassName,
    progressFGColorClassName,
    skeletonProps,
  } = useMemo(() => {
    switch (color) {
      case 'pink':
        return {
          backgroundImage: pinkBg,
          titleClassName: 'text-pink-700',
          progressBGColorClassName: 'bg-pink-700/10',
          progressFGColorClassName: 'bg-pink-500/50',
          skeletonProps: {
            baseColor: '#b832801A',
            highlightColor: '#ed64a680',
          },
        };

      case 'red':
        return {
          backgroundImage: redBg,
          titleClassName: 'text-red-700',
          progressBGColorClassName: 'bg-red-700/10',
          progressFGColorClassName: 'bg-red-500/50',
          skeletonProps: {
            baseColor: '#c530301A',
            highlightColor: '#f5656580',
          },
        };

      case 'yellow':
        return {
          backgroundImage: yellowBg,
          titleClassName: 'text-yellow-700',
          progressBGColorClassName: 'bg-yellow-700/10',
          progressFGColorClassName: 'bg-yellow-500/50',
          skeletonProps: {
            baseColor: '#b7791f1A',
            highlightColor: '#ecc94b80',
          },
        };

      case 'purple':
        return {
          backgroundImage: purpleBg,
          titleClassName: 'text-purple-700',
          progressBGColorClassName: 'bg-purple-700/10',
          progressFGColorClassName: 'bg-purple-500/50',
          skeletonProps: {
            baseColor: '#6b46c11A',
            highlightColor: '#9f7aea80',
          },
        };

      case 'orange':
        return {
          backgroundImage: orangeBg,
          titleClassName: 'text-orange-700',
          progressBGColorClassName: 'bg-orange-700/10',
          progressFGColorClassName: 'bg-orange-500/50',
          skeletonProps: {
            baseColor: '#c05621A1',
            highlightColor: '#ed893680',
          },
        };

      case 'cyan':
        return {
          backgroundImage: cyanBg,
          titleClassName: 'text-cyan-700',
          progressBGColorClassName: 'bg-cyan-700/10',
          progressFGColorClassName: 'bg-cyan-500/50',
          skeletonProps: {
            baseColor: '#0e74901A',
            highlightColor: '#00BCD480',
          },
        };

      case 'green':
        return {
          backgroundImage: greenBg,
          titleClassName: 'text-green-700',
          progressBGColorClassName: 'bg-green-700/10',
          progressFGColorClassName: 'bg-green-500/50',
          skeletonProps: {
            baseColor: '#2f855a1A',
            highlightColor: '#48bb7880',
          },
        };

      case 'indigo':
        return {
          backgroundImage: indigoBg,
          titleClassName: 'text-indigo-700',
          progressBGColorClassName: 'bg-indigo-700/10',
          progressFGColorClassName: 'bg-indigo-500/50',
          skeletonProps: {
            baseColor: '#4c51bf1A',
            highlightColor: '#667eea80',
          },
        };

      case 'gray':
        return {
          backgroundImage: grayBg,
          titleClassName: 'text-gray-700',
          progressBGColorClassName: 'bg-gray-700/10',
          progressFGColorClassName: 'bg-gray-500/50',
          skeletonProps: {
            baseColor: '#4a55681A',
            highlightColor: '#a0aec080',
          },
        };
    }
  }, [color]);

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  useOnClickOutside(() => setIsOptionsOpen(false), [ref]);

  const nextLearningActivity = useMemo(() => {
    if (courseOutline) {
      for (const module of courseOutline.modules ?? []) {
        const nextLearningActivity = module.learningActivities?.find(
          (learningActivity) =>
            learningActivity.progress === LearningActivityProgress.InProgress ||
            learningActivity.progress === LearningActivityProgress.Todo
        );
        if (nextLearningActivity) {
          return nextLearningActivity;
        }
      }
    }

    return undefined;
  }, [courseOutline]);

  const randomWidthBetween = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min) + '%';
  };

  return (
    <SkeletonTheme
      baseColor={skeletonProps.baseColor}
      highlightColor={skeletonProps.highlightColor}
    >
      <div
        style={{ backgroundImage: `url(${backgroundImage})` }}
        className={`
        ${containerClassName ?? ''}
        flex flex-col justify-between rounded-xl bg-cover bg-right bg-no-repeat p-4  
        ${isPreview ? 'h-[100px] opacity-30 lg:h-[200px]' : 'lg:h-[156px]'}
        ${stats && 'lg:rounded-b-none'}`}
      >
        <div className="self-end">
          {options && (
            <FloatingOptions
              wrappedComponent={
                <button
                  ref={ref}
                  onClick={(e) => {
                    e.stopPropagation();
                    document.body.click();
                    setIsOptionsOpen(true);
                  }}
                >
                  <MoreVertIcon className="text-gray-700" />
                </button>
              }
              isOpen={isOptionsOpen}
              onClose={() => setIsOptionsOpen(false)}
              options={[
                {
                  onClick: (e) => {
                    e.stopPropagation();
                    setIsOptionsOpen(false);
                    options?.onEdit();
                  },
                  left: <Edit3Icon className="h-5 w-5" />,
                  label: i18n?.edit,
                },
                {
                  onClick: (e) => {
                    e.stopPropagation();
                    setIsOptionsOpen(false);
                    options?.onDuplicate();
                  },
                  left: <CopyIcon className="h-5 w-5" />,
                  label: i18n?.duplicate,
                },
                {
                  onClick: (e) => {
                    e.stopPropagation();
                    setIsOptionsOpen(false);
                    options?.onDelete();
                  },
                  left: <TrashIcon className="text-negative-01 h-5 w-5" />,
                  className: 'text-negative-01',
                  label: i18n?.delete,
                },
              ]}
            />
          )}
        </div>
        <div>
          <span
            className={`${
              titleClassName ?? ''
            } font-lexend text-text-01 overflow-hidden text-ellipsis whitespace-nowrap pb-1 text-base font-medium leading-normal lg:hidden lg:leading-[137%]`}
          >
            {courseOutline?.title ?? (
              <Skeleton
                enableAnimation={!isPreview}
                width={randomWidthBetween(30, 60)}
              />
            )}
          </span>
          <Heading4
            className={`${
              titleClassName ?? 'text-text-01'
            } hidden overflow-hidden text-ellipsis whitespace-nowrap pb-1 lg:block`}
          >
            {title ?? (
              <Skeleton
                enableAnimation={!isPreview}
                width={randomWidthBetween(30, 60)}
              />
            )}
          </Heading4>
          {courseOutline && courseOutline.modules && (
            <ToolTip
              isEnabled
              placement="bottom-start"
              color="dark"
              size="small"
              wrappedComponent={
                <span
                  className={`${
                    titleClassName ?? ''
                  } font-lexend text-text-01 font-regular block overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-normal lg:text-base`}
                >
                  {nextLearningActivity && i18n?.next ? (
                    <NextLearningActivityTitle
                      i18n={{ next: i18n.next }}
                      nextLearningActivity={nextLearningActivity}
                    />
                  ) : courseOutline ? (
                    <span>&nbsp;</span>
                  ) : (
                    <Skeleton
                      enableAnimation={!isPreview}
                      width={randomWidthBetween(60, 100)}
                    />
                  )}
                </span>
              }
            >
              {nextLearningActivity && i18n?.next && (
                <NextLearningActivityTitle
                  i18n={{ next: i18n.next }}
                  nextLearningActivity={nextLearningActivity}
                />
              )}
            </ToolTip>
          )}
          {!isPreview && progress !== undefined && (
            <ProgressBar
              className="pt-3"
              htmlId={`${courseId}-progress`}
              heightClassName="h-1"
              roundedClassName="rounded-2xl"
              bgColorClassName={progressBGColorClassName}
              progress={[
                {
                  value: progress * 100,
                  colorClassName: progressFGColorClassName,
                },
              ]}
            />
          )}
        </div>
      </div>
      {!isPreview && stats && (
        <ul className="bg-on-primary-01 text-text-02 hidden flex-row items-start justify-between rounded-b-xl border-b border-l border-r border-gray-100 p-6 text-sm shadow-sm lg:flex">
          {stats?.map(({ info, name }) => (
            <li key={name}>
              <p className="text-text-04 text-normal font-medium">{info}</p>
              <p>{name}</p>
            </li>
          ))}
          {courseLanguage && (
            <li>
              <BadgeLanguage label={courseLanguage} />
            </li>
          )}
        </ul>
      )}
    </SkeletonTheme>
  );
};

type NextLearningActivityTitleProps = {
  i18n: {
    next: string;
  };
  nextLearningActivity: LearningActivityOutline;
};

const NextLearningActivityTitle: React.FC<NextLearningActivityTitleProps> = ({
  nextLearningActivity,
  i18n,
}) => {
  return (
    <>
      <span className="font-medium">{`${i18n.next}: `}</span>
      {nextLearningActivity.title}
      {(nextLearningActivity?.duration ?? 0) > 0 && (
        <span className="text-sm">
          {` 
        (${secondsAsHours(nextLearningActivity.duration ?? 0)}h)`}
        </span>
      )}
    </>
  );
};
