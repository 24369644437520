import { WorkBasedActionLearningActivityStep } from '@stellar-lms-frontend/lms-api';
import { useParams } from 'react-router';
import WBAStepEdit, { WBAStepEditActions } from './wba-step-edit';
import WorkBasedActionStepView, {
  WorkBasedActionStepViewProps,
} from './work-based-action-step-view';
import { UploadActions } from '../learning-activity/learning-activity-step-view';

export type WorkBasedActionActions = WBAStepEditActions & {
  learnerWba: WorkBasedActionStepViewProps['actions'];
};

export type WorkBasedActionStepProps = {
  isEditing?: boolean;
  isLastStep: boolean;
  lastLearningActivity?: boolean;
  step?: WorkBasedActionLearningActivityStep;
  actions: UploadActions & WorkBasedActionActions;
  moduleId?: string; // TODO: consider adding it to the url courseId/moduleId/learningActivityId/stepId
};

export const WorkBasedActionStep: React.FC<WorkBasedActionStepProps> = ({
  isEditing,
  isLastStep,
  step,
  actions,
  moduleId,
}) => {
  const { learningActivityId, stepId, courseId } = useParams();

  if (!learningActivityId || !stepId || !courseId || !moduleId) {
    return null;
    // shouldn't we redirect?
  }

  if (isEditing) {
    return (
      <WBAStepEdit
        key={`${stepId}`}
        stepId={stepId}
        learningActivityId={learningActivityId}
        moduleId={moduleId}
        courseId={courseId}
        actions={actions}
      />
    );
  } else if (step) {
    return (
      <WorkBasedActionStepView
        actions={actions.learnerWba}
        key={step.id}
        step={step}
        isLastStep={isLastStep}
      />
    );
  } else {
    return null;
  }
};
