import { renderToString } from 'react-dom/server';
import { createRoot } from 'react-dom/client';
import { Edit4Icon } from '../../../icons';
import { API, BlockToolConstructorOptions, BlockToolData, ToolConfig } from '@editorjs/editorjs';
import { ArticleComponent, ArticleComponentProps } from './article-component';
import { v4 as uuidv4 } from 'uuid';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { EditorJsArticleBlock } from '@stellar-lms-frontend/lms-graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinesLeaning } from '@fortawesome/pro-light-svg-icons';
import { BlockEditorTypesEnum } from '../../block-editor';

export interface ArticleData extends BlockToolData {
  title: string;
  iconUrl?: string;
  description?: string;
  source: string;
  link: string;
}

interface ArticleConfig extends ToolConfig {
  urlAnalyserFunc: (url: string) => Promise<EditorJsArticleBlock | undefined>;
}

export class Article {
  api: API;
  data: BlockToolData<ArticleData>;
  internalData: ArticleComponentProps['state'] = { state: 'input', url: undefined };
  readOnly: boolean;
  wrapper: HTMLElement;
  config: ArticleConfig;
  id: string = uuidv4();

  constructor({
    data,
    api,
    config,
    readOnly,
  }: BlockToolConstructorOptions<ArticleData, ArticleConfig>) {
    if (!config) {
      this.config = { urlAnalyserFunc: () => Promise.reject() };
      Sentry.captureException(new Error('No analyser function provided for Articles in editorjs'), {
        level: 'error',
      });
    } else {
      this.config = config;
    }

    this.data = {
      title: data.title,
      description: data.description,
      source: data.source,
      iconUrl: data.iconUrl,
      link: data.link,
    };
    if (this.data.link?.trim()) {
      this.internalData = { state: 'display', data: this.data };
    }
    this.api = api;
    this.readOnly = readOnly;
    this.wrapper = this.createElement();
  }

  static get toolbox() {
    return {
      title: BlockEditorTypesEnum.ARTICLE,
      icon: renderToString(
        <FontAwesomeIcon
          icon={faLinesLeaning}
          className="text-text-01 !h-4"
        />
      ),
    };
  }

  static get inlineToolbar() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  createElement() {
    const rootNode = document.createElement('div');
    rootNode.id = this.id;
    return rootNode;
  }

  updateElement() {
    const root = createRoot(this.wrapper);
    root.render(
      <QueryClientProvider client={new QueryClient()}>
        <ArticleComponent
          id={this.id}
          isReadOnly={this.readOnly}
          analyseUrlFunc={this.config.urlAnalyserFunc}
          state={this.internalData}
          onChange={(newData) => (this.data = newData)}
          api={this.api}
        />
      </QueryClientProvider>
    );
  }

  render() {
    this.updateElement();
    return this.wrapper;
  }

  renderSettings() {
    return [
      {
        label: this.api.i18n.t('edit-article-menu-button'),
        icon: renderToString(<Edit4Icon className="text-text-01" />),
        onActivate: () => {
          this.internalData = { state: 'input', url: this.data.link };
          this.updateElement();
        },
        closeOnActivate: true,
      },
    ];
  }

  save(): BlockToolData<ArticleData> {
    return {
      title: this.data.title,
      description: this.data.description,
      source: this.data.source,
      iconUrl: this.data.iconUrl,
      link: this.data.link,
    };
  }
}
