export const DESIGNER = 'designer';
export const COURSE = 'course';
export const COURSES = 'courses';
export const COURSE_TITLE = 'course_title';
export const DASHBOARD = 'dashboard';
export const DASHBOARDS = 'dashboards';
export const COURSE_ACTIONS = 'course-actions';
export const COURSE_ACTION = 'course-action';
export const SURVEY = 'survey';
export const SURVEY_DETAILS = 'survey-details';
export const USER = 'user';
export const STATS = 'stats';
export const LEARNERS = 'learners';
export const ID = 'id';
export const COURSE_REGISTRATIONS = 'course-registrations';
export const COURSE_ID = 'course-id';
export const SKILLS = 'skills';
export const OBJECTIVES = 'objectives';
export const GRAPHQL = 'graphql';
export const STEP = 'learning-activity-step';
export const WBA = 'work-based-action';
export const TRANSFER_OBJECTIVES = 'transfer-objectives';
export const SCORM = 'scorm';
export const DESIGNER_LEARNER_TABLE = 'designer-learner-table';
