import { useEffect, useRef, useState } from 'react';
import { HeaderRightElements } from '../../learner-ui-shell/store';
import {
  ConditionalWrapper,
  FloatingOptions,
  FloatingOptionsOption,
  Tip,
} from '@stellar-lms-frontend/ui-components';
import { AISuggestionsButton } from '../components/ai-suggestions-button/ai-suggestions-button';
import { useOnClickOutside } from '@stellar-lms-frontend/common-utils';
import { useTranslation } from 'react-i18next';

export type UseAISuggestionsButtonArgs = {
  onClickNewFeatureTip?: () => void;
  wasViewed?: boolean;
} & (
  | {
      isLocked: true;
      options?: never;
      hasAlert?: never;
    }
  | {
      isLocked: false;
      options: FloatingOptionsOption[];
      hasAlert?: boolean;
    }
);

export const useAISuggestionsButton = ({
  options,
  isLocked,
  hasAlert,
  wasViewed = true,
  onClickNewFeatureTip,
}: UseAISuggestionsButtonArgs) => {
  const { t } = useTranslation('translation', { keyPrefix: 'learning-activity-overview' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const [addHeaderRightElement, removeHeaderRightElement] = HeaderRightElements.useStore(
    (state) => [state.addElement, state.removeElement]
  );
  const [isSuggestionsFloatingOpen, setIsSuggestionsFloatingOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useOnClickOutside(() => {
    if (!wasViewed) {
      onClickNewFeatureTip?.();
    }
  }, [ref]);

  useEffect(() => {
    if (!wasViewed) {
      timeoutRef.current = setTimeout(() => onClickNewFeatureTip?.(), 6000);
    }

    return () => {
      timeoutRef?.current && clearTimeout(timeoutRef.current);
    };
  }, [onClickNewFeatureTip, wasViewed]);

  useEffect(() => {
    addHeaderRightElement(
      'ai-button',
      !isLocked && (
        <FloatingOptions
          isOpen={isSuggestionsFloatingOpen}
          onClose={() => setIsSuggestionsFloatingOpen(false)}
          title={tGeneral('generate')}
          wrappedComponent={
            <ConditionalWrapper
              hasWrapper={!wasViewed}
              wrapper={(children) => (
                <Tip
                  color="dark"
                  onClick={onClickNewFeatureTip}
                  placement="bottom-start"
                  isOpen={!wasViewed}
                  wrappedComponent={children}
                >
                  <div
                    ref={ref}
                    className="max-w-[436px] space-y-1 whitespace-pre-wrap"
                  >
                    <div className="type-large-emphasis">{t('not-viewed-tooltip.title')}</div>
                    <div className="type-body-medium">{t('not-viewed-tooltip.description')}</div>
                  </div>
                </Tip>
              )}
            >
              <AISuggestionsButton
                onClick={(e) => {
                  e?.stopPropagation();
                  document.body.click();
                  wasViewed ? setIsSuggestionsFloatingOpen(true) : onClickNewFeatureTip?.();
                }}
                hasAlert={hasAlert}
              />
            </ConditionalWrapper>
          }
          options={
            options?.map((o) => ({
              ...o,
              onClick: (e) => {
                o.onClick?.(e);
                setIsSuggestionsFloatingOpen(false);
              },
            })) ?? []
          }
        />
      ),
      0
    );

    return () => {
      removeHeaderRightElement('ai-button');
    };
  }, [
    addHeaderRightElement,
    hasAlert,
    isLocked,
    isSuggestionsFloatingOpen,
    onClickNewFeatureTip,
    options,
    removeHeaderRightElement,
    t,
    tGeneral,
    wasViewed,
  ]);
};
