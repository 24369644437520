import { cn } from '@stellar-lms-frontend/common-utils';

export type SpinnerLoaderProps = {
  className?: string;
  color: 'dark-purple' | 'info-blue' | 'blue' | 'grey';
};

export const SpinnerLoader: React.FC<SpinnerLoaderProps> = ({ className = 'text-sm', color }) => {
  let spinnerAnimate;
  switch (color) {
    case 'dark-purple':
      spinnerAnimate = 'animate-darkPurpleSpinnerLoader';
      break;
    case 'info-blue':
      spinnerAnimate = 'animate-infoBlueSpinnerLoader';
      break;
    case 'blue':
      spinnerAnimate = 'animate-blueSpinnerLoader';
      break;
    case 'grey':
      spinnerAnimate = 'animate-greySpinnerLoader';
      break;
  }

  return <span className={cn('mx-auto h-[1em] w-[1em] rounded-full', spinnerAnimate, className)} />;
};
