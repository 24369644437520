import { create } from 'zustand';
import { Actions, State } from './types';

export const useStore = create<State & Actions>((set) => ({
  editingLearningActivityId: undefined,
  moduleIdToCreateLearningActivity: undefined,
  createLearningActivityType: undefined,
  startEditingLearningActivity: (editingLearningActivityId) => {
    set({
      editingLearningActivityId,
      moduleIdToCreateLearningActivity: undefined,
      createLearningActivityType: undefined,
    });
  },
  startCreatingLearningActivityForModule: ({ moduleId, type }) => {
    set({
      moduleIdToCreateLearningActivity: moduleId,
      createLearningActivityType: type,
      editingLearningActivityId: undefined,
    });
  },
  stopEditing: () => {
    set({
      editingLearningActivityId: undefined,
      moduleIdToCreateLearningActivity: undefined,
      createLearningActivityType: undefined,
    });
  },
}));
