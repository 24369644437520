import { useQuery } from '@tanstack/react-query';
import { getSRQLevelDistribution } from '../designer-insights.api';
import { useMemo } from 'react';
import { BarChart } from '@stellar-lms-frontend/ui-components';

const chartConfig = {
  level1: {
    label: 'Level 1',
    color: 'var(--chart-1)',
  },
  level2: {
    label: 'Level 2',
    color: 'var(--chart-2)',
  },
  level3: {
    label: 'Level 3',
    color: 'var(--chart-3)',
  },
  level4: {
    label: 'Level 4',
    color: 'var(--chart-4)',
  },
  level5: {
    label: 'Level 5',
    color: 'var(--chart-5)',
  },
};
export const TotalSRQDistributionChart = ({ courseId }: { courseId: string }) => {
  const { data, isLoading } = useQuery(
    ['srq-level-distribution', courseId],
    () => getSRQLevelDistribution(courseId),
    { refetchOnWindowFocus: false }
  );

  const rows = useMemo(() => {
    if (!data) return [];

    return data.courseSRQLevelDistribution.rows.reduce((acc, curr) => {
      acc.push({
        category: curr.category,
        level1: curr.items.find((i) => i.category === 'level1')?.count ?? 0,
        level2: curr.items.find((i) => i.category === 'level2')?.count ?? 0,
        level3: curr.items.find((i) => i.category === 'level3')?.count ?? 0,
        level4: curr.items.find((i) => i.category === 'level4')?.count ?? 0,
        level5: curr.items.find((i) => i.category === 'level5')?.count ?? 0,
      });
      return acc;
    }, [] as { category: string; level1: number; level2: number; level3: number; level4: number; level5: number }[]);
  }, [data]);

  return (
    <BarChart
      isLoading={isLoading}
      rows={rows}
      dataColumns={['level1', 'level2', 'level3', 'level4', 'level5']}
      chartConfig={chartConfig}
      stacked={false}
      showValueInBar
      showYaxis={false}
    />
  );
};
