import { ReactElement, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MobileNavFooter } from './mobile-nav-footer';
import { ButtonConfig } from './types';
import { ContentContainer } from '../content-container/content-container';
import { ScrollContainer } from '../scroll-container/scroll-container';
import { ConditionalWrapper } from '../wrappers/conditional-wrapper/conditional-wrapper';
import { Header, ThemableNavEntriesFunc } from './header';
import { EditingHeader } from '../header';
import { SubNavRightButtonConfig } from './sub-nav';

export type RealUIShellProps = {
  headerTheme?: 'view' | 'edit';
  // If we want our scrolling to be on the full viewport or in separate sections
  scrollOnDesktop?: boolean;
  autoScrollTop?: boolean;
  children: ReactElement; // TODO, should be ReactNode to comply with the PropsWithChildren type of react
  leftButton?: ButtonConfig;
  rightButton?: SubNavRightButtonConfig;
  leftSideBar?: ReactNode;
  rightSideBar?: ReactNode;
  useContentContainer?: boolean;
  navHeaderTitle?: ReactNode;
  subNavTitle?: ReactNode;
  subNavSubtitle?: string;
  leftTopNavEntries?: ThemableNavEntriesFunc;
  centerTopNavEntries?: ThemableNavEntriesFunc;
  rightTopNavEntries?: ThemableNavEntriesFunc;
  bottomNavEntries?: ReactNode[];
  showBottomNav?: boolean;
};

export const UIShell: React.FC<RealUIShellProps> = ({
  scrollOnDesktop = false,
  navHeaderTitle,
  subNavTitle,
  subNavSubtitle,
  children,
  leftTopNavEntries,
  centerTopNavEntries,
  rightTopNavEntries,
  bottomNavEntries,
  showBottomNav = true,
  autoScrollTop = true,
  leftButton,
  rightButton,
  leftSideBar,
  rightSideBar,
  useContentContainer = true,
  headerTheme = 'view',
}) => {
  const { pathname } = useLocation();

  // TODO: Dirty, no immediate alternative known and no time to investigate
  useEffect(() => {
    if (autoScrollTop) {
      window.scrollTo(0, 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]); // We do NOT want this to run again after the load of the page

  useEffect(() => {
    const reactNativeWebView = window.ReactNativeWebView;
    if (reactNativeWebView) {
      reactNativeWebView.postMessage(
        JSON.stringify({
          header_background: '#2551DA',
          header_style: 'light',
          footer_background: '#FFFFFF',
        })
      );
    }
  }, []);

  return (
    <div
      className={`font-lexend white-label-company-header flex h-full flex-col items-center 
      ${scrollOnDesktop ? '' : 'lg:h-auto'}
      ${headerTheme === 'view' ? 'bg-primary-01' : ''}`}
    >
      {headerTheme === 'view' ? (
        <Header
          mainCenterComponent={navHeaderTitle}
          leftNavEntries={leftTopNavEntries}
          centerNavEntries={centerTopNavEntries}
          rightNavEntries={rightTopNavEntries}
          leftButton={leftButton}
          rightButton={rightButton}
          subNavTitle={subNavTitle}
          subNavSubtitle={subNavSubtitle}
        />
      ) : (
        <EditingHeader
          className="shrink-0 grow-0"
          title={navHeaderTitle ?? ''}
          backButton={
            leftButton
              ? {
                  label: leftButton.label ?? '',
                  onClick: leftButton.action,
                }
              : undefined
          }
        />
      )}
      <div
        className={`bg-surface-01 block w-full grow overflow-hidden lg:flex 
        ${scrollOnDesktop ? '' : 'lg:overflow-visible'}
        ${headerTheme === 'view' ? 'rounded-t-4xl' : ''}`}
      >
        {leftSideBar}
        <ConditionalWrapper
          hasWrapper={useContentContainer}
          wrapper={(children) => (
            <ScrollContainer scrollOnDesktop={scrollOnDesktop}>
              <ContentContainer>{children}</ContentContainer>
            </ScrollContainer>
          )}
        >
          {children}
        </ConditionalWrapper>
        {rightSideBar && (
          <div className="from-surface-02 to-surface-01 rounded-t-4xl w-[560px] min-w-[250px] grow-0 bg-gradient-to-b p-6 xl:p-16">
            <div className="pt-[88px] xl:pt-12">{rightSideBar}</div>
          </div>
        )}
      </div>
      {showBottomNav && (
        <div className="w-full lg:hidden">
          <MobileNavFooter entries={bottomNavEntries ?? []} />
        </div>
      )}
    </div>
  );
};
