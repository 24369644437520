import { ButtonConfig } from './types';
import { LinkButton, SecondaryButton } from '../buttons';
import { ReactNode, useState } from 'react';
import { Heading4 } from '../heading';
import { VoidFunc, useScreenType } from '@stellar-lms-frontend/common-utils';
import { ConditionalWrapper, FloatingOptions, FloatingOptionsProps } from '../wrappers';

export type SubNavRightButtonConfig = Omit<ButtonConfig, 'action'> & {
  // The omitted props are configured by SubNav when calling the FloatingOptions component
  floatingOptions?: Omit<FloatingOptionsProps, 'isOpen' | 'onClose' | 'wrappedComponent'>;
  action?: VoidFunc;
};

export type SubNavProps = {
  rightButton?: SubNavRightButtonConfig;
  leftButton?: ButtonConfig;
  title?: ReactNode;
  subtitle?: string;
  entries?: ReactNode[];
};

export const SubNav: React.FC<SubNavProps> = ({
  leftButton,
  rightButton,
  title,
  subtitle,
  entries,
}) => {
  const { isTailwindLg } = useScreenType();
  const [isRightOptionsOpen, setIsRightOptionsOpen] = useState(false);

  return (
    <div className="flex w-full shrink-0 flex-col justify-end px-8 pt-4 pb-6 lg:h-[80px] lg:px-16 lg:pt-0">
      {title && (
        <div
          className={`text-on-primary-01 grid grid-cols-[min-content_1fr_min-content] items-center justify-items-start lg:items-start
          ${leftButton && 'gap-x-2 xl:-ml-[36px] xl:gap-x-4'}`}
        >
          <div className="mt-1">
            <LinkButton
              label=""
              htmlId="back"
              buttonStyle="fit"
              onClick={leftButton?.action}
              leftIcon={leftButton?.leftIcon}
            />
          </div>
          <div className="justify-self-center lg:justify-self-start">
            <Heading4 data-cy={'sub-nav-title'}>{title}</Heading4>
            <p className="col-start-2 hidden lg:block">{subtitle}</p>
          </div>
          {rightButton && isTailwindLg ? (
            <ConditionalWrapper
              hasWrapper={!!rightButton.floatingOptions}
              wrapper={(children) =>
                // React can't infer this from the hasWrapper condition
                rightButton.floatingOptions ? (
                  <FloatingOptions
                    {...rightButton.floatingOptions}
                    isOpen={isRightOptionsOpen}
                    onClose={() => setIsRightOptionsOpen(false)}
                    wrappedComponent={
                      <button onClick={() => setIsRightOptionsOpen(true)}>{children}</button>
                    }
                  />
                ) : (
                  children
                )
              }
            >
              <SecondaryButton
                theme="white"
                buttonStyle="small"
                onClick={rightButton.action}
                label={rightButton.label ?? ''}
                leftIcon={rightButton.leftIcon}
                rightIcon={rightButton.rightIcon}
              />
            </ConditionalWrapper>
          ) : (
            <button
              id="next-button"
              className="flex items-center gap-4 whitespace-nowrap"
              onClick={rightButton?.action}
            >
              {rightButton?.leftIcon}
              {rightButton?.label}
              {rightButton?.rightIcon}
            </button>
          )}
        </div>
      )}
      {!title && entries && (
        <div className="text-on-primary-01 hidden w-full justify-center gap-2 lg:flex">
          {entries}
        </div>
      )}
    </div>
  );
};
