import { faPen, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FloatingOptions, MoreVertIcon } from '@stellar-lms-frontend/ui-components';
import { useState } from 'react';

export type OptionsMenuCellI18N = {
  edit: string;
  delete: string;
};

export type OptionsMenuCellProps = {
  i18n: OptionsMenuCellI18N;
  onEdit: () => void;
  onDelete: () => void;
};

export const OptionsMenuCell: React.FC<OptionsMenuCellProps> = ({ i18n, onEdit, onDelete }) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  return (
    <FloatingOptions
      options={[
        {
          label: i18n.edit,
          onClick: () => {
            onEdit();
            setIsOptionsOpen(false);
          },
          className: '',
          left: <FontAwesomeIcon icon={faPen} />,
        },
        {
          label: i18n.delete,
          onClick: (e) => {
            e.stopPropagation();
            onDelete();
            setIsOptionsOpen(false);
          },
          className: 'text-negative-01',
          left: <FontAwesomeIcon icon={faTrashCan} />,
        },
      ]}
      placement="bottom-end"
      wrappedComponent={
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsOptionsOpen(true);
          }}
          className="-my-3 flex w-full items-center justify-end px-3 py-3"
        >
          <MoreVertIcon className="h-4 w-4" />
        </button>
      }
      isOpen={isOptionsOpen}
      onClose={() => setIsOptionsOpen(false)}
    />
  );
};
