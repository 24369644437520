import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Sidebar,
  Input,
  Dropdown,
  PrimaryButton,
  LinkButton,
  ChevronLeftIcon,
  ChevronRightIcon,
  MoreVertIcon,
  FloatingOptions,
  FloatingOptionsOption,
  TrashIcon,
  ToolTip,
} from '@stellar-lms-frontend/ui-components';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import { SupportedLanguagesOptions } from '../../../../types/types';
import { useTranslation } from 'react-i18next';

export type UserProfileEditFormData = {
  fullName: string;
  language: string;
  email: string;
  jobTitle: string;
  access: 'user' | 'course_creator' | 'admin';
};

export type EditMemberSidebarProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: UserProfileEditFormData) => void;
  onDelete: () => void;
  onNavigateLeft: () => void;
  onNavigateRight: () => void;
  user: UserProfileEditFormData;
  supportedLanguages: SupportedLanguagesOptions;
};

export const EditMemberSidebar: React.FC<EditMemberSidebarProps> = ({
  isOpen,
  onClose,
  onSubmit,
  onDelete,
  onNavigateLeft,
  onNavigateRight,
  supportedLanguages,
  user,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organization.members-tab.options-menu.edit-member-sidebar',
  });
  const schema = yup.object({
    fullName: yup.string().required(t('forms.validation.required')),
    jobTitle: yup.string(),
    access: yup.string().required('forms.validation.required'),
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm<UserProfileEditFormData>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: user,
  });

  useEffect(() => {
    reset(user);
  }, [reset, user]);

  const [isOptionMenuOpen, setIsOptionMenuOpen] = useState(false);

  const menuOptions: FloatingOptionsOption[] = [
    {
      label: t('menu.delete'),
      onClick: () => {
        onDelete();
        setIsOptionMenuOpen(false);
        onClose();
      },
      className: 'text-negative-01',
      left: <TrashIcon className="h-4 w-4" />,
    },
  ];

  const fullName = watch('fullName');

  return (
    <Sidebar
      isOpen={isOpen}
      position={'right'}
      onClose={onClose}
      contentClassName="md:max-w-[600px] md:w-[60%] font-lexend"
      hasBackdrop={false}
      buttons={{
        hasDivider: false,
        buttons: [
          <PrimaryButton
            onClick={handleSubmit(onSubmit)}
            label={t('buttons.save')}
            key={1}
            enabled={isValid}
          />,
          <LinkButton
            label={t('buttons.cancel')}
            key={2}
            onClick={onClose}
          />,
        ],
      }}
      header={
        <div className="flex items-center justify-between pr-4">
          <div className="flex shrink-0 gap-2">
            <button onClick={onNavigateLeft}>
              <ChevronLeftIcon className="text-text-02 h-8 w-8" />
            </button>
            <button onClick={onNavigateRight}>
              <ChevronRightIcon className="text-text-02 h-8 w-8" />
            </button>
          </div>
          <FloatingOptions
            wrappedComponent={
              <button
                className="h-fit shrink-0"
                onClick={() => setIsOptionMenuOpen(!isOptionMenuOpen)}
              >
                <MoreVertIcon className="text-text-02 mt-1 h-4 w-4 shrink-0" />
              </button>
            }
            isOpen={isOptionMenuOpen}
            onClose={() => setIsOptionMenuOpen(false)}
            options={menuOptions}
          />
        </div>
      }
    >
      <div className="space-y-8">
        <h2 className="text-text-04 type-headline-2 min-h-[36px] break-words">{fullName}</h2>
        <form className="space-y-6">
          <Controller
            control={control}
            name="fullName"
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                htmlId={''}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                required
                label={t('forms.full-name.label')}
                placeholder={t('forms.full-name.placeholder')}
                error={errors.fullName?.message}
              />
            )}
          />
          <Dropdown
            label={t('forms.language.label')}
            htmlId="language"
            {...register('language')}
          >
            {supportedLanguages.map(({ label, value }) => (
              <option
                key={'language_' + value}
                value={value}
              >
                {label}
              </option>
            ))}
          </Dropdown>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                htmlId={''}
                enabled={false}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                label={t('forms.email.label')}
                error={errors.email?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="jobTitle"
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                htmlId={''}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                label={t('forms.job-title.label')}
                placeholder={t('forms.job-title.placeholder')}
                error={errors.jobTitle?.message}
              />
            )}
          />
          <Dropdown
            htmlId={''}
            {...register('access')}
            label={t('forms.role.label')}
            required
            error={errors.access?.message}
            labelRightNode={
              <ToolTip
                color="dark"
                size="small"
                placement="bottom-start"
                isEnabled={true}
                childrenClassName="w-[300px]"
                wrappedComponent={
                  <FontAwesomeIcon
                    icon={faCircleQuestion}
                    className="text-text-02 h-4 w-4"
                  />
                }
              >
                <div className="whitespace-normal">
                  <p>{t('forms.role.tooltip.description')}</p>
                  <ul className="list-inside list-disc">
                    <li>{t('forms.role.tooltip.course-creator')}</li>
                    <li>{t('forms.role.tooltip.admin')}</li>
                  </ul>
                </div>
              </ToolTip>
            }
          >
            <option value="user">{t('forms.role.options.user')}</option>
            <option value="course_creator">{t('forms.role.options.course-creator')}</option>
            <option value="admin">{t('forms.role.options.admin')}</option>
          </Dropdown>
        </form>
      </div>
    </Sidebar>
  );
};
