import {
  QuestionType,
  questionTypeToTranslatableQuestionType,
} from '@stellar-lms-frontend/lms-api';
import { SurveyListView } from '@stellar-lms-frontend/lms-components';
import { LogoLoader } from '@stellar-lms-frontend/ui-components';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { COURSE, SURVEY, SURVEY_RESULT } from '../../constants/query';
import {
  DESIGNER_SURVEYS_ROUTE,
  DESIGNER_SURVEY_QUESTION_ROUTE,
  MENTOR_SURVEYS_ROUTE,
  MENTOR_SURVEY_QUESTION_ROUTE,
} from '../../constants/routes';
import { UIShell } from '../shell/ui-shell';
import { getSurveyResultsAsMentor } from './survey.api';
import { graphQLClient } from '../../lib/graphql';

export const SurveyListContainer = () => {
  const { courseId = '', surveyId = '', questionId = '' } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'course-survey-master-detail' });
  const { t: tQuestion } = useTranslation('translation', { keyPrefix: 'question.types' });
  const { t: tTable } = useTranslation('translation', { keyPrefix: 'table' });
  const prevCourseIdRef = useRef(courseId);

  const isMentor = window.location.pathname.includes('mentor');

  const surveyDetailQuery = useQuery(
    [SURVEY_RESULT, COURSE, courseId, SURVEY, surveyId],
    () =>
      getSurveyResultsAsMentor(graphQLClient(isMentor ? 'mentor' : 'designer'), surveyId, courseId),
    {
      enabled: !!surveyId,
    }
  );

  useEffect(() => {
    if (courseId !== prevCourseIdRef.current) {
      if (isMentor) {
        navigate(MENTOR_SURVEYS_ROUTE(courseId));
      } else {
        navigate(DESIGNER_SURVEYS_ROUTE(courseId));
      }
    }
  }, [courseId, navigate, isMentor]);

  useEffect(() => {
    if (
      surveyDetailQuery.isSuccess &&
      !!surveyDetailQuery.data.length &&
      (!questionId || !surveyDetailQuery.data.find((el) => el.questionId === questionId))
    ) {
      if (isMentor) {
        navigate(
          MENTOR_SURVEY_QUESTION_ROUTE(courseId, surveyId, surveyDetailQuery.data?.[0]?.questionId)
        );
      } else {
        navigate(
          DESIGNER_SURVEY_QUESTION_ROUTE(
            courseId,
            surveyId,
            surveyDetailQuery.data?.[0]?.questionId
          )
        );
      }
    }
  }, [
    courseId,
    navigate,
    questionId,
    surveyDetailQuery.data,
    surveyDetailQuery.isSuccess,
    surveyId,
    isMentor,
  ]);

  const { currentQuestion, currentAnswers } = useMemo(() => {
    const currentQuestion = surveyDetailQuery?.data?.find((el) => el.questionId === questionId);

    return {
      currentQuestion,
      currentAnswers: currentQuestion?.answers || [],
    };
  }, [questionId, surveyDetailQuery?.data]);

  const onSelectQuestion = useCallback(
    (questionId: string) => {
      if (isMentor) {
        navigate(MENTOR_SURVEY_QUESTION_ROUTE(courseId, surveyId, questionId));
      } else {
        navigate(DESIGNER_SURVEY_QUESTION_ROUTE(courseId, surveyId, questionId));
      }
    },
    [surveyId, navigate, courseId, isMentor]
  );

  return (
    <UIShell useContentContainer={false}>
      {courseId ? (
        <SurveyListView
          graphQLClient={graphQLClient()}
          i18n={{
            table: {
              pageSizeSelector: {
                showing: tTable('page-size-selector.showing'),
                of: (count) => tTable('page-size-selector.of', { count }),
              },
            },
            columns: {
              answer: t('table.answer'),
              learner: t('table.learner'),
              date: t('table.date'),
            },
            rows: {
              answer: (count) => t('answer', { count }),
            },
            empty: {
              title: t('empty-survey-title'),
              description: t('empty-survey-description'),
            },
            question: {
              type: (type: QuestionType) => tQuestion(questionTypeToTranslatableQuestionType(type)),
            },
          }}
          surveyDetails={surveyDetailQuery.data}
          currentQuestion={currentQuestion}
          currentAnswers={currentAnswers}
          questionId={questionId}
          onSelectQuestion={onSelectQuestion}
        />
      ) : (
        <LogoLoader />
      )}
    </UIShell>
  );
};
