import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import { ChevronRightIcon } from '../icons';

interface Breadcrumb {
  label: string;
  onClick: VoidFunc;
}

export interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
  className?: string;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs, className }) => {
  return (
    <nav className={`font-lexend ${className}`}>
      <ol className="flex items-center gap-2">
        {breadcrumbs.map((breadcrumb, index) => {
          const isLast = index === breadcrumbs.length - 1;
          return (
            <li
              key={index}
              className="flex items-center gap-2"
            >
              <button
                className={`shrink-0 text-xl font-medium  ${
                  !isLast ? 'text-primary-01' : 'text-text-02'
                }`}
                onClick={breadcrumb.onClick}
                disabled={isLast}
              >
                {breadcrumb.label}
              </button>
              {!isLast && <ChevronRightIcon className="text-text-03 h-6 w-6 shrink-0" />}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
