import {
  ChevronDownIcon,
  LinkButton,
  Typewriter,
  UploadFile,
  WizardContext,
} from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createCourse,
  createTransferObjectives,
  getSuggestedTransferObjectives,
} from '../create-journey.api';
import { useContext, useEffect, useMemo, useState } from 'react';
import { DynamicEditableCheckboxCardList } from './dynamic-editable-checkbox-card-list';
import { replaceTagsInText } from '@stellar-lms-frontend/common-utils';
import {
  CreateCourseMutationMutationVariables,
  Language,
  SuggestionGenerationStatus,
  SuggestionsStatus,
  getSuggestionGenerationStatus,
  mapToInputLanguage,
} from '@stellar-lms-frontend/lms-graphql';
import { TRANSFER_OBJECTIVES } from '../../constants/query-constants';
import { TransferObjectivesModal } from '@stellar-lms-frontend/lms-components';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { graphQLClient } from '../../../../lib/graphql';
import { DESIGNER_COURSE_ROUTE } from '../../constants/routes';
import { Message } from 'ably';
import { useChannel } from 'ably/react';

export type TransferObjective = {
  id: string;
  title: string;
  description: string;
  isSelected: boolean;
};

export type StepChooseTransferObjectivesProps = {
  onSubmit: (allTransferObjectives: TransferObjective[]) => void;
  data: {
    skill: string;
    targetAudience: string;
    industry: string;
    language: Language;
    projectId: string;
    documents: UploadFile[];
    allTransferObjectives: TransferObjective[];
  };
  companyId: string;
  projectId: string;
  realtimeChannel: string;
};

export const StepChooseTransferObjectives: React.FC<StepChooseTransferObjectivesProps> = ({
  onSubmit,
  data,
  companyId,
  projectId,
  realtimeChannel,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'create-journey.steps.choose-transfer-objectives',
  });
  const { t: tDocuments } = useTranslation('translation', {
    keyPrefix: 'create-journey.steps.add-documents',
  });
  const { t: tAnimation } = useTranslation('translation', {
    keyPrefix: 'create-journey.steps.animated-prompt',
  });
  const { t: tCreateJourney } = useTranslation('translation', { keyPrefix: 'create-journey' });

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [allTransferObjectives, setAllTransferObjectives] = useState(data.allTransferObjectives);
  const [isFormsVisible, setIsFormsVisible] = useState(false);
  const [isTransferObjectivesModalOpen, setIsTransferObjectivesModalOpen] = useState(false);
  const [isCreatingCourse, setIsCreatingCourse] = useState(false);

  useEffect(() => {
    setAllTransferObjectives(data.allTransferObjectives);
  }, [data.allTransferObjectives]);

  useChannel(realtimeChannel, (message: Message) => {
    if (
      message.name === 'create' &&
      message.data.type === 'SuggestedTransferObjective' &&
      message.data.id === 'all'
    ) {
      queryClient.invalidateQueries([TRANSFER_OBJECTIVES, { projectId }]);
    }
  });

  const {
    data: suggestedQuestionsPages,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    [TRANSFER_OBJECTIVES, { projectId }],
    ({ pageParam = null }) =>
      getSuggestedTransferObjectives({
        companyId,
        projectId,
        nrPerPage: 5,
        cursor: pageParam,
      }),
    {
      enabled: !!projectId,
      cacheTime: Infinity,
      getNextPageParam: (lastPage) =>
        lastPage.suggestedTransferObjectives.pageInfo.hasNextPage
          ? lastPage.suggestedTransferObjectives.pageInfo.cursor
          : undefined,
    },
  );

  const status = useMemo(
    () =>
      getSuggestionGenerationStatus(
        suggestedQuestionsPages?.pages.map((p) => ({
          status: p?.suggestedTransferObjectives.status ?? SuggestionGenerationStatus.None,
        })) ?? [],
      ),
    [suggestedQuestionsPages?.pages],
  );

  const suggestedTransferObjectives = useMemo(() => {
    const newSuggestions =
      suggestedQuestionsPages?.pages
        .flatMap((p) => p?.suggestedTransferObjectives.transferObjectives)
        .flatMap((a) => (a ? [a] : [])) ?? [];

    const ret = [...allTransferObjectives];

    newSuggestions.forEach((s, index) => {
      if (allTransferObjectives.every((a) => a.id !== s.id)) {
        ret.push({
          isSelected: index < 5 && allTransferObjectives.length === 0 ? true : false,
          ...s,
        });
      }
    });

    return ret;
  }, [allTransferObjectives, suggestedQuestionsPages?.pages]);

  const { setNextFunc, setIsCurrentStepValid, setPrevFunc } = useContext(WizardContext);

  const { mutateAsync: createTransferObjectivesMutation } = useMutation(createTransferObjectives);
  const { mutateAsync: mutateCreateCourse } = useMutation({
    mutationFn: ({
      companyId,
      course,
    }: CreateCourseMutationMutationVariables & { transferObjectives: TransferObjective[] }) => {
      return createCourse(graphQLClient('designer'), { companyId, course });
    },
    onMutate: () => {
      setIsCreatingCourse(true);
    },
    onSuccess: async (data, variables) => {
      if (data.createCourse?.id && companyId) {
        try {
          await createTransferObjectivesMutation({
            companyId: companyId,
            input: variables.transferObjectives.map((tO) => ({
              name: tO.title,
              description: tO.description,
            })),
            courseId: data.createCourse?.id,
          });
          navigate(DESIGNER_COURSE_ROUTE(data.createCourse.id));
        } catch {
          // TODO this is not enough. we should show an error and delete the created course
          setIsCreatingCourse(false);
        }
      }
    },
    onError: (error, variables) => {
      setIsCreatingCourse(false);
      toast.error(tCreateJourney('create-failure'));
      Sentry.captureException(new Error('Create course failed'), {
        extra: { variables: JSON.stringify(variables), innerError: JSON.stringify(error) },
      });
    },
  });
  useEffect(() => {
    setIsCurrentStepValid(!isCreatingCourse);

    setNextFunc(async () => {
      onSubmit(suggestedTransferObjectives);
      companyId &&
        (await mutateCreateCourse({
          companyId: companyId,
          course: {
            description: tDocuments('course-description', {
              skill: data.skill,
              targetAudience: data.targetAudience,
              industry: data.industry,
            }),
            skills: [data.skill ?? ''],
            title: data.skill ?? '',
            aiProjectId: data.projectId,
            language: mapToInputLanguage(data.language),
            transferObjectives:
              suggestedTransferObjectives
                .filter((tO) => tO.isSelected)
                .map((tO) => {
                  return { name: tO.title, description: tO.description };
                }) ?? [],
            documents: data.documents
              .filter((file) => file.status === 'complete')
              .map((file) => {
                return {
                  name: file.file?.name ?? '',
                  fileId: file.fileId ?? '',
                };
              }),
          },
          transferObjectives: data.allTransferObjectives.filter((tO) => tO.isSelected),
        }));
      return false;
    });
  }, [
    data,
    isCreatingCourse,
    mutateCreateCourse,
    setIsCurrentStepValid,
    setNextFunc,
    t,
    companyId,
    tDocuments,
    onSubmit,
    suggestedTransferObjectives,
  ]);

  useEffect(() => {
    setPrevFunc(async () => {
      onSubmit(suggestedTransferObjectives);
      return true;
    });
  }, [onSubmit, setPrevFunc, suggestedTransferObjectives]);

  useEffect(() => {
    suggestedTransferObjectives.filter((s) => s.isSelected).length > 0
      ? setIsCurrentStepValid(true)
      : setIsCurrentStepValid(false);
  }, [setIsCurrentStepValid, suggestedTransferObjectives]);

  const isAnimationEnabled = useMemo(
    () => data.allTransferObjectives.length === 0,
    [data.allTransferObjectives.length],
  );

  return (
    <>
      <TransferObjectivesModal
        isOpen={isTransferObjectivesModalOpen}
        onClose={() => setIsTransferObjectivesModalOpen(false)}
      />
      <div className="max-w-createJourneyStep mx-auto flex w-full flex-col gap-6 px-12 py-14">
        <p className="text-text-01 text-center text-3xl">
          {tAnimation('part-1')}{' '}
          {replaceTagsInText(tAnimation('part-2', { skill: data.skill }), {
            blue: <span className="text-primary-02" />,
          })}{' '}
          <Typewriter
            onFinishTyping={() => setIsFormsVisible(true)}
            isEnabled={isAnimationEnabled}
          >
            {replaceTagsInText(
              tAnimation('part-3', {
                targetAudience: data.targetAudience,
                industry: data.industry,
              }),
              { blue: <span className="text-primary-02" /> },
            )}
            {tAnimation('ellipsis')}
          </Typewriter>
        </p>
        {isFormsVisible && (
          <div className={`${isAnimationEnabled ? 'animate-fadeIn' : ''} flex grow flex-col`}>
            <p className="text-text-02 mb-6 text-center">
              {replaceTagsInText(t('sub-text'), {
                button: (
                  <span
                    role="button"
                    className="underline"
                    onClick={() => setIsTransferObjectivesModalOpen(true)}
                  />
                ),
              })}
            </p>
            <div className="grid grid-cols-3 gap-4">
              <DynamicEditableCheckboxCardList
                items={suggestedTransferObjectives}
                isLoading={
                  (status === SuggestionsStatus.IN_PROGRESS && !hasNextPage) ||
                  suggestedTransferObjectives?.length === 0
                }
                onChange={(cards) =>
                  setAllTransferObjectives(
                    cards.map((c) => ({
                      title: c.title ?? '',
                      description: c.description ?? '',
                      ...c,
                    })),
                  )
                }
              />
            </div>
            {suggestedTransferObjectives?.length !== 0 && hasNextPage && (
              <button
                className={'mt-4 flex items-center gap-2 text-left'}
                onClick={() => fetchNextPage()}
              >
                <ChevronDownIcon className="h-6 w-6" />
                <LinkButton
                  buttonStyle="fit"
                  label={t('load-more')}
                />
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};
