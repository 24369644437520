import { ReactNode, forwardRef } from 'react';
import { Label } from '../../label/label';

export type CheckBoxVariant = 'white-check' | 'blue-check';

export type CheckBoxProps = {
  value?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  readOnly?: boolean;
  className?: string;
  variant?: CheckBoxVariant;
  isRequired?: boolean;
  label?: ReactNode | string;
};

export const CheckBox = forwardRef<HTMLInputElement, CheckBoxProps>(
  (
    {
      value,
      onChange,
      name,
      readOnly = false,
      className = '',
      variant = 'blue-check',
      isRequired,
      label,
    },
    ref
  ) => {
    const variantClasses = {
      'blue-check': `bg-blue text-white ${value ? 'selected' : ''}`,
      'white-check': `bg-white text-blue hover:border-blue focus:border-blue ${
        value ? 'text-blue' : ''
      }`,
    };

    return (
      <div className="flex flex-row gap-4">
        <input
          ref={ref}
          name={name}
          type="checkbox"
          checked={value}
          onChange={(e) => !readOnly && onChange(e)}
          className={`border-border-01 h-5 w-5 cursor-pointer rounded-md focus:shadow-none focus:outline-none 
      focus:ring-0 focus:ring-offset-0 ${variantClasses[variant]} ${className}`}
          readOnly={readOnly}
        />
        {label && (
          <Label
            required={!!isRequired}
            label={label}
          />
        )}
      </div>
    );
  }
);
