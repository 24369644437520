import { useTranslation } from 'react-i18next';
import { Badge } from '@stellar-lms-frontend/ui-components';
import { SubscriptionType } from './types';

type SubscriptionCell = {
  type: SubscriptionType;
  status: boolean;
};

export const SubscriptionCell: React.FC<SubscriptionCell> = ({ type, status }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'administration.company-tab.subscription-cell',
  });

  const getLabel = () => {
    switch (type) {
      case 'TRIAL':
        return t('trial');
      case 'INTERNAL':
        return t('internal');
      case 'STELLAR_LABS_MANAGED':
        return t('stellar-labs-managed');
      case 'STRIPE_MANAGED':
        return t('stripe-managed');
      case 'UNKNOWN':
        return t('unknown');
    }
  };

  return (
    <Badge color={status ? 'green' : 'red'}>
      <div className="flex flex-row items-center">
        <span>{getLabel()}</span>
      </div>
    </Badge>
  );
};
