import { Route, Routes } from 'react-router-dom';
import {
  LearningActivityOverview,
  I18N as LearningActivityOverviewI18N,
  LearningActivityOverviewProps,
} from './learning-activity-overview';

export const LEARNING_ACTIVITY_URI_ID_PARAM_NAME = 'learningActivityId';
export const STEP_URI_CREATE_CONST = 'create';

export const LEARNING_ACTIVITY_STEP_URI_ID_PARAM_NAME = 'stepId';
export const LEARNING_ACTIVITY_STEP_URI = `step/:${LEARNING_ACTIVITY_STEP_URI_ID_PARAM_NAME}`;
export const LEARNING_ACTIVITY_STEP_CREATE_URI = `./step/${STEP_URI_CREATE_CONST}`;
export const LEARNING_ACTIVITY_STEP_ROUTE = (stepId: string) => `./step/${stepId}`;

export type I18N = LearningActivityOverviewI18N;

export const LearningActivityRoutes: React.FC<LearningActivityOverviewProps> = (props) => {
  return (
    <Routes>
      <Route
        path=""
        element={<LearningActivityOverview {...props} />}
      >
        <Route
          path={LEARNING_ACTIVITY_STEP_URI}
          element={<LearningActivityOverview {...props} />}
        />
      </Route>
    </Routes>
  );
};
