import React, { useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Dropdown,
  Form,
  Input,
  Modal,
  PrimaryButton,
  ToolTip,
} from '@stellar-lms-frontend/ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import { SupportedLanguagesOptions } from '../../../../types/types';
import { useTranslation } from 'react-i18next';

export type InviteMemberFormData = {
  name: string;
  language: string;
  email: string;
  jobTitle: string;
  role: 'user' | 'course_creator' | 'admin';
};

export type InviteMemberModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: InviteMemberFormData) => Promise<void>;
  supportedLanguages: SupportedLanguagesOptions;
  defaultLanguage: string;
};

export const InviteMemberModal: React.FC<InviteMemberModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  supportedLanguages,
  defaultLanguage,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organization.members-tab.actions.add.invite-modal',
  });
  const { t: tValidation } = useTranslation('translation', { keyPrefix: 'validation' });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(tValidation('required')),
    email: Yup.string().email(tValidation('required')).required(tValidation('required')),
    jobTitle: Yup.string(),
    language: Yup.string().oneOf(supportedLanguages.map(({ value }) => value)),
    role: Yup.string().oneOf(['user', 'admin', 'course_creator']),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    setError,
    reset,
  } = useForm<InviteMemberFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      language: defaultLanguage,
    },
  });

  const onSubmitHandler: SubmitHandler<InviteMemberFormData> = async (data) => {
    try {
      await onSubmit(data);
      onClose();
      reset();
    } catch (error) {
      setError('email', { message: t('forms.email.errors.email-exist') });
    }
  };

  useEffect(() => {
    reset();
  }, [isOpen, reset]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('title')}
      className={'bg-surface-01 min-w-[720px]'}
      variant="default"
      buttons={{
        hasShadow: true,
        buttons: [
          <PrimaryButton
            key={1}
            onClick={handleSubmit(onSubmitHandler)}
            label={t('forms.buttons.submit')}
            enabled={isValid}
            htmlType="submit"
          />,
        ],
      }}
    >
      <div className=" space-y-6">
        <p className="text-text-02 text-sm">{t('subtitle')}</p>
        <Form
          className="space-y-6"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                htmlId={'name'}
                label={t('forms.name.label')}
                required
                error={errors.name?.message}
                placeholder={t('forms.name.placeholder')}
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                htmlId={'email'}
                label={t('forms.email.label')}
                required
                error={errors.email?.message}
                placeholder={t('forms.email.placeholder')}
              />
            )}
          />
          <Dropdown
            label={t('forms.language.label')}
            htmlId="language"
            {...register('language')}
          >
            {supportedLanguages.map(({ label, value }) => (
              <option
                key={'language_' + value}
                value={value}
              >
                {label}
              </option>
            ))}
          </Dropdown>
          <Controller
            control={control}
            name="jobTitle"
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                htmlId={'jobTitle'}
                label={t('forms.job-title.label')}
                error={errors.jobTitle?.message}
                placeholder={t('forms.job-title.placeholder')}
              />
            )}
          />
          <Dropdown
            htmlId={'role'}
            required
            label={t('forms.role.label')}
            labelRightNode={
              <ToolTip
                color="dark"
                size="small"
                placement="bottom-start"
                isEnabled={true}
                childrenClassName="w-[300px]"
                wrappedComponent={
                  <FontAwesomeIcon
                    icon={faCircleQuestion}
                    className="text-text-02 h-4 w-4"
                  />
                }
              >
                <div className="whitespace-normal">
                  <p>{t('forms.role.tooltip.description')}</p>
                  <ul className="list-inside list-disc">
                    <li>{t('forms.role.tooltip.course-creator')}</li>
                    <li>{t('forms.role.tooltip.admin')}</li>
                  </ul>
                </div>
              </ToolTip>
            }
            {...register('role')}
          >
            <option value="user">{t('forms.role.options.user')}</option>
            <option value="admin">{t('forms.role.options.admin')}</option>
            <option value="course_creator">{t('forms.role.options.course-creator')}</option>
          </Dropdown>
        </Form>
      </div>
    </Modal>
  );
};
