import { forwardRef } from 'react';
import BaseButton, { BaseButtonProps } from '../base-button/base-button';

export interface TertiaryButtonProps extends BaseButtonProps {
  active?: boolean;
}

export const TertiaryButton = forwardRef<HTMLButtonElement, TertiaryButtonProps>(
  ({ display = 'normal', active = false, className, ...baseProps }, ref) => {
    return (
      <BaseButton
        {...baseProps}
        display={display}
        className={`text-text-02 bg-surface-01 border-1 border-border-02 outline-on-primary-02
         rounded-xl shadow-sm outline-offset-2 focus:outline-4 disabled:shadow-none
         ${className}
        ${
          active
            ? 'bg-primary-03 text-primary-02 border-primary-02'
            : `hover:border-border-01 focus:border-border-01 disabled:border-border-01
               hover:text-text-01  focus:text-text-01  disabled:text-text-03`
        }`}
        ref={ref}
      />
    );
  }
);

TertiaryButton.displayName = 'TertiaryButton';

export default TertiaryButton;
