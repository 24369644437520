import { faBarsFilter, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Sidebar } from '../sidebar/sidebar';
import { useState } from 'react';
import { Dropdown, InputAutoComplete, Suggestion } from '../form';
import { Heading3 } from '../heading';
import { FilterOptionConfig, FilterValue } from './types';
import { LinkButton, PrimaryButton } from '../buttons';

export const FilterOptionSelector = <TDataItem,>({
  filterOptions,
  onFilter,
  resetFilters,
}: {
  filterOptions: FilterOptionConfig<TDataItem>[];
  onFilter: (newFilterValues: FilterValue<TDataItem>[]) => void;
  resetFilters: () => void;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.filter-option-selector' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const [isOpen, setIsOpen] = useState(false);
  const [filtersToApply, setFiltersToApply] = useState<
    Map<keyof TDataItem, FilterValue<TDataItem>>
  >(new Map());

  const numberOfSelectedFilters = filterOptions.filter((option) => option.hasSelectedValue).length;

  if (filterOptions.length === 0) {
    return null;
  }

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        onKeyDown={() => setIsOpen(true)}
        className="relative flex cursor-pointer gap-2 pr-[22px]"
      >
        <FontAwesomeIcon
          icon={faBarsFilter}
          className="text-xl text-gray-500"
        />
        <span className="text-text-01 type-body-medium underline">{t('filter')}</span>
        {numberOfSelectedFilters > 0 && (
          <span className="bg-warning-01 type-tiny absolute right-0 top-[-10px] flex h-[22px] w-[22px] items-center justify-center overflow-hidden rounded-full text-white">
            {numberOfSelectedFilters}
          </span>
        )}
      </button>

      <Sidebar
        isOpen={isOpen}
        header={<Heading3 className="text-text-04">{t('title')}</Heading3>}
        position={'right'}
        contentClassName="md:max-w-[600px] md:w-[60%] font-lexend"
        onClose={() => setIsOpen(false)}
        buttons={{
          buttons: [
            <div
              key="sidebar-buttons"
              className="flex w-full justify-between"
            >
              <div className="flex">
                <PrimaryButton
                  label={tGeneral('apply')}
                  enabled={filtersToApply.size > 0}
                  onClick={() => {
                    onFilter([...filtersToApply.values()]);
                    setIsOpen(false);
                  }}
                />
                <LinkButton
                  label={tGeneral('cancel')}
                  onClick={() => {
                    setFiltersToApply(new Map());
                    setIsOpen(false);
                  }}
                />
              </div>
              <LinkButton
                leftIcon={
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="text-xl text-red-500"
                  />
                }
                type="negative"
                label={t('clear-filters')}
                onClick={() => {
                  resetFilters();
                  setIsOpen(false);
                }}
              />
            </div>,
          ],
        }}
      >
        <div className="space-y-8">
          {filterOptions.map((filterOption) => {
            switch (filterOption.type) {
              case 'dropdown':
                return (
                  <Dropdown
                    key={filterOption.fieldName as string}
                    htmlId={`${String(filterOption.fieldName)}-filter`}
                    label={filterOption.label}
                    onChange={(e) =>
                      setFiltersToApply((previous) => {
                        const newMap = new Map(previous);
                        newMap.set(filterOption.fieldName, {
                          fieldName: filterOption.fieldName,
                          value: e.target.value,
                        });
                        return newMap;
                      })
                    }
                    value={
                      filtersToApply.get(filterOption.fieldName)?.value ??
                      filterOption.selectedValue
                    }
                  >
                    <option value="">{filterOption.hint}</option>
                    {filterOption.options.map((option) => (
                      <option
                        key={option.value}
                        id={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </Dropdown>
                );

              // under construction ;-)
              case 'input-autocomplete':
                return (
                  <InputAutoComplete
                    key={filterOption.fieldName as string}
                    suggestions={filterOption.suggestions}
                    onSuggestionSelected={(suggestion) =>
                      setFiltersToApply((previous) => {
                        const newMap = new Map(previous);
                        newMap.set(filterOption.fieldName, {
                          fieldName: filterOption.fieldName,
                          value: suggestion.label,
                        });
                        return newMap;
                      })
                    }
                    renderOption={(suggestion: Suggestion) => {
                      return {
                        key: '',
                        label: 'suggestion.label',
                        type: 'option',
                      };
                    }}
                  />
                );
              default:
                return null;
            }
          })}
        </div>
      </Sidebar>
    </>
  );
};
