import { useQuery } from '@tanstack/react-query';
import { MENTEE_ACTIONS } from '../../../constants/query';
import { useMemo } from 'react';
import { MENTOR_ACTIONS_BASE_ROUTE } from '../../../constants/routes';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WBAItem } from './wba-item';
import { LearnerWorkBasedAction, LearnerWorkBasedActionState } from '@stellar-lms-frontend/lms-api';
import { Section } from '@stellar-lms-frontend/lms-components';
import { getMenteeActions } from '../../actions/actions.api';
import { graphQLClient } from '../../../lib/graphql';
import { sortDates } from '@stellar-lms-frontend/common-utils';

const isActionableState = (state: LearnerWorkBasedActionState, dueDate: Date | undefined) => {
  return (
    state === 'executed' ||
    (dueDate && dueDate.getTime() < Date.now() && state !== 'reviewed' && state !== 'completed')
  );
};

export const WBASection: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'mentees-overview' });
  const { courseId = '' } = useParams();

  const { data: actions } = useQuery([MENTEE_ACTIONS, { courseId }], () =>
    getMenteeActions(graphQLClient(), courseId)
  );

  const flattenActions = useMemo(
    () =>
      actions
        ?.reduce((acc, curr) => [...acc, ...curr.actions], [] as LearnerWorkBasedAction[])
        ?.filter((a) => isActionableState(a.state, a.learnerDueDate))
        .sort((a, b) => sortDates(a.learnerDueDate, b.learnerDueDate))
        .sort((a) => (a.state === 'executed' ? -1 : 0)) || [],
    [actions]
  );

  return (
    <Section
      data-cy={'wba-section'}
      title={t('wba-section-title')}
    >
      <div>
        {flattenActions?.length > 0 ? (
          flattenActions?.slice(0, 3).map((wba, index) => (
            <WBAItem
              key={wba.id}
              wba={wba}
              data-cy={`wba-section-${index}`}
            />
          ))
        ) : (
          <div
            className="mb-1 mt-2"
            data-cy="wba-section-empty"
          >
            <p className="text-text-01">{t('wba-section-empty')}</p>
          </div>
        )}
        <div className="mt-4">
          <Link
            data-cy="wba-section-view-all"
            to={MENTOR_ACTIONS_BASE_ROUTE(courseId)}
            className="text-primary-01 underline"
          >
            {t('all-actions')}
          </Link>
        </div>
      </div>
    </Section>
  );
};
