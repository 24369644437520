import { useTranslation } from 'react-i18next';
import { AIStepEdit, AIStepEditI18N, AIStepEditProps } from '../ai-step-edit/ai-step-edit';
import { ScenarioSuggestionsCard } from './components/scenario-suggestions-card';

export type ScenarioSuggestion = {
  id: string;
  image?: string;
  title: string;
  description: string;
  options: {
    bestOption: boolean;
    consequence: string;
    description: string;
    feedback: string;
    title: string;
  }[];
};

export type ScenarioAIStepEditI18N = AIStepEditI18N;

export type ScenarioAIStepEditProps = Omit<
  AIStepEditProps<ScenarioSuggestion, ScenarioSuggestion>,
  'renderSuggestion' | 'aiButtonLabel' | 'i18n'
>;

export const ScenarioAIStepEdit: React.FC<ScenarioAIStepEditProps> = (props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'scenario-step-view' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  return (
    <AIStepEdit
      {...props}
      i18n={{
        loading: t('loading-message'),
        suggestionsHint: {
          title: t('suggestions-hint.title'),
          link: t('suggestions-hint.link'),
        },
        sidebar: {
          title: t('sidebar.suggestions-title'),
          hint: t('sidebar.suggestions-hint'),
          load: {
            more: t('sidebar.load-more-suggestions'),
            message: t('sidebar.suggestions-loading-message'),
          },
          buttons: {
            add: tGeneral('add'),
            cancel: tGeneral('cancel'),
          },
          confirmOverwriteModal: {
            title: t('sidebar.overwrite-modal.title'),
            description: t('sidebar.overwrite-modal.description'),
            confirm: tGeneral('confirm'),
            cancel: tGeneral('cancel'),
          },
          retryMessage: {
            title: t('sidebar.retry-message.title'),
            description: t('sidebar.retry-message.description'),
            retry: tGeneral('retry'),
          },
        },
      }}
      aiButtonLabel={t('floating.add-level')}
      renderSuggestion={({
        suggestion: s,
        isSelected,
        setAddedSuggestions,
        setAcceptedSuggestions,
        onRemoveSuggestion,
      }) => (
        <ScenarioSuggestionsCard
          key={s.id}
          title={s.title}
          isHighlighted={isSelected}
          description={s.description}
          image={s.image}
          // The user can select only one Scenario suggestion, so it's an array with a single element
          onClick={() => {
            if (isSelected) {
              onRemoveSuggestion(s.id);
            } else {
              setAcceptedSuggestions(() => new Set([s.id]));
              setAddedSuggestions(() => [s]);
            }
          }}
        />
      )}
    />
  );
};
