import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';
import { graphql } from '@stellar-lms-frontend/lms-graphql';
import { useQuery } from '@tanstack/react-query';
import { SingleNumberChart } from '@stellar-lms-frontend/ui-components';

const getActiveSRQs = async (companyId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query activeSRQs($companyId: ID!) {
        companyActiveSRQs(companyId: $companyId)
      }
    `),
    { companyId: companyId }
  );
};

export const ActiveSRQsChart = ({ companyId }: { companyId: string }) => {
  const { data, isLoading } = useQuery(['active-srqs', companyId], () => getActiveSRQs(companyId), {
    refetchOnWindowFocus: false,
  });

  return (
    <SingleNumberChart
      value={data?.companyActiveSRQs}
      isLoading={isLoading}
    />
  );
};
