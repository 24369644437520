import { LogoLoader } from '@stellar-lms-frontend/ui-components';
import { useQuery } from '@tanstack/react-query';
import { Navigate, useParams } from 'react-router-dom';
import {COURSES} from '../../constants/query';
import { UIShell } from '../shell/ui-shell';
import {getLearnerWorkBasedAction} from "../workbasedactions/work-based-actions.api";
import {graphQLClient} from "../../lib/graphql";

export type LeggacyActionsOverviewPageProps = {
  baseRoute: (actionId: string) => string;
  baseCourseUri: string;
};
export const LegacyActionsOverviewPage: React.FC<LeggacyActionsOverviewPageProps> = ({
  baseRoute,
  baseCourseUri,
}) => {
  const { actionId = '' } = useParams();
  const { data: wba } = useQuery(
      [COURSES, actionId],
      () => (actionId ? getLearnerWorkBasedAction(graphQLClient(), actionId) : undefined),
      { enabled: !!actionId }
  );

  if (!wba) {
    return (
      <UIShell>
        <LogoLoader />
      </UIShell>
    );
  } else {
    return (
      <Navigate
        replace
        to={baseRoute(wba.courseId) + `/${actionId}`}
      />
    );
  }
};
