import {
  ChoiceSuggestedQuestion,
  FillInTheBlanksSuggestedQuestion,
  QuizChoiceQuestion,
  QuizFillInTheBlanksQuestion,
  QuizQuestionInput,
} from '@stellar-lms-frontend/lms-graphql';
import * as yup from 'yup';

export type AssessmentQuestionTypes = (
  | Omit<QuizChoiceQuestion, 'id'>
  | Omit<QuizFillInTheBlanksQuestion, 'id'>
) & { id?: string };

export enum AssessmentQuestionTypeNamesEnum {
  QUIZ_CHOICE_QUESTION = 'QuizChoiceQuestion',
  QUIZ_FILL_IN_THE_BLANKS_QUESTION = 'QuizFillInTheBlanksQuestion',
}

export type AssessmentSuggestedQuestionTypes =
  | ChoiceSuggestedQuestion
  | FillInTheBlanksSuggestedQuestion;

export enum AssessmentSuggestedQuestionTypeNamesEnum {
  CHOICE_SUGGESTED_QUESTIONS = 'ChoiceSuggestedQuestion',
  FILL_IN_THE_BLANKS_SUGGESTED_QUESTION = 'FillInTheBlanksSuggestedQuestion',
}

// TODO: ideally this validation should be placed inside each card component, but it's not possible because the data schema of the component it's different from the graphql schema
export const assessmentFieldsSchemas = (i18n: { required?: string }) => ({
  [AssessmentQuestionTypeNamesEnum.QUIZ_FILL_IN_THE_BLANKS_QUESTION]: yup.object().shape({
    text: yup.string().required(i18n?.required),
    feedback: yup.string().required(i18n?.required),
  }),
  [AssessmentQuestionTypeNamesEnum.QUIZ_CHOICE_QUESTION]: yup.object().shape({
    text: yup.string().required(i18n?.required),
    feedback: yup.string().required(i18n?.required),
  }),
});

export type AssessmentMultipleChoiceErrorType =
  | {
      text?: { message?: string };
      feedback?: { message?: string };
    }
  | undefined;

export type AssessmentFillBlanksErrorType =
  | {
      text?: { message?: string };
      feedback?: { message?: string };
    }
  | undefined;

export const transformAssessmentQuestionsToSubmit = (
  questions: AssessmentQuestionTypes[]
): QuizQuestionInput[] =>
  questions
    .map((q) => {
      switch (q.__typename) {
        case AssessmentQuestionTypeNamesEnum.QUIZ_CHOICE_QUESTION:
          return {
            type: 'CHOICE',
            feedback: q.feedback,
            id: q.id,
            spacedRepetitionEnabled: q.spacedRepetitionEnabled,
            text: q.text,
            choice: {
              answers: q.answers,
              multiple: q.multiple,
            },
          };
        case AssessmentQuestionTypeNamesEnum.QUIZ_FILL_IN_THE_BLANKS_QUESTION:
          return {
            type: 'FILL_IN_THE_BLANKS',
            feedback: q.feedback,
            id: q.id,
            spacedRepetitionEnabled: q.spacedRepetitionEnabled,
            text: q.text,
          };
        default:
          return undefined;
      }
    })
    .filter((q) => !!q) as NonNullable<QuizQuestionInput[]>;
