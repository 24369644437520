import { forwardRef, InputHTMLAttributes } from 'react';
import { FileInputComplete, FileInputError, FileInputInit, FileInputLoading } from './components';
import { useFileUpload } from './use-file-upload';
import { EmptyStateCard } from '../../card/empty-state-card/empty-state-card';

type InitState = { status: 'init'; file?: never; fileId?: never; progress?: never };
type LoadingState = { status: 'loading'; file?: never; fileId?: never; progress: number };
type ErrorState = { status: 'error'; file?: never; fileId?: never; progress?: never };
type CompletedState = { status: 'complete'; file: File; fileId?: string; progress?: never };

export type UploadFile = InitState | LoadingState | ErrorState | CompletedState;

export type FileInputI18N = {
  invalidFormat: string;
};

export type FileInputProps = InputHTMLAttributes<HTMLInputElement> & {
  i18n: FileInputI18N;
  className?: string;
  file: UploadFile;
  maxFileSize?: {
    size: number;
    errorMessage: string;
  };
};

export const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  (
    { file = { status: 'init' }, maxFileSize, onChange, accept, className, i18n, ...props },
    ref
  ) => {
    const { fileInputProps, handleDrop, handleClick, fileError, handleDeleteFile } = useFileUpload({
      maxFileSize: maxFileSize?.size,
      onChange,
      accept,
      forwardedRef: ref,
    });

    let fileErrorText = null;
    if (fileError) {
      switch (fileError) {
        case 'maxFileSizeExceeded':
          fileErrorText = maxFileSize?.errorMessage ?? '';
          break;
        case 'invalidFormat':
          fileErrorText = i18n.invalidFormat;
          break;
      }
    }

    return (
      <div
        className={`${className ?? ''} font-lexend`}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        <EmptyStateCard>
          {file.status === 'init' && <FileInputInit fileError={fileErrorText} />}
          {file.status === 'loading' && <FileInputLoading progress={file.progress} />}
          {file.status === 'error' && <FileInputError onRetry={handleClick} />}
          {file.status === 'complete' && (
            <FileInputComplete
              file={file.file}
              onReplaceFile={handleClick}
              onDeleteFile={handleDeleteFile}
            />
          )}

          <input
            {...props}
            {...fileInputProps}
          />
        </EmptyStateCard>
      </div>
    );
  }
);
