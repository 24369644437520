import { useMediaQuery, navigation } from '@stellar-lms-frontend/common-utils';
import {
  WorkBasedActionLearningActivityStep,
  LearnerWorkBasedAction,
} from '@stellar-lms-frontend/lms-api';
import {
  ScrollContainer,
  Heading3,
  Heading4,
  ReadOnlyInput,
  ReadOnlyTextArea,
  SecondaryButton,
  LogoLoader,
  ContentWrapper,
  HeaderImage,
} from '@stellar-lms-frontend/ui-components';
import { useQueryClient, useQuery } from '@tanstack/react-query';

import moment from 'moment';
import { useState, useCallback, useEffect } from 'react';
import { WorkBasedActionEdit, WorkBasedActionEditProps } from './work-based-action-edit';
import { LEARNER_WBA_BY_SOURCE_ID, LEARNER_WBA } from './query';
import { useTranslation } from 'react-i18next';

type Actions = {
  fetchLearnerWorkBasedActionById: (
    sourceId: string,
    stepId: string
  ) => Promise<LearnerWorkBasedAction>;
} & WorkBasedActionEditProps['actions'];

export type WorkBasedActionStepViewProps = {
  step: WorkBasedActionLearningActivityStep;
  isLastStep: boolean;
  actions: Actions;
};

const NAVIGATION_KEY = 'work-based-action-step';

export const WorkBasedActionStepView = ({
  step,
  isLastStep,
  actions,
}: WorkBasedActionStepViewProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'work-based-actions' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const isDesktop = useMediaQuery('(min-width: 1280px)');

  const { data: workBasedAction } = useQuery(
    [LEARNER_WBA_BY_SOURCE_ID, step.id, step.sourceId],
    () => actions.fetchLearnerWorkBasedActionById(step.sourceId, step.id),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        // If there is an id
        if (data.id) {
          // we also set the data with the id (so that we don't unecessarily refresh that data)
          queryClient.setQueryData([LEARNER_WBA, data.id], data);
        }
      },
    }
  );

  const afterEditSubmitFunc = () => {
    queryClient.invalidateQueries([LEARNER_WBA_BY_SOURCE_ID, step.id, step.sourceId]);
    setIsEdit(false);
  };

  const setUpEditButtonsFunc = useCallback(
    (submitFunc: () => void) => {
      if (!isDesktop) {
        // For edit we only set the previous button as the save button is handled by the component itself
        navigation.setLeftPrimary(NAVIGATION_KEY, {
          label: '',
          action: () => setIsEdit(false),
        });
      }
      // We just want to hide the button of the parent for now in mobile view
      navigation.setRightPrimary(NAVIGATION_KEY, {
        label: isLastStep ? t('finish') : t('next'),
        action: () => submitFunc(),
        disabled: true,
      });

      if (navigation.getChangeState().rightSecondary) {
        navigation.setRightSecondary(NAVIGATION_KEY, {
          label: t('previous'),
          action: () => null,
          disabled: true,
        });
      }

      return () => {
        navigation.popLeftPrimary(NAVIGATION_KEY);
        navigation.popRightPrimary(NAVIGATION_KEY);
        navigation.popRightSecondary(NAVIGATION_KEY);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDesktop]
  );

  const afterCreateSubmitFunc = () => {
    // invalidate our get query
    queryClient.invalidateQueries([LEARNER_WBA_BY_SOURCE_ID, step.id, step.sourceId]);
    navigation.popRightPrimary(NAVIGATION_KEY);
  };

  const setUpCreateButtonsFunc = useCallback(
    (submitFunc: () => void) => {
      if (workBasedAction?.id) {
        navigation.setRightPrimary(NAVIGATION_KEY, {
          label: tGeneral('save'),
          action: () => submitFunc(),
        });
      }

      // no need to remove the button as the afterCreateSubmit does this for us
      return () => null;
    },
    [tGeneral, workBasedAction?.id]
  );

  useEffect(() => () => navigation.clearLevel(NAVIGATION_KEY), []);
  const renderReadOnly = (workBasedAction: LearnerWorkBasedAction) => (
    <ScrollContainer scrollOnDesktop={true}>
      <HeaderImage
        isEditing={false}
        value={step.headerImage ?? undefined}
        actions={{
          getUploadInformation: () => Promise.reject(),
          uploadFile: () => Promise.reject(),
          getFileUrl: () => Promise.reject(),
        }}
      />
      <ContentWrapper className="mx-auto pb-6 pt-4">
        <div className="space-y-6">
          <div className="flex flex-col space-y-2 lg:space-y-8">
            <Heading3 className="text-text-04 hidden md:inline">{workBasedAction.title}</Heading3>
            <Heading4 className="text-text-04 md:hidden">{workBasedAction.title}</Heading4>
            {/*
            STE-2536
            workBasedAction.description && workBasedAction.description.trim() !== '' && (
              <span className="font-lexend font-regular text-text-01 whitespace-pre-wrap text-base">
                {workBasedAction.description}
              </span>
            )*/}
          </div>
          <ReadOnlyInput
            label={t('learner.short-description')}
            value={workBasedAction.learnerShortDescription ?? ''}
          />
          <ReadOnlyTextArea
            label={t('learner.description')}
            text={workBasedAction.learnerDescription ?? ''}
          />
          <ReadOnlyInput
            label={t('learner.due-date')}
            value={moment(workBasedAction.learnerDueDate).format('DD MMM yyyy')}
          />
        </div>
        {!workBasedAction.completed && (
          <div className="mt-8 hidden lg:block">
            <SecondaryButton
              htmlId="edit-button"
              label={t('edit-button')}
              onClick={() => setIsEdit(true)}
            ></SecondaryButton>
          </div>
        )}
        {!workBasedAction.completed && (
          <div className="px-8 pb-8 lg:hidden">
            <SecondaryButton
              htmlId="edit-button"
              label={t('edit-button')}
              onClick={() => setIsEdit(true)}
            ></SecondaryButton>
          </div>
        )}
      </ContentWrapper>
    </ScrollContainer>
  );

  if (workBasedAction) {
    if (workBasedAction.submitted) {
      if (isEdit) {
        // This view is the edit view where there is a save button managed by the component and where we only have back navigation in the top bar
        return (
          <ScrollContainer scrollOnDesktop={true}>
            <HeaderImage
              isEditing={false}
              value={step.headerImage ?? undefined}
              actions={{
                getUploadInformation: () => Promise.reject(),
                uploadFile: () => Promise.reject(),
                getFileUrl: () => Promise.reject(),
              }}
            />
            <WorkBasedActionEdit
              learnerWorkBasedAction={workBasedAction}
              showWbaDesignerDetails={false}
              showSaveButton={true}
              afterSubmitFunc={afterEditSubmitFunc}
              setUpButtonsFunc={setUpEditButtonsFunc}
              actions={actions}
            />
          </ScrollContainer>
        );
      } else {
        return renderReadOnly(workBasedAction);
      }
    } else {
      // This view is the first time 'create' view. Saving is done by pressing the next button that we provide
      return (
        <ScrollContainer scrollOnDesktop={true}>
          <HeaderImage
            isEditing={false}
            value={step.headerImage ?? undefined}
            actions={{
              getUploadInformation: () => Promise.reject(),
              uploadFile: () => Promise.reject(),
              getFileUrl: () => Promise.reject(),
            }}
          />
          <WorkBasedActionEdit
            actions={actions}
            learnerWorkBasedAction={workBasedAction}
            showWbaDesignerDetails={true}
            showSaveButton={false}
            afterSubmitFunc={afterCreateSubmitFunc}
            setUpButtonsFunc={setUpCreateButtonsFunc}
          />
        </ScrollContainer>
      );
    }
  } else {
    return <LogoLoader />;
  }
};

export default WorkBasedActionStepView;
