import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import WorkBasedActionSurvey from './components/work-based-action-survey';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LogoLoader, Heading3, Heading4, Input } from '@stellar-lms-frontend/ui-components';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FETCH_ACTIONS, LEARNER_WBA, SURVEY } from '../../constants/query';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { ACTION_OLD_REFLECTION_URI_PART } from '../../constants/routes';
import { toSurveyQuestionUserAnswers } from '@stellar-lms-frontend/lms-components';
import { graphQLClient } from '../../lib/graphql';
import {
  getLearnerWorkBasedAction,
  submitWorkBasedActionLearnerSurveyWithTimeSpent,
} from './work-based-actions.api';
import { getSurveyById } from '../learning-activity/learner-learning-activity-detail-page.api';

type FormData = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  answers: any; // flexible type in which all the survey answers will go
  timeSpentHours: number;
  timeSpentMinutes: number;
};

export const WorkBasedActionLearnerSurveyView: React.FC = () => {
  const { t: tComplete } = useTranslation('translation', { keyPrefix: 'actions.complete' });
  const { t } = useTranslation('translation', { keyPrefix: 'actions.survey' });
  const { actionId } = useParams();
  const { data: wba } = useQuery(
    [LEARNER_WBA, actionId],
    () => (actionId ? getLearnerWorkBasedAction(graphQLClient(), actionId) : undefined),
    { enabled: !!actionId }
  );

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const schema = yup.object({
    answers: yup.object(),
    timeSpentHours: yup
      .number()
      .integer(tComplete('validation.time-spent.integer'))
      .typeError(tComplete('validation.time-spent.number'))
      .min(0, tComplete('validation.time-spent.min')),
    timeSpentMinutes: yup
      .number()
      .integer(tComplete('validation.time-spent.integer'))
      .typeError(tComplete('validation.time-spent.number'))
      .max(59, tComplete('validation.time-spent.max'))
      .min(0, tComplete('validation.time-spent.min')),
  });

  const onSubmit = async (publishedSurveyId: string, successFunc: VoidFunc) => {
    if (!wba || !wba.id) return;

    const wbaId = wba.id;

    await handleSubmit(async (data) => {
      const timeSpent = data.timeSpentHours * 60 * 60 + data.timeSpentMinutes * 60;
      await submitWorkBasedActionLearnerSurveyWithTimeSpent(
        graphQLClient(),
        wbaId,
        publishedSurveyId,
        toSurveyQuestionUserAnswers(data.answers),
        timeSpent
      );
      await queryClient.invalidateQueries([LEARNER_WBA]);
      await queryClient.invalidateQueries([FETCH_ACTIONS]);
      toast.success(t('successful-submit-message'));
      successFunc();
    })();
  };

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const learnerSurveyQueryEnabled = !!wba?.learnerSurveyId && wba.state === 'submitted';
  const learnerSurveyQuery = useQuery(
    [SURVEY, wba?.id, wba?.learnerSurveyId],
    () => {
      return wba && wba.id && wba.learnerSurveyId
        ? getSurveyById(graphQLClient(), wba.learnerSurveyId, wba.id)
        : undefined;
    },

    { enabled: wba && !!wba.id && learnerSurveyQueryEnabled }
  );

  // Rendering guards
  if (!wba || !wba.id || !wba.learnerSurveyId) {
    return null;
  }
  if (wba.state !== 'submitted') {
    navigate('../');
    return null;
  }
  if (learnerSurveyQueryEnabled && learnerSurveyQuery.isLoading) {
    return <LogoLoader />;
  }
  if (!learnerSurveyQueryEnabled || (learnerSurveyQuery.data?.questions.length ?? 0) <= 0) {
    navigate(`../${ACTION_OLD_REFLECTION_URI_PART}`, { replace: true });
    return null;
  }

  const renderTimeSpentQuestion = () => (
    <div className="space-y-8">
      <Heading3 className="text-text-04 hidden md:inline">{tComplete('time-spent')}</Heading3>
      <Heading4 className="text-text-04 md:hidden">{tComplete('time-spent')}</Heading4>
      <div className="flex justify-between space-x-4">
        <Controller
          control={control}
          name="timeSpentHours"
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onChange={onChange}
              onBlur={onBlur}
              value={value?.toString()}
              htmlType="number"
              htmlId="time-spent-hours"
              error={errors.timeSpentHours?.message}
              inputMode="numeric"
              rightIcon={tComplete('hours')}
            />
          )}
        />
        <Controller
          control={control}
          name="timeSpentMinutes"
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onChange={onChange}
              onBlur={onBlur}
              value={value?.toString()}
              htmlType="number"
              htmlId="time-spent-minutes"
              error={errors.timeSpentMinutes?.message}
              inputMode="numeric"
              rightIcon={tComplete('minutes')}
            />
          )}
        />
      </div>
    </div>
  );

  return (
    <FormProvider {...methods}>
      <WorkBasedActionSurvey
        surveyId={wba.learnerSurveyId}
        learnerWorkBasedActionId={wba.id}
        stepId={wba.activityId}
        formSectionName="answers"
        type="learner"
        submitWorkBasedActionSurveyFunc={(publishedSurveyId, successFunc) =>
          onSubmit(publishedSurveyId, successFunc)
        }
        extraQuestions={[renderTimeSpentQuestion()]}
      />
    </FormProvider>
  );
};
