import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from '@tanstack/react-query';

import { useNavigate } from 'react-router-dom';
import { FETCH_UNREAD_NOTIFICATIONS } from '../../constants/query';
import { getAllNotifications, markNotificationAsRead } from './notifications.api';
import { toast } from 'react-toastify';
import { UIShell } from '../shell/ui-shell';
import { Notifications } from '@stellar-lms-frontend/lms-components';

export const NotificationPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'notifications' });
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const fetchNotificationsCall = useCallback((param: Date) => {
    return getAllNotifications(param);
  }, []);

  const handleNotificationClick = (notification: Notifications.Notification) => {
    markNotificationAsRead(notification.id).then(() => {
      notification.read = true;
      queryClient.invalidateQueries([FETCH_UNREAD_NOTIFICATIONS]);
    });
    navigateToNotificationLink(notification);
  };

  const navigateToNotificationLink = (notification: Notifications.Notification) => {
    if (Notifications.allowedNotificationTypes.includes(notification.type)) {
      navigate(notification.link);
    } else {
      toast.info(t('not-supported'));
    }
  };

  return (
    <UIShell>
      <Notifications.NotificationComponent
        fetchNotificationFunc={fetchNotificationsCall}
        handleNotificationClick={handleNotificationClick}
      />
    </UIShell>
  );
};

export default NotificationPage;
