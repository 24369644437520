import { EmptyState, NotificationEmptyStateIcon } from '@stellar-lms-frontend/ui-components';
import NotificationItem from './notification-item';
import { useNotifications } from './use-notifications';
import { Notification } from './types';
import { useTranslation } from 'react-i18next';

export type NotificationComponentProps = {
  fetchNotificationFunc: (param: Date) => Promise<Notification[]>;
  handleNotificationClick: (notification: Notification) => void;
};

export const NotificationComponent: React.FC<NotificationComponentProps> = ({
  fetchNotificationFunc,
  handleNotificationClick,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'notifications' });

  const { query, hasNotifications, endOfPageRef } = useNotifications(fetchNotificationFunc);

  return (
    <div
      className="flex h-full w-full flex-row justify-center"
      data-cy="notifications-overview"
    >
      <div className="max-w-content h-full w-full">
        {!hasNotifications ? (
          <EmptyState
            icon={<NotificationEmptyStateIcon />}
            title={t('empty.title')}
            description={t('empty.description')}
          />
        ) : (
          <div className="pb-8">
            <ul
              className="w-full"
              data-cy="notification-list"
            >
              {query.data?.pages?.flat().map((notification) => (
                <li
                  key={notification.id}
                  className="w-full"
                  data-cy="notification-item"
                >
                  <NotificationItem
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                </li>
              ))}
            </ul>
            <div
              className="h-px"
              ref={endOfPageRef}
            />
          </div>
        )}
      </div>
    </div>
  );
};
