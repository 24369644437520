import { renderToString } from 'react-dom/server';
import { FileUploadIcon } from '../../../icons';
import { API, BlockToolConstructorOptions, BlockToolData, ToolConfig } from '@editorjs/editorjs';
import { v4 as uuidv4 } from 'uuid';
import { BlockEditorTypesEnum } from '../../block-editor';
import { createRoot } from 'react-dom/client';
import { DocumentComponent, DocumentComponentProps } from './document-component';
import { UploadResponse, VoidFunc } from '@stellar-lms-frontend/common-utils';
import * as Sentry from '@sentry/react';

export interface DocumentData extends BlockToolData {
  filename: string;
  mimeType: string;
  file: {
    id: string | undefined;
    url: string | undefined;
  };
}

interface DocumentConfig extends ToolConfig {
  documentsEnabled: boolean;
  onSupportClick: VoidFunc;
  uploader?: {
    uploadByFile?: (file: Blob, callback?: (progress: number) => void) => Promise<UploadResponse>;
  };
}

export class Document {
  api: API;
  data: BlockToolData<DocumentData>;
  internalData: DocumentComponentProps['state'] = {
    state: 'input',
    filename: undefined,
    url: undefined,
    errorMessage: undefined,
  };
  readOnly: boolean;
  element: HTMLElement;
  id: string = uuidv4();
  config: DocumentConfig;

  constructor({
    data,
    api,
    config,
    readOnly,
  }: BlockToolConstructorOptions<DocumentData, DocumentConfig>) {
    this.data = {
      filename: data.filename,
      mimeType: data.mimeType,
      file: {
        id: data.file?.id,
        url: data.file?.url,
      },
    };
    if (this.data.file.url?.trim()) {
      this.internalData = {
        state: 'display',
        data: {
          filename: data.filename,
          mimeType: data.mimeType,
          file: {
            id: data.file.id,
            url: data.file.url,
          },
        },
      };
    }
    this.api = api;
    this.readOnly = readOnly;
    this.element = this.createElement();
    if (!config) {
      this.config = {
        documentsEnabled: false,
        uploader: undefined,
        onSupportClick: () => {
          return;
        },
      };
      Sentry.captureException(new Error('No uploader function provided for document in editorjs'), {
        level: 'error',
      });
    } else {
      this.config = config;
    }
    if (this.config.documentsEnabled === false) {
      this.internalData = { state: 'not-supported' };
    }
  }

  static get toolbox() {
    return {
      title: BlockEditorTypesEnum.DOCUMENT,
      icon: renderToString(<FileUploadIcon className="text-text-01 !h-4" />),
    };
  }

  static get inlineToolbar() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  createElement() {
    const rootNode = document.createElement('div');
    rootNode.id = uuidv4();
    return rootNode;
  }

  updateElement() {
    const root = createRoot(this.element);
    root.render(
      <DocumentComponent
        data={this.data}
        onChange={(newData) => {
          this.data = newData;
        }}
        api={this.api}
        state={this.internalData}
        handleUpload={async (file: File, progress: (progres: number) => void) => {
          const response = await this.config.uploader?.uploadByFile?.(file, progress);
          if (response) {
            const toReturn: UploadResponse = {
              url: response.url,
              fileId: response.fileId,
            };
            return toReturn;
          }
          return Promise.reject();
        }}
        onSupportClick={this.config.onSupportClick}
      />
    );
  }

  render() {
    this.updateElement();
    return this.element;
  }

  /*

  Should we allow to update this or should they just do a delete and add again?
  renderSettings() {
    return [
      {
        label: this.api.i18n.t('edit-article-menu-button'),
        icon: renderToString(<Edit4Icon className="text-text-01" />),
        onActivate: () => {
          this.internalData = { state: 'input', url: this.data.file.url };
          this.updateElement();
        },
        closeOnActivate: true,
      },
    ];
  }*/

  save(): BlockToolData<DocumentData> {
    return {
      filename: this.data.filename,
      mimeType: this.data.mimeType,
      file: {
        url: this.data.file.url,
        id: this.data.file.id,
      },
    };
  }
}
