import { navigation, useScreenType, VoidFunc } from '@stellar-lms-frontend/common-utils';
import { PublishedSurvey, Question } from '@stellar-lms-frontend/lms-api';
import {
  getAllQuestions,
  Question as QuestionComponents,
} from '@stellar-lms-frontend/lms-components';
import {
  ContentContainer,
  LeftArrowIcon,
  LinkButton,
  PrimaryButton,
  RightArrowIcon,
  ScrollContainer,
  SecondaryButton,
} from '@stellar-lms-frontend/ui-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { UIShell } from '../../shell/ui-shell';
import {
  getLastSurveySubmission,
  getSurveyById,
} from '../../learning-activity/learner-learning-activity-detail-page.api';
import { graphQLClient } from '../../../lib/graphql';

export type WorkBasedActionSurveyProps = {
  surveyId: string;
  learnerWorkBasedActionId: string;
  stepId: string;
  formSectionName?: string; // For when this form is nested in a deeper form structure
  submitWorkBasedActionSurveyFunc: (publishedSurveyId: string, successFunc: VoidFunc) => void;
  type: 'learner' | 'mentor';
  extraQuestions?: React.ReactElement[];
};

const NAVIGATION_KEY = 'actions.survey';

// This code is very similar to survey-step-view a refactoring to pull the common code out into a survey-view component would be a nice addition
export const WorkBasedActionSurvey = ({
  surveyId,
  learnerWorkBasedActionId,
  stepId,
  formSectionName,
  submitWorkBasedActionSurveyFunc,
  type,
  extraQuestions,
}: WorkBasedActionSurveyProps) => {
  const [publishedSurvey, setPublishedSurvey] = useState<PublishedSurvey>();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [currentQuestion, setCurrentQuestion] = useState<Question>();
  const [totalQuestions, setTotalQuestions] = useState<number>(0);

  const showNextButton = currentQuestionIndex < totalQuestions - 1;
  const showPreviousButton = currentQuestionIndex > 0 && currentQuestionIndex < totalQuestions;
  const showFinishButton = currentQuestionIndex === totalQuestions - 1;

  const { t } = useTranslation('translation', { keyPrefix: 'actions.survey' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const { isTabletOrLarger } = useScreenType();

  const navigate = useNavigate();

  const nbExtraQuestions = useMemo(() => extraQuestions?.length ?? 0, [extraQuestions]);
  const methods = useForm();
  const { reset } = methods;

  const title = type === 'learner' ? t('learner-title') : t('mentor-title');

  const next = useCallback(() => {
    setCurrentQuestionIndex((c) => c + 1);
  }, [setCurrentQuestionIndex]);
  const previous = useCallback(() => {
    setCurrentQuestionIndex((c) => c - 1);
  }, [setCurrentQuestionIndex]);
  const cancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleSubmitAnswers = useCallback(async () => {
    if (!publishedSurvey) return;
    await submitWorkBasedActionSurveyFunc(publishedSurvey.id, () => navigate(-1));
  }, [navigate, publishedSurvey, submitWorkBasedActionSurveyFunc]);

  const finish = useCallback(async () => {
    handleSubmitAnswers();
  }, [handleSubmitAnswers]);

  // Fetching the survey
  useEffect(() => {
    const fetchSurvey = async (surveyId: string, stepId: string) => {
      let survey = await getLastSurveySubmission(graphQLClient(), stepId, surveyId);

      if (!survey || !survey.submitted) {
        // It only creates a new survey when there is no existing survey that is not submitted
        // dirty hack to always send learner context, also for mentor
        survey = await getSurveyById(graphQLClient('learner'), surveyId, stepId);
        setPublishedSurvey(survey);
      } else {
        setPublishedSurvey(survey);
      }

      const allQuestions = getAllQuestions(survey.questions);

      reset(allQuestions);

      const initialIndex = survey?.questions.findIndex((q) => !q.answered);
      setCurrentQuestionIndex(initialIndex !== -1 ? initialIndex : 0);
    };

    fetchSurvey(surveyId, stepId);
  }, [surveyId, stepId, reset, type, nbExtraQuestions]);

  useEffect(() => {
    const nbPublishedSurveyQuestions = publishedSurvey?.questions.length ?? 0;

    setTotalQuestions(nbPublishedSurveyQuestions + nbExtraQuestions);
  }, [nbExtraQuestions, publishedSurvey?.questions, totalQuestions]);

  useEffect(() => {
    return () => navigation.clearLevel(NAVIGATION_KEY);
  }, []);

  // Setting the current question
  useEffect(() => {
    setCurrentQuestion(publishedSurvey?.questions.at(currentQuestionIndex));
  }, [currentQuestionIndex, publishedSurvey?.questions]);

  useEffect(() => {
    if (!isTabletOrLarger) {
      navigation.setTitle(NAVIGATION_KEY, title);
      navigation.setLeftPrimary(NAVIGATION_KEY, {
        action: () => navigate(-1),
        leftIcon: <LeftArrowIcon className="text-on-primary-01" />,
        label: '',
      });

      if (showNextButton) {
        navigation.setRightPrimary(NAVIGATION_KEY, {
          action: () => {
            next();
          },
          label: tGeneral('next'),
        });
      }
      if (showPreviousButton) {
        navigation.setRightSecondary(NAVIGATION_KEY, {
          action: previous,
          label: tGeneral('back'),
        });
      }
      if (showFinishButton) {
        navigation.setRightPrimary(NAVIGATION_KEY, {
          action: () => {
            finish();
          },
          label: tGeneral('finish'),
        });
      }
    }
  }, [
    finish,
    handleSubmitAnswers,
    isTabletOrLarger,
    navigate,
    next,
    previous,
    showFinishButton,
    showNextButton,
    showPreviousButton,
    tGeneral,
    title,
  ]);

  if (!publishedSurvey) {
    return null;
  }

  const renderContent = () => (
    <>
      {
        /* Show the survey questions one by one */
        currentQuestion && currentQuestionIndex < totalQuestions - nbExtraQuestions && (
          <QuestionComponents.QuestionView
            key={currentQuestion.id}
            question={currentQuestion}
            formSectionName={formSectionName}
            showQuestionNumbers={false}
            number={currentQuestionIndex + 1}
            total={publishedSurvey.questions?.length || 0}
            children={(props) => <QuestionComponents.QuestionFullScreen {...props} />}
          />
        )
      }
      {
        /* Show the given extra questions one by one */
        currentQuestionIndex >= totalQuestions - nbExtraQuestions && (
          <ScrollContainer scrollOnDesktop>
            <ContentContainer>
              <div className="flex h-full w-full flex-col items-center justify-between">
                <div className="max-w-content w-full space-y-8">
                  {extraQuestions?.at(currentQuestionIndex - totalQuestions + nbExtraQuestions)}
                </div>
              </div>
            </ContentContainer>
          </ScrollContainer>
        )
      }
    </>
  );

  // With redesign this should be taken out to a separate component as well
  return (
    <div className="flex h-full w-full flex-col">
      {isTabletOrLarger && (
        <div className="border-b-1 border-border-01 flex h-[96px] w-full shrink-0 grow-0 items-center justify-between p-8">
          <div className="grow-0">
            <LinkButton
              htmlId="cancel-action-survey-button"
              label={t('return-to-wba')}
              leftIcon={<LeftArrowIcon className="text-text-01" />}
              onClick={cancel}
            ></LinkButton>
          </div>
          <div className="flex grow-0 space-x-4">
            <div>
              {showPreviousButton && (
                <SecondaryButton
                  htmlId="cancel-action-survey-button"
                  label={tGeneral('previous')}
                  onClick={previous}
                  buttonStyle="small"
                ></SecondaryButton>
              )}
            </div>
            <div>
              {showNextButton && (
                <PrimaryButton
                  htmlId="next-survey-button"
                  label={tGeneral('next')}
                  onClick={next}
                  buttonStyle="small"
                  rightIcon={<RightArrowIcon className="text-surface-01" />}
                ></PrimaryButton>
              )}
              {showFinishButton && (
                <PrimaryButton
                  htmlId="finish-survey-button"
                  label={tGeneral('finish')}
                  onClick={finish}
                  buttonStyle="small"
                ></PrimaryButton>
              )}
            </div>
          </div>
        </div>
      )}
      {isTabletOrLarger && renderContent()}
      {!isTabletOrLarger && (
        <UIShell
          showBottomNav={false}
          useContentContainer={false}
        >
          {renderContent()}
        </UIShell>
      )}
    </div>
  );
};

export default WorkBasedActionSurvey;
