import React, {
  useEffect,
  TextareaHTMLAttributes,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { InputError } from '../../../error';
import { Label } from '../../../label/label';

type Theme = 'unstyled' | 'default' | 'transparent';
type Size = 'small' | 'default' | 'no-padding';
export type ResizableTextAreaState = 'default' | 'warning';

export type AutoResizeTextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  maxRows?: number;
  minRows?: number;
  error?: string;
  required?: boolean;
  label?: string;
  enabled?: boolean;
  labelClassName?: string;
  theme?: Theme;
  value?: string;
  size?: Size;
  leftIcon?: React.ReactNode;
  textareaState?: ResizableTextAreaState;
};

export const AutoResizeTextArea = React.forwardRef<HTMLTextAreaElement, AutoResizeTextAreaProps>(
  (
    {
      onChange,
      className = '',
      value,
      maxRows = 3,
      minRows = 1,
      error,
      required = false,
      label,
      enabled,
      labelClassName,
      theme = 'default',
      size = 'default',
      leftIcon,
      textareaState = 'default',
      ...props
    },
    ref
  ) => {
    // TODO: The internalState is causing issues when managing the component state with ref.
    // The problem it's that if the internalState it's set, we can't update the component value through ref anymore. Eg. hooks-forms reset()
    const [internalState, setInternalState] = useState(value);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const [heightLimits, setHeightLimits] = useState({
      minHeight: 0,
      maxHeight: Infinity,
    });

    const resizeTextarea = useCallback(() => {
      const { current: textarea } = textareaRef;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${Math.max(textarea.scrollHeight, heightLimits.minHeight)}px`;
      }
    }, [heightLimits.minHeight]);

    useEffect(() => {
      setInternalState(value);
    }, [value]);

    useEffect(() => {
      resizeTextarea();
    }, [internalState, resizeTextarea]);

    useEffect(() => {
      const { current: textarea } = textareaRef;
      if (textarea) {
        const style = window.getComputedStyle(textarea, null);
        let lineHeight = parseFloat(style.getPropertyValue('line-height'));
        if (Number.isNaN(lineHeight)) {
          lineHeight = 20;
        }
        setHeightLimits({
          minHeight: minRows * lineHeight,
          maxHeight: maxRows * lineHeight,
        });
      }
    }, [maxRows, minRows]);

    useEffect(() => {
      const descriptor = Object.getOwnPropertyDescriptor(
        window.HTMLTextAreaElement.prototype,
        'value'
      );

      if (!descriptor || !textareaRef.current) return;

      Object.defineProperty(textareaRef.current, 'value', {
        ...descriptor,
        set: function (value) {
          descriptor.set?.call(this, value);
          resizeTextarea();
        },
      });
    }, [resizeTextarea]);

    useEffect(() => {
      resizeTextarea();
    }, [resizeTextarea]);

    const themeClassName = useMemo(() => {
      const focusedClass =
        'focus-within:outline-primary-02 focus-within:outline focus-within:outline-2';

      switch (theme) {
        case 'unstyled':
          return 'border-none bg-transparent focus:ring-transparent';

        case 'transparent':
          if (props.disabled) {
            return 'border-none';
          }

          return `hover:border-border-03 focus-within:border-primary-02 border-[1px]
                  bg-transparent hover:border-solid focus-within:border-solid focus-within:bg-white
                  transition-border-color ease-in-out duration-200 ${focusedClass}
                  ${textareaState === 'default' ? 'border-transparent' : ''}`;

        case 'default':
          return `text-text-01 focus-within:outline-primary-02 placeholder:text-text-03 disabled:border-border-01
                  disabled:text-text-03 bg-white border ${focusedClass}
                  ${textareaState === 'default' ? 'border-border-02' : ''}`;
      }
    }, [textareaState, props.disabled, theme]);

    return (
      <div className="font-lexend w-full space-y-2">
        <Label
          labelClassName={`
          ${labelClassName}
          ${enabled ? '' : 'cursor-[inherit]'}`}
          label={label}
          required={required}
        />
        <div
          className={`flex w-full items-start gap-2 whitespace-pre-wrap rounded-xl !-outline-offset-2
          ${className}
          ${error ? 'border-negative-02' : ''}
          ${textareaState === 'warning' ? 'border-warning-01' : ''}
          ${themeClassName}
          ${size === 'no-padding' ? 'p-0' : ''}
          ${size === 'small' ? 'p-2' : ''}
          ${size === 'default' ? 'py-4 pl-4 pr-3' : ''}
        `}
        >
          {leftIcon && <div className="mt-[2px]">{leftIcon}</div>}
          <textarea
            {...props}
            rows={minRows}
            value={internalState}
            style={{ maxHeight: `${heightLimits.maxHeight}px` }}
            ref={(element) => {
              textareaRef.current = element;
              if (typeof ref === 'function') {
                ref(element);
              } else if (ref) {
                ref.current = element;
              }
            }}
            className={`w-full resize-none overflow-auto whitespace-pre-wrap border-none bg-transparent p-0 focus:ring-transparent
            disabled:cursor-[inherit]`}
            onChange={(e) => {
              resizeTextarea();
              // Hacky way to be aware if it's controlled or uncontrolled
              value && setInternalState(e.target.value);
              onChange?.(e);
            }}
          />
        </div>
        <InputError error={error} />
      </div>
    );
  }
);
