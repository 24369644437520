import { BaseFilterOptionConfig, FilterOptionConfig, FilterValue } from './types';

export const filterData = <TDataItem>(
  data: TDataItem,
  filterOption: FilterOptionConfig<TDataItem>
): boolean => {
  switch (filterOption.type) {
    case 'dropdown':
    case 'input-autocomplete':
      return stringBasedFilterOperation(data, filterOption);
  }
};

const stringBasedFilterOperation = <
  TDataItem,
  F extends BaseFilterOptionConfig<TDataItem> & { selectedValue?: string }
>(
  dataItem: TDataItem,
  filter: F
) => {
  return filter.fieldTransformer
    ? filter.fieldTransformer(dataItem[filter.fieldName]) === filter.selectedValue
    : dataItem[filter.fieldName] === filter.selectedValue;
};

export const updateFilterOptions = <TDataItem>(
  previousFilters: Map<keyof TDataItem, FilterOptionConfig<TDataItem>>,
  newFilterValues: FilterValue<TDataItem>[]
) => {
  const newFilters = new Map(previousFilters);

  for (const newFilterValue of newFilterValues) {
    if (newFilters.has(newFilterValues[0].fieldName)) {
      const filter = newFilters.get(newFilterValue.fieldName);

      // typescript should know this cannot be undefined, typescript 5.5 will..
      if (filter) {
        const updatedFilter = updateFilter(filter, newFilterValue);
        newFilters.set(newFilterValue.fieldName, updatedFilter);
      }
    }
  }

  return newFilters;
};
const updateFilter = <TDataItem>(
  filter: FilterOptionConfig<TDataItem>,
  newFilterValue: FilterValue<TDataItem>
) => {
  filter.hasSelectedValue = newFilterValue.value !== '';

  switch (filter.type) {
    case 'dropdown':
    case 'input-autocomplete': {
      filter = updateStringBasedFilter(filter, newFilterValue);
      break;
    }
  }

  return filter;
};
const updateStringBasedFilter = <TDataItem, F extends { selectedValue?: string }>(
  filter: F,
  newFilterValue: FilterValue<TDataItem>
) => {
  filter.selectedValue = newFilterValue.value;
  return filter;
};

export const resetFilterOptions = <TDataItem>(
  previousFilters: Map<keyof TDataItem, FilterOptionConfig<TDataItem>>
) => {
  const newFilters = new Map(previousFilters);
  for (const filter of newFilters.values()) {
    const resetted = resetFilter(filter);
    newFilters.set(filter.fieldName, resetted);
  }

  return newFilters;
};

const resetFilter = <TDataItem>(filter: FilterOptionConfig<TDataItem>) => {
  filter.hasSelectedValue = false;
  switch (filter.type) {
    case 'dropdown':
    case 'input-autocomplete': {
      filter = resetStringBasedFilter(filter);
      break;
    }
  }

  return filter;
};
const resetStringBasedFilter = <T extends { selectedValue?: string }>(filter: T) => {
  filter.selectedValue = '';
  return filter;
};
