import { useId } from 'react';
import { Switch } from '../../form';
import { Input } from '../../form/input';
import { Radio } from '../../form/radio';
import { VerticalSeparator } from '../../separator/vertical-separator';
import { FreeTextFormErrors } from '../free-text-edit-card';
import { useTranslation } from 'react-i18next';

export type FormData = {
  questionTitle?: string;
  isMultiline: boolean;
  required: boolean;
  visibleToMentors: boolean;
};

export type FreeTextEditFormProps = {
  value: FormData;
  onChange: (data: FormData) => void;
  errors?: FreeTextFormErrors;
  renderShowToMentorsOption: boolean;
};

type RadioOptions = 'multiline' | 'singleline';

export const FreeTextEditForm: React.FC<FreeTextEditFormProps> = ({
  value,
  onChange,
  errors,
  renderShowToMentorsOption,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'survey-step-view.question-types.free-text',
  });
  const { t: tChoiceQuestion } = useTranslation('translation', {
    keyPrefix: 'survey-step-view.question-types.choice',
  });
  const { t: tValidation } = useTranslation('translation', { keyPrefix: 'validation' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const updateRadioValue = (selectedRadio: RadioOptions) =>
    onChange({ ...value, isMultiline: selectedRadio === 'multiline' });
  const updateTitleValue = (title: string) => onChange({ ...value, questionTitle: title });
  const updateRequiredValue = (required: boolean) => onChange({ ...value, required });
  const updateShowToMentorsValue = (visibleToMentors: boolean) =>
    onChange({ ...value, visibleToMentors });

  const generatedId = useId();

  return (
    <div className="space-y-4">
      <Input
        htmlId={`${generatedId}-question-title-input`}
        label={t('question-input-label')}
        onChange={(event) => updateTitleValue(event.target.value)}
        text={value.questionTitle}
        error={errors?.questionTitle?.message}
        placeholder={t('question-input-label')}
      />
      <Radio
        legend={tGeneral('size')}
        onChange={(newValue) => updateRadioValue(newValue as RadioOptions)}
        value={value.isMultiline ? 'multiline' : 'singleline'}
        options={[
          { label: t('multi-line'), value: 'multiline' },
          { label: t('single-line'), value: 'singleline' },
        ]}
        uniqueId={`${generatedId}-question-multiline-option`}
      />
      <div className="flex items-center space-x-4">
        <Switch
          label={tValidation('required')}
          value={value.required}
          onChange={(checked) => updateRequiredValue(checked)}
        />
        {renderShowToMentorsOption && (
          <>
            <VerticalSeparator />
            <Switch
              label={tChoiceQuestion('show-to-mentors')}
              value={value.visibleToMentors}
              onChange={(checked) => updateShowToMentorsValue(checked)}
            />
          </>
        )}
      </div>
    </div>
  );
};
