import { toSurveyQuestionUserAnswers } from '@stellar-lms-frontend/lms-components';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FETCH_ACTIONS, LEARNER_WBA } from '../../constants/query';
import WorkBasedActionSurvey from './components/work-based-action-survey';
import { graphQLClient } from '../../lib/graphql';
import {
  getLearnerWorkBasedAction,
  submitWorkBasedActionMentorSurvey,
} from './work-based-actions.api';

export const WorkBasedActionMentorSurveyView: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'actions.survey' });
  const { actionId } = useParams();
  const navigate = useNavigate();
  const { data: wba } = useQuery(
    [LEARNER_WBA, actionId],
    () => (actionId ? getLearnerWorkBasedAction(graphQLClient(), actionId) : undefined),
    { enabled: !!actionId }
  );

  const methods = useForm();
  const { handleSubmit } = methods;

  const queryClient = useQueryClient();

  const onSubmit = async (publishedSurveyId: string, successFunc: VoidFunction) => {
    if (!wba) {
      return;
    }

    await handleSubmit(async (data) => {
      await submitWorkBasedActionMentorSurvey(
        graphQLClient(),
        wba.id,
        publishedSurveyId,
        toSurveyQuestionUserAnswers(data)
      );
      await queryClient.invalidateQueries([LEARNER_WBA]);
      await queryClient.invalidateQueries([FETCH_ACTIONS]);
      toast.success(t('successful-submit-message'));
      successFunc();
    })();
  };

  // Rendering guards
  if (!wba || !wba.mentorSurveyId) return null;
  if (wba.state !== 'executed') {
    navigate('../');
    return null;
  }

  return (
    <FormProvider {...methods}>
      <WorkBasedActionSurvey
        surveyId={wba.mentorSurveyId}
        learnerWorkBasedActionId={wba.id}
        stepId={wba.activityId}
        type="mentor"
        submitWorkBasedActionSurveyFunc={(publishedSurveyId, successFunc) =>
          onSubmit(publishedSurveyId, successFunc)
        }
      />
    </FormProvider>
  );
};
