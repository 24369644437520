import { SurveyOverview, SurveyResult } from '@stellar-lms-frontend/lms-api';
import { PublishedSurveyQuestionType, graphql } from '@stellar-lms-frontend/lms-graphql';
import { sanitizeHtml } from '@stellar-lms-frontend/common-utils';
import { GraphQLClient } from 'graphql-request';

export const getCourseSurveysForMentor = async (
  client: GraphQLClient,
  courseId: string
): Promise<SurveyOverview[]> => {
  return client
    .request(
      graphql(`
        query GetCourseSurveysForMentor($courseId: ID!) {
          courseSurveys(courseId: $courseId) {
            moduleTitle
            surveyId
            title
            nbQuestions
            nbAnsweredQuestions
          }
        }
      `),
      {
        courseId,
      }
    )
    .then((data) => {
      return data.courseSurveys.map((s) => ({
        ...s,
        surveyId: s.surveyId ?? '',
        moduleTitle: s.moduleTitle ?? '',
      }));
    });
};

export const getSurveyResultsAsMentor = async (
  client: GraphQLClient,
  surveyId: string,
  courseId: string
): Promise<SurveyResult[]> => {
  return client
    .request(
      graphql(`
        query GetSurveyResultsAsMentor($id: ID!, $courseId: ID!) {
          surveyResult(id: $id, courseId: $courseId) {
            removedQuestion
            questionId
            questionTitle
            questionType
            answers {
              answers
              learner {
                id
              }
              time
            }
          }
        }
      `),
      {
        id: surveyId,
        courseId,
      }
    )
    .then((data) => {
      return data.surveyResult.map((q) => {
        return {
          questionId: q.questionId,
          questionType: mapToQuestionType(q.questionType),
          questionTitle: sanitizeHtml(q.questionTitle),
          answers: q.answers.map((a) => ({
            answers: a.answers,
            learnerId: a.learner.id,
            time: new Date(a.time),
          })),
        };
      });
    });
};

const mapToQuestionType = (type: PublishedSurveyQuestionType) => {
  switch (type) {
    case PublishedSurveyQuestionType.FreeTextMultiple:
      return 'free_text_multiple';
    case PublishedSurveyQuestionType.FreeTextSingle:
      return 'free_text_single';
    case PublishedSurveyQuestionType.MultipleChoiceMultipleSelection:
      return 'multiple_choice_multiple_selection';
    case PublishedSurveyQuestionType.MultipleChoiceSingleSelection:
      return 'multiple_choice_single_selection';
    case PublishedSurveyQuestionType.Scale:
      return 'scale';
  }
};
