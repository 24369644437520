import { API, BlockToolConstructorOptions, BlockToolData, ToolConfig } from '@editorjs/editorjs';
import { v4 as uuidv4 } from 'uuid';
import { renderToString } from 'react-dom/server';
import { DiscussIcon } from '../../../icons';
import { createRoot } from 'react-dom/client';
import { DiscussionPromptComponent } from './discussion-prompt-component';
import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import * as Sentry from '@sentry/react';
import { BlockEditorTypesEnum } from '../../block-editor';

export interface DiscussionPromptData extends BlockToolData {
  title: string;
  text: string;
}

interface DiscussionPromptConfig extends ToolConfig {
  onDiscussClick: VoidFunc | undefined;
}

export class DiscussionPrompt {
  api: API;
  data: DiscussionPromptData;
  readOnly: boolean;
  wrapper: HTMLElement;
  id: string = uuidv4();
  config: DiscussionPromptConfig;

  constructor({
    data,
    api,
    config,
    readOnly,
  }: BlockToolConstructorOptions<DiscussionPromptData, DiscussionPromptConfig>) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = {
      title: data.title ?? api.i18n.t('title-placeholder'),
      text: data.text ?? api.i18n.t('description-placeholder'),
    };
    this.wrapper = this.createElement();

    if (!config) {
      this.config = { onDiscussClick: undefined };
      Sentry.captureException(
        new Error('No open discussion panel function provided for discussion prompt in editorjs'),
        {
          level: 'error',
        }
      );
    } else {
      this.config = config;
    }
  }

  static get toolbox() {
    return {
      title: BlockEditorTypesEnum.DISCUSSION_PROMPT,
      icon: renderToString(<DiscussIcon />),
    };
  }

  static get inlineToolbar() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get enableLineBreaks() {
    return true;
  }

  createElement() {
    const rootNode = document.createElement('div');
    rootNode.id = this.id;
    return rootNode;
  }

  updateElement() {
    const root = createRoot(this.wrapper);
    root.render(
      <DiscussionPromptComponent
        id={this.id}
        data={this.data}
        isReadOnly={this.readOnly}
        onChange={(data) => (this.data = data)}
        api={this.api}
        onDiscussClick={this.config.onDiscussClick}
      />
    );
  }

  render() {
    this.updateElement();
    return this.wrapper;
  }

  save(): DiscussionPromptData {
    return {
      title: this.data.title,
      text: this.data.text,
    };
  }
}
