import { useScreenType } from '@stellar-lms-frontend/common-utils';
import { EmptyState, LightBulb, Nugget } from '@stellar-lms-frontend/ui-components';

type TipProps = {
  tips: TipType;
};

// Representation of how a tip is returned as object from i18n.
type TipType = {
  // 1-based string index
  [index: string]: { title: string; description: string };
};

export const Tip: React.FC<TipProps> = ({ tips }) => {
  const tipIndex = (new Date().getDate() % Object.keys(tips).length) + 1;
  const tip = tips[tipIndex];

  const { isTailwindLg } = useScreenType();

  if (!tip || !tip.title || !tip.description) {
    return null;
  }

  if (isTailwindLg) {
    return (
      <EmptyState
        icon={<LightBulb className="h-fit w-[72px] min-w-[72px] lg:w-auto" />}
        title={tip.title}
        description={tip.description}
      />
    );
  } else {
    return (
      <Nugget
        title={tip.title}
        description={tip.description}
        left={<LightBulb className="h-fit w-[72px] min-w-[72px] lg:w-auto" />}
      />
    );
  }
};
