import { LoaderWrapper } from './loader-wrapper';

// TODO no data display
export const SingleNumberChart = ({
  value,
  isLoading,
}: {
  value: number | undefined;
  isLoading: boolean;
}) => {
  return (
    <div className="flex grow items-center justify-center">
      <LoaderWrapper
        isLoading={isLoading}
        hasValue={!!value}
      >
        <div className="text-primary-02 font-lexend text-7xl">{value}</div>
      </LoaderWrapper>
    </div>
  );
};
