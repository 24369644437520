import { useMemo } from 'react';

export type DurationI18N = {
  timeHourShort: string;
  timeMinuteShort: string;
  hour: (count: number) => string;
  min: (count: number) => string;
};

export interface DurationProps {
  seconds: number;
  type?: 'short' | 'abbreviation';
  className?: string;
  i18n: DurationI18N;
}

export const Duration: React.FC<DurationProps> = ({
  seconds,
  type = 'short',
  className = '',
  i18n,
}) => {
  const translation = useMemo(() => {
    switch (type) {
      case 'short':
        return {
          hour: (count: number) => `${count}${i18n.timeHourShort}`,
          minute: (count: number) => `${count}${i18n.timeMinuteShort}`,
        };
      case 'abbreviation':
        return {
          hour: (count: number) => i18n.hour(count) + ' ',
          minute: (count: number) => i18n.min(count),
        };
    }
  }, [i18n, type]);

  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  const hDisplay = h > 0 ? translation.hour(h) : '';
  const mDisplay = m > 0 ? translation.minute(m) : '';

  return <span className={className}>{hDisplay + mDisplay}</span>;
};

export default Duration;
