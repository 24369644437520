import {
  ContentContainer,
  Form,
  LogoLoader,
  Heading4,
  Input,
  LeftArrowIcon,
  PrimaryButton,
  ScrollContainer,
  TertiaryButton,
  TextArea,
  Label,
} from '@stellar-lms-frontend/ui-components';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { navigation, useMediaQuery } from '@stellar-lms-frontend/common-utils';
import { LEARNER_WBA, FETCH_ACTIONS } from '../../constants/query';
import { graphQLClient } from '../../lib/graphql';
import { completeWorkBasedAction, getLearnerWorkBasedAction } from './work-based-actions.api';

export type WorkBasedActionCompleteViewProps = {
  learnerWorkBasedActionId: string;
};

const NAVIGATION_KEY = 'action-complete';

type FormData = {
  reflection: string;
  timeSpentHours: number;
  timeSpentMinutes: number;
};
/**
 * View used to complete a work based action ()
 */
export const WorkBasedActionCompleteView = ({
  learnerWorkBasedActionId,
}: WorkBasedActionCompleteViewProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'actions.complete' });
  const isDesktop = useMediaQuery('(min-width: 1280px)');

  const { data: workBasedAction } = useQuery([LEARNER_WBA, learnerWorkBasedActionId], () =>
    getLearnerWorkBasedAction(graphQLClient(), learnerWorkBasedActionId)
  );

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const schema = yup.object({
    reflection: yup.string().required(t('validation.reflection.required')),
    timeSpentHours: yup
      .number()
      .integer(t('validation.time-spent.integer'))
      .typeError(t('validation.time-spent.number'))
      .min(0, t('validation.time-spent.min')),
    timeSpentMinutes: yup
      .number()
      .integer(t('validation.time-spent.integer'))
      .typeError(t('validation.time-spent.number'))
      .max(59, t('validation.time-spent.max'))
      .min(0, t('validation.time-spent.min')),
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (!isDesktop) {
      navigation.setTitle(NAVIGATION_KEY, t('title'));
      navigation.setLeftPrimary(NAVIGATION_KEY, {
        action: () => navigateBack(),
        leftIcon: <LeftArrowIcon className="text-on-primary-01" />,
        label: '',
      });
    }

    return () => {
      if (!isDesktop) {
        navigation.popLeftPrimary(NAVIGATION_KEY);
        navigation.popTitle(NAVIGATION_KEY);
      }
    };
  }, [learnerWorkBasedActionId, navigate, t, isDesktop, navigateBack]);

  const handleUserInput = async (data: FormData) => {
    try {
      if (workBasedAction && workBasedAction.id) {
        workBasedAction.learnerFeedback = data.reflection;
        workBasedAction.timeSpent = data.timeSpentHours * 60 * 60 + data.timeSpentMinutes * 60;
        await completeWorkBasedAction(graphQLClient(), workBasedAction.id, {
          learnerFeedback: workBasedAction.learnerFeedback,
          timeSpent: workBasedAction.timeSpent,
        });
        queryClient.invalidateQueries([LEARNER_WBA]);
        queryClient.invalidateQueries([FETCH_ACTIONS]);
        toast.success(t('completed-successfully-message'));
        navigateBack();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onCancelClick = () => {
    navigateBack();
  };

  return !workBasedAction ? (
    <LogoLoader />
  ) : (
    <div
      className="flex h-full w-full flex-col justify-between lg:justify-start"
      data-cy="wba-complete-view"
    >
      <ScrollContainer>
        <ContentContainer>
          <div className="max-w-content w-full">
            <div className="hidden flex-row items-center justify-between pb-5 lg:flex">
              <Heading4>{t('description')}</Heading4>
            </div>
            <div className="pb-5">
              <Form className="mt-4 space-y-6">
                <TextArea
                  label={t('reflection')}
                  placeholder={t('reflection-placeholder')}
                  htmlId="reflection"
                  required
                  {...register('reflection')}
                  error={errors.reflection?.message}
                />
                <div>
                  <Label
                    htmlId={'time-spent-hours'}
                    label={t('time-spent')}
                    required
                  />
                  <div className="flex justify-between space-x-4">
                    <Controller
                      control={control}
                      name="timeSpentHours"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value?.toString()}
                          htmlType="number"
                          htmlId="time-spent-hours"
                          error={errors.timeSpentHours?.message}
                          inputMode="numeric"
                          rightIcon={t('hours') /* CLEANUP: not an icon, maybe should be renamed */}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="timeSpentMinutes"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value?.toString()}
                          htmlType="number"
                          htmlId="time-spent-minutes"
                          error={errors.timeSpentMinutes?.message}
                          inputMode="numeric"
                          rightIcon={
                            t('minutes') /* CLEANUP: not an icon, maybe should be renamed */
                          }
                        />
                      )}
                    />
                  </div>
                </div>
              </Form>
            </div>

            {!workBasedAction.completed && (
              <div className="mt-8 hidden space-x-4 lg:flex lg:flex-row">
                <PrimaryButton
                  data-cy="wba-complete-complete-button"
                  htmlId="complete-button"
                  label={t('cta-complete')}
                  onClick={handleSubmit(handleUserInput)}
                ></PrimaryButton>
                <TertiaryButton
                  data-cy="wba-complete-view-cancel-button"
                  htmlId="cancel-button"
                  label={t('cta-cancel')}
                  onClick={onCancelClick}
                />
              </div>
            )}
          </div>
        </ContentContainer>
      </ScrollContainer>
      {!workBasedAction.completed && (
        <ContentContainer className="shrink-0 grow-0 py-8 lg:hidden lg:p-0">
          <PrimaryButton
            data-cy="wba-complete-complete-button"
            htmlId="complete-button"
            label={t('cta-complete')}
            onClick={handleSubmit(handleUserInput)}
          ></PrimaryButton>
        </ContentContainer>
      )}
    </div>
  );
};

export default WorkBasedActionCompleteView;
