import { useQuery, useQueryClient } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import { useCallback } from 'react';
import { CompanyRole, Status, graphql } from '../graphql';

const QUERY_KEY = 'CURRENT_USER';

/**
 *
 * This hook provides the caller with the current logged in user
 */
export const useCurrentUser = (client: GraphQLClient) => {
  const queryClient = useQueryClient();

  const query = useQuery([QUERY_KEY], () => getCurrentUser(client), {
    staleTime: 60 * 1000, // 1 minute
    cacheTime: 5 * 60 * 10000, // 5 minutes
  });

  const invalidateCache = useCallback(
    () => queryClient.invalidateQueries([QUERY_KEY]),
    [queryClient]
  );

  const setCompletedOnboarding = useCallback(
    (completed: boolean) => {
      queryClient.setQueryData([QUERY_KEY], (data: any) => {
        return { ...data, hasCompletedOnboarding: completed };
      });
    },
    [queryClient]
  );

  return {
    invalidateCache: invalidateCache,
    setCompletedOnboarding,
    query: query,
  };
};

export type CurrentUser = {
  id: string;
  email: string;
  name?: string;
  lastName?: string;
  firstName?: string;
  language: string;
  role: CompanyRole;
  settings: {
    enabled: {
      push: boolean;
      email: boolean;
    };
    spacedRepetitionContactTime: string;
    spacedRepetitionNotifications: boolean;
  };
  thumbUri?: string;
  hasCompletedOnboarding: boolean;
  company: {
    id: string;
    realtimeChannel: {
      id: string;
    };
  };
  status: Status;
  viewedTips: (string | null)[];
  permissions: string[];
};

const getCurrentUser = (client: GraphQLClient): Promise<CurrentUser | undefined> => {
  return client
    .request(
      graphql(`
        query CurrentUser {
          currentUser {
            email
            id
            language
            name
            role
            roles {
              id
            }
            settings {
              enabled {
                email
                push
              }
              spacedRepetitionContactTime
              spacedRepetitionNotifications
            }
            thumbUri
            lastName
            hasCompletedOnboarding
            firstName
            company {
              id
              realtimeChannel {
                id
              }
            }
            status
            viewedTips
            permissions
          }
        }
      `)
    )
    .then((data) => {
      if (data.currentUser) {
        const dataUser = data.currentUser;
        const user: CurrentUser = {
          id: dataUser.id,
          email: dataUser.email,
          name: dataUser.name ?? undefined,
          lastName: dataUser.lastName ?? undefined,
          firstName: dataUser.firstName ?? undefined,
          language: dataUser.language ?? 'en',
          role: dataUser.role,
          settings: {
            enabled: {
              push: dataUser.settings.enabled.push,
              email: dataUser.settings.enabled.email,
            },
            spacedRepetitionContactTime: dataUser.settings.spacedRepetitionContactTime,
            spacedRepetitionNotifications: dataUser.settings.spacedRepetitionNotifications,
          },
          thumbUri: dataUser.thumbUri ?? undefined,
          hasCompletedOnboarding: dataUser.hasCompletedOnboarding ?? false,
          company: {
            id: dataUser.company.id,
            realtimeChannel: {
              id: dataUser.company.realtimeChannel.id,
            },
          },
          status: dataUser.status,
          viewedTips:
            dataUser.viewedTips?.filter((i) => {
              return i != null;
            }) ?? [],
          permissions: dataUser.permissions ?? [],
        };
        return user;
      }
      return undefined;
    });
};
