import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import { BadgeAI, SpinnerLoader } from '@stellar-lms-frontend/ui-components';

export const ViewListItem = ({
  title,
  isLoading,
  isHighlighted,
  isPlaceholder,
  aiBadgeLabel,
  onClick,
  id,
}: {
  title: string;
  isLoading: boolean;
  isHighlighted: boolean;
  isPlaceholder: boolean;
  aiBadgeLabel: string;
  onClick: VoidFunc;
  id: string;
}) => {
  let colorClassName = 'bg-transparent text-text-02 hover:text-text-01';
  let indicatorHighlightClassName = 'bg-primary-01';
  let textHighlightClassName = 'text-primary-01';
  if (isPlaceholder) {
    colorClassName = 'bg-transparent text-purple-500 hover:text-purple-600';
    indicatorHighlightClassName = 'bg-purple-500';
    textHighlightClassName = 'text-purple-600';
  }
  // TODO:  data-cy became id -- check tests
  return (
    <li
      data-cy={`course-overview-module-${id}`}
      key={id}
      id={`sidebar-module-${id}`}
      className="flex cursor-pointer flex-row items-center py-3"
      onClick={onClick}
    >
      <i
        className={`w-1 shrink-0 grow-0 rounded ${
          isHighlighted ? indicatorHighlightClassName : colorClassName
        }`}
      />
      <span
        className={`font-lexend grow pl-3 text-base font-medium ${
          isHighlighted ? textHighlightClassName : colorClassName
        }`}
      >
        {isPlaceholder ? `[${title}]` : title}
      </span>
      {isLoading && (
        <SpinnerLoader
          color={isHighlighted ? 'blue' : 'grey'}
          className="mr-3 text-[3px]"
        />
      )}
      {isPlaceholder && (
        <BadgeAI
          color="purple"
          label={aiBadgeLabel}
        />
      )}
    </li>
  );
};
