import { Table as TanStackTable } from '@tanstack/table-core';
import { DefaultRowDataType } from './flex-table/components/table-rows';

export type PageSelectorProps<T extends DefaultRowDataType = DefaultRowDataType> = {
  table: TanStackTable<T>;
};
export const PageSelector = <T extends DefaultRowDataType = DefaultRowDataType>({
  table,
}: PageSelectorProps<T>) => {
  // Build up page number labels
  // Can probably be a bit optimized for rendering if necessary
  const totalPages = table.getPageCount();
  const currentPageIndex = table.getState().pagination.pageIndex;
  const visiblePageIndexes = [
    0,
    totalPages - 1,
    currentPageIndex,
    currentPageIndex - 1,
    currentPageIndex - 2,
    currentPageIndex + 1,
    currentPageIndex + 2,
  ];
  const allPageNumbers = [...Array(table.getPageCount()).keys()]
    .filter((index) => visiblePageIndexes.some((visibleIndex) => visibleIndex === index))
    .reduce((combinedArray, currentIndex) => {
      const prevEntry = combinedArray.at(-1);
      // Explicit check as 0 is a possible index and would evaluate as falsy
      if (prevEntry !== undefined && currentIndex - prevEntry > 1) {
        return [...combinedArray, -1, currentIndex];
      }
      return [...combinedArray, currentIndex];
    }, [] as number[]);

  return (
    <div>
      {allPageNumbers.length > 1 && (
        <div className="text-text-02 font-regular font-lexend flex flex-row items-center space-x-1 text-base">
          {allPageNumbers.map((pageEntry) => {
            if (pageEntry !== -1) {
              return (
                <button
                  onClick={() => table.setPageIndex(pageEntry)}
                  className={`${
                    pageEntry === table.getState().pagination.pageIndex
                      ? 'text-primary-01 after:bg-primary-01'
                      : 'hover:after:bg-border-03'
                  } hover:text-text-04 relative w-8 cursor-pointer py-2 text-center after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-8 after:rounded-full`}
                >
                  {pageEntry + 1}
                </button>
              );
            } else {
              return <span>...</span>;
            }
          })}
        </div>
      )}
    </div>
  );
};
