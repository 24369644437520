import { NotificationType } from '@stellar-lms-frontend/lms-graphql';

export const allowedNotificationTypes = [
  NotificationType.CourseEnrollment,
  NotificationType.SpacedRepetition,
  NotificationType.ActionCreated,
  NotificationType.ActionCompleted,
  NotificationType.ActionReviewed,
  NotificationType.ForumComment,
  NotificationType.ForumCommentReply,
  NotificationType.ForumCommentLike,
  NotificationType.WbaComment,
  NotificationType.WbaCommentReply,
  NotificationType.WbaCommentLike,
  NotificationType.LearningActivityUnlocked,
  NotificationType.WbaDuedate,
  NotificationType.WbaOverdue,
  NotificationType.AssignedAsMentor,
  NotificationType.BehaviourGenerated,
];

export const notificationTypesWithCourseName = [
  NotificationType.ForumComment,
  NotificationType.ForumCommentReply,
  NotificationType.ForumCommentLike,
  NotificationType.AssignedAsMentor,
  NotificationType.BehaviourGenerated,
];
