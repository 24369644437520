import Media from 'react-media';
import { LeftArrowIcon } from '../../icons';
import LinkButton from '../link-button/link-button';

export interface BackButtonProps {
  htmlId: string;
  label?: string;
  className?: string;
  onClick: () => void;
}

export function BackButton({ htmlId, label, onClick, className }: BackButtonProps) {
  return (
    <Media query="(max-width: 1023px)">
      {(matches) =>
        matches ? (
          <LinkButton
            label=""
            htmlId={htmlId}
            onClick={onClick}
            leftIcon={<LeftArrowIcon className={className ?? 'fill-text-04'} />}
          />
        ) : (
          <LinkButton
            htmlId={htmlId}
            label={label ?? ''}
            onClick={onClick}
            leftIcon={<LeftArrowIcon className={className ?? 'fill-text-04'} />}
          />
        )
      }
    </Media>
  );
}

export default BackButton;
