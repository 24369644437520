import { VoidFunc, isEllipsisActive } from '@stellar-lms-frontend/common-utils';
import { useState } from 'react';
import { Badge } from '../badge/badge';
import { FloatingBox } from '../wrappers/floating-box';
import { CrossIcon } from '../icons';

export type BadgeSkillProps = {
  skill: string;
  onDelete?: VoidFunc;
};
export const BadgeSkill: React.FC<BadgeSkillProps> = ({ skill, onDelete }) => {
  const [toolTipOpen, setToolTipOpen] = useState<boolean>(false);

  return (
    <Badge
      color="grey"
      size="lg"
      className="flex items-center gap-2"
    >
      <FloatingBox
        placement={'top'}
        isOpen={toolTipOpen}
        wrappedComponent={
          <p
            className="truncate text-sm"
            onMouseEnter={(e) => {
              isEllipsisActive(e.currentTarget) && setToolTipOpen(true);
            }}
            onMouseLeave={() => setToolTipOpen(false)}
          >
            {skill}
          </p>
        }
      >
        <div className="bg-inverse-bg text-on-inverse-01 p-3 text-sm font-medium">
          <p>{skill}</p>
        </div>
      </FloatingBox>
      {onDelete && (
        <button
          type="button"
          onClick={onDelete}
        >
          <CrossIcon className="h-4 w-4" />
        </button>
      )}
    </Badge>
  );
};
