import { FloatingBox, ProgressBar } from '@stellar-lms-frontend/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type ModuleProgressBarProps = {
  startedPercentage: number;
  completedPercentage: number;
  className?: string;
};

export const ModuleProgressBar: React.FC<ModuleProgressBarProps> = ({
  startedPercentage = 0,
  completedPercentage = 0,
  className,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'module-overview.progress' });
  const [isStatsOpen, setIsStatsOpen] = useState(false);

  return (
    <FloatingBox
      placement={'bottom-start'}
      onClose={() => setIsStatsOpen(false)}
      wrappedComponent={
        <div
          className={`w-full py-2 ${className}`}
          onMouseEnter={() => setIsStatsOpen(true)}
          onMouseLeave={() => setIsStatsOpen(false)}
        >
          <ProgressBar
            htmlId="moduleProgress"
            bgColorClassName="bg-surface-02-hover"
            roundedClassName="rounded-full"
            heightClassName="h-[6px]"
            progress={[
              {
                value: startedPercentage,
                colorClassName:
                  'bg-gradient-to-r from-primary-gradient-start to-primary-gradient-end opacity-30',
              },
              {
                value: completedPercentage,
                colorClassName:
                  'bg-gradient-to-r from-primary-gradient-start to-primary-gradient-end',
              },
            ]}
          />
        </div>
      }
      isOpen={isStatsOpen}
    >
      <div className="bg-inverse-bg text-on-inverse-01 p-3 text-sm font-medium">
        <p>
          {t('label')}
          <br />
          {t('completed_perc', { percentage: completedPercentage.toFixed(1) })}
          <br />
          {t('started_perc', { percentage: startedPercentage.toFixed(1) })}
          <br />
        </p>
      </div>
    </FloatingBox>
  );
};
