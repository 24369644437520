import { PrimaryButton } from '@stellar-lms-frontend/ui-components';

export type LearnerTableActionsI18N = {
  add: string;
};

export type LearnerTableActionsProps = {
  i18n: LearnerTableActionsI18N;
  onClickAdd: () => void;
  hasEditPermission: boolean;
};

export const LearnerTableActions: React.FC<LearnerTableActionsProps> = ({
  i18n,
  onClickAdd,
  hasEditPermission,
}) => {
  return (
    <div className="flex items-center justify-end gap-4">
      {/* <Input
        htmlId={'learner-table-actions-search'}
        className="grow"
        leftIcon={<FontAwesomeIcon icon={faSearch} />}
      /> */}
      {hasEditPermission && (
        <PrimaryButton
          label={i18n.add}
          onClick={onClickAdd}
          className="shrink-0"
          enterListener={false}
        />
      )}
    </div>
  );
};
