import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import React, { ChangeEventHandler, KeyboardEventHandler, ReactNode } from 'react';
import { Label } from '../../label/label';
import { InputError } from '../../error/input-error';

export interface TextAreaProps {
  label?: string;
  name?: string;
  htmlId: string;
  placeholder?: string;
  required?: boolean;
  enabled?: boolean;
  error?: string;
  text?: string;
  initialText?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: ChangeEventHandler<HTMLTextAreaElement>;
  labelClassName?: string;
  heightClassName?: string;
  icon?: ReactNode;
  iconClick?: VoidFunc;
  onKeyUp?: KeyboardEventHandler<HTMLTextAreaElement>;
  length?: number;
  maxLength?: number;
}

export const TextArea = React.forwardRef(
  (
    {
      label,
      name,
      htmlId,
      placeholder,
      required = false,
      enabled = true,
      error,
      text,
      onChange,
      onBlur,
      labelClassName,
      initialText,
      heightClassName,
      icon,
      iconClick,
      onKeyUp,
      length,
      maxLength,
    }: TextAreaProps,
    ref: React.ForwardedRef<HTMLTextAreaElement>
  ) => {
    return (
      <div
        id={htmlId + '-container'}
        className="font-lexend w-full"
      >
        <Label
          htmlId={htmlId}
          labelClassName={`
          ${labelClassName}
          ${enabled ? '' : 'cursor-[inherit]'}`}
          label={label}
          required={required}
          length={length}
          maxLength={maxLength}
        />
        <div className="text-text-01 relative mt-2 flex w-full items-center">
          <textarea
            maxLength={maxLength}
            data-cy={`text-area-${name}`}
            name={name ?? htmlId}
            id={htmlId}
            required={required}
            disabled={!enabled}
            ref={ref}
            className={`
            ${error ? 'border-negative-01' : ''}
            ${heightClassName ? heightClassName : 'min-h-[240px]'}
            text-text-01 focus:outline-primary-02 !-outline-offset-2 placeholder:text-text-03
            disabled:border-border-01 disabled:text-text-03 border-border-02 flex 
            w-full resize-none flex-row whitespace-pre-wrap rounded-xl border p-4 focus:outline-2
            focus:ring-transparent disabled:cursor-[inherit]`}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={initialText}
            value={text}
            onKeyUp={onKeyUp}
          />
          {icon && (
            <div
              onClick={iconClick}
              className="absolute bottom-3 right-4"
            >
              {icon}
            </div>
          )}
        </div>
        <InputError error={error} />
      </div>
    );
  }
);

export default TextArea;
