export const scrollTo = (
  targetId: string,
  scrollViewId: string,
  smoothScroll: boolean,
  heightPadding: number,
  callback?: () => void
) => {
  const scrollView = document.getElementById(scrollViewId);
  const target = document.getElementById(targetId);

  if (!scrollView || !target) return;

  scrollView.scroll({
    left: 0,
    top: target.offsetTop - heightPadding,
    behavior: smoothScroll ? 'smooth' : 'auto',
  });
  callback?.();
};
