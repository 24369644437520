import { Heading4 } from '@stellar-lms-frontend/ui-components';
import { ModuleSidebarLoadingCard } from './module-sidebar-loading-card';
import { LoadingMoreModules } from './module-sidebar';
import { Dispatch } from 'react';
import { ModuleStore } from '../store';
import { ViewListItem } from './view-list-item';
import { useTranslation } from 'react-i18next';

type ViewProps = {
  selectModule: Dispatch<string>;
  loadingMoreModules: LoadingMoreModules;
  highlightedModuleId?: string;
};

export const View: React.FC<ViewProps> = ({
  selectModule,
  loadingMoreModules,
  highlightedModuleId,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'module-overview.module-list' });
  const { t: tLoading } = useTranslation('translation', { keyPrefix: 'dashboard.course.loading' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const modules = ModuleStore.useStore((state) => state.modules);

  return (
    <div
      className="self-start px-12 pt-16 pb-4"
      data-test-id="module-navigation"
    >
      <div className="flex items-center justify-between">
        <Heading4 className="text-text-04">{t('overview-title')}</Heading4>
      </div>
      {loadingMoreModules.loading && (
        <ModuleSidebarLoadingCard
          title={tLoading('title')}
          description={tLoading('description')}
          className="mt-2 mb-6"
        />
      )}
      <ul className="mb-3">
        {modules.map((module, index) => (
          <ViewListItem
            key={module.id}
            id={module.id}
            title={module.title ?? ''}
            isPlaceholder={module.placeholder}
            isLoading={module.learningActivities.some(
              (la) => la.aiGenerated && !la.aiGenerationDone
            )}
            isHighlighted={highlightedModuleId === module.id}
            aiBadgeLabel={tGeneral('ai-badge-label')}
            onClick={() => selectModule(module.id)}
          />
        ))}
      </ul>
    </div>
  );
};
