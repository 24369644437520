import { ReactElement, ReactNode } from 'react';

import { FloatingBox } from './floating-box';
import { DataCy, VoidFunc } from '@stellar-lms-frontend/common-utils';
import { Placement } from '@floating-ui/react-dom';
import { OptionItem } from './components/option-item';

export type FloatingOptionsOption = {
  key?: string;
  label?: ReactNode;
  subtitle?: ReactNode;
  onClick?: (e: React.UIEvent<Element>) => void;
  className?: string;
  left?: ReactNode;
  type?: 'option' | 'title' | 'divider' | 'custom';
};

export type FloatingOptionsProps = DataCy & {
  placement?: Placement;
  hasDivider?: boolean;
  usePortal?: boolean;
  options: FloatingOptionsOption[];
  widthClassName?: string;
  hasArrow?: boolean;
  heightClassName?: string;
  title?: string;
  wrappedComponent: ReactElement;
  isOpen: boolean;
  onClose: VoidFunc;
  matchWidth?: boolean;
};

export const FloatingOptions: React.FC<FloatingOptionsProps> = ({
  wrappedComponent,
  placement = 'bottom-end',
  hasDivider = false,
  usePortal = true,
  isOpen,
  onClose,
  options,
  widthClassName = 'w-[300px]',
  'data-cy': dataCy,
  hasArrow = false,
  heightClassName = 'max-h-[300px]',
  title,
  matchWidth,
}) => {
  return (
    <FloatingBox
      matchWidth={matchWidth}
      wrappedComponent={wrappedComponent}
      usePortal={usePortal}
      placement={placement}
      isOpen={isOpen}
      onClose={onClose}
      className={widthClassName}
      hasArrow={hasArrow}
      heightClassName={heightClassName}
    >
      <div className="flex w-full flex-col">
        {title && <p className="text-text-02 type-small-medium px-6 py-2">{title}</p>}
        {options.map((o, index) => (
          <OptionItem
            item={o}
            key={o.key || index}
            data-cy={dataCy}
            index={index}
            hasDivider={hasDivider}
          />
        ))}
      </div>
    </FloatingBox>
  );
};
