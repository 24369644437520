import { WBAStateChart } from './insights-charts/wba-state-chart';
import { MembersOverTimeChart } from './insights-charts/members-over-time-chart';
import { JourneysOverTimeChart } from './insights-charts/journeys-over-time-chart';
import { CompanyRolesChart } from './insights-charts/company-roles-chart';
import { TotalJourneysChart } from './insights-charts/total-journeys-chart';
import { TotalMembersChart } from './insights-charts/total-members-chart';
import { CompanyCourseMemberRolesChart } from './insights-charts/company-course-members-chart';
import { Heading2, ChartCard } from '@stellar-lms-frontend/ui-components';
import { CompletedSRQsChart } from './insights-charts/completed-srqs-chart';
import { ActiveSRQsChart } from './insights-charts/active-srqs-chart';
import { SRQLevelDistributionChart } from './insights-charts/srq-level-distribution-chart';

export const CompanyInsightsDashboard = ({ companyId }: { companyId: string }) => {
  return (
    <div className="max-w-screen-3xl w-full">
      <div className="grid w-full grid-cols-12 gap-4">
        <ChartCard
          className="col-span-3"
          title="Total Members"
        >
          <TotalMembersChart companyId={companyId} />
        </ChartCard>
        <ChartCard
          className="col-span-3"
          title="Company Roles"
        >
          <CompanyRolesChart companyId={companyId} />
        </ChartCard>
        <ChartCard
          className="col-span-3"
          title="Total Journeys"
        >
          <TotalJourneysChart companyId={companyId} />
        </ChartCard>
        <ChartCard
          className="col-span-3 "
          title="Journey Roles"
        >
          <CompanyCourseMemberRolesChart companyId={companyId} />
        </ChartCard>
        <ChartCard
          className="col-span-6 h-[400px]"
          title="Number of members over time"
        >
          <MembersOverTimeChart companyId={companyId} />
        </ChartCard>
        <ChartCard
          className="col-span-6 h-[400px]"
          title="Number of journeys over time"
        >
          <JourneysOverTimeChart companyId={companyId} />
        </ChartCard>
      </div>

      <div className="w-full pb-6 pt-10">
        <Heading2 className="text-text-01">Spaced Repetition Questions (SRQ)</Heading2>
      </div>
      <div className="grid w-full grid-cols-12 gap-4">
        <ChartCard
          className="col-span-3"
          title="Completed SRQs"
        >
          <CompletedSRQsChart companyId={companyId} />
        </ChartCard>
        <ChartCard
          className="col-span-3"
          title="Active SRQs"
        >
          <ActiveSRQsChart companyId={companyId} />
        </ChartCard>
        <ChartCard
          className="col-span-6"
          title="SRQ Level Distribution"
        >
          <SRQLevelDistributionChart companyId={companyId} />
        </ChartCard>
      </div>

      <div className="w-full pb-6 pt-10">
        <Heading2 className="text-text-01">Actions</Heading2>
      </div>
      <div className="grid w-full grid-cols-12 gap-4">
        <ChartCard
          className="col-span-6 h-[400px]"
          title="Action status distribution"
        >
          <WBAStateChart companyId={companyId} />
        </ChartCard>
      </div>
    </div>
  );
};
