export type QuestionType =
  | 'free_text_single'
  | 'free_text_multiple'
  | 'multiple_choice_single_selection'
  | 'multiple_choice_multiple_selection'
  | 'scale'
  | 'fill_in_the_blanks';

export type TranslatableQuestionType =
  | 'free-text-single'
  | 'free-text-multiple'
  | 'multiple-choice-single-selection'
  | 'multiple-choice-multiple-selection'
  | 'scale'
  | 'fill-in-the-blanks';

export const questionTypeToTranslatableQuestionType = (type: QuestionType) => {
  switch (type) {
    case 'free_text_single':
      return 'free-text-single';
    case 'free_text_multiple':
      return 'free-text-multiple';
    case 'multiple_choice_single_selection':
      return 'multiple-choice-single-selection';
    case 'multiple_choice_multiple_selection':
      return 'multiple-choice-multiple-selection';
    case 'scale':
      return 'scale';
    case 'fill_in_the_blanks':
      return 'fill-in-the-blanks';
  }
};
