import { SpacedRepetitionWizard } from './spaced-repetition-wizard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CheckLight, EmptyState, LogoLoader } from '@stellar-lms-frontend/ui-components';
import { useEventListener } from '@stellar-lms-frontend/common-utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { SPACED_REPETITION_QUESTIONS } from '../../constants/query';
import { getSpacedRepetitionQuestions } from './spaced-repetition.api';
import { useCurrentCompany } from '@stellar-lms-frontend/lms-graphql';
import { graphQLClient } from '../../lib/graphql';

export const SpacedRepetitionPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'spaced-repetition.empty' });
  const { query: currentCompanyQuery } = useCurrentCompany(graphQLClient('learner'));

  const methods = useForm();
  const srqQuery = useQuery(
    [SPACED_REPETITION_QUESTIONS],
    () =>
      currentCompanyQuery.data
        ? getSpacedRepetitionQuestions(graphQLClient('learner'), currentCompanyQuery.data.id)
        : [],
    {
      enabled: !!currentCompanyQuery.data?.id,
    }
  );
  const navigateToHome = () => {
    navigate('/');
  };

  const handleEnterClick = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      navigateToHome();
    }
  };

  useEventListener('keyup', handleEnterClick, !!srqQuery.data?.length);

  return srqQuery.isLoading ? (
    <LogoLoader />
  ) : srqQuery.data?.length ? (
    <FormProvider {...methods}>
      <SpacedRepetitionWizard questions={srqQuery.data} />
    </FormProvider>
  ) : (
    <div className="h-full w-full p-8">
      <EmptyState
        actionText={t('navigate-home')}
        onActionClick={navigateToHome}
        description={t('subtitle')}
        title={t('title')}
        verticalCenter={true}
        icon={<CheckLight />}
      />
    </div>
  );
};

export default SpacedRepetitionPage;
