import React, { ReactNode } from 'react';

export type TabMenuTab<T extends string | number> = {
  id: T;
  label: ReactNode;
};

export type TabMenuProps<T extends string | number> = {
  tabs: TabMenuTab<T>[];
  onChange: (tabId: T) => void;
  activeId: T;
  type: 'vertical' | 'horizontal';
  className?: string;
};

export const TabMenu = <T extends string | number>({
  tabs,
  onChange,
  activeId,
  type,
  className = '',
}: TabMenuProps<T>): React.ReactElement => {
  const isHorizontal = type === 'horizontal';

  const tabClassName = isHorizontal
    ? 'min-w-[100px] flex-col items-center justify-between gap-2 py-2'
    : 'flex-row-reverse items-stretch justify-end gap-3';

  return (
    <div
      className={`font-lexend flex ${isHorizontal ? 'flex-row' : 'flex-col gap-3'} ${className}`}
    >
      {tabs.map((t) => {
        const isTabActive = activeId === t.id;

        return (
          <button
            type="button"
            key={t.id.toString()}
            className={`flex cursor-pointer 
            ${isTabActive ? 'text-primary-01' : 'text-text-02 hover:text-text-01'}
            ${tabClassName}`}
            onClick={() => onChange(t.id)}
          >
            <div className={`${isHorizontal ? 'px-4' : ''}`}>{t.label}</div>
            <span
              className={`rounded-full 
              ${isHorizontal ? 'h-[2px] w-full' : 'w-[4px] self-stretch'}
              ${isTabActive ? 'bg-primary-01' : 'bg-transparent'}`}
            />
          </button>
        );
      })}
    </div>
  );
};
