import { ReactNode } from 'react';

export interface FormProps {
  id?: string;
  children?: ReactNode;
  className?: string;
  onSubmit?: () => void;
}

export const Form = ({ id, children, className, onSubmit }: FormProps) => {
  return (
    <form
      id={id}
      className={className}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit && onSubmit();
      }}
      noValidate
    >
      {children}
    </form>
  );
};
