import { useTranslation } from 'react-i18next';

export enum ErrorCodes {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
}

type ErrorMessageCellProps = {
  error: string;
};

const ErrorMessageCell: React.FC<ErrorMessageCellProps> = ({ error }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organization.members-tab.bulk-add-to-journey-error-modal.table.rows',
  });
  const getErrorText = (error: string) => {
    if (error === ErrorCodes.ALREADY_EXISTS) {
      return t('already-exists');
    }

    return t('default');
  };

  return <span className="text-sm">{getErrorText(error)}</span>;
};

export default ErrorMessageCell;
