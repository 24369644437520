type Props = {
  rounded: boolean;
  source: string;
  alt: string;
  imageClassName?: string;
};

/**
 * Interesting improvements for later
 * - make object-position configurable
 * - make object-fit configurable
 * - use srcset for different image sizes
 */

export const Picture = ({ rounded = false, source, alt, imageClassName = '' }: Props) => (
  <div className={`${rounded ? 'rounded-xl' : ''}  overflow-hidden`}>
    <img
      className={`${imageClassName} w-full object-cover object-center`}
      src={source}
      alt={alt}
    />
  </div>
);
