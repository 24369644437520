import { destructureSeconds } from '@stellar-lms-frontend/common-utils';
import { InsightCard, Section } from '@stellar-lms-frontend/lms-components';
import {
  ChartPieFilledIcon,
  ClockFilledIcon,
  UserFilledIcon,
} from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';

export type LearnerInsightsStats = {
  percentageCompleted: number;
  timeSpentInS: number;
  totalNumberOfActions: number;
  completedActions: number;
};

export type LearnerInsightsSectionProps = {
  stats: LearnerInsightsStats;
};

export const LearnerInsightsSection: React.FC<LearnerInsightsSectionProps> = ({ stats }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'learner-course-dashboard.insights' });

  const { hours, minutes } = destructureSeconds(stats.timeSpentInS);
  return (
    <Section
      data-cy={'insights-section'}
      title={t('title')}
    >
      <div className="flex flex-col flex-wrap gap-4 md:flex-row">
        <InsightCard
          icon={ChartPieFilledIcon}
          title={`${Math.round(stats.percentageCompleted)}%`}
          subtitle={t('completed')}
          data-cy="insights-section-0"
        />
        <InsightCard
          icon={ClockFilledIcon}
          title={`
            ${hours > 0 ? t('hours', { count: hours }) : ''}
            ${t('minutes', { count: minutes })}
          `}
          subtitle={t('spent-learning')}
          data-cy="insights-section-1"
        />
        <InsightCard
          icon={UserFilledIcon}
          title={`${stats.completedActions} / ${stats.totalNumberOfActions} ${t('actions')}`}
          subtitle={t('completed')}
          data-cy="insights-section-2"
        />
      </div>
    </Section>
  );
};
