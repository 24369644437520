import { useScreenType } from '@stellar-lms-frontend/common-utils';
import { ButtonConfig, ScrollContainer } from '@stellar-lms-frontend/ui-components';
import { ReactElement, ReactNode } from 'react';
import { UIShell } from './ui-shell';

export type SplitViewProps = {
  detailView: ReactElement;
  overview: ReactNode;
  detailSelected: boolean;
  navHeaderTitle?: ReactNode;
  leftButton?: ButtonConfig;
};
export const SplitView = ({
  detailView,
  overview,
  detailSelected,
  navHeaderTitle,
  leftButton,
}: SplitViewProps) => {
  const { isDesktop } = useScreenType();

  return isDesktop ? (
    <UIShell
      autoScrollTop={false}
      navHeaderTitle={navHeaderTitle}
      leftButton={leftButton}
      useContentContainer={false}
      leftSideBar={
        <div className="border-r-1 border-border-01 h-full shrink-0">
          <div
            className="mt-16 ml-14 hidden w-[407px] self-start lg:block"
            data-test-id="course-navigation"
          >
            {overview}
          </div>
        </div>
      }
    >
      {detailView}
    </UIShell>
  ) : detailSelected ? (
    <UIShell
      navHeaderTitle={navHeaderTitle}
      showBottomNav={false}
      useContentContainer={false}
    >
      {detailView}
    </UIShell>
  ) : (
    <UIShell
      navHeaderTitle={navHeaderTitle}
      useContentContainer={false}
    >
      <ScrollContainer>
        <div className="pl-8 lg:p-16">{overview}</div>
      </ScrollContainer>
    </UIShell>
  );
};
