import { LeftArrowIcon } from '@stellar-lms-frontend/ui-components';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OrganizationPage, SearchItem } from '@stellar-lms-frontend/lms-components';
import { graphQLClient } from '../../lib/graphql';
import {
  DEFAULT_LANGUAGE,
  supportedLanguages,
  upperSupportedLanguage,
} from '../../constants/languages';
import { useCurrentCompany } from '@stellar-lms-frontend/lms-graphql';
import { UIShell } from '../shell/ui-shell';
import { useQuery } from '@tanstack/react-query';
import { getJourneys } from './organization-overview.api';
import { Stripe } from '@stripe/stripe-js';

export const OrganizationOverview = ({
  stripePromise,
}: {
  stripePromise: Promise<Stripe | null>;
}) => {
  const client = graphQLClient();
  const { t } = useTranslation('translation', { keyPrefix: 'organization' });
  const { t: tLanguages } = useTranslation('translation', { keyPrefix: 'languages' });
  const navigate = useNavigate();

  const {
    query: { data: company },
  } = useCurrentCompany(graphQLClient('learner'));

  const { data: searchableCourses } = useQuery(
    ['ALL_JOURNEYS'],
    () => (company ? getJourneys(graphQLClient('learner'), { companyId: company?.id }) : null),
    {
      enabled: !!company?.id,
      select: (data) => {
        if (data?.journeys) {
          const searchableCourses: SearchItem[] = [];
          data.journeys.forEach((journey) => {
            if (journey?.id && journey?.title) {
              searchableCourses.push({ id: journey.id, label: journey.title });
            }
          });
          return searchableCourses;
        }
        return [];
      },
    }
  );

  return (
    <UIShell
      subNavTitle={t('title')}
      subNavSubtitle={t('subtitle')}
      scrollOnDesktop={true}
      leftButton={{
        action: () => navigate(-1),
        leftIcon: <LeftArrowIcon className="fill-on-primary-01 h-5 w-5" />,
      }}
      useContentContainer={false}
    >
      <OrganizationPage
        stripePromise={stripePromise}
        supportedLanguages={supportedLanguages.map<{
          label: string;
          value: string;
        }>((l) => ({
          label: tLanguages(upperSupportedLanguage(l)),
          value: l.toLowerCase(),
        }))}
        defaultLanguage={company?.language ?? DEFAULT_LANGUAGE.toLowerCase()}
        graphQLClient={client}
        searchableCourses={searchableCourses ?? []}
      />
    </UIShell>
  );
};
