import { courseData } from './course-data';
import { HomePage } from '../../home-page';
import { useCurrentUser, useCurrentCompany, Types } from '@stellar-lms-frontend/lms-graphql';
import { defaultGraphqlClient, unimplementedActionError } from '@stellar-lms-frontend/common-utils';
import { useListFilteringOptions } from '@stellar-lms-frontend/ui-components';

// This page is not internationalized because it is blurred
export const BlurHomePage = () => {
  const {
    query: { data: currentUser },
  } = useCurrentUser(defaultGraphqlClient);

  const {
    query: { data: currentCompany },
  } = useCurrentCompany(defaultGraphqlClient);

  // doesn't matter, but easier doing this than making piece of the param optional and dealing with all the null checks
  const filterOptions = useListFilteringOptions<Types.CourseDashboardListItem>({});

  return (
    <div className="blur-md">
      <HomePage
        graphQLClient={defaultGraphqlClient}
        courseList={{ list: courseData, filterOptions }}
        defaultLanguage="EN"
        supportedLanguages={[]}
        currentUser={currentUser ?? undefined}
        currentCompany={currentCompany ?? undefined}
        actions={{
          logout: unimplementedActionError,
          completeOnboarding: unimplementedActionError,
          deleteCourse: unimplementedActionError,
          duplicateCourse: unimplementedActionError,
          unreadNotificationsCount: () => 0,
          createScormPackage: unimplementedActionError,
          getScormPackage: unimplementedActionError,
          handleNotificationClick: unimplementedActionError,
          fetchNotifications: unimplementedActionError,
          markTipAsViewed: unimplementedActionError,
        }}
      />
    </div>
  );
};
