import { ModuleList } from '@stellar-lms-frontend/lms-components';
import {
  Course,
  CourseStats,
  useCurrentCompany,
  useCurrentUser,
} from '@stellar-lms-frontend/lms-graphql';
import { LogoLoader } from '@stellar-lms-frontend/ui-components';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { getMentorJourney } from './mentor-journey.api';
import { COURSE } from '../../constants/query';
import { UIShell } from '../shell/ui-shell';
import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';

export const MentorJourneyPage = () => {
  const { courseId = '' } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: 'course-overview' });
  const { t: tModuleOverview } = useTranslation('translation', { keyPrefix: 'module-overview' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const [searchParams, setSearchParams] = useSearchParams();

  const [courseOverviewState, setCourseOverviewState] = useState({
    id: '',
    highlightedModule: searchParams.get('moduleId'),
  });

  // Replace by GraphQL

  const { data: course } = useQuery<Course | undefined>(
    [COURSE, courseId],
    () => (courseId ? getMentorJourney({ id: courseId }) : undefined),
    {
      onSuccess: (courseData) => {
        if (courseData && courseId && courseOverviewState.id !== courseId) {
          setCourseOverviewState({
            id: courseId,
            highlightedModule: '',
          });
          setSearchParams({ moduleId: '' });
        }
      },
    }
  );

  const {
    query: { data: currentCompany },
  } = useCurrentCompany(defaultGraphqlClient);

  const {
    query: { data: currentUser },
  } = useCurrentUser(defaultGraphqlClient);

  return (
    <UIShell
      autoScrollTop={false}
      useContentContainer={false}
      scrollOnDesktop={true}
    >
      {course && course.outline ? (
        <ModuleList.ModuleList
          userRole="mentor"
          initialModules={course.outline.modules ?? []}
          totalLearners={(course.courseStats as CourseStats)?.numberOfStudents ?? 0}
          courseId={courseId}
          currentCompanyId={currentCompany?.id ?? undefined}
          currentUserId={currentUser?.id ?? undefined}
          i18n={{
            learningActivityList: {
              activityAvailableOnTooltip: (date: string) =>
                tModuleOverview('activity-available-on', { date: date }),
              activityAvailableWhenTooltip: (count: number, prerequisites: string) =>
                tModuleOverview('activity-available-when', {
                  count: count,
                  prerequisites: prerequisites,
                }),
              lockedActivityText: tModuleOverview('locked-activity'),
              activityCommentCount: (count: number) =>
                tModuleOverview('activity-comment-count', { count: count }),
              emptyStateMentorLearnerMessage: t('empty-learning-activities'),
              aiBadgeLabel: tGeneral('ai-badge-label'),
              descriptionSection: {
                duration: {
                  timeHourShort: tGeneral('time-hour-short'),
                  timeMinuteShort: tGeneral('time-minute-short'),
                  hour: (count) => tGeneral('hour', { count }),
                  min: (count) => tGeneral('min', { count }),
                },
              },
            },
          }}
        />
      ) : (
        <LogoLoader />
      )}
    </UIShell>
  );
};
