import moment from 'moment';

// Not ideal but it need to be shared between two components in lms-components
export interface LearningActivityPrerequisite {
  mandatory?: boolean | null;
  module?: {
    id: string;
    title?: string | null;
  } | null;
}

type LearningActivity = {
  id: string;
  title?: string | null;
  locked?: boolean | null;
  prerequisites?: LearningActivityPrerequisite[] | null;
  dateOpen?: Date | string;
};

type Module = {
  learningActivities?: LearningActivity[] | null;
};

export const getAdjacentLearningActivities = (modules: Module[], learningActivityId: string) => {
  const flatLAs = modules.flatMap((module) => module.learningActivities) ?? [];
  const laIndex = flatLAs.findIndex((la) => la?.id === learningActivityId);

  let prevLearningActivity, nextLearningActivity;

  if (laIndex > 0) {
    prevLearningActivity = flatLAs[laIndex - 1];
  }

  if (laIndex < flatLAs.length - 1) {
    nextLearningActivity = flatLAs[laIndex + 1];
  }

  return { nextLearningActivity, prevLearningActivity };
};

const MAX_TOOLTIP_CHARS = 60;

export const getTooltipForLockedLearningActivity = (
  learningActivity: LearningActivity,
  activityAvailableWhenTooltip: (length: number, mandatoryPrereqs: string) => string,
  activityAvailableOnTooltip: (date: string) => string
) => {
  let tooltipText = '';

  if (learningActivity.locked) {
    const mandatoryPrerequisites =
      learningActivity.prerequisites?.filter((req) => req.mandatory) ?? [];
    if (mandatoryPrerequisites.length > 0) {
      let mandatoryPrereqs = mandatoryPrerequisites
        .map((prereq) => prereq.module?.title ?? '')
        .join(', ');

      if (mandatoryPrereqs.length > MAX_TOOLTIP_CHARS) {
        mandatoryPrereqs = mandatoryPrereqs.slice(0, MAX_TOOLTIP_CHARS) + '...';
      }

      tooltipText = activityAvailableWhenTooltip(mandatoryPrerequisites.length, mandatoryPrereqs);
    } else if (learningActivity.dateOpen) {
      tooltipText = activityAvailableOnTooltip(
        moment(learningActivity.dateOpen).format('MMM Do, HH:mm')
      );
    }
  }

  return tooltipText;
};
