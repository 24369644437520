import { Badge } from '@stellar-lms-frontend/ui-components';
import * as CourseBox from './course-box';
import { createMentorCourseHref, getColorDefinitions } from '../functions';
import { BoxColor } from '../types';
import { useTranslation } from 'react-i18next';
import { Types } from '@stellar-lms-frontend/lms-graphql';

type MentorCourseBlockProps = {
  color: BoxColor;
  course: Types.MentorCourse;
};
export const MentorCourseBlock = ({ course, color }: MentorCourseBlockProps) => {
  const { t: tRoles } = useTranslation('translation', { keyPrefix: 'roles' });
  // Might be very interesting to do something with css variables instead
  const colorDef = getColorDefinitions(color);

  return (
    <CourseBox.Container onClick={() => (window.location.href = createMentorCourseHref(course.id))}>
      <CourseBox.Header colorDef={colorDef}>
        <Badge className={`mb-4 max-w-fit ${colorDef.progressBGColorClassName}`}>
          {tRoles('mentor')}
        </Badge>
        <CourseBox.Title
          title={course.title}
          colorDef={colorDef}
        />
      </CourseBox.Header>
    </CourseBox.Container>
  );
};
