import {
  TargetIllustration,
  Comment2AlertIcon,
  HintBox,
  LinkButton,
  Modal,
  PrimaryButton,
  SearchableSelect,
  SearchableSelectOptionType,
  TextArea,
} from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Objective, createObjective, searchObjectives } from '@stellar-lms-frontend/lms-api';
import { COURSES, DESIGNER, OBJECTIVES } from '../../constants/query-constants';
import { graphQLClient } from '../../../../lib/graphql';
import { useCurrentCompany } from '@stellar-lms-frontend/lms-graphql';

export type ObjectiveModalProps = {
  isOpen: boolean;
  onClose: VoidFunc;
  onChange: (objective: Objective) => void;
};

type ModalSteps = 'select' | 'create';
const MAX_OBJECTIVE_LENGTH = 120;

export const ObjectiveModal: React.FC<ObjectiveModalProps> = ({ isOpen, onClose, onChange }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'course-details.objectives' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const [step, setStep] = useState<ModalSteps>('select');
  const [searchQuery, setSearchQuery] = useState('');

  const {
    query: { data: company },
  } = useCurrentCompany(graphQLClient('learner'));

  const [selected, setSelected] = useState<SearchableSelectOptionType | undefined>(undefined);
  const queryClient = useQueryClient();

  const isObjectivesQueryEnabled = !!company?.id && isOpen;
  const objectivesQuery = useQuery(
    [OBJECTIVES, { searchQuery }],
    () =>
      isObjectivesQueryEnabled
        ? searchObjectives(graphQLClient('designer'), {
            companyId: company.id,
            query: searchQuery,
            limit: 10,
          })
        : null,
    { enabled: isObjectivesQueryEnabled }
  );

  const { mutate: createObjectiveMutation } = useMutation(
    createObjective(graphQLClient('designer')),
    {
      onSuccess: ({ createObjective }) => {
        if (createObjective) {
          onChange(createObjective);
          queryClient.invalidateQueries([DESIGNER, COURSES]);
        }
      },
    }
  );

  const beforeClose = useCallback(() => {
    setSearchQuery('');
    setSelected(undefined);
    setSearchQuery('');
    setStep('select');
    onClose();
  }, [setSearchQuery, setSelected, onClose]);

  const onCreate = useCallback(() => {
    if (company) {
      createObjectiveMutation({
        companyId: company.id,
        name: searchQuery,
      });
      beforeClose();
    }
  }, [createObjectiveMutation, beforeClose, searchQuery, company]);

  const config = useMemo(() => {
    switch (step) {
      case 'select':
        return {
          title: t('modal.select-title'),
          children: (
            <div className="h-[424px]">
              <p className="text-text-02 type-body-default mb-6">{t('explanation')}</p>
              <SearchableSelect
                options={objectivesQuery?.data?.map((o) => ({
                  id: o.id,
                  label: o.name,
                }))}
                onSelect={(selected) => {
                  if (selected.id) {
                    setSelected(selected);
                  } else {
                    if (searchQuery.length > MAX_OBJECTIVE_LENGTH) {
                      setSearchQuery(searchQuery.substring(0, MAX_OBJECTIVE_LENGTH));
                    }
                    setStep('create');
                  }
                }}
                isOpen
                onChangeSearch={(search) => setSearchQuery(search)}
                selected={selected}
                inputClassName={'px-6'}
                regularOptionClassName={'px-10 text-text-01'}
                addOptionClassName={'px-10 text-primary-01'}
                selectType="add"
                addText={t('modal.create-new')}
              />
            </div>
          ),
          buttons: {
            hasShadow: true,
            buttons: [
              <PrimaryButton
                key={1}
                label={tGeneral('select')}
                enabled={!!selected}
                onClick={() => {
                  if (selected) {
                    onChange({ id: selected.id, name: selected.label });
                    beforeClose();
                  }
                }}
              />,
              <LinkButton
                key={2}
                buttonStyle="fit"
                onClick={beforeClose}
                label={tGeneral('cancel')}
              />,
            ],
          },
        };
      case 'create':
        return {
          title: t('modal.create-title'),
          children: (
            <div className="flex flex-col items-center gap-8">
              <TargetIllustration />
              <p className="text-text-02 type-body-default">{t('explanation')}</p>
              <TextArea
                htmlId={'text-area-objective-label'}
                heightClassName="max-h-[96px]"
                text={searchQuery}
                onChange={({ target: { value } }) => setSearchQuery(value)}
                length={searchQuery.length}
                maxLength={MAX_OBJECTIVE_LENGTH}
              />
              <HintBox
                className="w-full"
                icon={<Comment2AlertIcon className="text-primary-02" />}
                title={t('modal.hints.title')}
                isOpen
                theme={'blue'}
              >
                <ul className="text-text-01 text-sm">
                  <li>{t('modal.hints.1')}</li>
                  <li>{t('modal.hints.2')}</li>
                  <li>{t('modal.hints.3')}</li>
                  <li>{t('modal.hints.4')}</li>
                </ul>
              </HintBox>
            </div>
          ),
          buttons: {
            hasShadow: false,
            buttons: [
              <PrimaryButton
                key={1}
                label={t('modal.save-select')}
                enabled={!!searchQuery}
                onClick={() => searchQuery && onCreate()}
              />,
              <LinkButton
                buttonStyle="fit"
                key={2}
                onClick={beforeClose}
                label={tGeneral('cancel')}
              />,
            ],
          },
        };
    }
  }, [step, t, tGeneral, selected, onChange, beforeClose, objectivesQuery, searchQuery, onCreate]);

  return (
    <Modal
      onClickBack={
        step === 'create'
          ? () => {
              setStep('select');
              setSearchQuery('');
            }
          : undefined
      }
      isOpen={isOpen}
      title={config.title}
      onClose={beforeClose}
      buttons={config.buttons}
      className="bg-surface-01 h-full md:h-auto md:w-[720px]"
    >
      {config.children}
    </Modal>
  );
};
