import { useQuery } from '@tanstack/react-query';
import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';
import { graphql } from '@stellar-lms-frontend/lms-graphql';
import { StackedHalfRadialChart } from '@stellar-lms-frontend/ui-components';

const getSRQLevelDistribution = async (companyId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query GetCompanySRQLevelDistribution($companyId: ID!) {
        companySRQLevelDistribution(companyId: $companyId) {
          rows {
            category
            count
          }
        }
      }
    `),
    { companyId: companyId }
  );
};
const chartConfig = {
  '1': {
    label: 'Level 1',
    color: 'var(--chart-1)',
  },
  2: {
    label: 'Level 2',
    color: 'var(--chart-2)',
  },
  3: {
    label: 'Level 3',
    color: 'var(--chart-3)',
  },
  4: {
    label: 'Level 4',
    color: 'var(--chart-4)',
  },
  5: {
    label: 'Level 5',
    color: 'var(--chart-5)',
  },
  6: {
    label: 'Level 6',
    color: 'var(--chart-6)',
  },
};

export const SRQLevelDistributionChart = ({ companyId }: { companyId: string }) => {
  const { data, isLoading } = useQuery(
    ['srq-level-distribution', companyId],
    () => getSRQLevelDistribution(companyId),
    { refetchOnWindowFocus: false }
  );

  return (
    <StackedHalfRadialChart
      isLoading={isLoading}
      rows={data?.companySRQLevelDistribution.rows}
      chartConfig={chartConfig}
      showValueInBar
    />
  );
};
