import { CourseModuleOutline, LearningActivityOutline } from '@stellar-lms-frontend/lms-graphql';
import { Dispatch, SetStateAction } from 'react';
import {
  LearningActivityActions,
  LearningActivityList,
  LearningActivityListI18N,
} from '../learning-activity-list';
import { ModuleHeader, ModuleHeaderActions } from '../module-header';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DropContainer } from './sort/drop-container';
import { MoveLearningActivityFunction } from './sort';

export type Actions = ModuleHeaderActions & LearningActivityActions;

export type I18N = {
  learningActivityList: LearningActivityListI18N;
};
export type ModuleSectionProps = {
  module: CourseModuleOutline;
  learningActivities: LearningActivityOutline[];
  setRef?: (element: HTMLDivElement) => void;
  totalLearners: number;
  i18n: I18N;
  actions?: Actions;
  isEditing?: boolean;
  setIsEditing?: Dispatch<SetStateAction<boolean>>;
  courseId: string;
  showSeparator?: boolean;
  moveUp: MoveLearningActivityFunction;
  moveDown: MoveLearningActivityFunction;
};
export const ModuleSection: React.FC<ModuleSectionProps> = ({
  module,
  learningActivities,
  setRef,
  totalLearners,
  i18n,
  isEditing = false,
  setIsEditing,
  courseId,
  actions,
  showSeparator = true,
  moveUp,
  moveDown,
}) => {
  return (
    <div
      id={module.id}
      className="w-full"
      data-cy="module"
      ref={setRef}
    >
      <SortableContext
        id={module.id}
        items={learningActivities}
        strategy={verticalListSortingStrategy}
      >
        <DropContainer containerId={module.id}>
          <div className="w-full space-y-6">
            <ModuleHeader
              totalLearners={totalLearners}
              module={{
                title: module.title ?? '',
                description: module.description ?? '',
                stats: module.stats,
                id: module.id,
              }}
              courseId={courseId}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              actions={actions}
            />
            <LearningActivityList
              learningActivities={learningActivities}
              moduleId={module.id}
              actions={actions}
              i18n={i18n.learningActivityList}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              moveUp={moveUp}
              moveDown={moveDown}
            />
            {isEditing && showSeparator && <div className="bg-border-01 h-[1px] w-full"></div>}
          </div>
        </DropContainer>
      </SortableContext>
    </div>
  );
};
