import * as ReactDOM from 'react-dom/client';

import App from './app/app';
import '@stellar-lms-frontend/common-utils';
import { QueryClientProvider } from '@tanstack/react-query';
import { Suspense } from 'react';
import { LogoLoader } from '@stellar-lms-frontend/ui-components';
import {
  getEnvironment,
  setupSentry,
  StellarQueryClient,
} from '@stellar-lms-frontend/common-utils';
import { environment } from './environments/environment';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const { isProduction, isStaging } = getEnvironment();
setupSentry(isProduction ? 'production' : isStaging ? 'staging' : 'local', environment.sentry_dsn);

// The queryclient for react-query
const queryClient = StellarQueryClient();

root.render(
  <Suspense fallback={<LogoLoader />}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Suspense>
);
