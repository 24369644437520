import { DISCUSSION_PAGE_SIZE } from '@stellar-lms-frontend/lms-api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useLayoutEffect, useRef } from 'react';
import { Notification } from './types';

const QUERY_KEY = 'notifications';

export const useNotifications = (
  fetchNotificationFunc: (pageParam: Date) => Promise<Notification[]>
) => {
  const endOfPageRef = useRef<HTMLDivElement>(null);

  const query = useInfiniteQuery([QUERY_KEY], ({ pageParam }) => fetchNotificationFunc(pageParam), {
    getNextPageParam: (lastPage) => {
      if (lastPage.length < DISCUSSION_PAGE_SIZE) {
        return undefined;
      }
      return lastPage.at(-1)?.date;
    },
  });

  const handleObserver = () => {
    query.fetchNextPage();
  };

  const observer = useRef(
    new IntersectionObserver(handleObserver, {
      rootMargin: '0px',
    })
  );

  useLayoutEffect(() => {
    if (endOfPageRef.current) {
      observer.current.observe(endOfPageRef.current);
    }
  });

  const hasNotifications =
    query.data && query.data.pages.length > 0 && query.data.pages[0].length > 0;

  return {
    query,
    hasNotifications,
    endOfPageRef,
  };
};
