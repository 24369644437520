import React from 'react';
import { ContentEditable } from '../../components/content-editable';
import { WarningData } from './warning-plugin';
import { AlertTriangle } from '../../../icons';
import { API } from '@editorjs/editorjs';
import './warning-component.css';

interface WarningComponentProps {
  data: WarningData;
  onChange: (newData: WarningData) => void;
  isReadOnly: boolean;
  className?: string;
  api: API;
}

export const WarningComponent: React.FC<WarningComponentProps> = ({
  data,
  onChange,
  isReadOnly,
  className = '',
  api,
}) => {
  return (
    <div className="font-lexend bg-warning-03 my-4 flex items-start gap-6 rounded-xl p-6">
      <AlertTriangle className="text-warning-01 h-6 w-6 shrink-0" />
      <div className="grow overflow-hidden">
        <ContentEditable
          onChange={(value) => {
            onChange({ ...data, title: value });
          }}
          value={data.title}
          contentEditable={!isReadOnly}
          placeholder={api.i18n.t('title-placeholder')}
          className={` text-text-01 text-xl outline-none`}
        />
        <ContentEditable
          onChange={(value) => {
            onChange({
              ...data,
              text: value,
            });
          }}
          value={data.text}
          contentEditable={!isReadOnly}
          className={`${className} text-text-04 mt-1 w-full overflow-hidden break-words outline-none`}
        />
      </div>
    </div>
  );
};
