import { useEventListener } from '@stellar-lms-frontend/common-utils';
import BaseButton, { BaseButtonProps } from '../base-button/base-button';

export interface PrimaryButtonProps extends BaseButtonProps {
  enterListener?: boolean;
  theme?: 'red' | 'blue' | 'dark';
}
const INPUT_TYPES = ['input', 'select', 'textarea'];

export function PrimaryButton({
  enterListener = true,
  theme = 'blue',
  className = '',
  ...baseProps
}: PrimaryButtonProps) {
  const handleEnterClick = (event: KeyboardEvent) => {
    const activeElement = document.activeElement;
    if (
      activeElement &&
      INPUT_TYPES.indexOf(activeElement.tagName.toLowerCase()) === -1 &&
      event.key === 'Enter' &&
      baseProps.onClick
    ) {
      baseProps.onClick();
    }
  };

  useEventListener('keyup', handleEnterClick, enterListener);

  return (
    <BaseButton
      {...baseProps}
      className={`${className} rounded-xl border-0 shadow-sm focus:outline focus:outline-4 focus:outline-offset-2  disabled:from-gray-200 disabled:to-gray-200 disabled:text-gray-400 disabled:shadow-none
      ${
        theme === 'blue'
          ? 'text-on-primary-01 outline-on-primary-02 from-primary-02 hover:from-primary-02 hover:to-primary-02 focus:from-primary-02 focus:to-primary-02 bg-gradient-to-r to-blue-500  '
          : ''
      }
      ${
        theme === 'red'
          ? 'text-on-primary-01 outline-on-primary-02 from-negative-01 hover:from-negative-01 hover:to-negative-01 focus:from-negative-01 focus:to-negative-01 bg-gradient-to-r to-red-500 '
          : ''
      }
      ${
        theme === 'dark'
          ? 'bg-surface-01 text-text-01 hover:text-text-02 active:text-text-02 focus:outline-2 focus:outline-offset-0 focus:outline-blue-400 active:outline-2 active:outline-offset-0 active:outline-blue-400'
          : ''
      }
      `}
    />
  );
}

export default PrimaryButton;
