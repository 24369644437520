import { LearnersTable } from '@stellar-lms-frontend/lms-components';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { hasAnyPermission } from '@stellar-lms-frontend/common-utils';
import { useCurrentCompany } from '@stellar-lms-frontend/lms-graphql';
import { graphQLClient } from '../../../lib/graphql';
import { UIShell } from '../../shell/ui-shell';
import {
  DEFAULT_LANGUAGE,
  supportedLanguages,
  upperSupportedLanguage,
} from '../../../constants/languages';

export const DesignerLearnersTable: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'learners-table' });
  const { t: tTable } = useTranslation('translation', { keyPrefix: 'table' });
  const { t: tLanguages } = useTranslation('translation', { keyPrefix: 'languages' });

  const { courseId = '' } = useParams();
  const {
    query: { data: company },
  } = useCurrentCompany(graphQLClient('designer'));

  return (
    <UIShell>
      <LearnersTable
        hasEditPermission={hasAnyPermission(company?.permissions, [
          'update',
          'member_management',
          'create_course',
        ])}
        graphQLClient={graphQLClient('designer')}
        courseId={courseId}
        supportedLanguages={supportedLanguages.map<{
          label: string;
          value: string;
        }>((l) => ({
          label: tLanguages(upperSupportedLanguage(l)),
          value: l.toLowerCase(),
        }))}
        defaultLanguage={company?.language ?? DEFAULT_LANGUAGE.toLowerCase()}
        i18n={{
          table: {
            pageSizeSelector: {
              showing: tTable('page-size-selector.showing'),
              of: (count: number) => tTable('page-size-selector.of', { count }),
            },
          },
          header: {
            name: t('name'),
            access: t('access'),
            // lastActivity: t('last-activity'),
            mentor: t('mentor'),
            progress: t('progress'),
          },
          tableActions: {
            add: t('tableActions.add'),
          },
          removeUserFromJourneyModal: {
            title: t('remove-user-from-journey-modal.title'),
            description: t('remove-user-from-journey-modal.description'),
            remove: t('remove-user-from-journey-modal.remove'),
            cancel: t('remove-user-from-journey-modal.cancel'),
          },
          editLearnerSidebar: {
            forms: {
              access: {
                label: t('edit-learner-sidebar.forms.access.label'),
                options: {
                  author: t('edit-learner-sidebar.forms.access.options.author'),
                  collaborator: t('edit-learner-sidebar.forms.access.options.collaborator'),
                  learner: t('edit-learner-sidebar.forms.access.options.learner'),
                },
              },
              mentor: {
                label: t('edit-learner-sidebar.forms.mentor.label'),
                emptyLabel: t('add-to-journey-modal.fields.mentor.empty-label'),
              },
            },
            buttons: {
              save: t('edit-learner-sidebar.buttons.save'),
              cancel: t('edit-learner-sidebar.buttons.cancel'),
            },
            menu: {
              delete: t('edit-learner-sidebar.menu.delete'),
            },
          },
          accessCell: {
            author: t('access-cell.author'),
            collaborator: t('access-cell.collaborator'),
            learner: t('access-cell.learner'),
            mentor: t('access-cell.mentor'),
          },
          toasts: {
            addMember: t('toasts.add-member'),
            deleteMember: t('toasts.delete-member'),
            updateMember: t('toasts.update-member'),
          },
          empty: {
            title: t('empty.title'),
            description: t('empty.description'),
          },
          optionsMenu: {
            edit: t('options-menu.edit'),
            delete: t('options-menu.delete'),
          },
        }}
      />
    </UIShell>
  );
};
