import { isMatch, useNavigateExternal } from '@stellar-lms-frontend/common-utils';
import { HeaderNavTheme, ListUnorderedIcon, NavButton } from '@stellar-lms-frontend/ui-components';

export const JourneyNavButton = ({
  theme,
  title,
  url,
}: {
  theme: HeaderNavTheme;
  title: string;
  url: string;
}) => {
  const { navigateExternal } = useNavigateExternal();
  return (
    <NavButton
      theme={theme}
      navEntry={{
        title: title,
        filledIcon: [ListUnorderedIcon],
        icon: [ListUnorderedIcon],
        action: () => navigateExternal(url),
        isActive: isMatch(url),
      }}
      type={'icon-text'}
    />
  );
};
