export const hasIntersection = <T>(a: T[], b: T[]): boolean => {
  return a.filter((aEntry) => b.some((bEntry) => aEntry === bEntry)).length > 0;
};

export const conditionalArrayItem = <T>(condition: boolean, item: T): T[] => {
  return condition ? [item] : [];
};

export const getNumbersArray = (initial: number, nItems: number, interval: number): number[] => {
  return Array.from({ length: nItems }, (_, i) => initial + i * interval);
};

export function swapElements<T>(arr: T[], origin: number, destination: number): T[] {
  if (origin >= arr.length || destination >= arr.length || origin < 0 || destination < 0) {
    return arr;
  }

  const localArr = [...arr];

  const temp = localArr[origin];
  localArr[origin] = localArr[destination];
  localArr[destination] = temp;
  return localArr;
}
