import {
  ArrowRightCircleIcon,
  CheckCircle2Icon,
  ChevronsDownIcon,
  ContentWrapper,
  EvaluatedSelect,
  SecondaryButton,
  SelectOptionAnswer,
  SelectOptionType,
  SingleSelect,
} from '@stellar-lms-frontend/ui-components';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type ScenarioViewLevel = {
  id: string;
  description: string;
  options: SelectOptionType[];
  answers?: SelectOptionAnswer[];
  isSelectCollapsed?: boolean;
  feedback?: string;
};

export type ScenarioLevelViewProps = {
  levelNumber: number;
  value: ScenarioViewLevel;
  onSubmit: () => void;
  onContinue: () => void;
};

export const ScenarioLevelView: React.FC<ScenarioLevelViewProps> = ({
  levelNumber,
  value,
  onSubmit,
  onContinue,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'scenario-step-view' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const { control, watch } = useFormContext();

  const fieldValue = watch(value.id);

  const isEvaluated = !!value.answers;

  return (
    <ContentWrapper className="font-lexend mx-auto space-y-6 pt-4 pb-6">
      <div className="space-y-4">
        <p className="type-body-medium text-text-02">{t('level.title', { count: levelNumber })}</p>
        <p className="type-body text-text-04">{value.description}</p>
        {value.answers ? (
          <div className="space-y-4">
            <EvaluatedSelect
              answers={value.answers}
              isCollapsed={value.isSelectCollapsed}
            />
            {value.feedback && (
              <div className="flex items-start gap-2">
                <CheckCircle2Icon className="text-positive-02 shrink-0" />
                <p className="type-body-emphasis text-text-02">{value.feedback}</p>
              </div>
            )}
          </div>
        ) : (
          <Controller
            control={control}
            name={value.id}
            render={({ field }) => (
              <SingleSelect
                options={value.options}
                isDisabled={false}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
        )}
      </div>
      {!value.isSelectCollapsed && (
        <SecondaryButton
          leftIcon={isEvaluated ? <ChevronsDownIcon /> : <ArrowRightCircleIcon />}
          enabled={fieldValue || isEvaluated}
          label={isEvaluated ? tGeneral('submit') : tGeneral('continue')}
          onClick={isEvaluated ? onContinue : onSubmit}
        />
      )}
    </ContentWrapper>
  );
};
