import { Link, To } from 'react-router-dom';
import { UsersList } from '../users';
import { Section } from '../section';

export type ListViewAllProps = {
  text: string;
  target: To;
};

export type LearnersListProps = {
  title: string;
  learners: { id: string; name: string; thumbUri: string }[];
  viewAll?: ListViewAllProps;
  emptyMessage?: string;
  dataCy?: string;
};
//CLEANUP Move this to UsersList
export const LearnersList: React.FC<LearnersListProps> = ({
  title,
  learners,
  viewAll,
  emptyMessage,
  dataCy = '',
}) => {
  return (
    <Section
      title={title}
      data-cy={dataCy}
    >
      {learners.length > 0 ? (
        <>
          <UsersList users={learners} />
          {viewAll && (
            <Link
              data-cy={`${dataCy}-view-all`}
              to={viewAll.target}
              className="text-primary-01 underline"
            >
              {viewAll.text}
            </Link>
          )}
        </>
      ) : (
        <p className="text-text-02 text-sm">{emptyMessage}</p>
      )}
    </Section>
  );
};
