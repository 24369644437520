import { useEventListener } from '@stellar-lms-frontend/common-utils';

export interface KeyBoardShortcutProps {
  index: number;
  className?: string;
  action: (shouldTrigger: boolean) => void;
}

export const KeyBoardShortcut: React.FC<KeyBoardShortcutProps> = ({ index, className, action }) => {
  const handleKeyUp = (event: KeyboardEvent) => {
    action(event.key.toLowerCase().charCodeAt(0) % 'a'.charCodeAt(0) === index);
  };

  useEventListener('keyup', handleKeyUp, true);

  return (
    <div
      className={`${
        className ?? ''
      } border-1 border-text-02 shadow-text-02 shadow-shortcut hidden h-6 w-6 flex-col items-center justify-center rounded xl:flex`}
    >
      {String.fromCharCode('A'.charCodeAt(0) + index)}
    </div>
  );
};

export default KeyBoardShortcut;
