import React from 'react';
import { ReactNode } from 'react';

export type ClickableSelectCardProps = {
  className: string;
  children: ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const ClickableSelectCard = React.forwardRef<HTMLButtonElement, ClickableSelectCardProps>(
  ({ className, children, onClick }, ref) => {
    return (
      <button
        onClick={onClick}
        className={`bg-surface-02 flex min-h-[56px] w-full items-center rounded-xl px-4 ${className}`}
        ref={ref}
      >
        {children}
      </button>
    );
  }
);
