import {
  LearningActivityUtils,
  useScreenType,
  useWatchScreenResize,
  scrollTo,
  defaultGraphqlClient,
} from '@stellar-lms-frontend/common-utils';
import {
  CourseModuleOutline,
  JourneyGenerationProject,
  MutationCreateCourseModuleArgs,
  SuggestionGenerationStatus,
  useMarkTipViewed,
} from '@stellar-lms-frontend/lms-graphql';
import {
  ContentContainer,
  ContentWrapper,
  EmptyState,
  HintDescription,
  HintDescriptionProps,
  JourneyIllustration,
  PlusIcon,
  ScrollContainer,
} from '@stellar-lms-frontend/ui-components';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LearningActivityActions, LearningActivityListI18N } from './learning-activity-list';
import { MODULE_HEADER_INPUT_ID, ModuleHeaderActions } from './module-header';
import { ModuleSidebarActions, ModuleSidebar } from './sidebar/module-sidebar';
import { ModuleSelector } from './components/module-selector';
import {
  SortContextActions,
  LearningActivitySortContext,
} from './components/sort/learning-activity-sort-context';
import { ModuleSection } from './components/module-section';
import { autocompleteLearningActivity, createIntersectionObserver } from './functions';
import { ModuleListContext, UserRole } from './module-list-context';
import { EditingStore, ModuleStore } from './store';
import {
  LearningActivityEditDrawer,
  LearningActivityEditDrawerI18N,
  LearningActivityEditFormData,
} from '../learning-activity-edit-drawer/learning-activity-edit-drawer';
import { shallow } from 'zustand/shallow';
import { STEP_URI_CREATE_CONST } from '../learning-activity/routes';
import { LearningActivityType } from '../utils/learningActivity';
import { Behaviour, BehaviourAISidebar } from '../course/components/behaviour-ai-sidebar';
import { useTranslation } from 'react-i18next';

const SCROLLVIEW_ID = 'center-scroll-container';
const SIDEBAR_SCROLLVIEW_ID = 'module-list-sidebar-scroll-container';

const GEAR_TIP_ID = 'modulelist-gear-tip';

export type I18N = {
  learningActivityList: LearningActivityListI18N;
  learningActivityEditDrawer?: LearningActivityEditDrawerI18N;
};

export type Actions = {
  module: {
    create?: (variables: MutationCreateCourseModuleArgs) => void;
    fetchSuggestedBehaviours: (
      companyId: string,
      projectId: string
    ) => Promise<{
      status: SuggestionGenerationStatus;
      suggestions: Behaviour[];
    }>;
    startBehaviourGeneration: (courseId: string) => Promise<boolean>;
    startBehaviourContentGeneration: (
      courseId: string,
      behaviours: Behaviour[]
    ) => Promise<boolean>;
  };
  modal: {
    openGEARModal: () => void;
  };
} & ModuleSidebarActions &
  ModuleHeaderActions &
  LearningActivityActions &
  SortContextActions;

export type ModuleListProps = {
  initialModules: CourseModuleOutline[];
  courseId: string;
  i18n: I18N; // FUTURE think on how to give the components control over their own translation (with possible override)
  totalLearners?: number;
  isEditing?: boolean;
  setIsEditing?: Dispatch<SetStateAction<boolean>>;
  loadingMoreModules?: { loading: boolean; amount: number };
  actions?: Actions;
  forcedModuleId?: string;
  userRole: UserRole;
  hint?: HintDescriptionProps;
  aiProject?: JourneyGenerationProject;
  currentUserId?: string;
  currentCompanyId?: string;
};

export const ModuleList: React.FC<ModuleListProps> = ({
  initialModules,
  forcedModuleId,
  totalLearners = 0,
  i18n,
  courseId,
  isEditing,
  setIsEditing,
  loadingMoreModules = { loading: false, amount: 0 },
  actions,
  userRole,
  hint,
  aiProject,
  currentUserId,
  currentCompanyId,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'module-overview' });
  const { isTailwindLg } = useScreenType();
  const [lastModuleHeight, setLastModuleHeight] = useState(0);
  const [hintSectionHeight, setHintSectionHeight] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [moduleScrollTargetOnLoad, setModuleScrollTargetOnLoad] = useState(
    () => searchParams.get('moduleId') ?? undefined
  );
  const [highlightedModuleId, setHighlightedModuleId] = useState<string | undefined>(
    () => searchParams.get('moduleId') ?? initialModules.at(0)?.id
  );
  const [isBehaviourSideBarOpen, setIsBehaviourSideBarOpen] = useState(false);

  const navigate = useNavigate();
  const { screenHeight } = useWatchScreenResize();

  const markTipViewedMutation = useMarkTipViewed(defaultGraphqlClient);

  const [
    editingLearningActivityId,
    startEditingLearningActivity,
    moduleIdToCreateLearningActivity,
    createLearningActivityType,
    stopEditing,
  ] = EditingStore.useStore(
    (state) => [
      state.editingLearningActivityId,
      state.startEditingLearningActivity,
      state.moduleIdToCreateLearningActivity,
      state.createLearningActivityType,
      state.stopEditing,
    ],
    shallow
  );

  const [setModules, modules] = ModuleStore.useStore(
    (state) => [state.setModules, state.modules],
    shallow
  );

  // TODO: after move selector to common-utils use useMemo to avoid unnecessary calls
  const [learningActivity, moduleForLearningActivity, learningActivityLocation] =
    ModuleStore.useStore((state) => {
      if (editingLearningActivityId) {
        const learningActivityLocation = ModuleStore.getLearningActivityLocation(
          state,
          editingLearningActivityId
        );

        if (learningActivityLocation) {
          const { moduleIndex, learningActivityIndexInModule } = learningActivityLocation;

          return [
            ModuleStore.getLearningActivityByLocation(
              state,
              moduleIndex,
              learningActivityIndexInModule
            ),
            ModuleStore.getModuleByLocation(state, moduleIndex),
            learningActivityLocation,
          ];
        }
      }

      return [undefined, undefined, undefined];
    }, shallow);

  const { prevLearningActivityId, nextLearningActivityId } = useMemo(() => {
    const adjacentLearningActivities = editingLearningActivityId
      ? LearningActivityUtils.getAdjacentLearningActivities(modules, editingLearningActivityId)
      : undefined;

    return {
      prevLearningActivityId: adjacentLearningActivities?.prevLearningActivity?.id,
      nextLearningActivityId: adjacentLearningActivities?.nextLearningActivity?.id,
    };
  }, [editingLearningActivityId, modules]);

  useEffect(() => {
    setModules(initialModules);

    return () => setModules([]);
  }, [initialModules, setModules]);

  useEffect(() => {
    if (!isEditing) {
      stopEditing();
    }
  }, [isEditing, stopEditing]);

  useEffect(
    () => () => {
      stopEditing();
    },
    [stopEditing]
  );

  const scrollSideBarModuleIntoView = useCallback(
    (id: string) =>
      scrollTo(`sidebar-module-${id}`, SIDEBAR_SCROLLVIEW_ID, true, (isTailwindLg ? 64 : 32) + 30),
    [isTailwindLg]
  );

  const scrollToModule = useCallback(
    (id: string, smooth = true) => {
      scrollTo(id, SCROLLVIEW_ID, smooth, isTailwindLg ? 64 : 32, () => {
        document.getElementById(MODULE_HEADER_INPUT_ID(id))?.focus({ preventScroll: true });
      });
    },
    [isTailwindLg]
  );

  // Accept a forced moduleId from the parent component
  useEffect(() => {
    if (forcedModuleId) {
      scrollToModule(forcedModuleId, true);
    }
  }, [forcedModuleId, scrollToModule]);

  const handleModuleSelected = (moduleId: string) => {
    scrollToModule(moduleId, isTailwindLg);
  };

  const handleCreateModule = useCallback(() => {
    if (aiProject) {
      setIsBehaviourSideBarOpen(true);
    } else {
      // non AI module creation
      const newModuleRegex = new RegExp(`^${t('new-module', { count: 0 })}(?: \\((\\d+)\\))?$`);

      const newModules = modules.filter((m) => newModuleRegex.test(m.title ?? ''));

      actions?.module.create?.({
        input: {
          courseId,
          title: t('new-module', { count: newModules.length }),
        },
      });
    }
  }, [actions?.module, courseId, aiProject, modules, t]);

  /**
   * Scrolls to a certain module on load
   */
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (
      modules &&
      modules.length > 0 &&
      moduleScrollTargetOnLoad &&
      (!forcedModuleId || forcedModuleId === moduleScrollTargetOnLoad)
    ) {
      // The timeout is needed to kick this to then next render cycle as we need to first have the modules rendered fully in html
      timeout = setTimeout(() => {
        scrollToModule(moduleScrollTargetOnLoad, false);
        setModuleScrollTargetOnLoad(undefined);
      }, 0);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
    // We only want to do this once when the modules are loaded
    // Multiple scrolls would cause a weird effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modules]);

  useEffect(() => {
    if (modules && modules.length > 0) {
      // Adding the hint section height to the padding of the intersection observer has a downside that is currently not a problem.
      // It is possible that the hint secion is so large that the intersection observer line (see debug mode) will be pushed down too much.
      // When it is pushed down that far very small modules might fit above the intersecione line without triggering an intersection.
      //
      // As long as the smallest module (a module without learning activities) is smaller than the total hint section heigth (+ its padding) it will not be a problem.
      // When it becomes a problem we'll have to be a bit more dynamic with this padding and move the intersection line when the hintsection is not visible anymore in the scrollview.
      // This will be developed once we have the need for it.
      const padding =
        hintSectionHeight + (hintSectionHeight > 0 ? 36 : 0) + (isTailwindLg ? 64 : 32);
      const intersectionObserver = createIntersectionObserver(
        SCROLLVIEW_ID,
        modules,
        (intersectedId) => {
          setHighlightedModuleId(intersectedId);
          setSearchParams({ moduleId: intersectedId });
          scrollSideBarModuleIntoView(intersectedId);
        },
        { debug: false, heightPadding: padding }
      );

      return () => {
        intersectionObserver?.disconnect();
      };
    }

    return () => null;

    // We cannot add setSearchParams to useEffect as it will be called on every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modules,
    screenHeight,
    hintSectionHeight,
    isEditing,
    isTailwindLg,
    scrollSideBarModuleIntoView,
  ]);

  const lastModuleRef = useCallback(
    (lastListSectionElement: HTMLDivElement) => {
      if (modules && lastListSectionElement) {
        setLastModuleHeight(lastListSectionElement.clientHeight);
      }
    },
    [modules]
  );

  const hintSectionRef = useCallback((hintSectionElement: HTMLDivElement) => {
    if (hintSectionElement) {
      setHintSectionHeight(hintSectionElement.clientHeight);
    } else {
      setHintSectionHeight(0);
    }
  }, []);

  const onStepClick = useCallback(
    (stepId: string) =>
      learningActivity &&
      navigate(`./learning-activity/${learningActivity.id}/step/${stepId}`, {
        state: { isEditing: true },
      }),
    [learningActivity, navigate]
  );

  const autoCompletePrerequisites = useMemo(() => {
    if (learningActivityLocation) {
      return (query: string) =>
        autocompleteLearningActivity(
          query,
          learningActivityLocation.learningActivityIndexInModule,
          learningActivityLocation.moduleIndex,
          modules
        );
    }

    if (moduleIdToCreateLearningActivity) {
      const moduleIndex = modules.findIndex((m) => m.id === moduleIdToCreateLearningActivity);
      if (moduleIndex !== -1) {
        return (query: string) =>
          autocompleteLearningActivity(
            query,
            modules[moduleIndex].learningActivities.length,
            moduleIndex,
            modules
          );
      }
    }

    return undefined;
  }, [learningActivityLocation, moduleIdToCreateLearningActivity, modules]);

  const onUpdate = useMemo(
    () =>
      actions?.learningActivity.update
        ? (data: LearningActivityEditFormData) => {
            if (moduleForLearningActivity?.id && learningActivity?.id) {
              actions?.learningActivity.update?.(
                moduleForLearningActivity?.id,
                learningActivity?.id,
                {
                  ...data,
                  type: learningActivity.type,
                  mandatoryPrerequisiteLearningActivityIds:
                    data.mandatoryPrerequisiteLearningActivityIds.map((p) => p.id),
                }
              );

              stopEditing();
            }
          }
        : undefined,
    [actions?.learningActivity, learningActivity, moduleForLearningActivity?.id, stopEditing]
  );

  const onCreate = useMemo(
    () =>
      actions?.learningActivity.create
        ? (data: LearningActivityEditFormData) => {
            if (!moduleIdToCreateLearningActivity || !createLearningActivityType) return;

            actions?.learningActivity.create?.(moduleIdToCreateLearningActivity, {
              ...data,
              type: createLearningActivityType,
              mandatoryPrerequisiteLearningActivityIds:
                data.mandatoryPrerequisiteLearningActivityIds.map((p) => p.id),
            });

            stopEditing();
          }
        : undefined,
    [
      actions?.learningActivity,
      createLearningActivityType,
      moduleIdToCreateLearningActivity,
      stopEditing,
    ]
  );

  const onSubmitLearningActivity = useMemo(
    () => (editingLearningActivityId ? onUpdate : onCreate),
    [editingLearningActivityId, onCreate, onUpdate]
  );

  const onDelete = useMemo(
    () =>
      actions?.learningActivity.delete
        ? () => {
            moduleForLearningActivity?.id &&
              editingLearningActivityId &&
              actions?.learningActivity.delete?.(
                moduleForLearningActivity?.id,
                editingLearningActivityId
              );
            stopEditing();
          }
        : undefined,
    [
      actions?.learningActivity,
      editingLearningActivityId,
      moduleForLearningActivity?.id,
      stopEditing,
    ]
  );

  const behaviourActions = useMemo(() => {
    return {
      startBehaviourGeneration: () => {
        return actions
          ? actions?.module.startBehaviourGeneration(courseId)
          : Promise.resolve(false);
      },
      fetchBehaviourSuggestions: () => {
        if (actions && aiProject?.id && currentCompanyId) {
          return actions?.module.fetchSuggestedBehaviours(currentCompanyId, aiProject.id);
        } else {
          return Promise.resolve({
            status: SuggestionGenerationStatus.None,
            suggestions: [] as Behaviour[],
          });
        }
      },
      generateBehaviourContent: (selectedBehaviours: Behaviour[]) =>
        actions?.module.startBehaviourContentGeneration(courseId, selectedBehaviours) ??
        Promise.resolve(false),
    };
  }, [actions, courseId, aiProject?.id, currentCompanyId]);

  const onDuplicate = useMemo(
    () =>
      actions?.learningActivity.duplicate
        ? () => {
            moduleForLearningActivity?.id &&
              editingLearningActivityId &&
              actions?.learningActivity.duplicate?.(
                moduleForLearningActivity?.id,
                editingLearningActivityId
              );
            stopEditing();
          }
        : undefined,
    [
      actions?.learningActivity,
      editingLearningActivityId,
      moduleForLearningActivity?.id,
      stopEditing,
    ]
  );

  const drawerLaNavigation = useMemo(
    () =>
      editingLearningActivityId
        ? {
            onNavigatePrev: prevLearningActivityId
              ? () => startEditingLearningActivity(prevLearningActivityId)
              : undefined,
            onNavigateNext: nextLearningActivityId
              ? () => startEditingLearningActivity(nextLearningActivityId)
              : undefined,
          }
        : undefined,
    [
      editingLearningActivityId,
      nextLearningActivityId,
      prevLearningActivityId,
      startEditingLearningActivity,
    ]
  );

  if (modules.length === 0 && !isEditing) {
    return (
      <EmptyState
        className="max-w-[570px]"
        icon={<JourneyIllustration />}
        title={t('empty.title')}
        description={t('empty.description')}
        verticalCenter={true}
        actionText={t('empty.cta')}
        buttonType="primary"
        buttonConfig={{
          leftIcon: <PlusIcon className="h-5 w-5 text-white" />,
          fullWidth: false,
        }}
        onActionClick={() => {
          handleCreateModule();
          setIsEditing?.(true);
        }}
      />
    );
  }

  return (
    <ModuleListContext.Provider value={{ userRole }}>
      <div className="flex h-full w-full">
        <div className="bg-sidebar-gradient shrink-0">
          <ModuleSidebar
            selectModule={handleModuleSelected}
            loadingMoreModules={loadingMoreModules}
            highlightedModuleId={highlightedModuleId}
            isEditing={isEditing}
            actions={{
              ...actions,
              module: { ...actions?.module, handleCreate: handleCreateModule },
            }}
            courseId={courseId}
            scrollContainerId={SIDEBAR_SCROLLVIEW_ID}
            isAiCourse={!!aiProject?.id}
            isAIGenerating={loadingMoreModules.loading}
          />
        </div>
        <div className="h-full grow">
          <div className="flex h-full w-full flex-col items-start overflow-hidden xl:flex-row">
            <div className="w-full px-8 pt-8 lg:hidden">
              <ModuleSelector
                onModuleSelect={handleModuleSelected}
                modules={modules}
              />
            </div>
            <ScrollContainer
              scrollOnDesktop
              htmlId={SCROLLVIEW_ID}
            >
              <ContentContainer>
                <ContentWrapper className="mx-auto flex flex-col space-y-6">
                  {hint && currentUserId && (
                    <HintDescription
                      className="mb-3"
                      setRef={hintSectionRef}
                      hasCloseButton={true}
                      onClose={() => {
                        markTipViewedMutation.mutate({
                          userId: currentUserId,
                          tip: GEAR_TIP_ID,
                        });
                      }}
                      {...hint}
                    />
                  )}
                  <LearningActivitySortContext
                    modules={modules}
                    actions={actions}
                  >
                    {(learningActivityMap, moveUp, moveDown) =>
                      modules.map((module, index) => (
                        <ModuleSection
                          module={module}
                          totalLearners={totalLearners}
                          actions={actions}
                          i18n={i18n}
                          courseId={courseId}
                          isEditing={isEditing}
                          setIsEditing={setIsEditing}
                          setRef={index === modules.length - 1 ? lastModuleRef : undefined}
                          key={module.id}
                          learningActivities={learningActivityMap.get(module.id) ?? []}
                          showSeparator={index !== modules.length - 1}
                          moveUp={moveUp}
                          moveDown={moveDown}
                        />
                      ))
                    }
                  </LearningActivitySortContext>
                  {/*
                   * It is important that the artificial divs below have correct heights as they make sure that we can scroll
                   * the last module to the top of the scroll view to align it with the intersection observer.
                   *
                   * Use the debug mode of the intersection observer to figure out the correct height (most of the work comes from figuring out the paddings..)
                   */}
                  {isEditing ? (
                    <div
                      style={{
                        height: `calc(100vh - ${lastModuleHeight}px - 116px - ${
                          isTailwindLg ? 128 : 64
                        }px + 24px)`,
                        marginTop: 0,
                      }}
                    ></div>
                  ) : (
                    <div
                      style={{
                        height: `calc(100vh - ${lastModuleHeight}px - 152px - ${
                          isTailwindLg ? 128 : 64
                        }px )`,
                        marginTop: 0,
                      }}
                    ></div>
                  )}
                </ContentWrapper>
              </ContentContainer>
            </ScrollContainer>
          </div>
        </div>
      </div>
      {onSubmitLearningActivity &&
        onDelete &&
        onDuplicate &&
        actions?.modal.openGEARModal &&
        i18n.learningActivityEditDrawer &&
        autoCompletePrerequisites && (
          <LearningActivityEditDrawer
            key={learningActivity?.id}
            isPlaceholder={learningActivity?.placeholder ?? false}
            isOpen={!!editingLearningActivityId || !!moduleIdToCreateLearningActivity}
            onClose={stopEditing}
            onSave={onSubmitLearningActivity}
            onDelete={onDelete}
            onDuplicate={onDuplicate}
            i18n={i18n.learningActivityEditDrawer}
            prerequisiteAutocompleteFunc={autoCompletePrerequisites}
            data={{
              title: learningActivity?.title ?? '',
              description: learningActivity?.description ?? '',
              durationInSeconds: learningActivity?.duration ?? 300,
              mandatoryPrerequisiteLearningActivityIds:
                learningActivity?.prerequisites.map((p) => ({
                  id: p?.module?.id ?? '',
                  label: p.module?.title ?? '',
                })) ?? [],
              openDate: learningActivity?.dateOpen ?? '',
            }}
            laNavigation={drawerLaNavigation}
            steps={
              learningActivity?.steps.map((s) => ({ id: s.id ?? '', title: s.title ?? '' })) ?? []
            }
            onStepClick={onStepClick}
            addStep={{
              isEnabled: !!editingLearningActivityId,
              url: `./learning-activity/${learningActivity?.id}/step/${STEP_URI_CREATE_CONST}`,
            }}
            isCreating={!!moduleIdToCreateLearningActivity}
            type={
              learningActivity?.type ?? createLearningActivityType ?? LearningActivityType.Unknown
            }
            onOpenGEARModal={actions?.modal.openGEARModal}
          />
        )}
      {isEditing && (
        <BehaviourAISidebar
          ablyChannelId={aiProject?.realtimeChannel.id}
          isOpen={isBehaviourSideBarOpen}
          onClose={() => {
            setIsBehaviourSideBarOpen(false);
          }}
          actions={behaviourActions}
        />
      )}
    </ModuleListContext.Provider>
  );
};

export default ModuleList;
