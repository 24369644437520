import { CheckIcon } from '../../icons';
import { FontBody } from '../../text';

type ProgressBarStepItemProps = {
  isEnabled: boolean;
  isActive: boolean;
  isFirst: boolean;
  isLast: boolean;
  isCompleted: boolean;
  stepNumber: number;
  title: string;
  onClick: (e: React.MouseEvent) => void;
};
export const ProgressBarStepItem: React.FC<ProgressBarStepItemProps> = ({
  isEnabled,
  isActive,
  isFirst,
  isLast,
  isCompleted,
  stepNumber,
  title = '',
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={`${
        isEnabled && !isActive ? 'cursor-pointer' : ''
      } lexend flex flex-col items-center space-y-2`}
    >
      <div className="flex w-full items-center">
        <i className={`h-[2px] grow ${!isFirst ? 'bg-blue-400' : ''}`}></i>
        <div
          className={`
          ${isEnabled ? 'border-white' : 'border-white/60 text-white/60'}
          ${isEnabled && !isActive ? 'text-white' : ''}
          ${isActive ? 'text-primary-01 bg-white' : 'bg-none'} 
          border-1 flex h-12 w-12 shrink-0 grow-0 items-center justify-center rounded-full`}
        >
          <FontBody
            tag="span"
            type="medium"
          >
            {isCompleted ? <CheckIcon /> : stepNumber}
          </FontBody>
        </div>
        <i className={`h-[2px] grow ${!isLast ? 'bg-blue-400' : ''} `}></i>
      </div>
      <FontBody
        tag="span"
        type="medium"
        className={`${isEnabled ? 'text-white' : 'text-white/60'}`}
      >
        {title}
      </FontBody>
    </div>
  );
};
