import { Outlet } from 'react-router-dom';
import { getLoggedInUser, User } from '@stellar-lms-frontend/lms-api';
import { LogoLoader } from '@stellar-lms-frontend/ui-components';
import {
  applyStylesheet,
  defaultGraphqlClient,
  getOnsophicApi,
  useSessionRecorder,
} from '@stellar-lms-frontend/common-utils';
import { useQuery } from '@tanstack/react-query';
import { getAblyRequestToken, useCurrentCompany } from '@stellar-lms-frontend/lms-graphql';
import { useEffect } from 'react';
import { Realtime } from 'ably';
import { AblyProvider } from 'ably/react';

type RequireUserProps = {
  children?: JSX.Element;
  userCallback?: (user: User) => void;
};

const isInternal = (email: string | undefined): boolean => {
  if (!email) {
    return false;
  }

  return email.endsWith('@stellarlabs.eu') || email.endsWith('@stellarlabs.io');
};

const client = new Realtime({
  authCallback: async (tokenParams, callback) => {
    let tokenRequest;
    try {
      const request = (await getAblyRequestToken(defaultGraphqlClient)).getRealtimeTokenRequest; // Make a network request to your server
      tokenRequest = {
        capability: request.capability ?? '',
        clientId: request.clientId ?? undefined,
        keyName: request.keyName,
        nonce: request.nonce,
        timestamp: request.timestamp,
        mac: request.mac,
      };
    } catch (err) {
      callback('failed to get realtime token', null);
      return;
    }
    callback(null, tokenRequest);
  },
});
export const RequireUser = ({ children, userCallback }: RequireUserProps) => {
  // configure ably as early as possible
  // configureAbly({
  //   authCallback: async (_tokenParams, callback) => {
  //     const request = (await getAblyRequestToken(defaultGraphqlClient)).getRealtimeTokenRequest;
  //     const tokenRequest = {
  //       capability: request.capability ?? '',
  //       clientId: request.clientId ?? undefined,
  //       keyName: request.keyName,
  //       nonce: request.nonce,
  //       timestamp: request.timestamp,
  //       mac: request.mac,
  //     };
  //     callback(null, tokenRequest);
  //   },
  // });

  // CLEANUP Would be nice if this could be replace by useCurrentUser, but that one is returning GraphQL User
  const { data: user, isLoading } = useQuery(
    ['LOGGED_IN_USER'],
    () => getLoggedInUser(defaultGraphqlClient),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        userCallback?.(data);
      },
      onError: () => {
        window.location.href =
          getOnsophicApi() +
          '/user/auth0/login?redirectUrl=' +
          encodeURIComponent(window.location.href);
      },
    }
  );

  const {
    query: { data: company },
  } = useCurrentCompany(defaultGraphqlClient);

  useEffect(() => {
    if (company?.styleProperties?.stylesheet) {
      applyStylesheet(company.styleProperties.stylesheet);
    }
  }, [company?.styleProperties?.stylesheet]);

  useSessionRecorder(user?.id, company?.id, isInternal(user?.email));

  if (isLoading) {
    return <LogoLoader />;
  }

  return <AblyProvider client={client}>{children ?? <Outlet />}</AblyProvider>;
};

export default RequireUser;
