import { CourseOverviewNoAccessIcon, EmptyState } from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';

import { REDIRECT_HOME_URI } from '../../constants/routes';
import { ErrorWrapper, ErrorWrapperType } from './error-wrapper';

export type Error403Props = {
  type?: ErrorWrapperType;
};

export const Error403 = ({ type = 'paged' }: Error403Props) => {
  const { t } = useTranslation('translation', { keyPrefix: '403' });

  return (
    <ErrorWrapper
      type={type}
      pageTitle={t('page-title')}
      pageSubTitle={t('page-subtitle')}
    >
      <EmptyState
        icon={<CourseOverviewNoAccessIcon />}
        title={t('title')}
        description={t('description')}
        actionText={t('button-cta')}
        verticalCenter={true}
        onActionClick={() => {
          window.location.href = REDIRECT_HOME_URI;
        }}
      />
    </ErrorWrapper>
  );
};
