import BaseButton, { BaseButtonProps } from '../base-button/base-button';

export interface SecondaryButtonProps extends BaseButtonProps {
  theme?: 'red' | 'blue' | 'white';
}

export function SecondaryButton({ theme = 'blue', ...baseProps }: SecondaryButtonProps) {
  return (
    <BaseButton
      {...baseProps}
      className={`
      ${
        theme === 'blue'
          ? 'text-primary-02 hover:text-primary-01 focus:text-primary-01 border-primary-02 hover:border-primary-01 focus:border-primary-01'
          : ''
      }
      ${
        theme === 'red'
          ? 'text-negative-02 hover:text-negative-01 focus:text-negative-01 border-negative-02 hover:border-negative-01 focus:border-negative-01'
          : ''
      }
      ${theme === 'white' ? 'border-white text-white' : ''}
       disabled:text-text-03 border-1 disabled:border-border-01  outline-on-primary-02 rounded-xl bg-transparent shadow-sm outline-offset-2 focus:outline-4  disabled:shadow-none`}
    />
  );
}

export default SecondaryButton;
