import { User } from '@stellar-lms-frontend/lms-api';
import { i18next } from '@stellar-lms-frontend/common-utils';
import moment from 'moment';
import {
  getCompanyCookie,
  setCompanyCookie,
  useCompanyStore,
} from '@stellar-lms-frontend/lms-graphql';

export const handleUserInformation = (user: User) => {
  if (i18next.language !== user.language) {
    i18next.changeLanguage(user.language);
  }
  moment.locale(user.language);

  const existingCompanyCookie = getCompanyCookie();
  if (!existingCompanyCookie) {
    setCompanyCookie(user.companyReference.id);
    useCompanyStore.setState({ companyId: user.companyReference.id });
  } else {
    useCompanyStore.setState({ companyId: existingCompanyCookie });
  }

  const reactNativeWebView = window.ReactNativeWebView;
  if (reactNativeWebView) {
    reactNativeWebView.postMessage(
      JSON.stringify({
        after_logon: true,
      })
    );
  }
};
