import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { DEFAULT_LANGUAGE, translatedLanguages } from '../../constants/languages';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dropdown, Input, PrimaryButton } from '@stellar-lms-frontend/ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/pro-light-svg-icons';
import Lottie from 'react-lottie';

import * as animationData from '../../assets/animations/trial-registration-page.json';
import { useMutation } from '@tanstack/react-query';
import { createTrial } from './trial-registration.api';
import {
  CreateTrialErrorCode,
  InputLanguage,
  transferToInputLanguage,
} from '@stellar-lms-frontend/lms-graphql';
import { APP_BASE_URI, getDesignerCourseCreateRoute } from '@stellar-lms-frontend/lms-components';
import { useNavigate } from 'react-router-dom';

type FormData = {
  userName: string;
  companyName: string;
  language: string;
};

export const TrialRegistrationPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'trial-registration' });
  const { t: tLanguages } = useTranslation('translation', { keyPrefix: 'languages' });
  const navigate = useNavigate();

  const languages = translatedLanguages(tLanguages);

  const schema = yup.object().shape({
    userName: yup.string().required(t('fields.full-name.required')),
    companyName: yup.string().required(t('fields.company-name.required')),
    language: yup.string().oneOf(languages.map(({ value }) => value)),
  });

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors, isValid },
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      language: DEFAULT_LANGUAGE.toLowerCase(),
    },
  });

  const lottieOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      className: '!w-auto',
    },
  };

  const { mutate: mutateCreateTrial } = useMutation(
    (variables: { input: { companyName: string; userName: string; language: InputLanguage } }) =>
      createTrial(variables),
    {
      onSuccess: (data) => {
        if ((data.createTrial?.errors?.length ?? 0) === 0) {
          navigate(getDesignerCourseCreateRoute());
        } else {
          // handle errors
          data.createTrial?.errors?.forEach((error) => {
            switch (error) {
              case CreateTrialErrorCode.DuplicateCompanyName:
                setError('companyName', {
                  type: 'custom',
                  message: t('fields.company-name.duplicate'),
                });
                break;
              case CreateTrialErrorCode.UserAlreadyExist:
                navigate(APP_BASE_URI);
                break;
            }
          });
        }
      },
    }
  );

  const onSubmit = (data: FormData) => {
    mutateCreateTrial({
      input: {
        userName: data.userName,
        companyName: data.companyName,
        language: transferToInputLanguage(data.language) ?? InputLanguage.En,
      },
    });
  };

  return (
    <div className=" flex h-full w-full items-center justify-center bg-blue-700 p-4">
      <div className="bg-primary-01 mx-auto my-auto flex h-[666px] min-h-[666px] w-full max-w-[1085px] flex-row items-center justify-center gap-[72px] rounded-3xl px-[72px] py-16">
        <div className="h-full w-full">
          <div className=" text-on-primary-01 type-headline-1">{t('title')}</div>
          <div className="text-primary-03 font-lexend type-body pt-4">{t('description')}</div>
          <form className="space-y-6 pt-6">
            <Controller
              control={control}
              name="userName"
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  htmlId="trial-user-name-input"
                  placeholder={t('fields.full-name.placeholder')}
                  label={t('fields.full-name.label')}
                  error={errors.userName?.message}
                  required
                  theme="dark"
                />
              )}
            />
            <Controller
              control={control}
              name="companyName"
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  htmlId="trial-company-name-input"
                  placeholder={t('fields.company-name.placeholder')}
                  label={t('fields.company-name.label')}
                  error={errors.companyName?.message}
                  required
                  theme="dark"
                />
              )}
            />

            <Dropdown
              label={t('fields.language.label')}
              htmlId="language"
              theme="dark"
              {...register('language')}
            >
              {languages.map(({ label, value }) => (
                <option
                  key={value}
                  value={value}
                >
                  {label}
                </option>
              ))}
            </Dropdown>
          </form>

          <PrimaryButton
            key="start-trial-cta"
            onClick={handleSubmit(onSubmit)}
            label={t('start-trial')}
            enabled={isValid}
            className="mt-10"
            htmlType="submit"
            theme="dark"
            leftIcon={<FontAwesomeIcon icon={faArrowRightLong} />}
          />
        </div>
        <div className=" -mt-[128px] hidden h-full w-full xl:block">
          <Lottie
            options={lottieOptions}
            width={'auto'}
            height={'666px'}
          />
        </div>
      </div>
    </div>
  );
};
