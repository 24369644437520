import { faPen, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FloatingOptions, MoreVertIcon } from '@stellar-lms-frontend/ui-components';
import { useState } from 'react';
import { SupportedLanguagesOptions } from '../../../../../../types/types';
import { faPersonWalkingArrowRight } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';

export type OptionsMenuCellProps = {
  onEdit: () => void;
  onAddToJourney: () => void;
  onDelete: () => void;
  supportedLanguages: SupportedLanguagesOptions;
};

export const OptionsMenuCell: React.FC<OptionsMenuCellProps> = ({
  onEdit,
  onAddToJourney,
  onDelete,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organization.members-tab.options-menu',
  });
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  return (
    <FloatingOptions
      options={[
        {
          label: t('edit'),
          onClick: () => {
            onEdit();
            setIsOptionsOpen(false);
          },
          className: '',
          left: <FontAwesomeIcon icon={faPen} />,
        },
        {
          label: t('add-to-journey'),
          onClick: (e) => {
            e.stopPropagation();
            onAddToJourney();
            setIsOptionsOpen(false);
          },
          className: '',
          left: <FontAwesomeIcon icon={faPersonWalkingArrowRight} />,
        },
        {
          label: t('delete'),
          onClick: (e) => {
            e.stopPropagation();
            onDelete();
            setIsOptionsOpen(false);
          },
          className: 'text-negative-01',
          left: <FontAwesomeIcon icon={faTrashCan} />,
        },
      ]}
      placement="bottom-end"
      wrappedComponent={
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsOptionsOpen(true);
          }}
          className="-my-3 flex w-full items-center justify-end px-3 py-3"
        >
          <MoreVertIcon className="h-4 w-4" />
        </button>
      }
      isOpen={isOptionsOpen}
      onClose={() => setIsOptionsOpen(false)}
    />
  );
};
