import { ReactNode } from 'react';

type FontSmallTypes = 'default' | 'medium';

export type FontSmallProps = {
  children: ReactNode;
  className?: string;
  type: FontSmallTypes;
};

/**
 * @deprecated `FontSmall` is deprecated. Use tailwind components instead.
 */
export const FontSmall: React.FC<FontSmallProps> = ({ children, className = '', type }) => {
  return (
    <p
      className={`${className} whitespace-normal text-sm
                ${type === 'default' ? 'font-regular' : ''}
                ${type === 'medium' ? 'font-medium' : ''}`}
    >
      {children}
    </p>
  );
};
