import { DndContext, DragEndEvent, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import React, { useCallback, useEffect, useState } from 'react';
import { DragSortableItem, DragItemProps } from '../drag-sortable-item/drag-sortable-item';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

export type ChildrenProps<T> = {
  item: T;
  index: number;
} & DragItemProps;

type DragVerticalListProps<T> = {
  listClassName?: string;
  items: T[];
  children: (props: ChildrenProps<T>) => React.ReactNode;
  onChange: (items: T[], previousPosition: number, newPosition: number) => void;
};

export function addIds<T>(items: T[]) {
  return items.map((i, index) => ({ id: index.toString(), item: i }));
}

export function DragVerticalList<T>({
  listClassName = '',
  items,
  children,
  onChange,
}: DragVerticalListProps<T>) {
  const [localItems, setLocalItems] = useState(addIds(items));

  useEffect(() => {
    setLocalItems(addIds(items));
  }, [items]);

  const onDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (active.id !== over?.id && over) {
        const activeIndex = localItems.findIndex((i) => i.id === active.id);
        const overIndex = localItems.findIndex((i) => i.id === over.id);

        const newArr = arrayMove(localItems, activeIndex, overIndex);
        setLocalItems(newArr);
        onChange(
          newArr.map((el) => el.item),
          activeIndex,
          overIndex
        );
      }
    },
    [localItems, onChange]
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext
        items={localItems}
        strategy={verticalListSortingStrategy}
      >
        <ul className={`flex flex-col ${listClassName}`}>
          {localItems.map((item, index) => (
            <DragSortableItem
              id={item.id}
              key={item.id}
            >
              {(props) => children({ item: item.item, index, ...props })}
            </DragSortableItem>
          ))}
        </ul>
      </SortableContext>
    </DndContext>
  );
}
