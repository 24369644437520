import { graphql, UnreadNotificationCount } from '@stellar-lms-frontend/lms-graphql';
import { GraphQLClient } from 'graphql-request';
import { Notifications } from '@stellar-lms-frontend/lms-components';
import { graphQLClient } from '../../lib/graphql';

export const getAllNotifications = (lastDate?: Date): Promise<Notifications.Notification[]> => {
  return graphQLClient()
    .request(
      graphql(`
        query GetAllNotifications($lastDate: DateTime) {
          allNotifications(lastDate: $lastDate) {
            id
            date
            senderId
            message
            type
            link
            read
            properties {
              name
              value
            }
            sender {
              id
              name
            }
          }
        }
      `),
      {
        lastDate: lastDate ? lastDate.toISOString() : undefined,
      }
    )
    .then((data) => {
      return data.allNotifications.map((notification: any) => {
        const properties: Map<string, string> = new Map();
        for (const property of notification.properties) {
          properties.set(property.name, property.value);
        }

        return {
          id: notification.id,
          date: new Date(notification.date),
          message: notification.message,
          senderId: notification.sender?.id ?? undefined,
          type: notification.type,
          link: notification.link,
          read: notification.read,
          properties,
        };
      });
    });
};

export const markNotificationAsRead = async (id: string): Promise<void> => {
  await graphQLClient().request(
    graphql(`
      mutation MarkNotificationAsRead($id: ID!) {
        markNotificationAsRead(id: $id)
      }
    `),
    {
      id,
    }
  );
  return Promise.resolve();
};

export const unreadNotificationCount = (client: GraphQLClient) =>
  client
    .request(
      // CLEANUP not only for learner anymore
      graphql(`
        query LearnerUnreadNotificationCount {
          unreadNotificationCount {
            unread
          }
        }
      `)
    )
    .then((res) => res.unreadNotificationCount as UnreadNotificationCount);
