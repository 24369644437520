import { ReactNode, useMemo, useState } from 'react';
import { BaseCard } from '..';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CopyIcon,
  Edit3Icon,
  MoreVertIcon,
  TrashIcon,
} from '../../icons';
import { DragItemProps } from '../../list';
import { FloatingOptions, FloatingOptionsOption } from '../../wrappers';
import { InputError } from '../../error';
import { DragHandler } from '../../drag-handler/drag-handler';
import { useTranslation } from 'react-i18next';

export type SettingsCardProps = {
  closedChildren: ReactNode;
  color?: 'default' | 'purple' | 'surface-02';
} & (
  | {
      onToggleOpenState?: () => void;
      onDelete?: () => void;
      onDuplicate?: () => void;
      isOpen: boolean;
      dragProps?: DragItemProps;
      hasError?: boolean;
      openChildren: ReactNode;
      hasOptionsMenu?: boolean;
      moveUp?: () => void;
      moveDown?: () => void;
      onClick?: never;
      isHighlighted?: never;
    }
  | {
      onToggleOpenState?: never;
      onDelete?: never;
      onDuplicate?: never;
      isOpen?: false;
      dragProps?: never;
      hasError?: never;
      openChildren?: never;
      hasOptionsMenu?: never;
      moveUp?: never;
      moveDown?: never;
      onClick?: () => void;
      isHighlighted?: boolean;
    }
);

export const SettingsCard: React.FC<SettingsCardProps> = ({
  dragProps,
  isOpen,
  color,
  onToggleOpenState,
  onDelete,
  onDuplicate,
  closedChildren,
  openChildren,
  hasError,
  hasOptionsMenu,
  moveDown,
  moveUp,
  isHighlighted,
  onClick,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'general' });

  const [isOptionMenuOpen, setIsOptionMenuOpen] = useState(false);

  const menuOptions = useMemo(() => {
    const options: FloatingOptionsOption[] = [];
    if (onToggleOpenState) {
      options.push({
        label: t('edit'),
        onClick: isOpen
          ? undefined
          : () => {
              onToggleOpenState();
              setIsOptionMenuOpen(false);
            },
        className: isOpen ? 'opacity-50' : '',
        left: <Edit3Icon className="text-text-01 h-4 w-4" />,
      });
    }

    if (moveUp) {
      options.push({
        label: t('move-up'),
        onClick: () => {
          moveUp();
          setIsOptionMenuOpen(false);
        },
        left: <ChevronUpIcon className="text-text-01 h-4 w-4" />,
      });
    }

    if (moveDown) {
      options.push({
        label: t('move-down'),
        onClick: () => {
          moveDown();
          setIsOptionMenuOpen(false);
        },
        left: <ChevronDownIcon className="text-text-01 h-4 w-4" />,
      });
    }

    if (onDuplicate) {
      options.push({
        label: t('duplicate'),
        onClick: () => {
          onDuplicate();
          setIsOptionMenuOpen(false);
        },
        left: <CopyIcon className="text-text-01 h-4 w-4" />,
      });
    }

    if (onDelete) {
      options.push({
        label: t('delete'),
        onClick: () => {
          onDelete();
          setIsOptionMenuOpen(false);
        },
        className: 'text-negative-01',
        left: <TrashIcon className="text-negative-01 h-4 w-4" />,
      });
    }

    return options;
  }, [t, isOpen, moveDown, moveUp, onDelete, onDuplicate, onToggleOpenState]);

  return (
    <BaseCard
      color={color}
      isHighlighted={isHighlighted}
      className="p-4"
      childrenClassName={`flex gap-2`}
      onClick={onClick}
    >
      {dragProps && (
        <div className="mt-1 shrink-0">
          <DragHandler
            {...dragProps}
            className="text-text-02"
          />
        </div>
      )}
      <div className="grow">
        {isOpen ? (
          openChildren
        ) : (
          <div
            className="cursor-pointer space-y-4"
            onClick={onToggleOpenState}
            role="button"
          >
            {closedChildren}
            {hasError && <InputError error={t('missing-details')} />}
          </div>
        )}
      </div>
      {onToggleOpenState && openChildren && (
        <button
          className="h-fit"
          onClick={onToggleOpenState}
        >
          {isOpen ? (
            <ChevronUpIcon className="text-text-02 h-6 w-6 shrink-0" />
          ) : (
            <ChevronDownIcon className="text-text-02 h-6 w-6 shrink-0" />
          )}
        </button>
      )}
      {menuOptions.length > 0 && hasOptionsMenu && (
        <div className="w-3 shrink-0">
          <FloatingOptions
            wrappedComponent={
              <button
                className="h-fit"
                onClick={() => setIsOptionMenuOpen(!isOptionMenuOpen)}
              >
                <MoreVertIcon className="text-text-02 mt-1 h-4 w-4 shrink-0" />
              </button>
            }
            isOpen={isOptionMenuOpen}
            onClose={() => setIsOptionMenuOpen(false)}
            options={menuOptions}
          />
        </div>
      )}
    </BaseCard>
  );
};
