import { graphql } from '@stellar-lms-frontend/lms-graphql';
import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';

export const getSpacedRepetitionErrorRates = async (courseId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query CourseMentorDashboardSRQErrorRates($courseId: ID!) {
        mentorCourseSRQErrorRates(courseId: $courseId) {
          rows {
            category
            count
          }
        }
      }
    `),
    { courseId: courseId }
  );
};

export const getUserProgress = async (courseId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query CourseMentorDashboardUserProgress($courseId: ID!) {
        mentorCourseUserProgress(courseId: $courseId) {
          rows {
            category
            count
          }
        }
      }
    `),
    { courseId: courseId }
  );
};

export const getSRQUserLevelDistribution = async (courseId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query CourseMentorDashboardSRQUserLevelDistribution($courseId: ID!) {
        mentorCourseSRQUserLevelDistribution(courseId: $courseId) {
          rows {
            category
            items {
              category
              count
            }
          }
        }
      }
    `),
    { courseId: courseId }
  );
};
export const getUserWBAStates = async (courseId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query CourseMentorDashboardUserWBAStates($courseId: ID!) {
        mentorCourseUserWBAStates(courseId: $courseId) {
          rows {
            category
            items {
              category
              count
            }
          }
        }
      }
    `),
    { courseId: courseId }
  );
};
export const getCourseOverallWBAStates = async (courseId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query CourseMentorDashboardOverallWBAStates($courseId: ID!) {
        mentorCourseOverallWBAStates(courseId: $courseId) {
          rows {
            category
            items {
              category
              count
            }
          }
        }
      }
    `),
    { courseId: courseId }
  );
};
export const getSRQLevelDistribution = async (courseId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query CourseMentorDashboardSRQLevelDistribution($courseId: ID!) {
        mentorCourseSRQLevelDistribution(courseId: $courseId) {
          rows {
            category
            items {
              category
              count
            }
          }
        }
      }
    `),
    { courseId: courseId }
  );
};
