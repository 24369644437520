import { useDisableScroll, VoidFunc } from '@stellar-lms-frontend/common-utils';
import { ReactNode } from 'react';
import { CrossIcon } from '../icons';
import { Portal } from '../wrappers/portal/portal';

export type SidebarButtonProps = {
  hasDivider?: boolean;
  buttons: ReactNode[];
};

export type SidebarProps = {
  children: ReactNode;
  isOpen: boolean;
  contentClassName?: string;
  position: 'left' | 'right';
  onClose: VoidFunc;
  header?: ReactNode;
  isDisableScrollEnabled?: boolean;
  hasBackdrop?: boolean;
  buttons?: SidebarButtonProps;
};

export const Sidebar: React.FC<SidebarProps> = ({
  children,
  isOpen,
  contentClassName,
  position,
  onClose,
  header,
  isDisableScrollEnabled = true,
  hasBackdrop = true,
  buttons,
}) => {
  useDisableScroll(isOpen && isDisableScrollEnabled);

  if (!isOpen) return null;

  return (
    <Portal>
      <div
        className={`flex
                  ${
                    hasBackdrop
                      ? 'modal-bg'
                      : 'fixed right-0 top-0 z-20 flex h-full w-full drop-shadow-2xl'
                  }
                  ${position === 'left' ? 'justify-start' : ''}
                  ${position === 'right' ? 'justify-end' : ''}`}
      >
        <div
          data-cy="sidebar-content"
          className={`${contentClassName} bg-surface-01 flex h-full w-full flex-col py-6`}
        >
          <div className="mb-8 flex shrink-0 items-start px-10">
            <div className="grow">{header}</div>
            <button
              onClick={onClose}
              data-cy="sidebar-close"
              className="shrink-0 p-1"
            >
              <CrossIcon className="text-text-01 h-6 w-6" />
            </button>
          </div>
          <div
            className="flex grow flex-col overflow-scroll px-10"
            data-cy="sidebar-children"
          >
            <div className="grow pb-4">{children}</div>
          </div>
          {buttons && (
            <div
              className={`${
                buttons.hasDivider ? 'border-t-1 border-border-02' : ''
              } z-10 flex shrink-0 items-center gap-4 px-10 pt-6`}
            >
              {buttons.buttons}
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
};
