import React, { useMemo, MouseEvent, useRef } from 'react';
import { FloatingProps, Floating } from '../floating/floating';
import { CrossIcon } from '../../icons';
import { useOnClickOutside } from '@stellar-lms-frontend/common-utils';

export type TipProps = Omit<FloatingProps, 'isOpen'> & {
  color?: 'dark' | 'unstyled';
  paddingSize?: 'large' | 'small' | 'unstyled';
  widthClassName?: string;
  isOpen: boolean;
  onClose?: () => void;
  onClick?: (event?: MouseEvent<HTMLElement>) => void;
};

export const Tip: React.FC<TipProps> = ({
  children,
  color = 'unstyled',
  paddingSize = 'unstyled',
  widthClassName = '',
  wrappedComponent,
  isOpen,
  onClick,
  onClose,
  ...rest
}) => {
  const colorClassName = useMemo(() => {
    switch (color) {
      case 'dark':
        return 'bg-inverse-bg text-on-inverse-01 ';
      case 'unstyled':
        return '';
    }
  }, [color]);

  const paddingSizeClassName = {
    large: 'p-6',
    small: 'py-3 px-2',
    unstyled: 'p-2',
  };

  const wrappedComponentRef = useRef<HTMLElement | null>(null);
  const childrenRef = useRef<HTMLElement | null>(null);

  useOnClickOutside(isOpen ? onClose : undefined, [wrappedComponentRef, childrenRef]);

  return (
    <Floating
      {...rest}
      wrappedComponent={wrappedComponent}
      isOpen={isOpen}
      innerRefs={{
        setWrappedComponentRef: (e) => (wrappedComponentRef.current = e),
        setChildrenRef: (e) => (childrenRef.current = e),
      }}
    >
      <div
        className={`${colorClassName} ${paddingSizeClassName[paddingSize]} ${
          onClick ? 'cursor-pointer' : ''
        } ${widthClassName} font-lexend
        flex items-start space-x-4 rounded-md text-sm font-medium`}
        onClick={onClick}
      >
        {children}
        {onClose && (
          <div>
            <button
              className="-mr-2 -mt-2"
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              <CrossIcon />
            </button>
          </div>
        )}
      </div>
    </Floating>
  );
};
