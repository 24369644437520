import React from 'react';

interface DividerComponentProps {
  className?: string;
}

export const DividerComponent: React.FC<DividerComponentProps> = ({ className = '' }) => {
  return (
    <div className={`${className} flex h-[97px] flex-col items-center justify-center`}>
      <div className="bg-border-02 h-[1px] w-full"></div>
    </div>
  );
};
