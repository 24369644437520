import {
  GetLastAssessmentSubmissionQueryVariables,
  graphql,
  PublishedAssessment,
  QueryLearningActivityStepArgs,
  ScenarioStepQueryVariables,
  SessionEventType,
  SurveyQuestionUserAnswer,
} from '@stellar-lms-frontend/lms-graphql';
import { graphQLClient } from '../../lib/graphql';
import { objectApply, sanitizeHtml } from '@stellar-lms-frontend/common-utils';
import { GraphQLClient } from 'graphql-request';
import {
  Assessment,
  AssessmentSubmission,
  CourseEvent,
  DISCUSSION_PAGE_SIZE,
  DiscussionItem,
  Evaluation,
  PublishedSurvey,
  QuestionType,
  SubmissionResultWithScore,
} from '@stellar-lms-frontend/lms-api';
import { mapDiscussionItem } from '../workbasedactions/work-based-actions.api';
import { typemapToAssessmentQuestionUserAnswers } from '@stellar-lms-frontend/lms-components';

export const evaluateAssessmentQuestion = (
  client: GraphQLClient,
  learningActivityStepId: string,
  questionId: string,
  assessmentId: string,
  answer: AssessmentSubmission
): Promise<Evaluation> => {
  const answerData = typemapToAssessmentQuestionUserAnswers(answer.answers);

  return client
    .request(
      graphql(`
        mutation EvaluatePublishedAssessmentQuestion(
          $learningActivityStepId: ID!
          $id: ID!
          $questionId: ID!
          $answers: [AssessmentQuestionUserAnswer!]!
        ) {
          evaluatePublishedAssessmentQuestion(
            learningActivityStepId: $learningActivityStepId
            id: $id
            questionId: $questionId
            answers: $answers
          ) {
            id
            questionId
            type
            text
            evaluated
            evaluation
            score
            totalPoints
            earnedPoints
            answered
            feedback
            answers {
              id
              answerId
              label
              mediaType
              answerCorrect
              answerText
              userAnswered
              userAnsweredCorrect
              userInput
            }
          }
        }
      `),
      {
        learningActivityStepId,
        id: assessmentId,
        questionId,
        answers: answerData,
      }
    )
    .then((data) => {
      return {
        ...data.evaluatePublishedAssessmentQuestion,
        type: data.evaluatePublishedAssessmentQuestion.type.toLowerCase() as QuestionType,
        feedback: sanitizeHtml(data.evaluatePublishedAssessmentQuestion.feedback ?? ''),
        text: sanitizeHtml(data.evaluatePublishedAssessmentQuestion.text ?? ''),
        assessmentId: assessmentId,
        leftLabel: '',
        rightLabel: '',
        inputMaxLength: 0,
        label: '',
        answers: data.evaluatePublishedAssessmentQuestion.answers?.map((a) => ({
          ...a,
          label: a.label ?? '',
          userAnswered: a.userAnswered ?? false,
          userInput: a.userInput ?? '',
          answerCorrect: a.answerCorrect ?? false,
          answerText: a.answerText ?? '',
          userAnsweredCorrect: a.userAnsweredCorrect ?? false,
          text: '',
          other: false,
        })),
        answerOptions: [],
      };
    });
};

export const fetchBlockContentStep = (variables: QueryLearningActivityStepArgs) => {
  return graphQLClient('learner').request(
    graphql(`
      query BlockContentStep($learningActivityId: ID!, $stepId: ID!) {
        learningActivityStep(learningActivityId: $learningActivityId, stepId: $stepId) {
          id
          title
          headerImage {
            url
            fileId
            isFullWidth
          }
          ... on BlockContentStep {
            __typename
            blocks {
              content
              id
              type
            }
          }
        }
      }
    `),
    variables
  );
};

export const fetchScenarioStep = (variables: ScenarioStepQueryVariables) => {
  return graphQLClient('mentor')
    .request(
      graphql(`
        query ScenarioStep($learningActivityId: ID!, $stepId: ID!) {
          learningActivityStep(learningActivityId: $learningActivityId, stepId: $stepId) {
            ... on ScenarioStep {
              __typename
              id
              title
              headerImage {
                url
                fileId
                isFullWidth
              }
              levels {
                id
                text
                multiple
                answers {
                  correct
                  feedback
                  id
                  text
                }
                spacedRepetitionEnabled
              }
            }
          }
        }
      `),
      variables
    )
    .then((res) => objectApply(res, (v: any) => (typeof v === 'string' ? sanitizeHtml(v) : v)));
};

export const getAssessmentById = (
  client: GraphQLClient,
  id: string,
  activityId: string
): Promise<Assessment> => {
  return client
    .request(
      graphql(`
        query GetAssessmentById($id: ID!, $externalId: ID) {
          publishedAssessment(id: $id, externalId: $externalId) {
            id
            title
            assessmentId
            showFinalScore
            showQuestionLevelFeedbackAlways
            showAnswers
            questionEvaluation
            pagePerQuestion
            questions {
              id
              questionId
              type
              text
              totalPoints
              answered
              inputMaxLength
              answers {
                id
                mediaType
                label
                answerText
                userAnswered
                userInput
              }
              evaluatedAnswers {
                id
                answerId
                label
                mediaType
                answerCorrect
                answerText
                userAnswered
                userAnsweredCorrect
                userInput
              }
              answerOptions
            }
            evaluatedQuestions {
              id
              questionId
              type
              text
              evaluated
              evaluation
              score
              totalPoints
              earnedPoints
              answered
              feedback
              answers {
                id
                answerId
                label
                mediaType
                answerCorrect
                answerText
                userAnswered
                userAnsweredCorrect
                userInput
              }
            }
          }
        }
      `),
      {
        id,
        externalId: activityId,
      }
    )
    .then((data) => {
      return mapPublishedAssessment(data.publishedAssessment);
    });
};

export const getDiscussionItems = (
  client: GraphQLClient,
  activityId: string,
  stepId: string,
  offset = 0
): Promise<DiscussionItem[]> => {
  return client
    .request(
      graphql(`
        query GetLearningActivityDiscussionItems(
          $activityId: ID!
          $stepId: ID!
          $offset: Int
          $pageSize: Int
        ) {
          discussionItems(
            activityId: $activityId
            stepId: $stepId
            offset: $offset
            pageSize: $pageSize
          ) {
            totalItems
            items {
              id
              text
              nrOfLikes
              liked
              secondsSinceLastUpdate
              createdAt
              createdBy {
                id
                name
                thumbUri
              }
              replies {
                id
                text
                nrOfLikes
                liked
                secondsSinceLastUpdate
                createdAt
                createdBy {
                  id
                  name
                }
              }
            }
          }
        }
      `),
      {
        activityId,
        stepId,
        offset,
        pageSize: DISCUSSION_PAGE_SIZE,
      }
    )
    .then((data) => {
      return data.discussionItems.items.map((item: any) =>
        mapDiscussionItem(item, activityId, stepId)
      );
    });
};

export const getLastAssessmentSubmission = (
  client: GraphQLClient,
  variables: GetLastAssessmentSubmissionQueryVariables
): Promise<SubmissionResultWithScore | undefined> => {
  return client
    .request(
      graphql(`
        query GetLastAssessmentSubmission($learningActivityStepId: ID!, $assessmentId: ID!) {
          publishedAssessmentLastSubmission(
            learningActivityStepId: $learningActivityStepId
            assessmentId: $assessmentId
          ) {
            id
            title
            pagePerQuestion
            questionEvaluation
            user {
              id
              name
            }
            assessmentId
            submissionDate
            submitted
            nrOfAttempts
            externalId
            timeSpentInS
            score
            highestScore
            averageScore
            earnedPoints
            totalPoints
            correctQuestions
            totalQuestions
            showAnswers
            questions {
              id
              questionId
              type
              text
              evaluated
              evaluation
              score
              totalPoints
              earnedPoints
              answered
              feedback
              answers {
                id
                answerId
                label
                mediaType
                answerCorrect
                answerText
                userAnswered
                userAnsweredCorrect
                userInput
              }
            }
          }
        }
      `),
      variables
    )
    .then((data) => {
      if (data.publishedAssessmentLastSubmission) {
        const lastSubmission = data.publishedAssessmentLastSubmission;
        return {
          ...lastSubmission,
          timeSpentInS: lastSubmission.timeSpentInS ?? 0,
          questions: lastSubmission.questions.map((q) => {
            return {
              ...q,
              type: q.type.toLowerCase() as QuestionType,
              assessmentId: lastSubmission.assessmentId ?? '',
              text: sanitizeHtml(q.text ?? ''),
              feedback: q.feedback ? sanitizeHtml(q.feedback) : '',
              leftLabel: '',
              rightLabel: '',
              inputMaxLength: 0,
              label: '',
              answerOptions: [],
              answers: q.answers.map((a) => {
                return {
                  ...a,
                  text: a.answerText ?? '',
                  label: a.answerText ?? '',
                  other: false,
                  userAnswered: a.userAnswered ?? false,
                  userInput: a.userInput ?? '',
                  answerCorrect: a.answerCorrect ?? false,
                  answerText: a.answerText ?? '',
                  userAnsweredCorrect: a.userAnsweredCorrect ?? false,
                  feedback: '',
                };
              }),
            };
          }),
        };
      } else {
        return undefined;
      }
    });
};

export const getSurveyById = (
  client: GraphQLClient,
  id: string,
  externalId: string
): Promise<PublishedSurvey> => {
  return client
    .request(
      graphql(`
        query GetSurveyById($id: ID!, $externalId: ID) {
          publishedSurvey(id: $id, externalId: $externalId) {
            id
            title
            externalId
            description
            submitted
            submissionDate
            pagePerQuestion
            questions {
              id
              questionId
              type
              text
              answered
              visibleToMentors
              leftLabel
              rightLabel
              required
              answers {
                id
                answerId
                userInput
                other
                text
                userAnswered
              }
            }
          }
        }
      `),
      {
        id,
        externalId,
      }
    )
    .then((data) => {
      return mapPublishedSurvey(data.publishedSurvey);
    });
};

export const getLastSurveySubmission = (
  client: GraphQLClient,
  externalId: string,
  surveyId?: string
): Promise<PublishedSurvey | undefined> => {
  return client
    .request(
      graphql(`
        query GetLastSurveySubmission($externalId: ID!, $surveyId: ID) {
          publishedSurveyLastSubmission(externalId: $externalId, surveyId: $surveyId) {
            id
            title
            externalId
            description
            submitted
            submissionDate
            pagePerQuestion
            questions {
              id
              questionId
              type
              text
              answered
              visibleToMentors
              leftLabel
              rightLabel
              required
              answers {
                id
                answerId
                userInput
                other
                text
                userAnswered
              }
            }
          }
        }
      `),
      {
        externalId,
        surveyId,
      }
    )
    .then((data) => {
      return data.publishedSurveyLastSubmission
        ? mapPublishedSurvey(data.publishedSurveyLastSubmission)
        : undefined;
    });
};

export const submitAnswers = (
  client: GraphQLClient,
  learningActivityStepId: string,
  id: string,
  answers: SurveyQuestionUserAnswer[]
) => {
  return client
    .request(
      graphql(`
        mutation SubmitPublishedSurvey(
          $learningActivityStepId: ID!
          $id: ID!
          $answers: [SurveyQuestionUserAnswer!]!
        ) {
          submitPublishedSurvey(
            learningActivityStepId: $learningActivityStepId
            id: $id
            answers: $answers
          )
        }
      `),
      {
        learningActivityStepId,
        id,
        answers,
      }
    )
    .then((data) => {
      return data.submitPublishedSurvey;
    });
};

export const submitPartialAssessment = (
  client: GraphQLClient,
  learningActivityStepId: string,
  submission: AssessmentSubmission
): Promise<boolean> => {
  const answerData = typemapToAssessmentQuestionUserAnswers(submission.answers);

  return client
    .request(
      graphql(`
        mutation SubmitPartialPublishedAssessment(
          $learningActivityStepId: ID!
          $id: ID!
          $answers: [AssessmentQuestionUserAnswer!]!
        ) {
          submitPartialPublishedAssessment(
            learningActivityStepId: $learningActivityStepId
            id: $id
            answers: $answers
          )
        }
      `),
      {
        learningActivityStepId,
        id: submission.assessment.id,
        answers: answerData,
      }
    )
    .then((data) => {
      return data.submitPartialPublishedAssessment;
    });
};

export const trackCourseEvent = async (client: GraphQLClient, data: CourseEvent) => {
  let sessionEventType: SessionEventType = SessionEventType.Ping;
  if (data.type === 'close') {
    sessionEventType = SessionEventType.Close;
  } else if (data.type === 'open') {
    sessionEventType = SessionEventType.Open;
  }

  return client
    .request(
      graphql(`
        mutation TrackCourseLearningSessionEvent($courseId: ID!, $event: LearningSessionEvent!) {
          trackCourseLearningSessionEvent(courseId: $courseId, event: $event)
        }
      `),
      {
        courseId: data.courseId,
        event: {
          timezone: data.timezone,
          moduleId: data.moduleId,
          activityId: data.activityId,
          type: sessionEventType,
          learningSessionId: data.learningSessionId,
        },
      }
    )
    .then((data) => {
      return {
        id: data.trackCourseLearningSessionEvent as string,
      };
    });
};

export const mapPublishedAssessment = (assessment: PublishedAssessment): Assessment => {
  return {
    ...assessment,
    id: assessment.id ?? '',
    evaluatedQuestions: assessment.evaluatedQuestions?.map((eQ) => ({
      ...eQ,
      type: eQ.type.toLowerCase() as QuestionType,
      feedback: sanitizeHtml(eQ.feedback ?? ''),
      text: sanitizeHtml(eQ.text ?? ''),
      assessmentId: assessment.id ?? '',
      leftLabel: '',
      rightLabel: '',
      inputMaxLength: 0,
      label: '',
      answers: eQ.answers?.map((a) => ({
        ...a,
        label: a.label ?? '',
        userAnswered: a.userAnswered ?? false,
        userInput: a.userInput ?? '',
        answerCorrect: a.answerCorrect ?? false,
        answerText: a.answerText ?? '',
        userAnsweredCorrect: a.userAnsweredCorrect ?? false,
        text: '',
        other: false,
      })),
      answerOptions: [],
    })),
    questions: assessment.questions.map((q) => {
      return {
        ...q,
        // remove html formatting from question title
        assessmentId: assessment.id ?? '',
        text: sanitizeHtml(q.text ?? ''),
        type: q.type.toLowerCase() as QuestionType,
        leftLabel: '',
        rightLabel: '',
        label: '',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        answers: q.answers.map((a: any) => {
          // This should be fixed with better typing,  there is a small difference in onsophic that we map here (answerText to text)
          return {
            ...a,
            // Transforming to fit the structure of surveys
            text: a.answerText,
            label: a.answerText,
          };
        }),
      };
    }),
  };
};

export const mapPublishedSurvey = (survey: any): PublishedSurvey => {
  return {
    id: survey.id,
    title: survey.title,
    submitted: survey.submitted,
    questions: survey.questions.map((q: any) => {
      return {
        id: q.id,
        questionId: q.questionId,
        type: q.type.toLowerCase(),
        answered: q.answered,
        visibleToMentors: q.visibleToMentors,
        leftLabel: q.leftLabel,
        rightLabel: q.rightLabel,
        required: q.required,
        text: sanitizeHtml(q.text),
        answers: q.answers.map((a: any) => ({
          id: a.id,
          answerId: a.answerId,
          text: a.text,
          other: a.other,
          userAnswered: a.userAnswered,
          userInput: a.userInput,
          label: a.text,
        })),
      };
    }),
  };
};
