import { SortOption } from './types';

export const sortData = <TDataItem>(
  itemA: TDataItem,
  itemB: TDataItem,
  sortOption: SortOption<TDataItem>
) => {
  const fieldA = itemA[sortOption.fieldName];
  const fieldB = itemB[sortOption.fieldName];

  if (fieldA instanceof Date && fieldB instanceof Date) {
    return sortOption.sortDirection === 'asc'
      ? fieldA.getTime() - fieldB.getTime()
      : fieldB.getTime() - fieldA.getTime();
  }

  if (typeof fieldA === 'string' && typeof fieldB === 'string') {
    const comp = fieldA.localeCompare(fieldB);
    return sortOption.sortDirection === 'asc' ? comp : -comp;
  }

  if (typeof fieldA === 'number' && typeof fieldB === 'number') {
    return sortOption.sortDirection === 'asc' ? fieldA - fieldB : fieldB - fieldA;
  }

  return 0;
};
