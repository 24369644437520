import { QuizMultipleChoiceSettingsCard } from '@stellar-lms-frontend/ui-components';
import {
  AssessmentQuestionTypeNamesEnum,
  AssessmentQuestionTypes,
  AssessmentSuggestedQuestionTypeNamesEnum,
  AssessmentSuggestedQuestionTypes,
} from '../util-functions';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';
import { FillInTheBlanksSuggestionCard } from './fill-in-the-blanks-suggestion-card';
import { AIStepEdit, AIStepEditProps } from '../../ai-step-edit/ai-step-edit';
import { useTranslation } from 'react-i18next';

export type AssessmentAIStepEditProps = Omit<
  AIStepEditProps<AssessmentSuggestedQuestionTypes, AssessmentQuestionTypes>,
  'renderSuggestion' | 'aiButtonLabel' | 'i18n'
>;

export const AssessmentAIStepEdit: React.FC<AssessmentAIStepEditProps> = (props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assessment-step-view' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const { stepId } = useParams();

  return (
    <AIStepEdit
      {...props}
      i18n={{
        loading: t('suggestions-loading-message'),
        suggestionsHint: {
          title: t('edit.suggestions-available'),
          link: (count: number) => t('edit.available-suggestions', { count }),
        },
        sidebar: {
          title: t('suggestions-title'),
          hint: t('edit.suggestions-hint'),
          load: {
            more: t('edit.load-more-suggestions'),
            message: t('suggestions-loading-message'),
          },
          buttons: {
            add: tGeneral('add'),
            cancel: tGeneral('cancel'),
          },
          retryMessage: {
            title: t('edit.retry-message.title'),
            description: t('edit.retry-message.description'),
            retry: tGeneral('retry'),
          },
        },
      }}
      aiButtonLabel={(count: number) => t('edit.floating.questions', { count })}
      renderSuggestion={({
        suggestion: s,
        isSelected,
        setAddedSuggestions,
        setAcceptedSuggestions,
        onRemoveSuggestion,
      }) => {
        switch (s.__typename) {
          case AssessmentSuggestedQuestionTypeNamesEnum.CHOICE_SUGGESTED_QUESTIONS:
            return (
              <QuizMultipleChoiceSettingsCard
                key={s.id}
                isHighlighted={isSelected}
                onClick={(value) => {
                  if (isSelected) {
                    onRemoveSuggestion(s.id);
                  } else {
                    setAcceptedSuggestions((prev) => prev.add(s.id));
                    setAddedSuggestions((prev) => [
                      ...prev,
                      {
                        ...value,
                        id: s.id,
                        __typename: AssessmentQuestionTypeNamesEnum.QUIZ_CHOICE_QUESTION,
                        answers: value.answers.map((a) => ({ ...a, id: a.id ?? '' })),
                      },
                    ]);
                  }
                }}
                isPreview
                value={{
                  ...s,
                  spacedRepetitionEnabled: true,
                  multiple: false,
                  answers: s?.answers?.flatMap((a) => (a ? [a] : [])) ?? [],
                }}
              />
            );
          case AssessmentSuggestedQuestionTypeNamesEnum.FILL_IN_THE_BLANKS_SUGGESTED_QUESTION:
            return (
              <FillInTheBlanksSuggestionCard
                suggestion={s}
                isSelected={isSelected}
                onRemoveSuggestion={onRemoveSuggestion}
                setAddedSuggestions={setAddedSuggestions}
                setAcceptedSuggestions={setAcceptedSuggestions}
              />
            );
          default:
            Sentry.captureException(new Error('Assessment suggested question without __typename'), {
              extra: {
                stepId: stepId,
                questionId: s.id,
              },
            });
            return <> </>;
        }
      }}
    />
  );
};
