import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useScreenType } from '@stellar-lms-frontend/common-utils';
import { ReactNode, useState } from 'react';
import { NavHeader } from './nav-header';
import { SubNav, SubNavRightButtonConfig } from './sub-nav';
import { ButtonConfig, HeaderNavTheme } from './types';

export type ThemableNavEntriesFunc = (theme: HeaderNavTheme) => ReactNode[];

export type HeaderProps = {
  mainCenterComponent?: ReactNode;
  subNavTitle?: ReactNode;
  subNavSubtitle?: string;
  leftNavEntries?: ThemableNavEntriesFunc;
  centerNavEntries?: ThemableNavEntriesFunc;
  rightNavEntries?: ThemableNavEntriesFunc;
  leftButton?: ButtonConfig;
  rightButton?: SubNavRightButtonConfig;
};

export const Header: React.FC<HeaderProps> = ({
  leftNavEntries,
  centerNavEntries,
  rightNavEntries,
  mainCenterComponent,
  subNavTitle,
  subNavSubtitle,
  leftButton,
  rightButton,
}) => {
  const [isFloating, setIsFloating] = useState(false);
  const { isTailwindLg } = useScreenType();

  const hasNavEntries = !!leftNavEntries || !!centerNavEntries || !!rightNavEntries;

  useScrollPosition(({ currPos }) => {
    setIsFloating(hasNavEntries && currPos.y < 0);
  });

  const actualSubNavTitle = isTailwindLg ? subNavTitle : subNavTitle || mainCenterComponent;
  const showCenterNavWhenFloating = isFloating && !actualSubNavTitle;
  const theme = isFloating ? 'white' : 'blue';

  if (!hasNavEntries) return null;

  return (
    <>
      <NavHeader
        title={!isFloating ? mainCenterComponent : undefined}
        centerNavEntries={showCenterNavWhenFloating ? centerNavEntries?.(theme) ?? [] : []}
        rightNavEntries={rightNavEntries?.(theme) ?? []}
        leftNavEntries={leftNavEntries?.(theme) ?? []}
        theme={theme}
      />
      <SubNav
        title={actualSubNavTitle}
        subtitle={subNavSubtitle}
        leftButton={leftButton}
        rightButton={rightButton}
        // only show these entries when the top bar cannot show them because of the navHeaderTitle
        entries={!showCenterNavWhenFloating && mainCenterComponent ? centerNavEntries?.(theme) : []}
      />
    </>
  );
};
