import { FlexTable, HintDescription, Modal } from '@stellar-lms-frontend/ui-components';
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo } from 'react';
import ErrorMessageCell from './components/error-message-cell';
import { useTranslation } from 'react-i18next';

const DEFAULT_PAGE_SIZE = 20;

export type ErrorMessageTableRowShape = {
  line: number;
  email: string;
  error: string;
};

export type BulkErrorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  errors: ErrorMessageTableRowShape[];
};

const columnHelper = createColumnHelper<ErrorMessageTableRowShape>();

export const BulkErrorModal: React.FC<BulkErrorModalProps> = ({ isOpen, onClose, errors }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organization.members-tab.bulk-error-modal',
  });

  const columns = useMemo<ColumnDef<ErrorMessageTableRowShape, any>[]>(
    () => [
      columnHelper.accessor((row) => row.line, {
        header: t('table.headers.line'),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row.email, {
        header: t('table.headers.email'),
        cell: (info) => <span className="text-sm">{info.getValue()}</span>,
      }),
      columnHelper.accessor((row) => row.error, {
        header: t('table.headers.error'),
        cell: (info) => <ErrorMessageCell error={info.getValue()} />,
      }),
    ],
    [t]
  );

  const table = useReactTable({
    data: errors,
    columns,
    initialState: { pagination: { pageSize: DEFAULT_PAGE_SIZE } },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Modal
      className="bg-surface-01 h-[566px] min-w-[720px]"
      isOpen={isOpen}
      onClose={onClose}
      title={t('title')}
    >
      <div className="space-y-6">
        <HintDescription
          theme="negative"
          description={<p className="text-sm">{t('hint')}</p>}
        />
        <FlexTable
          table={table}
          i18n={{
            pageSizeSelector: {
              showing: t('table.page-size-selector.showing'),
              of: (count) => t('table.page-size-selector.of', { count }),
            },
          }}
        />
      </div>
    </Modal>
  );
};
