import { DragItemProps } from '../../list';
import { ScaleSettingsForm } from './scale-settings-form';
import Scale from './scale';
import { FontBody } from '../../text';
import { SettingsCard } from '../../card';

export type ScaleFormErrors = {
  title?: {
    message?: string;
  };
  leftLabel?: {
    message?: string;
  };
  rightLabel?: {
    message?: string;
  };
};

// Configurable in the future, right now it's this length by default and unchangeable.
const SCALE_LENGTH = 6;

export type ScaleSettings = {
  title: string;
  leftLabel: string;
  rightLabel: string;
  required: boolean;
  showToMentors: boolean;
};

export type ScaleSettingsCardProps = {
  value: ScaleSettings;
  onChange: (data: ScaleSettings) => void;
  renderShowToMentorsOption: boolean;
  dragProps?: DragItemProps;
  isOpen: boolean;
  onDelete: () => void;
  onToggleOpenState?: () => void;
  errors?: ScaleFormErrors;
};

export const ScaleSettingsCard: React.FC<ScaleSettingsCardProps> = ({
  value,
  isOpen,
  onChange,
  onDelete,
  onToggleOpenState,
  dragProps,
  errors,
  renderShowToMentorsOption,
}) => {
  return (
    <SettingsCard
      hasOptionsMenu
      onToggleOpenState={onToggleOpenState}
      onDelete={onDelete}
      isOpen={isOpen}
      dragProps={dragProps}
      openChildren={
        <ScaleSettingsForm
          renderShowToMentorsOption={renderShowToMentorsOption}
          value={value}
          onChange={(newValue) => onChange(newValue)}
          errors={errors}
        />
      }
      closedChildren={
        <>
          <FontBody
            type="emphasis"
            className="text-text-02"
          >
            {value.title}
          </FontBody>
          <Scale
            isDisabled
            options={Array.from({ length: SCALE_LENGTH }, (v, i) => ({
              id: i.toString(),
              label: (i + 1).toString(),
            }))}
            left={value.leftLabel}
            right={value.rightLabel}
          />
        </>
      }
      hasError={!!errors}
    />
  );
};
