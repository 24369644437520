export type BulletElementProps = {
  index?: number;
};

export type BulletElementType = React.ElementType<BulletElementProps>;

export const KeyTakeawayBullet: React.FC<BulletElementProps> = () => {
  return (
    <div className="border-border-01 flex h-6 w-6 items-center justify-center rounded-full border-2">
      <div className="bg-text-01 h-1 w-1 rounded-full" />
    </div>
  );
};

export const PracticalTipsBullet: React.FC<BulletElementProps> = ({ index = 0 }) => {
  return (
    <div className="bg-surface-02 flex h-10 w-10 items-center justify-center rounded-full">
      <p className="text-text-01">{index + 1}</p>
    </div>
  );
};
