import { QueryJourneysArgs, graphql } from '@stellar-lms-frontend/lms-graphql';
import { GraphQLClient } from 'graphql-request';

export const getJourneys = (client: GraphQLClient, variables: QueryJourneysArgs) => {
  return client.request(
    graphql(`
      query Journeys($companyId: ID!) {
        journeys(companyId: $companyId) {
          id
          title
        }
      }
    `),
    variables
  );
};
