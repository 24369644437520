import { ReactNode } from 'react';
import { BaseCard } from '../base-card/base-card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';

export type ErrorCardProps = {
  className?: string;
  childrenClassName?: string;
  title?: ReactNode;
  children?: ReactNode;
  hasShadow?: boolean;
  hasIcon?: boolean;
  color?: 'red';
};

export const ErrorCard: React.FC<ErrorCardProps> = ({
  className = '',
  childrenClassName = '',
  title,
  hasIcon = true,
  children,
  hasShadow = false,
  color = 'red',
}) => {
  return (
    <BaseCard
      hasShadow={hasShadow}
      className={className}
      childrenClassName={`${childrenClassName} font-lexend flex items-center gap-1 p-4`}
      color={color}
    >
      {hasIcon && (
        <div className="shrink-0 text-center ">
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className={'h-6 w-6'}
          />
        </div>
      )}
      <div className="grow">
        {title && typeof title === 'string' ? <p className="font-medium">{title}</p> : title}
        {children}
      </div>
    </BaseCard>
  );
};
