import {
  Dropdown,
  Input,
  LinkButton,
  Modal,
  PrimaryButton,
} from '@stellar-lms-frontend/ui-components';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { formatDate } from '@stellar-lms-frontend/common-utils';
import { SubscriptionType } from './types';

export type UpdateSubscriptionModalProps = {
  subscription: {
    id: string;
    type: SubscriptionType;
    startDate: Date | undefined;
    endDate: Date | undefined;
  };
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: UpdateSubscriptionFormData) => void;
};

export type UpdateSubscriptionFormData = {
  id: string;
  license: 'trial' | 'stellar_labs_managed' | 'internal';
  startDate: string;
  endDate: string;
};

const isSupportedSubscriptionType = (type: SubscriptionType): boolean => {
  return type === 'INTERNAL' || type === 'STELLAR_LABS_MANAGED' || type === 'TRIAL';
};

const mapToLicense = (type: SubscriptionType): UpdateSubscriptionFormData['license'] => {
  switch (type) {
    case 'TRIAL':
      return 'trial';
    case 'INTERNAL':
      return 'internal';
    case 'STELLAR_LABS_MANAGED':
      return 'stellar_labs_managed';
    case 'STRIPE_MANAGED':
      return 'trial'; // Should never happen
    case 'UNKNOWN':
      return 'trial'; // Should never happen
  }
};

const mapToFormData = (
  subscription: UpdateSubscriptionModalProps['subscription']
): UpdateSubscriptionFormData => {
  const today = moment().startOf('day');
  const defaultTrialEndDate = moment().add(7, 'day').endOf('day');

  return {
    id: subscription.id,
    license: mapToLicense(subscription.type),
    startDate: formatDate(subscription.startDate ? moment(subscription.startDate) : today),
    endDate: formatDate(subscription.endDate ? moment(subscription.endDate) : defaultTrialEndDate),
  };
};

export const UpdateSubscriptionModal: React.FC<UpdateSubscriptionModalProps> = ({
  subscription,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'update-subscription' });
  const tomorrow = moment().add(1, 'days').startOf('day');

  if (!subscription || !isSupportedSubscriptionType(subscription.type)) {
    onClose();
  }

  const schema = yup.object().shape({
    license: yup.string().oneOf(['trial', 'stellar_labs_managed', 'internal']),
    startDate: yup.date(),
    endDate: yup
      .date()
      .typeError(t('fields.license.trial.end.wrong-type'))
      .required(t('fields.license.trial.end.required'))
      .min(tomorrow, t('fields.license.trial.end.min', { date: formatDate(tomorrow) })),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset,
  } = useForm<UpdateSubscriptionFormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: mapToFormData(subscription),
  });

  useEffect(() => {
    reset(mapToFormData(subscription));
  }, [subscription]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('title')}
      buttons={{
        hasShadow: false,
        buttons: [
          <PrimaryButton
            key={1}
            onClick={handleSubmit(onSubmit)}
            label={t('buttons.save')}
            enabled={isValid}
            htmlType="submit"
          />,
          <LinkButton
            key={2}
            onClick={onClose}
            label={t('buttons.cancel')}
            htmlType="button"
          />,
        ],
      }}
    >
      <form className="space-y-6">
        <Dropdown
          label={t('fields.license.label')}
          htmlId="license"
          {...register('license')}
        >
          <option
            key={'license_trial'}
            value="trial"
          >
            {t('fields.license.options.trial')}
          </option>
          <option
            key={'license_stellar_labs_managed'}
            value="stellar_labs_managed"
          >
            {t('fields.license.options.stellar_labs_managed')}
          </option>
          <option
            key={'license_internal'}
            value="internal"
          >
            {t('fields.license.options.internal')}
          </option>
        </Dropdown>
        <div className="flex flex-row space-x-6">
          <Controller
            control={control}
            name="startDate"
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                label={t('fields.license.trial.start.label')}
                htmlId="startDate"
                htmlType="date"
                enabled={false}
              />
            )}
          />
          <Controller
            control={control}
            name="endDate"
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                label={t('fields.license.trial.end.label')}
                htmlId="endDate"
                htmlType="date"
                error={errors.endDate?.message}
              />
            )}
          />
        </div>
      </form>
    </Modal>
  );
};
