import { ScrollContainer } from '@stellar-lms-frontend/ui-components';
import { Dispatch } from 'react';
import { Edit } from './edit';
import { View } from './view';
import { MutationUpdateCourseModuleOrderArgs } from '@stellar-lms-frontend/lms-graphql';

export type ModuleSidebarI18N = {
  moduleListTitle: string;
  add?: string;
  save?: string;
  cancel?: string;
  editDetails?: string;
  duplicate?: string;
  delete?: string;
  moveUp?: string;
  moveDown?: string;
  loading?: { title: string; description: string };
  newModule?: (count: number) => string;
  aiBadgeLabel?: string;
  edit?: string;
};

export type ModuleSidebarActions = {
  module: {
    duplicate?: (moduleId: string) => void;
    delete?: (id: string) => void;
    updateOrder?: (variables: MutationUpdateCourseModuleOrderArgs) => void;
    handleCreate?: () => void;
  };
};

export type LoadingMoreModules = { loading: boolean; amount: number };

export type ModuleSidebarProps = {
  selectModule: Dispatch<string>;
  loadingMoreModules?: LoadingMoreModules;
  highlightedModuleId?: string;
  isEditing?: boolean;
  actions?: ModuleSidebarActions;
  courseId: string;
  // Specific id for the scroll container
  scrollContainerId?: string;
  isAiCourse?: boolean;
  isAIGenerating: boolean;
};

export const ModuleSidebar: React.FC<ModuleSidebarProps> = ({
  selectModule,
  loadingMoreModules = { loading: false, amount: 0 },
  highlightedModuleId,
  isEditing,
  actions,
  courseId,
  scrollContainerId,
  isAiCourse = false,
  isAIGenerating,
}) => {
  return (
    <div className="hidden h-full w-[424px] lg:flex lg:flex-col ">
      <div className="w-full grow"></div>
      <ScrollContainer
        scrollOnDesktop
        htmlId={scrollContainerId ?? 'module-list-sidebar-scroll-container'}
      >
        {isEditing ? (
          <Edit
            selectModule={selectModule}
            loadingMoreModules={loadingMoreModules}
            highlightedModuleId={highlightedModuleId}
            actions={actions}
            courseId={courseId}
            isAiCourse={isAiCourse}
            isAIGenerating={isAIGenerating}
          />
        ) : (
          <View
            selectModule={selectModule}
            loadingMoreModules={loadingMoreModules}
            highlightedModuleId={highlightedModuleId}
          />
        )}
      </ScrollContainer>
    </div>
  );
};
