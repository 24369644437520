import { BaseCard, CheckBox, YoutubeIcon } from '@stellar-lms-frontend/ui-components';
import { Video, VideoService } from '@stellar-lms-frontend/lms-graphql';
import { ChangeEvent, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';

export const VideoSuggestionCard = ({
  video,
  onSelectVideo,
  onDeselectVideo,
}: {
  video?: Video;
  onSelectVideo: (video: Video) => void;
  onDeselectVideo: (video: Video) => void;
}) => {
  const [isChecked, setIsChecked] = useState(false);

  return !video ? (
    <BaseCard
      color="surface-02"
      className="my-2"
      childrenClassName="p-4"
      hasShadow={false}
    >
      <LoadingCard />
    </BaseCard>
  ) : (
    <BaseCard
      color="surface-02"
      className="my-2"
      childrenClassName="p-4"
      isHighlighted={isChecked}
      hasShadow={false}
    >
      <div className="flex items-center gap-4">
        <CheckBox
          value={isChecked}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              setIsChecked(true);
              onSelectVideo(video);
            } else {
              setIsChecked(false);
              onDeselectVideo(video);
            }
          }}
        />

        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe
          width="181"
          allowFullScreen
          height="112"
          src={video.embedUrl}
        ></iframe>

        <div className="grow">
          <div className="flex gap-1">
            <div className="grow">
              <VideoProviderIcon provider={video?.service} />
              <span className="font-lexend type-body-medium text-text-01 line-clamp-2 break-words [word-break:break-word] ">
                {video.title}
              </span>
            </div>
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              className="text-md shrink-0 cursor-pointer"
              onClick={() => {
                window.open(video.url, '_blank');
              }}
            />
          </div>
          {video.description && (
            <span className="font-lexend type-tiny text-text-01 line-clamp-2 break-words [word-break:break-word]">
              {video.description}
            </span>
          )}
        </div>
      </div>
    </BaseCard>
  );
};

const LoadingCard = () => (
  <div className="flex items-center gap-4 ">
    <div>
      <Skeleton
        width={24}
        height={24}
      />
    </div>
    <Skeleton
      width={181}
      height={112}
    />
    <div className="grow">
      <div className="flex gap-1">
        <div className="grow">
          <Skeleton
            width={24}
            height={24}
          />
          <Skeleton width={150} />
        </div>
      </div>
      <div>
        <Skeleton width={200} />
        <Skeleton width={170} />
      </div>
    </div>
  </div>
);

const VideoProviderIcon = ({ provider }: { provider?: VideoService }) => {
  if (provider) {
    switch (provider) {
      case VideoService.Youtube:
        return <YoutubeIcon />;
      case VideoService.Unknown:
        return null;
    }
  } else {
    return (
      <Skeleton
        width={20}
        height={20}
      />
    );
  }
};
