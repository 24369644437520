import { useEffect } from 'react';

// TODO: if this is used on multiple places in a page we run the risk that the overflow
// .     of the body element gets overwritten by another `useDisableScroll` hook, it's
// .     order dependent and will need to be checked in the future.
// .
// .     Because this is currently only used in modals it's probably not a real issue right now
// .     (with one modal open it would be difficult to open another), it's just when we start using it
// .     in other parts of the app. Only if we would render a modal open on page load with another modal
// .     defined later in the page the second modal might overwrite the overflow to 'auto' again.
// .
// .     One place where this might be an issue is the learner homepage where we have the
// .     onboarding modal and the notification sidebar that will both try to set the body overflow to hidden or auto.

export const useDisableScroll = (isDisabled: boolean) => {
  useEffect(() => {
    if (isDisabled) {
      window.document.body.style.overflow = 'hidden';
    } else {
      window.document.body.style.overflow = 'auto';
    }

    return () => {
      window.document.body.style.overflow = 'auto';
    };
  }, [isDisabled]);
};
