export const APP_BASE_URI = '/';

// These specific role routes will be merged
// Learner
export const LEARNER_URI = '/learner';
export const getLearnerCourseRoute = (courseId?: string) =>
  `${LEARNER_URI}/programme/${courseId ?? ''}`;
export const getLearnerCourseModulesRoute = (courseId?: string) =>
  `${LEARNER_URI}/programme/${courseId ?? ''}`;
export const getLearnerCourseDashboardRoute = (courseId?: string) =>
  `${LEARNER_URI}/programme/${courseId ?? ''}/overview`;
export const getLearnerCourseActionsRoute = (courseId?: string) =>
  `${LEARNER_URI}/programme/${courseId ?? ''}/actions`;

// Designer
export const DESIGNER_BASE_URI = '/designer';
export const getDesignerCourseRoute = (courseId?: string) =>
  `${DESIGNER_BASE_URI}/course/${courseId ?? ''}`;
export const getDesignerModulesRoute = (courseId = '') =>
  `${DESIGNER_BASE_URI}/course/${courseId}/journey`;
export const getDesignerCourseActionsRoute = (courseId = '') =>
  `${DESIGNER_BASE_URI}/course/${courseId}/actions`;
export const getDesignerCourseMembersRoute = (courseId = '') =>
  `${DESIGNER_BASE_URI}/course/${courseId}/learners`;
export const getDesignerCourseSurveysRoute = (courseId = '') =>
  `${DESIGNER_BASE_URI}/course/${courseId}/surveys`;
export const getDesignerCourseEditRoute = (courseId = '') =>
  `${DESIGNER_BASE_URI}/course/${courseId}/about`;
export const getDesignerCourseCreateRoute = () => `${DESIGNER_BASE_URI}/course/create`;

// Mentor
export const MENTOR_BASE_URI = '/mentor';
export const getMentorCourseDashboardRoute = (courseId?: string) =>
  `${MENTOR_BASE_URI}/course/${courseId ?? ''}`;
export const getMentorCourseModulesRoute = (courseId?: string) =>
  `${MENTOR_BASE_URI}/course/${courseId ?? ''}/journey`;
export const getMentorCourseActionsRoute = (courseId?: string) =>
  `${MENTOR_BASE_URI}/course/${courseId ?? ''}/actions`;
export const getMentorCourseMembersRoute = (courseId?: string) =>
  `${MENTOR_BASE_URI}/course/${courseId ?? ''}/learners`;
export const getMentorCourseSurveysRoute = (courseId?: string) =>
  `${MENTOR_BASE_URI}/course/${courseId ?? ''}/surveys`;

export const MORE_URI = '/more';
export const PROFILE_URI = '/profile';
export const ORGANIZATION_URI = '/organization';
export const ADMINISTRATION_URI = '/administration';
export const NOTIFICATIONS_URI = '/notifications';
export const SPACED_REPETITION_URI = '/spaced-repetition';
export const PRIVACY_POLICY = 'https://www.stellarlabs.eu/privacy-and-cookies-policy';
