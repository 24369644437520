import { useTranslation } from 'react-i18next';
import { TabLink } from './tab-link';

export const Sidebar = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'administration.tabs' });

  return (
    <div className="bg-sidebar-gradient p-16">
      <div className={`font-lexend flex flex-col gap-3`}>
        <TabLink
          to=""
          title={t('companies')}
          subTitle={''}
        />
      </div>
    </div>
  );
};
