import { LearningActivityUtils } from '@stellar-lms-frontend/common-utils';
import {
  LearningActivityOutline,
  LearningActivityProgress,
  CourseModuleOutline,
  Course,
} from '@stellar-lms-frontend/lms-graphql';
import { useCourseOutline } from '../../../hooks';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const useCourseData = (
  getCourseOutlineFunc: (input: { courseId: string }) => Promise<Course>
) => {
  const [learningActivity, setLearningActivity] = useState<LearningActivityOutline>();
  const [forceLoad, setForceLoad] = useState(false);
  const { courseId, learningActivityId, stepId } = useParams();
  const [moduleData, setModuleData] = useState<{
    modules: CourseModuleOutline[];
    moduleIndex: number;
    learningActivityIndexInModule: number;
    moduleId?: string;
  }>();

  const doLoad = useCallback((forceLoad = false) => {
    setForceLoad(forceLoad);
  }, []);

  const {
    useCourseOutlineQuery: { data, isSuccess },
    invalidateCache,
  } = useCourseOutline(courseId, getCourseOutlineFunc);

  const ret = useMemo(() => {
    let stepNumber = 1;

    if (stepId && learningActivity) {
      const stepIndex = learningActivity.steps?.findIndex((s) => s.id === stepId);
      if (stepIndex !== -1 && stepIndex !== undefined) {
        stepNumber = stepIndex + 1;
      }
    }

    const previousLearningActivityIndex = stepNumber - 2;

    if (!learningActivity?.steps) {
      invalidateCache();
    }

    if (!learningActivity) {
      return {
        learningActivity,
        currentStepNumber: stepNumber,
      };
    } else {
      return {
        learningActivity,
        previousLearningStep:
          previousLearningActivityIndex >= 0
            ? learningActivity.steps?.at(previousLearningActivityIndex)
            : undefined,
        nextLearningStep: learningActivity.steps?.at(stepNumber),
        nextUncompletedLearningStep: learningActivity.steps?.find((s) => !s.completed),
        totalStepCount: learningActivity.steps?.length,
        currentStepNumber: stepNumber,
      };
    }
  }, [invalidateCache, learningActivity, stepId]);

  useEffect(() => {
    if (isSuccess && data?.outline) {
      const moduleIndex = data.outline.modules?.findIndex((m) =>
        m.learningActivities?.some((la) => la.id === learningActivityId)
      );

      if (moduleIndex === -1 || moduleIndex === undefined) return;

      const learningActivityIndexInModule = data.outline.modules?.[
        moduleIndex
      ].learningActivities?.findIndex((la) => la.id === learningActivityId);

      if (learningActivityIndexInModule !== -1 && learningActivityIndexInModule !== undefined) {
        const module = data.outline.modules?.[moduleIndex];
        const learningActivity = module?.learningActivities?.[learningActivityIndexInModule];

        if (!forceLoad && learningActivity && learningActivity.id === learningActivityId) {
          setLearningActivity({
            id: learningActivity.id,
            title: learningActivity.title,
            locked: false,
            state: '',
            progress: LearningActivityProgress.Todo,
            enabled: true,
            placeholder: false,
            steps: learningActivity.steps,
            description: learningActivity.description,
            prerequisites: learningActivity.prerequisites,
            duration: learningActivity.duration,
            type: learningActivity.type,
          });
        } else if (learningActivity) {
          setLearningActivity({ ...learningActivity });
        }

        setModuleData({
          moduleIndex,
          learningActivityIndexInModule,
          modules: data.outline.modules ?? [],
          moduleId: data.outline.modules?.[moduleIndex].id,
        });
      }
    }
  }, [isSuccess, data, learningActivityId, forceLoad]);

  const { nextLearningActivity, prevLearningActivity } = useMemo(() => {
    return learningActivityId && data?.outline?.modules
      ? LearningActivityUtils.getAdjacentLearningActivities(
          data.outline.modules,
          learningActivityId
        )
      : { nextLearningActivity: undefined, prevLearningActivity: undefined };
  }, [data?.outline?.modules, learningActivityId]);

  return {
    ...ret,
    ...moduleData,
    prevLearningActivity,
    nextLearningActivity,
    doLoad,
    invalidateCache,
  };
};
