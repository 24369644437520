import { updateCourseObjectives } from '@stellar-lms-frontend/lms-api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as yup from 'yup';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  LogoLoader,
  Form,
  Heading3,
  Input,
  LeftArrowIcon,
  TextArea,
  Dropdown,
} from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { ObjectivesSection } from './components/objectives-section';
import { graphQLClient } from '../../../lib/graphql';
import { SkillsSection } from './components/skills-section';
import { Course, Language, transferToInputLanguage } from '@stellar-lms-frontend/lms-graphql';
import {
  getDesignerCourseDetails,
  QUERY_KEY as COURSE_DETAILS_QUERY_KEY,
  updateCourse,
} from './course-details.api';
import { UIShell } from '../../shell/ui-shell';
import { DESIGNER_COURSE_ROUTE } from '../constants/routes';
import { supportedLanguages, upperSupportedLanguage } from '../../../constants/languages';

export type FormShape = Pick<Course, 'title' | 'description' | 'skills'> & {
  language: string;
};

export type PatchCourseMutation = {
  courseId: string;
  data: FormShape;
};

export const CourseDetails: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'course-details' });
  const { t: tValidation } = useTranslation('translation', { keyPrefix: 'validation' });
  const { t: tLanguage } = useTranslation('translation', { keyPrefix: 'languages' });
  const { courseId } = useParams();
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: mutatePatchCourse } = useMutation(
    ({ courseId, data }: PatchCourseMutation) =>
      updateCourse(graphQLClient('designer'), {
        course: {
          id: courseId,
          title: data.title,
          description: data.description,
          skills: data.skills,
          language: transferToInputLanguage(data.language),
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([COURSE_DETAILS_QUERY_KEY, courseId]);
      },
    }
  );

  const { mutate: mutateUpdateCourseObjectives } = useMutation(
    (variables: { courseId: string; objectiveIds: string[] }) =>
      updateCourseObjectives(graphQLClient('designer'), variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([COURSE_DETAILS_QUERY_KEY, courseId]);
      },
    }
  );

  const courseQuery = useQuery(
    [COURSE_DETAILS_QUERY_KEY, courseId],
    ({ signal }) =>
      courseId
        ? getDesignerCourseDetails(graphQLClient('designer'), { id: courseId }, signal)
        : null,
    { enabled: !!courseId }
  );

  const schema = yup.object({
    title: yup.string().required(tValidation('required')),
  });

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors, isValidating, isValid, isDirty },
  } = useForm<FormShape>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const course = useMemo(() => {
    const course = courseQuery?.data;

    if (courseQuery.isSuccess && !isDirty) {
      reset({
        title: course?.title ?? '',
        description: course?.description ?? '',
        skills: course?.skills ?? [],
        language: course?.language?.toLowerCase() ?? Language.En.toLowerCase(),
      });
    }

    return course;
  }, [courseQuery?.data, courseQuery?.isSuccess, isDirty, reset]);

  const onSubmit = useCallback(
    (data: FormShape) => {
      course && mutatePatchCourse({ courseId: course.id, data });
    },
    [course, mutatePatchCourse]
  );

  useEffect(() => {
    clearTimeout(timerRef.current);
    timerRef.current =
      !isValidating && isDirty && isValid
        ? setTimeout(() => {
            handleSubmit(onSubmit)();
          }, 1000)
        : undefined;

    if (timerRef.current) {
      // Only cancel a query if we have a timer running.
      queryClient.cancelQueries([COURSE_DETAILS_QUERY_KEY, courseId]);
    }
  }, [handleSubmit, isValidating, onSubmit, isDirty, queryClient, courseId, isValid]);

  return (
    <UIShell
      subNavTitle={t('about')}
      leftButton={{
        leftIcon: <LeftArrowIcon className="fill-on-primary-01 h-5 w-5" />,
        action: () => navigate(DESIGNER_COURSE_ROUTE(courseId)),
        label: '',
      }}
    >
      {!(courseId && course) ? (
        <LogoLoader />
      ) : (
        <Form className="flex w-full max-w-[532px] flex-col items-stretch space-y-12">
          <div className="space-y-6">
            <Heading3>{t('general')}</Heading3>
            <Controller
              control={control}
              name="title"
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  htmlId={'course-title'}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value ?? undefined}
                  label={t('title')}
                  error={errors.title?.message}
                />
              )}
            />
            <TextArea
              {...register('description')}
              heightClassName={'h-[100px]'}
              htmlId={'course-description'}
              label={t('description')}
              error={errors.description?.message}
            />
            <Controller
              control={control}
              name="skills"
              render={({ field }) => (
                <SkillsSection
                  value={field.value ?? []}
                  onChange={field.onChange}
                />
              )}
            />
            <Dropdown
              label={t('language')}
              htmlId="language"
              hint={t('language-hint')}
              {...register('language')}
            >
              {supportedLanguages.map((language) => (
                <option
                  key={'language_' + language}
                  value={language}
                >
                  {tLanguage(upperSupportedLanguage(language))}
                </option>
              ))}
            </Dropdown>
          </div>
          <ObjectivesSection
            value={course.objectives ?? []}
            onChange={(objectives) => {
              mutateUpdateCourseObjectives({
                courseId,
                objectiveIds: objectives.map((obj) => obj.id),
              });
            }}
          />
        </Form>
      )}
    </UIShell>
  );
};
