import { yupResolver } from '@hookform/resolvers/yup';
import {
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
  updateCompany,
  useCurrentCompany,
} from '@stellar-lms-frontend/lms-graphql';
import {
  ContentContainer,
  Input,
  Dropdown,
  ScrollContainer,
} from '@stellar-lms-frontend/ui-components';
import { useMutation } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { transformOutgoingLanguageCode } from './users-tab/utils/transformers';
import { SupportedLanguagesOptions } from '../../types/types';

type FormData = {
  name: string;
  industry: string;
  language: string;
};

export type DetailsTabProps = {
  supportedLanguages: SupportedLanguagesOptions;
  defaultLanguage: string;
  graphQLClient: GraphQLClient;
};

export const DetailsTab: React.FC<DetailsTabProps> = ({
  supportedLanguages,
  defaultLanguage,
  graphQLClient,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'organization.details-tab' });
  const { t: tValidation } = useTranslation('translation', { keyPrefix: 'validation' });

  const {
    query: { data: company },
    updateCompanyDetails,
  } = useCurrentCompany(graphQLClient);

  const { mutate } = useMutation((variables: UpdateCompanyMutationVariables) =>
    updateCompany(graphQLClient, variables)
  );

  const timer = React.useRef<NodeJS.Timeout | null>(null);

  const schema = yup.object().shape({
    name: yup.string().required(tValidation('required')),
    industry: yup.string(),
    language: yup.string().oneOf(supportedLanguages.map(({ value }) => value)),
  });

  const {
    register,
    watch,
    control,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      language: company?.language ?? defaultLanguage,
      name: company?.name ?? '',
      industry: company?.industry ?? '',
    },
  });

  const formData = watch();

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      if (company?.id && isDirty) {
        mutate(
          {
            updateCompanyId: company?.id,
            input: {
              name: formData.name,
              industry: formData.industry,
              language: transformOutgoingLanguageCode(formData.language.toLowerCase()),
            },
          },
          {
            onSuccess: (data: UpdateCompanyMutation) => {
              data.updateCompany && updateCompanyDetails(data.updateCompany);
            },
          }
        );
      }
    }, 500);
  }, [
    company?.id,
    formData.industry,
    formData.name,
    formData.language,
    updateCompanyDetails,
    mutate,
    isDirty,
  ]);

  return (
    <ScrollContainer scrollOnDesktop>
      <ContentContainer>
        <div className="mx-auto w-full max-w-[504px] space-y-6">
          <h3 className="type-headline-3 text-text-01">{t('title')}</h3>
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                htmlId={'name'}
                label={t('fields.name.label')}
                error={errors.name?.message}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="industry"
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                htmlId={'industry'}
                label={t('fields.industry.label')}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="language"
            render={({ field: { onChange, onBlur, value } }) => (
              <Dropdown
                label={t('language')}
                htmlId="language"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              >
                {supportedLanguages.map(({ label, value }) => (
                  <option
                    key={'language_' + value}
                    value={value}
                  >
                    {label}
                  </option>
                ))}
              </Dropdown>
            )}
          />
          <div className="space-y-4">
            <p className="type-body text-text-02">{t('branding')}</p>
            <p className="type-small text-text-02">{t('branding-description')}</p>
          </div>
        </div>
      </ContentContainer>
    </ScrollContainer>
  );
};
