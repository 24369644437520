import moment from 'moment';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type DueType = 'overdue' | 'future' | 'soon' | 'today';
export type Due = {
  type: DueType;
  label: string;
  date: moment.Moment;
};

export const useDueDate = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'actions' });

  return useCallback(
    (date: Date): Due => {
      const dueDate = moment(date);
      const now = moment(new Date()).startOf('day');
      const daysToGo = Math.trunc(moment.duration(dueDate.diff(now)).asDays());

      if (daysToGo > 2) {
        return {
          type: 'future',
          label: t('due-date', { date: dueDate.format('D MMM yyyy') }),
          date: dueDate,
        };
      } else if (daysToGo > 0) {
        return { type: 'soon', label: t('due-days', { count: daysToGo }), date: dueDate };
      } else if (daysToGo === 0) {
        return { type: 'today', label: t('due-today'), date: dueDate };
      } else {
        return {
          type: 'overdue',
          label: t('overdue-days', { count: daysToGo * -1 }),
          date: dueDate,
        };
      }
    },
    [t]
  );
};
