import { yupResolver } from '@hookform/resolvers/yup';
import {
  PrimaryButton,
  Dropdown,
  Modal,
  InputAutoComplete,
  Suggestion,
} from '@stellar-lms-frontend/ui-components';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { AccessTypes } from './access-cell';
import { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faUser } from '@fortawesome/pro-light-svg-icons';
import {
  InviteMemberFormData,
  InviteMemberModal,
} from '../../../organization-page/components/users-tab/components/invite-member-modal';
import { SupportedLanguagesOptions } from '../../../types/types';
import { useTranslation } from 'react-i18next';
import { defaultGraphqlClient, hasAnyPermission } from '@stellar-lms-frontend/common-utils';
import {
  transformOutgoingCompanyRole,
  transformOutgoingLanguageCode,
} from '../../../organization-page/components/users-tab/utils/transformers';
import { useMutation } from '@tanstack/react-query';
import {
  SubscriptionType,
  UserInput,
  addUserToCompany,
  useCurrentCompany,
} from '@stellar-lms-frontend/lms-graphql';
import { toast } from 'react-toastify';

export type AddToJourneyModalFormData = {
  selectMember: string;
  access: AccessTypes;
  mentor: string;
};

type Member = {
  id: string;
  name: string;
};

export type AddToJourneyModalProps = {
  onAdd: (data: AddToJourneyModalFormData) => void;
  isOpen: boolean;
  onClose: () => void;
  membersList: Member[];
  membersToAddList: Member[];
  supportedLanguages: SupportedLanguagesOptions;
  defaultLanguage: string;
  companyId: string;
};

export const AddToJourneyModal: React.FC<AddToJourneyModalProps> = ({
  companyId,
  onAdd,
  isOpen,
  onClose,
  membersList,
  membersToAddList,
  supportedLanguages,
  defaultLanguage,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'learners-table.add-to-journey-modal' });
  const { t: tToast } = useTranslation('translation', {
    keyPrefix: 'organization.members-tab.toast',
  });

  const [isInviteToOrganizationModalOpen, setIsInviteToOrganizationModalOpen] = useState(false);
  const [newOrgMember, setNewOrgMember] = useState<{ id: string; name: string } | undefined>(
    undefined
  );

  const schema = Yup.object().shape({
    selectMember: Yup.string().required(t('fields.member.error')),
  });

  const {
    query: { data: currentCompany },
  } = useCurrentCompany(defaultGraphqlClient);

  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, errors },
    reset,
    watch,
    setValue,
  } = useForm<AddToJourneyModalFormData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const resetModal = () => {
    reset();
    setNewOrgMember(undefined);
    setIsInviteToOrganizationModalOpen(false);
  };

  const onSubmitHandler: SubmitHandler<AddToJourneyModalFormData> = (data) => {
    onAdd(data);
    resetModal();
  };

  const selectedAccesss = watch('access');
  const memberId = watch('selectMember');
  const mentorId = watch('mentor');

  useEffect(() => {
    if (selectedAccesss !== 'learner') {
      setValue('mentor', '');
    }
  }, [selectedAccesss, setValue]);

  const renderMentorField = useCallback(() => {
    if (selectedAccesss === 'learner') {
      return (
        <Controller
          control={control}
          name="mentor"
          render={({ field }) => (
            <InputAutoComplete
              emptyLabel={t('fields.mentor.empty-label')}
              suggestions={membersList
                .filter((member) => member.id !== memberId)
                .map((member) => ({
                  id: member.id,
                  label: member.name,
                }))}
              label={t('fields.mentor.label')}
              onChangeInput={() => field.onChange(undefined)}
              onSuggestionSelected={(s) => field.onChange(s.id)}
              renderOption={(suggestion: Suggestion) => {
                return {
                  label: suggestion.label,
                  className: 'text-text-02',
                };
              }}
            />
          )}
        />
      );
    }
    return null;
  }, [control, memberId, membersList, selectedAccesss, t]);

  const { mutateAsync: addUserToCompanyMutation } = useMutation(
    (user: UserInput) =>
      addUserToCompany(defaultGraphqlClient, {
        companyId,
        input: user,
      }),
    {
      onSuccess: () => {
        if (currentCompany?.subscription?.type === SubscriptionType.StripeManaged) {
          toast.success(tToast('add-user-success', { count: 1 }));
        } else {
          toast.success(tToast('add-user'));
        }
      },
    }
  );
  const hasOrgMemberMgmtPermission = hasAnyPermission(currentCompany?.permissions, [
    'member_management',
  ]);

  return (
    <>
      <InviteMemberModal
        onSubmit={async (user: InviteMemberFormData) => {
          if (hasOrgMemberMgmtPermission) {
            // save org user get an id
            const addUserResult = await addUserToCompanyMutation({
              email: user.email,
              language: transformOutgoingLanguageCode(user.language.toLowerCase()),
              name: user.name,
              role: transformOutgoingCompanyRole(user.role),
              jobTitle: user.jobTitle,
            });

            if (addUserResult.addUser) {
              const id = addUserResult.addUser.id;
              setValue('selectMember', id, { shouldValidate: true });

              setNewOrgMember({ name: user.name, id });
            }
          }
        }}
        isOpen={isInviteToOrganizationModalOpen}
        onClose={() => {
          setIsInviteToOrganizationModalOpen(false);
        }}
        supportedLanguages={supportedLanguages}
        defaultLanguage={defaultLanguage}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={t('add-member')}
        buttons={{
          hasShadow: false,
          buttons: [
            <PrimaryButton
              onClick={handleSubmit(onSubmitHandler)}
              label={t('add-member')}
              enabled={isValid}
              htmlType="submit"
            />,
          ],
        }}
      >
        <form
          className="space-y-6"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <Controller
            control={control}
            name="selectMember"
            render={({ field }) => (
              <InputAutoComplete
                suggestions={membersToAddList
                  .filter((member) => member.id !== mentorId)
                  .map((member) => ({
                    id: member.id,
                    label: member.name,
                  }))}
                forcedValue={newOrgMember ? { label: newOrgMember.name } : undefined}
                emptyOption={{
                  label: <NoResults hasOrgMemberMgmtPermission={hasOrgMemberMgmtPermission} />,
                  type: 'custom',
                }}
                addButton={
                  hasOrgMemberMgmtPermission
                    ? {
                        label: t('fields.member.cta-create-new-org-user'),
                        onClick: () => {
                          setIsInviteToOrganizationModalOpen(true);
                        },
                        left: (
                          <FontAwesomeIcon
                            icon={faUser}
                            className="text-xl"
                          />
                        ),
                        className: '!text-primary-01',
                      }
                    : undefined
                }
                label={t('fields.member.select-member')}
                error={errors.selectMember?.message}
                onChangeInput={() => field.onChange(undefined)}
                showAddButtonWhenNoFullMatch={true}
                onSuggestionSelected={(s) => field.onChange(s.id)}
                placeholder={t('fields.member.placeholder')}
                renderOption={(suggestion: Suggestion) => {
                  return {
                    label: suggestion.label,
                    className: 'text-text-02',
                  };
                }}
              />
            )}
          />
          <Dropdown
            htmlId={'access'}
            required
            label={t('fields.access.label')}
            {...register('access')}
          >
            <option value="author">{t('fields.access.options.author')}</option>
            <option value="collaborator">{t('fields.access.options.collaborator')}</option>
            <option value="learner">{t('fields.access.options.learner')}</option>
          </Dropdown>
          {renderMentorField()}
        </form>
      </Modal>
    </>
  );
};

const NoResults = (props: { hasOrgMemberMgmtPermission: boolean }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'learners-table.add-to-journey-modal.fields.member',
  });

  return (
    <div className="flex items-center gap-4 px-6 py-3">
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        className="shrink-0 text-lg"
      />
      <span className="text-text-02 type-small-medium">
        {props.hasOrgMemberMgmtPermission ? t('empty-label-invite-to-org') : t('empty-label')}
      </span>
    </div>
  );
};
