import { DataCy, VoidFunc, useOnClickOutside } from '@stellar-lms-frontend/common-utils';
import { Floating, FloatingProps } from '../floating/floating';
import React, { useRef } from 'react';

export type FloatingBoxProps = DataCy &
  FloatingProps & {
    onClose?: VoidFunc;
    className?: string;
    heightClassName?: string;
    matchWidth?: boolean;
  };

export const FloatingBox: React.FC<FloatingBoxProps> = ({
  isOpen,
  children,
  onClose = () => null,
  className = '',
  'data-cy': dataCy,
  heightClassName = 'max-h-[300px]',
  matchWidth,
  ...rest
}) => {
  const wrappedComponentRef = useRef<HTMLElement | null>(null);
  const childrenRef = useRef<HTMLElement | null>(null);

  useOnClickOutside(isOpen ? onClose : undefined, [wrappedComponentRef, childrenRef]);

  return (
    <Floating
      {...rest}
      isOpen={isOpen}
      arrowClassName="fill-white"
      innerRefs={{
        setWrappedComponentRef: (e) => (wrappedComponentRef.current = e),
        setChildrenRef: (e) => (childrenRef.current = e),
      }}
    >
      <div
        data-cy={dataCy}
        className={`${className} ${heightClassName} border-border-01 z-10 min-h-fit overflow-auto overscroll-contain rounded-lg border bg-white drop-shadow-md`}
        style={{
          width:
            wrappedComponentRef.current?.offsetWidth && matchWidth
              ? `${wrappedComponentRef.current?.offsetWidth}px`
              : undefined,
        }}
      >
        {children}
      </div>
    </Floating>
  );
};
