export type LearnerCellProps = {
  thumbUri: string;
  name: string;
};

export const LearnerCell: React.FC<LearnerCellProps> = ({ thumbUri, name }) => {
  return (
    <div className="flex items-center">
      <img
        className="mr-6 h-8 w-8 rounded-full"
        src={thumbUri}
        alt="Learner avatar"
      />
      <p className="font-medium">{name}</p>
    </div>
  );
};
