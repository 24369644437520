import { CheckIcon } from '@stellar-lms-frontend/ui-components';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

export type ImageSuggestionCardProps =
  | {
      thumbURL?: never;
      author?: never;
      onClick?: never;
      isSelected?: never;
      isSkeleton: true;
    }
  | {
      thumbURL: string;
      author: {
        name: string;
        url?: string;
      };
      onClick: (event: MouseEvent<HTMLDivElement>) => void;
      isSelected: boolean;
      isSkeleton: false;
    };

export const ImageSuggestionCard: React.FC<ImageSuggestionCardProps> = ({
  thumbURL,
  author,
  onClick,
  isSelected,
  isSkeleton,
}) => {
  // Note: keeping the specific name for now, could be that this needs to move up if this card gets more reuse in the future
  const { t: tBlockStepView } = useTranslation('translation', { keyPrefix: 'block-step-view' });

  return isSkeleton ? (
    <div className="bg-surface-02 animate-fadeInOutDissolve h-[96px] w-full rounded-xl" />
  ) : (
    <div
      className="font-lexend cursor-pointer space-y-1"
      onClick={onClick}
    >
      <div className="relative">
        <div className="h-[96px] w-full overflow-hidden rounded-xl">
          <img
            src={thumbURL}
            alt="Suggestion thumbnail"
            className={`
              focus:border-primary-01 h-full w-full
              object-cover hover:opacity-50 focus:border-2
              ${isSelected ? 'border-primary-01 border-2' : ''}
            `}
          />
        </div>
        {isSelected && (
          <>
            <div
              className="absolute top-0 left-0 h-full w-full rounded-xl
                        bg-gradient-to-r from-blue-900 to-blue-900 opacity-40 hover:opacity-20"
            />
            <CheckIcon className="absolute top-2 right-2 h-10 w-10 text-white" />
          </>
        )}
      </div>
      <p className="type-body-medium text-text-02 w-full overflow-hidden text-ellipsis whitespace-nowrap ">
        <span>{tBlockStepView('image-ai-sidebar.image-suggestion-card.by')}</span>{' '}
        <span>
          <a
            className={author.url ? 'underline' : ''}
            href={author.url}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            {author.name}
          </a>
        </span>
      </p>
    </div>
  );
};
