import { SurveyOverviewView } from '@stellar-lms-frontend/lms-components';
import { EmptyState, LogoLoader } from '@stellar-lms-frontend/ui-components';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DESIGNER_SURVEY_ROUTE, MENTOR_SURVEY_ROUTE } from '../../constants/routes';
import { UIShell } from '../shell/ui-shell';
import { getCourseSurveysForMentor } from './survey.api';
import { graphQLClient } from '../../lib/graphql';
import { SURVEY_OVERVIEW } from '@stellar-lms-frontend/lms-api';

export const SurveyOverviewContainer = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'course-survey-list' });
  const { t: tTable } = useTranslation('translation', { keyPrefix: 'table' });

  const isMentor = window.location.pathname.includes('mentor');

  const surveyQuery = useQuery(
    [SURVEY_OVERVIEW, courseId],
    () =>
      courseId
        ? getCourseSurveysForMentor(graphQLClient(isMentor ? 'mentor' : 'designer'), courseId)
        : Promise.resolve([]),
    {
      enabled: !!courseId,
    }
  );

  const onSurveySelect = (surveyId: string) => {
    if (isMentor) {
      navigate(MENTOR_SURVEY_ROUTE(courseId, surveyId));
    } else {
      navigate(DESIGNER_SURVEY_ROUTE(courseId, surveyId));
    }
  };

  return (
    <UIShell>
      {surveyQuery.isLoading ? (
        <LogoLoader />
      ) : !surveyQuery.data?.length ? (
        <EmptyState
          title={t('empty-course-title')}
          description={t('empty-course-description')}
          verticalCenter
        />
      ) : (
        <SurveyOverviewView
          i18n={{
            table: {
              pageSizeSelector: {
                showing: tTable('page-size-selector.showing'),
                of: (count) => tTable('page-size-selector.of', { count }),
              },
            },
            columns: {
              module: t('table.module'),
              title: t('table.title'),
              questions: t('table.questions'),
              answeredQuestions: t('table.answeredQuestions'),
            },
          }}
          data={surveyQuery.data}
          surveySelected={onSurveySelect}
        />
      )}
    </UIShell>
  );
};
