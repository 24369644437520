export type Option = {
  label: string;
  value: string;
};

export type RadioProps = {
  legend?: string;
  uniqueId: string;
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  radioDirection?: 'horizontal' | 'vertical';
  className?: string;
};
export const Radio: React.FC<RadioProps> = ({
  options,
  value,
  legend,
  onChange,
  uniqueId,
  radioDirection = 'horizontal',
  className = '',
}) => {
  return (
    <fieldset>
      <div
        className={`inline-flex gap-4 
        ${radioDirection === 'horizontal' ? 'flex-row items-center' : 'flex-col items-start'}
        ${className}
        `}
      >
        {legend && <span className="type-body-medium">{legend}</span>}
        {options.map((option) => (
          <span key={option.label}>
            <label
              htmlFor={`option-${uniqueId}-${option.label}`}
              className="space-x-3"
            >
              <input
                type="radio"
                onChange={(src) => onChange(src.target.value)}
                id={`option-${uniqueId}-${option.label}`}
                name={`option-${uniqueId}`}
                value={option.value}
                checked={value === option.value}
              />
              <span className="type-body">{option.label}</span>
            </label>
          </span>
        ))}
      </div>
    </fieldset>
  );
};
