import { Input } from '@stellar-lms-frontend/ui-components';
import React from 'react';
import { ChangeEventHandler } from 'react';

export type EditHeaderProps = {
  onChangeTitle: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  value: string;
  error?: string;
};

export const EditHeader = React.forwardRef<HTMLInputElement, EditHeaderProps>(
  ({ onChangeTitle, placeholder, value, error }, ref) => {
    return (
      <div className="flex items-start gap-4">
        <Input
          className="text-2xl"
          htmlId="la-title-input"
          onChange={onChangeTitle}
          text={value}
          placeholder={placeholder}
          error={error}
          ref={ref}
        />
      </div>
    );
  }
);
