import { useMemo } from 'react';
import { FontBody } from '../../../text';
import { FillBlanks } from '../fill-blanks';
import { generateFITBAnswersFromText } from '../fill-blanks.functions';
import { FillBlanksSettings } from './fill-blanks-settings-card';
import { useTranslation } from 'react-i18next';

export type FillBlanksSettingsPreviewProps = {
  settings: FillBlanksSettings;
  isHighlighted?: boolean;
};

export const FillBlanksSettingsPreview: React.FC<FillBlanksSettingsPreviewProps> = ({
  settings,
  isHighlighted,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assessment-step-view' });

  const { answers, parts } = useMemo(
    () => generateFITBAnswersFromText(settings.text),
    [settings.text]
  );

  return (
    <div className="space-y-4">
      <FontBody
        type="emphasis"
        className="text-text-02"
      >
        {t('question-types.fill-blanks.name')}
      </FontBody>
      <FillBlanks
        color={isHighlighted ? 'dark' : 'normal'}
        isDisabled
        parts={parts}
        inputLength={10}
        answerOptions={answers}
      />
    </div>
  );
};
