import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import {
  CrossIcon,
  Heading3,
  QuestionIcon,
  Sidebar,
  StepCounter,
} from '@stellar-lms-frontend/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type TopBarProps = {
  onClose: VoidFunc;
  total: number;
  remaining: number;
};

export const TopBar: React.FC<TopBarProps> = ({ onClose, total, remaining }) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'spaced-repetition' });

  return (
    <>
      <Sidebar
        isOpen={isPanelOpen}
        position={'right'}
        contentClassName="max-w-[500px]"
        onClose={() => setIsPanelOpen(false)}
      >
        <Heading3 className="text-text-04 mb-4">{t('srq-explanation-title')}</Heading3>
        <div className="space-y-6">
          {t('srq-explanation-text')
            .split('\n')
            .map((p) => (
              <p>{p}</p>
            ))}
        </div>
      </Sidebar>
      <div className="bg-surface-01 z-10 grid w-full shrink-0 grid-cols-3 items-center lg:sticky lg:top-0 lg:pt-6">
        <StepCounter
          className="text-text-04 col-start-2 justify-self-center font-semibold lg:hidden"
          total={total}
          curr={total - remaining + 1}
        />
        <div className="col-start-3 space-x-6 justify-self-end">
          <button onClick={() => setIsPanelOpen(true)}>
            <QuestionIcon className="text-text-01 hidden lg:block" />
          </button>
          <button
            onClick={onClose}
            data-cy="close-button"
          >
            <CrossIcon className="text-text-01 h-6 w-6" />
          </button>
        </div>
      </div>
    </>
  );
};
