import { EmptyState, ExclamationIllustration } from '@stellar-lms-frontend/ui-components';

import { useTranslation } from 'react-i18next';

export const MobileError: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'mobile-error' });

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <EmptyState
        icon={<ExclamationIllustration />}
        title={t('title')}
        description={t('description')}
        verticalCenter
      />
    </div>
  );
};
