import { useQuery } from '@tanstack/react-query';
import { ImageSettings } from '@stellar-lms-frontend/ui-components';

export const useDefaultHeaderImage = (
  stepId?: string,
  getDefaultHeaderImageFunc?: () => Promise<ImageSettings | undefined>,
  callback?: (data: ImageSettings) => void
) => {
  const queryData = useQuery(['default-header-image'], () => getDefaultHeaderImageFunc?.(), {
    enabled: !!stepId && stepId === 'create' && !!getDefaultHeaderImageFunc,
    onSuccess(data) {
      data && callback?.(data);
    },
  });

  return {
    headerImage: queryData.data ?? undefined,
  };
};
