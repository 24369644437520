import { MutationCreateTrialArgs, graphql } from '@stellar-lms-frontend/lms-graphql';
import { graphQLClient } from '../../lib/graphql';

export const createTrial = (variables: MutationCreateTrialArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation CreateTrial($input: CreateTrialInput!) {
        createTrial(input: $input) {
          errors
        }
      }
    `),
    variables
  );
};
