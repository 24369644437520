import { DiscussionItem, DISCUSSION_PAGE_SIZE } from '@stellar-lms-frontend/lms-api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useLayoutEffect, useRef } from 'react';
import { DISCUSSIONS_QUERY_KEY } from '../query';

export type GetDiscussionItemsFunc = (
  learningActivityId: string,
  stepId: string,
  pageParam: number
) => Promise<DiscussionItem[]>;

export const useDiscussion = (
  getDiscussionItems: GetDiscussionItemsFunc,
  learningActivityId?: string,
  stepId?: string
) => {
  const endOfPageRef = useRef<HTMLDivElement>(null);

  const { data, fetchNextPage } = useInfiniteQuery(
    [DISCUSSIONS_QUERY_KEY, learningActivityId, stepId],
    ({ pageParam = 0 }) => {
      if (learningActivityId && stepId) {
        return getDiscussionItems(learningActivityId, stepId, pageParam);
      }
      return Promise.resolve([]);
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.length < DISCUSSION_PAGE_SIZE) {
          return undefined;
        }
        return pages.length * DISCUSSION_PAGE_SIZE;
      },
    }
  );

  const handleObserver = () => {
    fetchNextPage();
  };

  const observer = useRef(
    new IntersectionObserver(handleObserver, {
      rootMargin: '0px',
    })
  );

  useLayoutEffect(() => {
    if (endOfPageRef.current) {
      observer.current.observe(endOfPageRef.current);
    }
  });

  const hasComments = data && data.pages.length > 0 && data.pages[0].length > 0;

  return {
    data,
    hasComments,
    endOfPageRef,
  };
};
