import { API } from '@editorjs/editorjs';
import { useState } from 'react';
import { SecondaryButton } from '../../../buttons';
import { Modal } from '../../../modal/modal';

export const DiscussNotAvailable = ({ api }: { api: API }) => {
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <>
      <SecondaryButton
        label={api.i18n.t('show-discuss-panel-button')}
        onClick={() => setPopupOpen(true)}
      />
      <Modal
        title={api.i18n.t('no-discuss-panel-title')}
        isOpen={popupOpen}
        onClose={() => setPopupOpen(false)}
      >
        {api.i18n.t('no-discuss-panel-description')}
      </Modal>
    </>
  );
};
