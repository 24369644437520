import { CrossIcon } from '../icons';

export type InputError = {
  error?: string;
  theme?: 'default' | 'dark';
};

export const InputError: React.FC<InputError> = ({ error, theme = 'default' }) => {
  if (!error) return null;
  const color = theme === 'dark' ? 'text-red-200' : 'text-negative-01';
  return (
    <div className={`${color} my-2 flex text-sm`}>
      <div className="mr-2 h-5 w-5 self-start justify-self-start">
        <CrossIcon className={`$color`} />
      </div>
      <span>{error}</span>
    </div>
  );
};
