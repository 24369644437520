import React from 'react';
import { FillBlanksAnswer } from './types';

type Colors = 'normal' | 'dark';

export class Blank {
  label: string;
  index: number;

  constructor(label: string, index: number) {
    this.label = label;
    this.index = index;
  }
}

export type FillBlanksProps = {
  parts: (string | Blank)[];
  answerOptions?: string[];
  inputLength?: number;
  evaluated?: boolean;
  color?: Colors;
} & (
  | {
      isDisabled?: false;
      value: FillBlanksAnswer[];
      onChange: (answers: FillBlanksAnswer[]) => void;
    }
  | {
      isDisabled: true;
      value?: never;
      onChange?: never;
    }
);

export const FillBlanks: React.FC<FillBlanksProps> = ({
  parts,
  value = [],
  answerOptions = [],
  onChange,
  inputLength,
  evaluated,
  isDisabled,
  color = 'normal',
}) => {
  const onRemove = (index: number) => {
    value[index] = { ...value[index], userInput: '' };

    onChange?.(value);
  };

  const onAdd = (userInput: string) => {
    const index = value.findIndex((el) => !el.userInput);
    const firstEmpty = index === -1 ? value.length : index;
    value[firstEmpty] = { ...value[firstEmpty], userInput };
    onChange?.(value);
  };

  const renderInput = (part: Blank) => {
    const answer = value[part.index];
    const textLength = evaluated ? answer?.userInput?.length ?? 0 : inputLength || 0;
    const inputWidth = textLength < 10 ? '12ch' : textLength + 4 + 'ch'; //+4 to compensate for the padding in the input field
    const isDisabled = !answer?.userInput || evaluated;

    return (
      <button
        data-cy={`fill-blanks-fill-${answer?.userInput}`}
        style={{ width: inputWidth }}
        onClick={() => {
          !isDisabled && answer?.userInput && onRemove(part.index);
        }}
        className={`
            mx-2 h-[2.5rem] border-b-2 text-center
            ${color === 'normal' ? 'border-border-02' : 'border-border-03'}
            ${evaluated && !answer?.userAnsweredCorrect ? 'text-negative-01' : 'text-primary-01'}
            ${isDisabled ? 'cursor-default' : ''}
        `}
      >
        <span>{answer?.userInput ? answer.userInput : '\u00A0'}</span>
      </button>
    );
  };

  return (
    <div className="font-lexend flex flex-col">
      <div
        className={`${color === 'normal' ? 'text-text-02' : 'text-text-01'} leading-12 text-base`}
      >
        {parts.map((part, index) => (
          <React.Fragment key={index}>
            {part instanceof Blank ? renderInput(part) : <span>{part}</span>}
          </React.Fragment>
        ))}
      </div>
      <div className="mt-16 flex flex-wrap justify-center gap-3 self-center">
        {answerOptions.map((option, index) => {
          const isOptionDisabled = isDisabled || !!value.find((el) => el.userInput === option);
          return (
            <button
              data-cy={`fill-blanks-option-${option}`}
              key={`${index}-${option}`}
              className={`mr-3 rounded-xl border px-4 py-3
            ${
              isOptionDisabled
                ? `${
                    color === 'normal' ? 'text-text-03' : 'text-text-02'
                  } border-text-03 cursor-default border-dashed`
                : 'bg-primary-03 text-primary-01 border-transparent'
            }`}
              onClick={() => {
                !isOptionDisabled && onAdd(option);
              }}
            >
              {option}
            </button>
          );
        })}
      </div>
    </div>
  );
};
