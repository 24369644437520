import { Question } from '@stellar-lms-frontend/lms-api';
import { SelectBox } from '@stellar-lms-frontend/ui-components';
import React from 'react';

type ReadOnlyQuestionAnswerProps = {
  question: Question;
};
export const ReadOnlyQuestionAnswer: React.FC<ReadOnlyQuestionAnswerProps> = ({ question }) => {
  switch (question.type) {
    case 'free_text_single':
    case 'free_text_multiple': {
      return (
        <SelectBox type="neutral-white">
          <span className="whitespace-pre-wrap">{question.answers.at(0)?.userInput ?? ''}</span>
        </SelectBox>
      );
    }
    case 'scale': {
      return (
        <SelectBox type="neutral-white">
          {question.answers.filter((answer) => answer.userAnswered).at(0)?.userInput ?? ''}
        </SelectBox>
      );
    }
    case 'multiple_choice_multiple_selection':
    case 'multiple_choice_single_selection': {
      return (
        <div className="space-y-2">
          {question.answers
            .filter((answer) => answer.userAnswered)
            .map((answer) => (
              <SelectBox
                type="neutral-white"
                key={answer.id}
              >
                {answer.userInput}
              </SelectBox>
            ))}
        </div>
      );
    }
    default:
      return null;
  }
};
