import { formatDate, VoidFunc } from '@stellar-lms-frontend/common-utils';
import { LearnerWorkBasedAction } from '@stellar-lms-frontend/lms-api';
import {
  Heading3,
  Heading4,
  Input,
  PrimaryButton,
  SecondaryButton,
  Form,
  AutoResizeTextArea,
  ContentWrapper,
} from '@stellar-lms-frontend/ui-components';
import { useQueryClient, useMutation } from '@tanstack/react-query';

import moment from 'moment';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LEARNER_WBA, LEARNER_WBA_BY_SOURCE_ID } from './query';
import { toast } from 'react-toastify';
import { LearnerWorkBasedActionSubmitInput } from '@stellar-lms-frontend/lms-graphql';

export type WorkBasedActionEditProps = {
  learnerWorkBasedAction: LearnerWorkBasedAction;

  /**
   * If the WBA designer details should be shown (title, description)
   */
  showWbaDesignerDetails: boolean;

  /**
   * Should the component handle the save button itself or is the parent handling it (through the navigation bar for example)
   */
  showSaveButton: boolean;

  /*
   * A function to help set up the menu bar buttons by giving it a primaryAction.
   * This feels so hacky, but duplicating the form logic in every parent feels hacky as well. Better solutions are very welcome!
   */
  setUpButtonsFunc?: (primaryAction: VoidFunc) => VoidFunc;

  /**
   * A function that should be executed after submitting the user input to the server
   */
  afterSubmitFunc?: VoidFunc;

  /**
   * A function to call when the user cancels
   */
  onCancelFunc?: VoidFunc;

  /**
   * All operations/actions needed to make this component function
   */
  actions: Actions;
};

type FormData = {
  learnerShortDescription: string;
  learnerDescription: string;
  learnerDueDate: string;
};

type Actions = {
  submitLearnerWorkBasedAction: (
    id: string,
    input: LearnerWorkBasedActionSubmitInput
  ) => Promise<LearnerWorkBasedAction>;
};

export const WorkBasedActionEdit = ({
  learnerWorkBasedAction,
  showWbaDesignerDetails,
  showSaveButton = false,
  afterSubmitFunc,
  setUpButtonsFunc,
  onCancelFunc,
  actions,
}: WorkBasedActionEditProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'work-based-actions' });
  const minDate = moment().startOf('day');
  const maxDate = moment().add(1, 'year').endOf('year');
  const [isEdit] = useState<boolean>(learnerWorkBasedAction.submitted);

  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (workBasedAction: LearnerWorkBasedAction) => {
      // for designers we don't have an id as there is no learner wba in the database.
      // it's not properly represented in our types in graphql so we need to check it here.
      if (workBasedAction.id) {
        return actions.submitLearnerWorkBasedAction(workBasedAction.id, {
          learnerDescription: workBasedAction.learnerDescription,
          learnerDueDate: workBasedAction.learnerDueDate,
          learnerShortDescription: workBasedAction.learnerShortDescription,
        });
      }

      return Promise.resolve(null);
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData([LEARNER_WBA, learnerWorkBasedAction.id], data);
        queryClient.setQueryData(
          [LEARNER_WBA_BY_SOURCE_ID, learnerWorkBasedAction.id, learnerWorkBasedAction.sourceId],
          data
        );
        if (!isEdit) {
          toast.success(t('create-success-message'));
        }
        afterSubmitFunc && afterSubmitFunc();
      },
    }
  );

  const wbaForms = useMemo(() => {
    return {
      learnerShortDescription: learnerWorkBasedAction.learnerShortDescription ?? '',
      learnerDescription: learnerWorkBasedAction.learnerDescription ?? '',
      learnerDueDate: learnerWorkBasedAction.learnerDueDate
        ? formatDate(moment(learnerWorkBasedAction.learnerDueDate))
        : '',
    };
  }, [learnerWorkBasedAction]);

  const schema = yup.object({
    learnerShortDescription: yup
      .string()
      .required(t('validation.learner-short-description.required')),
    learnerDescription: yup.string().required(t('validation.learner-description.required')),
    learnerDueDate: yup
      .date()
      .typeError(t('validation.learner-due-date.wrong-type'))
      .required(t('validation.learner-due-date.required'))
      .min(minDate, t('validation.learner-due-date.min', { date: formatDate(minDate) }))
      .max(maxDate, t('validation.learner-due-date.max', { date: formatDate(maxDate) })),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: wbaForms,
  });

  const handleWbaSubmit = useCallback(
    async (data: FormData) => {
      const toSubmit: LearnerWorkBasedAction = {
        ...learnerWorkBasedAction,
        learnerDescription: data.learnerDescription,
        learnerDueDate: new Date(data.learnerDueDate),
        learnerShortDescription: data.learnerShortDescription,
      };
      mutate(toSubmit);
    },
    [learnerWorkBasedAction, mutate]
  );

  useEffect(() => {
    reset(wbaForms);
  }, [reset, wbaForms]);

  // Setup buttons and teardown afterwards
  useEffect(() => {
    let destructor: VoidFunc | undefined;
    if (setUpButtonsFunc) {
      destructor = setUpButtonsFunc(handleSubmit(handleWbaSubmit));
    }

    return () => destructor?.();
  }, [handleSubmit, handleWbaSubmit, setUpButtonsFunc]);

  return (
    <ContentWrapper className="mx-auto flex flex-col justify-between pb-6 pt-4">
      {/* CLEANUP: might not be needed, to check.. */}
      <div className="flex w-full flex-col">
        {showWbaDesignerDetails && (
          <div className="flex flex-col space-y-2 lg:space-y-8">
            <Heading3 className="text-text-04 hidden md:inline">
              {learnerWorkBasedAction.title}
            </Heading3>
            <Heading4 className="text-text-04 md:hidden">{learnerWorkBasedAction.title}</Heading4>
            {/*STE-2536
            learnerWorkBasedAction.description &&
              learnerWorkBasedAction.description.trim() !== '' && (
                <span className="font-lexend font-regular text-text-01 whitespace-pre-wrap text-base">
                  {learnerWorkBasedAction.description}
                </span>
              )*/}
          </div>
        )}
        <Form className="mt-4 space-y-6">
          <Controller
            control={control}
            name="learnerShortDescription"
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={t('learner.short-description')}
                htmlId="shortDescription"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={errors.learnerShortDescription?.message}
              />
            )}
          />
          <AutoResizeTextArea
            label={t('learner.description')}
            minRows={1}
            maxRows={10}
            id="description"
            {...register('learnerDescription')}
            error={errors.learnerDescription?.message}
          />
          <Controller
            control={control}
            name="learnerDueDate"
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={t('learner.due-date')}
                htmlId="due-date"
                htmlType="date"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={errors.learnerDueDate?.message}
              />
            )}
          />
        </Form>
        {showSaveButton && (
          <div className="mt-8  hidden flex-row space-x-4 lg:flex">
            <PrimaryButton
              htmlId="save-button"
              label={t('save-button')}
              onClick={handleSubmit(handleWbaSubmit)}
            ></PrimaryButton>
            {onCancelFunc && (
              <SecondaryButton
                htmlId="cancel-button"
                label={t('cancel-button')}
                onClick={onCancelFunc}
              ></SecondaryButton>
            )}
          </div>
        )}
      </div>
      {showSaveButton && (
        <div className="px-8 pb-8 lg:hidden">
          <PrimaryButton
            htmlId="save-button"
            label={t('save-button')}
            onClick={handleSubmit(handleWbaSubmit)}
          ></PrimaryButton>
        </div>
      )}
    </ContentWrapper>
  );
};
