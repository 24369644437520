import { Row } from '@tanstack/react-table';
import { TableCell } from './table-cell';
import { DataCy } from '@stellar-lms-frontend/common-utils';

export interface DefaultRowDataType {
  [key: string]: unknown;
}

type TableRowsProps<T = DefaultRowDataType> = DataCy & {
  rows: Row<T>[];
  rowClick?: (row: Row<T>, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const TableRows = <T extends DefaultRowDataType = DefaultRowDataType>({
  rows,
  rowClick,
  'data-cy': dataCy,
}: TableRowsProps<T>) => (
  <>
    {rows.map((row, rowIndex) => {
      return (
        <div
          data-cy={`${dataCy}-row-${rowIndex}`}
          role="row"
          key={row.id}
          className="flex w-full flex-row"
        >
          <div
            onClick={(e) => rowClick?.(row, e)}
            className={`text-text-02 font-regular hover:bg-surface-bg hover:text-text-01 flex grow flex-row items-center
                  text-base hover:rounded-xl
                  ${rowClick && 'cursor-pointer'}
                  ${row.getIsSelected() || row.getIsExpanded() ? 'bg-surface-bg' : ''}`}
          >
            <div
              aria-hidden
              className="td w-4 shrink-0 rounded-l-xl"
            />
            <div className="border-surface-02 border-t-1 flex grow flex-row items-center">
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  cell={cell}
                  data-cy={dataCy}
                  rowIndex={rowIndex}
                  row={row}
                />
              ))}
            </div>
            <div
              aria-hidden
              className="td w-4 shrink-0 rounded-r-xl"
            />
          </div>
        </div>
      );
    })}
  </>
);
