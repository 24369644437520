import { GraphQLClient } from 'graphql-request';
import {
  AddMemberToCourseMutationVariables,
  Course,
  DeleteMemberRegistrationFromCourseMutationVariables,
  GetCourseOutlineQueryVariables,
  GetCourseRegistrationsQueryVariables,
  UpdateCourseRegistrationMutationVariables,
  graphql,
} from '../graphql';

export const getCourseOutline = (
  graphQLClient: GraphQLClient,
  variables: GetCourseOutlineQueryVariables,
  signal?: AbortSignal
) => {
  return graphQLClient
    .request({
      document: graphql(`
        query getCourseOutline($courseId: ID!) {
          course(id: $courseId) {
            outline {
              title
              id
              modules {
                id
                title
                placeholder
                learningActivities {
                  commentCount
                  dateOpen
                  description
                  duration
                  enabled
                  id
                  locked
                  title
                  placeholder
                  type
                  steps {
                    commentCount
                    completed
                    duration
                    enabled
                    id
                    title
                    visited
                    aiGenerationStatus
                    aiGenerated
                  }
                  state
                  progress
                  prerequisites {
                    mandatory
                    module {
                      id
                      title
                      steps {
                        id
                        title
                      }
                    }
                  }
                }
                placeholder
              }
            }
          }
        }
      `),
      variables,
      signal,
    })
    .then((res) => res.course as Course);
};

export const getMembersOfCourse = (
  client: GraphQLClient,
  variables: GetCourseRegistrationsQueryVariables
) => {
  return client
    .request(
      graphql(`
        query GetCourseRegistrations($courseId: ID!, $pageSize: Int!, $cursor: String) {
          getCourseRegistrations(courseId: $courseId, pageSize: $pageSize, cursor: $cursor) {
            totalCount
            pageInfo {
              hasNextPage
              cursor
            }
            registrations {
              id
              userStats {
                progress
              }
              mentor {
                id
                name
              }
              user {
                id
                thumbUri
                name
              }
              courseRole
            }
          }
        }
      `),
      variables
    )
    .then((res) => res.getCourseRegistrations);
};

export const addMemberToCourse = (
  client: GraphQLClient,
  variables: AddMemberToCourseMutationVariables
) => {
  return client.request(
    graphql(`
      mutation AddMemberToCourse($courseId: ID!, $input: CourseRegistrationCreateInput!) {
        addMemberToCourse(courseId: $courseId, input: $input) {
          id
        }
      }
    `),
    variables
  );
};

export const deleteMemberRegistrationFromCourse = (
  client: GraphQLClient,
  variables: DeleteMemberRegistrationFromCourseMutationVariables
) => {
  return client.request(
    graphql(`
      mutation DeleteMemberRegistrationFromCourse($courseId: ID!, $registrationId: ID!) {
        deleteMemberRegistrationFromCourse(courseId: $courseId, registrationId: $registrationId)
      }
    `),
    variables
  );
};

export const updateMemberRegistrationFromCourse = (
  client: GraphQLClient,
  variables: UpdateCourseRegistrationMutationVariables
) => {
  return client.request(
    graphql(`
      mutation UpdateCourseRegistration(
        $courseId: ID!
        $registrationId: ID!
        $input: CourseRegistrationUpdateInput!
      ) {
        updateCourseRegistration(
          courseId: $courseId
          registrationId: $registrationId
          input: $input
        ) {
          id
        }
      }
    `),
    variables
  );
};
