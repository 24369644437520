import {
  Course,
  graphql,
  MutationBehaviourContentGenerationArgs,
  MutationBehaviourGenerationArgs,
  MutationCreateCourseModuleArgs,
  MutationCreateLearningActivityArgs,
  MutationDeleteCourseModuleArgs,
  MutationDuplicateCourseModuleArgs,
  MutationMoveLearningActivityArgs,
  MutationUpdateCourseModuleArgs,
  MutationUpdateCourseModuleOrderArgs,
  QueryCourseArgs,
  QuerySuggestedTransferObjectivesArgs,
} from '@stellar-lms-frontend/lms-graphql';
import { graphQLClient } from '../../../lib/graphql';

export const getDesignerJourney = (variables: QueryCourseArgs, signal?: AbortSignal) => {
  return graphQLClient('designer')
    .request({
      document: graphql(
        `
          query designerJourneyPage($id: ID!) {
            course(id: $id) {
              id
              realtimeChannel {
                id
              }
              transferObjectives {
                id
              }
              courseStats {
                ... on CourseStats {
                  numberOfStudents
                }
              }
              aiGenerated
              aiGenerationDone
              aiProject {
                id
                audience
                realtimeChannel {
                  id
                }
                skill
                industry
                courseGenerationProgress
              }
              outline {
                modules {
                  id
                  title
                  description
                  placeholder
                  learningActivities {
                    id
                    title
                    dateOpen
                    locked
                    progress
                    aiGenerated
                    aiGenerationDone
                    placeholder
                    duration
                    description
                    commentCount
                    type
                    steps {
                      id
                      title
                      duration
                    }
                    prerequisites {
                      mandatory
                      module {
                        id
                        title
                      }
                    }
                  }
                  stats {
                    learnersStarted
                    learnersCompleted
                  }
                }
              }
            }
          }
        `
      ),
      variables,
      signal,
    })
    .then((res) => res.course as Course);
};

export const deleteCourseModule = (variables: MutationDeleteCourseModuleArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation designerDeleteModule($input: CourseModuleDeleteInput!) {
        deleteCourseModule(input: $input)
      }
    `),
    variables
  );
};

export const updateCourseModule = (variables: MutationUpdateCourseModuleArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation UpdateCourseModule($input: CourseModuleUpdateInput!) {
        updateCourseModule(input: $input) {
          id
          title
          description
        }
      }
    `),
    variables
  );
};

export const duplicateCourseModule = (variables: MutationDuplicateCourseModuleArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation designerDuplicateModule($courseId: ID!, $moduleId: ID!) {
        duplicateCourseModule(courseId: $courseId, moduleId: $moduleId)
      }
    `),
    variables
  );
};

export const createCourseModule = (variables: MutationCreateCourseModuleArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation CreateCourseModule($input: CourseModuleInput!) {
        createCourseModule(input: $input) {
          id
          title
        }
      }
    `),
    variables
  );
};

export const createLearningActivity = (variables: MutationCreateLearningActivityArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation createLearningActivityMutation(
        $courseId: ID!
        $moduleId: ID!
        $input: LearningActivityCreateInput!
      ) {
        createLearningActivity(courseId: $courseId, moduleId: $moduleId, input: $input) {
          id
        }
      }
    `),
    variables
  );
};

export const updateCourseModuleOrder = (variables: MutationUpdateCourseModuleOrderArgs) =>
  graphQLClient('designer').request(
    graphql(`
      mutation UpdateCourseModuleOrder($input: CourseModuleOrderInput!) {
        updateCourseModuleOrder(input: $input) {
          count
        }
      }
    `),
    variables
  );

export const moveLearningActivity = (variables: MutationMoveLearningActivityArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation MoveLearningActivity(
        $courseId: ID!
        $moduleId: ID!
        $learningActivityId: ID!
        $input: LearningActivityMoveInput!
      ) {
        moveLearningActivity(
          courseId: $courseId
          moduleId: $moduleId
          learningActivityId: $learningActivityId
          input: $input
        )
      }
    `),
    variables
  );
};

export const startBehaviourGeneration = (variables: MutationBehaviourGenerationArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation BehaviourGeneration($input: SuggestedBehaviourGenerationInput!) {
        behaviourGeneration(input: $input)
      }
    `),
    variables
  );
};

export const fetchSuggestedBehaviours = (variables: QuerySuggestedTransferObjectivesArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      query ModuleSuggestedTransferObjectives($companyId: ID!, $projectId: ID!, $nrPerPage: Int!) {
        suggestedTransferObjectives(
          companyId: $companyId
          projectId: $projectId
          nrPerPage: $nrPerPage
        ) {
          status
          transferObjectives {
            title
            description
            id
          }
        }
      }
    `),
    variables
  );
};

export const startBehaviourContentGeneration = (
  variables: MutationBehaviourContentGenerationArgs
) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation BehaviourContentGeneration($input: SuggestedBehaviourContentGenerationInput!) {
        behaviourContentGeneration(input: $input)
      }
    `),
    variables
  );
};
