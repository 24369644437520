import { BaseCard } from '@stellar-lms-frontend/ui-components';

export type ScenarioSuggestionsCardProps = {
  image?: string;
  title: string;
  description: string;
  isHighlighted?: boolean;
  onClick?: () => void;
};

export const ScenarioSuggestionsCard: React.FC<ScenarioSuggestionsCardProps> = ({
  image,
  title,
  description,
  isHighlighted,
  onClick,
}) => {
  return (
    <BaseCard
      color="surface-02"
      className="py-4 px-6"
      childrenClassName="flex gap-4"
      isHighlighted={isHighlighted}
      onClick={onClick}
    >
      {image && (
        <img
          className="h-full w-auto shrink-0 rounded-xl"
          src={image}
          alt=""
        />
      )}
      <div className="grow space-y-2">
        <p className="type-body-medium text-text-04">{title}</p>
        <p className="type-small text-text-01">{description}</p>
      </div>
    </BaseCard>
  );
};
