import { FillInTheBlanksSuggestedQuestion } from '@stellar-lms-frontend/lms-graphql';
import {
  parseFITBTextFromAnswers,
  FillBlanksSettingsCard,
} from '@stellar-lms-frontend/ui-components';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { AssessmentQuestionTypes, AssessmentQuestionTypeNamesEnum } from '../util-functions';

type FillInTheBlanksSuggestionCardProps = {
  suggestion: FillInTheBlanksSuggestedQuestion;
  isSelected: boolean;
  setAddedSuggestions: Dispatch<SetStateAction<AssessmentQuestionTypes[]>>;
  setAcceptedSuggestions: Dispatch<SetStateAction<Set<string>>>;
  onRemoveSuggestion: (id: string) => void;
};

export const FillInTheBlanksSuggestionCard: React.FC<FillInTheBlanksSuggestionCardProps> = ({
  suggestion,
  isSelected,
  setAddedSuggestions,
  setAcceptedSuggestions,
  onRemoveSuggestion,
}) => {
  const transformedSuggestion = useMemo(
    () => ({
      ...suggestion,
      text: parseFITBTextFromAnswers(
        suggestion.text,
        suggestion.options.map((option, index) => ({
          label: `${index + 1}`,
          text: option ?? '',
        }))
      ),
    }),
    [suggestion]
  );

  return (
    <FillBlanksSettingsCard
      key={suggestion.id}
      isHighlighted={isSelected}
      onClick={(value) => {
        if (isSelected) {
          onRemoveSuggestion(suggestion.id);
        } else {
          setAcceptedSuggestions((prev) => prev.add(suggestion.id));
          setAddedSuggestions((prev) => [
            ...prev,
            {
              ...value,
              id: suggestion.id,
              __typename: AssessmentQuestionTypeNamesEnum.QUIZ_FILL_IN_THE_BLANKS_QUESTION,
            },
          ]);
        }
      }}
      isPreview
      value={{ ...transformedSuggestion, spacedRepetitionEnabled: true }}
    />
  );
};
