import { isMatch, useNavigateExternal } from '@stellar-lms-frontend/common-utils';
import {
  HeaderNavTheme,
  NavButton,
  UserFilledIcon,
  UserIcon,
} from '@stellar-lms-frontend/ui-components';

export const MembersNavButton = ({
  theme,
  title,
  url,
}: {
  theme: HeaderNavTheme;
  title: string;
  url: string;
}) => {
  const { navigateExternal } = useNavigateExternal();
  return (
    <NavButton
      theme={theme}
      navEntry={{
        title: title,
        filledIcon: [UserFilledIcon],
        icon: [UserIcon],
        action: () => navigateExternal(url),
        isActive: isMatch(url, 'contains'),
      }}
      type={'icon-text'}
    />
  );
};
