import { GraphQLClient } from 'graphql-request';
import { graphql } from '../graphql';

export const getAblyRequestToken = (graphQLClient: GraphQLClient) => {
  return graphQLClient.request(
    graphql(`
      query GetRealtimeTokenRequest {
        getRealtimeTokenRequest {
          capability
          clientId
          keyName
          mac
          nonce
          timestamp
          ttl
        }
      }
    `)
  );
};
