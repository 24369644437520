import { useTranslation } from 'react-i18next';

export enum ErrorCodes {
  INVALID_NAME = 'INVALID_NAME',
  INVALID_EMAIL = 'INVALID_EMAIL',
  MULTIPLE_MANAGERS_FOUND = 'MULTIPLE_MANAGERS_FOUND',
  MANAGER_NOT_FOUND = 'MANAGER_NOT_FOUND',
  DUPLICATE_EMAIL = 'DUPLICATE_EMAIL',
}

type ErrorMessageCellProps = {
  error: string;
};

const ErrorMessageCell: React.FC<ErrorMessageCellProps> = ({ error }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organization.members-tab.bulk-error-modal.table.rows',
  });

  const getErrorText = (error: string) => {
    switch (error) {
      case ErrorCodes.INVALID_EMAIL:
        return t('invalid-email');
      case ErrorCodes.INVALID_NAME:
        return t('invalid-name');
      case ErrorCodes.MANAGER_NOT_FOUND:
        return t('manager-not-found');
      case ErrorCodes.MULTIPLE_MANAGERS_FOUND:
        return t('multiple-manager-found');
      case ErrorCodes.DUPLICATE_EMAIL:
        return t('duplicate-email');
      default:
        return t('default');
    }
  };

  return <span className="text-sm">{getErrorText(error)}</span>;
};

export default ErrorMessageCell;
