import { GraphQLClient } from 'graphql-request';
import {
  AddUserToCompanyMutationVariables,
  BulkDeleteUsersMutationVariables,
  BulkUpdateUsersMutationVariables,
  BulkUploadUsersMutationVariables,
  CreateCompanyMutationVariables,
  DeleteUserFromCompanyMutationVariables,
  GetUploadUrlQueryVariables,
  MutationBatchAddRegistrationsForCourseArgs,
  ResendCompanyMemberInviteMutationVariables,
  UpdateCompanyMutationVariables,
  UpdateUserFromCompanyMutationVariables,
  UsersOfCompanyQueryVariables,
  graphql,
} from '../graphql';
import axios from 'axios';

export const updateCompany = (
  graphQLClient: GraphQLClient,
  variables: UpdateCompanyMutationVariables
) => {
  return graphQLClient.request({
    document: graphql(`
      mutation UpdateCompany($updateCompanyId: ID!, $input: CompanyInput!) {
        updateCompany(id: $updateCompanyId, input: $input) {
          id
          name
          industry
          language
        }
      }
    `),
    variables,
  });
};

export const getUsersOfCompany = (
  graphQLClient: GraphQLClient,
  variables: UsersOfCompanyQueryVariables
) => {
  return graphQLClient
    .request({
      document: graphql(`
        query UsersOfCompany($companyId: ID!, $pageSize: Int!, $cursor: String, $sort: UserSort) {
          usersOfCompany(companyId: $companyId, pageSize: $pageSize, cursor: $cursor, sort: $sort) {
            pageInfo {
              cursor
              hasNextPage
            }
            totalCount
            users {
              id
              status
              role
              jobTitle
              name
              thumbUri
              email
              language
              manager {
                id
                name
              }
            }
          }
        }
      `),
      variables,
    })
    .then((res) => res.usersOfCompany);
};

export const addUserToCompany = (
  graphQLClient: GraphQLClient,
  variables: AddUserToCompanyMutationVariables
) => {
  return graphQLClient.request({
    document: graphql(`
      mutation AddUserToCompany($companyId: ID!, $input: UserInput!) {
        addUser(companyId: $companyId, input: $input) {
          id
        }
      }
    `),
    variables,
  });
};

export const deleteUserFromCompany = (
  graphQLClient: GraphQLClient,
  variables: DeleteUserFromCompanyMutationVariables
) => {
  return graphQLClient.request({
    document: graphql(`
      mutation DeleteUserFromCompany($deleteUserId: ID!) {
        deleteUser(id: $deleteUserId)
      }
    `),
    variables,
  });
};

export const bulkDeleteUserFromCompany = (
  graphQLClient: GraphQLClient,
  variables: BulkDeleteUsersMutationVariables
) => {
  return graphQLClient.request({
    document: graphql(`
      mutation BulkDeleteUsers($companyId: ID!, $userIds: [ID!]!) {
        bulkDeleteUsers(companyId: $companyId, userIds: $userIds)
      }
    `),
    variables,
  });
};

export const updateUserFromCompany = (
  graphQLClient: GraphQLClient,
  variables: UpdateUserFromCompanyMutationVariables
) => {
  return graphQLClient.request({
    document: graphql(`
      mutation UpdateUserFromCompany($companyId: ID!, $userId: ID!, $input: UserUpdateInput!) {
        updateUser(companyId: $companyId, userId: $userId, input: $input) {
          id
        }
      }
    `),
    variables,
  });
};

export const bulkUpdateUsersFromCompany = (
  graphQLClient: GraphQLClient,
  variables: BulkUpdateUsersMutationVariables
) => {
  return graphQLClient.request({
    document: graphql(`
      mutation BulkUpdateUsers($companyId: ID!, $input: BulkUserUpdateInput!) {
        bulkUpdateUsers(companyId: $companyId, input: $input) {
          id
        }
      }
    `),
    variables,
  });
};

export const bulkAddToJourney = (
  graphQLClient: GraphQLClient,
  variables: MutationBatchAddRegistrationsForCourseArgs
) => {
  return graphQLClient.request({
    document: graphql(`
      mutation BulkAddToJourney($courseId: ID!, $input: BatchCourseRegistrationCreateInput!) {
        batchAddRegistrationsForCourse(courseId: $courseId, input: $input) {
          errors {
            userId
            errorCode
          }
        }
      }
    `),
    variables,
  });
};

export const resendCompanyMemberInvite = (
  client: GraphQLClient,
  variables: ResendCompanyMemberInviteMutationVariables
) => {
  return client.request(
    graphql(`
      mutation ResendCompanyMemberInvite($requestPasswordResetId: ID!) {
        requestPasswordReset(id: $requestPasswordResetId)
      }
    `),
    variables
  );
};

export const getUploadUrl = (client: GraphQLClient, variables: GetUploadUrlQueryVariables) => {
  return client.request(
    graphql(`
      query GetUploadUrl($companyId: ID!, $fileName: String!, $mimeType: String!) {
        getUploadUrl(companyId: $companyId, fileName: $fileName, mimeType: $mimeType) {
          fileId
          url
        }
      }
    `),
    variables
  );
};

export const bulkUploadUsersToCompany = (
  client: GraphQLClient,
  variables: BulkUploadUsersMutationVariables
) => {
  return client.request(
    graphql(`
      mutation BulkUploadUsers($companyId: ID!, $input: BulkUserUploadInput!) {
        bulkUploadUsers(companyId: $companyId, input: $input) {
          items {
            error {
              managerName
              name
              errorCode
            }
            id
            success
          }
        }
      }
    `),
    variables
  );
};

export const uploadFile = (url: string, file: File, callback?: (progres: number) => void) => {
  return axios.put<void>(url, file, {
    headers: { 'Content-Type': file.type },
    onUploadProgress: (progressEvent) => {
      if (callback) {
        callback(Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? 1)));
      }
    },
  });
};

export const getBulkUploadTemplateUrl = (client: GraphQLClient) => {
  return client.request(
    graphql(`
      query BulkUploadTemplateUrl {
        bulkUploadTemplateUrl
      }
    `)
  );
};

export const createCompany = (client: GraphQLClient, variables: CreateCompanyMutationVariables) => {
  return client.request(
    graphql(
      `
        mutation CreateCompany($input: CreateCompanyInput!) {
          createCompany(input: $input) {
            id
          }
        }
      `
    ),
    variables
  );
};
