import { DataCy } from '@stellar-lms-frontend/common-utils';
import { BaseCard, PlusCircleIcon, whiteBg } from '@stellar-lms-frontend/ui-components';

export type AddJourneyDashboardBoxI18N = {
  cta: string;
};

export type AddJourneyDashboardBoxProps = {
  onClick: () => void;
  i18n: AddJourneyDashboardBoxI18N;
  className?: string;
} & DataCy;

export const AddJourneyDashboardBox: React.FC<AddJourneyDashboardBoxProps> = ({
  i18n,
  onClick,
  className,
  'data-cy': dataCy,
}) => {
  return (
    <BaseCard
      onClick={onClick}
      className={`${className} rounded-xl bg-cover bg-center bg-no-repeat`}
      style={{
        backgroundImage: `url(${whiteBg})`,
      }}
      childrenClassName="flex items-center justify-center h-[237px]"
      data-cy={dataCy}
    >
      <div className="text-primary-02 flex flex-col items-center space-y-4">
        <PlusCircleIcon className="h-8 w-8" />
        <p className="text-xl font-medium">{i18n.cta}</p>
      </div>
    </BaseCard>
  );
};
