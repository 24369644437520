import { LogoLoader, UploadFile, Wizard, WizardStep } from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  StepChooseTransferObjectives,
  TransferObjective,
} from './components/step-choose-transfer-objectives';
import { StepChooseSkill } from './components/step-choose-skill';
import { StepDefineAudience } from './components/step-define-audience';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createJourneyGenerationProjectWithDocuments,
  getJourneyGenerationProject,
} from './create-journey.api';
import {
  InputLanguage,
  Language,
  mapToInputLanguage,
  useCompanyStore,
} from '@stellar-lms-frontend/lms-graphql';
import { USER } from '../constants/query-constants';
import { StepAddDocuments } from './components/step-add-documents';
import { graphQLClient } from '../../../lib/graphql';
import { getLoggedInUser } from '@stellar-lms-frontend/lms-api';
import { findLanguageByValue } from '../../../constants/languages';
import * as Sentry from '@sentry/react';
import { ChannelProvider } from 'ably/react';

export type CreateJourneyProjectData = {
  skill: string;
  targetAudience: string;
  industry: string;
  language: Language;
  companyId: string;
  projectId: string | undefined;
  realtimeChannel: string | undefined;
  allTransferObjectives: TransferObjective[];
  documents: UploadFile[];
};

const formsDataDefault = {
  skill: '',
  targetAudience: '',
  industry: '',
  companyId: '',
  projectId: undefined,
  realtimeChannel: undefined,
  allTransferObjectives: [],
  createOption: undefined,
  language: Language.En,
  documents: [],
};

export const CreateJourneyPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'create-journey' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const [formsData, setFormsData] = useState<CreateJourneyProjectData>(formsDataDefault);
  const [isDocumentStepDone, setIsDocumentStepDone] = useState(false);

  const { data: user } = useQuery([USER], () => getLoggedInUser(graphQLClient()), {
    staleTime: 60000,
  });

  const companyId = useCompanyStore((state) => state.companyId);

  useEffect(() => {
    setFormsData((f) => {
      return {
        ...f,
        language: findLanguageByValue(user?.language),
        companyId: companyId ?? '',
      };
    });
  }, [user?.language, user?.companyReference, companyId]);

  const { mutate: createJourneyGenerationProjectMutation } = useMutation(
    createJourneyGenerationProjectWithDocuments,
    {
      onSuccess: (data) => {
        if (!data.createAIProject) {
          Sentry.captureException(
            new Error('Creating journey generation project did not return project id'),
            {
              extra: {
                companyId: companyId,
                userId: user?.id,
              },
            }
          );
        } else {
          setFormsData((prev) => ({
            ...formsDataDefault,
            skill: prev.skill,
            targetAudience: prev.targetAudience,
            industry: prev.industry,
            language: prev.language,
            companyId: prev.companyId,
            documents: prev.documents,
            projectId: data.createAIProject ?? undefined,
            allTransferObjectives: [],
          }));
        }
      },
    }
  );

  useQuery(
    ['ai-project'],
    async () => {
      if (companyId && formsData.projectId) {
        const project = await getJourneyGenerationProject({
          companyId: companyId,
          projectId: formsData.projectId,
        });
        if (!project.journeyGenerationProject) {
          throw new Error('no project found');
        } else {
          setFormsData((prev) => ({
            ...prev,
            realtimeChannel: project.journeyGenerationProject?.realtimeChannel.id,
          }));
          return project;
        }
      } else {
        return null;
      }
    },
    {
      enabled: !!formsData.projectId && !!companyId,
      refetchOnWindowFocus: false,
      retryDelay: 1000,
      retry: 30,
    }
  );

  const journeyGenerationProjectMutation = useCallback(
    async (documents: UploadFile[]) => {
      if (
        formsData.skill &&
        formsData.industry &&
        formsData.targetAudience &&
        formsData.language &&
        user
      ) {
        return await createJourneyGenerationProjectMutation({
          companyId: companyId ?? user?.companyReference.id,
          projectInput: {
            skill: formsData.skill,
            audience: formsData.targetAudience,
            industry: formsData.industry,
            companyId: companyId ?? user?.companyReference.id,
            language: mapToInputLanguage(formsData.language) ?? InputLanguage.En,
            documents: documents
              .filter((file) => file.status === 'complete')
              .map((file) => {
                return {
                  name: file.file?.name ?? '',
                  fileId: file.fileId ?? '',
                };
              }),
          },
        });
      } else {
        return Promise.resolve();
      }
    },
    [
      companyId,
      createJourneyGenerationProjectMutation,
      formsData.industry,
      formsData.language,
      formsData.skill,
      formsData.targetAudience,
      user,
    ]
  );

  const onSubmitSkill = useCallback(
    (skill: string) =>
      setFormsData((prev) => ({
        ...prev,
        ...(prev.skill !== skill ? { allTransferObjectives: [], projectId: '' } : {}),
        skill,
      })),
    []
  );

  const onSubmitTargetAudience = useCallback(
    (data: { targetAudience: string; industry: string; language: Language }) => {
      setFormsData((prev) => ({
        ...prev,
        ...(prev.targetAudience !== data.targetAudience || prev.industry !== data.industry
          ? { allTransferObjectives: [], projectId: '' }
          : {}),
        skill: prev.skill,
        targetAudience: data.targetAudience,
        industry: data.industry,
        language: data.language,
      }));
    },
    []
  );

  const onSubmitDocuments = useCallback((documents: UploadFile[]) => {
    setFormsData((prev) => ({ ...prev, documents }));
    setIsDocumentStepDone(true);
  }, []);

  const onSubmitTransferObjectives = useCallback(
    (allTransferObjectives: TransferObjective[]) =>
      setFormsData((prev) => ({ ...prev, allTransferObjectives, createOption: undefined })),
    []
  );

  const steps = useMemo<WizardStep[]>(
    () => [
      {
        component: (
          <StepChooseSkill
            onSubmit={onSubmitSkill}
            skill={formsData.skill}
          />
        ),
        isCompleted: !!formsData.skill,
        title: t('steps.choose-skill.title'),
      },
      {
        component: (
          <StepDefineAudience
            onSubmit={onSubmitTargetAudience}
            skill={formsData.skill}
            data={{ ...formsData }}
          />
        ),
        isCompleted: !!formsData.industry && !!formsData.targetAudience,
        title: t('steps.define-audience.title'),
      },
      {
        component: (
          <StepAddDocuments
            onSubmit={onSubmitDocuments}
            data={formsData}
            createAIProject={journeyGenerationProjectMutation}
          />
        ),
        isCompleted: isDocumentStepDone,
        title: t('steps.add-documents.step-title'),
      },
      {
        component:
          formsData.realtimeChannel && formsData.projectId ? (
            <ChannelProvider channelName={formsData.realtimeChannel} >
            <StepChooseTransferObjectives
              onSubmit={onSubmitTransferObjectives}
              data={{ ...formsData, projectId: formsData.projectId }}
              companyId={formsData.companyId}
              projectId={formsData.projectId}
              realtimeChannel={formsData.realtimeChannel}
            />
            </ChannelProvider>
          ) : (
            <LogoLoader />
          ),
        isCompleted: formsData.allTransferObjectives.filter((s) => s.isSelected).length > 0,
        title: t('steps.choose-transfer-objectives.title'),
      },
    ],
    [
      onSubmitSkill,
      formsData,
      t,
      onSubmitTargetAudience,
      onSubmitDocuments,
      journeyGenerationProjectMutation,
      isDocumentStepDone,
      onSubmitTransferObjectives,
    ]
  );

  return (
    <Wizard
      steps={steps}
      allowStepClick
      i18n={{
        title: t('headline'),
        buttons: {
          left: tGeneral('home'),
          rightPrimaryDefault: tGeneral('next'),
          rightPrimaryLast: tGeneral('create'),
          rightSecondary: tGeneral('previous'),
        },
      }}
    />
  );
};
