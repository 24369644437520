import { DocumentIllustration } from '../../../icons';
import { ProgressBar } from '../../../progress-bar';
import { useTranslation } from 'react-i18next';

type FileInputLoadingProps = {
  progress: number;
};

export const FileInputLoading: React.FC<FileInputLoadingProps> = ({ progress }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.file-input' });

  return (
    <div className="flex flex-col items-center justify-center space-y-2">
      <DocumentIllustration className="h-[92px] w-[92px]" />
      <span className="text-text-02 type-body-default ">{t('loading')}</span>

      <ProgressBar
        htmlId={''}
        className={'mt-6 w-[260px]'}
        bgColorClassName={'bg-border-01'}
        roundedClassName="rounded-full"
        progress={[
          {
            value: progress ?? 0,
            colorClassName: 'bg-primary-01',
          },
        ]}
      />
    </div>
  );
};
