import { useEventListener } from '@stellar-lms-frontend/common-utils';
import { RightArrowIcon } from '../../icons';
import { ScaleOptionType } from './types';
import { SelectBox } from '../select';

export type ScaleProps = {
  options: ScaleOptionType[];
  left?: string;
  right?: string;
} & (
  | {
      isDisabled?: false;
      value?: ScaleOptionType;
      onChange: (selectedValue: ScaleOptionType | undefined) => void;
    }
  | {
      isDisabled: true;
      value?: never;
      onChange?: never;
    }
);

export const Scale: React.FC<ScaleProps> = ({
  options,
  value,
  onChange,
  left,
  right,
  isDisabled,
}) => {
  const handleKeyUp = (event: KeyboardEvent) => {
    const option = options.find((o) => o.label === event.key);
    option && onSelect(option);
  };

  useEventListener('keyup', handleKeyUp, !isDisabled);

  const onSelect = (newValue: ScaleOptionType) => {
    onChange?.(newValue.id === value?.id ? undefined : { ...newValue, userInput: newValue.label });
  };

  const renderInput = (option: ScaleOptionType) => {
    return (
      <div className="flex flex-row justify-center">
        <input
          name={`option-${option.label}`}
          type="radio"
          className="hidden"
          checked={option.id === value?.id}
          readOnly
        ></input>

        <label
          htmlFor={`option-${option.label}`}
          className="cursor-pointer select-none"
        >
          {option.label}
        </label>
      </div>
    );
  };

  const renderScaleOptionBox = (option: ScaleOptionType) => {
    const isSelected = value?.id === option.id;

    return (
      <div
        className="grow cursor-pointer"
        onClick={() => onSelect(option)}
      >
        {isSelected ? (
          <SelectBox
            type="selected"
            data-cy={`scale-option-selected-${option}`}
          >
            {renderInput(option)}
          </SelectBox>
        ) : (
          <SelectBox
            type="neutral-white"
            data-cy={`scale-option-neutral-${option}`}
          >
            {renderInput(option)}
          </SelectBox>
        )}
      </div>
    );
  };

  return (
    <div className="flex w-full flex-col">
      <div className="flex space-x-2">
        {options.map((option) => (
          <div
            key={option.id}
            className="grow"
          >
            {renderScaleOptionBox(option)}
          </div>
        ))}
      </div>
      <div className="font-lexend text-text-03 mt-4 flex flex-col text-base xl:flex-row xl:justify-between">
        <div className="flex flex-row items-center space-x-2">
          <span className="xl:hidden">{options[0].label}</span>
          <RightArrowIcon className="fill-text-03 h-4 w-4 stroke-1 xl:hidden" />
          <span>{left}</span>
        </div>
        <div className="flex flex-row items-center space-x-2">
          <span className="xl:hidden">{options[options.length - 1].label}</span>
          <RightArrowIcon className="fill-text-03 h-4 w-4 stroke-1 xl:hidden" />
          <span>{right}</span>
        </div>
      </div>
    </div>
  );
};

export default Scale;
