import { apiUrl } from '../context';

export const REDIRECT_HOME_URI = `${apiUrl}/user/home`;

export const COURSE_URI = 'programme/:courseId';
export const COURSE_ROUTE = (courseId: string) => `/learner/programme/${courseId}`;

// Spaced repetition
export const SPACED_REPETITION_URI = '/spaced-repetition';

// Actions
export const LEARNER_COURSE_ACTIONS_BASE_ROUTE = (courseId = '') =>
  `/learner/programme/${courseId}/actions`;

export const LEARNER_ACTIONS_BASE_URI = '/learner/programme/:courseId/actions';
export const LEGACY_MENTOR_ACTIONS_BASE_URI = '/mentor/actions/:actionId/*';
export const MENTOR_ACTIONS_BASE_URI = '/mentor/course/:courseId/actions';
export const MENTOR_ACTIONS_BASE_ROUTE = (courseId = '') => `/mentor/course/${courseId}/actions`;
export const ACTION_URI_ID_PARAM_NAME = 'actionId';
export const ACTION_EDIT_URI_PART = 'edit';

export const MENTOR_COURSE_INSIGHTS_URI = '/mentor/course/:courseId/insights';
export const MENTOR_COURSE_INSIGHTS_ROUTE = (courseId: string) =>
  `/mentor/course/${courseId}/insights`;

export const ACTION_REFLECTION_RESULT_URI_PART = 'reflection-result';
export const ACTION_REFLECTION_URI_PART = 'reflection';
export const ACTION_OLD_REFLECTION_URI_PART = 'complete';

export const ACTION_REVIEW_URI_PART = 'review';
export const ACTION_REVIEW_RESULT_URI_PART = 'review-result';
export const ACTION_OLD_REVIEW_URI_PART = 'review-fallback'; // Anyone know a better name for the fallback?

// More
// Deprecated soon

export const NOTIFICATIONS_URI = '/notifications';

// Mentor
export const MENTOR_BASE_URI = '/mentor';
export const MENTOR_COURSE_URI = '/mentor/course/:courseId';
export const MENTOR_COURSE_ROUTE = (courseId = '') => `/mentor/course/${courseId}`;
export const MENTOR_JOURNEY_URI = '/mentor/course/:courseId/journey';

// Mentor - Learner
export const MENTOR_LEARNERS_URI = '/mentor/course/:courseId/learners';
export const MENTOR_LEARNERS_ROUTE = (courseId = '') => `/mentor/course/${courseId}/learners`;

export const MENTOR_SURVEYS_URI = '/mentor/course/:courseId/surveys';
export const MENTOR_SURVEYS_ROUTE = (courseId = '') => `/mentor/course/${courseId}/surveys`;
export const MENTOR_SURVEY_URI = '/mentor/course/:courseId/surveys/:surveyId';
export const MENTOR_SURVEY_ROUTE = (courseId = '', surveyId = '') =>
  `/mentor/course/${courseId}/surveys/${surveyId}`;
export const MENTOR_SURVEY_QUESTION_URI =
  '/mentor/course/:courseId/surveys/:surveyId/question/:questionId';
export const MENTOR_SURVEY_QUESTION_ROUTE = (courseId = '', surveyId = '', questionId = '') =>
  `/mentor/course/${courseId}/surveys/${surveyId}/question/${questionId}`;

export const LEARNING_ACTIVITY_DETAIL_URI = 'learning-activity';

// These are relative routes (relative to /designer)
export const DESIGNER_COURSE_URI = 'course/:courseId';
export const DESIGNER_COURSE_INSIGHTS_URI = 'course/:courseId/insights';
export const DESIGNER_JOURNEY_URI = 'course/:courseId/journey';
export const DESIGNER_ACTIONS_URI = 'course/:courseId/actions';
export const DESIGNER_ACTION_URI = 'course/:courseId/actions/:actionId';
export const DESIGNER_SURVEYS_URI = 'course/:courseId/surveys';
export const DESIGNER_SURVEYS_ROUTE = (courseId = '') => `/designer/course/${courseId}/surveys`;
export const DESIGNER_SURVEY_URI = ':surveyId';
export const DESIGNER_SURVEY_ROUTE = (courseId = '', surveyId = '') =>
  `/designer/course/${courseId}/surveys/${surveyId}`;
export const DESIGNER_SURVEY_QUESTION_URI = ':surveyId/question/:questionId';
export const DESIGNER_SURVEY_QUESTION_ROUTE = (courseId = '', surveyId = '', questionId = '') =>
  `/designer/course/${courseId}/surveys/${surveyId}/question/${questionId}`;
export const DESIGNER_LEARNERS_URI = 'course/:courseId/learners';
export const DESIGNER_COURSE_ABOUT_URI = 'course/:courseId/about';
export const JOURNEY_CREATE_URI = 'course/create';
