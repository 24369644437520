import { useQuery, useQueryClient } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import { CompaniesQueryVariables, graphql } from '../graphql';
import { useCallback, useEffect, useState } from 'react';

const QUERY_KEY = 'COMPANIES';

export const useCompanies = (
  client: GraphQLClient,
  options: {
    max: number;
    search: string;
  }
) => {
  const queryClient = useQueryClient();
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean | undefined>(undefined);

  const query = useQuery(
    [QUERY_KEY, options],
    () => getCompanies(client, { max: options.max, query: options.search }),
    {
      staleTime: 60 * 1000, // 1 minute
      cacheTime: 5 * 60 * 10000, // 5 minutes
    }
  );

  useEffect(() => {
    if (!isSuperAdmin && (query.data?.length ?? 0) > 1) {
      setIsSuperAdmin(true);
    }
  }, [query.data, isSuperAdmin]);

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries([QUERY_KEY]);
  }, [queryClient]);

  return {
    isSuperAdmin,
    query,
    invalidateCache,
  };
};

export const getCompanies = (graphQLClient: GraphQLClient, variables: CompaniesQueryVariables) => {
  return graphQLClient
    .request({
      document: graphql(`
        query Companies($max: Int!, $query: String) {
          companies(max: $max, query: $query) {
            id
            name
          }
        }
      `),
      variables,
    })
    .then((res) => res.companies);
};
