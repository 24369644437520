import { GraphQLClient } from 'graphql-request';
import { MutationAddDiscussionItemArgs, MutationLikeDiscussionItemArgs, graphql } from '../graphql';

export const likeDiscussionItem = (
  client: GraphQLClient,
  activityId: string,
  stepId: string,
  discussionItemId: string
) => {
  const variables: MutationLikeDiscussionItemArgs = {
    activityId,
    stepId,
    discussionItemId,
  };
  return client.request(
    graphql(`
      mutation LikeDiscussionItem($activityId: ID!, $stepId: ID!, $discussionItemId: ID!) {
        likeDiscussionItem(
          activityId: $activityId
          stepId: $stepId
          discussionItemId: $discussionItemId
        )
      }
    `),
    variables
  );
};

export const unlikeDiscussionItem = (
  client: GraphQLClient,
  activityId: string,
  stepId: string,
  discussionItemId: string
) => {
  const variables: MutationLikeDiscussionItemArgs = {
    activityId,
    stepId,
    discussionItemId,
  };
  return client.request(
    graphql(`
      mutation UnlikeDiscussionItem($activityId: ID!, $stepId: ID!, $discussionItemId: ID!) {
        unlikeDiscussionItem(
          activityId: $activityId
          stepId: $stepId
          discussionItemId: $discussionItemId
        )
      }
    `),
    variables
  );
};

export const createDiscussionItem = (
  client: GraphQLClient,
  activityId: string,
  stepId: string,
  comment: string,
  parentDiscussionItemId?: string
) => {
  const variables: MutationAddDiscussionItemArgs = {
    activityId,
    stepId,
    comment,
    parentDiscussionItemId,
  };
  return client.request(
    graphql(`
      mutation AddDiscussionItem(
        $activityId: ID!
        $stepId: ID!
        $comment: String!
        $parentDiscussionItemId: ID
      ) {
        addDiscussionItem(
          activityId: $activityId
          stepId: $stepId
          comment: $comment
          parentDiscussionItemId: $parentDiscussionItemId
        ) {
          id
        }
      }
    `),
    variables
  );
};
