import { Badge } from '../badge/badge';
import { StarIcon } from '../icons';

export type BadgeAIProps = {
  label: string;
  color: 'purple' | 'dark-purple';
  className?: string;
};

const starIconColor = {
  purple: 'text-purple-500',
  'dark-purple': 'text-purple-600',
};

export const BadgeAI: React.FC<BadgeAIProps> = ({ label, color, className }) => {
  return (
    <Badge
      color={color}
      className={className}
    >
      <div className="flex flex-row items-center gap-[2px]">
        <StarIcon className={`h-4 w-4 ${starIconColor[color]}`} />
        <span>{label}</span>
      </div>
    </Badge>
  );
};
