import { ProgressBar } from '@stellar-lms-frontend/ui-components';
import { BoxColorDefinition } from '../../types';

// TODO might just be inlined, what's the use in having it separate
export const Progress = ({
  progress,
  colorDef,
  id,
}: {
  id: string;
  progress: number;
  colorDef: BoxColorDefinition;
}) => (
  <ProgressBar
    className="pt-3"
    htmlId={`${id}-progress`}
    heightClassName="h-1"
    roundedClassName="rounded-2xl"
    bgColorClassName={colorDef.progressBGColorClassName}
    progress={[
      {
        value: progress * 100,
        colorClassName: colorDef.progressFGColorClassName,
      },
    ]}
  />
);
