import { forwardRef } from 'react';
import { DataCy } from '@stellar-lms-frontend/common-utils';
import { ReactNode, MouseEvent } from 'react';
import { ButtonStyle, ButtonType } from '.';

export type ButtonTypes = 'primary' | 'secondary' | 'tertiary';

export type ClickButtonHandler = (event?: MouseEvent<HTMLButtonElement>) => void;

export interface BaseButtonProps extends DataCy {
  htmlId?: string;
  label: ReactNode;
  enabled?: boolean;
  onClick?: ClickButtonHandler;
  htmlType?: ButtonType;
  fullWidth?: boolean;
  buttonStyle?: ButtonStyle;
  className?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  isOnPrimaryColor?: boolean;
  display?: 'inline' | 'normal';
}

export const PrimaryButton = forwardRef<HTMLButtonElement, BaseButtonProps>(
  (
    {
      htmlId,
      label,
      enabled = true,
      fullWidth = false,
      onClick,
      htmlType = 'button',
      buttonStyle = 'normal',
      className,
      leftIcon,
      rightIcon,
      display = 'normal',
      'data-cy': dataCy,
    },
    ref
  ) => {
    let heightAndPadding = '';
    let itemSpacing = 'space-x-4';
    switch (buttonStyle) {
      case 'normal':
        heightAndPadding = 'h-14 px-6';
        break;
      case 'small':
        heightAndPadding = 'h-12 px-4';
        break;
      case 'icon':
        heightAndPadding = 'h-12';
        break;
      case 'fit':
        heightAndPadding = 'h-fit';
        break;
      case 'badge':
        heightAndPadding = 'h-12 pr-6 pl-5';
        itemSpacing = 'space-x-2';
        break;
      case 'fit_small_padding':
        heightAndPadding = 'h-fit';
        itemSpacing = 'space-x-2';
        break;
    }

    const width = fullWidth ? 'w-full' : 'w-full lg:w-auto';

    return (
      <button
        data-cy={dataCy}
        id={htmlId}
        onClick={onClick}
        disabled={!enabled}
        type={htmlType}
        className={`${
          display === 'inline' ? 'px-3 py-2 text-sm' : `${heightAndPadding} ${width} text-base`
        } font-lexend flex items-center ${itemSpacing} justify-between whitespace-nowrap font-medium
        leading-normal ${className}`}
        ref={ref}
      >
        {leftIcon && <div className={`items-end`}>{leftIcon}</div>}
        <div className="grow">{label}</div>
        {rightIcon && <div className={`items-end`}>{rightIcon}</div>}
      </button>
    );
  }
);

PrimaryButton.displayName = 'PrimaryButton';

export default PrimaryButton;
