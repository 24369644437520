import { DataCy } from '@stellar-lms-frontend/common-utils';
import React, { ReactNode } from 'react';

export type IconItemProps = DataCy & {
  className?: string;
  // CLEANUP?: is no-padding ever used? And is that really a good option for 'size'?
  size?: 'no-padding' | 'small' | 'medium' | 'large';
  onClick?: (e: React.UIEvent<Element>) => void;
  icon?: ReactNode;
  title: ReactNode;
  subtitleList?: ReactNode[];
  left?: ReactNode;
  right?: ReactNode;
  ['aria-label']?: string;
};

export const IconItem: React.FC<IconItemProps> = ({
  className = '',
  size = 'medium',
  onClick,
  icon,
  title,
  subtitleList,
  left,
  right,
  'aria-label': ariaLabel,
  'data-cy': dataCy,
}) => {
  return (
    <div
      className={`${className} flex items-center justify-between
                  ${size === 'small' ? 'py-3 pr-4' : ''} 
                  ${size === 'medium' ? 'py-4 pr-6' : ''} 
                  ${onClick ? 'cursor-pointer' : 'cursor-default'}
                  `}
      onClick={onClick}
      aria-label={ariaLabel}
      data-cy={dataCy}
    >
      <div className="flex grow items-center gap-4">
        {left}
        <div className="grow">
          <div className="font-medium">{title}</div>
          <div className="block md:hidden">{right}</div>
          {subtitleList && (
            <div className="text-text-03 mt-1 flex space-x-2 text-sm">
              {subtitleList.map((item, index, { length }) => (
                <React.Fragment key={index}>
                  <span>{item}</span>
                  {index < length - 1 && <span>·</span>}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-4">
        <div className="hidden md:block">{right}</div>
        {icon}
      </div>
    </div>
  );
};
