import { BaseCard, ColorType } from '../base-card/base-card';

export type CardDuringDragProps = {
  title: string;
  normalColor?: ColorType;
  darkerColor?: ColorType;
  isDragged?: boolean;
};

export const CardDuringDrag: React.FC<CardDuringDragProps> = ({
  title,
  normalColor,
  darkerColor,
  isDragged = false,
}) => {
  return (
    <BaseCard
      className={`my-2 ${isDragged ? 'bg-surface-02-hover' : 'bg-surface-01'} py-4`}
      color={isDragged ? darkerColor : normalColor}
    >
      {isDragged ? (
        <div className="h-6"></div>
      ) : (
        <div className="h-6 px-[42px]">
          <p className="h-full overflow-hidden text-ellipsis whitespace-nowrap font-semibold">
            {title}
          </p>
        </div>
      )}
    </BaseCard>
  );
};
