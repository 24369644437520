import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckBox, AutoResizeTextArea, ResizableTextAreaState } from '../../form';
import { BaseCard } from '../base-card/base-card';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import React from 'react';

export type EditableCheckboxCardState = {
  isSelected: boolean;
  title?: string;
  description?: string;
  titleState?: ResizableTextAreaState;
  descriptionState?: ResizableTextAreaState;
};

export type EditableCheckboxCardProps = {
  state: EditableCheckboxCardState;
  isEditable: boolean;
  onChange: (state: EditableCheckboxCardState) => void;
  titlePlaceholder?: string;
  descriptionPlaceholder?: string;
  className?: string;
  warningMessage?: string;
};

export const EditableCheckboxCard = React.forwardRef<
  HTMLTextAreaElement,
  EditableCheckboxCardProps
>(
  (
    {
      state,
      onChange,
      isEditable,
      titlePlaceholder,
      descriptionPlaceholder,
      warningMessage,
      className = '',
    },
    ref
  ) => {
    return (
      <BaseCard
        className={`font-lexend w-full p-5
      ${state.isSelected ? 'bg-on-primary-02' : 'bg-surface-02'}
      ${className}`}
        childrenClassName="flex gap-4"
      >
        <CheckBox
          value={state.isSelected}
          onChange={({ target: { checked: isSelected } }) => {
            onChange({ ...state, isSelected });
          }}
          readOnly={false}
          className="mt-[10px]"
        />
        <div className="flex grow flex-col gap-2">
          <AutoResizeTextArea
            disabled={!isEditable}
            minRows={1}
            maxRows={Infinity}
            ref={ref}
            onChange={(e) => {
              onChange({ ...state, title: e.target.value });
            }}
            theme="transparent"
            className={`text-text-01 font-semibold`}
            value={state.title}
            placeholder={titlePlaceholder}
            size="small"
            textareaState={state.titleState}
          />
          {(state.description || descriptionPlaceholder) && (
            <AutoResizeTextArea
              disabled={!isEditable}
              minRows={2}
              maxRows={Infinity}
              theme="transparent"
              onChange={(e) => {
                onChange({ ...state, description: e.target.value });
              }}
              className={`text-text-02 p-2 text-sm`}
              value={state.description}
              placeholder={descriptionPlaceholder}
              size="small"
              textareaState={state.descriptionState}
            />
          )}
          {warningMessage && (
            <div className="flex items-center gap-2">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="text-warning-01 h-4 w-4"
              />
              <p className="type-small text-orange-900">{warningMessage}</p>
            </div>
          )}
        </div>
      </BaseCard>
    );
  }
);
