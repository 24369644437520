import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import { ReactNode } from 'react';
import { CrossIcon } from '../../icons';

export type HintBoxProps = {
  icon: ReactNode;
  title: string;
  onClose?: VoidFunc;
  isOpen: boolean;
  theme: 'blue'; // configure the colors for the bg and crossIcon
  children: ReactNode;
  className?: string;
};

export const HintBox: React.FC<HintBoxProps> = ({
  icon,
  title,
  onClose,
  isOpen,
  theme,
  children,
  className,
}) => {
  if (!isOpen) return null;

  return (
    <div
      className={`font-lexend rounded-xl p-3 ${className}
                ${theme === 'blue' && 'bg-informative-03'}`}
    >
      <div className="mb-6 flex items-center justify-between">
        <div className="flex items-center gap-4">
          {icon}
          <p className="text-text-04 text-sm font-medium">{title}</p>
        </div>
        {onClose && (
          <button onClick={onClose}>
            <CrossIcon className={`${theme === 'blue' && 'text-informative-01'}`} />
          </button>
        )}
      </div>
      {children}
    </div>
  );
};
