import { ReactNode } from 'react';

export type ContentContainerProps = {
  children: ReactNode;
  className?: string;
  includePadding?: boolean; // CLEANUP this should not be needed, but difficult to remove the padding right now as it will have application wide impact
  allowOverflow?: boolean;
};

export const ContentContainer = ({
  children,
  className = 'w-full h-full',
  includePadding = true,
  allowOverflow = false,
}: ContentContainerProps) => {
  return (
    <div
      className={`${className} flex justify-center ${allowOverflow ? '' : 'overflow-hidden'} ${
        includePadding ? 'px-8 lg:p-16' : ''
      }`}
    >
      {children}
    </div>
  );
};
