import {
  Accordion,
  Badge,
  BadgeLanguage,
  BadgeSkill,
  BaseCard,
  CircleIcon,
  DocumentIllustration,
  Heading4,
  purpleBg,
} from '@stellar-lms-frontend/ui-components';
import { useScreenType } from '@stellar-lms-frontend/common-utils';
import { Course, Document } from '@stellar-lms-frontend/lms-graphql';
import { useCallback, useMemo } from 'react';
import { EmptyCardSection } from './components/empty-card-section';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/pro-light-svg-icons';

export type CourseDetailsCardProps = {
  course: Course;
  showEmptyState: boolean;
  i18n: CourseDetailsCardI18N;
  getDocument?: (courseId: string, documentId: string) => Promise<Document | undefined>;
};

export type CourseDetailsCardI18N = {
  readLess: string;
  readMore: string;
  skills: {
    label: string;
    empty: string;
  };
  objectives: {
    label: string;
    empty: string;
  };
  transferObjectives: {
    label: string;
    empty: string;
  };
  languages: {
    label: string;
  };
  documents: string;
};

export const CourseDetailsCard: React.FC<CourseDetailsCardProps> = ({
  course,
  showEmptyState = false,
  i18n,
  getDocument,
}) => {
  const { isTailwindLg } = useScreenType();

  const getCourseHeading = () => {
    if (isTailwindLg) {
      return course.title;
    }
    return course.description ? course.description : course.title;
  };

  const showSkills = useMemo(
    () => showEmptyState || (course.skills && course.skills.length > 0),
    [showEmptyState, course.skills]
  );
  const showObjectives = useMemo(
    () => showEmptyState || (course.objectives && course.objectives.length > 0),
    [showEmptyState, course.objectives]
  );

  const onDocumentClick = useCallback(
    async (documentId: string) => {
      if (getDocument) {
        const document = await getDocument(course.id, documentId);
        if (document && document.presignedUrl) {
          window.open(document.presignedUrl, '_blank');
        }
      }
    },
    [course.id, getDocument]
  );

  return (
    <BaseCard>
      <div
        className="rounded-t-xl p-4 pr-12 lg:px-8 lg:py-10"
        style={{ backgroundImage: `url(${purpleBg})` }}
      >
        <Heading4 className="text-text-04">{getCourseHeading()}</Heading4>
        <p className="text-text-02 mt-2 hidden text-sm lg:block">{course.description}</p>
      </div>
      <div className={'rounded-b-xl p-4 lg:p-6'}>
        <Accordion
          trigger={{
            openText: i18n.readLess,
            closedText: i18n.readMore,
            className: 'text-primary-02 text-sm font-regular',
          }}
          isEnabled={!isTailwindLg}
        >
          <div className="flex flex-col space-y-4">
            {showSkills && (
              <>
                <p className="text-text-01 type-tiny-emphasis">{i18n.skills.label}</p>
                {course.skills && course.skills.length > 0 ? (
                  <ul className="my-4 flex flex-wrap gap-2 break-all">
                    {course.skills.slice(0, isTailwindLg ? Infinity : 5).map((s) => (
                      <li
                        key={s}
                        className="overflow-hidden"
                      >
                        <BadgeSkill skill={s} />
                      </li>
                    ))}
                    {!isTailwindLg && course.skills.length > 5 && (
                      <li>
                        <Badge
                          color="grey"
                          size="lg"
                        >
                          <p className="type-small">+{course.skills.length - 5}</p>
                        </Badge>
                      </li>
                    )}
                  </ul>
                ) : (
                  <EmptyCardSection emptyText={i18n.skills.empty} />
                )}
              </>
            )}
            {showObjectives && (
              <>
                <p className="text-text-01 type-tiny-emphasis">{i18n.objectives.label}</p>
                {(course.objectives?.length ?? 0) > 0 ? (
                  <ul className="my-4 space-y-4">
                    {course.objectives?.map((o) => (
                      <li key={o.id}>
                        <div className="text-text-01 flex flex-row items-center space-x-2">
                          <CircleIcon className="shrink-0" />
                          <span className="text-sm font-medium">{o.name}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <EmptyCardSection
                    icon={<CircleIcon />}
                    emptyText={i18n.objectives.empty}
                  />
                )}
              </>
            )}
            {course.language && (
              <>
                <p className="text-text-01 type-tiny-emphasis">{i18n.languages.label}</p>
                <div>
                  <BadgeLanguage label={course.language.toUpperCase()} />
                </div>
              </>
            )}
            {course.documents && course.documents.length > 0 && (
              <>
                <p className="text-text-01 type-tiny-emphasis">{i18n.documents}</p>
                <ul className="my-4 space-y-4">
                  {course.documents?.map((doc) => (
                    <li key={doc.id}>
                      <div className="text-text-01 flex flex-row items-center space-x-2">
                        <FontAwesomeIcon
                          icon={faFile}
                          className="text-text-02 text-2xl"
                        />
                        <span
                          className="cursor-pointer text-sm font-medium underline"
                          onClick={() => onDocumentClick(doc.id)}
                        >
                          {doc.filename}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </Accordion>
      </div>
    </BaseCard>
  );
};
