import { useScroll } from '@react-hooks-library/core';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useWatchScreenResize } from '@stellar-lms-frontend/common-utils';

export const HorizontalScrollFadeWrapper = ({
  children,
  horizontalScrollViewElement,
}: {
  children: React.ReactNode | undefined;
  horizontalScrollViewElement: Element | null;
}) => {
  const leftFadeElement = useRef<HTMLDivElement | null>(null);
  const rightFadeElement = useRef<HTMLDivElement | null>(null);
  const [isScrollEnabled, setIsScrollEnabled] = useState(false);

  useScroll(horizontalScrollViewElement, ({ scrollX }) => {
    if (isNaN(scrollX)) return;

    if (scrollX === 0 && leftFadeElement.current) {
      leftFadeElement.current.style.opacity = '0';
    } else if (scrollX === 1 && rightFadeElement.current) {
      rightFadeElement.current.style.opacity = '0';
    } else if (rightFadeElement.current && leftFadeElement.current) {
      leftFadeElement.current.style.opacity = '1';
      rightFadeElement.current.style.opacity = '1';
    }
  });

  // Basically the initializer, otherwise the right fade will not show on page load, only after scrolling a bit
  useEffect(() => {
    initFades(horizontalScrollViewElement, leftFadeElement, rightFadeElement);
  }, [horizontalScrollViewElement]);

  useWatchScreenResize(() => {
    if (horizontalScrollViewElement) {
      const isScrollCurrentlyEnabled =
        horizontalScrollViewElement.scrollWidth > horizontalScrollViewElement.clientWidth;

      if (isScrollCurrentlyEnabled !== isScrollEnabled) {
        setIsScrollEnabled(isScrollCurrentlyEnabled);
        initFades(horizontalScrollViewElement, leftFadeElement, rightFadeElement);
      }
    }
  });

  return (
    <div className="relative w-full">
      <div className="pointer-events-none absolute z-50 -ml-4 flex h-full w-[calc(100%+32px)] flex-row justify-between">
        <div
          ref={leftFadeElement}
          className="flex flex-row opacity-0 transition-opacity duration-200 ease-in-out"
        >
          <div className="h-full w-[16px] bg-white"></div>
          <div
            ref={leftFadeElement}
            className="bg-left-fade-gradient h-full w-[50px] "
            style={{
              maskImage: 'linear-gradient(to bottom, transparent 10%, #000 50%, transparent 95%)',
            }}
          ></div>
        </div>
        <div
          ref={rightFadeElement}
          className="bg-right-fade-gradient h-full w-[50px] opacity-0 transition-opacity duration-200 ease-in-out"
          style={{
            maskImage: 'linear-gradient(to bottom, transparent 10%, #000 50%, transparent 95%)',
          }}
        ></div>
      </div>
      {children}
    </div>
  );
};

const initFades = (
  scrollViewElement: Element | null,
  leftFadeElement: MutableRefObject<HTMLDivElement | null>,
  rightFadeElement: MutableRefObject<HTMLDivElement | null>
) => {
  if (!leftFadeElement.current || !rightFadeElement.current) return;

  if (!scrollViewElement || scrollViewElement.scrollWidth <= scrollViewElement.clientWidth) {
    leftFadeElement.current.style.opacity = '0';
    rightFadeElement.current.style.opacity = '0';
  } else {
    if (scrollViewElement.scrollLeft === 0) {
      leftFadeElement.current.style.opacity = '0';
      rightFadeElement.current.style.opacity = '1';
    }
  }
};
