import { useQuery } from '@tanstack/react-query';
import { getUserProgress } from '../designer-insights.api';
import { BarChart } from '@stellar-lms-frontend/ui-components';

const chartConfig = {
  count: {
    label: 'Progress',
    color: 'var(--chart-1)',
  },
};
export const UserProgressChart = ({ courseId }: { courseId: string }) => {
  const { data, isLoading } = useQuery(
    ['user-progress', courseId],
    () => getUserProgress(courseId),
    { refetchOnWindowFocus: false },
  );

  return (
    <BarChart
      rows={data?.courseUserProgress.rows}
      isLoading={isLoading}
      dataColumns={['count']}
      chartConfig={chartConfig}
      stacked={false}
      showPercentages={true}
      showTooltip={true}
      showValueInBar={true}
      showYaxis={false}
    />
  );
};
