import {
  Dropdown,
  Input,
  LinkButton,
  Modal,
  PrimaryButton,
} from '@stellar-lms-frontend/ui-components';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SupportedLanguagesOptions } from '../types/types';
import moment from 'moment';
import { formatDate } from '@stellar-lms-frontend/common-utils';

export type AddCompanyModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: FormData) => void;
  name?: string;
  defaultLanguage: string;
  supportedLanguages: SupportedLanguagesOptions;
};

type FormData = {
  name: string;
  industry: string;
  language: string;
  license: 'trial' | 'active';
  trialStartDate: string;
  trialEndDate: string;
};

export const AddCompanyModal: React.FC<AddCompanyModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  name = '',
  defaultLanguage,
  supportedLanguages,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'add-company-modal' });
  const { t: tValidation } = useTranslation('translation', { keyPrefix: 'validation' });
  const today = moment().startOf('day');
  const tomorrow = moment().add(1, 'days').startOf('day');
  const defaultTrialEndDate = moment().add(7, 'day').endOf('day');

  const schema = yup.object().shape({
    name: yup.string().required(tValidation('required')),
    industry: yup.string(),
    language: yup.string().oneOf(supportedLanguages.map(({ value }) => value)),
    license: yup.string().oneOf(['trial', 'active']),
    trialEndDate: yup
      .date()
      .typeError(t('fields.license.trial.end.wrong-type'))
      .required(t('fields.license.trial.end.required'))
      .min(tomorrow, t('fields.license.trial.end.min', { date: formatDate(tomorrow) })),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    control,
    reset,
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      language: defaultLanguage,
      trialStartDate: formatDate(today),
      trialEndDate: formatDate(defaultTrialEndDate),
    },
  });

  const selectedLicenseType = watch('license');

  const renderTrialDates = useCallback(() => {
    if (selectedLicenseType !== 'trial') {
      return null;
    }
    return (
      <div className="flex flex-row space-x-6">
        <Controller
          control={control}
          name="trialStartDate"
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label={t('fields.license.trial.start.label')}
              htmlId="trialStartDate"
              htmlType="date"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              enabled={false}
            />
          )}
        />
        <Controller
          control={control}
          name="trialEndDate"
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label={t('fields.license.trial.end.label')}
              htmlId="trialEndDate"
              htmlType="date"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              error={errors.trialEndDate?.message}
            />
          )}
        />
      </div>
    );
  }, [errors.trialEndDate?.message, control, selectedLicenseType, t]);

  useEffect(() => {
    reset({
      name,
      language: defaultLanguage,
      trialStartDate: formatDate(today),
      trialEndDate: formatDate(defaultTrialEndDate),
    });
    // Only update name from props when it opens
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, reset]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('title')}
      buttons={{
        hasShadow: false,
        buttons: [
          <PrimaryButton
            key={1}
            onClick={handleSubmit(onSubmit)}
            label={t('buttons.create')}
            enabled={isValid}
            htmlType="submit"
          />,
          <LinkButton
            key={2}
            onClick={onClose}
            label={t('buttons.cancel')}
            htmlType="button"
          />,
        ],
      }}
    >
      <form
        className="space-y-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              htmlId="add-company-modal-name-input"
              placeholder={t('fields.name.placeholder')}
              label={t('fields.name.label')}
              error={errors.name?.message}
              required
            />
          )}
        />
        <Controller
          control={control}
          name="industry"
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              htmlId="add-company-modal-industry-input"
              placeholder={t('fields.industry.label')}
              label={t('fields.industry.placeholder')}
            />
          )}
        />

        <Dropdown
          label={t('fields.language.label')}
          htmlId="language"
          {...register('language')}
        >
          {supportedLanguages.map(({ label, value }) => (
            <option
              key={'language_' + value}
              value={value}
            >
              {label}
            </option>
          ))}
        </Dropdown>
        <Dropdown
          label={t('fields.license.label')}
          htmlId="license"
          {...register('license')}
        >
          <option
            key={'license_trial'}
            value="trial"
          >
            {t('fields.license.options.trial')}
          </option>
          <option
            key={'license_active'}
            value="active"
          >
            {t('fields.license.options.active')}
          </option>
        </Dropdown>
        {renderTrialDates()}
      </form>
    </Modal>
  );
};
