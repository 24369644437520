import { BoxColor, DashboardStatsBox } from './dashboard-stats-box';

export const BOX_COLORS = [
  'cyan',
  'purple',
  'yellow',
  'green',
  'indigo',
  'gray',
  'orange',
  'pink',
] as BoxColor[];

export const PreviewBoxList = () => {
  return (
    <ul className="3xl:grid-cols-2 relative mt-4 grid grid-cols-1 justify-items-center gap-4 lg:gap-10">
      {BOX_COLORS.slice(0, 6).map((color) => (
        <li
          key={color}
          className="w-full"
        >
          <DashboardStatsBox
            color={color}
            isPreview
          />
        </li>
      ))}
      <div className="absolute h-full w-full bg-gradient-to-b from-transparent to-white" />
    </ul>
  );
};
