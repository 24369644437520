import {
  AutoResizeTextArea,
  AutoResizeTextAreaProps,
  Debounced,
  Heading3,
  LinkButton,
  PrimaryButton,
  Sidebar,
} from '@stellar-lms-frontend/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getChangeEventValue } from '@stellar-lms-frontend/common-utils';
import { useQueryClient } from '@tanstack/react-query';
import { VideoSearchQuerySuggestion } from './components/video-search-query-suggestion';
import { SuggestedVideos } from './components/suggested-videos';
import { SuggestedSearchTerms, Video } from '@stellar-lms-frontend/lms-graphql';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type VideoAISidebarProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  findVideos: (query: string) => Promise<Video[] | undefined>;
  startVideoSearchTermGeneration: () => Promise<boolean>;
  getSuggestedVideoSearchTerm: () => Promise<SuggestedSearchTerms>;
  onAdd: (videos: Video[]) => void;
};

export const VideoAISidebar = ({
  isOpen,
  onClose,
  onAdd,
  findVideos,
  startVideoSearchTermGeneration,
  getSuggestedVideoSearchTerm,
}: VideoAISidebarProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'block-step-view.video-ai-sidebar' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const [selectedVideos, setSelectedVideos] = useState<{ [key: string]: Video }>({});
  const [searchQuery, setSearchQuery] = useState('');

  const queryClient = useQueryClient();

  return (
    <Sidebar
      isOpen={isOpen}
      onClose={onClose}
      position="right"
      contentClassName="md:max-w-[600px] md:w-[60%] font-lexend"
      isDisableScrollEnabled={false}
      hasBackdrop={false}
      header={<Heading3 className="text-text-01">{t('title')}</Heading3>}
      buttons={{
        hasDivider: true,
        buttons: [
          <PrimaryButton
            key="add-button"
            label={tGeneral('add')}
            enabled={Object.keys(selectedVideos).length > 0}
            onClick={() => {
              Object.keys(selectedVideos).length > 0 && onAdd([...Object.values(selectedVideos)]);
              onClose();
              setSelectedVideos({});
              // TODO remove those videos from search results??
            }}
          />,
          <LinkButton
            key="cancel-button"
            label={tGeneral('cancel')}
            onClick={onClose}
          />,
        ],
      }}
    >
      <Debounced<AutoResizeTextAreaProps>
        element={AutoResizeTextArea}
        placeholder={t('search.placeholder')}
        value={searchQuery}
        minRows={1}
        maxRows={3}
        leftIcon={
          <FontAwesomeIcon
            icon={faSearch}
            className="text-text-01 text-xl"
          />
        }
        onChangeDebounced={(e) => {
          setSearchQuery(getChangeEventValue(e));
        }}
        onChange={(e) => {
          queryClient.cancelQueries([{ searchQuery }]);
          setSearchQuery(getChangeEventValue(e));
        }}
      />
      <VideoSearchQuerySuggestion
        currentSearchTerm={searchQuery}
        onAcceptQuerySuggestion={(suggestion) => setSearchQuery(suggestion)}
        startVideoSearchTermGeneration={startVideoSearchTermGeneration}
        getSuggestedVideoSearchTerm={getSuggestedVideoSearchTerm}
      />
      <SuggestedVideos
        query={searchQuery}
        findVideos={findVideos}
        onSelectVideo={(video) =>
          setSelectedVideos((current) => ({ ...current, [video.url]: video }))
        }
        onDeselectVideo={(video) =>
          setSelectedVideos((current) => {
            const next = { ...current };
            delete next[video.url];
            return next;
          })
        }
      />
    </Sidebar>
  );
};
