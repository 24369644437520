import { VoidFunc } from '@stellar-lms-frontend/common-utils';

import { VerticalSeparator } from '../../separator/vertical-separator';
import { ReactNode } from 'react';
import { BackButton } from '../../buttons';

export type EditingHeaderProps = {
  backButton?: {
    onClick: VoidFunc;
    label: string;
  };
  title?: ReactNode;
  className?: string;
  rightElements?: ReactNode[];
};

export const EditingHeader: React.FC<EditingHeaderProps> = ({
  backButton,
  title,
  rightElements,
  className = '',
}) => {
  return (
    <nav
      className={`${className} border-b-1 flex h-[97px] w-full items-center justify-between lg:px-8 xl:px-16`}
    >
      <div className="shrink-1 grow-0">
        {backButton && (
          <BackButton
            className="w-auto"
            htmlId={'back-to-home'}
            label={backButton.label}
            onClick={backButton.onClick}
          />
        )}
      </div>
      <div className="grow-1 align-self-start flex w-full items-center">
        <VerticalSeparator />
        <div className="font-lexend ml-4 text-xl">{title}</div>
      </div>
      {rightElements && (
        <ul className="flex items-center gap-4">
          {rightElements.map((element, index) => (
            <li
              className="shrink-0 grow-0"
              key={index}
            >
              {element}
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
};
