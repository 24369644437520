import { DocumentIllustration } from '../../../icons';
import { useTranslation } from 'react-i18next';
import { TertiaryButton } from '../../../buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTurnDownRight } from '@fortawesome/pro-light-svg-icons';

type FileInputErrorProps = {
  onRetry: () => void;
};

export const FileInputError: React.FC<FileInputErrorProps> = ({ onRetry }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.file-input' });

  return (
    <div className="flex flex-col items-center justify-center space-y-2">
      <DocumentIllustration className="h-[92px] w-[92px]" />
      <span className="text-negative-02 type-body-default ">{t('error.generic')}</span>
      <TertiaryButton
        label={t('retry')}
        onClick={onRetry}
        className="text-text-01"
        leftIcon={
          <FontAwesomeIcon
            icon={faArrowTurnDownRight}
            className="text-text-01"
          />
        }
      />
    </div>
  );
};
