import { RequestError, RequestStatus } from '@stellar-lms-frontend/common-utils';

export const GraphQLErrorBuilder = (error: any): RequestError => {
  const graphQLError = error.response.errors?.[0];
  let status = RequestStatus.UNKNOWN;
  if (graphQLError) {
    const errorCode = graphQLError.extensions['code'];
    switch (errorCode) {
      case 403:
        status = RequestStatus.NO_ACCESS;
        break;
      case 404:
        status = RequestStatus.NOT_FOUND;
        break;
    }
  }
  return new RequestError(status, error);
};
