export interface ReadOnlyTextAreadProps {
  label: string;
  text: string;
}

export const ReadOnlyTextArea = ({ label, text }: ReadOnlyTextAreadProps) => {
  return (
    <div className="w-full">
      <p className={'font-lexend text-text-04 block text-sm font-medium'}>{label}</p>
      <div
        className={
          'font-lexend font-regular text-text-01 mt-1 inline-block w-full whitespace-pre-wrap break-words text-base'
        }
      >
        {text}
      </div>
    </div>
  );
};
