import { Modal, ModalButtonProps } from '@stellar-lms-frontend/ui-components';

export type ConfirmationModalProps = {
  title: string;
  description: string;
  buttons: ModalButtonProps;
  isOpen: boolean;
  onClose: () => void;
};

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  description,
  buttons,
  isOpen,
  onClose,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      buttons={buttons}
      className="bg-surface-01 h-full md:h-auto md:w-[510px]"
    >
      <p className="text-text-01 type-body-default">{description}</p>
    </Modal>
  );
};
