import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';
import { InputLanguage, graphql } from '../graphql';

export const getCheckoutSessionStatus = (sessionId: string, companyId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query GetCheckoutSessionStatus($sessionId: String!, $companyId: ID!) {
        stripeCheckoutSessionStatus(sessionId: $sessionId, companyId: $companyId) {
          status
          customerId
          sessionId
        }
      }
    `),
    {
      sessionId,
      companyId,
    }
  );
};

export const createCheckoutSession = (companyId: string, language?: InputLanguage) => {
  return defaultGraphqlClient.request(
    graphql(`
      mutation CreateStripeCheckoutSession($companyId: ID!, $language: InputLanguage) {
        createStripeCheckoutSession(companyId: $companyId, language: $language) {
          clientSecret
          sessionId
        }
      }
    `),
    { companyId, language }
  );
};

export const completeCheckoutSession = (companyId: string, sessionId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      mutation CompleteStripeCheckoutSession($companyId: ID!, $sessionId: String!) {
        completeStripeCheckoutSession(companyId: $companyId, sessionId: $sessionId)
      }
    `),
    { companyId, sessionId }
  );
};

export const createCustomerPortalSession = (companyId: string, language?: InputLanguage) => {
  return defaultGraphqlClient.request(
    graphql(`
      mutation CreateCustomerPortalSession($companyId: ID!, $language: InputLanguage) {
        createStripeCustomerPortalSession(companyId: $companyId, language: $language) {
          portalUrl
          sessionId
        }
      }
    `),
    { companyId, language }
  );
};
