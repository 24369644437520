import { BaseCard } from '@stellar-lms-frontend/ui-components';

export type WbaSuggestionCardProps = {
  image?: string;
  title: string;
  description: string;
  isHighlighted?: boolean;
  onClick?: React.MouseEventHandler<Element>;
};

export const WbaSuggestionCard: React.FC<WbaSuggestionCardProps> = ({
  image,
  title,
  description,
  isHighlighted,
  onClick,
}) => {
  return (
    <BaseCard
      color="surface-02"
      className="py-4 px-6"
      childrenClassName="flex"
      isHighlighted={isHighlighted}
      onClick={onClick}
    >
      {image && (
        <img
          src={image}
          alt={title}
          className="mr-4 h-full w-auto shrink-0 rounded-xl"
        />
      )}
      <div className="grow space-y-2">
        <p className="text-text-04 type-body-medium">{title}</p>
        <p className="text-text-01 type-body">{description}</p>
      </div>
    </BaseCard>
  );
};
