import moment from 'moment';

export const beforeToday = (date: Date): boolean => {
  const dueDate = moment(date);
  const now = moment(new Date()).startOf('day');
  const daysToGo = Math.trunc(moment.duration(dueDate.diff(now)).asDays());

  return daysToGo < 0;
};

export const destructureSeconds = (timeSeconds: number) => {
  const seconds = timeSeconds % 60;
  const minutes = Math.floor(timeSeconds / 60) % 60;
  const hours = Math.floor(timeSeconds / (60 * 60));

  return { hours, minutes, seconds };
};

export const formatDateLong = (date: Date, locale: string) => {
  return new Intl.DateTimeFormat(locale, {
    dateStyle: 'long',
  }).format(date);
};

export const formatDate = (date: moment.Moment) => {
  return date.format('yyyy-MM-DD');
};

export const formatDateTime = (date: moment.Moment) => {
  return date.format('yyyy-MM-DD HH:mm');
};

export const sortDates = (dateA: Date | undefined, dateB: Date | undefined) => {
  if (!dateA && !dateB) return 0;
  if (!dateA) return 1;
  if (!dateB) return -1;

  return dateA.getTime() - dateB.getTime();
};

export const calculateDaysRemaining = (today: Date, endDate: Date | undefined) => {
  if (endDate !== undefined) {
    const diff = endDate.getTime() - today.getTime();
    return Math.round(diff / (1000 * 3600 * 24));
  }
  return 0;
};
