import { ReactNode } from 'react';

export type BannerProps = {
  children: ReactNode;
  position: 'top' | 'bottom';
};

export const Banner: React.FC<BannerProps> = ({ children, position = 'top' }) => {
  return (
    <div
      className={`font-lexend text-surface-01 bg-negative-01 type-small-medium sticky z-50 w-full ${position}-0 p-4`}
    >
      {children}
    </div>
  );
};
