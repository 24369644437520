import { TitleLink } from './api-links';

export type LearningActivity = {
  id: string;
  title: string;
  steps: TitleLink[];
};

type ImageSettings = {
  url: string;
  fileId: string;
  isFullWidth: boolean;
};
export type LearningActivityStep = {
  id: string;
  title: string;
  source: string;
  secureSourceUrl?: string;
  sourceId?: string;
  sourceType: LearningActivityStepSourceType;
  enabled: boolean;
  durationInS: number;
  headerImage?: ImageSettings;
};

export type WorkBasedActionLearningActivityStep = LearningActivityStep & {
  sourceId: string;
};

export enum LearningActivityStepSourceType {
  IFRAME = 'IFRAME',
  SURVEY = 'SURVEY',
  ASSESSMENT = 'ASSESSMENT',
  WBA = 'WBA',
  BLOCK = 'BLOCK',
  SCENARIO = 'SCENARIO',
}
