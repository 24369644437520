import {
  ChevronDownIcon,
  ChevronLeftIcon,
  Duration,
  DurationI18N,
  FloatingBox,
  IconItem,
  Input,
  InputError,
  Label,
  PlusCircleIcon,
  PrimaryButton,
} from '@stellar-lms-frontend/ui-components';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

type FormShape = {
  hours: string;
  minutes: string;
};

export type DurationPickerI18N = {
  addLabel: string;
  hour: string;
  min: string;
  addCta: string;
  h: string;
  validation: {
    number: string;
    max: (n: number) => string;
    min: (n: number) => string;
    sum: string;
  };
  duration: DurationI18N;
};

export type DurationPickerProps = {
  value: number;
  onChange: (value: number) => void;
  options: number[];
  label?: string;
  required?: boolean;
  hasAdd: boolean;
  i18n: DurationPickerI18N;
};

export const DurationPicker: React.FC<DurationPickerProps> = ({
  value,
  onChange,
  options,
  hasAdd = true,
  i18n,
  label,
  required = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [stage, setStage] = useState<'add' | 'select'>('select');

  const optionsSorted = useMemo(() => {
    if (options.includes(value) || !value) {
      return options.sort((a, b) => a - b);
    } else {
      return [...options, value].sort((a, b) => a - b);
    }
  }, [options, value]);

  const schema = yup.object({
    hours: yup
      .number()
      .typeError(i18n?.validation.number ?? '')
      .min(0, i18n?.validation.min(0)),
    minutes: yup
      .number()
      .typeError(i18n?.validation.number ?? '')
      .max(59, i18n?.validation.max(59))
      .min(0, i18n?.validation.min(0)),
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid, touchedFields },
  } = useForm<FormShape>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      hours: '0',
      minutes: '0',
    },
  });

  useEffect(() => {
    setStage('select');
  }, [isOpen]);

  useEffect(() => {
    reset();
  }, [reset, stage]);

  // TODO: couldn't find a proper way to do it on yup. Using .test it will only run when the associated field change
  const sumError =
    parseInt(watch('hours')) + parseInt(watch('minutes')) === 0 ? i18n?.validation.sum : undefined;

  return (
    <FloatingBox
      placement="bottom-start"
      className="w-[300px]"
      wrappedComponent={
        <div>
          <Label
            label={label}
            required={required}
          />
          <button
            onClick={() => setIsOpen((prev) => !prev)}
            className="border-border-02 mt-2 flex h-14 w-full justify-between rounded-xl border p-4"
            type="button"
          >
            <Duration
              i18n={i18n?.duration}
              seconds={value}
              type="abbreviation"
            />
            <ChevronDownIcon />
          </button>
        </div>
      }
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      {stage === 'select' && (
        <>
          {optionsSorted.map((o) => (
            <IconItem
              key={o}
              title={
                <Duration
                  i18n={i18n?.duration}
                  seconds={o}
                  type="abbreviation"
                />
              }
              size="no-padding"
              className="hover:bg-surface-02 text-text-01 w-full cursor-pointer px-6 py-3 text-start"
              onClick={() => {
                onChange(o);
                setIsOpen(false);
              }}
            />
          ))}
          {hasAdd && (
            <IconItem
              size="no-padding"
              className="text-primary-02 hover:bg-surface-02 w-full cursor-pointer px-6 py-3 text-start"
              left={<PlusCircleIcon className="text-primary-02 h-4 w-4" />}
              title={i18n?.addLabel}
              onClick={(e) => {
                e.stopPropagation();
                setStage('add');
              }}
            />
          )}
        </>
      )}
      {stage === 'add' && (
        <div className="flex flex-col gap-4 p-4">
          <div className="text-text-01 flex gap-2 py-3 font-medium">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setStage('select');
              }}
            >
              <ChevronLeftIcon className="text-text-01 h-4 w-4" />
            </button>
            <p>{i18n?.addLabel}</p>
          </div>
          <div>
            <div className="flex justify-between gap-2">
              <Input
                htmlId={'duration-picker-hours-input'}
                labelClassName="capitalize"
                label={i18n?.hour ?? ''}
                error={errors.hours?.message}
                rightIcon={<p>{i18n?.h ?? ''}</p>}
                {...register('hours')}
              />
              <Input
                htmlId={'duration-picker-minutes-input'}
                labelClassName="capitalize"
                label={i18n?.min ?? ''}
                error={errors.minutes?.message}
                rightIcon={<p>{i18n?.min ?? ''}</p>}
                {...register('minutes')}
              />
            </div>
            <InputError error={Object.keys(touchedFields).length > 0 ? sumError : undefined} />
          </div>
          <div>
            <PrimaryButton
              enabled={isValid && !sumError}
              label={i18n?.addCta ?? ''}
              onClick={() => {
                handleSubmit((data) =>
                  onChange(parseInt(data.hours) * 60 * 60 + parseInt(data.minutes) * 60)
                )();
                setIsOpen(false);
              }}
            />
          </div>
        </div>
      )}
    </FloatingBox>
  );
};
