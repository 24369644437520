import { Dropdown, Input, Typewriter, WizardContext } from '@stellar-lms-frontend/ui-components';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { focusNextElement, replaceTagsInText } from '@stellar-lms-frontend/common-utils';
import { Language } from '@stellar-lms-frontend/lms-graphql';

type FormData = {
  targetAudience: string;
  industry: string;
  language: Language;
};

export type StepDefineAudienceProps = {
  onSubmit: (data: FormData) => void;
  skill: string;
  data: FormData;
};

export const StepDefineAudience: React.FC<StepDefineAudienceProps> = ({
  onSubmit,
  skill,
  data,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'create-journey.steps.define-audience',
  });
  const { t: tLanguage } = useTranslation('translation', { keyPrefix: 'languages' });
  const { t: tAnimation } = useTranslation('translation', {
    keyPrefix: 'create-journey.steps.animated-prompt',
  });
  const { t: tValidation } = useTranslation('translation', { keyPrefix: 'validation' });
  const [isFormsVisible, setIsFormsVisible] = useState(false);

  const supportedLanguages: Language[] = [
    Language.De,
    Language.En,
    Language.Es,
    Language.Fr,
    Language.It,
    Language.Nl,
    Language.Pt,
  ];

  const schema = yup.object().shape({
    targetAudience: yup.string().required(tValidation('required')),
    industry: yup.string().required(tValidation('required')),
    language: yup.string().required(tValidation('required')),
  });

  const { register, handleSubmit, formState, control } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      ...data,
    },
  });

  const { setNextFunc, setIsCurrentStepValid, setEnterFunc } = useContext(WizardContext);

  useEffect(() => {
    setEnterFunc(focusNextElement);
  }, [setEnterFunc]);

  useEffect(() => {
    setNextFunc(async () => {
      if (formState.isValid) {
        handleSubmit((data) => onSubmit(data))();
        return true;
      }

      return false;
    });
  }, [formState.isValid, handleSubmit, onSubmit, setNextFunc]);

  useEffect(() => {
    formState.isValid ? setIsCurrentStepValid(true) : setIsCurrentStepValid(false);
  }, [formState.isValid, setIsCurrentStepValid]);

  const isAnimationEnabled = useMemo(
    () => !data.industry && !data.targetAudience,
    [data.industry, data.targetAudience],
  );

  return (
    <div className="mx-auto flex w-full max-w-[560px] flex-col py-14">
      <p className="text-text-01 text-center text-3xl">
        {tAnimation('part-1')}{' '}
        <Typewriter
          onFinishTyping={() => setIsFormsVisible(true)}
          isEnabled={isAnimationEnabled}
        >
          {replaceTagsInText(tAnimation('part-2', { skill }), {
            blue: <span className="text-primary-02" />,
          })}
          {tAnimation('ellipsis')}
        </Typewriter>
      </p>
      {isFormsVisible && (
        <div className={`${isAnimationEnabled ? 'animate-fadeIn' : ''} mt-6 flex flex-col gap-6 `}>
          <p className="text-text-02 mb-6 text-center">{t('sub-text')}</p>
          <Controller
            control={control}
            name="targetAudience"
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                autoFocus
                htmlId={''}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                label={t('target-audience')}
                hint={t('target-audience-hint')}
              />
            )}
          />
          <Controller
            control={control}
            name="industry"
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onFocus={() => setEnterFunc(undefined)}
                onBlur={() => {
                  setEnterFunc(focusNextElement);
                  onBlur();
                }}
                htmlId={''}
                onChange={onChange}
                value={value}
                label={t('industry')}
                hint={t('industry-hint')}
              />
            )}
          />

          <Dropdown
            label={t('language')}
            htmlId="language"
            hint={t('language-hint')}
            {...register('language')}
          >
            {supportedLanguages.map((language) => (
              <option
                key={'language_' + language}
                value={language}
              >
                {tLanguage(language)}
              </option>
            ))}
          </Dropdown>
        </div>
      )}
    </div>
  );
};
