import { useQuery } from '@tanstack/react-query';
import { getCourseOverallWBAStates } from '../designer-insights.api';
import { useMemo } from 'react';
import { BarChart } from '@stellar-lms-frontend/ui-components';

const chartConfig = {
  started: {
    label: 'Started',
    color: 'var(--chart-1)',
  },
  reviewed: {
    label: 'Reviewed',
    color: 'var(--chart-2)',
  },
  completed: {
    label: 'Completed',
    color: 'var(--chart-3)',
  },
};

export const OverallWorkBasedActionStateDistributionChart = ({
  courseId,
}: {
  courseId: string;
}) => {
  const { data, isLoading } = useQuery(
    ['overall-course-wba-states', courseId],
    () => getCourseOverallWBAStates(courseId),
    { refetchOnWindowFocus: false }
  );

  const rows = useMemo(() => {
    if (!data) return [];

    return data.courseOverallWBAStates.rows.reduce((acc, curr) => {
      acc.push({
        category: curr.category,
        started: curr.items.find((i) => i.category === 'started')?.count ?? 0,
        reviewed: curr.items.find((i) => i.category === 'reviewed')?.count ?? 0,
        completed: curr.items.find((i) => i.category === 'completed')?.count ?? 0,
      });
      return acc;
    }, [] as { category: string; started: number; reviewed: number; completed: number }[]);
  }, [data]);

  return (
    <BarChart
      rows={rows}
      isLoading={isLoading}
      dataColumns={['started', 'reviewed', 'completed']}
      chartConfig={chartConfig}
      stacked={false}
      showYaxis={false}
      showValueInBar
    />
  );
};
