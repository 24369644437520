import { useMediaQuery } from './use-media-query';

export const useScreenType = () => {
  const isDesktop = useMediaQuery('(min-width: 1280px)'); // tailwind xl:
  const isTailwindLg = useMediaQuery('(min-width: 1024px)'); // tailwind lg:
  const isTabletOrLarger = useMediaQuery('(min-width: 768px)'); //tailwind md:

  return {
    isDesktop,
    isTailwindLg,
    isTabletOrLarger,
  };
};
