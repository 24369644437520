import { LearningActivityStep } from '@stellar-lms-frontend/lms-api';
import {
  CreateSurveyStepMutation,
  DesignerSurveyStepQuery,
  DesignerSurveyStepQueryVariables,
  MutationCreateSurveyStepArgs,
  MutationUpdateSurveyStepArgs,
  UpdateSurveyStepMutation,
} from '@stellar-lms-frontend/lms-graphql';
import { useParams } from 'react-router';
import SurveyStepEdit from './survey-step-edit';
import SurveyStepView, { SurveyStepViewProps } from './survey-step-view';
import { xxHash } from '@stellar-lms-frontend/common-utils';
import { UploadActions } from '../learning-activity/learning-activity-step-view';

export type SurveyActions = {
  survey?: {
    create?: (variables: MutationCreateSurveyStepArgs) => Promise<CreateSurveyStepMutation>;
    update?: (variables: MutationUpdateSurveyStepArgs) => Promise<UpdateSurveyStepMutation>;
    fetch?: (variables: DesignerSurveyStepQueryVariables) => Promise<DesignerSurveyStepQuery>;
  };
  publishedSurvey: SurveyStepViewProps['actions'];
};

export type SurveyStepProps = {
  isEditing?: boolean;
  lastLearningActivity?: boolean;
  step?: LearningActivityStep;
  actions: SurveyActions & UploadActions;
};

export const SurveyStep: React.FC<SurveyStepProps> = ({
  isEditing,
  lastLearningActivity = false,
  step,
  actions,
}) => {
  const { learningActivityId, courseId } = useParams();

  if (!learningActivityId || !courseId) {
    return null;
  }

  if (isEditing) {
    return (
      <SurveyStepEdit
        key={`${xxHash(JSON.stringify(step))}}`}
        learningActivityId={learningActivityId}
        courseId={courseId}
        step={step}
        actions={actions}
      />
    );
  } else if (step) {
    return (
      <SurveyStepView
        lastLearningActivity={lastLearningActivity}
        step={step}
        actions={actions.publishedSurvey}
      />
    );
  } else {
    return null;
  }
};
