export interface Heading1Props {
  children: React.ReactNode;
  className?: string;
}

export const Heading1 = ({ children, className = '' }: Heading1Props) => {
  return (
    <h1 className={`${className} font-lexend text-4xl font-light leading-tight md:text-5xl`}>
      {children}
    </h1>
  );
};

export default Heading1;
