import { ReactNode } from 'react';

/**
 * Navigation utility to keep track of navigation state across the application so that we can display buttons and titles in a generic navigation component (and potentially other components if needed)
 *
 * DISCLAIMER: A lot of improvements could be made to this.
 * TODO: improve the documentation
 *
 * Basically every category here represents a stack (represented by the keys array). The stack represents the current navigation we are in.
 * Imagine that we are in navigation level 1 with navigation key 'A' (so [A]) and then set a button with navigation key 'B' (: [A, B]) at this point 'B' will be the visible button as it is at the top of the stack.
 *
 * Why this complex stack thing and not just keep the current button?
 *   There are moments where we want to have a continuous next button flow where the sub-navigation levels are invisible to the users.
 *   We might navigate learning Steps on the top level and sometimes come across a survey or a quiz step which also need to use the top bar navigation to go between their own list of questions.
 *   By using the stack we can push this survey sub-level of navigation that hides away the step level navigation.
 *   When we come to the beginning or end of these surveys or quizzes we need to execute the action of the parent level (STEP level in this case) to go to the next step. We can do this by just popping off our
 *   current key which will uncover the other navigation with correct texts and click handlers.
 *
 * Whishlist:
 * - It's global state right now, that might not be the best thing, this could go into rematch or redux, or could be provided by a contextprovider.
 * - Some way to just change or append to the action that is defined on a button would be great.
 *   We do some popping and programatical clicking of the primary buttons in certain situations
 *   (when we want to save a quiz/survey at the end of the quiz and then still execute the 'next' behavior
 *   that is defined a level above on learning activity step level)
 * - Utility function that pops every button/title with a certain navigation key
 */

export type ButtonConfig = {
  label?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  action: () => void;
  disabled?: boolean;
};

type NavigationState = {
  rightPrimary: Map<string, ButtonConfig>;
  rightPrimaryKeys: string[];
  leftPrimary: Map<string, ButtonConfig>;
  leftPrimaryKeys: string[];
  rightSecondary: Map<string, ButtonConfig>;
  rightSecondaryKeys: string[];
  discussion: Map<string, ButtonConfig>;
  discussionKeys: string[];
  title: Map<string, string>;
  titleKeys: string[];
};

type CallBack = (navigationState: ChangeState) => void;

interface Navigation {
  currentState: NavigationState; //should be private
  subscribers: CallBack[]; //should be private

  setTitle: (key: string, title: string) => void;
  popTitle: (key: string) => void;

  setRightPrimary: (key: string, config: ButtonConfig) => void;
  popRightPrimary: (key: string) => void;
  clickRightPrimary: () => void;

  setLeftPrimary: (key: string, config: ButtonConfig) => void;
  popLeftPrimary: (key: string) => void;

  setRightSecondary: (key: string, config: ButtonConfig) => void;
  popRightSecondary: (key: string) => void;

  setDiscussion: (key: string, config: ButtonConfig) => void;
  popDiscussion: (key: string) => void;

  notify: (newState: NavigationState) => void; // should be private imho
  getChangeState: () => ChangeState;
  clear: () => void;
  clearLevel: (levelKey: string) => void;
  subscribe: (cb: CallBack) => () => void;
  unSubscribe: (cb: CallBack) => void;
}

const filterKey = (key: string, keys: string[]) => keys.filter((k) => k !== key);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filterMapKey = (key: string, map: Map<string, any>) => {
  map.delete(key);
  return map;
};

export type ChangeState = {
  leftPrimary: ButtonConfig | undefined;
  rightPrimary: ButtonConfig | undefined;
  rightSecondary: ButtonConfig | undefined;
  discussion: ButtonConfig | undefined;
  title: string | undefined;
};

export const navigation: Navigation = {
  currentState: {
    rightPrimary: new Map<string, ButtonConfig>(),
    rightPrimaryKeys: [],
    leftPrimary: new Map<string, ButtonConfig>(),
    leftPrimaryKeys: [],
    rightSecondary: new Map<string, ButtonConfig>(),
    rightSecondaryKeys: [],
    discussion: new Map<string, ButtonConfig>(),
    discussionKeys: [],
    title: new Map<string, string>(),
    titleKeys: [],
  },
  subscribers: [],

  /*
   * Title
   */
  setTitle(key: string, title: string) {
    let newKeys = [...this.currentState.titleKeys];
    if (!newKeys.some((k) => key === k)) {
      newKeys = [...newKeys, key];
    }

    this.currentState = {
      ...this.currentState,
      title: new Map(this.currentState.title.set(key, title)),
      titleKeys: newKeys,
    };

    this.notify(this.currentState);
  },
  popTitle(key: string) {
    this.currentState.title.delete(key);

    const index = this.currentState.titleKeys.findIndex((k) => key === k);
    if (index !== -1) {
      this.currentState.titleKeys.splice(index, 1);
    }

    this.currentState = {
      ...this.currentState,
      title: new Map(this.currentState.title),
      titleKeys: [...this.currentState.titleKeys],
    };

    this.notify(this.currentState);
  },

  /*
   * Next button actions
   */
  setRightPrimary(key: string, config: ButtonConfig) {
    let newKeys = [...this.currentState.rightPrimaryKeys];
    if (!newKeys.some((k) => key === k)) {
      newKeys = [...newKeys, key];
    }

    this.currentState = {
      ...this.currentState,
      rightPrimary: new Map(this.currentState.rightPrimary.set(key, config)),
      rightPrimaryKeys: newKeys,
    };

    this.notify(this.currentState);
  },
  popRightPrimary(key: string) {
    this.currentState.rightPrimary.delete(key);

    const index = this.currentState.rightPrimaryKeys.findIndex((k) => key === k);
    if (index !== -1) {
      this.currentState.rightPrimaryKeys.splice(index, 1);
    }

    this.currentState = {
      ...this.currentState,
      rightPrimary: new Map(this.currentState.rightPrimary),
      rightPrimaryKeys: [...this.currentState.rightPrimaryKeys],
    };
    this.notify(this.currentState);
  },
  clickRightPrimary() {
    const rightPrimary = this.currentState.rightPrimary.get(
      this.currentState.rightPrimaryKeys.at(-1) ?? ''
    );
    rightPrimary?.action();
  },

  /*
   * Back button actions
   */
  setLeftPrimary(key: string, config: ButtonConfig) {
    this.currentState = {
      ...this.currentState,
      leftPrimary: new Map(this.currentState.leftPrimary.set(key, config)),
      leftPrimaryKeys: [...this.currentState.leftPrimaryKeys, key],
    };
    this.notify(this.currentState);
  },
  popLeftPrimary(key: string) {
    this.currentState.leftPrimary.delete(key);

    const index = this.currentState.leftPrimaryKeys.findIndex((k) => key === k);
    if (index !== -1) {
      this.currentState.leftPrimaryKeys.splice(index, 1);
    }

    this.currentState = {
      ...this.currentState,
      leftPrimary: new Map(this.currentState.leftPrimary),
      leftPrimaryKeys: [...this.currentState.leftPrimaryKeys],
    };
    this.notify(this.currentState);
  },

  /*
   * Previous button
   */
  setRightSecondary(key: string, config: ButtonConfig) {
    let newKeys = [...this.currentState.rightSecondaryKeys];
    if (!newKeys.some((k) => key === k)) {
      newKeys = [...newKeys, key];
    }

    this.currentState = {
      ...this.currentState,
      rightSecondary: new Map(this.currentState.rightSecondary.set(key, config)),
      rightSecondaryKeys: newKeys,
    };
    this.notify(this.currentState);
  },
  popRightSecondary(key: string) {
    this.currentState.rightSecondary.delete(key);

    const index = this.currentState.rightSecondaryKeys.findIndex((k) => key === k);
    if (index !== -1) {
      this.currentState.rightSecondaryKeys.splice(index, 1);
    }

    this.currentState = {
      ...this.currentState,
      rightSecondary: new Map(this.currentState.rightSecondary),
      rightSecondaryKeys: [...this.currentState.rightSecondaryKeys],
    };
    this.notify(this.currentState);
  },

  /*
   * Discussion button
   */
  setDiscussion(key: string, config: ButtonConfig) {
    let newKeys = [...this.currentState.discussionKeys];
    if (!newKeys.some((k) => key === k)) {
      newKeys = [...newKeys, key];
    }

    this.currentState = {
      ...this.currentState,
      discussion: new Map(this.currentState.discussion.set(key, config)),
      discussionKeys: newKeys,
    };
    this.notify(this.currentState);
  },
  popDiscussion(key: string) {
    this.currentState.discussion.delete(key);

    const index = this.currentState.discussionKeys.findIndex((k) => key === k);
    if (index !== -1) {
      this.currentState.discussionKeys.splice(index, 1);
    }

    this.currentState = {
      ...this.currentState,
      discussion: new Map(this.currentState.discussion),
      discussionKeys: [...this.currentState.discussionKeys],
    };
    this.notify(this.currentState);
  },

  /*
   * Util
   */
  notify(newState: NavigationState) {
    this.subscribers.forEach((notify) => {
      notify(this.getChangeState());
    });
  },
  getChangeState() {
    const rightPrimary = this.currentState.rightPrimary.get(
      this.currentState.rightPrimaryKeys.at(-1) ?? ''
    );
    const leftPrimary = this.currentState.leftPrimary.get(
      this.currentState.leftPrimaryKeys.at(-1) ?? ''
    );
    const rightSecondary = this.currentState.rightSecondary.get(
      this.currentState.rightSecondaryKeys.at(-1) ?? ''
    );
    const discussion = this.currentState.discussion.get(
      this.currentState.discussionKeys.at(-1) ?? ''
    );
    const title = this.currentState.title.get(this.currentState.titleKeys.at(-1) ?? '');

    return { rightPrimary, leftPrimary, rightSecondary, discussion, title };
  },
  clear() {
    this.currentState = {
      leftPrimaryKeys: [],
      leftPrimary: new Map<string, ButtonConfig>(),
      rightPrimaryKeys: [],
      rightPrimary: new Map<string, ButtonConfig>(),
      rightSecondaryKeys: [],
      rightSecondary: new Map<string, ButtonConfig>(),
      discussionKeys: [],
      discussion: new Map<string, ButtonConfig>(),
      titleKeys: [],
      title: new Map<string, string>(),
    };

    this.notify(this.currentState);
  },
  clearLevel(levelKey: string) {
    this.currentState = {
      leftPrimaryKeys: filterKey(levelKey, this.currentState.leftPrimaryKeys),
      leftPrimary: filterMapKey(levelKey, this.currentState.leftPrimary),
      rightPrimaryKeys: filterKey(levelKey, this.currentState.rightPrimaryKeys),
      rightPrimary: filterMapKey(levelKey, this.currentState.rightPrimary),
      rightSecondaryKeys: filterKey(levelKey, this.currentState.rightSecondaryKeys),
      rightSecondary: filterMapKey(levelKey, this.currentState.rightSecondary),
      discussionKeys: filterKey(levelKey, this.currentState.discussionKeys),
      discussion: filterMapKey(levelKey, this.currentState.discussion),
      titleKeys: filterKey(levelKey, this.currentState.titleKeys),
      title: filterMapKey(levelKey, this.currentState.title),
    };

    this.notify(this.currentState);
  },

  subscribe(cb: CallBack) {
    this.subscribers = [...this.subscribers, cb];

    return () => this.unSubscribe(cb);
  },
  unSubscribe(callBack: CallBack) {
    this.subscribers = this.subscribers.filter((cb) => cb !== callBack);
  },
};
