import { SubscriptionType } from '@stellar-lms-frontend/lms-graphql';
import { GraphQLClient } from 'graphql-request';
import { useTranslation } from 'react-i18next';
import { useUsersOfCompany } from './users-tab/utils/use-users-of-company';
import { hasPermission } from '@stellar-lms-frontend/common-utils';
import { TabLink } from './tab-link';

export type SidebarCompany = {
  id: string;
  name: string;
  permissions: string[];
  subscriptionType: SubscriptionType | undefined;
};

export const Sidebar = ({
  company,
  graphQLClient,
}: {
  company: SidebarCompany;
  graphQLClient: GraphQLClient;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'organization.tabs' });
  const { t: tCurrentPlan } = useTranslation('translation', {
    keyPrefix: 'components.org-billing-tab.current-plan',
  });

  const {
    query: { data: members },
  } = useUsersOfCompany(graphQLClient, {
    companyId: company?.id,
    pageSize: 0,
  });

  const hasMemberManagementPermission = hasPermission(company.permissions, 'member_management');
  const hasCompanyUpdatePermission = hasPermission(company.permissions, 'update');

  const subscriptionTypeToLabel = (subscriptionType: SubscriptionType | undefined) => {
    switch (subscriptionType) {
      case SubscriptionType.Trial:
        return tCurrentPlan('trial.short-label');
      case SubscriptionType.Internal:
        return tCurrentPlan('internal.short-label');
      case SubscriptionType.StripeManaged:
        return tCurrentPlan('individual.short-label');
      case SubscriptionType.StellarLabsManaged:
        return tCurrentPlan('stellar-managed.short-label');
      default:
        return '';
    }
  };

  return (
    <div className="bg-sidebar-gradient p-16">
      <div className={`font-lexend flex flex-col gap-3`}>
        {hasCompanyUpdatePermission && (
          <TabLink
            to=""
            title={t('details')}
            subTitle={company.name ?? ''}
          />
        )}
        {hasMemberManagementPermission && (
          <TabLink
            to="members"
            title={t('members.title')}
            subTitle={t('members.subtitle', { count: members?.totalCount ?? 0 })}
          />
        )}
        {hasCompanyUpdatePermission && (
          <TabLink
            to="insights"
            title={t('insights.title')}
            subTitle={t('insights.subtitle')}
          />
        )}
        {hasCompanyUpdatePermission && (
          <TabLink
            to="billing"
            title={t('billing')}
            subTitle={subscriptionTypeToLabel(company.subscriptionType)}
          />
        )}
      </div>
    </div>
  );
};
