import { ReactNode } from 'react';

type ButtonSize = 'default' | 'large';
type RoundedIconButtonProps = {
  htmlId: string;
  enabled?: boolean;
  onClick?: () => void;
  icon?: ReactNode;
  size?: ButtonSize;
};

export const RoundedIconButton = ({
  htmlId,
  onClick,
  icon,
  size = 'default',
}: RoundedIconButtonProps) => {
  const sizeClassName = getSizeClassName(size);

  return (
    <div
      id={htmlId}
      role="button"
      className={`${sizeClassName} border-1 border-border-02 flex items-center justify-center rounded-full bg-white shadow-lg`}
      onClick={onClick}
    >
      {icon}
    </div>
  );
};

const getSizeClassName = (size: ButtonSize) => {
  let className = '';
  switch (size) {
    case 'default':
      className = 'w-10 h-10';
      break;
    case 'large':
      className = 'w-13 h-13';
      break;
  }
  return className;
};
