import { ReactNode } from 'react';

export type ContentWrapperProps = {
  children: ReactNode;
  className?: string;
};

export const ContentWrapper = ({ children, className = '' }: ContentWrapperProps) => {
  return (
    <div
      id="container"
      className={`${className} w-full  max-w-[512px] 2xl:max-w-[720px]`}
    >
      {children}
    </div>
  );
};
