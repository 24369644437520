import { InputAutoComplete, Modal, PrimaryButton } from '@stellar-lms-frontend/ui-components';
import { useCallback, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddMentorToCourseMutationVariables,
  getUsersOfCompany,
  useCurrentCompany,
} from '@stellar-lms-frontend/lms-graphql';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { COMPANY, COURSE, MEMBER } from '@stellar-lms-frontend/lms-api';
import { graphQLClient } from '../../../lib/graphql';
import { useParams } from 'react-router-dom';
import { addMentorToCourse } from './mentor-invite.api';
import { LEARNER_COURSE_DASHBOARD } from '../../../constants/query';

export type ModalInviteMentorProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ModalInviteMentor: React.FC<ModalInviteMentorProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'learner-course-dashboard.mentors' });
  const queryClient = useQueryClient();
  const { courseId } = useParams();
  const [selectedMemberId, setSelectedMemberId] = useState<string | undefined>(undefined);

  const { query: companyQuery } = useCurrentCompany(graphQLClient('learner'));
  const membersQuery = useQuery(
    [COMPANY, MEMBER, companyQuery.data?.id],
    () =>
      companyQuery.data?.id
        ? getUsersOfCompany(graphQLClient('learner'), {
            companyId: companyQuery.data?.id,
            pageSize: 500,
            cursor: '0',
          })
        : undefined,
    {
      enabled: !!companyQuery.data?.id,
    }
  );

  const { mutate: addMentorToCourseMutation } = useMutation(
    (input: AddMentorToCourseMutationVariables) =>
      addMentorToCourse(graphQLClient('learner'), input),
    {
      onSuccess: () => {
        onClose();
        queryClient.invalidateQueries([COURSE, MEMBER, courseId]);
        queryClient.invalidateQueries([LEARNER_COURSE_DASHBOARD, courseId]);
        toast.success(t('modal.toasts.success'));
      },
    }
  );

  const onSubmit = useCallback(
    (e?: MouseEvent<HTMLButtonElement>) => {
      e?.preventDefault();

      if (!courseId || !selectedMemberId) return;

      addMentorToCourseMutation({
        courseId,
        mentorId: selectedMemberId,
      });
    },
    [addMentorToCourseMutation, courseId, selectedMemberId]
  );

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title={t('modal.title')}
      className="bg-surface-01 h-full md:h-auto md:w-[640px]"
      buttons={{
        hasShadow: false,
        buttons: [
          <PrimaryButton
            key={0}
            label={t('modal.add-mentor')}
            enabled={selectedMemberId !== undefined}
            onClick={onSubmit}
          />,
        ],
      }}
    >
      <div className="flex h-full flex-col">
        <p className="text-text-02 mb-6 text-sm">{t('modal.description')}</p>
        <InputAutoComplete
          required
          onChangeInput={() => setSelectedMemberId(undefined)}
          suggestions={
            membersQuery.data?.users?.map((m) => ({
              id: m.id ?? '',
              label: m.name ?? '',
            })) ?? []
          }
          onSuggestionSelected={(s) => {
            setSelectedMemberId(s.id);
          }}
          renderOption={(s) => {
            return {
              label: s.label,
              className: 'type-body text-text-02',
            };
          }}
          label={t('modal.fields.select-member.label')}
          emptyLabel={t('modal.fields.select-member.empty-label')}
          placeholder={t('modal.fields.select-member.placeholder')}
        />
      </div>
    </Modal>
  );
};
