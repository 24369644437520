import { Heading3, RetainIllustration } from '@stellar-lms-frontend/ui-components';
import { GEARModalRetainImage, GEARModalRetainImage2 } from './images';
import { useTranslation } from 'react-i18next';

export const Retain: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'gear-template-modal.retain' });

  return (
    <div className="text-text-02 space-y-6">
      <div className="flex items-center gap-2">
        <RetainIllustration />
        <Heading3 className="text-text-04">{t('title')}</Heading3>
      </div>
      <p className="font-semibold">{t('brain-fact')}</p>
      <img
        src={GEARModalRetainImage}
        alt=""
        className="h-auto w-full"
      />
      <p>{t('memory-promotion')}</p>
      <ul className="ml-6 space-y-2">
        <li className="list-disc">{t('link-to-previous')}</li>
        <li className="list-disc">{t('emotional-experience')}</li>
        <li className="list-disc">{t('attention-guide')}</li>
        <li className="list-disc">{t('repetition')}</li>
        <li className="list-disc">{t('novelty')}</li>
        <li className="list-disc">{t('stories')}</li>
      </ul>
      <p>{t('top-tip')}</p>
      <p>{t('blend-ideas')}</p>
      <p>
        {t('use-the')}{' '}
        <a
          target="_blank"
          rel="noreferrer"
          className="underline"
          href="https://www.stellarlabs.io/resources/spaced-repetition"
        >
          {t('spaced-repetition-link-text')}
        </a>{' '}
        {t('in')}{' '}
        <a
          target="_blank"
          rel="noreferrer"
          className="underline"
          href="https://www.stellarlabs.io/platform"
        >
          {t('platform-link-text')}
        </a>{' '}
        {t('strengthen-memory')}
      </p>
      <img
        src={GEARModalRetainImage2}
        alt=""
        className="h-auto w-full"
      />
      <p>{t('scheduled-activities')}</p>
    </div>
  );
};
