import {
  EmptyState,
  ListFilterOptions,
  ListFilteringBar,
  MagnifyingLight,
  PlusIcon,
} from '@stellar-lms-frontend/ui-components';
import { Types, CurrentCompany, CurrentUser } from '@stellar-lms-frontend/lms-graphql';
import { BlueUIShell } from '../ui-shell/blue-ui-shell';
import { Logo } from '../logo/logo';
import {
  hasPermission,
  useNavigateExternal,
  useScreenType,
} from '@stellar-lms-frontend/common-utils';
import * as CourseList from '../course-list';
import * as Onboarding from '../onboarding';
import { AddJourneyDashboardBox, PreviewBoxList } from '../dashboard-stats-box';
import { useMemo } from 'react';
import { getDesignerCourseCreateRoute, getDesignerCourseEditRoute } from '../constants';
import { Question } from '@stellar-lms-frontend/lms-api';
import { SpacedRepetitionCTA } from './components/spaced-repetition-cta';
import { Actions } from './types';
import { Tip } from '../tip';
import { GraphQLClient } from 'graphql-request';
import { SupportedLanguagesOptions } from '../types/types';
import { useTranslation } from 'react-i18next';

export type HomePageProps = {
  graphQLClient: GraphQLClient;
  currentUser?: CurrentUser;
  currentCompany?: CurrentCompany;
  spacedRepetitionQuestions?: Question[];
  courseList: {
    list: Types.CourseDashboardListItem[];
    filterOptions: ListFilterOptions<Types.CourseDashboardListItem>; // cleanup, this combo of list and options can become a generic type when it is used more
  };
  actions: Actions;
  supportedLanguages: SupportedLanguagesOptions;
  defaultLanguage: string;
};

export const HomePage: React.FC<HomePageProps> = ({
  graphQLClient,
  currentUser,
  currentCompany,
  spacedRepetitionQuestions,
  courseList,
  actions,
  supportedLanguages,
  defaultLanguage,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'home' });
  const { t: tAll } = useTranslation('translation');

  const { isTailwindLg } = useScreenType();
  const { navigateExternal } = useNavigateExternal();

  const rightSideBar = useMemo(() => {
    return (spacedRepetitionQuestions?.length ?? 0) > 0 && currentUser ? (
      <div className="mt-[120px]">
        <SpacedRepetitionCTA firstName={currentUser.firstName ?? undefined} />
      </div>
    ) : (
      <div className="mt-[120px]">
        <Tip tips={tAll('tips', { returnObjects: true })} />
      </div>
    );
  }, [spacedRepetitionQuestions?.length, currentUser, tAll]);

  const subNavTitle = `${t('welcome-greeting')} ${currentUser?.firstName ?? ''}`;
  const subNavSubtitle = `${t('headline-cta-learner')}`;

  const isSearchOrFilter =
    courseList.filterOptions.state.searchQuery.length > 0 ||
    courseList.filterOptions.state.filterOptions.some((filter) => filter.hasSelectedValue);

  return (
    <BlueUIShell
      graphQLClient={graphQLClient}
      subNavTitle={subNavTitle}
      subNavSubtitle={subNavSubtitle}
      rightButton={
        hasPermission(currentCompany?.permissions, 'create_course')
          ? {
              label: t('create.new-journey'),
              leftIcon: <PlusIcon className="h-6 w-6" />,
              action: () => navigateExternal(getDesignerCourseCreateRoute()),
            }
          : undefined
      }
      rightSideBar={isTailwindLg && rightSideBar}
      actions={actions}
      currentUser={currentUser}
      currentCompany={currentCompany}
      leftButton={
        !isTailwindLg
          ? {
              label: '',
              action: () => null,
              leftIcon: (
                <Logo
                  type="short-icon"
                  theme="blue"
                  className="h-6 w-6 text-white"
                />
              ),
            }
          : undefined
      }
      supportedLanguages={supportedLanguages}
      defaultLanguage={defaultLanguage}
      hasCompanySelector
    >
      <>
        <div className="h-full w-full space-y-8">
          {/* show right side bar inline if the sceen is too small */}
          {!isTailwindLg && rightSideBar}

          <div className="flex h-full grow flex-col">
            <ListFilteringBar
              options={courseList.filterOptions}
              searchPlaceholder={t('search-input-placeholder')}
            />

            {courseList && courseList.list.length > 0 ? (
              <CourseList.CourseList
                courseList={courseList.list}
                actions={{
                  onEdit: (courseId) => navigateExternal(getDesignerCourseEditRoute(courseId)),
                  onDuplicate: actions.duplicateCourse,
                  onDelete: actions.deleteCourse,
                }}
              />
            ) : (
              <ListEmptyState
                isSearchOrFilter={isSearchOrFilter}
                searchQuery={courseList.filterOptions.state.searchQuery}
                permissions={currentCompany?.permissions ?? undefined}
              />
            )}
          </div>
        </div>
        {false && currentUser && (
          <Onboarding.Onboarding
            onClose={() => currentUser && actions.completeOnboarding()}
            isOpen={!currentUser?.hasCompletedOnboarding}
          />
        )}
      </>
    </BlueUIShell>
  );
};

const ListEmptyState = ({
  isSearchOrFilter,
  searchQuery,
  permissions,
}: {
  isSearchOrFilter: boolean;
  searchQuery: string;
  permissions?: string[];
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'home' });
  const { navigateExternal } = useNavigateExternal();

  if (isSearchOrFilter) {
    let title = t('no-results.title');
    if (searchQuery.length > 0) {
      title += ` ${t('no-results.for-search-term', {
        term: searchQuery,
      })}`;
    }
    return (
      <EmptyState
        className="mt-[110px]"
        icon={<MagnifyingLight />}
        title={title}
        description={t('no-results.description')}
      />
    );
  } else {
    return hasPermission(permissions, 'create_course') ? (
      <div className="3xl:grid-cols-2 relative mt-4 grid grid-cols-1 justify-items-center gap-4 lg:gap-10">
        <AddJourneyDashboardBox
          className="w-full"
          onClick={() => navigateExternal(getDesignerCourseCreateRoute())}
          i18n={{
            cta: t('empty.cta'),
          }}
        />
      </div>
    ) : (
      <>
        <p className="font-lexend font-regular text-text-02 mt-3 text-base lg:mt-2">
          {t('not-enrolled-subtitle')}
        </p>
        <PreviewBoxList />
      </>
    );
  }
};
