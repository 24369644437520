import { LearningActivityStep } from '@stellar-lms-frontend/lms-api';
import { EditHeader } from '../learning-activity/components/edit-header';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  ContentWrapper,
  EmptyState,
  Form,
  Input,
  Link2Icon,
  LinkIllustration,
} from '@stellar-lms-frontend/ui-components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { LearningActivityContext } from '../learning-activity';
import { useLearningActivityState } from '../learning-activity/hooks/use-learning-activity-state';
import { IFrame } from './iframe';
import { useAISuggestionsButton } from '../learning-activity/hooks/use-ai-suggestions-button';
import { useTranslation } from 'react-i18next';

type FormData = {
  title: string;
  url: string;
};

export type IFrameStepEditProps = {
  step?: LearningActivityStep;
  save: (data: FormData) => Promise<void>;
};

const srcFromIframeRegex = /(?:<iframe [^>]*src=")(https?:\/\/(?:[^"]+)*)"(?:[^>]*><\/iframe>)/;
const urlValidatorRegex =
  /^(http(s)?):\/\/(www\.)?[a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;

export const IFrameStepEdit: React.FC<IFrameStepEditProps> = ({
  step,
  save,
}: IFrameStepEditProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'iframe-step-view' });
  const { t: tValidation } = useTranslation('translation', { keyPrefix: 'validation' });

  const { setSaveFunc } = useContext(LearningActivityContext);

  const schema = yup.object({
    title: yup.string().required(tValidation('required')),
    url: yup
      .string()
      .required(tValidation('required'))
      .test('validUrl', t('invalid-url-or-embed'), (value) => !!urlValidatorRegex.exec(value)),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm<FormData>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      title: step?.title,
      url: step?.secureSourceUrl ?? step?.source,
    },
  });

  useLearningActivityState({ isDirty, isValid });

  useEffect(() => {
    setSaveFunc?.(() => handleSubmit(save));
  }, [handleSubmit, save, setSaveFunc]);

  useAISuggestionsButton({
    isLocked: true,
  });

  return (
    <div className="flex w-full flex-col">
      <ContentWrapper className="mx-auto mb-6 mt-10 flex-none">
        <Form className="space-y-3">
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <EditHeader
                {...field}
                onChangeTitle={(e) => field.onChange(e.target.value)}
                placeholder={t('title-placeholder')}
                error={errors.title?.message}
              />
            )}
          />
          <div className="flex items-center gap-4">
            <Link2Icon />
            <Controller
              control={control}
              name="url"
              render={({ field }) => (
                <Input
                  htmlId="iframe-url-input"
                  {...field}
                  onPaste={(data: string) => {
                    let resultUrl = data;

                    if (resultUrl.includes('<iframe')) {
                      const extractedMatch = srcFromIframeRegex.exec(data);

                      if (extractedMatch && extractedMatch.length > 1 && extractedMatch[1] !== '') {
                        resultUrl = extractedMatch[1];
                      }
                    }
                    field.onChange(resultUrl);
                    setValue('url', resultUrl);
                    return resultUrl;
                  }}
                  placeholder={t('url-or-embed-placeholder')}
                  error={errors.url?.message}
                />
              )}
            />
          </div>
        </Form>
      </ContentWrapper>
      {step?.secureSourceUrl || step?.source ? (
        <IFrame
          className="grow"
          title={step?.title}
          url={step?.secureSourceUrl ? step?.secureSourceUrl : step?.source}
        />
      ) : (
        <div className="mt-[100px] flex justify-center">
          <EmptyState
            icon={<LinkIllustration />}
            title={t('empty.title')}
            description={t('empty.subtitle')}
          />
        </div>
      )}
    </div>
  );
};

export default IFrameStepEdit;
