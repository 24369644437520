import {
  EditableCheckboxCardState,
  EditableCheckboxCard,
  DynamicList,
  SkeletonCard,
} from '@stellar-lms-frontend/ui-components';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

type LocalEditableCheckboxCardState = EditableCheckboxCardState & { id: string };

export type DynamicEditableCheckboxCardListProps = {
  items?: LocalEditableCheckboxCardState[];
  onChange: (items: LocalEditableCheckboxCardState[]) => void;
  isLoading?: boolean;
};

const emptyItem = (): LocalEditableCheckboxCardState => {
  return {
    id: uuid(),
    isSelected: false,
    title: '',
    description: '',
  };
};

const isItemEmpty = (item: LocalEditableCheckboxCardState) => {
  return !item.title && !item.description;
};

export const DynamicEditableCheckboxCardList: React.FC<DynamicEditableCheckboxCardListProps> = ({
  items = [],
  onChange,
  isLoading,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'create-journey.steps.choose-transfer-objectives',
  });

  return (
    <DynamicList
      innerRef={null}
      initialItems={items}
      renderItem={({ item, onChange }) => {
        const editableCheckboxCardState: EditableCheckboxCardState = {
          ...item,
          titleState: 'default',
          descriptionState: 'default',
        };
        let warningMessage = '';
        if (item.title !== '' || item.description !== '') {
          if (!item.title) {
            warningMessage = t('missing-title');
            editableCheckboxCardState.titleState = 'warning';
          } else if (!item.description) {
            warningMessage = t('missing-description');
            editableCheckboxCardState.descriptionState = 'warning';
          }
        }

        return (
          <EditableCheckboxCard
            className="min-h-full"
            isEditable={true}
            state={editableCheckboxCardState}
            onChange={(newState) => onChange({ id: item.id, ...newState })}
            titlePlaceholder={t('add-custom-transfer-objective')}
            descriptionPlaceholder={t('add-description')}
            warningMessage={warningMessage}
          />
        );
      }}
      onItemsChange={(items) => onChange(items)}
      getNewItem={emptyItem}
      isItemEmpty={isItemEmpty}
      loadingCard={
        <SkeletonCard
          className="min-h-full"
          barConfigs={[
            { width: '80%', height: '12px' },
            { width: '100%', height: '8px' },
            { width: '100%', height: '8px' },
          ]}
        />
      }
      isLoading={isLoading}
    />
  );
};
