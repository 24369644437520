import { DataCy } from '@stellar-lms-frontend/common-utils';

export type CountBoxProps = DataCy & {
  text: string;
  count: number;
  color: string;
  className?: string;
  size?: 'base';
};

export const CountBox: React.FC<CountBoxProps> = ({
  text,
  count,
  color,
  className,
  size = 'base',
  'data-cy': dataCy,
}) => {
  return (
    <div
      data-cy={dataCy}
      className={`bg-surface-02 font-lexend flex flex-col items-center justify-center rounded-xl p-3 text-center
                ${className}`}
    >
      <p
        className={`${color} 
                    ${size === 'base' && 'text-xl'}`}
      >
        {count}
      </p>
      <p className="text-text-02">{text}</p>
    </div>
  );
};
