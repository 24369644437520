import { useMemo } from 'react';
import { MultiSelect, SingleSelect } from '../form';
import { FontBody } from '../text';
import { QuizMultipleChoiceSettings } from './quiz-multiple-choice-settings-card';

export type QuizMultipleChoiceSettingsPreviewProps = {
  settings: QuizMultipleChoiceSettings;
  isPreview?: boolean;
  isHighlighted?: boolean;
};

// As with other preview cards we mess a bit with some properties to get a kind of working preview for designers.
// This should never be used to actually pick up answers/values
export const QuizMultipleChoiceSettingsPreview: React.FC<
  QuizMultipleChoiceSettingsPreviewProps
> = ({ settings, isPreview, isHighlighted }) => {
  const answers = useMemo(
    () =>
      settings.answers.map((a, index) => ({
        id: a.id ?? index.toString(), // index is normally not a good idea, but for this preview component it is fine
        label: a.text,
        shortcutKey: String.fromCharCode(65 + index),
        correct: a.correct,
        other: false, //TODO what to do here?
      })),
    [settings.answers]
  );

  const showCorrectAnswerSelectors = useMemo(
    () => answers.every((a) => a.correct !== undefined),
    [answers]
  );

  const getSelectTheme = () => {
    if (!isPreview) {
      return 'normal';
    }
    if (isHighlighted) {
      return 'blue';
    }
    return 'dark';
  };

  const hasRightSpace = showCorrectAnswerSelectors && !isPreview;

  return (
    <div className="space-y-4">
      <FontBody
        type="emphasis"
        className="text-text-02"
      >
        {settings.text}
      </FontBody>
      <div className={hasRightSpace ? '-mr-12' : ''}>
        {settings.multiple ? (
          <MultiSelect
            isDisabled
            showCorrectAnswerSelectors={showCorrectAnswerSelectors}
            theme={getSelectTheme()}
            options={answers}
          />
        ) : (
          <SingleSelect
            isDisabled
            showCorrectAnswerSelectors={showCorrectAnswerSelectors}
            theme={getSelectTheme()}
            options={answers}
          />
        )}
      </div>
    </div>
  );
};
