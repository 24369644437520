import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, ChevronRightIcon } from '@stellar-lms-frontend/ui-components';
import { useDueDate } from '../../../hooks/use-due-date';
import { LEARNER_ACTIONS_BASE_URI } from '../../../constants/routes';
import {
  PersistedLearnerWorkBasedAction,
  searchUsersLightById,
  USERS_LIGHT,
} from '@stellar-lms-frontend/lms-api';
import { useCurrentCompany } from '@stellar-lms-frontend/lms-graphql';
import { graphQLClient } from '../../../lib/graphql';

export type ActionListItemProps = {
  isDesktop: boolean;
  selectedActionId?: string;
  action: PersistedLearnerWorkBasedAction;
  titleClassName?: string;
  showUserName?: boolean;
  baseUrl?: string;
};

export const ActionListItem = ({
  isDesktop,
  selectedActionId,
  action,
  titleClassName = 'text-text-01',
  showUserName = false,
  baseUrl = LEARNER_ACTIONS_BASE_URI,
}: ActionListItemProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    query: { data: company },
  } = useCurrentCompany(graphQLClient('learner'));
  const { data: user } = useQuery(
    [USERS_LIGHT, action.userId],
    () => (company ? searchUsersLightById(graphQLClient(), company.id, [action.userId || '']) : []),
    {
      select: (data) => data.at(0),
    }
  );

  const selectAction = (id: string) => {
    navigate(`${baseUrl}/${id}`, { state: location.state });
  };

  return (
    <li
      className={`py-4 pr-8 ${isDesktop ? '' : '-mr-8'} ${
        isDesktop && action.id === selectedActionId
          ? 'bg-blue-selected-gradient border-primary-01 border-r-2'
          : ''
      } shadow-bottom-line hover:bg-surface-03 flex cursor-pointer items-center justify-between`}
      onClick={() => selectAction(action.id)}
      data-cy="action-list-item"
    >
      <div className="space-y-2">
        <div
          className={`${
            isDesktop && action.id === selectedActionId ? 'text-primary-01' : titleClassName
          } font-lexend text-base font-medium leading-normal`}
          data-cy="title"
        >
          {action.title}
        </div>
        <div
          className="flex w-full space-x-2"
          data-cy="state"
        >
          <ActionStateView action={action} />
          {showUserName && user && (
            <>
              <span className="font-lexend font-regular text-text-03 text-sm">·</span>
              <span className="font-lexend font-regular text-text-03 text-sm">
                {user.name ?? ''}
              </span>
            </>
          )}
        </div>
      </div>
      <ChevronRightIcon className="fill-text-03 xl:hidden" />
    </li>
  );
};

const ActionStateView = ({ action }: { action: PersistedLearnerWorkBasedAction }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'actions' });
  const getDue = useDueDate();

  const due = action.learnerDueDate ? getDue(action.learnerDueDate) : undefined;

  if (['reviewed', 'completed'].includes(action.state)) {
    return (
      <span className={`text-text-03 font-lexend font-regular inline-block text-sm leading-normal`}>
        {t('completed-date', { date: moment(action.completedDate).format('D MMM yyyy') })}
      </span>
    );
  }
  if (action.state === 'executed') {
    return <Badge color="purple">{t('state.awaiting_review')}</Badge>;
  }

  if (due && due.type === 'overdue') {
    return <Badge color="red">{due.label}</Badge>;
  }
  return (
    <span className={`text-text-03 font-lexend font-regular inline-block text-sm leading-normal`}>
      {due ? due.label : ''}
    </span>
  );
};
