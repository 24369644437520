import { useNavigateExternal, useScreenType } from '@stellar-lms-frontend/common-utils';
import { BrainLight, EmptyState, Nugget } from '@stellar-lms-frontend/ui-components';
import { SPACED_REPETITION_URI } from '../../constants';
import { useTranslation } from 'react-i18next';

export const SpacedRepetitionCTA = ({ firstName }: { firstName?: string }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'spaced-repetition.start-page' });
  const { isTailwindLg } = useScreenType();
  const { navigateExternal } = useNavigateExternal();

  return isTailwindLg ? (
    <EmptyState
      icon={<BrainLight className="h-fit w-[72px] min-w-[72px] lg:w-auto" />}
      title={t('title')}
      description={t('subtitle', { firstName: firstName })}
      actionText={t('call-to-action')}
      onActionClick={() => navigateExternal(SPACED_REPETITION_URI + '?state=questions')}
      buttonType={'primary'}
    />
  ) : (
    <Nugget
      left={<BrainLight className="h-fit w-[72px] min-w-[72px] lg:w-auto" />}
      title={t('title')}
      description={t('subtitle')}
      actionText={t('call-to-action')}
      onActionClick={() => navigateExternal(SPACED_REPETITION_URI + '?state=questions')}
    />
  );
};
