import { Question, QuestionUserAnswer, TypeMap } from '@stellar-lms-frontend/lms-api';
import {
  SurveyChoiceQuestion,
  SurveyScaleQuestion,
  SurveyFreeTextQuestion,
  SurveyQuestionType,
  SurveyQuestionInput,
} from '@stellar-lms-frontend/lms-graphql';
import * as yup from 'yup';

export type SurveyQuestionTypes = (
  | Omit<SurveyChoiceQuestion, 'id'>
  | Omit<SurveyScaleQuestion, 'id'>
  | Omit<SurveyFreeTextQuestion, 'id'>
) & { id?: string };

export enum SurveyQuestionTypeNamesEnum {
  SURVEY_CHOICE_QUESTION = 'SurveyChoiceQuestion',
  SURVEY_SCALE_QUESTION = 'SurveyScaleQuestion',
  SURVEY_FREE_TEXT_QUESTION = 'SurveyFreeTextQuestion',
}

/*
 *  Transform the ids of the stored answers of a learner to the ids of a new submission and return the transformed answers
 */
export const transformQuestionsToNewSubmission = (
  questionsToSubmit: TypeMap<TypeMap<QuestionUserAnswer>>,
  originalQuestions: Pick<Question, 'id' | 'questionId' | 'answers'>[],
  newQuestions: Pick<Question, 'id' | 'questionId' | 'answers'>[]
) => {
  // map the unique question.id fields of the original submission to the new one to correctly send newly submitted answers
  const questionIds = originalQuestions.map((question) => question.questionId);

  const idToQuestionIdMapping = originalQuestions.reduce((acc, next) => {
    acc[next.questionId] = next.id;
    return acc;
  }, {} as TypeMap<string>);

  const questionIdToIdMapping = newQuestions.reduce((acc, next) => {
    acc[next.questionId] = next.id;
    return acc;
  }, {} as TypeMap<string>);

  // now build up a mapping from unique id in the original submission to the new one
  const combinedMapping = questionIds.reduce((acc, next) => {
    const originalId = idToQuestionIdMapping[next];
    const newId = questionIdToIdMapping[next];
    acc[originalId] = newId;
    return acc;
  }, {} as TypeMap<string>);

  const answersMapping = buildAnswerIdMap(originalQuestions, newQuestions);

  // transform the answers to submit to work for the new submission
  const transformedAnswers = Object.keys(questionsToSubmit).reduce((acc, currentKey) => {
    const transformedKey = combinedMapping[currentKey];
    acc[transformedKey] = transformAnswersToNewSubmission(
      questionsToSubmit[currentKey],
      answersMapping
    );
    return acc;
  }, {} as TypeMap<TypeMap<QuestionUserAnswer>>);

  return transformedAnswers;
};

const transformAnswersToNewSubmission = (
  answersToSubmit: TypeMap<QuestionUserAnswer>,
  answersIdMapping: TypeMap<string>
) => {
  const transformedAnswers = Object.keys(answersToSubmit).reduce((acc, currentKey) => {
    const transformedKey = answersIdMapping[currentKey];
    acc[transformedKey] = answersToSubmit[currentKey];
    return acc;
  }, {} as TypeMap<QuestionUserAnswer>);

  return transformedAnswers;
};

const buildAnswerIdMap = (
  originalQuestions: Pick<Question, 'answers'>[],
  newQuestions: Pick<Question, 'answers'>[]
) => {
  const allOriginalAnswers = originalQuestions.flatMap((question) => question.answers);

  const originalAnswers = allOriginalAnswers.reduce((acc, current) => {
    acc[current.answerId] = current.id;
    return acc;
  }, {} as TypeMap<string>);

  const newAnswers = newQuestions
    .flatMap((question) => question.answers)
    .reduce((acc, current) => {
      acc[current.answerId] = current.id;
      return acc;
    }, {} as TypeMap<string>);

  const answerIds = allOriginalAnswers.map((answer) => answer.answerId);

  const combinedMapping = answerIds.reduce((acc, next) => {
    const originalId = originalAnswers[next];
    const newId = newAnswers[next];
    acc[originalId] = newId;
    return acc;
  }, {} as TypeMap<string>);

  return combinedMapping;
};

// TODO: ideally this validation should be placed inside each card component, but it's not possible because the data schema of the component it's different from the graphql schema
export const fieldSchemas = (i18n: { required?: string }) => ({
  [SurveyQuestionTypeNamesEnum.SURVEY_FREE_TEXT_QUESTION]: yup.object().shape({
    text: yup.string().required(i18n?.required),
  }),
  [SurveyQuestionTypeNamesEnum.SURVEY_CHOICE_QUESTION]: yup.object().shape({
    text: yup.string().required(i18n?.required),
  }),
  [SurveyQuestionTypeNamesEnum.SURVEY_SCALE_QUESTION]: yup.object().shape({
    text: yup.string().required(i18n?.required),
    leftLabel: yup.string().required(i18n?.required),
    rightLabel: yup.string().required(i18n?.required),
  }),
});

export type SurveyFreeTextErrorType =
  | {
      text?: { message?: string };
    }
  | undefined;

export type SurveyChoiceErrorType =
  | {
      text?: { message: string };
    }
  | undefined;

export type SurveyScaleErrorType =
  | {
      text?: { message: string };
      leftLabel?: { message: string };
      rightLabel?: { message: string };
    }
  | undefined;

export const transformSurveyQuestionsToSubmit = (
  questions: SurveyQuestionTypes[]
): SurveyQuestionInput[] =>
  questions
    .map((q) => {
      switch (q.__typename) {
        case SurveyQuestionTypeNamesEnum.SURVEY_CHOICE_QUESTION:
          return {
            text: q.text,
            id: q.id,
            required: q.required,
            visibleToMentors: q.visibleToMentors,
            type: SurveyQuestionType.Choice,
            choice: {
              answers: q.answers,
              multiple: q.multiple,
            },
          };
        case SurveyQuestionTypeNamesEnum.SURVEY_FREE_TEXT_QUESTION:
          return {
            text: q.text,
            id: q.id,
            type: SurveyQuestionType.Freetext,
            required: q.required,
            visibleToMentors: q.visibleToMentors,
            freeText: {
              multiline: q.multiline,
            },
          };
        case SurveyQuestionTypeNamesEnum.SURVEY_SCALE_QUESTION:
          return {
            text: q.text,
            required: q.required,
            visibleToMentors: q.visibleToMentors,
            id: q.id,
            type: SurveyQuestionType.Scale,
            scale: {
              leftLabel: q.leftLabel,
              rightLabel: q.rightLabel,
            },
          };
        default:
          return undefined;
      }
    })
    .filter((q) => !!q) as NonNullable<SurveyQuestionInput[]>;
