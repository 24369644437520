import { BoxColorDefinition } from '../../types';

// Fixed height for now. If this does not get displayed with the other variants we coul leave it flexible
export const Header = ({
  children,
  colorDef,
}: {
  children: React.ReactNode;
  colorDef: BoxColorDefinition;
}) => (
  <div
    style={{ backgroundImage: `url(${colorDef.backgroundImage})` }}
    className={`flex h-[150px] flex-col justify-between bg-cover bg-right bg-no-repeat p-4`}
  >
    {children}
  </div>
);
