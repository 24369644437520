import { Section, CourseModuleList } from '@stellar-lms-frontend/lms-components';
import { Course, CourseModuleOutline, CourseStats, Maybe } from '@stellar-lms-frontend/lms-graphql';

import { LoadingCard, PlusIcon, PrimaryButton } from '@stellar-lms-frontend/ui-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DESIGNER_JOURNEY_ROUTE } from '../../constants/routes';

// Calculate the module stats based on the module level and amount of learners.
const calculateModuleStats = (modules: CourseModuleOutline[], numberOfStudents?: Maybe<number>) => {
  return modules.map((module) => {
    const startedPercentage =
      ((module.stats?.learnersStarted ?? 0) / (numberOfStudents ?? 1)) * 100 || 0;
    const completedPercentage =
      ((module.stats?.learnersCompleted ?? 0) / (numberOfStudents ?? 1)) * 100 || 0;
    return { ...module, stats: { startedPercentage, completedPercentage } };
  });
};

export type JourneySectionProps = {
  onClickModule: (id: string) => void;
  courseDashboard: Course;
};

export const JourneySection: React.FC<JourneySectionProps> = ({
  courseDashboard,
  onClickModule,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.course' });
  const navigate = useNavigate();
  const { courseId } = useParams();

  const isLoadingModules =
    (courseDashboard.aiGenerated && !courseDashboard.aiGenerationDone) ?? false;

  // prepare the stats in the modules
  const courseModuleOverview = useMemo(() => {
    if (!courseDashboard?.outline?.modules) {
      return undefined;
    }
    return calculateModuleStats(
      courseDashboard.outline.modules,
      (courseDashboard.courseStats as CourseStats)?.numberOfStudents
    );
  }, [courseDashboard?.outline?.modules, courseDashboard?.courseStats]);

  if (!courseModuleOverview) {
    return null;
  }

  return (
    <Section title={t('title')}>
      {isLoadingModules && (
        <LoadingCard title={t('loading.title')}>
          <span className="font-lexend font-regular text-xs">{t('loading.description')}</span>
        </LoadingCard>
      )}
      {courseModuleOverview.length > 0 ? (
        <CourseModuleList
          modules={courseModuleOverview}
          moduleClick={onClickModule}
          loadingMoreModules={{
            loading: isLoadingModules,
            amount: courseDashboard.transferObjectives?.length ?? 0,
          }}
        />
      ) : (
        <div className="mt-2">
          <p className="text-text-01 mb-4 font-medium">{t('empty.headline')}</p>
          <p className="text-text-02 mb-6 text-sm">{t('empty.description')}</p>
          <PrimaryButton
            onClick={() =>
              navigate(DESIGNER_JOURNEY_ROUTE(courseId), { state: { isEditing: true } })
            }
            buttonStyle="small"
            leftIcon={<PlusIcon className="h-5 w-5 text-white" />}
            label={t('empty.cta')}
          />
        </div>
      )}
    </Section>
  );
};
