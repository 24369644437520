import { LearningActivityOutline, LearningActivityType } from '@stellar-lms-frontend/lms-graphql';
import { CardDuringDrag, DragSortableItem, PlusIcon } from '@stellar-lms-frontend/ui-components';
import { Dispatch, SetStateAction, useContext } from 'react';
import { LearningActivityCard } from './components';
import { ModuleListContext } from './module-list-context';
import { MoveLearningActivityFunction } from './components/sort';
import { DescriptionSectionI18N } from './components/description-section';
import { EditingStore } from './store';
import { convertToPlaceholderTitle } from './functions';

export type LearningActivityListProps = {
  learningActivities: LearningActivityOutline[];
  i18n: LearningActivityListI18N;
  isEditing: boolean;
  setIsEditing?: Dispatch<SetStateAction<boolean>>;
  actions?: LearningActivityActions;
  moduleId: string;
  moveUp: MoveLearningActivityFunction;
  moveDown: MoveLearningActivityFunction;
};

export type LearningActivityActions = {
  learningActivity: {
    create?: (
      moduleId: string,
      input: {
        title: string;
        description: string;
        mandatoryPrerequisiteLearningActivityIds: string[];
        durationInSeconds: number;
        type: LearningActivityType;
      }
    ) => void;
    update?: (
      moduleId: string,
      learningActivityId: string,
      input: {
        title: string;
        description: string;
        mandatoryPrerequisiteLearningActivityIds: string[];
        durationInSeconds: number;
        type?: LearningActivityType;
      }
    ) => void;
    duplicate?: (moduleId: string, learningActivityId: string) => void;
    delete?: (moduleId: string, learningActivityId: string) => void;
  };
};

export type LearningActivityListI18N = {
  activityAvailableWhenTooltip: (count: number, prerequisites: string) => string;
  activityCommentCount: (count: number) => string;
  activityAvailableOnTooltip: (date: string) => string;
  lockedActivityText: string;
  mins?: (count: number) => string;
  exampleDescription?: string;
  addNewLearningActivity?: string;
  duplicate?: string;
  emptyStateMentorLearnerMessage?: string;
  emptyStateDesignerMessage?: string;
  descriptionSection: DescriptionSectionI18N;
  learningActivityTypes?: {
    guide: {
      label: string;
      description: string;
    };
    experiment: {
      label: string;
      description: string;
    };
    apply: {
      label: string;
      description: string;
    };
    retain: {
      label: string;
      description: string;
    };
    miscellaneous: {
      label: string;
      description: string;
    };
  };
  aiBadgeLabel: string;
};

export const LearningActivityList = ({
  learningActivities = [],
  i18n,
  isEditing,
  setIsEditing,
  actions,
  moduleId,
  moveUp,
  moveDown,
}: LearningActivityListProps) => {
  const { userRole } = useContext(ModuleListContext);
  const [startEditingLearningActivity, startCreatingLearningActivityForModule] =
    EditingStore.useStore((state) => [
      state.startEditingLearningActivity,
      state.startCreatingLearningActivityForModule,
    ]);

  if (isEditing) {
    return (
      <ul className="text-text-02 font-lexend space-y-4">
        {learningActivities?.map((learningActivity) => (
          <DragSortableItem
            id={learningActivity.id}
            key={learningActivity.id}
          >
            {(props) =>
              props.dragActive ? (
                <CardDuringDrag
                  title={
                    learningActivity.placeholder
                      ? convertToPlaceholderTitle(learningActivity.title ?? undefined)
                      : learningActivity.title ?? ''
                  }
                  normalColor={learningActivity.placeholder ? 'purple' : 'default'}
                  darkerColor={learningActivity.placeholder ? 'dark-purple' : 'default'}
                  isDragged={props.dragActive.id === learningActivity.id}
                />
              ) : (
                <LearningActivityCard.Edit
                  key={learningActivity.id}
                  i18n={i18n}
                  onOpenClick={() => {
                    startEditingLearningActivity(learningActivity.id);
                  }}
                  onDuplicate={() =>
                    actions?.learningActivity.duplicate?.(moduleId, learningActivity.id)
                  }
                  onDelete={() => actions?.learningActivity.delete?.(moduleId, learningActivity.id)}
                  dragProps={props}
                  moveUp={() => moveUp(moduleId, learningActivity.id)}
                  moveDown={() => moveDown(moduleId, learningActivity.id)}
                  learningActivity={{
                    id: learningActivity.id,
                    aiGenerated: learningActivity.aiGenerated ?? false,
                    aiGenerationDone: learningActivity.aiGenerationDone ?? false,
                    placeholder: learningActivity.placeholder ?? false,
                    title: learningActivity.title ?? '',
                    description: learningActivity.description ?? '',
                    durationInSeconds: learningActivity.duration ?? 0,
                    steps:
                      learningActivity?.steps?.map((s) => ({
                        id: s.id,
                        title: s.title ?? '',
                      })) ?? [],
                    type: learningActivity.type ?? undefined,
                  }}
                />
              )
            }
          </DragSortableItem>
        ))}
        <LearningActivityCard.Create
          i18n={i18n}
          onSelect={(type) => {
            startCreatingLearningActivityForModule({ moduleId, type });
          }}
        />
      </ul>
    );
  } else {
    return (
      <ul className="font-lexend">
        {(learningActivities ?? []).length > 0 ? (
          learningActivities.map((learningActivity) => (
            <LearningActivityCard.Read
              key={learningActivity.id}
              i18n={i18n}
              learningActivity={learningActivity}
            />
          ))
        ) : (
          <>
            {(userRole === 'learner' || userRole === 'mentor') && (
              <p className="text-text-02">{i18n.emptyStateMentorLearnerMessage}</p>
            )}
            {userRole === 'designer' && (
              <div
                className="text-text-01 flex items-center gap-2 font-medium"
                role="button"
                onClick={() => {
                  setIsEditing?.(true);
                }}
              >
                <PlusIcon className="h-6 w-6" />
                <p className="underline">{i18n.emptyStateDesignerMessage}</p>
              </div>
            )}
          </>
        )}
      </ul>
    );
  }
};
