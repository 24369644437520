import { Table as TanStackTable } from '@tanstack/table-core';
import { PageSizeSelectorI18N } from '../../page-size-selector';
import { PageSelector } from '../../page-selector';
import { PageSizeSelector } from '../../page-size-selector';
import { DefaultRowDataType } from './table-rows';

type TableControlsI18N = {
  pageSizeSelector: PageSizeSelectorI18N;
};

type TableControlsProps<T extends DefaultRowDataType = DefaultRowDataType> = {
  table: TanStackTable<T>;
  i18n: TableControlsI18N;
  totalRows?: number;
};

export const TableControls = <T extends DefaultRowDataType = DefaultRowDataType>({
  table,
  i18n,
  totalRows,
}: TableControlsProps<T>) => (
  <div className="mt-6 flex flex-row items-center justify-between">
    <PageSizeSelector
      table={table}
      i18n={i18n.pageSizeSelector}
      totalRows={totalRows}
    />
    <PageSelector table={table} />
  </div>
);
