import { useQuery } from '@tanstack/react-query';
import {searchUsersLightById, USERS_LIGHT} from '@stellar-lms-frontend/lms-api';
import {useCurrentCompany} from "@stellar-lms-frontend/lms-graphql";
import {GraphQLClient} from "graphql-request";

type LearnerCellProps = {
  learnerId: string;
  graphQLClient: GraphQLClient;
};

export const LearnerCell: React.FC<LearnerCellProps> = ({ learnerId, graphQLClient }) => {
  const {
    query: { data: company },
  } = useCurrentCompany(graphQLClient);
  const query = useQuery([USERS_LIGHT, learnerId], () =>
    company ? searchUsersLightById(graphQLClient, company.id, [learnerId]) : []
  );

  if (!query.isSuccess) return null;

  return <div>{query.data.at(0)?.name ?? ''}</div>;
};
