import moment from 'moment';
import { useNavigate } from 'react-router-dom';

type NavLinkType = {
  title: string;
  path: string;
};
export type ActivityProps = {
  avatar: string | undefined;
  author: string;
  message: string;
  date: Date;
  type?: 'state';
  replies?: never;
  replyCTA?: never;
  link?: NavLinkType;
};

export const Activity: React.FC<ActivityProps> = ({ avatar, author, message, date, link }) => {
  const navigate = useNavigate();

  return (
    <div className="text-text-01 flex items-start justify-between text-sm">
      <div className="flex items-start">
        <div className="h-6 w-6 shrink-0">
          {avatar && (
            <img
              src={avatar}
              className="rounded-full"
              alt="user avatar"
            />
          )}
        </div>

        <div
          className="ml-4 flex flex-col"
          data-cy="activity-text"
        >
          <p className="lowercase">
            <span className="font-semibold capitalize">{author}</span> {message}.
          </p>
          {link && (
            <div
              role="button"
              data-cy="activity-item-link"
              onClick={() => navigate(link.path)}
              className="text-primary-01 cursor-pointer"
            >
              {link.title} &gt;
            </div>
          )}
        </div>
      </div>
      <p className="text-text-03">{moment(moment.now()).diff(date, 'days')}d</p>
    </div>
  );
};
