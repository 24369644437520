import {
  ButtonConfig,
  ChangeState,
  VoidFunc,
  navigation,
} from '@stellar-lms-frontend/common-utils';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { LearnerUIShellHeader } from './learner-ui-shell-header';
import { EditingHeader } from '@stellar-lms-frontend/ui-components';
import { HeaderRightElements } from './store';

type InitialHeaderElements = { id: string; element: ReactNode }[];

export type LearnerUIShellProps = {
  children?: ReactNode;
} & (
  | {
      headerTheme?: 'view';
      backButton?: never;
      title?: never;
      initialHeaderRightElements?: InitialHeaderElements;
    }
  | {
      headerTheme: 'edit';
      backButton: {
        label: string;
        onClick: VoidFunc;
      };
      title: ReactNode;
      initialHeaderRightElements: InitialHeaderElements;
    }
);

export const LearnerUIShell = ({
  children,
  headerTheme = 'view',
  initialHeaderRightElements,
  title,
  backButton,
}: LearnerUIShellProps) => {
  const [rightPrimary, setRightPrimary] = useState<ButtonConfig>();
  const [leftPrimary, setLeftPrimary] = useState<ButtonConfig>();
  const [rightSecondary, setRightSecondary] = useState<ButtonConfig>();
  const [discussionButton, setDiscussionButton] = useState<ButtonConfig>();
  const [localTitle, setLocalTitle] = useState<string>('');

  const changeButtons = useCallback((changeState: ChangeState) => {
    setLeftPrimary(changeState.leftPrimary);
    setRightPrimary(changeState.rightPrimary);
    setRightSecondary(changeState.rightSecondary);
    setDiscussionButton(changeState.discussion);
    setLocalTitle(changeState.title ?? '');
  }, []);

  const [headerRightElements, setHeaderRightElement, removeHeaderRightElement] =
    HeaderRightElements.useStore((state) => [
      state.getElementsAsArray(),
      state.setElement,
      state.removeElement,
    ]);

  useEffect(() => {
    initialHeaderRightElements?.forEach(({ id, element }) => {
      setHeaderRightElement(id, element);
    });

    return () => {
      initialHeaderRightElements?.forEach(({ id }) => {
        removeHeaderRightElement(id);
      });
    };
  }, [initialHeaderRightElements, setHeaderRightElement, removeHeaderRightElement]);

  const unsubscribe = useMemo(() => {
    changeButtons(navigation.getChangeState());
    return navigation.subscribe(changeButtons);
  }, [changeButtons]);

  useEffect(() => {
    return () => {
      unsubscribe();
    };
  }, [unsubscribe]);

  useEffect(() => {
    const reactNativeWebView = window.ReactNativeWebView;
    if (reactNativeWebView) {
      reactNativeWebView.postMessage(
        JSON.stringify({
          header_background: '#FFFFFF',
          header_style: 'dark',
          footer_background: '#FFFFFF',
        })
      );
    }
  }, []);

  return (
    <div className="flex h-full flex-col items-center">
      {headerTheme === 'view' && (
        <LearnerUIShellHeader
          className="shrink-0"
          title={localTitle}
          leftPrimary={leftPrimary}
          rightSecondary={rightSecondary}
          rightPrimary={rightPrimary}
          discussionButton={discussionButton}
          rightElements={headerRightElements}
        />
      )}
      {headerTheme === 'edit' && (
        <EditingHeader
          className="shrink-0 grow-0"
          title={title}
          rightElements={headerRightElements}
          backButton={backButton}
        />
      )}
      <div className="w-full grow overflow-hidden">{children ? children : <Outlet></Outlet>}</div>
    </div>
  );
};

export default LearnerUIShell;
