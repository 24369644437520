import { useNavigate } from 'react-router-dom';

export const isMatch = (path: string, type: 'contains' | 'exact' = 'exact') => {
  switch (type) {
    case 'exact':
      return window.location.pathname === path;
    case 'contains':
      return window.location.pathname.includes(path);
    default:
      return window.location.pathname === path;
  }
};

// Temporary solution to navigate between the learner and designer app (outside of react router)
// we're checking if the start of the path matches the current path to avoid resetting the react router
// ONLY use this for navigation between our own apps.
export const useNavigateExternal = () => {
  const navigate = useNavigate();

  const navigateLearner = (path: string) => {
    // if (!window.location.pathname.startsWith('/designer/')) {
    //   // we can stay within our application context
    navigate(path);
    // } else {
    //   window.location.href = path;
    // }
  };

  const navigateExternal = (path: string) => {
    // if (path.startsWith('/designer/')) {
    //   navigateDesigner(path);
    // } else {
    navigateLearner(path);
    // }
  };

  return { navigateExternal };
};
