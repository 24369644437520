import {
  ScrollContainer,
  StepCounter,
  Heading3,
  Heading4,
  ContentWrapper,
} from '@stellar-lms-frontend/ui-components';

import { QuestionViewChildrenProps } from './question-view';
import { useTranslation } from 'react-i18next';

export type QuestionFullScreenProps = QuestionViewChildrenProps;

export const QuestionFullScreen: React.FC<QuestionFullScreenProps> = ({
  showQuestionNumbers,
  number,
  total,
  questionTitle,
  questionRendered,
  answerEvaluation,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assessment-step-view' });

  return (
    <div className="flex h-full w-full flex-col items-center justify-between">
      <ScrollContainer scrollOnDesktop>
        <ContentWrapper className="mx-auto pb-6 pt-4">
          <div>
            {showQuestionNumbers && (
              <StepCounter
                className="text-text-03  text-sm"
                curr={number}
                total={total}
              />
            )}
            <Heading3 className="text-text-04 hidden md:inline">{questionTitle}</Heading3>
            <Heading4 className="text-text-04 md:hidden">{questionTitle}</Heading4>
          </div>
          {questionRendered}
        </ContentWrapper>
      </ScrollContainer>

      {answerEvaluation && answerEvaluation.feedback && (
        <div className="shadow-feedback flex w-full shrink-0 justify-center">
          <div className="max-w-content w-full space-y-1 p-8">
            {answerEvaluation.earnedPoints === answerEvaluation.totalPoints ? (
              <Heading4 className="text-positive-01">{t('correct')}</Heading4>
            ) : (
              <Heading4 className="text-negative-01">{t('incorrect')}</Heading4>
            )}
            <p className="text-text-02 whitespace-pre-wrap">{answerEvaluation.feedback}</p>
          </div>
        </div>
      )}
    </div>
  );
};
