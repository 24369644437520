import { isMatch, useNavigateExternal } from '@stellar-lms-frontend/common-utils';
import {
  HeaderNavTheme,
  NavButton,
  PresentationChartFilledIcon,
  PresentationChartIcon,
} from '@stellar-lms-frontend/ui-components';

export const OverviewNavButton = ({
  theme,
  url,
  title,
}: {
  theme: HeaderNavTheme;
  url: string;
  title: string;
}) => {
  const { navigateExternal } = useNavigateExternal();

  return (
    <NavButton
      theme={theme}
      navEntry={{
        title: title,
        filledIcon: [PresentationChartFilledIcon],
        icon: [PresentationChartIcon],
        action: () => navigateExternal(url),
        isActive: isMatch(url),
      }}
      type={'icon-text'}
    />
  );
};
