import { SurveyOverview } from '@stellar-lms-frontend/lms-api';
import { FlexTable, FlexTableI18N } from '@stellar-lms-frontend/ui-components';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  Row,
} from '@tanstack/react-table';
import { useMemo } from 'react';

const columnHelper = createColumnHelper<SurveyOverview>();

type SurveyOverviewViewI18N = {
  table: FlexTableI18N;
  columns: {
    module: string;
    title: string;
    questions: string;
    answeredQuestions: string;
  };
};

type SurveyOverviewViewProps = {
  data: SurveyOverview[];
  surveySelected: (surveyId: string) => void;
  i18n: SurveyOverviewViewI18N;
};

export const SurveyOverviewView: React.FC<SurveyOverviewViewProps> = ({
  data,
  surveySelected,
  i18n,
}) => {
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.moduleTitle, {
        id: 'moduleTitle',
        header: i18n.columns.module,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row.title, {
        id: 'title',
        header: i18n.columns.title,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row.nbQuestions, {
        id: 'nbQuestions',
        header: i18n.columns.questions,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row.nbAnsweredQuestions, {
        id: 'nbAnsweredQuestions',
        header: i18n.columns.answeredQuestions,
        cell: (info) => info.getValue(),
      }),
    ],
    [i18n.columns]
  );

  const table = useReactTable({
    data,
    columns,
    enableColumnResizing: false,
    initialState: { sorting: [{ id: 'title', desc: false }], pagination: { pageSize: 20 } },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const onRowClick = (row: Row<SurveyOverview>) => {
    surveySelected(row.original.surveyId);
  };

  return (
    <FlexTable
      i18n={i18n.table}
      table={table}
      rowClick={onRowClick}
    />
  );
};
