import React, { useState } from 'react';
import { FloatingProps, Floating } from '../floating/floating';
import { CrossIcon } from '../../icons';

export type ToolTipProps = Omit<FloatingProps, 'isOpen'> & {
  color?: 'dark' | 'unstyled';
  size?: 'large' | 'small' | 'unstyled'; // CLEANUP rename to padding or paddingSize
  isEnabled: boolean;
  enableClick?: boolean;
  childrenClassName?: string;
  isOpen?: boolean;
  onClose?: () => void;
};

export const ToolTip: React.FC<ToolTipProps> = ({
  children,
  color = 'unstyled',
  size = 'unstyled',
  isEnabled,
  isOpen = false,
  enableClick = false,
  wrappedComponent,
  childrenClassName = '',
  onClose,
  ...rest
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isNear, setIsNear] = useState(false);
  const [isOpened, setIsOpened] = useState(isOpen);

  const sizeClassName = {
    large: 'p-6',
    small: 'py-3 px-2',
    unstyled: '',
  };

  const colorClassMap = {
    dark: 'bg-inverse-bg text-on-inverse-01',
    unstyled: '',
  };

  const toggleClick = () => {
    if (isOpened || isHovering) {
      onClose?.();
      setIsOpened(false);
      setIsHovering(false);
    } else {
      setIsOpened(true);
    }
  };

  return (
    <Floating
      {...rest}
      isNearWrapperCallback={setIsNear}
      wrappedComponent={
        <div
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={() => enableClick && toggleClick()}
          className={`${enableClick ? 'cursor-pointer' : ''}`}
          onKeyDown={() => enableClick && toggleClick()}
        >
          {wrappedComponent}
        </div>
      }
      isOpen={(isNear || isHovering || isOpened) && isEnabled}
    >
      <div
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        className={`${colorClassMap[color]} ${sizeClassName[size]} ${childrenClassName} font-lexend
        flex items-start space-x-4 whitespace-nowrap rounded-md text-sm font-medium`}
      >
        {children}
        {enableClick && (
          <div>
            <button
              className="-mr-2 -mt-2"
              onClick={(e) => {
                e.stopPropagation();
                toggleClick();
              }}
            >
              <CrossIcon />
            </button>
          </div>
        )}
      </div>
    </Floating>
  );
};
