import { Heading3, Heading4 } from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { GEARModel } from './images';

export const Methodology: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'gear-template-modal.methodology' });

  return (
    <div className="text-text-02 space-y-6">
      <Heading3 className="text-text-04">{t('heading')}</Heading3>
      <p className="font-semibold">{t('intro')}</p>
      <p>{t('designing-learning')}</p>
      <img
        src={GEARModel}
        alt="GEAR (Guide - Experiment - Apply - Retain) Model illustration"
        className="w-full"
      />
      <p>{t('gear-intro')}</p>
      <ul className="ml-6 space-y-2">
        <li className="list-disc">{t('guide-description')}</li>
        <li className="list-disc">{t('experiment-description')}</li>
        <li className="list-disc">{t('apply-description')}</li>
        <li className="list-disc">{t('retain-description')}</li>
      </ul>
      <Heading4 className="text-text-04">{t('methodology-section-heading')}</Heading4>
      <p>{t('learning-process')}</p>
      <p>{t('learning-new-skills')}</p>
      <p>{t('brain-connection')}</p>
      <p>{t('designer-role')}</p>
      <p>
        <a
          target="_blank"
          rel="noreferrer"
          className=" underline"
          href="https://www.stellarlabs.io/training-programmes/neuroscience-for-learning-transfer"
        >
          {t('learn-more-link-text')}
        </a>
      </p>
    </div>
  );
};
