import { LeftArrowIcon } from '@stellar-lms-frontend/ui-components';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { graphQLClient } from '../../lib/graphql';
import { useCurrentUser } from '@stellar-lms-frontend/lms-graphql';
import { UIShell } from '../shell/ui-shell';
import { AdministrationPage } from '@stellar-lms-frontend/lms-components';
import { hasPermission } from '@stellar-lms-frontend/common-utils';
import { REDIRECT_HOME_URI } from '../../constants/routes';
import {
  DEFAULT_LANGUAGE,
  supportedLanguages,
  upperSupportedLanguage,
} from '../../constants/languages';

export const AdministrationOverview = () => {
  const client = graphQLClient();
  const { t } = useTranslation('translation', { keyPrefix: 'administration' });
  const { t: tLanguages } = useTranslation('translation', { keyPrefix: 'languages' });
  const navigate = useNavigate();

  const {
    query: { data: user },
  } = useCurrentUser(graphQLClient('learner'));

  if (!hasPermission(user?.permissions, 'administration')) {
    window.location.href = REDIRECT_HOME_URI;
  }

  return (
    <UIShell
      subNavTitle={t('title')}
      scrollOnDesktop={true}
      leftButton={{
        action: () => navigate(-1),
        leftIcon: <LeftArrowIcon className="fill-on-primary-01 h-5 w-5" />,
      }}
      useContentContainer={false}
    >
      <AdministrationPage
        supportedLanguages={supportedLanguages.map<{
          label: string;
          value: string;
        }>((l) => ({
          label: tLanguages(upperSupportedLanguage(l)),
          value: l.toLowerCase(),
        }))}
        defaultLanguage={DEFAULT_LANGUAGE.toLowerCase()}
        graphQLClient={client}
      />
    </UIShell>
  );
};
