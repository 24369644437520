import { TableControls } from './components/table-controls';
import { TableHeader } from './components/table-header';
import { DefaultRowDataType, TableRows } from './components/table-rows';
import { PageSizeSelectorI18N } from '../page-size-selector';
import { Table as TanStackTable, Row } from '@tanstack/table-core';
import { DataCy } from '@stellar-lms-frontend/common-utils';
import { HorizontalScrollFadeWrapper } from '../../horizontal-scroll-fade-wrapper/horizontal-scroll-fade-wrapper';
import { useRef, useState } from 'react';

export type FlexTableI18N = {
  pageSizeSelector: PageSizeSelectorI18N;
};

type FlexTableProps<T = DefaultRowDataType> = DataCy & {
  i18n: FlexTableI18N;
  table: TanStackTable<T>;
  rowClick?: (row: Row<T>, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
  totalRows?: number;
};

export const FlexTable = <T extends DefaultRowDataType = DefaultRowDataType>({
  table,
  rowClick,
  'data-cy': dataCy,
  i18n,
  className = '',
  totalRows,
}: FlexTableProps<T>) => {
  const [scrollViewElement, setScrollViewElement] = useState<Element | null>(null);
  const scrollViewRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      className={`${className} w-full px-4`}
      data-cy={dataCy}
    >
      <HorizontalScrollFadeWrapper horizontalScrollViewElement={scrollViewElement}>
        <div
          ref={(node) => {
            if (node !== scrollViewElement) {
              setScrollViewElement(node);
              scrollViewRef.current = node;
            }
          }}
          className="-ml-4 w-[calc(100%+32px)] overflow-x-scroll"
        >
          <div className="w-full min-w-[768px]">
            <div
              role="table"
              className="font-lexend w-full border-collapse border-spacing-0"
            >
              <TableHeader
                headerGroups={table.getHeaderGroups()}
                data-cy={dataCy}
              />
              <TableRows
                rows={table.getRowModel().rows}
                rowClick={rowClick}
                data-cy={dataCy}
              />
            </div>
          </div>
        </div>
      </HorizontalScrollFadeWrapper>
      <TableControls
        table={table}
        i18n={i18n}
        totalRows={totalRows}
      />
    </div>
  );
};
