import { Stripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import {
  createCheckoutSession,
  completeCheckoutSession,
  transferToInputLanguage,
  useCompanyStore,
  useCurrentUser,
} from '@stellar-lms-frontend/lms-graphql';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { VoidFunc, defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';

export const StripeCheckout = ({
  stripePromise,
  onComplete,
}: {
  stripePromise: Promise<Stripe | null>;
  onComplete: VoidFunc;
}) => {
  const companyId = useCompanyStore((state) => state.companyId);
  const {
    query: { data: currentUser },
  } = useCurrentUser(defaultGraphqlClient);

  const startCheckoutSession = useMutation({
    mutationFn: (companyId: string) =>
      createCheckoutSession(
        companyId,
        transferToInputLanguage(currentUser?.language ?? undefined)
      ).then((res) => res.createStripeCheckoutSession),
  });

  const completeCheckoutSessionMutation = useMutation({
    mutationFn: ({ companyId, sessionId }: { companyId: string; sessionId: string }) =>
      completeCheckoutSession(companyId, sessionId).then(
        (res) => res.completeStripeCheckoutSession
      ),
  });

  useEffect(() => {
    if (companyId) {
      startCheckoutSession.mutate(companyId);
    }

    // We don't want to re-run this effect if the mutation changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  if (!companyId || !startCheckoutSession.data?.clientSecret) return null;

  const options = {
    clientSecret: startCheckoutSession.data.clientSecret,
    onComplete: async () => {
      completeCheckoutSessionMutation.mutateAsync({
        companyId: companyId,
        sessionId: startCheckoutSession.data.sessionId,
      });
      onComplete();
    },
  };

  return (
    <EmbeddedCheckoutProvider
      stripe={stripePromise}
      options={options}
    >
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
};
