import { InputLanguage, Language, SuggestionGenerationStatus } from '../graphql';

export enum SuggestionsStatus {
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
  NONE = 'NONE',
  ERROR = 'ERROR',
}

export const getSuggestionGenerationStatus = (pages: { status: SuggestionGenerationStatus }[]) => {
  let status: SuggestionsStatus = SuggestionsStatus.NONE;
  let isDone = true;

  for (const p of pages) {
    if (p?.status === SuggestionGenerationStatus.InProgress) {
      status = SuggestionsStatus.IN_PROGRESS;
      break;
    }
    if (p?.status === SuggestionGenerationStatus.Error) {
      status = SuggestionsStatus.ERROR;
      break;
    }
    if (p?.status !== SuggestionGenerationStatus.Done) {
      isDone = false;
    }
  }

  if (status === SuggestionsStatus.NONE && isDone) {
    status = SuggestionsStatus.DONE;
  }

  return status;
};

export const mapToInputLanguage = (
  language: Language | undefined | null
): InputLanguage | undefined => {
  if (!language) {
    return undefined;
  }

  switch (language) {
    case Language.De:
      return InputLanguage.De;
    case Language.En:
      return InputLanguage.En;
    case Language.Es:
      return InputLanguage.Es;
    case Language.Fr:
      return InputLanguage.Fr;
    case Language.It:
      return InputLanguage.It;
    case Language.Nl:
      return InputLanguage.Nl;
    case Language.Pt:
      return InputLanguage.Pt;
    default:
      return InputLanguage.En;
  }
};

export const transferToInputLanguage = (language?: string): InputLanguage => {
  switch (language) {
    case 'de':
      return InputLanguage.De;
    case 'en':
      return InputLanguage.En;
    case 'es':
      return InputLanguage.Es;
    case 'fr':
      return InputLanguage.Fr;
    case 'it':
      return InputLanguage.It;
    case 'nl':
      return InputLanguage.Nl;
    case 'pt':
      return InputLanguage.Pt;
    default:
      return InputLanguage.En;
  }
};
