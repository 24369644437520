import { ReactNode } from 'react';
import { SpinnerLoader } from '../../loaders';
import { BaseCard } from '../base-card/base-card';

export type LoadingCardProps = {
  className?: string;
  childrenClassName?: string;
  title: ReactNode;
  children?: ReactNode;
  hasShadow?: boolean;
  color?: 'dark-purple' | 'info-blue';
};

export const LoadingCard: React.FC<LoadingCardProps> = ({
  className = '',
  childrenClassName = '',
  title,
  children,
  hasShadow = false,
  color = 'info-blue',
}) => {
  return (
    <BaseCard
      hasShadow={hasShadow}
      className={className}
      childrenClassName={`${childrenClassName} font-lexend grid grid-cols-[48px_1fr] items-center gap-1 py-4 pl-2 pr-4`}
      color={color}
    >
      <SpinnerLoader
        className="text-[4px]"
        color={color}
      />
      <p className="font-medium">{title}</p>
      {children && <div className="col-start-2">{children}</div>}
    </BaseCard>
  );
};
