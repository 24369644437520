import { ActionsIllustration, EmptyState, LogoLoader } from '@stellar-lms-frontend/ui-components';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { WorkBasedActionsTable } from './work-based-actions-table';
import { useTranslation } from 'react-i18next';
import { COURSE_ACTIONS } from '../constants/query-constants';
import { UIShell } from '../../shell/ui-shell';
import {getAllLearnerWorkBasedActionsForCourse} from "./work-based-action.api";
import {graphQLClient} from "../../../lib/graphql";

export const WorkBasedActionCourseListView = () => {
  const { courseId } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: 'course-action-list.empty-state' });

  const { data: actionsData } = useQuery(
    [COURSE_ACTIONS, courseId],
    () => (courseId ? getAllLearnerWorkBasedActionsForCourse(graphQLClient(), courseId) : null),
    {
      enabled: !!courseId,
    }
  );

  // This helps avoiding typescript complaints about it being possibly undefined
  if (!courseId) return null;

  return (
    <UIShell>
      {actionsData ? (
        actionsData.length === 0 ? (
          <EmptyState
            className="mt-16"
            icon={<ActionsIllustration />}
            title={t('title')}
            description={t('description')}
            verticalCenter
          />
        ) : (
          <WorkBasedActionsTable
            courseId={courseId}
            learnerActions={actionsData}
          />
        )
      ) : (
        <LogoLoader />
      )}
    </UIShell>
  );
};
