import { Permission } from '../types';

export const hasPermission = (
  permissions: string[] | undefined | null,
  permission: Permission
): boolean => {
  return permissions?.includes(permission) ?? false;
};

export const hasAnyPermission = (
  permissions: string[] | undefined | null,
  any: Permission[]
): boolean => {
  return permissions?.some((permission) => (any as string[]).includes(permission)) ?? false;
};
