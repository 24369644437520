import { useEffect, useRef } from 'react';

type LinkInlineToolInputProps = {
  value: string;
  onEnter: (value: string) => void;
  placeholder: string;
  className: string;
  onChange: (value: string) => void;
  isVisible: boolean;
};

export const LinkInlineToolInputComponent: React.FC<LinkInlineToolInputProps> = ({
  value,
  onEnter,
  placeholder,
  className,
  onChange,
  isVisible,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      onEnter(value);
    }
  };

  useEffect(() => {
    isVisible && ref.current?.focus();
  }, [isVisible]);

  return (
    <input
      ref={ref}
      autoFocus
      defaultValue={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      className={className}
    />
  );
};
