import { GraphQLClient } from 'graphql-request';
import {
  PersistedLearnerWorkBasedActionWithUser,
  UserActions,
} from '@stellar-lms-frontend/lms-api';
import { getFragmentData, graphql } from '@stellar-lms-frontend/lms-graphql';
import {
  PersistedLearnerWorkBasedActionFragment,
  mapPersistedWBA,
} from '../designer/work-based-action/work-based-action.api';

export const getLearnerWorkBasedActions = (
  client: GraphQLClient
): Promise<PersistedLearnerWorkBasedActionWithUser[]> => {
  return client
    .request(
      graphql(`
        query GetActions {
          actions {
            ...PersistedLearnerWorkBasedActionFragment
          }
        }
      `)
    )
    .then((data) => {
      return data.actions.map((wba) =>
        mapPersistedWBA(getFragmentData(PersistedLearnerWorkBasedActionFragment, wba))
      );
    });
};

export const getMenteeActions = (
  client: GraphQLClient,
  courseId?: string
): Promise<UserActions[]> => {
  return client
    .request(
      graphql(`
        query MenteeActions($courseId: String) {
          menteeActions(courseId: $courseId) {
            user {
              id
              name
              thumbUri
            }
            actions {
              ...PersistedLearnerWorkBasedActionFragment
            }
          }
        }
      `),
      {
        courseId,
      }
    )
    .then((data) => {
      return data.menteeActions.map((summary) => {
        return {
          userId: summary.user?.id,
          actions:
            summary.actions?.map((action) =>
              mapPersistedWBA(getFragmentData(PersistedLearnerWorkBasedActionFragment, action))
            ) ?? [],
        } as UserActions;
      });
    });
};
