import { useTranslation } from 'react-i18next';
import { DropdownSelect } from '../form';
import { SortOption } from './types';
import { CSSProperties } from 'react';

/**
 *
 * Simple sort option dropdown:
 *
 * No support for:
 * - more than 1 sort direction (except through different sort options)
 * - a 'none' selection
 */
export const SortOptionSelector = <TDataItem,>({
  sortOptions,
  selectedOption,
  onSortChange,
}: {
  sortOptions: SortOption<TDataItem>[];
  selectedOption?: SortOption<TDataItem>;
  onSortChange: (option?: SortOption<TDataItem>) => void;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.sort-option-selector' });
  const width = sortOptions.reduce((acc, curr) => Math.max(acc, curr.label.length), 0);
  const options = sortOptions.map((option) => ({
    id: option.label,
    label: option.label,
    value: option,
  }));

  return options.length > 0 ? (
    <div
      className="flex items-center gap-2"
      style={{ '--option-select-width': width + 'ch' } as CSSProperties}
    >
      <span className="text-text-02 type-body-medium">{t('sort-by')}</span>
      <DropdownSelect
        optionClassName="!w-[260px]"
        selectorClassName={`w-[var(--option-select-width)]`}
        selected={
          selectedOption
            ? {
                id: selectedOption.label,
                label: selectedOption.label,
                value: selectedOption,
              }
            : { id: 'none', label: '', value: undefined }
        }
        onChange={(v) => onSortChange(v.value)}
        options={options}
      />
    </div>
  ) : null;
};
