import { Badge, CopyIcon, Edit3Icon, TrashIcon } from '@stellar-lms-frontend/ui-components';
import * as CourseBox from './course-box';
import { Actions, BoxColor } from '../types';
import { createDesignerCourseHref, getColorDefinitions } from '../functions';
import { secondsAsHours } from '@stellar-lms-frontend/common-utils';
import { useTranslation } from 'react-i18next';
import { Types } from '@stellar-lms-frontend/lms-graphql';

type DesignerCourseBlockProps = {
  color: BoxColor;
  course: Types.DesignerCourse;
  actions: Actions;
};
export const DesignerCourseBlock = ({ course, color, actions }: DesignerCourseBlockProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard-box' });
  const { t: tRoles } = useTranslation('translation', { keyPrefix: 'roles' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const statList = [
    {
      label: t('completion'),
      value: `${Math.round(course.stats.averageCompletionRate * 100) / 100}%`,
    },
    {
      label: t('spent'),
      value: `${secondsAsHours(course.stats.averageTimeSpentInSeconds)}h`,
    },
    {
      label: t('signed-up'),
      value: `${course.stats.numberOfActiveStudents} ${t('of')} ${course.stats.numberOfStudents}`,
    },
    { label: course.language },
  ];

  // Might be very interesting to do something with css variables instead
  const colorDef = getColorDefinitions(color);

  return (
    <CourseBox.Container
      onClick={() => (window.location.href = createDesignerCourseHref(course.id))}
    >
      <CourseBox.Header colorDef={colorDef}>
        <div className="mb-4 flex justify-between">
          <Badge className={`max-w-fit ${colorDef.progressBGColorClassName}`}>
            {tRoles('designer')}
          </Badge>
          <CourseBox.OptionMenu
            actions={[
              {
                key: `edit-option`,
                click: () => actions.onEdit(course.id),
                label: t('edit-details'),
                leftIcon: <Edit3Icon className="h-5 w-5" />,
              },
              {
                key: `duplicate-option`,
                click: () => actions.onDuplicate(course.id),
                label: tGeneral('duplicate'),
                leftIcon: <CopyIcon className="h-5 w-5" />,
              },
              {
                key: `delete-option`,
                click: () => actions.onDelete(course.id),
                label: tGeneral('delete'),
                colorClassName: 'text-negative-01',
                leftIcon: <TrashIcon className="text-negative-01 h-5 w-5" />,
              },
            ]}
          />
        </div>
        <CourseBox.Title
          title={course.title}
          colorDef={colorDef}
        />
      </CourseBox.Header>
      <CourseBox.Content items={statList} />
    </CourseBox.Container>
  );
};
