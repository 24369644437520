import { ReactNode, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '../../icons';

export type AccordionProps = {
  trigger: {
    openText: ReactNode;
    closedText: ReactNode;
    className: string;
  };
  children: ReactNode;
  isEnabled: boolean;
};

export const Accordion: React.FC<AccordionProps> = ({ trigger, children, isEnabled }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isEnabled && (
        <button
          onClick={() => setIsOpen((isOpen) => !isOpen)}
          className={`flex items-center gap-2 ${trigger.className} ${isOpen && 'mb-2'}`}
        >
          {isOpen ? (
            <>
              <p>{trigger.openText}</p>
              <ChevronUpIcon />
            </>
          ) : (
            <>
              <p>{trigger.closedText}</p>
              <ChevronDownIcon />
            </>
          )}
        </button>
      )}
      {(!isEnabled || isOpen) && <div>{children}</div>}
    </>
  );
};
