import React, { useState, useCallback } from 'react';
import { Input } from './input';
import {
  FloatingOptions,
  FloatingOptionsOption,
} from '../../wrappers/floating-box/floating-options';
import Fuse from 'fuse.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';

export interface Suggestion {
  label: string;
}
/**
 * Some definitions:
 *
 * - partial match: a match that is found by fuzzy matching rules, but not an exact superset of the search value.
 * - full match: either an exact 'equals' match between the search value and a suggestion label or at least the full search value should be part of the suggestion label.
 */
export interface InputAutoCompleteProps<T extends Suggestion> {
  suggestions: T[];
  defaultSuggestion?: T;
  onSuggestionSelected: (suggestion: T) => void;
  renderOption: (suggestion: T) => FloatingOptionsOption;
  onChangeInput?: (value: string) => void;
  error?: string;
  label?: string;
  labelClassName?: string;
  emptyLabel?: string;
  emptyOption?: FloatingOptionsOption; // not great (and we should type check that emptyLabel and emptyOption are not both present), takes too much time cleaning up how these dropdowns work
  addButton?: FloatingOptionsOption;
  placeholder?: string;
  required?: boolean;
  forcedValue?: Suggestion;
  // Turn on/off fuzzy matching
  fuzzyMatch?: boolean;
  // If you want to show the add button when there are only partial matches
  showAddButtonWhenNoFullMatch?: boolean;
  // If you want to show the empty message when there are only partial matches
  showEmptyWhenNoFullMatch?: boolean;
}

type FuseResult<T extends Suggestion> = {
  item: T;
  score: number;
};

export const InputAutoComplete = <T extends Suggestion>({
  suggestions,
  defaultSuggestion,
  onSuggestionSelected,
  renderOption,
  onChangeInput,
  error,
  label,
  labelClassName,
  emptyLabel,
  emptyOption,
  addButton,
  placeholder,
  required,
  forcedValue,
  fuzzyMatch = true,
  showAddButtonWhenNoFullMatch = false,
  showEmptyWhenNoFullMatch = false,
}: InputAutoCompleteProps<T>) => {
  const [inputValue, setInputValue] = useState(defaultSuggestion ? defaultSuggestion.label : '');
  const [filteredSuggestions, setFilteredSuggestions] = useState<FuseResult<T>[]>(
    suggestions.map((suggestion) => ({ item: suggestion, score: 1.0 }))
  );
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleSuggestionClick = useCallback(
    (suggestion: T) => {
      setInputValue(suggestion.label);
      setShowSuggestions(false);
      onSuggestionSelected(suggestion);
    },
    [onSuggestionSelected]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);

    const fuseOptions: { keys: string[]; includeScore: boolean; threshold?: number } = {
      keys: ['label'],
      includeScore: true,
    };
    if (!fuzzyMatch) {
      // We can probably just usa a strategy and not use fusejs at all.
      fuseOptions.threshold = 0.0;
    }

    const fuse = new Fuse(suggestions, fuseOptions);

    const result = fuse.search(e.target.value);
    setFilteredSuggestions(
      result.map(({ item, score }) => ({
        item,
        score: score ?? 1.0,
      }))
    );
    setShowSuggestions(e.target.value.length > 0);
    onChangeInput?.(e.target.value);
  };

  /** According to the fuse.js docs a full match would return 0 but after reading many issue reports that never got resolved this does not seem to be the case.
   * Seems that full matches return some EPSILON value. Based on limited testing it seems that we can assume a full match if the score is less or equal to 8.569061098350962e-10.
   *(Note that it seems, on my computer, that the result of a full match is 8.569061098350962e-12, so 8.569061098350962e-10 has a bit of margin)
   *
   * Also note that we also check for a partial substring match, as the 'full' match of fuse.js expects the full. This even makes the score check redundant, it is a bit more performant so keeping it for now.
   */

  const hasExactMatch = filteredSuggestions.some(
    (suggestion) => suggestion.score <= 0.00000001 || suggestion.item.label.includes(inputValue)
  );

  const options: FloatingOptionsOption[] = filteredSuggestions.map((s) => {
    const res = renderOption(s.item);

    return {
      ...res,
      onClick: (e) => {
        res.onClick?.(e);
        handleSuggestionClick(s.item);
      },
    };
  });

  if (options.length === 0 || (showEmptyWhenNoFullMatch && !hasExactMatch)) {
    options.push(
      emptyOption ?? {
        label: <span className="text-text-02 type-body">{emptyLabel}</span>,
        left: (
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className={'text-text-01 h-4 w-4'}
          />
        ),
      }
    );
  }

  if (addButton && ((showAddButtonWhenNoFullMatch && !hasExactMatch) || options.length === 0)) {
    options.push({
      type: 'divider',
      className: 'mx-6',
    });
    options.push(addButton);
  }

  return (
    <FloatingOptions
      options={options}
      isOpen={showSuggestions}
      onClose={() => setShowSuggestions(false)}
      matchWidth
      wrappedComponent={
        <Input
          required={required}
          htmlId="autocomplete-input"
          onChange={handleInputChange}
          text={forcedValue ? forcedValue.label : inputValue}
          error={error}
          label={label}
          labelClassName={labelClassName}
          placeholder={placeholder}
        />
      }
      widthClassName="w-full"
    />
  );
};
