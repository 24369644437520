import {
  BrainLight,
  LogoLoader,
  Heading4,
  PrimaryButton,
  TertiaryButton,
} from '@stellar-lms-frontend/ui-components';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { SuccessStatePage } from './success-state-page';
import { useFormContext } from 'react-hook-form';
import { TypeMap } from '../../types';
import { SRQStats, Stats } from './components/srq-stats';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SPACED_REPETITION_OVERVIEW } from '../../constants/query';
import { TopBar } from './components/top-bar';
import { getLoggedInUser, Question, SurveyAnswerMap, USER } from '@stellar-lms-frontend/lms-api';
import { navigation } from '@stellar-lms-frontend/common-utils';
import { Question as QuestionComponents } from '@stellar-lms-frontend/lms-components';
import { QuestionCard } from './question-card';
import {
  evaluateSpacedRepetitionQuestion,
  getSpacedRepetitionOverview,
} from './spaced-repetition.api';
import { graphQLClient } from '../../lib/graphql';
import { useCurrentCompany } from '@stellar-lms-frontend/lms-graphql';

export type SpacedRepetitionWizardProps = {
  questions: Question[];
};

type ViewState = 'cta' | 'questions' | 'finished';
const NAVIGATION_KEY = 'spaced-repetition';

export const SpacedRepetitionWizard = ({ questions }: SpacedRepetitionWizardProps) => {
  const location = useLocation();
  const [viewState, setViewState] = useState<ViewState>('cta');
  const [stats, setStats] = useState<Stats>({
    remaining: questions.length,
    correct: 0,
    wrong: 0,
  });
  const { t } = useTranslation('translation', { keyPrefix: 'spaced-repetition' });
  const { reset } = useFormContext();
  const { query: currentCompanyQuery } = useCurrentCompany(graphQLClient('learner'));
  const navigate = useNavigate();
  const navigateToHome = useCallback(() => navigate('/'), [navigate]);

  const showQuestionsPage = () => setViewState('questions');
  const showFinishPage = () => setViewState('finished');
  const queryClient = useQueryClient();

  const user = useQuery([USER], () => getLoggedInUser(graphQLClient()));

  const { data: overview } = useQuery([SPACED_REPETITION_OVERVIEW], () =>
    currentCompanyQuery.data && user.data
      ? getSpacedRepetitionOverview(graphQLClient(), user.data.id, currentCompanyQuery.data.id)
      : undefined
  );

  useEffect(() => {
    if (location?.state?.viewState) {
      setViewState(location?.state?.viewState);
    }
  }, [location?.state?.viewState]);

  const setBackToHomeButton = useCallback(() => {
    navigation.setLeftPrimary(NAVIGATION_KEY, {
      label: t('return-home'),
      action: () => {
        navigateToHome();
      },
    });
  }, [navigateToHome, t]);

  const setFinishButton = useCallback(() => {
    navigation.setRightPrimary(NAVIGATION_KEY, {
      label: t('finish'),
      action: () => {
        showFinishPage();
      },
    });
  }, [t]);

  const evaluateQuestionAnswer = useCallback(
    async (assessmentId: string, questionId: string, answers: TypeMap<SurveyAnswerMap>) => {
      if (!currentCompanyQuery.data) {
        return Promise.resolve(null);
      }

      const evaluation = await evaluateSpacedRepetitionQuestion(
        graphQLClient('learner'),
        currentCompanyQuery.data.id,
        assessmentId,
        questionId,
        answers
      );

      //WEBSOCKET
      setTimeout(() => {
        queryClient.invalidateQueries([SPACED_REPETITION_OVERVIEW]);
      }, 500);

      const isCorrect = evaluation.earnedPoints === evaluation.totalPoints;

      setStats(({ correct, wrong, ...rest }) => ({
        ...rest,
        correct: isCorrect ? correct + 1 : correct,
        wrong: isCorrect ? wrong : wrong + 1,
      }));

      reset();
      return Promise.resolve(evaluation);
    },
    [queryClient, reset, currentCompanyQuery]
  );

  useEffect(() => {
    navigation.clear();
    setBackToHomeButton();
    setFinishButton();

    return () => {
      navigation.popLeftPrimary(NAVIGATION_KEY);
      navigation.popRightPrimary(NAVIGATION_KEY);
    };
  }, [setBackToHomeButton, setFinishButton]);

  const renderStartPage = () => {
    return (
      <div className="flex h-full w-full items-center justify-center bg-white">
        <div className="flex h-full max-w-lg flex-col items-center justify-between space-y-8 px-8 py-10 lg:justify-center">
          <div className="flex w-full grow flex-col items-center justify-center space-y-8 lg:grow-0">
            <BrainLight />
            <div className="space-y-4 text-center">
              <Heading4>{t('start-page.title')}</Heading4>
              <p className="font-lexend font-regular text-text-02 text-base leading-normal">
                {t('start-page.subtitle', {
                  firstName: user.data?.firstName ?? '',
                })}
              </p>
            </div>
          </div>

          <div className="flex w-full shrink-0 flex-col items-center space-y-4">
            <PrimaryButton
              htmlId={'get-started-button'}
              label={t('start-page.call-to-action')}
              onClick={showQuestionsPage}
              fullWidth
            />
            <TertiaryButton
              htmlId={'later-button'}
              label={t('start-page.later')}
              fullWidth
              onClick={navigateToHome}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPage = () => {
    switch (viewState) {
      case 'cta':
        return renderStartPage();
      case 'questions':
        if (!questions || !stats || !overview) {
          return <LogoLoader />;
        }
        return (
          <div className="font-lexend flex h-full w-full">
            <div
              className="border-border-01 hidden h-full w-full
                        grow-[1] basis-0 items-center justify-center border-r p-8 lg:flex lg:overflow-scroll"
            >
              <SRQStats
                stats={stats}
                overview={overview}
              />
            </div>
            <div className="grow-[2] basis-0 overflow-hidden p-6 pb-0 lg:flex lg:flex-col lg:overflow-scroll lg:p-6 lg:pt-0">
              <TopBar
                onClose={navigateToHome}
                total={questions.length}
                remaining={stats.remaining}
              />
              <div className="mx-auto h-full py-6 lg:h-auto lg:w-[85%] lg:grow lg:py-0 xl:w-[60%]">
                <QuestionComponents.QuestionsContainer
                  onChangeQuestion={(evaluation) => {
                    if (!evaluation) return;

                    setStats(({ remaining, ...rest }) => ({
                      ...rest,
                      remaining: remaining > 1 ? remaining - 1 : remaining,
                    }));
                  }}
                  questions={questions}
                  allowPrevious={false}
                  showQuestionNumbers
                  evaluateQuestionFunc={evaluateQuestionAnswer}
                  allowSkip={false}
                >
                  {(props) => (
                    <QuestionCard
                      {...props}
                      stats={stats}
                      overview={overview}
                    />
                  )}
                </QuestionComponents.QuestionsContainer>
              </div>
            </div>
          </div>
        );
      case 'finished':
        return (
          <SuccessStatePage
            amountOfQuestions={new Set(questions.map((question) => question.id)).size}
          />
        );
    }
  };

  return renderPage();
};
