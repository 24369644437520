import { ReactNode } from 'react';

export type LabelProps = {
  htmlId?: string;
  labelClassName?: string;
  label?: ReactNode | string;
  required: boolean;
  length?: number;
  maxLength?: number;
  rightNode?: ReactNode;
  theme?: 'default' | 'dark';
};

export const Label: React.FC<LabelProps> = ({
  htmlId,
  labelClassName,
  label,
  required,
  length,
  maxLength,
  rightNode,
  theme = 'default',
}) => {
  const showCharacterCount = maxLength && length !== undefined && maxLength > 0 && length >= 0;

  if (!label && !showCharacterCount) return null;
  return (
    <label
      id={htmlId + '-label'}
      htmlFor={htmlId}
      className={`font-lexend flex flex-row justify-between text-base
      ${theme === 'default' ? 'text-text-02' : ''}
      ${theme === 'dark' ? 'text-white' : ''}

      ${labelClassName}`}
    >
      <div className="grow">
        {label}
        {required && (
          <span
            className={` px-1 font-medium ${theme === 'default' ? 'text-negative-01' : ''} ${
              theme === 'dark' ? 'text-red-200' : ''
            }`}
          >
            *
          </span>
        )}
      </div>
      {!!showCharacterCount && (
        <span className="shrink-0">
          {length}/{maxLength}
        </span>
      )}
      {rightNode && <span className="shrink-0">{rightNode}</span>}
    </label>
  );
};
