import { useTranslation } from 'react-i18next';
import { TransferObjective } from './step-choose-transfer-objectives';
import { replaceTagsInText } from '@stellar-lms-frontend/common-utils';
import {
  Language,
  getUploadUrl,
  uploadFile,
  useCompanyStore,
} from '@stellar-lms-frontend/lms-graphql';
import {
  Badge,
  FileInput,
  HintDescription,
  UploadFile,
  WizardContext,
} from '@stellar-lms-frontend/ui-components';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFieldArray, useForm } from 'react-hook-form';
import { graphQLClient } from '../../../../lib/graphql';
import { useContext, useEffect } from 'react';
import { openHubspotChat } from '@stellar-lms-frontend/lms-components';

type FormValues = {
  files: Array<UploadFile>;
};

export type StepAddDocumentsProps = {
  data: {
    skill: string;
    targetAudience: string;
    industry: string;
    language: Language;
    allTransferObjectives: TransferObjective[];
  };
  onSubmit: (documents: UploadFile[]) => void;
  createAIProject: (documents: UploadFile[]) => Promise<void>;
};

export const StepAddDocuments: React.FC<StepAddDocumentsProps> = ({
  data,
  onSubmit,
  createAIProject,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'create-journey.steps.add-documents',
  });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const hints: string[] = t('hint.hints', { returnObjects: true });

  const { setNextFunc, setPrevFunc, setIsCurrentStepValid } = useContext(WizardContext);

  const companyId = useCompanyStore((state) => state.companyId);

  const { register, control } = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      files: [{ status: 'init' }],
    },
  });

  const { fields, append, update, remove } = useFieldArray<FormValues, 'files', 'id'>({
    control,
    name: 'files',
  });

  useEffect(() => {
    if (fields.filter((field) => field.status === 'complete').length === fields.length) {
      append({ status: 'init' });
    }
  }, [fields, append]);

  const handleFileAdded = async (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsCurrentStepValid(false);
      update(index, { status: 'loading', progress: 0 });
      const file = e.target.files[0];

      if (!companyId) {
        update(index, { status: 'error' });
        return;
      }

      const { getUploadUrl: uploadUrl } = await getUploadUrl(graphQLClient(), {
        mimeType: file.type,
        fileName: file.name,
        companyId: companyId,
      });

      if (uploadUrl?.url && uploadUrl.fileId) {
        try {
          await uploadFile(uploadUrl.url, file, (progress) => {
            update(index, { status: 'loading', progress: progress });
          });
          update(index, { status: 'complete', file: file, fileId: uploadUrl.fileId });
          setIsCurrentStepValid(true);
        } catch (_) {
          update(index, { status: 'error' });
        }
      }
    } else {
      // Only delete field in case there is another in init state
      if (fields[index].status !== 'init') {
        remove(index);
      }
    }
  };

  useEffect(() => {
    setPrevFunc(async () => {
      onSubmit(fields);
      return true;
    });
  }, [setPrevFunc, onSubmit, fields]);

  useEffect(() => {
    setNextFunc(async () => {
      createAIProject(fields).then();
      onSubmit(fields);
      return true;
    });
  }, [setNextFunc, onSubmit, fields, createAIProject]);

  return (
    <div className="max-w-createJourneyStep font-lexend mx-auto flex w-full flex-col items-center gap-6 px-12 py-14">
      <div className="flex flex-row items-center space-x-2">
        <p className="text-text-01 text-center text-3xl">{t('title')}</p>
        <div>
          <Badge
            color="purple"
            size="normal"
            children={<span>{tGeneral('beta')}</span>}
          />
        </div>
      </div>
      <p className="text-text-02 type-body-medium text-center">{t('description')}</p>
      <form className="flex w-full flex-col space-y-4">
        {fields.map((item, index) => {
          const myRegister = register(`files.${index}.file`);
          return (
            <FileInput
              key={item.id}
              i18n={{
                invalidFormat: t('upload.errors.invalid-format'),
              }}
              file={item}
              accept=".docx,.md,.pdf,.pptx,.txt"
              className="w-full"
              maxFileSize={{
                size: 51200, // 50MB
                errorMessage: t('upload.errors.max-file-size'),
              }}
              {...{
                ...myRegister,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  handleFileAdded(e, index);
                  myRegister.onChange(e);
                },
              }}
            />
          );
        })}
      </form>
      <HintDescription
        className="w-full"
        title={t('hint.title')}
        icon={
          <FontAwesomeIcon
            icon={faInfoCircle}
            className=" text-informative-01 h-6 w-6"
          />
        }
        description={
          <div className="space-y-1 rounded text-base">
            <ul className="ml-4 list-disc text-sm">
              {hints.map((hint) => {
                return (
                  <li>
                    {replaceTagsInText(hint, {
                      underline: (
                        <span
                          className="cursor-pointer underline"
                          onClick={openHubspotChat}
                        />
                      ),
                    })}
                  </li>
                );
              })}
            </ul>
          </div>
        }
      />
    </div>
  );
};
