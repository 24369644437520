import { useCallback, useState, useMemo } from 'react';
import { FloatingOptions } from '../../../wrappers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';

export type DropdownSelectOption<T> = {
  id: string;
  label: string;
  value: T;
};

export type DropdownSelectProps<T> = {
  selected: DropdownSelectOption<T>;
  onChange: (v: DropdownSelectOption<T>) => void;
  options: DropdownSelectOption<T>[];
  optionClassName?: string;
  selectorClassName?: string;
  hasDivider?: boolean;
};

export const DropdownSelect = <T,>({
  selected,
  onChange,
  options,
  optionClassName,
  selectorClassName,
  hasDivider = false,
}: DropdownSelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = useCallback(() => setIsOpen(false), []);

  const selectedNode = useMemo(
    () => (
      <span
        data-cy="dropdown-select-title"
        className={`${selectorClassName} text-text-01 type-body-medium text-right`}
      >
        {selected.label}
      </span>
    ),
    [selectorClassName, selected.label]
  );

  return (
    <FloatingOptions
      data-cy="dropdown-select"
      placement="bottom-end"
      hasDivider={hasDivider}
      onClose={onClose}
      isOpen={isOpen}
      widthClassName="w-fit"
      wrappedComponent={
        options.length > 1 ? (
          <button
            onClick={() => setIsOpen((prev) => !prev)}
            className="flex items-center gap-2 lg:underline"
            data-cy="dropdown-select-dropdown"
          >
            {selectedNode}
            <FontAwesomeIcon
              icon={faChevronDown}
              className="text-text-01 text-2xl"
            />
          </button>
        ) : (
          selectedNode
        )
      }
      options={options.map((o) => ({
        onClick: () => {
          onClose();
          onChange(o);
        },
        className: optionClassName,
        label: o.label,
      }))}
    />
  );
};
