import { useNavigate, useParams } from 'react-router-dom';
import {
  ChartCard,
  ContentContainer,
  LeftArrowIcon,
  ScrollContainer,
} from '@stellar-lms-frontend/ui-components';
import { MENTOR_COURSE_ROUTE } from '../../constants/routes';
import {} from '@stellar-lms-frontend/lms-components';
import { useTranslation } from 'react-i18next';
import { UIShell } from '../shell/ui-shell';
import { UserProgressChart } from './charts/user-progress-chart';
import { SpacedRepetitionErrorRatesChart } from './charts/spaced-repetition-error-rates-chart';
import { SRQDistributionChart } from './charts/srq-distribution-chart';
import { TotalSRQDistributionChart } from './charts/total-srq-distribution-chart';
import { UserWorkBasedActionsChart } from './charts/user-work-based-actions-chart';
import { OverallWorkBasedActionStateDistributionChart } from './charts/overall-work-based-action-state-distribution-chart.tsx';

export const MentorInsightsPage = () => {
  const navigate = useNavigate();
  const { courseId } = useParams();

  const { t } = useTranslation('translation', { keyPrefix: 'insights-page' });

  // CLEANUP we're in a course route it should always be there -> upgrade routing to new react router
  if (!courseId) {
    return null;
  }

  return (
    <UIShell
      subNavTitle={t('title')}
      subNavSubtitle={t('subtitle')}
      leftButton={{
        leftIcon: <LeftArrowIcon className="fill-on-primary-01 h-5 w-5" />,
        action: () => navigate(MENTOR_COURSE_ROUTE(courseId)),
      }}
    >
      <ScrollContainer scrollOnDesktop>
        <ContentContainer>
          <div className="max-w-screen-3xl w-full">
            <div className="grid w-full grid-cols-12 gap-4">
              <ChartCard
                title="Learner Progress"
                className="col-span-12 h-[400px]"
              >
                <UserProgressChart courseId={courseId} />
              </ChartCard>
              <ChartCard
                title="Spaced Repetition Questions (SRQs) distribution"
                className="col-span-9 h-[400px]"
              >
                <SRQDistributionChart courseId={courseId} />
              </ChartCard>
              <ChartCard
                title="Total SRQ Distribution"
                className="col-span-3 h-[400px]"
              >
                <TotalSRQDistributionChart courseId={courseId} />
              </ChartCard>
              <ChartCard
                title="Spaced Repetition Error Rates"
                className="col-span-12 h-[400px]"
              >
                <SpacedRepetitionErrorRatesChart courseId={courseId} />
              </ChartCard>
              <ChartCard
                title="Learner Work Based Actions"
                className="col-span-9 h-[400px]"
              >
                <UserWorkBasedActionsChart courseId={courseId} />
              </ChartCard>
              <ChartCard
                title="Overall Learner Work Based Actions"
                className="col-span-3 h-[400px]"
              >
                <OverallWorkBasedActionStateDistributionChart courseId={courseId} />
              </ChartCard>
            </div>
          </div>
        </ContentContainer>
      </ScrollContainer>
    </UIShell>
  );
};
