import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import { ReactNode } from 'react';
import { SecondaryButton } from '../buttons';
import { Heading4 } from '../heading';

export type ErrorViewComponentProps = {
  icon: ReactNode;
  title: string;
  subtitle?: string;
  callToAction: string;
  action: VoidFunc;
};
export const ErrorViewTemplate = ({
  icon,
  title,
  subtitle,
  callToAction,
  action,
}: ErrorViewComponentProps) => (
  <div className="center grid h-full w-full place-items-center p-4 lg:p-8">
    <div className="font-lexend flex max-w-2xl flex-col items-center space-y-8 text-center">
      {icon}
      <Heading4>{title}</Heading4>
      {subtitle && <p className="text-text-02">{subtitle}</p>}
      <SecondaryButton
        htmlId="go-back-button"
        label={callToAction}
        onClick={action}
      />
    </div>
  </div>
);
