import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import { ReactNode } from 'react';

export type NuggetProps = {
  title: string;
  description: string;
  onActionClick?: VoidFunc;
  actionText?: string;
  left?: ReactNode;
};

export const Nugget: React.FC<NuggetProps> = ({
  title,
  description,
  onActionClick,
  actionText,
  left,
}) => {
  return (
    <div className="bg-surface-02 font-lexend flex items-center justify-between rounded-xl p-4">
      <div className="pr-2">
        <p className="text-text-04 font-medium">{title}</p>
        <p className="text-text-02 text-sm">{description}</p>
        {actionText && (
          <button
            onClick={onActionClick}
            className="text-primary-01 text-sm underline"
          >
            {actionText}
          </button>
        )}
      </div>
      {left}
    </div>
  );
};
