import { useMediaQuery } from '@stellar-lms-frontend/common-utils';
import { ActionEmptyStateIcon, EmptyState, LogoLoader } from '@stellar-lms-frontend/ui-components';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ActionDetail } from './components/action-detail';
import { CompletedActionList } from './components/completed-action-list';
import { OngoingActionList } from './components/ongoing-action-list';
import { PersistedLearnerWorkBasedAction } from '@stellar-lms-frontend/lms-api';
import { LEARNER_COURSE_ACTIONS_BASE_ROUTE } from '../../constants/routes';
import { SplitView } from '../shell/split-view';

export type ActionsOverviewProps = {
  completedActions: PersistedLearnerWorkBasedAction[];
  openActions: PersistedLearnerWorkBasedAction[];
};
export const ActionsOverviewPage = ({ completedActions, openActions }: ActionsOverviewProps) => {
  const isDesktop = useMediaQuery('(min-width: 1280px)');
  const [action, setAction] = useState<PersistedLearnerWorkBasedAction>();
  const [showMoreActions, setShowMoreActions] = useState<boolean>(false);

  const { courseId, actionId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const navigateToFirstAction = () => {
      const action = openActions.at(0);
      action && navigate(`${LEARNER_COURSE_ACTIONS_BASE_ROUTE(courseId)}/${action.id}`);
    };

    if (!actionId && isDesktop) {
      navigateToFirstAction();
    } else {
      const action =
        openActions.find((action) => action.id === actionId) ??
        completedActions.find((action) => action.id === actionId);

      action ? setAction(action) : navigateToFirstAction();
    }
  }, [actionId, isDesktop, openActions, navigate, completedActions, courseId]);

  return (
    <SplitView
      detailSelected={!!actionId}
      detailView={
        <DetailView
          isEmpty={openActions.length === 0 && !actionId}
          action={action}
        />
      }
      overview={
        <div className="flex w-full flex-col">
          <section className="w-full">
            <OngoingActionList
              openActions={openActions}
              actionId={actionId}
              isDesktop={true}
              baseUrl={LEARNER_COURSE_ACTIONS_BASE_ROUTE(courseId)}
            />
          </section>
          <section className="w-full pt-12">
            <CompletedActionList
              completedActions={completedActions}
              isDesktop={true}
              actionId={actionId}
              showMore={showMoreActions}
              setShowMore={setShowMoreActions}
              baseUrl={LEARNER_COURSE_ACTIONS_BASE_ROUTE(courseId)}
            />
          </section>
        </div>
      }
    />
  );
};

const DetailView = ({
  isEmpty,
  action,
}: {
  isEmpty: boolean;
  action: PersistedLearnerWorkBasedAction | undefined;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'actions' });

  if (isEmpty) {
    return (
      <EmptyState
        icon={<ActionEmptyStateIcon />}
        title={t('empty-ongoing.title')}
        description={t('empty-ongoing.description')}
      />
    );
  }
  if (action) {
    return (
      <ActionDetail
        key={action.id}
        action={action}
      />
    );
  }
  return <LogoLoader />;
};
