import { Outlet } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getLoggedInUser, USER } from '@stellar-lms-frontend/lms-api';
import { hasIntersection } from '@stellar-lms-frontend/common-utils';
import { CompanyRole } from '@stellar-lms-frontend/lms-graphql';
import {GraphQLClient} from "graphql-request";

export type RequireRoleProps = {
  acceptedRoles: CompanyRole[];
  children?: JSX.Element;
  graphQLClient: GraphQLClient;
  loaderComponent: JSX.Element;
  unAuthorizedView: JSX.Element;
};
export const RequireRole = ({
  acceptedRoles,
  children,
  graphQLClient,
  loaderComponent,
  unAuthorizedView,
}: RequireRoleProps) => {
  const [isAuthorized, setIsAuthorized] = useState(false);

  const { data: user, isLoading } = useQuery([USER], () => getLoggedInUser(graphQLClient));

  useEffect(() => {
    setIsAuthorized(hasIntersection(user ? [user.role] : [], acceptedRoles));
  }, [acceptedRoles, user]);

  return isLoading ? loaderComponent : isAuthorized ? children ?? <Outlet /> : unAuthorizedView;
};
