import { AddMentorToCourseMutationVariables, graphql } from '@stellar-lms-frontend/lms-graphql';
import { GraphQLClient } from 'graphql-request';

export const addMentorToCourse = (
  client: GraphQLClient,
  variables: AddMentorToCourseMutationVariables
) => {
  return client.request(
    graphql(`
      mutation AddMentorToCourse($courseId: ID!, $mentorId: ID!) {
        addMentorToCourse(courseId: $courseId, mentorId: $mentorId)
      }
    `),
    variables
  );
};
