import { DragItemProps } from '../../../list';
import { FillBlanksSettingsForm } from './fill-blanks-settings-form';
import { FillBlanksSettingsPreview } from './fill-blanks-settings-preview';
import { SettingsCard } from '../../../card';

export type FillBlanksFormErrors = {
  text?: {
    message?: string;
  };
  feedback?: {
    message?: string;
  };
};

export type FillBlanksSettings = {
  text: string;
  feedback: string;
  spacedRepetitionEnabled: boolean;
};

export type FillBlanksSettingsCardProps = {
  value: FillBlanksSettings;
} & (
  | {
      isPreview: true;
      onClick: (value: FillBlanksSettings) => void;
      isHighlighted?: boolean;
      onChange?: never;
      dragProps?: never;
      isOpen?: never;
      onDelete?: never;
      onToggleOpenState?: never;
      errors?: never;
    }
  | {
      isPreview?: false;
      onClick?: never;
      isHighlighted?: never;
      onChange: (data: FillBlanksSettings) => void;
      dragProps?: DragItemProps;
      isOpen: boolean;
      onDelete: () => void;
      onToggleOpenState?: () => void;
      errors?: FillBlanksFormErrors;
    }
);

export const FillBlanksSettingsCard: React.FC<FillBlanksSettingsCardProps> = ({
  value,
  isOpen,
  onChange,
  onDelete,
  onToggleOpenState,
  dragProps,
  errors,
  isPreview,
  onClick,
  isHighlighted,
}) => {
  return isPreview ? (
    <SettingsCard
      color={'surface-02'}
      isHighlighted={isHighlighted}
      onClick={() => onClick(value)}
      closedChildren={
        <FillBlanksSettingsPreview
          isHighlighted={isHighlighted}
          settings={value}
        />
      }
    />
  ) : (
    <SettingsCard
      hasOptionsMenu
      onToggleOpenState={onToggleOpenState}
      onDelete={onDelete}
      isOpen={isOpen}
      dragProps={dragProps}
      openChildren={
        <FillBlanksSettingsForm
          value={value}
          onChange={(newValue) => onChange(newValue)}
          errors={errors}
        />
      }
      closedChildren={<FillBlanksSettingsPreview settings={value} />}
      hasError={!!errors}
    />
  );
};
