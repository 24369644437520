import { useScreenType } from '@stellar-lms-frontend/common-utils';
import {
  ConditionalWrapper,
  DraggableWrapper,
  Heading3,
  Heading4,
  LinkButton,
  Modal,
  PrimaryButton,
  ScrollContainer,
  StepCounter,
  TertiaryButton,
  TrophyIcon,
} from '@stellar-lms-frontend/ui-components';
import { useQueryClient } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';
import { SPACED_REPETITION_OVERVIEW } from '../../constants/query';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  SpacedRepetitionOverview,
} from '@stellar-lms-frontend/lms-api';
import { SRQStats, Stats } from './components/srq-stats';
import { Question } from '@stellar-lms-frontend/lms-components';
import {putQuestionLevelOnProficient} from "./spaced-repetition.api";
import {graphQLClient} from "../../lib/graphql";
import {useCurrentCompany} from "@stellar-lms-frontend/lms-graphql";

type QuestionCardProps = Question.QuestionViewChildrenProps & {
  stats: Stats;
  overview: SpacedRepetitionOverview;
};

const FEEDBACK_FALLBACK_HEIGHT_PX = 200;
const SRQ_STATS_FALLBACK_HEIGHT_PX = 800;

export const QuestionCard: React.FC<QuestionCardProps> = ({
  questionId,
  questionTitle,
  questionRendered,
  answerEvaluation,
  isDirty,
  buttonContent,
  showQuestionNumbers,
  total,
  number,
  stats,
  overview,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assessment-step-view' });
  const { t: tsr } = useTranslation('translation', { keyPrefix: 'spaced-repetition' });
  const { isTailwindLg } = useScreenType();
  const refFeedback = useRef<HTMLDivElement>(null);
  const refDraggable = useRef<HTMLDivElement>(null);
  const refButton = useRef<HTMLDivElement>(null);
  const [draggablePos, setDraggablePos] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [showMasterModal, setShowMasterModal] = useState(false);
  const queryClient = useQueryClient();
  const { query: currentCompanyQuery } = useCurrentCompany(graphQLClient('learner'));

  const buttonText = useMemo(() => {
    const buttonText = buttonContent?.label ?? tsr('check');
    if (buttonText === tsr('check')) return tsr('check-answer');
    else if (buttonText === tsr('next')) return tsr('next-question');
    else if (buttonText === tsr('finish')) return buttonText;

    return '';
  }, [buttonContent?.label, tsr]);

  const confirmMasteredQuestion = async () => {
    if (currentCompanyQuery.data) {
      await putQuestionLevelOnProficient(graphQLClient('learner'), currentCompanyQuery.data.id, questionId);
    }
    buttonContent?.action();

    //WEBSOCKET
    setTimeout(() => {
      queryClient.invalidateQueries([SPACED_REPETITION_OVERVIEW]);
    }, 500);
  };

  const onChangePosition = useCallback((x: number, y: number) => setDraggablePos({ x, y }), []);

  const {
    earnedPoints,
    totalPoints,
    feedback,
    remainingRepetitionsForQuestion = 0,
  } = answerEvaluation || {};

  const isCorrect = answerEvaluation && earnedPoints === totalPoints;
  const canJumpToMastery = isCorrect && remainingRepetitionsForQuestion > 0;

  return (
    <>
      <Modal
        className="bg-surface-01 h-full md:h-auto md:w-[510px]"
        isOpen={showMasterModal}
        title={tsr('mastered.title')}
        onClose={() => {
          setShowMasterModal(false);
        }}
      >
        <div className="flex h-full flex-col lg:h-auto">
          <div className="font-regular font-lexend text-text-01 grow lg:grow-0">
            {tsr('mastered.description')}
          </div>
          <div className="mt-6 flex flex-col space-y-2 lg:flex-row lg:space-x-4 lg:space-y-0">
            <PrimaryButton
              data-cy="mastered-modal-confirm"
              label={tsr('mastered.confirm_cta')}
              onClick={() => confirmMasteredQuestion()}
            />
            <LinkButton
              data-cy="mastered-modal-cancel"
              label={tsr('mastered.cancel_cta')}
              onClick={() => setShowMasterModal(false)}
            />
          </div>
        </div>
      </Modal>
      <div className="flex h-full items-stretch justify-center lg:items-center">
        <div className="relative flex w-full flex-col lg:static lg:m-auto">
          <ScrollContainer
            addPadding={false}
            scrollOnDesktop
          >
            <div className="mb-6">
              {showQuestionNumbers && (
                <StepCounter
                  className="text-primary-01 mb-2 hidden lg:block"
                  curr={number}
                  total={total}
                />
              )}
              <Heading3 className="text-text-04 hidden lg:inline">{questionTitle}</Heading3>
              <Heading4 className="text-text-04 lg:hidden">{questionTitle}</Heading4>
            </div>
            {questionRendered}
            <div
              className="bg-surface-01"
              style={{
                height: !isTailwindLg && !!answerEvaluation ? -draggablePos.y + 32 : 0, // REFACTOR: think if there is a better solution
              }}
            />
          </ScrollContainer>

          <ConditionalWrapper
            hasWrapper={!isTailwindLg && !!answerEvaluation}
            wrapper={(children) => (
              <DraggableWrapper
                onChangePosition={onChangePosition}
                className="shadow-feedback w-screen px-6"
                canDragY
                dragContainer={{
                  minX: -Infinity,
                  maxX: Infinity,
                  minY: -(refDraggable.current?.offsetHeight ?? SRQ_STATS_FALLBACK_HEIGHT_PX),
                  maxY: (refDraggable.current?.offsetHeight ?? SRQ_STATS_FALLBACK_HEIGHT_PX) - 32, // padding and grey bar,
                }}
                x={-24}
                y={
                  -(refFeedback.current?.offsetHeight ?? FEEDBACK_FALLBACK_HEIGHT_PX) - 32 // padding and grey bar
                }
              >
                <div
                  className="bg-surface-01 z-10 pt-2"
                  ref={refDraggable}
                >
                  <div className="bg-border-02 mx-auto mb-5 h-1 w-[80px]" />
                  {children}
                  <SRQStats
                    stats={stats}
                    overview={overview}
                  />
                </div>
              </DraggableWrapper>
            )}
          >
            <div className="lg:mt-8">
              {feedback && (
                <div
                  className="mb-6"
                  ref={refFeedback}
                >
                  <div className="h-fit space-y-1">
                    {isCorrect ? (
                      <Heading4
                        className="text-positive-01"
                        data-cy={'question-card-feedback-title'}
                      >
                        {t('correct')}
                      </Heading4>
                    ) : (
                      <Heading4
                        className="text-negative-01"
                        data-cy={'question-card-feedback-title'}
                      >
                        {t('incorrect')}
                      </Heading4>
                    )}
                    <p
                      className="text-text-02 whitespace-pre-wrap"
                      data-cy={'question-card-feedback-text'}
                    >
                      {feedback}
                    </p>
                    {remainingRepetitionsForQuestion >= 0 && (
                      <div
                        className=" flex flex-row space-x-3 pt-2"
                        data-cy="question-card-remaining-repetition"
                      >
                        <TrophyIcon />
                        <p className="text-text-02 font-normal">
                          {remainingRepetitionsForQuestion > 0 ? (
                            <Trans
                              t={tsr}
                              i18nKey="answer-x-times-to-master"
                              count={remainingRepetitionsForQuestion}
                            >
                              part-0<span className="text-positive-01 font-semibold">part-1</span>
                              part-2
                            </Trans>
                          ) : (
                            <span>{tsr('answer-mastered')}</span>
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </ConditionalWrapper>
          <div
            ref={refButton}
            className={
              'bg-surface-01 sticky bottom-0 left-0 z-20 flex w-full flex-col-reverse space-y-6 space-y-reverse py-6 lg:static lg:w-full lg:flex-row lg:space-x-4 lg:p-0 lg:pb-6'
            }
          >
            <PrimaryButton
              data-cy="question-card-next-button"
              enabled={isDirty || !buttonText.includes(tsr('check'))}
              onClick={() => buttonContent?.action()}
              label={buttonText}
            />
            {canJumpToMastery && (
              <TertiaryButton
                data-cy="question-card-mastered-modal"
                label={tsr('mastered.modal_cta')}
                onClick={() => setShowMasterModal(true)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
