import { useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  SuggestionGenerationStatus,
  getSuggestionGenerationStatus,
} from '@stellar-lms-frontend/lms-graphql';

interface SuggestedPages<T> {
  status?: SuggestionGenerationStatus | null;
  suggestions?: T[] | null;
  pageInfo?: {
    hasNextPage: boolean;
    cursor?: string | null;
  };
}

type FetchFunction<T> = (params: {
  courseId: string;
  moduleId: string;
  nrPerPage: number;
  cursor: string;
}) => Promise<T>;

type useAISuggestionsArgs<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryKey: any[];
  fetchFunction?: FetchFunction<SuggestedPages<T> | undefined>;
  courseId: string | undefined;
  moduleId: string | undefined;
};

export const useAISuggestions = <T>({
  queryKey,
  fetchFunction,
  courseId,
  moduleId,
}: useAISuggestionsArgs<T>) => {
  const {
    data: suggestedPages,
    fetchNextPage,
    refetch,
    hasNextPage,
  } = useInfiniteQuery<SuggestedPages<T> | undefined>(
    queryKey,
    ({ pageParam = '' }) => {
      return courseId && moduleId && fetchFunction
        ? fetchFunction({
            courseId,
            moduleId,
            nrPerPage: 3,
            cursor: pageParam,
          })
        : undefined;
    },
    {
      enabled: !!courseId && !!moduleId && !!fetchFunction,
      refetchInterval: 10000,
      getNextPageParam: (lastPage) =>
        lastPage?.pageInfo?.hasNextPage ? lastPage?.pageInfo?.cursor : undefined,
    }
  );

  const status = useMemo(
    () =>
      getSuggestionGenerationStatus(
        suggestedPages?.pages.map((p) => ({
          status: p?.status ?? SuggestionGenerationStatus.None,
        })) ?? []
      ),
    [suggestedPages?.pages]
  );

  return {
    suggestions:
      suggestedPages?.pages.flatMap((p) => p?.suggestions).flatMap((a) => (a ? [a] : [])) ?? [],
    status,
    fetchNextPage,
    hasNextPage,
    refetch,
  };
};
