import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionEmptyStateIcon, EmptyState, LogoLoader } from '@stellar-lms-frontend/ui-components';
import { ActionsOverviewPage } from './actions-overview-page';
import { useQuery } from '@tanstack/react-query';
import { FETCH_ACTIONS } from '../../constants/query';
import { useNavigate, useParams } from 'react-router-dom';
import { useDueDate } from '../../hooks/use-due-date';
import { PersistedLearnerWorkBasedAction } from '@stellar-lms-frontend/lms-api';
import { UIShell } from '../shell/ui-shell';
import { getLearnerWorkBasedActions } from './actions.api';
import { graphQLClient } from '../../lib/graphql';

const filterCompletedActions = (actions: PersistedLearnerWorkBasedAction[]) =>
  actions.filter((a) => a.state === 'completed' || a.state === 'reviewed');
const filterOpenactions = (actions: PersistedLearnerWorkBasedAction[]) =>
  actions.filter((a) => a.state !== 'completed' && a.state !== 'reviewed');

export const ActionsPage = () => {
  const { courseId } = useParams();
  const actionsQuery = useQuery([FETCH_ACTIONS], () => getLearnerWorkBasedActions(graphQLClient()));

  const actions = useMemo(
    () => actionsQuery.data?.filter((action) => !courseId || action.courseId === courseId) ?? [],
    [actionsQuery.data, courseId]
  );

  const navigate = useNavigate();
  const getDue = useDueDate();

  const completedActions = useMemo<PersistedLearnerWorkBasedAction[]>(
    () => filterCompletedActions(actions),
    [actions]
  );

  const openActions = useMemo<PersistedLearnerWorkBasedAction[]>(() => {
    const openActions = filterOpenactions(actions);

    const inReviewActions = openActions.filter((a) => a.state === 'executed');
    let filteredIds = inReviewActions.map((a) => a.id);

    const overDueActions = openActions.filter(
      (a) =>
        !filteredIds.includes(a.id) &&
        a.learnerDueDate &&
        getDue(a.learnerDueDate).type === 'overdue'
    );

    filteredIds = [...filteredIds, ...overDueActions.map((a) => a.id)];
    const onGoingActions = openActions.filter((a) => !filteredIds.includes(a.id));

    return [...overDueActions, ...inReviewActions, ...onGoingActions];
  }, [actions, getDue]);

  const { t } = useTranslation('translation', { keyPrefix: 'actions' });

  if (actionsQuery.isLoading) {
    return (
      <UIShell>
        <LogoLoader />
      </UIShell>
    );
  } else if (actions && actions.length > 0) {
    return (
      <ActionsOverviewPage
        completedActions={completedActions}
        openActions={openActions}
      />
    );
  } else {
    return (
      <UIShell>
        <div className="flex h-full w-full flex-row  justify-center">
          <div className="max-w-content h-full w-full">
            <EmptyState
              icon={<ActionEmptyStateIcon />}
              title={t('empty.title')}
              actionText={t('empty.go-back')}
              onActionClick={() => navigate(-1)}
              description={t('empty.description')}
            />
          </div>
        </div>
      </UIShell>
    );
  }
};

export default ActionsPage;
