import { HeaderComponent } from './header-component';
import { v4 as uuidv4 } from 'uuid';
import { Alignment, Levels } from '../types';
import ReactDOM from 'react-dom';
import { API, BlockToolConstructorOptions, BlockToolData, ToolConfig } from '@editorjs/editorjs';

export interface HeaderData extends BlockToolData {
  text: string;
  alignment: Alignment;
}

interface HeaderConfig extends ToolConfig {
  defaultAlignment: Alignment;
}

export class Header {
  api: API;
  data: HeaderData;
  readOnly: boolean;
  element: HTMLElement;

  constructor({
    data,
    api,
    config,
    readOnly,
  }: BlockToolConstructorOptions<HeaderData, HeaderConfig>) {
    this.data = {
      text: data.text || '',
      alignment: data.alignment || config?.defaultAlignment || Alignment.LEFT,
    };

    this.api = api;
    this.element = this.createElement();
    this.readOnly = readOnly;

    this.setAlignment = this.setAlignment.bind(this);
  }

  static get toolbox() {
    return {
      title: 'Header',
    };
  }

  static get inlineToolbar() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  createElement() {
    const rootNode = document.createElement('div');
    rootNode.id = uuidv4();
    return rootNode;
  }

  updateElement() {
    // Calling ReactDOM will generate errors on the console, it should be replaced with createRoot.
    // But doing that will lead to error on EditorJs side (eg. Press Enter won't create a new block)
    ReactDOM.render(
      <HeaderComponent
        level={Levels.ONE}
        isReadOnly={this.readOnly}
        data={this.data}
        onChange={(newData: HeaderData) => (this.data = newData)}
      />,
      this.element
    );
  }

  setAlignment(alignment: Alignment) {
    this.data = {
      ...this.data,
      alignment: alignment,
    };

    this.updateElement();
  }

  // renderSettings() {
  //   const alignmentSettings = getAlignmentSettings(this.setAlignment);

  //   return [...alignmentSettings];
  // }

  render(): HTMLElement {
    this.updateElement();
    return this.element;
  }

  save(blockContent: HTMLElement): BlockToolData<HeaderData> {
    return {
      text: blockContent.innerHTML,
      alignment: this.data.alignment,
    };
  }

  onPaste(event: CustomEvent) {
    this.data = { ...this.data, text: event.detail.data.innerHTML };
    this.updateElement();
  }

  static get sanitize() {
    return {
      text: {
        br: true,
      },
    };
  }
}
