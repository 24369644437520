import { Maybe } from '@stellar-lms-frontend/lms-graphql';
import Fuse from 'fuse.js';

type Module = {
  id: string;
  learningActivities?: Maybe<
    {
      id: string;
      title?: Maybe<string>;
    }[]
  >;
};

const autocompleteLearningActivity = (
  query: string,
  learningActivityIndexInModule: number,
  moduleIndex: number,
  modules: Module[]
) => {
  if (modules) {
    const flatModulesLA = modules
      .flatMap((module, index) =>
        module.learningActivities?.map((la) => ({ ...la, moduleIndex: index }))
      )
      .flatMap((la) => (la ? [la] : []));

    const laAbsoluteIndex =
      flatModulesLA.findIndex((m) => m?.moduleIndex === moduleIndex) +
      learningActivityIndexInModule;

    const defaultOptions = flatModulesLA
      .slice(laAbsoluteIndex - 3 >= 0 ? laAbsoluteIndex - 3 : 0, laAbsoluteIndex)
      .map((la) => ({ id: la.id, label: la?.title ?? '' }))
      .reverse();

    const fuse = new Fuse(flatModulesLA, {
      keys: ['title'],
      includeScore: true,
      threshold: 0.3,
    });

    const results = fuse.search(query, { limit: 3 });
    return Promise.resolve({
      options: results.map((result) => ({
        id: result.item.id,
        label: result.item.title ?? '',
      })),
      defaultOptions,
    });
  } else {
    return Promise.resolve({ options: [{ id: '', label: '' }] });
  }
};

const createIntersectionObserver = (
  scrollViewId: string,
  subjects: { id: string }[],
  intersectionFunc: (intersectedId: string) => void,
  options: { debug: boolean; heightPadding: number } | undefined = {
    debug: false,
    heightPadding: 0,
  }
) => {
  const scrollView = document.getElementById(scrollViewId);
  if (!scrollView) {
    return null;
  }

  const detectionZoneHeight = 1;
  const windowHeight =
    (scrollView?.clientHeight ?? 0) - options.heightPadding - detectionZoneHeight;

  if (options.debug) {
    const debugDivId = `intersection-observer-debug-${scrollViewId}`;
    document.getElementById(debugDivId)?.remove();

    const debugDiv = document.createElement('div');
    debugDiv.id = debugDivId;
    debugDiv.style.position = 'absolute';
    debugDiv.style.left = scrollView.getBoundingClientRect().left + 'px';
    debugDiv.style.top = scrollView.getBoundingClientRect().top + options.heightPadding + 'px';
    debugDiv.style.height = detectionZoneHeight + 'px';
    debugDiv.style.width = scrollView.getBoundingClientRect().width + 'px';
    debugDiv.style.backgroundColor = 'rgba(255, 0, 0, 0.5)';
    document.body.appendChild(debugDiv);
  }

  const observer = new IntersectionObserver(
    (entries) => {
      entries
        .filter((entry) => entry.isIntersecting)
        .forEach((entry) => {
          intersectionFunc(entry.target.id);
        });
    },
    {
      rootMargin: `${-options.heightPadding}px 0px ${-windowHeight}px 0px`,
      threshold: 0,
      root: scrollView,
    }
  );

  const observedSubjects: string[] = [];
  subjects.forEach((m) => {
    const element = document.getElementById(m.id);
    if (element && options.debug) {
      element.style.border = '1px solid green';
    }
    if (element && !observedSubjects.includes(m.id)) {
      observer.observe(element);
      observedSubjects.push(m.id);
    }
  });

  return observer;
};

const convertToPlaceholderTitle = (title?: string) => {
  return title ? `[${title}]` : '';
};

export { autocompleteLearningActivity, createIntersectionObserver, convertToPlaceholderTitle };
