import { CourseModuleOutline } from '@stellar-lms-frontend/lms-graphql';
import { useTranslation } from 'react-i18next';

export type ModuleSelectorProps = {
  modules: CourseModuleOutline[];
  onModuleSelect: (moduleID: string) => void;
};
export const ModuleSelector: React.FC<ModuleSelectorProps> = ({ modules, onModuleSelect }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'module-overview' });
  const handleModuleSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    onModuleSelect(e.target.value);
  };

  return (
    <select
      className="font-lexend text-text-01 border-1 border-border-02 outline-on-primary-02
       hover:border-border-01 focus:border-border-02 focus:outline-on-primary-02 active:border-border-02
       active:outline-on-primary-02 flex h-14 w-full flex-row items-center justify-center rounded-xl
       bg-white px-6 py-0 text-center text-base leading-normal shadow-sm outline-offset-2 focus:outline-4 active:outline-4
       disabled:from-gray-200 disabled:to-gray-200 disabled:text-gray-400 disabled:shadow-none"
      onChange={handleModuleSelected}
    >
      <option
        key="placeholder"
        value=""
        hidden
        disabled
      >
        {t('module-search-text')}
      </option>
      {modules.map((module) => (
        <option
          key={module.id}
          value={module.id}
        >
          {module.title}
        </option>
      ))}
    </select>
  );
};
