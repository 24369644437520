import { Badge } from '../badge/badge';

export type BadgeLanguageProps = {
  label: string;
  className?: string;
};

export const BadgeLanguage: React.FC<BadgeLanguageProps> = ({ label, className }) => {
  return (
    <Badge
      color="grey"
      size="normal"
      className={className}
    >
      <p className="type-tiny-default">{label.toUpperCase()}</p>
    </Badge>
  );
};
