import Skeleton from "react-loading-skeleton";

export const ArticleLoading = () => (
  <div className={`my-2 space-y-2 rounded-xl p-4 shadow-lg`}>
    <div className="flex space-x-2">
      <Skeleton width={24} />
      <div className="type-body-medium text-text-01 font-lexend line-clamp-1 grow">
        <Skeleton
          height={18}
          width={350}
        />
      </div>
    </div>

    <div className="line-clamp-3 type-tiny font-lexend text-text-02">
      <Skeleton
        height={16}
        width={600}
      />
      <Skeleton
        height={16}
        width={400}
      />
    </div>
    <div className="type-tiny font-lexend text-text-02">
      <Skeleton
        height={16}
        width={100}
      />
    </div>
  </div>
);
