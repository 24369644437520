import {
  CrossIcon,
  FileInput,
  HintDescription,
  Modal,
  PrimaryButton,
  ProgressBar,
  TertiaryButton,
  UploadFile,
  UserCircleIcon,
} from '@stellar-lms-frontend/ui-components';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTurnDownRight } from '@fortawesome/pro-light-svg-icons';
import { replaceTagsInText } from '@stellar-lms-frontend/common-utils';
import { openHubspotChat } from '../../../../hubspot';
import { useTranslation } from 'react-i18next';

type FormData = {
  file: UploadFile;
};

export type MemberBulkImportModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (file: File) => Promise<void>;
  uploadProgress?: number;
};

export const MemberBulkImportModal: React.FC<MemberBulkImportModalProps> = ({
  isOpen,
  onClose,
  onUpload,
  uploadProgress,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organization.members-tab.actions.add.bulk-modal',
  });
  const schema = yup.object().shape({
    file: yup
      .mixed()
      .required(t('file.error'))
      .test('filesLength', t('file.error'), (value: any) => {
        return value.length > 0;
      }),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const [errorState, setErrorState] = useState(false);
  const [uploadProgressState, setUploadProgressState] = useState(uploadProgress);
  const [uploadFile, setUploadFile] = useState<UploadFile>({ status: 'init' });

  useEffect(() => {
    setUploadProgressState(uploadProgress ?? 0);
  }, [uploadProgress]);

  const inProgressState = useMemo(() => {
    return (
      <div className="flex flex-col items-center">
        <UserCircleIcon />
        <span className="text-text-02 mt-10">{t('uploading')}</span>
        <ProgressBar
          htmlId={''}
          className={'mt-6 w-full rounded-full'}
          bgColorClassName={'bg-border-01'}
          progress={[
            {
              value: uploadProgressState ?? 0,
              colorClassName: 'bg-primary-01',
            },
          ]}
        />
      </div>
    );
  }, [t, uploadProgressState]);

  const fileRegister = register(`file`);

  const inputState = useMemo(() => {
    const onSubmitForm = async (data: FormData) => {
      if (uploadFile.file) {
        try {
          await onUpload(uploadFile.file);
          setErrorState(false);
        } catch (e) {
          reset();
          setErrorState(true);
        }
        setUploadProgressState(0);
        setUploadFile({ status: 'init' });
      }
    };

    const handleFileAdded = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        setUploadFile({ status: 'complete', file: file });
      } else {
        setUploadFile({ status: 'init' });
      }
    };

    return (
      <form
        onSubmit={handleSubmit(onSubmitForm)}
        className="space-y-6"
      >
        <HintDescription
          title={t('hint.title')}
          description={
            <div className="space-y-1 rounded text-base">
              <ul className="ml-4 list-disc">
                {t('hint.hints', { returnObjects: true }).map((hint) => (
                  <li>{hint}</li>
                ))}
              </ul>
            </div>
          }
        />
        <p className="text-text-01">{t('subtitle')}</p>
        <FileInput
          i18n={{
            invalidFormat: t('file.invalid-format'),
          }}
          accept=".csv"
          file={uploadFile}
          className="w-full"
          {...{
            ...fileRegister,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              handleFileAdded(e);
              fileRegister.onChange(e);
            },
          }}
        />
        <PrimaryButton
          htmlType="submit"
          label={t('cta')}
          enabled={isValid}
        />
      </form>
    );
  }, [handleSubmit, t, isValid, reset, onUpload, uploadFile, fileRegister]);

  const inErrorState = useMemo(() => {
    return (
      <div className="flex flex-col items-center gap-6">
        <UserCircleIcon />
        <span className="text-negative-01 flex flex-row items-center gap-2 text-center">
          <CrossIcon className="h-5 w-5" />
          {t('error.title')}
        </span>
        <span className={'text-text-02 text-center'}>
          {replaceTagsInText(t('error.message'), {
            underline: (
              <span
                className="cursor-pointer underline"
                onClick={openHubspotChat}
              />
            ),
          })}
        </span>
        <TertiaryButton
          label={t('error.cta')}
          leftIcon={
            <FontAwesomeIcon
              icon={faArrowTurnDownRight}
              className={'text-text-01 h-6 w-6'}
            />
          }
          onClick={() => {
            setUploadProgressState(0);
            setErrorState(false);
          }}
        />
      </div>
    );
  }, [t]);

  const renderModalContent = useCallback(() => {
    if (errorState) {
      return inErrorState;
    }

    if ((uploadProgressState ?? 0) > 0) {
      return inProgressState;
    }

    return inputState;
  }, [errorState, inErrorState, inProgressState, inputState, uploadProgressState]);

  return (
    <Modal
      className="bg-surface-01 min-h-[658px] w-[720px]"
      isOpen={isOpen}
      onClose={() => {
        setErrorState(false);
        setUploadProgressState(0);
        onClose();
      }}
      title={t('title')}
    >
      {renderModalContent()}
    </Modal>
  );
};
