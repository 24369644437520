import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useNavigationDirection = () => {
  const [isBackwardNavigation, setIsBackwardNavigation] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if ((location.state as { navigationDirection?: string })?.navigationDirection === 'backwards') {
      setIsBackwardNavigation(true);
    } else {
      setIsBackwardNavigation(false);
    }
  }, [location, navigate]);

  const resetNavigationDirection = () => {
    setIsBackwardNavigation(false);
  };

  return {
    isBackwardNavigation,
    resetNavigationDirection,
  };
};
