import { Blank } from './fill-blanks';
export const generateFITBAnswersFromText = (text: string) => {
  const result = text.split(/(\{[^}]*})/).reduce(
    (acc, curr) => {
      const match: RegExpMatchArray | null = curr.match(/{([^}]*)}/);
      if (match) {
        const newBlanksIndex = acc.blankIndex + 1;
        return {
          answers: [...acc.answers, match[1]],
          parts: [...acc.parts, new Blank(acc.blankIndex.toString(), acc.blankIndex)],
          blankIndex: newBlanksIndex,
        };
      } else {
        return { ...acc, parts: [...acc.parts, curr.trim()] };
      }
    },
    {
      answers: [],
      parts: [],
      blankIndex: 0,
    } as {
      answers: string[];
      parts: (string | Blank)[];
      blankIndex: number;
    }
  );
  return { answers: result.answers, parts: result.parts };
};

export const parseFITBTextFromAnswers = (
  text: string,
  answers?: { label: string; text: string }[]
) => {
  if (!answers) {
    // Should this ever happen, do we need to log to sentry?
    return text;
  }

  const modifiedText = text.replaceAll(/\{(\d)}/g, (_, p1) => {
    let toRet = p1;

    const correspondingAnswer = answers.find((answer) => answer.label === p1);
    if (correspondingAnswer) {
      toRet = correspondingAnswer.text;
    }

    return `{${toRet}}`;
  });

  return modifiedText;
};

export const parseFillInTheBlanksParts = (text: string) =>
  text.split(/(\{\d})/).reduce(
    (acc, curr) => {
      if (curr.match(/{\d}/)) {
        const newBlanksIndex = acc.blankIndex + 1;
        return {
          parts: [...acc.parts, new Blank(curr, acc.blankIndex)],
          blankIndex: newBlanksIndex,
        };
      } else {
        return { parts: [...acc.parts, curr.trim()], blankIndex: acc.blankIndex };
      }
    },
    {
      parts: [],
      blankIndex: 0,
    } as {
      parts: (string | Blank)[];
      blankIndex: number;
    }
  ).parts;
