import { LearnerWorkBasedAction } from '@stellar-lms-frontend/lms-api';
import { BadgeColors } from '@stellar-lms-frontend/ui-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDueDate } from './use-due-date';

type WBAStatusDisplay = {
  label: string;
  color: BadgeColors;
};
export const useWBAStatus = (wba?: LearnerWorkBasedAction) => {
  const getDue = useDueDate();
  const { t } = useTranslation('translation', { keyPrefix: 'actions' });

  return useMemo(() => {
    let returnState: WBAStatusDisplay = { label: t('state.in_progress'), color: 'grey' };
    if (wba) {
      switch (wba.state) {
        case 'open':
        case 'submitted': {
          if (wba.learnerDueDate) {
            const due = getDue(wba.learnerDueDate);
            returnState =
              due.type === 'overdue'
                ? { label: due.label, color: 'red' }
                : { label: t('state.in_progress'), color: 'grey' };
          }
          break;
        }
        case 'executed': {
          returnState = { label: t('state.awaiting_review'), color: 'purple' };
          break;
        }
        case 'reviewed':
        case 'completed': {
          returnState = { label: t('state.completed'), color: 'green' };
          break;
        }
      }
    }

    return returnState;
  }, [wba, t, getDue]);
};
