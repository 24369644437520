import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend, { ChainedBackendOptions } from 'i18next-chained-backend';
import Locize from 'i18next-locize-backend';
import HttpApi from 'i18next-http-backend';

const ns = ['translation'];

const i18nNextInitOptions = {
  load: 'languageOnly' as const,
  debug: false,
  fallbackLng: 'en',
  ns,
  interpolation: {
    escapeValue: false,
  },
  backend: {
    backends: [
      Locize, // primary
      HttpApi, // fallback
    ],
    backendOptions: [
      {
        projectId: '8adc2738-b86d-4387-b3b9-28642aadc4ad',
      },
      {
        // This currently means that for the fallback we should have translation files in
        // the published assets of the app, this is handled during build, any NEW app needs to get this configured as well
        loadPath: './assets/locales/{{lng}}/{{ns}}.json',
        requestOptions: { cache: 'no-cache' },
      },
    ],
  },
};

i18next
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init<ChainedBackendOptions>(i18nNextInitOptions);

async function getLocalTranslations(
  lng: string,
  namespaces: string[]
): Promise<Record<string, any>> {
  const arr = await Promise.all(
    namespaces.map(async (n) => {
      const data = await import(`../assets/locales/${lng}/${n}.json`);
      return {
        key: n,
        value: data.default,
      };
    })
  );

  return arr.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
}

if (!process.env['NODE_ENV'] || process.env['NODE_ENV'] === 'development') {
  // IMPORTANT: This is a dev utility, it's not perfect.
  // It cannot interpolate values like {{ variable}}, so when you see those not working
  // it is to be expected for keys that don't exist on locize yet.
  getLocalTranslations('en', ns).then((localFiles) => {
    i18next.init({
      ...i18nNextInitOptions,
      parseMissingKeyHandler(missingKey) {
        const splitKey = missingKey.split(':');
        const namespace = splitKey.length > 1 ? splitKey[0] : ns[0];
        const key = splitKey.length > 1 ? splitKey[1] : splitKey[0];

        if (!localFiles) {
          return missingKey;
        }

        // Not ideal, are there alternatives? (dev only though so not that important)
        let result = localFiles[namespace];
        const keySegments = key.split('.');
        for (const keySegment of keySegments) {
          if (Object.hasOwn(result, keySegment)) {
            result = result[keySegment];
          } else {
            return missingKey;
          }
        }
        return result;
      },
    });
  });
}

export { i18next };
