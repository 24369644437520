import {
  DndContext,
  DragOverlay,
  DragOverEvent,
  DragStartEvent,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  getDataRefContainerId,
  swapInNewActivitiesContainer,
  swapInSameLearningActivitiesContainer,
} from './util.functions';
import { CourseModuleOutline, LearningActivityOutline } from '@stellar-lms-frontend/lms-graphql';
import { useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { DROP_CONTAINER_PREFIX } from './drop-container';
import { CardDuringDrag } from '@stellar-lms-frontend/ui-components';

export type SortContextActions = {
  learningActivity: {
    move: (
      moduleId: string,
      learningActivityId: string,
      input: { targetPosition: number; targetModuleId: string }
    ) => void;
  };
};

export type MoveLearningActivityFunction = (moduleId: string, learningActivityId: string) => void;

export type LearningActivitySortContextProps = {
  modules: CourseModuleOutline[];
  children: (
    learningActivitiesMap: Map<string, LearningActivityOutline[]>,
    moveUp: MoveLearningActivityFunction,
    moveDown: MoveLearningActivityFunction
  ) => React.ReactNode;
  actions?: SortContextActions;
};
export const LearningActivitySortContext: React.FC<LearningActivitySortContextProps> = ({
  modules,
  children,
  actions,
}) => {
  const [draggedLearningActivity, setDraggedLearningActivity] = useState<
    LearningActivityOutline | undefined
  >(undefined);
  const [localLearningActivities, setLocalLearningActivities] = useState<
    Map<string, LearningActivityOutline[]>
  >(new Map());
  const [originalModuleId, setOriginalModuleId] = useState<string | undefined>(undefined);

  useEffect(() => setLocalLearningActivities(createModuleActivityMap(modules)), [modules]);

  const handleDragStart = (event: DragStartEvent) => {
    const oldModuleId = getDataRefContainerId(event.active.data);
    const learningActivity = modules
      .flatMap((module) => module.learningActivities)
      .find((la) => la?.id === event.active.id);
    if (learningActivity) {
      setDraggedLearningActivity(learningActivity);
      setOriginalModuleId(oldModuleId);
    }
  };

  const handleDragCancel = () => {
    setLocalLearningActivities(createModuleActivityMap(modules));
    setDraggedLearningActivity(undefined);
    setOriginalModuleId(undefined);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!originalModuleId || !draggedLearningActivity) {
      return; // if this is the case, dragstart didn't happen
    }

    // As we already moved the learning activity in the correct position,
    // we only need to figure out which position that is to do the action on the api

    const newModuleId = getDataRefContainerId(active.data);
    if (!newModuleId) {
      Sentry.captureException(
        'No module id found in drag container when stopped dragging, while there should be one. This should never happen and might point to a dnd library issue.'
      );
      return;
    }

    const activities = localLearningActivities.get(newModuleId) ?? [];
    const newPosition = activities.findIndex((i) => i.id === active.id);

    // Activity not found in the activity list, abort
    if (newPosition === -1) {
      Sentry.captureException(
        'Stopped dragging learning activity, but learning activity did not exist in learning activity list of target.',
        {
          extra: {
            learningActivityId: over?.id,
            localLearningActivities: JSON.stringify(localLearningActivities),
            originalModuleId,
            newModuleId,
          },
        }
      );
      return;
    }

    actions?.learningActivity.move(originalModuleId, draggedLearningActivity.id, {
      targetModuleId: newModuleId,
      targetPosition: newPosition,
    });

    setDraggedLearningActivity(undefined);
    setOriginalModuleId(undefined);
  };

  const handleDragOver = ({ active, over, delta }: DragOverEvent) => {
    if (!draggedLearningActivity || !originalModuleId) {
      return; // if this is the case, dragstart didn't happen
    }

    const currentModuleId = getDataRefContainerId(active.data);
    const newModuleId = getDataRefContainerId(over?.data);

    // If we are not over a different droppable container we should abort
    if (!currentModuleId || !newModuleId) {
      return;
    }

    const isOverDropContainer = over?.id.toString().startsWith(DROP_CONTAINER_PREFIX);

    const activities = localLearningActivities.get(newModuleId) ?? [];
    let newPosition = 0;
    if (isOverDropContainer) {
      newPosition = delta.y < 0 ? activities.length : 0;
    } else if (activities.length > 0) {
      newPosition = activities.findIndex((i) => i.id === over?.id);
      if (newPosition === -1) {
        return;
      }
    }

    if (currentModuleId === newModuleId) {
      if (!isOverDropContainer && activities.find((a) => a.id === active.id)) {
        setLocalLearningActivities((previous) =>
          swapInSameLearningActivitiesContainer(
            previous,
            activities,
            active,
            newModuleId,
            newPosition
          )
        );
      }
    } else {
      setLocalLearningActivities((previous) =>
        swapInNewActivitiesContainer(
          previous,
          active,
          draggedLearningActivity,
          currentModuleId,
          newModuleId,
          newPosition
        )
      );
    }
  };

  const findModuleIndex = useCallback(
    (moduleId: string) => modules.findIndex((module) => module.id === moduleId),
    [modules]
  );

  const moveUp = useCallback(
    (moduleId: string, learningActivityId: string) => {
      const activities = localLearningActivities.get(moduleId);
      if (!activities) return;

      const index = activities.findIndex((la) => la.id === learningActivityId);
      if (index < 0) return;

      if (index === 0) {
        const moduleIndex = findModuleIndex(moduleId);
        if (moduleIndex > 0) {
          const prevModuleId = modules[moduleIndex - 1].id;
          const prevModuleActivities = localLearningActivities.get(prevModuleId);
          const destinationIndex = prevModuleActivities ? prevModuleActivities.length : 0;

          setLocalLearningActivities((prev) =>
            swapInNewActivitiesContainer(
              prev,
              { id: learningActivityId },
              activities[index],
              moduleId,
              prevModuleId,
              destinationIndex
            )
          );

          actions?.learningActivity.move(moduleId, learningActivityId, {
            targetModuleId: prevModuleId,
            targetPosition: destinationIndex,
          });
        }
      } else {
        setLocalLearningActivities((prev) =>
          swapInSameLearningActivitiesContainer(
            prev,
            activities,
            { id: learningActivityId },
            moduleId,
            index - 1
          )
        );

        actions?.learningActivity.move(moduleId, learningActivityId, {
          targetModuleId: moduleId,
          targetPosition: index - 1,
        });
      }
    },
    [findModuleIndex, localLearningActivities, modules, actions]
  );

  const moveDown = useCallback(
    (moduleId: string, learningActivityId: string) => {
      const activities = localLearningActivities.get(moduleId);
      if (!activities) return;

      const index = activities.findIndex((la) => la.id === learningActivityId);
      if (index < 0) return;

      if (index === activities.length - 1) {
        const moduleIndex = findModuleIndex(moduleId);
        if (moduleIndex < modules.length - 1) {
          const nextModuleId = modules[moduleIndex + 1].id;

          setLocalLearningActivities((prev) =>
            swapInNewActivitiesContainer(
              prev,
              { id: learningActivityId },
              activities[index],
              moduleId,
              nextModuleId,
              0
            )
          );

          actions?.learningActivity.move(moduleId, learningActivityId, {
            targetModuleId: nextModuleId,
            targetPosition: 0,
          });
        }
      } else {
        setLocalLearningActivities((prev) =>
          swapInSameLearningActivitiesContainer(
            prev,
            activities,
            { id: learningActivityId },
            moduleId,
            index + 1
          )
        );

        actions?.learningActivity.move(moduleId, learningActivityId, {
          targetModuleId: moduleId,
          targetPosition: index + 1,
        });
      }
    },
    [findModuleIndex, localLearningActivities, modules, actions]
  );

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDragCancel={handleDragCancel}
    >
      <>
        {children(localLearningActivities, moveUp, moveDown)}
        <DragOverlay>
          {draggedLearningActivity ? (
            <CardDuringDrag
              title={draggedLearningActivity.title ?? ''}
              normalColor={draggedLearningActivity.placeholder ? 'purple' : 'default'}
            />
          ) : null}
        </DragOverlay>
      </>
    </DndContext>
  );
};

const createModuleActivityMap = (modules: CourseModuleOutline[]) => {
  const learningActivityMap = new Map();
  modules.forEach((module) => {
    learningActivityMap.set(module.id, module.learningActivities);
  });
  return learningActivityMap;
};
