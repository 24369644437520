import { InProgressIcon } from '@stellar-lms-frontend/ui-components';
import { ModuleProgressBar } from './module-progress-bar';
import React, { useEffect, useRef } from 'react';

type Module = {
  id?: string | null;
  title?: string | null;
  description?: string | null;
  stats?: {
    startedPercentage: number;
    completedPercentage: number;
  };
};

export type CourseModuleListProps = {
  modules: Module[];
  moduleClick?: (moduleId: string) => void;
  loadingMoreModules?: { loading: boolean; amount: number };
};

enum Stats {
  DONE,
  IN_PROGRESS,
  TODO,
}

const getStats = (module: Module): Stats => {
  if (module.stats?.completedPercentage === 100) {
    return Stats.DONE;
  }
  if ((module.stats?.startedPercentage ?? 0) > 0) {
    return Stats.IN_PROGRESS;
  }
  return Stats.TODO;
};

export const CourseModuleList: React.FC<CourseModuleListProps> = ({
  modules,
  moduleClick = (_) => null,
}) => {
  const titleRefs = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    let maxWidth = 0;

    titleRefs.current.forEach((node) => {
      const nodeWidth = node.getBoundingClientRect().width;
      if (nodeWidth > maxWidth) {
        maxWidth = nodeWidth;
      }
    });

    titleRefs.current.forEach((node) => {
      node.style.width = `${maxWidth}px`;
    });
  }, [modules]);

  return (
    <ul>
      {modules.map((m, i) => (
        <li
          key={m.id}
          className="group relative flex cursor-pointer"
          onClick={() => m.id && moduleClick(m.id)}
        >
          <span className="relative mr-2 flex w-6 shrink-0 flex-col items-center">
            <i className="bg-surface-02-hover -mt-[14px] w-[2px] grow group-first:bg-transparent"></i>
            <StatsIcon stats={getStats(m)} />
            <i className="bg-surface-02-hover -mb-[14px] w-[2px] grow group-last:bg-transparent"></i>
          </span>
          <div
            tabIndex={0}
            className="hover:bg-surface-03 focus:border-primary-02 active:bg-surface-02 grow
            rounded-2xl border-[1px] border-transparent py-3 px-4 active:border-transparent"
          >
            <p
              ref={(el) => el && (titleRefs.current[i] = el)}
              className={`type-body-medium text-text-01 mb-1 max-w-[320px] shrink-0 overflow-hidden text-ellipsis whitespace-nowrap`}
            >
              {m.title}
            </p>
            {m.description && (
              <p className="font-lexend text-text-02 type-small line-clamp-2 text-ellipsis">
                {m.description}
              </p>
            )}
            {m.stats && (
              <ModuleProgressBar
                startedPercentage={m.stats.startedPercentage}
                completedPercentage={m.stats.completedPercentage}
                className="mt-4 grow"
              />
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

type StatsIconProps = {
  stats: Stats;
};

const StatsIcon: React.FC<StatsIconProps> = ({ stats }) => {
  const baseClass = 'my-1 flex h-4 w-4 shrink-0 justify-center rounded-full';
  switch (stats) {
    case Stats.DONE:
      return <i className={`${baseClass} bg-primary-02`} />;
    case Stats.IN_PROGRESS:
      return (
        <i className={`${baseClass} border-border-01 items-center border-2`}>
          <InProgressIcon className="fill-border-01 w-4" />
        </i>
      );
    default:
      return <i className={`${baseClass} border-border-01 border-2`} />;
  }
};
