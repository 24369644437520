import { LoadingCard } from '@stellar-lms-frontend/ui-components';

type ModuleSidebarLoadingCardProps = {
  className?: string;
  childrenClassName?: string;
  title: string;
  description: string;
};

export const ModuleSidebarLoadingCard: React.FC<ModuleSidebarLoadingCardProps> = ({
  title,
  description,
  className = '',
  childrenClassName = '',
}) => {
  return (
    <LoadingCard
      className={className}
      childrenClassName={childrenClassName}
      title={title}
      hasShadow={true}
    >
      <span className="font-lexend font-regular text-xs">{description}</span>
    </LoadingCard>
  );
};
