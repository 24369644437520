import { ParagraphComponent } from './paragraph-component';
import { v4 as uuidv4 } from 'uuid';
import { Alignment } from '../types';
import ReactDOM from 'react-dom';
import { API, BlockToolConstructorOptions, BlockToolData, ToolConfig } from '@editorjs/editorjs';
import { renderToString } from 'react-dom/server';
import { TIcon } from '../../../icons';
import { BlockEditorTypesEnum } from '../../block-editor';

export interface ParagraphData extends BlockToolData {
  text: string;
  alignment: Alignment;
}

interface ParagraphConfig extends ToolConfig {
  defaultAlignment: Alignment;
  placeholder: string;
}

export class Paragraph {
  api: API;
  data: BlockToolData<ParagraphData>;
  readOnly: boolean;
  element: HTMLElement;
  placeholder: string;

  static get DEFAULT_PLACEHOLDER() {
    return '';
  }

  constructor({
    data,
    api,
    config,
    readOnly,
  }: BlockToolConstructorOptions<ParagraphData, ParagraphConfig>) {
    this.data = {
      text: data.text || '',
      alignment: data.alignment || config?.defaultAlignment || Alignment.LEFT,
    };
    this.api = api;
    this.readOnly = readOnly;
    this.placeholder = config?.placeholder ?? Paragraph.DEFAULT_PLACEHOLDER;

    this.element = this.createElement();
    this.setAlignment = this.setAlignment.bind(this);
  }

  static get toolbox() {
    return {
      title: BlockEditorTypesEnum.PARAGRAPH,
      icon: renderToString(<TIcon />),
    };
  }

  static get inlineToolbar() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  createElement() {
    const rootNode = document.createElement('div');
    rootNode.id = uuidv4();
    return rootNode;
  }

  updateElement() {
    // Calling ReactDOM will generate errors on the console, it should be replaced with createRoot.
    // But doing that will lead to error on EditorJs side (eg. Press Enter won't create a new block)
    ReactDOM.render(
      <ParagraphComponent
        isReadOnly={this.readOnly}
        data={this.data}
        onChange={(newData: ParagraphData) => (this.data = newData)}
        placeholder={this.placeholder}
      />,
      this.element
    );
  }

  setAlignment(alignment: Alignment) {
    this.data = {
      ...this.data,
      alignment: alignment,
    };

    this.updateElement();
  }

  // renderSettings() {
  //   const alignmentSettings = getAlignmentSettings(this.setAlignment);

  //   return [...alignmentSettings];
  // }

  render() {
    this.updateElement();
    return this.element;
  }

  save(blockContent: HTMLElement): BlockToolData<ParagraphData> {
    return {
      text: blockContent.innerHTML,
      alignment: this.data.alignment,
    };
  }

  static get pasteConfig() {
    return {
      tags: ['P'],
    };
  }

  onPaste(event: CustomEvent) {
    this.data = { ...this.data, text: event.detail.data.innerHTML };
    this.updateElement();
  }

  static get sanitize() {
    return {
      text: {
        br: true,
      },
    };
  }
}
