import { SurveyAnswerMap, TypeMap } from '@stellar-lms-frontend/lms-api';
import {
  AblyChannelSubscriber,
  ConfirmationModal,
  LearningActivity,
  openHubspotChat,
  typemapToSurveyQuestionUserAnswers,
  USE_COURSE_OUTLINE,
  WBA,
} from '@stellar-lms-frontend/lms-components';
import { useCallback, useMemo, useState } from 'react';
import {
  IfDefinedWrapper,
  LinkButton,
  ModalButtonProps,
  PrimaryButton,
  SecondaryButton,
} from '@stellar-lms-frontend/ui-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import {
  actionGeneration,
  createAssessmentStep,
  createBlockContentItem,
  createBlockContentStep,
  createContentStep,
  createScenarioStep,
  createSurveyStep,
  createWBAStep,
  deleteStep,
  fetchAssessmentStep,
  fetchBlockContentStep,
  fetchScenarioStep,
  fetchSuggestedQuestions,
  fetchSurveyStep,
  fetchWBAStep,
  getActionsSuggestions,
  getFileUrl,
  fetchSuggestedContentEntities,
  fetchSuggestedImages,
  getUploadUrl,
  markSuggestedActionAsAccepted,
  markSuggestedQuestionAsAccepted,
  questionGeneration,
  startContentEntitiesGeneration,
  updateAssessmentStep,
  updateBlockContentItem,
  updateBlockContentStep,
  updateContentStep,
  updateLearningActivityStepsOrder,
  updateScenarioStep,
  updateSurveyStep,
  updateWBAStep,
  uploadFile,
  fetchSuggestedScenarios,
  scenarioGeneration,
  fetchRelatedStepSuggestions,
  relatedContentGeneration,
  relatedStepSuggestionGeneration,
  analyseArticleUrlForEditor,
  searchStepVideos,
  searchStepArticles,
  startVideoSearchTermGeneration,
  startArticleSearchTermGeneration,
  getSuggestedArticleSearchTerm,
  getSuggestedVideoSearchTerm,
  fetchDefaultHeaderImage,
} from './designer-learning-activity.api';
import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import { useLocation } from 'react-router-dom';
import {
  LearningActivityType,
  createDiscussionItem,
  getCourseOutline,
  likeDiscussionItem,
  unlikeDiscussionItem,
  useCurrentUser,
  useMarkTipViewed,
  LearnerWorkBasedActionSubmitInput,
  useCurrentCompany,
  useCurrentCourse,
  getLightCourseInfo,
  LearningActivityStepSourceType,
} from '@stellar-lms-frontend/lms-graphql';
import { graphQLClient } from '../../../lib/graphql';
import {
  useDeleteLearningActivity,
  useDuplicateLearningActivity,
  useUpdateLearningActivity,
} from '../../../api/learning-activity.api';
import { DESIGNER_JOURNEY_ROUTE } from '../constants/routes';
import { GEARTemplateModal } from '../gear-template-modal/gear-template-modal';
import {
  getOrCreateLearnerWorkBasedAction,
  submitLearnerWorkBasedAction,
} from '../work-based-action/work-based-action.api';
import { getLearningActivityStepById } from '../../learning-activity/learning-activity.api';
import {
  evaluateAssessmentQuestion,
  getAssessmentById,
  getDiscussionItems,
  getLastAssessmentSubmission,
  getLastSurveySubmission,
  getSurveyById,
  submitAnswers,
  submitPartialAssessment,
  trackCourseEvent,
} from '../../learning-activity/learner-learning-activity-detail-page.api';
import { Message } from 'ably';

type ConfigModalArgs =
  | {
    type: 'delete-learning-activity';
    courseId: string;
    moduleId: string;
    learningActivityId: string;
  }
  | {
    type: 'delete-step';
    learningActivityId: string;
    stepId: string;
  }
  | {
    type: 'unsaved-changes';
    callback: VoidFunc;
    saveFunc?: () => Promise<void>;
  };

type ModalConfig = {
  title: string;
  description: string;
  buttons: ModalButtonProps;
};

const getDummyAssessmentSubmission = (assessmentId: string, answers: TypeMap<SurveyAnswerMap>) => {
  return {
    assessment: { id: assessmentId, title: '', href: '' },
    answers,
  };
};

export const DesignerLearningActivityDetailPage = () => {
  const { t } = useTranslation('translation');
  const { t: tLaActions } = useTranslation('translation', {
    keyPrefix: 'learning-activity-actions',
  });
  const { t: tLaEdit } = useTranslation('translation', { keyPrefix: 'learning-activity-edit' });
  const { t: tValidation } = useTranslation('translation', { keyPrefix: 'validation' });
  const { t: tError403 } = useTranslation('translation', { keyPrefix: '403' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const location = useLocation();
  const [isEditing, setIsEditing] = useState(location?.state?.isEditing ?? false);
  const [modalConfig, setModalConfig] = useState<ModalConfig>();
  const queryClient = useQueryClient();
  const { courseId, learningActivityId } = useParams();
  const navigate = useNavigate();
  const [saveFunc, setSaveFunc] = useState<() => Promise<void>>();
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isGEARModalOpen, setIsGEARModalOpen] = useState(false);

  const {
    query: { data: currentCourse },
  } = useCurrentCourse(
    (courseId: string) => getLightCourseInfo(graphQLClient(), { courseId }),
    courseId
  );

  const {
    query: { data: company },
  } = useCurrentCompany(graphQLClient());

  const { query: userQuery } = useCurrentUser(graphQLClient('designer'));
  const markTipViewedMutation = useMarkTipViewed(graphQLClient('designer'));

  const { mutate: deleteStepMutation } = useMutation(
    (variables: { learningActivityId: string; stepId: string }) => deleteStep(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USE_COURSE_OUTLINE, courseId]);
        setModalConfig(undefined);
        toast.success(t('learning-activity-overview.delete.success'));
      },
    }
  );

  const ablyCallback = useCallback(
    (message: Message) => {
      console.log('Received message from Ably:', message);
      if (message.data.type === 'Module') {
        queryClient.invalidateQueries([USE_COURSE_OUTLINE, courseId]);
      }
    },
    [courseId, queryClient]
  );

  const refreshData = useCallback(
    async (stepId: string, shouldNavigate: boolean) => {
      await Promise.all([
        queryClient.invalidateQueries([USE_COURSE_OUTLINE, courseId]),
        queryClient.invalidateQueries([learningActivityId, stepId]),
        queryClient.invalidateQueries([LearningActivity.LEARNING_ACTIVITY_STEP, stepId]),
      ]);
      shouldNavigate && navigate(LearningActivity.LEARNING_ACTIVITY_STEP_ROUTE(stepId));
    },
    [courseId, navigate, queryClient, learningActivityId]
  );

  const createContentStepMutation = useMutation(createContentStep, {
    onSuccess: (data) => {
      refreshData(data.createContentStep.id, true);
      toast.success(t('learning-activity-overview.url.create-success'));
    },
  });

  const updateContentStepMutation = useMutation(updateContentStep, {
    onSuccess: (data) => {
      refreshData(data.updateContentStep.id, false);
      toast.success(t('learning-activity-overview.url.update-success'));
    },
  });

  const createBlockContentItemMutation = useMutation(createBlockContentItem, {
    onSuccess: (data) => {
      refreshData(data.createBlockContentItem.id, false);
      toast.success(t('learning-activity-overview.block.create-success'));
    },
  });

  const createBlockContentStepMutation = useMutation(createBlockContentStep, {
    onSuccess: (data) => {
      refreshData(data.createBlockContentStep.id, true);
      toast.success(t('learning-activity-overview.block.create-success'));
    },
  });

  const updateBlockContentItemMutation = useMutation(updateBlockContentItem, {
    onSuccess: (data) => {
      refreshData(data.updateBlockContentItem.id, false);
      toast.success(t('learning-activity-overview.block.update-success'));
    },
  });

  const updateBlockContentStepMutation = useMutation(updateBlockContentStep, {
    onSuccess: (data) => {
      refreshData(data.updateBlockContentStep.id, false);
      toast.success(t('learning-activity-overview.block.update-success'));
    },
  });

  const createSurveyStepMutation = useMutation(createSurveyStep, {
    onSuccess: (data) => {
      refreshData(data.createSurveyStep.id, true);
      toast.success(t('learning-activity-overview.survey.create-success'));
    },
  });

  const updateSurveyStepMutation = useMutation(updateSurveyStep, {
    onSuccess: (data) => {
      refreshData(data.updateSurveyStep.id, false);
      toast.success(t('learning-activity-overview.survey.update-success'));
    },
  });

  const createAssessmentStepMutation = useMutation(createAssessmentStep, {
    onSuccess: (data) => {
      refreshData(data.createQuizStep.id, true);
      toast.success(t('learning-activity-overview.assessment.create-success'));
    },
  });

  const updateAssessmentStepMutation = useMutation(updateAssessmentStep, {
    onSuccess: (data) => {
      refreshData(data.updateQuizStep.id, false);
      toast.success(t('learning-activity-overview.assessment.update-success'));
    },
  });

  const createWBAStepMutation = useMutation(createWBAStep, {
    onSuccess: (data) => {
      refreshData(data.createWBAStep.id, true);

      toast.success(t('learning-activity-overview.wba.create-success'));
    },
  });

  const updateWBAStepMutation = useMutation(updateWBAStep, {
    onSuccess: (data) => {
      refreshData(data.updateWBAStep.id, false);
      queryClient.invalidateQueries([
        WBA.QueryKeys.LEARNER_WBA_BY_SOURCE_ID,
        data.updateWBAStep.id,
        data.updateWBAStep.sourceId,
      ]);
      toast.success(t('learning-activity-overview.wba.update-success'));
    },
  });

  const createScenarioStepMutation = useMutation(createScenarioStep, {
    onSuccess: (data) => {
      refreshData(data.createScenarioStep.id, true);
      toast.success(t('learning-activity-overview.scenario.create-success'));
    },
  });

  const updateScenarioStepMutation = useMutation(updateScenarioStep, {
    onSuccess: (data) => {
      refreshData(data.updateScenarioStep.id, false);
      toast.success(t('learning-activity-overview.scenario.update-success'));
    },
  });

  const updateStepsOrder = useMutation(updateLearningActivityStepsOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries([USE_COURSE_OUTLINE, courseId]);
    },
  });

  const startRelatedContentStepGeneration = useMutation(relatedContentGeneration, {
    onSuccess: () => {
      queryClient.invalidateQueries([USE_COURSE_OUTLINE, courseId]);
    },
  });
  const startRelatedStepSuggestionGeneration = useMutation(relatedStepSuggestionGeneration);
  const startVideoSearchTermGenerationMutation = useMutation(startVideoSearchTermGeneration);
  const startArticleSearchTermGenerationMutation = useMutation(startArticleSearchTermGeneration);

  const { mutate: deleteLearningActivityMutate } = useDeleteLearningActivity({
    onSuccess: () => toast.success(tLaActions('delete.success')),
  });
  const updateLearningActivityMutation = useUpdateLearningActivity({
    onSuccess: () => toast.success(tLaActions('update-success')),
  });
  const duplicateLearningActivityMutation = useDuplicateLearningActivity({
    onSuccess: () => toast.success(tLaActions('duplicate-success')),
  });

  const navigateToParentModule = useCallback(
    (courseId: string, moduleId: string) => {
      navigate(DESIGNER_JOURNEY_ROUTE(courseId) + '?moduleId=' + moduleId, {
        state: { isEditing: true },
      });
    },
    [navigate]
  );

  const configModal = useCallback(
    (args: ConfigModalArgs) => {
      switch (args.type) {
        case 'delete-learning-activity':
          setModalConfig({
            title: tLaActions('delete.title'),
            description: tLaActions('delete.description'),
            buttons: {
              hasShadow: false,
              buttons: [
                <PrimaryButton
                  key={1}
                  theme="red"
                  label={tLaActions('delete.cta-delete')}
                  onClick={() => {
                    deleteLearningActivityMutate({
                      learningActivityId: args.learningActivityId,
                      courseId: args.courseId,
                      moduleId: args.moduleId,
                    });
                    navigateToParentModule(args.courseId, args.moduleId);
                  }}
                />,
                <LinkButton
                  buttonStyle="fit"
                  key={2}
                  onClick={() => setModalConfig(undefined)}
                  label={t('general.cancel')}
                />,
              ],
            },
          });
          break;
        case 'delete-step':
          setModalConfig({
            title: t('learning-activity-overview.delete.title'),
            description: t('learning-activity-overview.delete.description'),
            buttons: {
              hasShadow: false,
              buttons: [
                <PrimaryButton
                  key={1}
                  theme="red"
                  label={t('learning-activity-overview.delete.cta-delete')}
                  onClick={() => {
                    deleteStepMutation({
                      learningActivityId: args.learningActivityId,
                      stepId: args.stepId,
                    });
                  }}
                />,
                <LinkButton
                  buttonStyle="fit"
                  key={2}
                  onClick={() => setModalConfig(undefined)}
                  label={t('general.cancel')}
                />,
              ],
            },
          });
          break;
        case 'unsaved-changes':
          setModalConfig({
            description: t('learning-activity-overview.unsaved-changes-alert.description'),
            title: t('learning-activity-overview.unsaved-changes-alert.title'),
            buttons: {
              hasShadow: false,
              buttons: [
                <PrimaryButton
                  key={1}
                  label={t('general.save')}
                  onClick={async (e) => {
                    e?.stopPropagation();
                    e?.preventDefault();
                    args.saveFunc?.();
                    setModalConfig(undefined);
                    isValid && args.callback();
                  }}
                />,
                <SecondaryButton
                  key={2}
                  theme="red"
                  label={t('general.discard')}
                  onClick={(e) => {
                    e?.stopPropagation();
                    e?.preventDefault();
                    setModalConfig(undefined);
                    args.callback();
                  }}
                />,
                <LinkButton
                  buttonStyle="fit"
                  key={3}
                  onClick={() => setModalConfig(undefined)}
                  label={t('general.cancel')}
                />,
              ],
            },
          });
          break;
      }
    },
    [
      deleteLearningActivityMutate,
      deleteStepMutation,
      isValid,
      navigateToParentModule,
      t,
      tLaActions,
    ]
  );

  const actions: LearningActivity.Actions = useMemo(
    () => ({
      upload: {
        getUploadInformation: async (fileName: string, mimeType: string) => {
          const result = await getUploadUrl({ fileName, mimeType, companyId: company?.id ?? '' });
          return {
            fileId: result.fileId ?? undefined,
            presignedUrl: result.url ?? undefined,
          };
        },
        uploadFile: uploadFile,
        getFileUrl: async (fileId: string) => {
          const result = await getFileUrl({ fileId, companyId: company?.id ?? '' });
          return {
            fileId: result.fileId ?? undefined,
            presignedUrl: result.url ?? undefined,
          };
        },
      },
      learningActivity: {
        delete: ({ courseId, learningActivityId, moduleId }) =>
          configModal({
            type: 'delete-learning-activity',
            learningActivityId,
            moduleId,
            courseId,
          }),
        update: updateLearningActivityMutation.mutateAsync,
        duplicate: duplicateLearningActivityMutation.mutateAsync,
      },
      edit: {
        onLeave: (callback, saveFunc) =>
          configModal({ type: 'unsaved-changes', callback, saveFunc }),
        onNavigateModule: (courseId, moduleId) => {
          setIsEditing(false);
          navigateToParentModule(courseId, moduleId);
        },
        markTipViewed: markTipViewedMutation.mutate,
      },
      tracking: {
        saveCourseEvent: (data) => trackCourseEvent(graphQLClient('designer'), data),
      },
      step: {
        delete: (learningActivityId, stepId) =>
          configModal({ type: 'delete-step', learningActivityId, stepId }),
        updateOrder: updateStepsOrder.mutateAsync,
        onChange: (callback, saveFunc) =>
          configModal({ type: 'unsaved-changes', callback, saveFunc }),
        onCreate: (callback, saveFunc) =>
          configModal({ type: 'unsaved-changes', callback, saveFunc }),
        getLearningActivityStep: (learningActivityId: string, stepId: string) =>
          getLearningActivityStepById(graphQLClient('designer'), learningActivityId, stepId),
      },
      iframe: {
        create: createContentStepMutation.mutateAsync,
        update: updateContentStepMutation.mutateAsync,
      },
      block: {
        createItem: createBlockContentItemMutation.mutateAsync,
        createStep: createBlockContentStepMutation.mutateAsync,
        updateItem: updateBlockContentItemMutation.mutateAsync,
        updateStep: updateBlockContentStepMutation.mutateAsync,
        fetch: fetchBlockContentStep,
        fetchSuggestedContentEntities,
        fetchSuggestedImages,
        startContentEntitiesGeneration,
        analyseArticleUrl: (url: string, courseId: string) =>
          analyseArticleUrlForEditor({ url, courseId }),
        searchStepVideos: (courseId: string, searchQuery: string) =>
          searchStepVideos({ courseId, search: searchQuery }),
        startVideoSearchTermGeneration: (
          courseId: string,
          moduleId: string,
          learningActivityId: string,
          learningActivityStepId: string
        ) =>
          startVideoSearchTermGenerationMutation.mutateAsync({
            input: { courseId, moduleId, learningActivityId, learningActivityStepId },
          }),
        getSuggestedVideoSearchTerm: (
          courseId: string,
          moduleId: string,
          learningActivityId: string,
          learningActivityStepId: string
        ) =>
          getSuggestedVideoSearchTerm({
            courseId,
            moduleId,
            learningActivityId,
            learningActivityStepId,
          }),
        searchStepArticles: (courseId: string, searchQuery: string) =>
          searchStepArticles({ courseId, search: searchQuery }),
        startArticleSearchTermGeneration: (
          courseId: string,
          moduleId: string,
          learningActivityId: string,
          learningActivityStepId: string
        ) =>
          startArticleSearchTermGenerationMutation.mutateAsync({
            input: { courseId, moduleId, learningActivityId, learningActivityStepId },
          }),
        getSuggestedArticleSearchTerm: (
          courseId: string,
          moduleId: string,
          learningActivityId: string,
          learningActivityStepId: string
        ) =>
          getSuggestedArticleSearchTerm({
            courseId,
            moduleId,
            learningActivityId,
            learningActivityStepId,
          }),
      },
      openSupport: openHubspotChat,
      survey: {
        create: createSurveyStepMutation.mutateAsync,
        update: updateSurveyStepMutation.mutateAsync,
        fetch: fetchSurveyStep,
        getDefaultHeaderImage: (courseId: string) =>
          fetchDefaultHeaderImage({ type: LearningActivityStepSourceType.Survey, courseId }),
      },
      assessment: {
        create: createAssessmentStepMutation.mutateAsync,
        update: updateAssessmentStepMutation.mutateAsync,
        getDefaultHeaderImage: (courseId: string) =>
          fetchDefaultHeaderImage({ type: LearningActivityStepSourceType.Assessment, courseId }),
        fetch: fetchAssessmentStep,
        fetchSuggestedQuestions,
        questionGeneration: questionGeneration,
        markSuggestedQuestionAsAccepted,
      },
      scenario: {
        create: createScenarioStepMutation.mutateAsync,
        update: updateScenarioStepMutation.mutateAsync,
        fetch: fetchScenarioStep,
        scenarioGeneration: scenarioGeneration,
        fetchSuggestedScenarios: fetchSuggestedScenarios,
        getDefaultHeaderImage: (courseId: string) =>
          fetchDefaultHeaderImage({ type: LearningActivityStepSourceType.Scenario, courseId }),
      },
      wba: {
        fetch: (learningActivityId: string, stepId: string) =>
          fetchWBAStep({ learningActivityId, stepId }),
        create: createWBAStepMutation.mutateAsync,
        update: updateWBAStepMutation.mutateAsync,
        actionGeneration: actionGeneration,
        fetchSuggestedActions: getActionsSuggestions,
        markSuggestedActionAsAccepted,
        getDefaultHeaderImage: (courseId: string) =>
          fetchDefaultHeaderImage({ type: LearningActivityStepSourceType.Wba, courseId }),
      },
      modal: {
        openGEARModal: () => setIsGEARModalOpen(true),
      },
      course: {
        getCourseOutline: (input: { courseId: string }) =>
          getCourseOutline(graphQLClient('designer'), input),
        fetchRelatedStepSuggestions: (
          courseId: string,
          moduleId: string,
          learningActivityId: string,
          nrPerPage: number
        ) => fetchRelatedStepSuggestions({ courseId, moduleId, learningActivityId, nrPerPage }),
        startRelatedStepSuggestionGeneration: (
          courseId: string,
          moduleId: string,
          learningActivityId: string
        ) =>
          startRelatedStepSuggestionGeneration.mutateAsync({
            input: { courseId, moduleId, learningActivityId },
          }),
        startRelatedContentStepGeneration: (
          courseId: string,
          moduleId: string,
          learningActivityId: string,
          suggestionId: string,
          suggestionTitle: string,
          suggestionDescription: string
        ) =>
          startRelatedContentStepGeneration.mutateAsync({
            input: {
              courseId,
              moduleId,
              learningActivityId,
              suggestedRelatedContent: {
                id: suggestionId,
                title: suggestionTitle,
                description: suggestionDescription,
              },
            },
          }),
      },
      publishedSurvey: {
        getLastSurveySubmission: (learningActivityId: string) =>
          getLastSurveySubmission(graphQLClient('designer'), learningActivityId),
        getSurveyById: (sourceId: string, stepId: string) =>
          getSurveyById(graphQLClient('designer'), sourceId, stepId),
        saveAnswers: (
          learningActivityStepId: string,
          publishedSurveyId: string,
          answers: TypeMap<SurveyAnswerMap>
        ) =>
          submitAnswers(
            graphQLClient('designer'),
            learningActivityStepId,
            publishedSurveyId,
            typemapToSurveyQuestionUserAnswers(answers)
          ).then(),
      },
      publishedAssessment: {
        evaluate: (
          learningActivityStepId: string,
          assessmentId: string,
          questionId: string,
          answer: TypeMap<SurveyAnswerMap>
        ) =>
          evaluateAssessmentQuestion(
            graphQLClient('designer'),
            learningActivityStepId,
            questionId,
            assessmentId,
            getDummyAssessmentSubmission(assessmentId, answer)
          ),
        getAssessmentById: (assessmentId: string, learningActivityId: string) =>
          getAssessmentById(graphQLClient('designer'), assessmentId, learningActivityId),
        getLastAssessmentSubmission: (learningActivityStepId: string, assessmentId: string) =>
          getLastAssessmentSubmission(graphQLClient('designer'), {
            learningActivityStepId,
            assessmentId,
          }),
        submitPartialAnswers: (
          learningActivityStepId: string,
          answers: TypeMap<SurveyAnswerMap>,
          assessmentId: string
        ) =>
          submitPartialAssessment(
            graphQLClient('designer'),
            learningActivityStepId,
            getDummyAssessmentSubmission(assessmentId, answers)
          ).then(),
      },
      publishedScenario: {
        evaluate: (
          learningActivityStepId: string,
          scenarioId: string,
          levelId: string,
          answer: TypeMap<SurveyAnswerMap>
        ) =>
          evaluateAssessmentQuestion(
            graphQLClient('designer'),
            learningActivityStepId,
            levelId,
            scenarioId,
            getDummyAssessmentSubmission(scenarioId, answer)
          ),
        getScenarioById: (scenarioId: string, learningActivityId: string) =>
          getAssessmentById(graphQLClient('designer'), scenarioId, learningActivityId),
        getLastScenarioSubmission: (learningActivityStepId: string, scenarioId: string) =>
          getLastAssessmentSubmission(graphQLClient('designer'), {
            learningActivityStepId,
            assessmentId: scenarioId,
          }),
      },
      learnerWba: {
        fetchLearnerWorkBasedActionById: (sourceId: string, stepId: string) =>
          getOrCreateLearnerWorkBasedAction(graphQLClient('designer'), sourceId, stepId),
        submitLearnerWorkBasedAction: (id: string, input: LearnerWorkBasedActionSubmitInput) =>
          submitLearnerWorkBasedAction(graphQLClient('designer'), id, input),
      },
      discussion: {
        createComment: (learningActivityId: string, stepId: string, comment: string) =>
          createDiscussionItem(graphQLClient('designer'), learningActivityId, stepId, comment),
        getDiscussionItems: (learningActivityId: string, stepId: string, offset: number) =>
          getDiscussionItems(graphQLClient('designer'), learningActivityId, stepId, offset),
        like: (learningActivityId: string, stepId: string, commentId: string) =>
          likeDiscussionItem(
            graphQLClient('designer'),
            learningActivityId,
            stepId,
            commentId
          ).then(),
        unlike: (learningActivityId: string, stepId: string, commentId: string) =>
          unlikeDiscussionItem(
            graphQLClient('designer'),
            learningActivityId,
            stepId,
            commentId
          ).then(),
        reply: (learningActivityId: string, stepId: string, reply: string, commentId: string) =>
          createDiscussionItem(
            graphQLClient('designer'),
            learningActivityId,
            stepId,
            reply,
            commentId
          ).then(),
      },
    }),
    // Do not add startRelatedContentStepGeneration or startRelatedStepSuggestionGeneration here (they are added with mutateAsync, but the linter is not happy).
    // If you add them you will get bugs.
    [
      configModal,
      updateLearningActivityMutation.mutateAsync,
      duplicateLearningActivityMutation.mutateAsync,
      markTipViewedMutation.mutate,
      updateStepsOrder.mutateAsync,
      createContentStepMutation.mutateAsync,
      updateContentStepMutation.mutateAsync,
      createBlockContentItemMutation.mutateAsync,
      createBlockContentStepMutation.mutateAsync,
      updateBlockContentItemMutation.mutateAsync,
      updateBlockContentStepMutation.mutateAsync,
      createSurveyStepMutation.mutateAsync,
      updateSurveyStepMutation.mutateAsync,
      createAssessmentStepMutation.mutateAsync,
      updateAssessmentStepMutation.mutateAsync,
      createScenarioStepMutation.mutateAsync,
      updateScenarioStepMutation.mutateAsync,
      createWBAStepMutation.mutateAsync,
      updateWBAStepMutation.mutateAsync,
      startVideoSearchTermGenerationMutation.mutateAsync,
      startArticleSearchTermGenerationMutation.mutateAsync,
      company?.id,
      navigateToParentModule,
      startRelatedContentStepGeneration.mutateAsync,
      startRelatedStepSuggestionGeneration.mutateAsync,
    ]
  );

  const overviewPageProps: LearningActivity.LearningActivityOverviewProps = useMemo(
    () => ({
      currentCourse: currentCourse,
      i18n: {
        error403ButtonCta: tError403('button-cta'),
        error403Description: tError403('description'),
        error403Title: tError403('title'),
        laStepOverview: {
          duration: {
            timeHourShort: tGeneral('time-hour-short'),
            timeMinuteShort: tGeneral('time-minute-short'),
            hour: (count: number) => tGeneral('hour', { count }),
            min: (count: number) => tGeneral('min', { count }),
          },
          edit: tGeneral('edit'),
          nextLearningActivityButton: {
            next: t('learning-activity-overview.next-activity'),
          },
          laList: {
            add: t('learning-activity-overview.add-step'),
            floatingStepOptions: {
              block: t('learning-activity-overview.block.label'),
              survey: t('learning-activity-overview.survey.label'),
              assessment: t('learning-activity-overview.assessment.label'),
              action: t('learning-activity-overview.wba.label'),
              url: t('learning-activity-overview.url.label'),
              scenario: t('learning-activity-overview.scenario.label'),
            },
            delete: t('learning-activity-overview.delete-step'),
          },
          learningActivityEditDrawer: {
            activityName: {
              label: tLaEdit('activity-name'),
              placeholder: tLaEdit('activity-name-placeholder'),
            },
            description: {
              label: tGeneral('description'),
              placeholder: tLaEdit('description-placeholder'),
            },
            openDate: {
              label: tLaEdit('open-date'),
            },
            duration: {
              label: tGeneral('duration'),
              durationPicker: {
                addLabel: tLaEdit('add-other-time'),
                addCta: tLaEdit('add-time'),
                hour: tGeneral('hours'),
                min: tGeneral('mins'),
                h: tGeneral('time-hour-short'),
                validation: {
                  number: tValidation('number'),
                  max: (n: number) => tValidation('max', { number: n }),
                  min: (n: number) => tValidation('min', { number: n }),
                  sum: tLaEdit('validation.sum-hours-min'),
                },
                duration: {
                  timeHourShort: tGeneral('time-hour-short'),
                  timeMinuteShort: tGeneral('time-minute-short'),
                  hour: (count: number) => tGeneral('hour', { count }),
                  min: (count: number) => tGeneral('min', { count }),
                },
              },
            },
            errors: {
              required: tValidation('required'),
            },
            prerequisites: {
              label: tGeneral('prerequisites'),
              addNew: tGeneral('add-new'),
              placeholder: tLaEdit('type-activity-name'),
            },
            buttons: {
              cancel: tGeneral('cancel'),
              save: tGeneral('save'),
              delete: tLaEdit('delete'),
            },
            steps: {
              title: tLaEdit('steps.title'),
              addStep: tLaEdit('steps.add-step'),
              floatingStepOptions: {
                block: tLaEdit('steps.block.label'),
                survey: tLaEdit('steps.survey.label'),
                assessment: tLaEdit('steps.assessment.label'),
                action: tLaEdit('steps.wba.label'),
                url: tLaEdit('steps.url.label'),
                scenario: t('learning-activity-overview.scenario.label'),
              },
            },
            menu: {
              duplicate: tGeneral('duplicate'),
              delete: tGeneral('delete'),
            },
            types: {
              [LearningActivityType.Apply]: tLaEdit('types.apply'),
              [LearningActivityType.Experiment]: tLaEdit('types.experiment'),
              [LearningActivityType.Guide]: tLaEdit('types.guide'),
              [LearningActivityType.Retain]: tLaEdit('types.retain'),
              [LearningActivityType.Unknown]: tLaEdit('types.miscellaneous'),
            },
          },
        },
        empty: {
          title: t('learning-activity-overview.empty.title'),
          description: t('learning-activity-overview.empty.description'),
        },
      },
      companyId: company ? company.id : '',
      documentsEnabled: company?.documentConfiguration?.enabled,
      discussionAllowed: true,
      isEditing: isEditing,
      setIsEditing: setIsEditing,
      actions: actions,
    }),
    [currentCourse, tError403, tGeneral, t, tLaEdit, tValidation, company, isEditing, actions]
  );

  const contextValue = useMemo(
    () => ({
      saveFunc,
      setSaveFunc,
      isDirty,
      setIsDirty,
      isValid,
      setIsValid,
      user: {
        ...userQuery.data,
        viewedTips: userQuery.data?.viewedTips?.flatMap((t) => (t ? [t] : [])) ?? [],
      },
    }),
    [isDirty, isValid, saveFunc, userQuery.data]
  );

  return (
    <IfDefinedWrapper
      variable={currentCourse?.realtimeChannel.id}
      wrapper={(children, variable) => (
        <AblyChannelSubscriber
          channelId={variable}
          callback={ablyCallback}
        >
          {children}
        </AblyChannelSubscriber>
      )}
    >
      <LearningActivity.LearningActivityContext.Provider value={contextValue}>
        {modalConfig && (
          <ConfirmationModal
            title={modalConfig.title}
            description={modalConfig.description}
            buttons={modalConfig.buttons}
            isOpen
            onClose={() => setModalConfig(undefined)}
          />
        )}
        <GEARTemplateModal
          isOpen={isGEARModalOpen}
          onClose={() => setIsGEARModalOpen(false)}
        />
        <LearningActivity.LearningActivityRoutes {...overviewPageProps} />
      </LearningActivity.LearningActivityContext.Provider>
    </IfDefinedWrapper>
  );
};
