import { useTranslation } from 'react-i18next';
import moment from 'moment';

export interface MomentProps {
  date: Date;
}
/**
 * If you use this component, make sure you have the proper translations setup too.
 * general.minute
 * general.hour
 * general.yesterday
 *
 */
export const Moment = ({ date }: MomentProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'general' });

  const duration = moment.duration(moment().diff(date));
  const minutes = Math.round(duration.asMinutes());
  if (minutes < 60) {
    // Less than an hour
    return <span>{Math.round(minutes) + ' ' + t('minute', { count: minutes })}</span>;
  } else if (duration.asDays() < 1) {
    // Less than a day
    const hours = Math.round(duration.asHours());
    return <span>{t('hour', { count: hours })} </span>;
  } else if (duration.asDays() < 2) {
    // Yesterday
    return <span>{t('yesterday')}</span>;
  } else {
    return <span>{moment(date).format('DD MMM yyyy')}</span>;
  }
};

export default Moment;
