import { ChangeEvent } from 'react';
import { getChangeEventValue } from '@stellar-lms-frontend/common-utils';
import { ListFilterOptions } from './types';
import { Input } from '../form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-light-svg-icons';
import { SortOptionSelector } from './sort-option-selector';
import { FilterOptionSelector } from './filter-option-selector';

export const ListFilteringBar = <TDataItem,>({
  options,
  searchPlaceholder,
}: {
  options: ListFilterOptions<TDataItem>;
  searchPlaceholder: string;
}) => {
  const clearSearchQuery = () => {
    options.actions.onSearchQueryChange('');
  };

  return (
    <div className="flex items-center gap-6">
      <Input
        leftIcon={
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="text-text-01 text-xl"
          />
        }
        rightIcon={
          options.state.searchQuery === '' ? undefined : (
            <button
              onClick={clearSearchQuery}
              onKeyDown={clearSearchQuery}
              className="flex h-8 w-8 items-center justify-center"
            >
              <FontAwesomeIcon
                icon={faXmark}
                className="p-1 text-lg text-gray-500"
              />
            </button>
          )
        }
        placeholder={searchPlaceholder}
        text={options.state.searchQuery}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          options.actions.onSearchQueryChange(getChangeEventValue(e))
        }
        htmlId={'list-options-search-input'}
        theme="default"
      />
      {options.state.sortOptions.length > 0 ? (
        <div className="shrink-0">
          <SortOptionSelector
            sortOptions={options.state.sortOptions}
            selectedOption={options.state.selectedSortOption}
            onSortChange={(option) => options.actions.onSort(option)}
          />
        </div>
      ) : null}
      {options.state.filterOptions.length > 0 ? (
        <div className="shrink-0">
          <FilterOptionSelector
            filterOptions={options.state.filterOptions}
            onFilter={options.actions.onFilter}
            resetFilters={options.actions.onResetFilters}
          />
        </div>
      ) : null}
    </div>
  );
};
