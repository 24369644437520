import {
  cyanBg,
  grayBg,
  greenBg,
  indigoBg,
  orangeBg,
  pinkBg,
  purpleBg,
  redBg,
  yellowBg,
} from '@stellar-lms-frontend/ui-components';
import { BoxColor, BoxColorDefinition } from './types';

const colorDefinitions: Record<BoxColor, BoxColorDefinition> = {
  pink: {
    backgroundImage: pinkBg,
    titleClassName: 'text-pink-700',
    progressBGColorClassName: 'bg-pink-700/10',
    progressFGColorClassName: 'bg-pink-500/50',
    skeletonProps: {
      baseColor: '#b832801A',
      highlightColor: '#ed64a680',
    },
  },
  red: {
    backgroundImage: redBg,
    titleClassName: 'text-red-700',
    progressBGColorClassName: 'bg-red-700/10',
    progressFGColorClassName: 'bg-red-500/50',
    skeletonProps: {
      baseColor: '#c530301A',
      highlightColor: '#f5656580',
    },
  },
  yellow: {
    backgroundImage: yellowBg,
    titleClassName: 'text-yellow-700',
    progressBGColorClassName: 'bg-yellow-700/10',
    progressFGColorClassName: 'bg-yellow-500/50',
    skeletonProps: {
      baseColor: '#b7791f1A',
      highlightColor: '#ecc94b80',
    },
  },
  purple: {
    backgroundImage: purpleBg,
    titleClassName: 'text-purple-700',
    progressBGColorClassName: 'bg-purple-700/10',
    progressFGColorClassName: 'bg-purple-500/50',
    skeletonProps: {
      baseColor: '#6b46c11A',
      highlightColor: '#9f7aea80',
    },
  },
  orange: {
    backgroundImage: orangeBg,
    titleClassName: 'text-orange-700',
    progressBGColorClassName: 'bg-orange-700/10',
    progressFGColorClassName: 'bg-orange-500/50',
    skeletonProps: {
      baseColor: '#c05621A1',
      highlightColor: '#ed893680',
    },
  },
  cyan: {
    backgroundImage: cyanBg,
    titleClassName: 'text-cyan-700',
    progressBGColorClassName: 'bg-cyan-700/10',
    progressFGColorClassName: 'bg-cyan-500/50',
    skeletonProps: {
      baseColor: '#0e74901A',
      highlightColor: '#00BCD480',
    },
  },
  green: {
    backgroundImage: greenBg,
    titleClassName: 'text-green-700',
    progressBGColorClassName: 'bg-green-700/10',
    progressFGColorClassName: 'bg-green-500/50',
    skeletonProps: {
      baseColor: '#2f855a1A',
      highlightColor: '#48bb7880',
    },
  },
  indigo: {
    backgroundImage: indigoBg,
    titleClassName: 'text-indigo-700',
    progressBGColorClassName: 'bg-indigo-700/10',
    progressFGColorClassName: 'bg-indigo-500/50',
    skeletonProps: {
      baseColor: '#4c51bf1A',
      highlightColor: '#667eea80',
    },
  },
  gray: {
    backgroundImage: grayBg,
    titleClassName: 'text-gray-700',
    progressBGColorClassName: 'bg-gray-700/10',
    progressFGColorClassName: 'bg-gray-500/50',
    skeletonProps: {
      baseColor: '#4a55681A',
      highlightColor: '#a0aec080',
    },
  },
};

export function getColorDefinitions(color: BoxColor) {
  return colorDefinitions[color];
}

export function randomWidthBetween(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min) + '%';
}

// TODO: These might exist in our route constants
// NOTE: To avoid issues with how our basepath currently works in
//       the applications you need to use window.location.href to set this url
export function createLearnerCourseHref(id: string) {
  return `/learner/programme/${id}/overview`;
}
export function createDesignerCourseHref(id: string) {
  return `/designer/course/${id}`;
}
export function createMentorCourseHref(id: string) {
  return `/mentor/course/${id}`;
}
