import { faPenField } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DynamicList,
  EditableCheckboxCard,
  EditableCheckboxCardState,
  LoadingCard,
  SecondaryButton,
  SkeletonCard,
} from '@stellar-lms-frontend/ui-components';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

export type LocalEditableCheckboxCardState = EditableCheckboxCardState & { id: string };

export const BehaviourList = ({
  isLoading,
  behaviours = [],
  onChange,
}: {
  onChange: (items: LocalEditableCheckboxCardState[]) => void;
  isLoading: boolean;
  behaviours: LocalEditableCheckboxCardState[];
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.behaviour.ai-sidebar' });

  const emptyItemRef = useRef<HTMLTextAreaElement>(null);

  return (
    <>
      {!isLoading && (
        <SecondaryButton
          leftIcon={
            <FontAwesomeIcon
              icon={faPenField}
              className="ml-auto"
            />
          }
          label={t('add-custom-behaviour-button')}
          onClick={() => {
            if (emptyItemRef.current) {
              emptyItemRef.current.scrollIntoView({ behavior: 'smooth' });
              emptyItemRef.current.focus();
            }
          }}
          fullWidth={true}
        />
      )}
      {isLoading && (
        <LoadingCard
          title={t('loading-text')}
          color="dark-purple"
        />
      )}
      <DynamicList
        innerRef={emptyItemRef}
        initialItems={behaviours}
        renderItem={({ item, onChange, ref }) => {
          const editableCheckboxCardState: EditableCheckboxCardState = {
            ...item,
            titleState: 'default',
            descriptionState: 'default',
          };
          let warningMessage = '';
          if (item.title !== '' || item.description !== '') {
            if (!item.title) {
              warningMessage = t('missing-title');
              editableCheckboxCardState.titleState = 'warning';
            } else if (!item.description) {
              warningMessage = t('missing-description');
              editableCheckboxCardState.descriptionState = 'warning';
            }
          }

          return (
            <EditableCheckboxCard
              className="min-h-full"
              isEditable={true}
              state={editableCheckboxCardState}
              onChange={(newState) => onChange({ id: item.id, ...newState })}
              ref={ref}
              titlePlaceholder={t('add-custom-behaviour-title-placeholder')}
              descriptionPlaceholder={t('add-custom-behaviour-description-placeholder')}
              warningMessage={warningMessage}
            />
          );
        }}
        onItemsChange={(localItems) => {
          onChange(localItems);
        }}
        getNewItem={emptyItem}
        isItemEmpty={isItemEmpty}
        loadingCard={
          <SkeletonCard
            className="min-h-full"
            barConfigs={[
              { width: '80%', height: '12px' },
              { width: '100%', height: '8px' },
              { width: '100%', height: '8px' },
            ]}
          />
        }
        isLoading={isLoading}
      />
    </>
  );
};

const emptyItem = (): LocalEditableCheckboxCardState => {
  return {
    id: uuid(),
    isSelected: false,
    title: '',
    description: '',
  };
};

const isItemEmpty = (item: LocalEditableCheckboxCardState) => {
  return !item.title && !item.description;
};
