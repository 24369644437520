import { v4 as uuidv4 } from 'uuid';

export type Uploader = {
  uploadByFile: (file: File, callback?: (progress: number) => void) => Promise<UploadResponse>;
  uploadByUrl: (url: string) => Promise<UploadResponse>;
};

export type UploadResponse = {
  url: string;
  fileId: string;
};

export type FileInformation = {
  presignedUrl?: string; // sometimes this is an upload url, sometimes just a read url. This is not good
  fileId?: string;
};

type GetUploadInformationFunc = (fileName: string, mimeType: string) => Promise<FileInformation>;
type PerformFileUploadFunc = (
  presignedUrl: string,
  file: File,
  callback?: (progress: number) => void
) => Promise<void>;
type GetPresignedFileUrlFunc = (fileId: string) => Promise<FileInformation>;

export const fileUploader = (
  getPresignedUploadUrlFunc: GetUploadInformationFunc,
  performUploadFunc: PerformFileUploadFunc,
  getPresignedFileUrlFunc: GetPresignedFileUrlFunc
): Uploader => {
  const isValidImageRequest = (xmlHttpRequest: XMLHttpRequest, maxFileSize: number): boolean => {
    if (xmlHttpRequest.readyState === 4 && xmlHttpRequest.status === 200) {
      const contentType = xmlHttpRequest.getResponseHeader('content-type');
      if (!contentType?.startsWith('image')) {
        return false;
      }
      const contentLength = xmlHttpRequest.getResponseHeader('content-length');
      if (typeof Number(contentLength) === 'number') {
        return Number(contentLength) <= maxFileSize;
      }
    }
    return false;
  };

  const getFileExtension = (contentType: string): string | undefined => {
    switch (contentType) {
      case 'image/gif':
        return '.gif';
      case 'image/jpeg':
        return '.jpeg';
      case 'image/webp':
        return '.webp';
    }
    return undefined;
  };

  return {
    uploadByFile: async function (file: File, callback?: (progress: number) => void) {
      const uploadInformation = await getPresignedUploadUrlFunc(file.name, file.type);

      if (uploadInformation && uploadInformation.presignedUrl && uploadInformation.fileId) {
        await performUploadFunc(uploadInformation.presignedUrl, file, callback);

        const fileInformation = await getPresignedFileUrlFunc(uploadInformation.fileId);

        if (fileInformation && fileInformation.presignedUrl && fileInformation.fileId) {
          return {
            url: fileInformation.presignedUrl,
            fileId: fileInformation.fileId,
          };
        }
      }
      // TODO: some kind of server error??
      return Promise.reject();
    },

    uploadByUrl: async function (url: string) {
      try {
        const xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.open('HEAD', url, false);
        xmlHttpRequest.send();

        if (isValidImageRequest(xmlHttpRequest, 10 * 1024 * 1024)) {
          const contentType = xmlHttpRequest.getResponseHeader('content-type');
          const fileExtension = getFileExtension(contentType ?? '');
          if (contentType && fileExtension) {
            const file = await fetch(url)
              .then((r) => {
                return r.blob();
              })
              .then(
                (blobFile) => new File([blobFile], uuidv4() + fileExtension, { type: contentType })
              );
            return this.uploadByFile(file);
          }
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
      return Promise.reject();
    },
  };
};
