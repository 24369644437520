import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import { ReactNode, useMemo, useState } from 'react';
import React from 'react';
import { CrossIcon } from '../../icons';

export type HintDescriptionProps = {
  setRef?: (element: HTMLDivElement) => void;
  className?: string;
  isDefaultOpen?: boolean;
  type?: 'start' | 'center';
  icon?: ReactNode;
  title?: ReactNode | string;
  description?: ReactNode | string;
  onClick?: VoidFunc;
  onClose?: VoidFunc;
  hasCloseButton?: boolean;
  theme?: 'informative' | 'warning' | 'negative';
};

export const HintDescription: React.FC<HintDescriptionProps> = ({
  onClick,
  onClose,
  setRef,
  isDefaultOpen = true,
  className,
  type = 'start',
  icon,
  title,
  description,
  hasCloseButton,
  theme = 'informative',
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  const stylesClassName = useMemo(() => {
    switch (theme) {
      case 'informative':
        return 'bg-informative-03 text-informative-01';
      case 'warning':
        return 'bg-warning-03 text-warning-01';
      case 'negative':
        return 'bg-negative-03 text-negative-01';
    }
  }, [theme]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      role={onClick ? 'button' : 'div'}
      onClick={onClick}
      ref={setRef}
      className={`font-lexend rounded-xl p-4 ${className}
                ${onClick ? 'cursor-pointer' : 'cursor-default'}
                ${stylesClassName}`}
    >
      <div className={`flex ${type === 'start' ? 'items-start' : 'items-center'} justify-between`}>
        <div
          className={`flex w-full ${type === 'start' ? 'items-start gap-6' : 'items-center gap-4'}`}
        >
          {icon && <div className="shrink-0 grow-0">{icon}</div>}
          <div className="space-y-1 overflow-hidden">
            {title && typeof title === 'string' ? (
              <p className="break-words font-medium">{title}</p>
            ) : (
              title
            )}
            {description && typeof description === 'string' ? (
              <p className="break-words text-xs">{description}</p>
            ) : (
              description
            )}
          </div>
        </div>
        {hasCloseButton && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(false);
              onClose?.();
            }}
          >
            <CrossIcon />
          </button>
        )}
      </div>
    </div>
  );
};
