import { useCallback, useEffect, useState } from 'react';

import { navigation, useNavigationDirection } from '@stellar-lms-frontend/common-utils';
import { useFormContext } from 'react-hook-form';
import { ButtonConfig } from '@stellar-lms-frontend/ui-components';
import { Evaluation, Question, SurveyAnswerMap, TypeMap } from '@stellar-lms-frontend/lms-api';
import { toSubmissionDTO } from '../functions';
import { QuestionView, QuestionViewChildrenProps } from './question-view';
import { useTranslation } from 'react-i18next';

export type EvaluateFunc = (
  assessmentId: string,
  questionId: string,
  answers: TypeMap<SurveyAnswerMap>
) => Promise<Evaluation | null>;

export type SubmitAnswersFunc = (answers: TypeMap<SurveyAnswerMap>, assessmentId: string) => void;

export type QuestionsContainerProps = {
  questions: Question[];
  evaluations?: Evaluation[];
  allowPrevious: boolean;
  onFinish?: () => void;
  onBack?: () => void;
  showQuestionNumbers?: boolean;
  evaluateQuestionFunc?: EvaluateFunc;
  submitPartialAnswersFunc?: SubmitAnswersFunc;
  allowSkip: boolean;
  initialQuestionIndex?: number;
  onChangeQuestion?: (evaluation?: Evaluation) => void;
  children: (props: QuestionViewChildrenProps) => JSX.Element;
};

const NAVIGATION_KEY = 'question-steps';
export const QuestionsContainer: React.FC<QuestionsContainerProps> = ({
  questions,
  evaluations,
  allowPrevious,
  onFinish,
  evaluateQuestionFunc,
  submitPartialAnswersFunc,
  showQuestionNumbers = true,
  allowSkip = false,
  initialQuestionIndex = 0,
  onChangeQuestion,
  children,
}: QuestionsContainerProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assessment-step-view' });

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(initialQuestionIndex);
  const current = questions.at(currentQuestionIndex);
  const [currentQuestion, setCurrentQuestion] = useState<Question | undefined>(current);
  const { handleSubmit, getFieldState, formState } = useFormContext();
  const [buttonContent, setButtonContent] = useState<ButtonConfig | undefined>();
  const [evaluation, setEvaluation] = useState<Evaluation | undefined>();

  const { isBackwardNavigation, resetNavigationDirection } = useNavigationDirection();

  useEffect(() => {
    const currEvaluation = evaluations?.find((e) => e.questionId === currentQuestion?.questionId);
    currentQuestion ? setEvaluation(currEvaluation) : setEvaluation(undefined);
  }, [currentQuestion, evaluations]);

  const goToPreviousQuestion = useCallback(() => {
    if (!allowPrevious) return;

    const previousIndex = currentQuestionIndex - 1;
    const previousQuestion = questions.at(previousIndex);
    if (!previousQuestion) return;

    onChangeQuestion?.(evaluation);

    setCurrentQuestionIndex(previousIndex);
    setCurrentQuestion(previousQuestion);

    if (previousIndex === 0) {
      navigation.popRightSecondary(NAVIGATION_KEY);
    }
  }, [allowPrevious, currentQuestionIndex, questions, onChangeQuestion, evaluation]);

  const goToNextQuestion = useCallback(() => {
    onChangeQuestion?.(evaluation);
    if (currentQuestionIndex === questions.length - 1) {
      onFinish?.();
      return;
    }

    const nextIndex = currentQuestionIndex + 1;
    const nextQuestion = questions.at(nextIndex);
    if (!nextQuestion) return;

    setCurrentQuestionIndex(nextIndex);
    setCurrentQuestion(nextQuestion);
  }, [onChangeQuestion, evaluation, currentQuestionIndex, questions, onFinish]);

  const evaluateAnswer = useCallback(() => {
    const evaluateAnswer = async (question: Question, evaluateQuestionFunc: EvaluateFunc) => {
      handleSubmit(async (data) => {
        const evaluateRes: Evaluation | null = await evaluateQuestionFunc(
          question.assessmentId,
          question.id,
          { [question.id]: toSubmissionDTO(data)[question.id] || {} }
        );
        if (evaluateRes) {
          setEvaluation(evaluateRes);
        } else {
          goToNextQuestion();
        }
      })();
    };

    if (currentQuestion && evaluateQuestionFunc) {
      evaluateAnswer(currentQuestion, evaluateQuestionFunc);
    }
  }, [currentQuestion, evaluateQuestionFunc, goToNextQuestion, handleSubmit]);

  const submitAnswer = useCallback(() => {
    handleSubmit((answers) => {
      submitPartialAnswersFunc?.(toSubmissionDTO(answers), questions[0].assessmentId);
    })();

    const isLastQuestion = currentQuestionIndex === questions.length - 1;
    if (isLastQuestion) {
      navigation.popRightPrimary(NAVIGATION_KEY);
    }
  }, [questions, handleSubmit, submitPartialAnswersFunc, currentQuestionIndex]);

  const setNextButton = useCallback(() => {
    navigation.setRightPrimary(NAVIGATION_KEY, {
      label: t('next'),
      action: () => {
        goToNextQuestion();
      },
    });
  }, [t, goToNextQuestion]);

  const setPreviousButton = useCallback(() => {
    navigation.setRightSecondary(NAVIGATION_KEY, {
      label: t('previous'),
      action: () => {
        goToPreviousQuestion();
      },
    });
  }, [t, goToPreviousQuestion]);

  const setCheckButton = useCallback(() => {
    navigation.setRightPrimary(NAVIGATION_KEY, {
      label: t('check'),
      action: () => {
        submitAnswer();
        evaluateAnswer();
      },
    });
  }, [evaluateAnswer, t, submitAnswer]);

  const setButtons = useCallback(
    (evaluated: boolean, isDirty: boolean, currentQuestionIndex: number) => {
      const showCheckButton = (isDirty || !allowSkip) && !evaluated;

      if (showCheckButton) {
        setCheckButton();
      } else if (currentQuestionIndex === questions.length - 1) {
        navigation.popRightPrimary(NAVIGATION_KEY);
      } else {
        setNextButton();
      }

      if (currentQuestionIndex > 0) {
        allowPrevious && setPreviousButton();
      } else {
        navigation.popRightSecondary(NAVIGATION_KEY);
      }
    },
    [allowPrevious, allowSkip, questions.length, setCheckButton, setNextButton, setPreviousButton]
  );

  useEffect(() => {
    if (isBackwardNavigation) {
      setCurrentQuestionIndex(questions.length - 1);
      setCurrentQuestion(questions.at(questions.length - 1));
      resetNavigationDirection();
    }
  }, [isBackwardNavigation, questions, resetNavigationDirection]);

  const questionFieldState = getFieldState(currentQuestion?.id ?? '', formState);
  useEffect(() => {
    setButtons(!!evaluation, questionFieldState.isDirty, currentQuestionIndex);
  }, [evaluation, setButtons, questionFieldState.isDirty, currentQuestionIndex]);

  useEffect(() => {
    return () => {
      // let's try to make sure that nothing lingers in the state (does not trigger on refresh)
      navigation.popRightPrimary(NAVIGATION_KEY);
      navigation.popRightSecondary(NAVIGATION_KEY);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.subscribe(({ rightPrimary }) => setButtonContent(rightPrimary));

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div
      data-cy="questions-container"
      className="h-full w-full"
    >
      {currentQuestion && (
        <QuestionView
          key={currentQuestion.id}
          question={currentQuestion}
          showQuestionNumbers={showQuestionNumbers}
          number={currentQuestionIndex + 1}
          total={questions.length}
          answerEvaluation={evaluation}
          buttonContent={buttonContent}
          children={children}
        />
      )}
    </div>
  );
};
