import moment from 'moment-timezone';
import { TrackingEventResponse } from '../types';

export type SaveCourseEventFunc = (data: CourseEvent) => Promise<TrackingEventResponse>;
export type CourseEvent = {
  '@type': 'LearningSessionEvent';
  timezone: string;
  courseId: string;
  moduleId: string;
  activityId: string;
} & (
  | {
      type: 'open';
      learningSessionId?: never;
    }
  | {
      type: 'close' | 'ping';
      learningSessionId: string;
    }
);

export const startLearningActivityStep = (
  saveFunc: SaveCourseEventFunc,
  courseId: string,
  moduleId: string,
  activityId: string
): Promise<TrackingEventResponse> => {
  return saveFunc({
    '@type': 'LearningSessionEvent',
    type: 'open',
    timezone: moment.tz.guess(),
    courseId: courseId,
    moduleId: moduleId,
    activityId: activityId,
  });
};

export const pingLearningActivityStep = (
  saveFunc: SaveCourseEventFunc,
  courseId: string,
  moduleId: string,
  activityId: string,
  learningSessionId: string
): Promise<void> => {
  return saveFunc({
    '@type': 'LearningSessionEvent',
    type: 'ping',
    timezone: moment.tz.guess(),
    courseId: courseId,
    moduleId: moduleId,
    activityId: activityId,
    learningSessionId: learningSessionId,
  }).then();
};

export const stopLearningActivityStep = (
  saveFunc: SaveCourseEventFunc,
  courseId: string,
  moduleId: string,
  activityId: string,
  learningSessionId: string
): Promise<void> => {
  return saveFunc({
    '@type': 'LearningSessionEvent',
    type: 'close',
    timezone: moment.tz.guess(),
    courseId: courseId,
    moduleId: moduleId,
    activityId: activityId,
    learningSessionId: learningSessionId,
  }).then();
};
