import {
  LearningActivityStep,
  LearningActivityStepSourceType,
} from '@stellar-lms-frontend/lms-api';
import { GraphQLClient } from 'graphql-request';
import { graphql } from '@stellar-lms-frontend/lms-graphql';

export const getLearningActivityStepById = (
  client: GraphQLClient,
  learningActivityId: string,
  stepId: string
): Promise<LearningActivityStep> => {
  return client
    .request(
      graphql(`
        query LearningActivityStep($learningActivityId: ID!, $stepId: ID!) {
          learningActivityStep(learningActivityId: $learningActivityId, stepId: $stepId) {
            __typename
            id
            title
            sourceId
            headerImage {
              url
              fileId
              isFullWidth
            }
            ... on ContentStep {
              url
            }
          }
        }
      `),
      {
        learningActivityId,
        stepId,
      }
    )
    .then((data) => {
      let sourceType: LearningActivityStepSourceType = LearningActivityStepSourceType.IFRAME;
      let url: string | undefined;

      switch (data.learningActivityStep.__typename) {
        case 'ContentStep':
          sourceType = LearningActivityStepSourceType.IFRAME;
          url = data.learningActivityStep.url;
          break;
        case 'BlockContentStep':
          sourceType = LearningActivityStepSourceType.BLOCK;
          break;
        case 'QuizStep':
          sourceType = LearningActivityStepSourceType.ASSESSMENT;
          break;
        case 'SurveyStep':
          sourceType = LearningActivityStepSourceType.SURVEY;
          break;
        case 'ScenarioStep':
          sourceType = LearningActivityStepSourceType.SCENARIO;
          break;
        case 'WBAStep':
          sourceType = LearningActivityStepSourceType.WBA;
          break;
        default:
      }

      return {
        id: data.learningActivityStep.id,
        title: data.learningActivityStep.title,
        sourceId: data.learningActivityStep.sourceId,
        source: url,
        secureSourceUrl: url,
        sourceType,
        enabled: true,
        durationInS: 0,
        headerImage: data.learningActivityStep.headerImage,
      } as LearningActivityStep;
    });
};
