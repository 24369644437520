import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import WorkBasedActionCompleteView from '../../workbasedactions/work-based-action-complete-view';
import { WorkBasedActionEditView } from '../../workbasedactions/work-based-action-edit-view';
import WorkBasedActionReadOnlyView from '../../workbasedactions/work-based-action-read-only-view';
import {
  ACTION_EDIT_URI_PART,
  ACTION_REFLECTION_RESULT_URI_PART,
  ACTION_REVIEW_RESULT_URI_PART,
  ACTION_OLD_REFLECTION_URI_PART,
  ACTION_OLD_REVIEW_URI_PART,
} from '../../../constants/routes';
import { WorkBasedActionSurveyResult } from '../../workbasedactions/components/work-based-action-survey-result';
import { PersistedLearnerWorkBasedAction } from '@stellar-lms-frontend/lms-api';
import * as Sentry from '@sentry/react';

export type ActionDetailProps = {
  action: PersistedLearnerWorkBasedAction;
  isUserMentor?: boolean;
};

type ViewState =
  | typeof ACTION_OLD_REFLECTION_URI_PART
  | typeof ACTION_OLD_REVIEW_URI_PART
  | typeof ACTION_EDIT_URI_PART
  | typeof ACTION_REFLECTION_RESULT_URI_PART
  | typeof ACTION_REVIEW_RESULT_URI_PART;

export const ActionDetail = ({ action, isUserMentor = false }: ActionDetailProps) => {
  const location = useLocation();
  const viewState = location.pathname.split('/').filter(Boolean).pop() as ViewState;
  const { t: tSurvey } = useTranslation('translation', { keyPrefix: 'actions.survey' });

  const navigate = useNavigate();

  if (!isUserMentor && action.state === 'submitted' && viewState === ACTION_EDIT_URI_PART) {
    return <WorkBasedActionEditView learnerWorkBasedActionId={action.id} />;
  } else if (viewState === ACTION_OLD_REFLECTION_URI_PART && action.state === 'submitted') {
    return (
      <WorkBasedActionCompleteView
        key={action.id}
        learnerWorkBasedActionId={action.id}
      />
    );
  } else if (viewState === ACTION_OLD_REVIEW_URI_PART && action.state === 'executed') {
    // We don't have an old way of doing this without a survey, but that fallback is normally coming in the future
    navigate(`./`, { replace: true });
    return null;
  } else {
    switch (viewState) {
      case ACTION_REVIEW_RESULT_URI_PART:
        if (!action.mentorSurveyId) {
          Sentry.captureException(
            `No mentor survey id found for learner work based action with id: ${action.id}`
          );
          return null;
        }
        return (
          <WorkBasedActionSurveyResult
            key={action.mentorSurveyId}
            surveyId={action.mentorSurveyId}
            learnerWorkBasedActionId={action.id}
            title={tSurvey('mentor-result-title')}
          />
        );
      case ACTION_REFLECTION_RESULT_URI_PART:
        if (!action.learnerSurveyId) {
          Sentry.captureException(
            `No learner survey id found for learner work based action with id: ${action.id}`
          );
          return null;
        }
        return (
          <WorkBasedActionSurveyResult
            key={action.learnerSurveyId}
            surveyId={action.learnerSurveyId}
            learnerWorkBasedActionId={action.id}
            title={tSurvey('learner-result-title')}
          />
        );
      default:
        return (
          <WorkBasedActionReadOnlyView
            key={action.id}
            learnerWorkBaseActionId={action.id}
            isUserMentor={isUserMentor}
          />
        );
    }
  }
};
