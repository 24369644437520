import { VoidFunc, useDisableScroll } from '@stellar-lms-frontend/common-utils';
import { CrossIcon, LeftArrowIcon } from '../icons';
import { Portal } from '../wrappers/portal/portal';
import { ReactElement, ReactNode } from 'react';

export type ModalButtonProps = {
  hasShadow: boolean;
  buttons: ReactNode[];
};

export type ModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  children: ReactNode;
  className?: string;
  buttons?: ModalButtonProps;
  variant?: 'blank' | 'default';
} & (
  | {
      leftTitleIcon?: ReactElement;
      onClickBack?: never;
    }
  | {
      leftTitleIcon?: never;
      onClickBack?: VoidFunc;
    }
);

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  leftTitleIcon,
  className = 'bg-surface-01 md:w-[510px]',
  buttons,
  onClickBack,
  variant = 'default',
}) => {
  useDisableScroll(isOpen);

  if (!isOpen) {
    return null;
  }

  if (variant === 'blank') {
    return (
      <Portal>
        <div className="modal-bg font-lexend md:flex md:items-center md:justify-center">
          <div
            className={`${className} overflow-hidden md:max-h-[90%] md:max-w-[90%] md:rounded-3xl`}
          >
            {children}
          </div>
        </div>
      </Portal>
    );
  }

  return (
    <Portal>
      <div className="modal-bg font-lexend md:flex md:items-center md:justify-center">
        <div
          className={`${className} flex flex-col gap-6 pt-10 md:max-h-[90%] md:max-w-[90%] md:rounded-3xl`}
        >
          <div className="flex flex-col-reverse px-10 md:flex-row md:items-center md:justify-between">
            <div className="flex gap-4">
              {onClickBack && (
                <button onClick={onClickBack}>
                  <LeftArrowIcon className="text-primary-01 h-6 w-6" />
                </button>
              )}
              {leftTitleIcon && <div className="shrink-0">{leftTitleIcon}</div>}
              {title && (
                <span className="text-text-04 md:font-regular text-xl font-medium md:text-2xl">
                  {title}
                </span>
              )}
            </div>
            <button
              className="z-30 cursor-pointer self-end md:self-center"
              onClick={onClose}
            >
              <CrossIcon className="text-text-01 h-6 w-6" />
            </button>
          </div>
          <div className={`grow overflow-auto px-10 ${buttons ? 'pb-2' : 'pb-10'}`}>{children}</div>
          {buttons && (
            <div
              className={`${
                buttons.hasShadow ? 'shadow-modal-buttons pt-4' : ''
              } flex flex-col items-center space-x-4 px-10 pb-10 lg:flex-row`}
            >
              {buttons.buttons}
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
};
