import React from 'react';
import { ParagraphData } from './paragraph-plugin';
import { Alignment } from '../types';
import { ContentEditable } from '../../components/content-editable';

interface ParagraphComponentProps {
  data: ParagraphData;
  onChange: (newData: ParagraphData) => void;
  isReadOnly: boolean;
  placeholder: string;
  className?: string;
}

export const ParagraphComponent: React.FC<ParagraphComponentProps> = ({
  data,
  onChange,
  isReadOnly,
  placeholder,
  className = '',
}) => {
  return (
    <ContentEditable
      onChange={(value) => {
        onChange({
          ...data,
          text: value,
        });
      }}
      value={data.text}
      contentEditable={!isReadOnly}
      placeholder={placeholder}
      className={`
        ${data.alignment === Alignment.LEFT ? 'text-left' : ''}
        ${data.alignment === Alignment.CENTER ? 'text-center' : ''}
        ${data.alignment === Alignment.RIGHT ? 'text-right' : ''}
        ${data.alignment === Alignment.JUSTIFY ? 'text-justify' : ''}
        ${className}
        mt-1 w-full outline-none`}
    />
  );
};
