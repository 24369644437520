import { FontBody } from '../../text';

export type SwitchProps = {
  onChange: (checked: boolean) => void;
  value: boolean;
  label: string;
};

// This switch still needs to be styled
export const Switch: React.FC<SwitchProps> = ({ value, onChange, label }) => {
  return (
    <label className="text-text-01 flex items-center space-x-2">
      <input
        type="checkbox"
        checked={value}
        onChange={(event) => onChange(event.target.checked)}
      />
      <FontBody
        tag="span"
        type="default"
      >
        {label}
      </FontBody>
    </label>
  );
};
