import {
  SaveCourseEventFunc,
  pingLearningActivityStep,
  startLearningActivityStep,
  stopLearningActivityStep,
} from '@stellar-lms-frontend/lms-api';
import { useEffect, useRef } from 'react';

export const useCourseTracking = (
  saveCourseEventFunc?: SaveCourseEventFunc,
  courseId?: string,
  learningActivityId?: string,
  stepId?: string
) => {
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    // Practical disble of the use hook
    if (!saveCourseEventFunc) {
      return;
    }

    let learningSessionId: string;

    if (courseId && learningActivityId && stepId) {
      startLearningActivityStep(saveCourseEventFunc, courseId, learningActivityId, stepId).then(
        (response) => {
          learningSessionId = response.id;

          timerRef.current = setInterval(() => {
            if (learningSessionId) {
              pingLearningActivityStep(
                saveCourseEventFunc,
                courseId,
                learningActivityId,
                stepId,
                learningSessionId
              );
            }
          }, 60000);
        }
      );
    }

    return () => {
      if (courseId && learningActivityId && stepId && learningSessionId) {
        stopLearningActivityStep(
          saveCourseEventFunc,
          courseId,
          learningActivityId,
          stepId,
          learningSessionId
        );
      }
      timerRef.current && clearTimeout(timerRef.current);
    };
  }, [courseId, learningActivityId, saveCourseEventFunc, stepId]);
};

export default useCourseTracking;
