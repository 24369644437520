import { destructureSeconds } from '@stellar-lms-frontend/common-utils';
import { InsightCard, Section } from '@stellar-lms-frontend/lms-components';
import {
  ChartPieFilledIcon,
  ClockFilledIcon,
  LinkButton,
  UserFilledIcon,
} from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DESIGNER_COURSE_INSIGHTS_ROUTE } from '../../constants/routes';

export type DesignerInsightsSectionProps = {
  courseId: string;
  stats: {
    averageCompletionRate?: number | null;
    averageTimeSpentInSeconds?: number | null;
    numberOfActiveStudents?: number | null;
    numberOfStudents?: number | null;
  };
  hasDashboards: boolean;
};

export const DesignerInsightsSection: React.FC<DesignerInsightsSectionProps> = ({
  courseId,
  stats,
  hasDashboards,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'designer-overview' });
  const { t: tInsightsPage } = useTranslation('translation', { keyPrefix: 'insights-page' });
  const navigate = useNavigate();

  const { hours, minutes } = destructureSeconds(stats.averageTimeSpentInSeconds ?? 0);

  return (
    <Section
      data-cy={'insights-section'}
      title={t('insights-section-title')}
    >
      <div className="space-y-4">
        <div className="flex flex-col flex-wrap gap-4 md:flex-row">
          <InsightCard
            icon={UserFilledIcon}
            title={`${stats.numberOfActiveStudents} / ${stats.numberOfStudents}`}
            subtitle={t('insights.sign-up')}
            data-cy="insights-section-0"
          />
          <InsightCard
            icon={ChartPieFilledIcon}
            title={`${Math.round((stats.averageCompletionRate ?? 0) * 100)}%`}
            subtitle={t('insights.completion-rate')}
            data-cy="insights-section-1"
          />
          <InsightCard
            icon={ClockFilledIcon}
            title={`
            ${hours > 0 ? t('insights.hours', { count: hours }) : ''}
            ${t('insights.minutes', { count: minutes })}
          `}
            subtitle={t('insights.time-spent')}
            data-cy="insights-section-2"
          />
        </div>
        {hasDashboards && (
          <div>
            <LinkButton
              type="blue"
              buttonStyle="fit"
              label={tInsightsPage('title')}
              onClick={() => navigate(DESIGNER_COURSE_INSIGHTS_ROUTE(courseId))}
            />
          </div>
        )}
      </div>
    </Section>
  );
};
