import { LearningActivityUtils } from '@stellar-lms-frontend/common-utils';
import { State } from './types';

// TODO: Move those selectors to common-utils so they can be used in module-list.tsx and use-course-data.ts
export const getLearningActivityLocation = (state: State, learningActivityId: string) => {
  const moduleIndex = state.modules.findIndex((module) =>
    module.learningActivities.some((la) => la.id === learningActivityId)
  );
  const learningActivityIndexInModule = state.modules[moduleIndex].learningActivities.findIndex(
    (la) => la.id === learningActivityId
  );

  return {
    moduleIndex,
    learningActivityIndexInModule,
  };
};

export const getModuleForLearningActivityById = (state: State, learningActivityId: string) => {
  const { moduleIndex } = getLearningActivityLocation(state, learningActivityId);

  return getModuleByLocation(state, moduleIndex);
};

export const getModuleByLocation = (state: State, moduleIndex: number) => {
  return state.modules[moduleIndex];
};

export const getLearningActivityById = (state: State, learningActivityId: string) => {
  const { moduleIndex, learningActivityIndexInModule } = getLearningActivityLocation(
    state,
    learningActivityId
  );

  return getLearningActivityByLocation(state, moduleIndex, learningActivityIndexInModule);
};

export const getLearningActivityByLocation = (
  state: State,
  moduleIndex: number,
  learningActivityIndexInModule: number
) => {
  return state.modules[moduleIndex].learningActivities[learningActivityIndexInModule];
};

export const getAdjacentLearningActivities = (state: State, learningActivityId: string) => {
  return learningActivityId
    ? LearningActivityUtils.getAdjacentLearningActivities(state?.modules ?? [], learningActivityId)
    : { nextLearningActivity: undefined, prevLearningActivity: undefined };
};
