import { secondsAsHours } from '@stellar-lms-frontend/common-utils';
import { BoxColor, BoxColorDefinition } from '../types';
import { createLearnerCourseHref, getColorDefinitions } from '../functions';
import * as CourseBox from './course-box';
import { Badge } from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { Types } from '@stellar-lms-frontend/lms-graphql';

type LearnerCourseBlockProps = {
  color: BoxColor;
  course: Types.LearnerCourse;
};
export const LearnerCourseBlock = ({ course, color }: LearnerCourseBlockProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard-box' });
  const { t: tRoles } = useTranslation('translation', { keyPrefix: 'roles' });

  const statList = [
    {
      label: t('activities'),
      value: `${course.stats.nrOfLearningActivitiesCompleted} ${t('of')} ${
        course.stats.nrOfLearningActivities
      }`,
    },
    {
      label: t('spent'),
      value: `${secondsAsHours(course.stats.timeSpentInS)}h`,
    },
    {
      label: t('remaining'),
      value: `${secondsAsHours(course.stats.timeRemainingInS)}h`,
    },
    { label: course.language },
  ];

  // Might be very interesting to do something with css variables instead
  const colorDef = getColorDefinitions(color);

  return (
    <CourseBox.Container
      onClick={() => (window.location.href = createLearnerCourseHref(course.id))}
    >
      <CourseBox.Header colorDef={colorDef}>
        <Badge className={`mb-4 max-w-fit ${colorDef.progressBGColorClassName}`}>
          {tRoles('learner')}
        </Badge>
        <CourseBox.Title
          title={course.title}
          colorDef={colorDef}
        />
        <NextLearningActivity
          colorDef={colorDef}
          nextActivity={course.stats.nextActivity}
          nextActivityLabel={t('next')}
        />
        <CourseBox.Progress
          id={course.id}
          colorDef={colorDef}
          progress={course.stats.progress}
        />
      </CourseBox.Header>
      <CourseBox.Content items={statList} />
    </CourseBox.Container>
  );
};

const NextLearningActivity = ({
  colorDef,
  nextActivity,
  nextActivityLabel,
}: {
  colorDef: BoxColorDefinition;
  nextActivity?: { id: string; title: string; durationInS: number };
  nextActivityLabel: string;
}) => (
  <span
    className={`${
      colorDef.titleClassName ?? ''
    } font-lexend text-text-01 font-regular block overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-normal lg:text-base`}
  >
    {nextActivity && (
      <>
        <span className="font-medium">{nextActivityLabel}</span>
        &nbsp;
        {nextActivity.title}
        &nbsp;
        {(nextActivity.durationInS ?? 0) > 0 && (
          <span className="text-sm">{`(${secondsAsHours(nextActivity.durationInS ?? 0)}h)`}</span>
        )}
      </>
    )}
  </span>
);
