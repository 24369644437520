import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Sidebar,
  Input,
  Dropdown,
  PrimaryButton,
  LinkButton,
  ToolTip,
  Form,
} from '@stellar-lms-frontend/ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import { useCallback } from 'react';
import { SupportedLanguagesOptions } from '../../../../types/types';
import { useTranslation } from 'react-i18next';

export type MemberEditActionType = 'jobTitle' | 'language' | 'access';

export type UserProfileEditFormData = {
  language: string | null;
  jobTitle: string | undefined;
  access: 'user' | 'course_creator' | 'admin' | null;
};

export type EditMembersSidebarProps = {
  isOpen: boolean;
  type: MemberEditActionType;
  onClose: () => void;
  onSubmit: (data: UserProfileEditFormData) => void;
  supportedLanguages: SupportedLanguagesOptions;
  defaultLanguage: string;
};

export const EditMembersSidebar: React.FC<EditMembersSidebarProps> = ({
  isOpen,
  type,
  onClose,
  onSubmit,
  supportedLanguages,
  defaultLanguage,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organization.members-tab.options-menu.edit-members-sidebar',
  });

  // CLEANUP? Note the small difference in keyprefix member vs members
  const { t: tEditMemberSidebar } = useTranslation('translation', {
    keyPrefix: 'organization.members-tab.options-menu.edit-member-sidebar',
  });

  const schema = yup.object({
    jobTitle: yup.string(),
    access: yup.string(),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<UserProfileEditFormData>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      language: defaultLanguage,
    },
  });

  const renderFormContent = useCallback(() => {
    switch (type) {
      case 'language':
        return (
          <Dropdown
            label={tEditMemberSidebar('forms.language.label')}
            htmlId="language"
            {...register('language')}
          >
            {supportedLanguages.map(({ label, value }) => (
              <option
                key={'language_' + value}
                value={value}
              >
                {label}
              </option>
            ))}
          </Dropdown>
        );
      case 'access':
        return (
          <Dropdown
            htmlId={''}
            {...register('access')}
            label={tEditMemberSidebar('forms.role.label')}
            error={errors.access?.message}
            labelRightNode={
              <ToolTip
                color="dark"
                size="small"
                placement="bottom-start"
                isEnabled={true}
                childrenClassName="w-[300px]"
                wrappedComponent={
                  <FontAwesomeIcon
                    icon={faCircleQuestion}
                    className="text-text-02 h-4 w-4"
                  />
                }
              >
                <div className="whitespace-normal">
                  <p>{tEditMemberSidebar('forms.role.tooltip.description')}</p>
                  <ul className="list-inside list-disc">
                    <li>{tEditMemberSidebar('forms.role.tooltip.course-creator')}</li>
                    <li>{tEditMemberSidebar('forms.role.tooltip.admin')}</li>
                  </ul>
                </div>
              </ToolTip>
            }
          >
            <option value="user">{tEditMemberSidebar('forms.role.options.user')}</option>
            <option value="course_creator">
              {tEditMemberSidebar('forms.role.options.course-creator')}
            </option>
            <option value="admin">{tEditMemberSidebar('forms.role.options.admin')}</option>
          </Dropdown>
        );
      case 'jobTitle':
        return (
          <Controller
            control={control}
            name="jobTitle"
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                htmlId={''}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                label={tEditMemberSidebar('forms.job-title.label')}
                placeholder={tEditMemberSidebar('forms.job-title.placeholder')}
                error={errors.jobTitle?.message}
              />
            )}
          />
        );
    }
  }, [
    type,
    tEditMemberSidebar,
    register,
    supportedLanguages,
    errors.access?.message,
    errors.jobTitle?.message,
    control,
  ]);

  return (
    <Sidebar
      isOpen={isOpen}
      position={'right'}
      onClose={onClose}
      contentClassName="md:max-w-[600px] md:w-[60%] font-lexend"
      hasBackdrop={false}
      buttons={{
        hasDivider: false,
        buttons: [
          <PrimaryButton
            onClick={handleSubmit((data) => {
              switch (type) {
                case 'access':
                  onSubmit({ access: data.access, jobTitle: undefined, language: null });
                  break;
                case 'jobTitle':
                  onSubmit({ access: null, jobTitle: data.jobTitle, language: null });
                  break;
                case 'language':
                  onSubmit({ access: null, jobTitle: undefined, language: data.language });
                  break;
              }
            })}
            label={t('buttons.save')}
            key={1}
            enabled={isValid}
          />,
          <LinkButton
            label={t('buttons.cancel')}
            key={2}
            onClick={onClose}
          />,
        ],
      }}
    >
      <div className="space-y-8">
        <h2 className="text-text-04 type-headline-2 min-h-[36px] break-words">{t('title')}</h2>
        <p className="text-text-02 type-body">{t('description')}</p>
        <Form className="space-y-6">{renderFormContent()}</Form>
      </div>
    </Sidebar>
  );
};
