export const objectApply = <T extends Record<string, any>>(obj: T, callback: <T>(v: T) => T): T => {
  if (typeof obj === 'object' && !!obj) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        obj[key] = objectApply(obj[key], callback);
      }
    }
    return obj;
  } else {
    return callback(obj);
  }
};

type Obj = { [key: string]: any };

export const isObj = (val: any): val is Obj => {
  return typeof val === 'object' && val !== null && !Array.isArray(val);
};

export const deepEqual = <T extends Obj>(obj1: T, obj2: T): boolean => {
  const keys1 = Object.keys(obj1) as (keyof T)[];
  const keys2 = Object.keys(obj2) as (keyof T)[];

  return (
    keys1.length === keys2.length &&
    keys1.every((key) => {
      const val1 = obj1[key];
      const val2 = obj2[key];
      return isObj(val1) && isObj(val2)
        ? deepEqual(val1, val2)
        : Array.isArray(val1) && Array.isArray(val2)
        ? JSON.stringify(val1) === JSON.stringify(val2)
        : val1 === val2;
    })
  );
};

export const compareObjArrays = (arr1: Obj[], arr2: Obj[]): boolean => {
  return arr1.length === arr2.length && arr1.every((item, idx) => deepEqual(item, arr2[idx]));
};
