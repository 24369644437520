import {
  LearningActivityOutline,
  LearningActivityProgress,
} from '@stellar-lms-frontend/lms-graphql';
import {
  LockIcon,
  CheckIcon,
  InProgressIcon,
  FloatingBox,
  CommentCountIcon,
  ChevronRightIcon,
  ToolTip,
  SkeletonCard,
  BadgeAI,
} from '@stellar-lms-frontend/ui-components';
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { LearningActivityListI18N } from '../../learning-activity-list';
import { DescriptionSection } from '../description-section';
import { STEP_ITEM_ID, StepsSection } from '../steps-section';
import { LearningActivityUtils } from '@stellar-lms-frontend/common-utils';
import { getLearningActivityTypeIcon } from '../../../utils/learningActivity';
import { convertToPlaceholderTitle } from '../../functions';

type ReadProps = {
  i18n: LearningActivityListI18N;
  learningActivity: LearningActivityOutline;
};

export const Read: React.FC<ReadProps> = ({ i18n, learningActivity }) => {
  const [isCommentTooltipOpen, setIsCommentTooltipOpen] = useState(false);
  const navigate = useNavigate();

  const onLearningActivityClick = useCallback(
    (e: React.MouseEvent) => {
      if ((e.target as HTMLElement).id.includes(STEP_ITEM_ID)) {
        return;
      }

      if (
        !learningActivity.locked &&
        !learningActivity.placeholder &&
        !(learningActivity.aiGenerated && !learningActivity.aiGenerationDone)
      ) {
        navigate(`./learning-activity/${learningActivity.id}`);
      }
    },
    [
      learningActivity.id,
      learningActivity.locked,
      learningActivity.placeholder,
      learningActivity.aiGenerated,
      learningActivity.aiGenerationDone,
      navigate,
    ]
  );

  const onStepClick = useCallback(
    (stepId: string) =>
      learningActivity && navigate(`./learning-activity/${learningActivity.id}/step/${stepId}`),
    [learningActivity, navigate]
  );

  const Icon = getLearningActivityTypeIcon(learningActivity.type ?? undefined);
  const isAIGenerating = learningActivity.aiGenerated && !learningActivity.aiGenerationDone;

  return (
    <li
      key={learningActivity.id}
      className="group"
      onClick={onLearningActivityClick}
    >
      <ToolTip
        isEnabled={!!learningActivity.locked}
        placement="top-start"
        floatingOffset={-16}
        color="dark"
        size="small"
        wrappedComponent={
          <div
            className={`flex flex-row ${
              learningActivity.locked || learningActivity.placeholder || isAIGenerating
                ? ''
                : 'cursor-pointer'
            }`}
          >
            {/* These maybes should be taken out at a higher level in a refactor.. */}
            <ProgressTimeline
              locked={learningActivity.locked ?? undefined}
              progress={learningActivity.progress ?? undefined}
            />
            {learningActivity.placeholder ? (
              <AIPlaceholderCard
                title={learningActivity.title ?? ''}
                aiBadgeLabel={i18n.aiBadgeLabel}
              />
            ) : isAIGenerating ? (
              <SkeletonCard
                className="my-3 ml-6 w-full"
                barConfigs={[
                  { width: '35%', height: '12px' },
                  { width: '60%', height: '8px' },
                  { width: '15%', height: '6px' },
                ]}
              />
            ) : (
              <div
                className={`lg:bg-surface-02 mx-4 my-3 flex w-full flex-row gap-4 overflow-hidden rounded-2xl lg:ml-6 lg:mr-0 lg:p-6 ${
                  learningActivity.locked ? '' : 'hover:lg:bg-surface-03'
                } `}
              >
                <Icon className="text-primary-02 h-10 w-10 shrink-0" />
                <div className="grow overflow-hidden">
                  <p
                    className="text-text-01 break-words text-base font-medium lg:text-lg"
                    data-cy="learningactivity-title"
                  >
                    {learningActivity.title}
                  </p>
                  <DescriptionSection
                    i18n={i18n.descriptionSection}
                    duration={learningActivity.duration ?? 0}
                    description={learningActivity.description ?? ''}
                    durationClassName={'text-primary-01'}
                    isLocked={!!learningActivity.locked}
                    lockedText={i18n.lockedActivityText}
                  />
                  {learningActivity.steps && learningActivity.steps.length > 0 && (
                    <StepsSection
                      className="mt-4"
                      steps={learningActivity.steps?.map((s) => ({ ...s, title: s.title ?? '' }))}
                      onClick={onStepClick}
                    />
                  )}
                  {learningActivity.commentCount !== undefined &&
                    learningActivity.commentCount !== 0 && (
                      <FloatingBox
                        placement={'bottom-start'}
                        onClose={() => setIsCommentTooltipOpen(false)}
                        isOpen={isCommentTooltipOpen}
                        wrappedComponent={
                          <span
                            className="text-text-02 inline-flex items-center space-x-2 pt-4"
                            onMouseEnter={() => setIsCommentTooltipOpen(true)}
                            onMouseLeave={() => setIsCommentTooltipOpen(false)}
                          >
                            <CommentCountIcon />
                            <span>{learningActivity.commentCount}</span>
                          </span>
                        }
                      >
                        <div className="bg-inverse-bg text-on-inverse-01 p-3 text-sm font-medium">
                          <p>{i18n.activityCommentCount(learningActivity.commentCount ?? 0)}</p>
                        </div>
                      </FloatingBox>
                    )}
                </div>
                <span className="lg:pt-1">
                  {!learningActivity.locked && (
                    <ChevronRightIcon className="fill-text-01 grow-0 justify-end " />
                  )}
                </span>
              </div>
            )}
          </div>
        }
      >
        {LearningActivityUtils.getTooltipForLockedLearningActivity(
          learningActivity,
          i18n.activityAvailableWhenTooltip,
          i18n.activityAvailableOnTooltip
        )}
      </ToolTip>
    </li>
  );
};

const ProgressTimeline = ({
  progress,
  locked = false,
}: {
  // wonder if it would be better/cleaner to define the 'no progress' state as well so that all states are modeled instead of allowing undefined
  progress?: LearningActivityProgress;
  locked?: boolean;
}) => (
  <span className={`relative flex w-6 flex-col items-center`}>
    <i className="bg-border-01 w-[2px] grow group-first:bg-transparent"></i>
    {locked ? (
      <i className={`my-[2px] flex h-6 w-6 shrink-0 justify-center`}>
        <LockIcon className="fill-border-01" />
      </i>
    ) : (
      <i
        className={`my-[2px] flex h-6 w-6 shrink-0 items-center justify-center rounded-full ${
          progress === LearningActivityProgress.Done
            ? 'bg-positive-02 border-0'
            : 'bg-surface-01 border-border-01 border-2'
        }`}
      >
        {progress === LearningActivityProgress.Done ? (
          <CheckIcon className="text-surface-01 w-5" />
        ) : progress === LearningActivityProgress.InProgress ? (
          <InProgressIcon className="fill-border-01 w-5" />
        ) : null}
      </i>
    )}
    <i className="bg-border-01 w-[2px] grow group-last:bg-transparent"></i>
  </span>
);

const AIPlaceholderCard = ({ title, aiBadgeLabel }: { title: string; aiBadgeLabel: string }) => (
  <div
    className={`mx-4 my-3 flex w-full flex-row gap-4 overflow-hidden rounded-2xl bg-purple-50 lg:ml-6 lg:mr-0 lg:p-4`}
  >
    <div className="flex w-full">
      <p
        className="grow break-words text-base font-medium text-purple-600 lg:text-lg"
        data-cy="learningactivity-title"
      >
        {convertToPlaceholderTitle(title)}
      </p>
      <BadgeAI
        color="purple"
        className="shrink-0"
        label={aiBadgeLabel}
      />
    </div>
  </div>
);
