import React from 'react';
import { ReactNode } from 'react';

// We might be able to standardize these accross the ui components
export type ColorType = 'default' | 'purple' | 'dark-purple' | 'red' | 'info-blue' | 'surface-02';

export type BaseCardProps = {
  children?: ReactNode;
  className?: string;
  htmlId?: string;
  childrenClassName?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  style?: React.CSSProperties;
  isHighlighted?: boolean;
  color?: ColorType;
  hasShadow?: boolean;
};

export const BaseCard = React.forwardRef<HTMLDivElement, BaseCardProps>(
  (
    {
      htmlId,
      children,
      className = '',
      childrenClassName = '',
      onClick,
      isHighlighted,
      style,
      color = 'default',
      hasShadow = true,
    },
    ref
  ) => {
    let colorClassName = '';
    let hoverClassName = '';
    let activeClassName = '';
    let highlightClassName = '';
    switch (color) {
      case 'default':
        colorClassName = 'bg-on-primary-01 text-text-02 border-1';
        activeClassName = 'active:bg-surface-02 active:border-primary-02 focus:border-primary-02';
        highlightClassName = 'text-text-04 border-primary-02 bg-primary-03 border-2';
        break;
      case 'surface-02':
        colorClassName = 'bg-surface-02 text-text-02  focus:border-primary-02 border-1';
        hoverClassName = '';
        activeClassName = 'active:bg-surface-02 active:border-primary-02 focus:border-primary-02';
        highlightClassName = 'text-text-01 border-primary-02 bg-on-primary-02 border-1';
        break;
      case 'purple':
        colorClassName = 'bg-purple-50 text-purple-500 hover:text-purple-600  border-1';
        activeClassName = 'active:bg-purple-100 active:border-purple-500 focus:border-purple-500';
        hoverClassName = 'hover:border-transparent hover:bg-purple-100';
        highlightClassName =
          'text-purple-600 border-purple-500 hover:text-purple-500  bg-purple-100 border-2';
        break;
      case 'dark-purple':
        colorClassName =
          'bg-purple-100 text-purple-600 hover:bg-purple-200 hover:text-purple-700  border-1';
        hoverClassName = 'hover:border-transparent hover:bg-purple-200 ';
        activeClassName = 'active:bg-purple-200 active:border-purple-600 focus:border-purple-600';
        highlightClassName =
          'text-purple-700 border-purple-600 hover:text-purple-600 bg-purple-200 border-2 ';
        break;
      case 'red':
        colorClassName = 'bg-red-50 text-red-500 hover:text-red-600  border-1';
        activeClassName = 'active:bg-red-100 active:border-red-500 focus:border-red-500';
        hoverClassName = 'hover:border-transparent hover:bg-red-100';
        highlightClassName = 'text-red-600 border-red-500 hover:text-red-500  bg-red-100 border-2';
        break;
      case 'info-blue':
        colorClassName = 'bg-informative-03 text-informative-01';
        break;
    }

    return (
      <div
        id={htmlId}
        className={`
        ${className}
        ${isHighlighted ? highlightClassName : colorClassName}
        ${onClick ? `cursor-pointer ${hoverClassName} ${activeClassName}` : ''}
        ${hasShadow ? 'shadow-md' : ''}
        flex h-fit flex-col overflow-hidden rounded-xl border-transparent`}
        onClick={onClick ? onClick : undefined}
        role={onClick ? 'button' : undefined}
        style={style}
        ref={ref}
      >
        {children && <div className={`${childrenClassName} w-full`}>{children}</div>}
      </div>
    );
  }
);
