import { forwardRef } from 'react';
import { BadgeAI, LockIcon, TertiaryButton, ToolTip } from '@stellar-lms-frontend/ui-components';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

export type AISuggestionsButtonProps =
  | {
      hasLock?: never;
      hasAlert?: boolean;
      enabled?: boolean;
      onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
    }
  | {
      hasLock?: true;
      hasAlert?: never;
      enabled?: never;
      onClick?: never;
    };

export const AISuggestionsButton = forwardRef<HTMLButtonElement, AISuggestionsButtonProps>(
  ({ onClick, hasAlert, enabled = true, hasLock }, ref) => {
    const { t } = useTranslation('translation', { keyPrefix: 'learning-activity-overview' });
    const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

    return (
      <ToolTip
        color="dark"
        size="small"
        placement="bottom"
        isEnabled={!!hasLock}
        wrappedComponent={
          <TertiaryButton
            buttonStyle="badge"
            label={
              <div className="relative">
                {tGeneral('suggestions')}
                {hasAlert && (
                  <span className="bg-warning-02 absolute -top-2 -right-4 h-3 w-3 rounded-full" />
                )}
              </div>
            }
            leftIcon={
              <BadgeAI
                label={tGeneral('ai-badge-label')}
                color="dark-purple"
                className={`${enabled ? '' : 'opacity-40'}`}
              />
            }
            rightIcon={hasLock && <LockIcon className="text-text-02 h-6 w-6" />}
            onClick={onClick}
            enabled={enabled}
            ref={ref}
          />
        }
      >
        <div className="type-body-medium max-w-[280px] whitespace-pre-wrap">
          {t('ai-button-locked-hint')}
        </div>
      </ToolTip>
    );
  }
);

AISuggestionsButton.displayName = 'AISuggestionsButton';
