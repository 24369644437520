import { Learner, Maybe, User } from '../graphql';

export const mapUsersToUsersList = (users: Maybe<User[]> | undefined, max_users?: number) => {
  const toReturn: { id: string; name: string; thumbUri: string }[] = [];
  if (users) {
    for (const user of users) {
      if (max_users !== undefined && toReturn.length >= max_users) break;

      if (user.name && user.thumbUri) {
        toReturn.push({ id: user.id, name: user.name, thumbUri: user.thumbUri });
      }
    }
  }
  return toReturn;
};

export const mapLearnersToUsersList = (
  learners: Maybe<Learner[]> | undefined,
  max_users?: number
) => {
  const toReturn: { id: string; name: string; thumbUri: string }[] = [];
  if (learners) {
    for (const learner of learners) {
      if (max_users !== undefined && toReturn.length >= max_users) break;

      if (learner.user.name && learner.user.thumbUri) {
        toReturn.push({
          id: learner.user.id,
          name: learner.user.name,
          thumbUri: learner.user.thumbUri,
        });
      }
    }
  }
  return toReturn;
};
