export interface ProgressBarProps {
  htmlId: string;
  className?: string;
  bgColorClassName?: string;
  heightClassName?: string;
  roundedClassName?: string;
  progress?: ProgressItem[];
}

export type ProgressItem =
  | number
  | {
      value: number;
      colorClassName: string;
    };

export function ProgressBar({
  htmlId,
  className = '',
  bgColorClassName = 'bg-primary-03',
  heightClassName = 'h-[6px]',
  roundedClassName = 'rounded-full lg:rounded-none',
  progress = [0],
}: ProgressBarProps) {
  return (
    <div
      id={htmlId}
      role="meter"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={getProgress(progress[0])}
      className={`${className}`}
    >
      <div className={`relative w-full ${bgColorClassName} ${roundedClassName} ${heightClassName}`}>
        {progress.map((item) => (
          <div
            key={JSON.stringify(item)}
            style={{ width: getProgress(item) + '%' }}
            className={`${getForegroundColor(
              item
            )} ${roundedClassName} ${heightClassName} absolute  w-0`}
          />
        ))}
      </div>
    </div>
  );
}

const validProgress = (progress: number): number => {
  return progress >= 0 ? (progress <= 100 ? progress : 100) : 0;
};

const getProgress = (item: ProgressItem): number => {
  if (typeof item == 'number') {
    return validProgress(item);
  }
  return validProgress(item.value);
};

const getForegroundColor = (item: ProgressItem): string => {
  if (typeof item == 'number') {
    return 'bg-gradient-to-r from-primary-gradient-start to-primary-gradient-end';
  }
  return item.colorClassName;
};

export default ProgressBar;
