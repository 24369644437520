import React, { useState, useEffect, useRef, useCallback, KeyboardEvent } from 'react';
import { API } from '@editorjs/editorjs';
import { v4 as uuidv4 } from 'uuid';
import { BulletElementType } from './list-bullets';
import { AutoResizeTextArea } from '../../../../form/text-area/auto-resize-text-area/auto-resize-textarea';

interface ListData {
  items: string[];
  style: 'ordered' | 'unordered';
}

interface ListItem {
  id: string;
  text: string;
}

interface ListComponentProps {
  isReadOnly: boolean;
  data: ListData;
  onChange: (newData: ListData) => void;
  api: API;
  Bullet?: BulletElementType;
  className?: string;
}

export const ListComponent: React.FC<ListComponentProps> = ({
  api,
  data,
  isReadOnly,
  onChange,
  Bullet,
  className = '',
}) => {
  const [items, setItems] = useState<ListItem[]>(
    data.items.map((text) => ({ id: uuidv4(), text }))
  );
  const [focusIndex, setFocusIndex] = useState<number | null>(null);
  const inputRefs = useRef<(HTMLTextAreaElement | null)[]>([]);

  useEffect(() => {
    if (focusIndex !== null && inputRefs.current[focusIndex]) {
      const inputElement = inputRefs.current[focusIndex];
      inputElement?.focus();
      inputElement?.setSelectionRange(inputElement.value.length, inputElement.value.length);
    }
  }, [focusIndex]);

  useEffect(() => {
    onChange({ items: items.map((item) => item.text), style: data.style });
  }, [items, data.style, onChange]);

  const handleKeyEvent = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>, index: number) => {
      setItems((prev) => {
        const newItems = [...prev];
        if (e.key === 'Enter') {
          e.preventDefault();
          if (index === newItems.length - 1 && newItems[index].text === '') {
            newItems.pop();
            api.blocks.insert();
            api.caret.setToBlock(api.blocks.getCurrentBlockIndex());
          } else {
            newItems.splice(index + 1, 0, { id: uuidv4(), text: '' });
            setFocusIndex(index + 1);
          }
        } else if (e.key === 'Backspace' && newItems[index].text === '') {
          e.preventDefault();
          newItems.splice(index, 1);
          setFocusIndex(index > 0 ? index - 1 : null);
          if (newItems.length === 1 && newItems[0].text.trim() === '') {
            api.blocks.delete(api.blocks.getCurrentBlockIndex());
          }
        }
        return newItems;
      });
    },
    [api]
  );

  const Tag = data.style === 'ordered' ? 'ol' : 'ul';

  return (
    <Tag className={`font-lexend ${className}`}>
      {items.map((item, index) => (
        <li
          key={item.id}
          className="flex items-center gap-4"
        >
          {Bullet && (
            <div className="shrink-0">
              <Bullet index={index} />
            </div>
          )}
          <AutoResizeTextArea
            ref={(el) => (inputRefs.current[index] = el)}
            value={item.text}
            readOnly={isReadOnly}
            onChange={(e) => {
              setItems((prev) => {
                const newItems = [...prev];
                newItems[index].text = e.target.value;
                return newItems;
              });
            }}
            theme="unstyled"
            size="small"
            maxRows={10}
            onKeyDown={(e) => handleKeyEvent(e, index)}
            className="text-text-01 w-full border-none bg-transparent p-0 focus:ring-0"
          />
        </li>
      ))}
    </Tag>
  );
};
