export const focusableSelector =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

export const getChangeEventValue = ({ target }: { target: any }): string => {
  return 'value' in target ? (target?.value as string) : '';
};

export const isEllipsisActive = (e: HTMLElement) => {
  return e.offsetWidth < e.scrollWidth;
};

export const focusNextElement = () => {
  const focusableElements = Array.from(document.querySelectorAll<HTMLElement>(focusableSelector));
  const focusedElement = document.activeElement as HTMLElement | null;

  if (focusedElement) {
    const nextIndex = (focusableElements.indexOf(focusedElement) + 1) % focusableElements.length;
    focusableElements[nextIndex]?.focus();
  }
};

export const applyStylesheet = (css: string) => {
  const styleSheet = document.createElement('style');
  styleSheet.type = 'text/css';
  styleSheet.innerText = css;
  document.head.appendChild(styleSheet);
};
