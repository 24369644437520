import {
  Dispatch,
  ForwardedRef,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

export type DynamicListProps<T extends { id: string }> = {
  initialItems: T[];
  renderItem: (parameters: {
    item: T;
    index: number;
    onChange: Dispatch<T>;
    ref: ForwardedRef<HTMLTextAreaElement>;
  }) => ReactElement;
  onItemsChange: (items: T[]) => void;
  getNewItem: () => T;
  isItemEmpty: (item: T) => boolean;
  loadingCard?: ReactNode;
  isLoading?: boolean;
  innerRef: ForwardedRef<HTMLTextAreaElement>; // not named ref for react limitations..
};

export function DynamicList<T extends { id: string }>({
  initialItems,
  renderItem,
  onItemsChange,
  getNewItem,
  isItemEmpty,
  loadingCard,
  isLoading,
  innerRef,
}: DynamicListProps<T>): ReactElement {
  const [items, setItems] = useState<T[]>([]);

  const cleanUpItems = useCallback(
    (items: T[]) => {
      const cleanItems = items.filter((item) => !isItemEmpty(item));
      if (!isLoading) {
        cleanItems.push(getNewItem());
      }
      return cleanItems;
    },
    [isLoading, getNewItem, isItemEmpty]
  );

  useEffect(() => {
    setItems(cleanUpItems(initialItems));
  }, [cleanUpItems, initialItems]);

  const onItemChange = useCallback(
    (index: number, newItem: T) => {
      setItems((prevItems) => {
        const auxItems = [...prevItems];
        auxItems[index] = newItem;

        const cleanItems = cleanUpItems(auxItems);
        onItemsChange(cleanItems.filter((i) => !isItemEmpty(i)));

        return cleanItems;
      });
    },
    [cleanUpItems, isItemEmpty, onItemsChange]
  );

  return (
    <>
      {items.map((item, index) => (
        <div key={item.id}>
          {renderItem({
            item,
            index,
            onChange: (state) => onItemChange(index, state),
            ref: innerRef,
          })}
        </div>
      ))}
      {isLoading && loadingCard}
    </>
  );
}
