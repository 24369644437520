import { useId } from 'react';
import { Input } from '../../form/input';
import { TextArea } from '../../form/text-area';
import { useTranslation } from 'react-i18next';

export type FreeTextEditClosedProps = {
  id?: string;
  questionTitle?: string;
  isMultiline: boolean;
};
export const FreeTextEditClosed: React.FC<FreeTextEditClosedProps> = ({
  id,
  questionTitle,
  isMultiline,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'survey-step-view.question-types.free-text',
  });
  const generatedId = useId();

  return isMultiline ? (
    <TextArea
      enabled={false}
      heightClassName="h-[96px]"
      htmlId={`${id ?? generatedId}-preview-textarea`}
      label={questionTitle}
      placeholder={t('free-text-answer')}
    />
  ) : (
    <Input
      enabled={false}
      htmlId={`${id ?? generatedId}-preview-input`}
      label={questionTitle}
      placeholder={t('free-text-answer')}
    />
  );
};
