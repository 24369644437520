import { useTranslation } from 'react-i18next';
import { Badge, PrimaryButton, Tip } from '@stellar-lms-frontend/ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglass } from '@fortawesome/pro-light-svg-icons';
import {
  calculateDaysRemaining,
  defaultGraphqlClient,
  hasPermission,
} from '@stellar-lms-frontend/common-utils';
import {
  SubscriptionType,
  useCurrentCompany,
  useCurrentUser,
} from '@stellar-lms-frontend/lms-graphql';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

export const TrialBadge = ({ markTipAsViewed }: { markTipAsViewed: (id: string) => void }) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const { t: tTrial } = useTranslation('translation', { keyPrefix: 'trial' });
  const {
    query: { data: company },
  } = useCurrentCompany(defaultGraphqlClient);

  const {
    query: { data: user },
  } = useCurrentUser(defaultGraphqlClient);

  useEffect(() => {
    if (company?.subscription) {
      if (isActiveTrialSubscription(company.subscription)) {
        const remainingDays = calculateDaysRemaining(new Date(), company.subscription.endDate);
        const isTrialAlmostOver = remainingDays <= 3;
        const autoShowTooltip =
          isTrialAlmostOver &&
          !user?.viewedTips?.includes(constructTipId(company.subscription.endDate));
        setTooltipOpen(autoShowTooltip);
      }
    }
  }, [company?.subscription, user?.viewedTips]);

  const hasCompanyUpdatePermission = hasPermission(company?.permissions, 'update');

  if (!company?.subscription) {
    return null;
  }

  const subscription = company.subscription;

  if (subscription.active && subscription.type !== SubscriptionType.Trial) {
    return null;
  }

  if (!subscription.active) {
    return (
      <Badge
        color="red"
        size="xl"
        className="flex items-center gap-2"
      >
        <span className="type-small">{tTrial('trial-badge-expired')}</span>
      </Badge>
    );
  }

  const remainingDays = calculateDaysRemaining(new Date(), subscription.endDate);
  const isTrialAlmostOver = remainingDays <= 3;

  return (
    <Tip
      placement={'bottom-start'}
      color="dark"
      hasArrow={true}
      arrowClassName="fill-inverse-bg"
      isOpen={tooltipOpen}
      paddingSize="large"
      widthClassName="w-[402px]"
      onClose={() => {
        markTipAsViewed(constructTipId(subscription.endDate));
        setTooltipOpen(false);
      }}
      wrappedComponent={
        <Badge
          color={isTrialAlmostOver ? 'red' : 'yellow'}
          size="xl"
          className="flex items-center gap-2"
          onClick={() => {
            setTooltipOpen(!tooltipOpen);
          }}
        >
          <FontAwesomeIcon
            icon={faHourglass}
            className="h-4 w-4 text-inherit"
          />
          <span className="type-small">{tTrial('trial-badge', { count: remainingDays })}</span>
        </Badge>
      }
    >
      <div className="space-y-4">
        <span className="type-headline-3">{tTrial('trial-tooltip-title')}</span>
        <p className="type-small">
          {tTrial('trial-tooltip-description', { count: remainingDays })}
        </p>
        {hasCompanyUpdatePermission && (
          <NavLink
            to="/organization/billing"
            className="block"
          >
            {/* TODO: it works, but not valid HTML5.. need to find a solution to get that styling in a navlink */}
            <PrimaryButton
              theme="dark"
              label={tTrial('trial-tooltip-upgrade-cta')}
              onClick={() => {
                markTipAsViewed(constructTipId(subscription.endDate));
                setTooltipOpen(false);
              }}
            />
          </NavLink>
        )}
      </div>
    </Tip>
  );
};

function isActiveTrialSubscription(subscription: { active: boolean; type: SubscriptionType }) {
  return subscription.active && subscription.type === SubscriptionType.Trial;
}

function constructTipId(endDate: Date | undefined) {
  if (endDate) {
    return `trial-expiring-soon-${endDate.toISOString()}`;
  }
  return `trial-expiring-soon`;
}
