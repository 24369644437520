import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';
import { graphql } from '@stellar-lms-frontend/lms-graphql';
import { TimeSeriesAreaChart } from '@stellar-lms-frontend/ui-components';
import { useQuery } from '@tanstack/react-query';

const getJourneysOverTime = async (companyId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query GetCompanyDashboardJourneysOverTime($companyId: ID!) {
        companyJourneysOverTime(companyId: $companyId) {
          rows {
            date
            count
          }
        }
      }
    `),
    { companyId: companyId }
  );
};

const chartConfig = {
  count: {
    label: '# journeys',
    color: 'var(--chart-1)',
  },
};

export const JourneysOverTimeChart = ({ companyId }: { companyId: string }) => {
  const { data, isLoading } = useQuery(
    ['journeys-over-time', companyId],
    () => getJourneysOverTime(companyId),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <TimeSeriesAreaChart
      rows={data?.companyJourneysOverTime.rows}
      chartConfig={chartConfig}
      isLoading={isLoading}
    />
  );
};
