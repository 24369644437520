import { formatDateTime, getNumbersArray } from '@stellar-lms-frontend/common-utils';
import {
  AutoCompleteFunc,
  BadgeAutoComplete,
  ChevronLeftIcon,
  ChevronRightIcon,
  Input,
  LinkButton,
  ItemWithCircle,
  PrimaryButton,
  Sidebar,
  TextArea,
  TrashIcon,
  PlusIcon,
  MoreVertIcon,
  CopyIcon,
  FloatingOptionsOption,
  FloatingOptions,
  Badge,
  Comment2Info,
} from '@stellar-lms-frontend/ui-components';
import { Controller, useForm } from 'react-hook-form';
import { DurationPicker, DurationPickerI18N } from '../module-list/components/duration-picker';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import {
  FloatingStepOptions,
  FloatingStepOptionsI18n,
} from '../learning-activity/components/floating-step-options';
import { useCallback, useEffect, useState } from 'react';
import { LearningActivityType, getLearningActivityTypeIcon } from '../utils/learningActivity';

export type LearningActivityEditDrawerI18N = {
  activityName: {
    label: string;
    placeholder: string;
  };
  description: {
    label: string;
    placeholder: string;
  };
  openDate: {
    label: string;
  };
  duration: {
    label: string;
    durationPicker: DurationPickerI18N;
  };
  prerequisites: {
    label: string;
    addNew: string;
    placeholder: string;
  };
  buttons: {
    cancel: string;
    save: string;
    delete: string;
  };
  errors: {
    required: string;
  };
  steps: {
    title: string;
    addStep: string;
    floatingStepOptions: FloatingStepOptionsI18n;
  };
  menu: {
    duplicate: string;
    delete: string;
  };
  types: {
    [key in LearningActivityType]: string;
  };
};

export type Step = {
  id: string;
  title: string;
};

export type LearningActivityEditFormData = {
  title: string;
  description: string;
  durationInSeconds: number;
  mandatoryPrerequisiteLearningActivityIds: { id: string; label: string }[];
  openDate: string;
};

export type LearningActivityEditDrawerProps = {
  isPlaceholder: boolean;
  isCreating?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: LearningActivityEditFormData) => void;
  onDelete: () => void;
  onDuplicate: () => void;
  data?: LearningActivityEditFormData;
  i18n: LearningActivityEditDrawerI18N;
  prerequisiteAutocompleteFunc: AutoCompleteFunc;
  laNavigation?: {
    onNavigatePrev?: () => void;
    onNavigateNext?: () => void;
  };
  steps: Step[];
  onStepClick: (stepId: string) => void;
  addStep: {
    onClick?: (callback: () => void) => void;
    isEnabled?: boolean;
    url: string;
  };
  type: LearningActivityType;
  onOpenGEARModal: () => void;
};

export const LearningActivityEditDrawer: React.FC<LearningActivityEditDrawerProps> = ({
  isPlaceholder = false,
  isCreating,
  onSave,
  onDelete,
  onDuplicate,
  isOpen,
  onClose,
  data,
  i18n,
  prerequisiteAutocompleteFunc,
  laNavigation,
  steps,
  onStepClick,
  addStep,
  type,
  onOpenGEARModal,
}) => {
  const [isSelectingCreateType, setIsSelectingCreateType] = useState(false);
  const [isOptionMenuOpen, setIsOptionMenuOpen] = useState(false);

  const schema = yup.object({
    title: yup.string().required(i18n.errors.required),
    description: yup.string(),
    durationInSeconds: yup.number().positive().required(i18n.errors.required),
  });

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm<LearningActivityEditFormData>({
    mode: 'onChange',
    defaultValues: { ...data, openDate: formatDateTime(moment(data?.openDate)) },
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(() => {
    handleSubmit((data: LearningActivityEditFormData) => {
      onSave({ ...data, openDate: moment(data.openDate).toISOString() });
    })();
  }, [handleSubmit, onSave]);

  const handleOnCreateStepClick = useCallback(() => {
    const toggleIsSelectingCreateType = () => setIsSelectingCreateType((prev) => !prev);

    return addStep.onClick
      ? addStep.onClick(toggleIsSelectingCreateType)
      : toggleIsSelectingCreateType();
  }, [addStep]);

  useEffect(() => {
    reset({ ...data, openDate: formatDateTime(moment(data?.openDate)) });
  }, [data, reset]);

  const menuOptions: FloatingOptionsOption[] = [
    {
      label: i18n.menu.duplicate,
      onClick: () => {
        onDuplicate();
        setIsOptionMenuOpen(false);
      },
      left: <CopyIcon className="text-text-01 h-4 w-4" />,
    },
    {
      label: i18n.menu.delete,
      onClick: () => {
        onDelete();
        setIsOptionMenuOpen(false);
      },
      className: 'text-negative-01',
      left: <TrashIcon className="text-negative-01 h-4 w-4" />,
    },
  ];

  const Icon = getLearningActivityTypeIcon(type);
  const typeText = i18n.types[type];

  return (
    <Sidebar
      isOpen={isOpen}
      onClose={onClose}
      position="right"
      contentClassName="md:max-w-[600px] md:w-[40%]"
      isDisableScrollEnabled={false}
      hasBackdrop={false}
      buttons={{
        hasDivider: true,
        buttons: [
          <PrimaryButton
            key={1}
            enabled={isValid}
            onClick={handleSubmit(onSubmit)}
            label={i18n.buttons.save}
          />,
          <LinkButton
            key={2}
            onClick={onClose}
            label={i18n.buttons.cancel}
          />,
        ],
      }}
      header={
        <div className="flex items-center justify-between pr-4">
          <div className="flex shrink-0 gap-2">
            <Badge
              color="grey"
              className="flex items-center gap-2"
              size="xl"
            >
              <Icon className="text-text-01 h-6 w-auto" />
              <p className="text-text-01">{typeText}</p>
              <button onClick={onOpenGEARModal}>
                <Comment2Info className="text-text-01 h-5 w-5" />
              </button>
            </Badge>
            {laNavigation && (
              <div className="flex gap-1">
                <button onClick={laNavigation.onNavigatePrev}>
                  <ChevronLeftIcon
                    className={`${
                      laNavigation.onNavigatePrev ? 'text-text-02' : 'text-text-03 opacity-60'
                    } h-8 w-8`}
                  />
                </button>
                <button onClick={laNavigation.onNavigateNext}>
                  <ChevronRightIcon
                    className={`${
                      laNavigation.onNavigateNext ? 'text-text-02' : 'text-text-03 opacity-60'
                    } h-8 w-8`}
                  />
                </button>
              </div>
            )}
          </div>
          {!isPlaceholder && (
            <FloatingOptions
              wrappedComponent={
                <button
                  disabled={isCreating}
                  className="h-fit shrink-0"
                  onClick={() => setIsOptionMenuOpen(!isOptionMenuOpen)}
                >
                  <MoreVertIcon className="text-text-02 mt-1 h-4 w-4 shrink-0" />
                </button>
              }
              isOpen={isOptionMenuOpen}
              onClose={() => setIsOptionMenuOpen(false)}
              options={menuOptions}
            />
          )}
        </div>
      }
    >
      <form
        className="flex h-full flex-col"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <div className="grow space-y-6">
          <Controller
            control={control}
            name="title"
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                htmlId={'create-learning-activity-title'}
                label={i18n.activityName.label}
                placeholder={i18n.activityName.placeholder}
                error={errors.title?.message}
                required
              />
            )}
          />
          <TextArea
            heightClassName="h-[96px]"
            htmlId={'create-learning-activity-description'}
            {...register('description')}
            label={i18n.description.label}
            placeholder={i18n.description.placeholder}
          />
          {!isPlaceholder && (
            <>
              <div className="grid grid-cols-2 gap-6">
                <Controller
                  control={control}
                  name="openDate"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      label={i18n.openDate.label}
                      htmlId="due-date"
                      htmlType="datetime-local"
                      error={errors.openDate?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="durationInSeconds"
                  render={({ field }) => (
                    <DurationPicker
                      {...field}
                      label={i18n.duration.label}
                      options={getNumbersArray(60 * 15, 4, 60 * 15)}
                      hasAdd={true}
                      i18n={i18n.duration.durationPicker}
                    />
                  )}
                />
              </div>
              <Controller
                control={control}
                name="mandatoryPrerequisiteLearningActivityIds"
                render={({ field }) => (
                  <BadgeAutoComplete
                    value={field.value?.map((s) => ({ id: s.id, label: s.label }))}
                    onChange={field.onChange}
                    autoCompleteFunc={prerequisiteAutocompleteFunc}
                    allowAdditions={false}
                    i18n={{
                      fieldLabel: i18n.prerequisites.label,
                      addLabel: i18n.prerequisites.addNew,
                      placeholder: i18n.prerequisites.placeholder,
                    }}
                  />
                )}
              />
              <div>
                <p className="text-text-02 font-medium">{i18n.steps.title}</p>
                <ul className="mt-2 space-y-2">
                  {steps.map((step, index) => (
                    <ItemWithCircle
                      onClick={() => onStepClick(step.id)}
                      circleChildren={index + 1}
                      key={step.id}
                      className="w-fit"
                    >
                      <p className="text-text-02 hover:text-text-04 font-medium">{step.title}</p>
                    </ItemWithCircle>
                  ))}
                  <FloatingStepOptions
                    placement="bottom-start"
                    wrappedComponent={
                      <button
                        disabled={!addStep.isEnabled}
                        type="button"
                        onClick={handleOnCreateStepClick}
                      >
                        <ItemWithCircle
                          circleChildren={
                            <PlusIcon
                              className={`${
                                addStep.isEnabled ? 'text-primary-02' : 'text-text-03'
                              }`}
                            />
                          }
                        >
                          <p
                            className={`${
                              addStep.isEnabled
                                ? 'text-primary-02 hover:text-primary-01'
                                : 'text-text-03'
                            } font-medium`}
                          >
                            {i18n.steps.addStep}
                          </p>
                        </ItemWithCircle>
                      </button>
                    }
                    isOpen={isSelectingCreateType}
                    onClose={() => setIsSelectingCreateType(false)}
                    stepCreateUrl={addStep.url}
                    i18n={i18n.steps.floatingStepOptions}
                    onSelectStepType={onClose}
                  />
                </ul>
              </div>
            </>
          )}
        </div>
      </form>
    </Sidebar>
  );
};
