import { useEffect, useMemo, ReactNode } from 'react';
import ReactDOM from 'react-dom';

type PortalProps = {
  children: ReactNode;
  portalId?: string;
};

export const Portal: React.FC<PortalProps> = ({ children, portalId = 'STORYBOOK_PORTAL_ID' }) => {
  const container = useMemo(() => {
    const existingContainer = document.getElementById(portalId);

    if (existingContainer) {
      return existingContainer;
    }

    return document.createElement('div');
  }, [portalId]);

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      if (!document.getElementById(portalId)) {
        document.body.removeChild(container);
      }
    };
  }, [container, portalId]);

  return ReactDOM.createPortal(children, container);
};
