import { CheckBox } from '../../checkbox/checkbox';
import KeyBoardShortcut from '../../../keyboard-shortcut/keyboard-shortcut';
import TextArea from '../../text-area/text-area';
import { SelectOptionType } from '../types';
import { SelectCheckBox } from '../edit-select/components/select-checkbox';
import { SelectBox } from '../components/select-box/select-box';
import { useTranslation } from 'react-i18next';

export type MultiSelectProps = {
  options: SelectOptionType[];
  showCorrectAnswerSelectors?: boolean;
  theme?: 'blue' | 'normal' | 'dark';
} & (
  | {
      isDisabled?: false;
      onChange: (selectedValues: SelectOptionType[]) => void;
      value: SelectOptionType[];
    }
  | {
      isDisabled: true;
      onChange?: never;
      value?: SelectOptionType[];
    }
);

export const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  onChange,
  value = [],
  isDisabled,
  theme,
  showCorrectAnswerSelectors,
}) => {
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const { t } = useTranslation('translation', { keyPrefix: 'assessment-step-view' });

  const onChangeToggle = isDisabled
    ? undefined
    : (option: SelectOptionType) => {
        const exist = value.find((v) => v.id === option.id);
        const newOption = { ...option, userInput: option.label };
        exist ? onChange(value.filter((v) => v.id !== option.id)) : onChange([...value, newOption]);
      };

  const renderInputForOther = (option: SelectOptionType) => {
    const otherIndex = value.findIndex((curr) => curr.id === option.id && option.other);
    if (otherIndex !== -1) {
      return (
        <div className="space-y-2">
          <label
            htmlFor="input-other"
            className="text-text-02 font-lexend"
          >
            {tGeneral('other')}
          </label>
          <TextArea
            htmlId="input-other"
            onChange={(e) => {
              value[otherIndex].userInput = e.target.value;
              onChange?.(value);
            }}
            text={value[otherIndex].userInput}
            onKeyUp={(e) => e.stopPropagation()}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const renderInput = (option: SelectOptionType, index: number) => {
    const isChecked = value.some((selected) => selected.id === option.id);

    return (
      <div className="flex flex-row">
        <CheckBox
          name={`option-${index}`}
          value={isChecked}
          onChange={() => null}
          readOnly
          className="mr-4 mt-[2px]"
        />
        <label
          htmlFor={`option-${index}`}
          className={`mr-4 grow select-none whitespace-pre-wrap ${
            isDisabled ? 'cursor-[inherit]' : 'cursor-pointer'
          }`}
        >
          {option.other ? tGeneral('other') : option.label}
        </label>

        {onChangeToggle && (
          <div className="h-6 w-6 shrink-0">
            <KeyBoardShortcut
              index={index}
              action={(shouldTrigger) => shouldTrigger && onChangeToggle(option)}
            />
          </div>
        )}
      </div>
    );
  };

  const renderSelectOptionBox = (option: SelectOptionType, index: number) => {
    const isSelected = value.some(
      (value) => value.id === option.id || (value.other && option.other)
    );

    return (
      <div
        className={`flex w-full items-center gap-4 ${isDisabled ? '' : 'cursor-pointer'}`}
        onClick={() => {
          onChangeToggle?.(option);
        }}
      >
        {isSelected ? (
          <SelectBox
            type="selected"
            data-cy={`multi-select-option-selected-${index}`}
          >
            {renderInput(option, index)}
          </SelectBox>
        ) : (
          <SelectBox
            type={theme === 'dark' ? 'neutral-dark' : theme === 'blue' ? 'blue' : 'neutral-white'}
            data-cy={`multi-select-option-neutral-${index}`}
          >
            {renderInput(option, index)}
          </SelectBox>
        )}
        {showCorrectAnswerSelectors && (
          <SelectCheckBox
            theme={theme === 'dark' ? 'grey' : theme === 'blue' ? 'blue' : 'colored'}
            isDisabled={isDisabled}
            isMultiSelect
            checked={option.correct ?? false}
            hasAtLeastOneChecked={options.filter((o) => o.correct).length > 0}
          />
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col space-y-4">
      {options.map((option, index) => (
        <div
          key={option.id}
          className="space-y-4"
        >
          {renderSelectOptionBox(option, index)}
          {renderInputForOther(option)}
        </div>
      ))}
    </div>
  );
};

export default MultiSelect;
