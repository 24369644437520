import { CheckLight, Heading4, PrimaryButton } from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export type SuccessStatePageProps = {
  amountOfQuestions: number;
};
export const SuccessStatePage = ({ amountOfQuestions }: SuccessStatePageProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'spaced-repetition.success' });

  const navigateToHome = () => {
    navigate('/');
  };

  return (
    <div className="flex h-full w-full items-center justify-center bg-white">
      <div className="flex h-full max-w-lg flex-col items-center justify-between space-y-8 px-8 py-10 lg:justify-center">
        <div className="flex w-full grow flex-col items-center justify-center space-y-8 lg:grow-0">
          <CheckLight />
          <div className="space-y-4 text-center">
            <Heading4>{t('title')}</Heading4>
            <p
              className="font-lexend font-regular text-text-02 text-base leading-normal"
              data-cy="success-message"
            >
              {t('message', { count: amountOfQuestions })}
            </p>
          </div>
        </div>

        <div className="flex w-full shrink-0 flex-col items-center space-y-4">
          <PrimaryButton
            htmlId={'home-button'}
            label={'Take me home'}
            fullWidth
            onClick={navigateToHome}
          />
        </div>
      </div>
    </div>
  );
};
