import {
  AddCard,
  AlertTriangle,
  ChevronDownIcon,
  ChevronUpIcon,
  FloatingBox,
  FontSmall,
  Heading3,
  ListCard,
  TrashIcon,
} from '@stellar-lms-frontend/ui-components';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectiveModal } from './objective-modal';
import { Objective } from '@stellar-lms-frontend/lms-api';

export type ObjectivesSectionProps = {
  value?: Objective[];
  onChange: (objectives: Objective[]) => void;
};

export const ObjectivesSection: React.FC<ObjectivesSectionProps> = ({ value = [], onChange }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'course-details.objectives' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const moveObjective = useCallback(
    (action: 'up' | 'down', index: number) => {
      const temp = value[index];

      if (action === 'up') {
        if (index === 0) return;

        value[index] = value[index - 1];
        value[index - 1] = temp;
      }
      if (action === 'down') {
        if (index === value.length - 1) return;

        value[index] = value[index + 1];
        value[index + 1] = temp;
      }
      onChange(value);
    },
    [value, onChange]
  );

  const onObjectiveAdd = useCallback(
    (objective: Objective) => {
      if (value.some((o) => o.id === objective.id)) return;

      onChange([...value, objective]);
    },
    [onChange, value]
  );

  return (
    <>
      <ObjectiveModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onChange={onObjectiveAdd}
      />
      <div>
        <Heading3 className="text-text-04">{t('title')}</Heading3>
        <FontSmall
          type="medium"
          className="text-text-02 mt-6"
        >
          {t('explanation')}
        </FontSmall>
        <ul className="mt-4 w-full space-y-4">
          <AddCard
            isEnabled
            label={t('cta')}
            onClick={() => setIsModalOpen(true)}
            rightIcon={
              value.length >= 3 ? (
                <FloatingBox
                  placement="bottom-end"
                  className="w-[300px]"
                  onClose={() => setIsAlertOpen(false)}
                  wrappedComponent={
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setIsAlertOpen(true);
                      }}
                      onMouseEnter={() => setIsAlertOpen(true)}
                      onMouseLeave={() => setIsAlertOpen(false)}
                    >
                      <AlertTriangle className="text-orange-500" />
                    </button>
                  }
                  isOpen={isAlertOpen}
                >
                  <div className="bg-inverse-bg text-on-inverse-01 p-3 text-sm">
                    <p>{t('alert')}</p>
                  </div>
                </FloatingBox>
              ) : undefined
            }
          />
          {value.map((objective, index) => (
            <ListCard
              id={objective.id}
              key={objective.id}
              options={[
                {
                  onClick: () => {
                    moveObjective('up', index);
                  },
                  left: <ChevronUpIcon className="h-4 w-4" />,
                  label: tGeneral('move-up'),
                },
                {
                  onClick: () => {
                    moveObjective('down', index);
                  },
                  left: <ChevronDownIcon className="h-4 w-4" />,
                  label: tGeneral('move-down'),
                },
                {
                  onClick: () => {
                    onChange(value.filter((o) => o.id !== objective.id));
                  },
                  left: <TrashIcon className="text-negative-01 h-4 w-4" />,
                  label: tGeneral('delete'),
                  className: 'text-negative-01',
                },
              ]}
            >
              <FontSmall
                type="default"
                className="flex h-8 items-center"
              >
                {objective.name}
              </FontSmall>
            </ListCard>
          ))}
        </ul>
      </div>
    </>
  );
};
