import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ArticleData = {
  link: string;
  iconUrl?: string | null;
  title: string;
  description?: string;
  source: string;
};

export const ArticlePreview = ({ data }: { data: ArticleData }) => (
  <div className="space-y-2">
    <div className="flex space-x-2">
      {data.iconUrl && (
        <img
          className="h-6 w-6 shrink-0"
          src={data.iconUrl}
          alt="favicon"
        />
      )}

      <div className="type-body-medium text-text-01 font-lexend line-clamp-1 grow">
        {data.title}
      </div>
      <FontAwesomeIcon
        icon={faArrowUpRightFromSquare}
        className="text-md cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          window.open(data.link, '_blank');
        }}
      />
    </div>

    {data.description && (
      <div className="type-tiny font-lexend text-text-02 line-clamp-3">{data.description}</div>
    )}
    <div className="type-tiny font-lexend text-text-02">{data.source}</div>
  </div>
);
