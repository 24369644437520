import { QuestionType, SurveyAnswer, SurveyResult } from '@stellar-lms-frontend/lms-api';
import {
  Badge,
  FlexTable,
  Heading3,
  EmptyState,
  FlexTableI18N,
} from '@stellar-lms-frontend/ui-components';

import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  CellContext,
} from '@tanstack/react-table';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { LearnerCell } from './components/learner-cell';
import { QuestionItem } from './components/question-item';
import {GraphQLClient} from "graphql-request";

type RowShape = SurveyAnswer;
const columnHelper = createColumnHelper<RowShape>();

type SurveyListViewI18N = {
  table: FlexTableI18N;
  columns: {
    answer: string;
    learner: string;
    date: string;
  };
  empty: {
    title: string;
    description: string;
  };
  rows: {
    answer: (count: number) => string;
  };
  question: {
    type: (type: QuestionType) => string;
  };
};

// FUTURE: This can become more reusable without so much management coming from the caller.
//         For example, we could embed the relative routing of a master detail view into a generic component.
// .       This should also be adapted to use the split view, but first we need to figure out why some of
// .       the widths in the design of this view are different from our split view.
export type SurveyListViewProps = {
  surveyDetails?: SurveyResult[];
  currentQuestion?: SurveyResult;
  currentAnswers: SurveyAnswer[];
  questionId: string;
  onSelectQuestion: (questionId: string) => void;
  // Maybe there is a better solution than this. Exposing it as a global might be ok in this case.
  graphQLClient: GraphQLClient;
  // This should be different, but needs some thinking
  i18n: SurveyListViewI18N;
};

export const SurveyListView: React.FC<SurveyListViewProps> = ({
  surveyDetails,
  currentQuestion,
  currentAnswers,
  questionId,
  onSelectQuestion,
  graphQLClient,
  i18n,
}) => {
  const textCell = useCallback(
    (info: CellContext<SurveyAnswer, string[]>) => (
      <ul>
        {info.getValue().map((a) => (
          <li
            className="list-inside list-disc"
            key={a}
          >
            {a.split('\n').map((w) => (
              <p
                key={w}
                className="first:inline"
              >
                {w}
              </p>
            ))}
          </li>
        ))}
      </ul>
    ),
    []
  );

  const learnerFullNameCell = useCallback(
    (info: CellContext<SurveyAnswer, string>) => (
      <LearnerCell
        learnerId={info.getValue()}
        graphQLClient={graphQLClient}
      />
    ),
    [graphQLClient]
  );

  const columns = useMemo(() => {
    return [
      columnHelper.accessor((row) => row.answers, {
        id: 'text',
        header: i18n.columns.answer,
        cell: textCell,
      }),
      columnHelper.accessor((row) => row.learnerId, {
        id: 'learnerFullName',
        header: i18n.columns.learner,
        cell: learnerFullNameCell,
      }),
      columnHelper.accessor((row) => row.time, {
        id: 'date',
        header: i18n.columns.date,
        cell: (info) => moment(info.getValue()).format('DD MMM yyyy'),
      }),
    ];
  }, [i18n.columns, learnerFullNameCell, textCell]);

  const table = useReactTable({
    data: currentAnswers || [],
    columns,
    enableColumnResizing: false,
    initialState: { sorting: [{ id: 'text', desc: false }], pagination: { pageSize: 20 } },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div className="font-lexend flex h-full whitespace-normal px-4 xl:px-16">
      {/* Master/sidebar */}
      <div className="border-r-1 min-w-[300px] pt-16">
        {surveyDetails?.map((question) => (
          <QuestionItem
            key={question.questionId}
            active={questionId === question.questionId}
            onClick={onSelectQuestion}
            question={question}
            answerText={i18n.rows.answer(question.answers.length)}
            i18n={{
              question: {
                type: i18n.question.type,
              },
            }}
          />
        ))}
      </div>

      {/* Detail/content */}
      <div className="w-full grow overflow-auto p-16">
        {surveyDetails?.length === 0 ? (
          <EmptyState
            title={i18n.empty.title}
            description={i18n.empty.description}
            verticalCenter
          />
        ) : (
          <>
            <Heading3>{currentQuestion?.questionTitle}</Heading3>
            {currentQuestion?.questionType && (
              <Badge
                className="my-3"
                color="green"
                size="lg"
              >
                {i18n.question.type(currentQuestion.questionType)}
              </Badge>
            )}
            <FlexTable
              i18n={i18n.table}
              table={table}
            />
          </>
        )}
      </div>
    </div>
  );
};
