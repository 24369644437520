import { Modal } from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { IFrame } from '../../iframe-step-view';
import { useCurrentUser } from '@stellar-lms-frontend/lms-graphql';
import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';

export type ContactSalesModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ContactSalesModal: React.FC<ContactSalesModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'contact-sales-modal' });

  const {
    query: { data: user },
  } = useCurrentUser(defaultGraphqlClient);

  const url = new URL('https://meetings-eu1.hubspot.com/meetings/stella-collins?embed=true');

  if (user) {
    url.searchParams.set('email', user.email);
    user.firstName && url.searchParams.set('firstName', user.firstName);
    user.lastName && url.searchParams.set('lastName', user.lastName);
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('title')}
      className="bg-surface-01 h-[830px] w-[850px]"
    >
      <IFrame
        className="h-full"
        title={t('title')}
        url={url.toString()}
      />
    </Modal>
  );
};
