import { useTranslation } from 'react-i18next';
import { Switch } from '../../switch/switch';
import { AutoResizeTextArea } from '../../text-area';
import { FillBlanksFormErrors, FillBlanksSettings } from './fill-blanks-settings-card';

export type FillBlanksSettingsFormProps = {
  onChange: (value: FillBlanksSettings) => void;
  value: FillBlanksSettings;
  errors?: FillBlanksFormErrors;
};
export const FillBlanksSettingsForm: React.FC<FillBlanksSettingsFormProps> = ({
  value,
  onChange,
  errors,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'assessment-step-view.question-types.fill-blanks',
  });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const updateFillTextValue = (text: string) => onChange({ ...value, text });
  const updateFeedbackValue = (feedback: string) => onChange({ ...value, feedback });
  const updateAddToSpacedRepetitionValue = (spacedRepetitionEnabled: boolean) =>
    onChange({ ...value, spacedRepetitionEnabled });

  return (
    <div className="text-text-02 space-y-4">
      <AutoResizeTextArea
        required
        id="fitb-blank-text-input"
        minRows={3}
        maxRows={6}
        label={t('name')}
        onChange={(event) => updateFillTextValue(event.target.value)}
        value={value.text}
        placeholder={t('title-placeholder')}
        error={errors?.text?.message}
      />
      <AutoResizeTextArea
        required
        minRows={1}
        maxRows={3}
        id="fitb-feedback-input"
        label={tGeneral('feedback')}
        placeholder={tGeneral('feedback')}
        onChange={(event) => updateFeedbackValue(event.target.value)}
        value={value.feedback}
        error={errors?.feedback?.message}
      />
      <div className="flex items-center">
        <Switch
          label={t('add-to-spaced-repetition')}
          value={value.spacedRepetitionEnabled}
          onChange={(checked) => updateAddToSpacedRepetitionValue(checked)}
        />
      </div>
    </div>
  );
};
