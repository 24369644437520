import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ProgressBar } from './components/progress-bar';
import { WizardI18N, WizardStep } from './types';
import {
  Nullable,
  useEventListener,
  useStateForFunction,
} from '@stellar-lms-frontend/common-utils';
import { WizardContext } from './wizard-context';
import { EditingHeader } from '../header';
import { RightArrowIcon } from '../icons';
import { useNavigate } from 'react-router-dom';
import ButtonSelector, { BUTTON_TYPES } from '../buttons/button-selector/button-selector';
import { TertiaryButton } from '../buttons';
import { SpinnerLoader } from '../loaders';

export type WizardProps = {
  steps: WizardStep[];
  allowStepClick: boolean;
  i18n: WizardI18N;
};

export const Wizard: React.FC<WizardProps> = ({ steps, allowStepClick, i18n }) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [isCurrentStepValid, setIsCurrentStepValid] = useState(true);
  const navigate = useNavigate();
  const stepContainerRef = useRef<HTMLDivElement>(null);
  const [stepChanged, setStepChanged] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);

  // This might need to change if we want to render all the steps at all times (instead of just when they are the current step)
  // we need to keep track of the nextFunc per step. Could be that this is necessary for the animation
  const [nextFunc, setNextFunc] = useStateForFunction<Nullable<() => Promise<boolean>>>(undefined);
  const [prevFunc, setPrevFunc] = useStateForFunction<Nullable<() => Promise<boolean>>>(undefined);
  const [enterFunc, setEnterFunc] = useStateForFunction<Nullable<() => void>>(undefined);

  // This is a hack to scroll to the top after the state is updated.
  useEffect(() => {
    if (stepChanged) {
      stepContainerRef.current?.scrollTo(0, 0);
      setStepChanged(false);
    }
  }, [stepChanged]);

  const goToStep = useCallback(
    async (newStepIndex: number) => {
      const actionFunc = newStepIndex > activeStepIndex ? nextFunc : prevFunc;

      // If there is no externally bound action or it succeeds, we continue with the navigate.
      if (actionFunc) {
        await actionFunc();
      }
      setNextFunc(undefined);
      setPrevFunc(undefined);
      setEnterFunc(undefined);
      setIsCurrentStepValid(true);
      setActiveStepIndex(newStepIndex);
      setStepChanged(true);
    },
    [activeStepIndex, nextFunc, prevFunc, setEnterFunc, setNextFunc, setPrevFunc],
  );

  const hasNext = activeStepIndex < steps.length - 1;

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      enterFunc ? enterFunc() : handleNext();
    }
  };

  useEventListener('keydown', handleKeyDown, true);

  const handleNext = () => {
    hasNext && goToStep(activeStepIndex + 1);
  };

  const isLast = activeStepIndex === steps.length - 1;

  return (
    <WizardContext.Provider
      value={{
        activeStepIndex,
        setActiveStepIndex,
        isCurrentStepValid,
        setIsCurrentStepValid,
        nextFunc,
        setNextFunc,
        prevFunc,
        setPrevFunc,
        goToStep,
        enterFunc,
        setEnterFunc,
      }}
    >
      <div className="flex h-full w-full flex-col">
        <EditingHeader
          className="shrink-0 grow-0"
          backButton={{
            onClick: () => navigate('/'),
            label: i18n.buttons.left,
          }}
          title={i18n.title}
          rightElements={[
            <TertiaryButton
              enabled={activeStepIndex !== 0}
              label={i18n.buttons.rightSecondary}
              onClick={() => goToStep(activeStepIndex - 1)}
            />,
            <ButtonSelector
              enabled={isCurrentStepValid && !actionInProgress}
              label={isLast ? i18n.buttons.rightPrimaryLast : i18n.buttons.rightPrimaryDefault}
              leftIcon={
                actionInProgress ? (
                  <SpinnerLoader
                    color="grey"
                    className="block text-[3px]"
                  />
                ) : (
                  <RightArrowIcon />
                )
              }
              onClick={async () => {
                setActionInProgress(true);
                await goToStep(activeStepIndex + 1);
                setActionInProgress(false);
              }}
              type={isLast ? BUTTON_TYPES.PRIMARY : BUTTON_TYPES.SECONDARY}
              enterListener={isLast ? false : undefined}
            />,
          ]}
        />
        <div className="shrink-0 grow-0">
          <ProgressBar
            allowStepClick={allowStepClick}
            steps={steps}
          />
        </div>
        <div className="flex grow flex-col overflow-hidden">
          <div
            ref={stepContainerRef}
            className="flex grow flex-col overflow-auto"
          >
            {steps[activeStepIndex]?.component}
          </div>
        </div>
      </div>
    </WizardContext.Provider>
  );
};
