import { faArrowsRepeat, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MoreVertIcon } from '../../../icons';
import { FloatingOptions } from '../../../wrappers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { renderFileIcon } from '../../../icons/file-icons.util';

type FileInputCompleteProps = {
  file: File;
  onReplaceFile: () => void;
  onDeleteFile: () => void;
};

export const FileInputComplete: React.FC<FileInputCompleteProps> = ({
  file,
  onDeleteFile,
  onReplaceFile,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.file-input' });

  const [optionsMenuVisible, setOptionsMenuVisible] = useState<boolean>(false);

  return (
    <div className="flex flex-row items-center justify-center space-x-4">
      {renderFileIcon(file.type)}
      <span className="text-text-01 grow">{file.name}</span>

      <FloatingOptions
        isOpen={optionsMenuVisible}
        onClose={() => setOptionsMenuVisible(false)}
        placement="bottom-start"
        widthClassName="w-[600px] lg:w-[640px] 2xl:w-[720px]"
        wrappedComponent={
          <div
            className="p-3"
            onClick={(e) => {
              e.stopPropagation();
              setOptionsMenuVisible(true);
            }}
            role="button"
          >
            <MoreVertIcon className="h-[18px] w-[18px] shrink-0 text-gray-500" />
          </div>
        }
        options={[
          {
            key: 'replace',
            label: <span className="text-text-01">{t('replace')}</span>,
            left: (
              <FontAwesomeIcon
                icon={faArrowsRepeat}
                className="text-text-01"
              />
            ),
            onClick: (e) => {
              e.stopPropagation();
              onReplaceFile();
            },
          },
          {
            key: 'remove',
            label: <span className="text-negative-01">{t('remove')}</span>,
            left: (
              <FontAwesomeIcon
                icon={faTrashCan}
                className="text-negative-01"
              />
            ),
            onClick: (e) => {
              e.stopPropagation();
              onDeleteFile();
            },
          },
        ]}
      />
    </div>
  );
};
