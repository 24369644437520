import { useTranslation } from 'react-i18next';

type StepCounterProps = {
  curr: number | string;
  total: number | string;
  className?: string;
};

export const StepCounter = ({ curr, total, className = '' }: StepCounterProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'general' });

  return <div className={`${className} font-lexend`}>{`${curr} ${t('of')} ${total}`}</div>;
};
