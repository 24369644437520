import { DataCy } from '@stellar-lms-frontend/common-utils';
import { ReactNode, useMemo } from 'react';

export type SelectBoxTypes =
  | 'unselected-positive'
  | 'positive'
  | 'negative'
  | 'selected'
  | 'neutral-dark'
  | 'neutral-white'
  | 'disabled'
  | 'blue';

export type SelectBoxProps = DataCy & {
  children: ReactNode;
  type: SelectBoxTypes;
  className?: string;
};

export const SelectBox: React.FC<SelectBoxProps> = ({
  children,
  'data-cy': dataCy,
  type,
  className = '',
}) => {
  const selectBoxClassName = useMemo(() => {
    switch (type) {
      case 'unselected-positive':
        return 'text-positive-01 bg-surface-03 border-transparent';
      case 'positive':
        return 'text-positive-01 border-positive-02 bg-positive-03';
      case 'negative':
        return 'text-negative-01 border-negative-02 bg-negative-03';
      case 'selected':
        return 'text-primary-01 border-primary-02 bg-primary-03';
      case 'blue':
        return 'text-text-04 bg-blue-200';
      case 'neutral-dark':
        return `text-text-01 bg-surface-02-hover border-transparent`;
      case 'neutral-white':
        return `text-text-01 border-transparent bg-surface-02`;
      case 'disabled':
        return 'text-text-03 bg-surface-03 border-transparent';
    }
  }, [type]);

  return (
    <div
      data-cy={dataCy}
      className={`${selectBoxClassName} ${className} font-lexend w-full select-none rounded-xl border-2 p-4`}
    >
      {children}
    </div>
  );
};
