import { GraphQLClient } from 'graphql-request';
import { GetLightCourseInfoQuery, GetLightCourseInfoQueryVariables, graphql } from '../graphql';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

// CLEANUP: provide this as context/state with zustand instead of having a hook for it
const QUERY_KEY = 'COURSE_LIGHT';
export const useCurrentCourse = (
  getLightCourseInfo: (courseId: string) => Promise<GetLightCourseInfoQuery['course']>,
  courseId?: string
) => {
  const queryClient = useQueryClient();

  const query = useQuery(
    [QUERY_KEY, courseId],
    () => (courseId ? getLightCourseInfo(courseId) : undefined),
    {
      enabled: !!courseId,
      cacheTime: 20 * 1000, // 20 seconds, can be increased later, but takes proper cache invalidation
      staleTime: 10 * 1000, // 10 seconds, can be increased later, but takes proper cache invalidation
    }
  );

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries([QUERY_KEY, courseId]);
  }, [queryClient]);

  return {
    query: query,
    invalidateCache: invalidateCache,
  };
};

export const getLightCourseInfo = (
  client: GraphQLClient,
  variables: GetLightCourseInfoQueryVariables
) => {
  return client
    .request({
      document: graphql(
        `
          query GetLightCourseInfo($courseId: ID!) {
            course(id: $courseId) {
              id
              title
              createdAt
              updatedAt
              language
              realtimeChannel {
                id
              }
              aiProject {
                id
                audience
                realtimeChannel {
                  id
                }
                skill
                industry
                courseGenerationProgress
              }
              description
              permissions
            }
          }
        `
      ),
      // we only wanted id from aiProject, but as we use the Course type defined in the graphql schema we needed to include all required fields in JourneyGenerationProject
      variables: variables,
    })
    .then((data) => data.course);
};
