import { Course, graphql, QueryCourseArgs } from '@stellar-lms-frontend/lms-graphql';
import { GraphQLClient } from 'graphql-request';

export const getLearnerDashboard = (client: GraphQLClient, variables: QueryCourseArgs) => {
  return client
    .request(
      graphql(`
        query LearnerDashboard($id: ID!) {
          course(id: $id) {
            id
            title
            designers {
              id
              name
              thumbUri
            }
            mentors {
              id
              name
              thumbUri
            }
            outline {
              modules {
                id
                title
                description
                learningActivities {
                  steps {
                    completed
                    visited
                  }
                }
              }
            }
            description
            learners {
              user {
                id
                name
                thumbUri
              }
            }
            skills
            objectives {
              id
              name
            }
            transferObjectives {
              id
              name
              description
            }
            language
            invites {
              id
              name
              email
              message
              inviteMessageUrl
            }
            courseStats {
              ... on UserCourseStats {
                timeSpentInS
                progress
                nrOfActionsCompleted
                totalNrOfActions
              }
            }
          }
        }
      `),
      variables
    )
    .then((res) => res.course as Course);
};
