import { useQuery } from '@tanstack/react-query';
import { StackedHalfRadialChart } from '@stellar-lms-frontend/ui-components';
import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';
import { graphql } from '@stellar-lms-frontend/lms-graphql';

const getCourseMemberRoles = async (companyId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query GetCompanyDashboardCourseMemberRoles($companyId: ID!) {
        companyCourseMemberRoles(companyId: $companyId) {
          rows {
            category
            count
          }
        }
      }
    `),
    { companyId: companyId }
  );
};
const chartConfig = {
  LEARNER: {
    label: 'Learner',
    color: 'var(--chart-1)',
  },
  COLLABORATOR: {
    label: 'Collaborator',
    color: 'var(--chart-2)',
  },
  AUTHOR: {
    label: 'Author',
    color: 'var(--chart-3)',
  },
};

export const CompanyCourseMemberRolesChart = ({ companyId }: { companyId: string }) => {
  const { data, isLoading } = useQuery(
    ['company-course-member-roles', companyId],
    () => getCourseMemberRoles(companyId),
    { refetchOnWindowFocus: false }
  );

  return (
    <StackedHalfRadialChart
      isLoading={isLoading}
      rows={data?.companyCourseMemberRoles?.rows}
      chartConfig={chartConfig}
      showValueInBar={true}
    />
  );
};
