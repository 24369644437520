import { ApplyIllustration, Heading3 } from '@stellar-lms-frontend/ui-components';
import { GEARModalApplyImage } from './images';
import { useTranslation } from 'react-i18next';

export const Apply: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'gear-template-modal.apply' });

  return (
    <div className="text-text-02 space-y-6">
      <div className="flex items-center gap-2">
        <ApplyIllustration />
        <Heading3 className="text-text-04">{t('title')}</Heading3>
      </div>
      <p className="font-semibold">{t('training-courses')}</p>
      <p>{t('learning-process')}</p>
      <p>{t('learners-adaptation')}</p>
      <img
        src={GEARModalApplyImage}
        alt=""
        className="h-auto w-full"
      />
      <p>{t('learners-connect')}</p>
      <p>{t('spaced-practice')}</p>
      <p>{t('feedforward')}</p>
      <ul className="ml-6 space-y-2">
        <li className="list-disc">{t('list-positive')}</li>
        <li className="list-disc">{t('list-improve')}</li>
      </ul>
    </div>
  );
};
