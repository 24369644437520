import { ContentEditable } from '../../components/content-editable';
import { SecondaryButton } from '../../../buttons';
import { API } from '@editorjs/editorjs';
import { DiscussionPromptData } from './discussion-prompt-plugin';
import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import { DiscussIcon } from '../../../icons';
import { DiscussNotAvailable } from './discuss-not-available';

type Props = {
  id: string;
  data: DiscussionPromptData;
  isReadOnly: boolean;
  onChange: (data: DiscussionPromptData) => void;
  onDiscussClick: VoidFunc | undefined;
  api: API;
};

export const DiscussionPromptComponent = ({
  isReadOnly,
  data,
  onChange,
  api,
  id,
  onDiscussClick,
}: Props) => {
  return (
    <div>
      <div className="font-lexend bg-surface-03 my-4 flex items-start gap-6 rounded-xl p-6">
        <DiscussIcon className="text-text-02 shrink-0" />
        <div className="space-y-4">
          <ContentEditable
            onChange={(value) => {
              onChange({ ...data, title: value });
            }}
            value={data.title}
            contentEditable={!isReadOnly}
            placeholder={api.i18n.t('title-placeholder')}
            className={` text-text-01 text-xl outline-none`}
          />
          <div className="grow overflow-hidden">
            <ContentEditable
              onChange={(value) => onChange({ ...data, text: value })}
              value={data.text}
              placeholder={api.i18n.t('description-placeholder')}
              contentEditable={!isReadOnly}
              className={`text-text-04 w-full overflow-hidden break-words outline-none`}
            />
          </div>
          {onDiscussClick ? (
            <SecondaryButton
              label={api.i18n.t('show-discuss-panel-button')}
              onClick={onDiscussClick}
            />
          ) : (
            <DiscussNotAvailable api={api} />
          )}
        </div>
      </div>
    </div>
  );
};
