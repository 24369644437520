import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Sidebar,
  PrimaryButton,
  LinkButton,
  InputAutoComplete,
  Suggestion,
  Dropdown,
} from '@stellar-lms-frontend/ui-components';
import { useCallback, useEffect } from 'react';
import { AccessTypes } from '../../../../table/learners-table/components/access-cell';
import { SearchItem } from '../../../../types/types';
import { useTranslation } from 'react-i18next';

export type AddToJourneyFormData = {
  journeyId: string;
  access: AccessTypes;
  mentorId: string;
};

export type AddToJourneySidebarProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: AddToJourneyFormData) => void;
  membersList: SearchItem[];
  journeysList: SearchItem[];
  selectedMembersIdList: string[];
};

export const AddToJourneySidebar: React.FC<AddToJourneySidebarProps> = ({
  isOpen,
  onClose,
  onSubmit,
  membersList,
  journeysList,
  selectedMembersIdList,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organization.members-tab.options-menu.add-to-journey-sidebar',
  });

  const schema = Yup.object().shape({
    journeyId: Yup.string().required(t('forms.validation.required')),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<AddToJourneyFormData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const selectedAccesss = watch('access');

  useEffect(() => {
    if (selectedAccesss !== 'learner') {
      setValue('mentorId', '');
    }
  }, [selectedAccesss, setValue]);

  const renderMentorField = useCallback(() => {
    if (selectedAccesss === 'learner') {
      return (
        <Controller
          control={control}
          name="mentorId"
          render={({ field }) => (
            <InputAutoComplete
              emptyLabel={t('forms.mentor.empty-label')}
              suggestions={membersList.filter(
                (member) => !selectedMembersIdList.includes(member.id)
              )}
              label={t('forms.mentor.label')}
              onChangeInput={() => field.onChange(undefined)}
              onSuggestionSelected={(s) => field.onChange(s.id)}
              renderOption={(suggestion: Suggestion) => {
                return {
                  label: suggestion.label,
                  className: 'text-text-02',
                };
              }}
            />
          )}
        />
      );
    }
    return null;
  }, [control, t, membersList, selectedAccesss, selectedMembersIdList]);

  return (
    <Sidebar
      isOpen={isOpen}
      position={'right'}
      onClose={onClose}
      contentClassName="md:max-w-[600px] md:w-[60%] font-lexend"
      hasBackdrop={false}
      buttons={{
        hasDivider: false,
        buttons: [
          <PrimaryButton
            onClick={handleSubmit((data) => {
              onSubmit(data);
              reset();
            })}
            label={t('buttons.save')}
            key={1}
            enabled={isValid}
          />,
          <LinkButton
            label={t('buttons.cancel')}
            key={2}
            onClick={onClose}
          />,
        ],
      }}
    >
      <div className="space-y-8">
        <h2 className="text-text-04 type-headline-2 min-h-[36px] break-words">{t('title')}</h2>
        <p className="text-text-02 type-body">{t('description')}</p>
        <form className="space-y-6">
          <Controller
            control={control}
            name="journeyId"
            render={({ field }) => (
              <InputAutoComplete
                emptyLabel={t('forms.journey.empty-label')}
                suggestions={journeysList}
                label={t('forms.journey.label')}
                error={errors.journeyId?.message}
                onChangeInput={() => field.onChange(undefined)}
                onSuggestionSelected={(s) => field.onChange(s.id)}
                placeholder={t('forms.journey.placeholder')}
                renderOption={(suggestion: Suggestion) => {
                  return {
                    label: suggestion.label,
                    className: 'text-text-02',
                  };
                }}
              />
            )}
          />
          <Dropdown
            htmlId={'access'}
            required
            label={t('forms.access.label')}
            {...register('access')}
          >
            <option value="author">{t('forms.access.options.author')}</option>
            <option value="collaborator">{t('forms.access.options.collaborator')}</option>
            <option value="learner">{t('forms.access.options.learner')}</option>
          </Dropdown>
          {renderMentorField()}
        </form>
      </div>
    </Sidebar>
  );
};
