import { useTranslation } from 'react-i18next';
import { BadgeAutoComplete, SearchableSelectOptionType } from '@stellar-lms-frontend/ui-components';
import { useCallback, useMemo } from 'react';
import { useScreenType } from '@stellar-lms-frontend/common-utils';
import {autocompleteSkills} from "../course-details.api";
import {graphQLClient} from "../../../../lib/graphql";
import {useCurrentCompany} from "@stellar-lms-frontend/lms-graphql";

const AMOUNT_OF_SKILLS_DESKTOP = 3;
const AMOUNT_OF_SKILLS_MOBILE = 5;

export type SkillsSectionProps = {
  value: string[];
  onChange: (selected: string[]) => void;
};
export const SkillsSection: React.FC<SkillsSectionProps> = ({ value, onChange }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'course-details.skills' });
  const { isTabletOrLarger } = useScreenType();
  const {query: { data: company },} = useCurrentCompany(graphQLClient());

  const skillAutoComplete = useCallback((query: string) => {
    return autocompleteSkills(graphQLClient('designer'), company?.id ?? '', query).then((data) => ({
      options: data.map((s) => ({ id: s, label: s })),
    }));
  }, [company]);

  const onChangeInterceptor = useCallback(
    (newValue: SearchableSelectOptionType[]) => {
      onChange(newValue.map((v) => v.label));
    },
    [onChange]
  );

  const transformedValue = useMemo(() => value.map((s) => ({ id: s, label: s })), [value]);

  return (
    <BadgeAutoComplete
      value={transformedValue}
      onChange={onChangeInterceptor}
      maxToShow={isTabletOrLarger ? AMOUNT_OF_SKILLS_DESKTOP : AMOUNT_OF_SKILLS_MOBILE}
      autoCompleteFunc={skillAutoComplete}
      i18n={{
        fieldLabel: t('label'),
        addLabel: t('add-skill'),
        selectLabel: t('select-skill'),
      }}
    />
  );
};
