import { useEffect, useRef, RefObject } from 'react';

type Event = MouseEvent;

type ClickOutsideOptions = {
  enabled?: boolean;
};

const defaultOptions: ClickOutsideOptions = {
  enabled: true,
};

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  handler?: (event: Event) => void,
  refs?: RefObject<T>[],
  options: ClickOutsideOptions = defaultOptions
) => {
  const handlerRef = useRef<(event: Event) => void>();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const callback = (event: Event) => {
      const isOutside = refs?.every((ref) => !ref.current?.contains(event.target as Node));
      if (!isOutside) return;
      handlerRef.current?.(event);
    };

    if (options.enabled) {
      /*
        Trick to make sure the event listener is set after event bubbling finishes.
        Otherwise the useOnClickOutside will be called from events before the target element is rendered.
      */
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        document.addEventListener('click', callback);
      }, 0);
    }

    return () => {
      document.removeEventListener('click', callback);
    };
  }, [refs, options]);
};
