import { Badge } from '@stellar-lms-frontend/ui-components';

export type AccessCellI18N = {
  author: string;
  collaborator: string;
  learner: string;
  mentor: string;
};

export type AccessTypes = 'author' | 'collaborator' | 'learner' | 'mentor';

export type AccessCellProps = {
  access: AccessTypes;
  i18n: AccessCellI18N;
};

export const AccessCell: React.FC<AccessCellProps> = ({ access, i18n }) => {
  return (
    <Badge
      color="grey"
      className="flex w-fit items-center gap-1"
    >
      <span className="text-xs">{i18n[access]}</span>
    </Badge>
  );
};
