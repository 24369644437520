import {
  ButtonConfig,
  EvaluatedSelect,
  Input,
  FillBlanks,
  MultiSelect,
  SingleSelect,
  Scale,
  TextArea,
  parseFillInTheBlanksParts,
} from '@stellar-lms-frontend/ui-components';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactNode } from 'react';
import { Evaluation, Question } from '@stellar-lms-frontend/lms-api';
import { useTranslation } from 'react-i18next';

export type QuestionViewChildrenProps = {
  showQuestionNumbers: boolean;
  number: number;
  total: number;
  questionId: string;
  questionTitle: string;
  questionRendered: ReactNode;
  answerEvaluation?: Evaluation;
  isDirty: boolean;
  buttonContent?: ButtonConfig;
};

export type QuestionViewProps = {
  question: Question;
  showQuestionNumbers?: boolean;
  number: number;
  total: number;
  answerEvaluation?: Evaluation;
  formSectionName?: string; // Use this when this form is nested in another form
  buttonContent?: ButtonConfig;
  children: (props: QuestionViewChildrenProps) => JSX.Element;
};

export const QuestionView = ({
  question,
  showQuestionNumbers = true,
  number,
  total,
  formSectionName,
  answerEvaluation,
  buttonContent,
  children,
}: QuestionViewProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assessment-step-view' });

  const { control, getFieldState } = useFormContext();

  const formSectionPrefix = formSectionName ? `${formSectionName}.` : '';
  const renderQuestion = (question: Question) => {
    if (answerEvaluation) {
      switch (answerEvaluation.type) {
        case 'multiple_choice_single_selection':
        case 'multiple_choice_multiple_selection':
          return (
            <div className="pt-8">
              <EvaluatedSelect
                key={answerEvaluation.id}
                withCheckbox={answerEvaluation.type === 'multiple_choice_multiple_selection'}
                answers={answerEvaluation.answers}
              />
            </div>
          );
        case 'fill_in_the_blanks':
          return (
            <div className="pt-8">
              <FillBlanks
                key={answerEvaluation.id}
                parts={parseFillInTheBlanksParts(question.text)}
                inputLength={question.inputMaxLength}
                value={answerEvaluation.answers}
                onChange={() => null}
                evaluated
              />
            </div>
          );
      }
    }

    switch (question.type) {
      case 'multiple_choice_single_selection':
        return (
          <div className="pt-8">
            <Controller
              key={question.id}
              control={control}
              name={formSectionPrefix + question.id}
              render={({ field: { ref, ...fieldRest } }) => (
                <SingleSelect
                  {...fieldRest}
                  options={question.answers}
                />
              )}
            />
          </div>
        );
      case 'multiple_choice_multiple_selection':
        return (
          <div className="space-y-8">
            <p className="type-body font-lexend text-text-02">{t('select-multiple-hint')}</p>
            <Controller
              key={question.id}
              control={control}
              name={formSectionPrefix + question.id}
              render={({ field: { ref, ...fieldRest } }) => (
                <MultiSelect
                  {...fieldRest}
                  options={question.answers}
                />
              )}
            />
          </div>
        );
      case 'fill_in_the_blanks':
        return (
          <div className="pt-8">
            <Controller
              key={question.id}
              control={control}
              name={formSectionPrefix + question.id}
              render={({ field: { ref, ...fieldRest } }) => (
                <FillBlanks
                  {...fieldRest}
                  parts={parseFillInTheBlanksParts(question.text)}
                  inputLength={question.inputMaxLength}
                  onChange={(value) => {
                    fieldRest.onChange(value.map((v, i) => ({ ...v, id: question.answers[i].id })));
                  }}
                  answerOptions={question.answerOptions}
                />
              )}
            />
          </div>
        );
      case 'scale': {
        return (
          <div className="pt-8">
            <Controller
              key={question.id}
              name={formSectionPrefix + question.id}
              control={control}
              render={({ field: { ref, ...fieldRest } }) => (
                <Scale
                  {...fieldRest}
                  options={question.answers}
                  left={question.leftLabel}
                  right={question.rightLabel}
                />
              )}
            />
          </div>
        );
      }
      case 'free_text_single': {
        return (
          <div className="pt-8">
            <Controller
              key={question.id}
              name={formSectionPrefix + question.id}
              control={control}
              render={({ field: { ref, ...fieldRest } }) => (
                <Input
                  {...fieldRest}
                  key={question.id}
                  autoComplete="off"
                  htmlId={question.id}
                  onChange={({ target: { value } }) =>
                    fieldRest.onChange({ userInput: value, id: question.answers[0].id })
                  }
                  text={fieldRest.value?.userInput}
                />
              )}
            />
          </div>
        );
      }
      case 'free_text_multiple': {
        return (
          <div className="pt-8">
            <Controller
              key={question.id}
              name={formSectionPrefix + question.id}
              control={control}
              render={({ field: { ref, ...fieldRest } }) => (
                <TextArea
                  {...fieldRest}
                  key={question.id}
                  htmlId={question.id}
                  placeholder={t('type-here')}
                  onChange={({ target: { value } }) =>
                    fieldRest.onChange({ userInput: value, id: question.answers[0].id })
                  }
                  text={fieldRest.value?.userInput}
                />
              )}
            />
          </div>
        );
      }

      default:
        return null;
    }
  };

  let questionTitle = question.text;
  if (question.type === 'fill_in_the_blanks') {
    questionTitle = t('fill-in-the-blanks-title');
  }

  const typeProps: QuestionViewChildrenProps = {
    showQuestionNumbers,
    number,
    total,
    questionTitle,
    questionRendered: renderQuestion(question),
    answerEvaluation,
    isDirty: getFieldState(question.id).isDirty,
    buttonContent,
    questionId: question.id,
  };

  return children(typeProps);
};
