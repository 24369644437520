import { NavLink } from 'react-router-dom';

export const TabLink = ({
  to,
  title,
  subTitle,
}: {
  to: string;
  title: string;
  subTitle: string;
}) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      `flex cursor-pointer flex-row-reverse items-stretch justify-end gap-3 ${
        isActive ? 'text-primary-01' : 'text-text-02 hover:text-text-01'
      } `
    }
    end
  >
    {({ isActive }) => (
      <>
        <div>
          <div className="flex flex-col items-start gap-1">
            <h4 className="type-headline-4">{title}</h4>
            <p className="type-small text-text-02">
              {subTitle}
              {!subTitle && <>&nbsp;</>}
            </p>
          </div>
        </div>
        <span
          className={`w-[4px] self-stretch rounded-full
              ${isActive ? 'bg-primary-01' : 'bg-transparent'}`}
        />
      </>
    )}
  </NavLink>
);
