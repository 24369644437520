import { ButtonConfig, useScreenType } from '@stellar-lms-frontend/common-utils';
import {
  BackButton,
  TertiaryButton,
  PrimaryButton,
  RightArrowIcon,
} from '@stellar-lms-frontend/ui-components';

export type LearnerUIShellHeaderProps = {
  title: string;
  leftPrimary?: ButtonConfig;
  rightSecondary?: ButtonConfig;
  rightPrimary?: ButtonConfig;
  discussionButton?: ButtonConfig;
  className?: string;
  rightElements?: React.ReactNode[];
};

export const LearnerUIShellHeader: React.FC<LearnerUIShellHeaderProps> = ({
  title,
  leftPrimary,
  rightSecondary,
  rightPrimary,
  discussionButton,
  className = '',
  rightElements,
}) => {
  const { isTabletOrLarger } = useScreenType();

  return (
    <div
      className={`${className} grid-cols-mobile-header-learning-activity md:after:shadow-bottom-line relative grid h-[46px] w-full items-center justify-between md:flex md:h-[96px] md:after:absolute md:after:bottom-0 md:after:z-10 md:after:h-10 md:after:w-full md:after:content-['']`}
    >
      <div
        className={`lg:shadow-right flex h-full flex-row items-center overflow-hidden lg:w-[488px] lg:shrink-0`}
      >
        <div className="min-w-[100px]">
          {leftPrimary && (
            <BackButton
              htmlId="back"
              label={leftPrimary.label}
              onClick={() => leftPrimary.action()}
            />
          )}
        </div>
      </div>
      <div className="flex grow justify-center overflow-hidden text-center md:items-start md:text-left lg:hidden">
        <p
          data-test-id="learning-activity-title"
          className="text-text-04 w-full text-base font-semibold"
        >
          {title}
        </p>
      </div>
      <div className="flex shrink-0 items-center overflow-hidden">
        {/* TODO can we make the buttons an array? -> right buttons, left buttons */}
        {rightElements && (
          <ul className="mr-4">
            {rightElements.map((e, index) => (
              <li
                key={index}
                className="flex gap-4"
              >
                {e}
              </li>
            ))}
          </ul>
        )}
        {discussionButton && isTabletOrLarger && (
          <div
            className="mr-4 hidden md:inline"
            onClick={() => discussionButton.action()}
          >
            <TertiaryButton
              buttonStyle="small"
              leftIcon={discussionButton.leftIcon}
              htmlId={`previous-button`}
              label={discussionButton.label ?? ''}
            />
          </div>
        )}
        {rightSecondary && (
          <div className="mr-4 hidden md:inline">
            <TertiaryButton
              buttonStyle="small"
              htmlId={`previous-button`}
              label={rightSecondary.label ?? ''}
              enabled={!rightSecondary?.disabled}
              onClick={() => rightSecondary.action()}
            />
          </div>
        )}
        {rightPrimary && (
          <div className="min-w-[100px] px-8 md:px-0 md:pr-10">
            {!isTabletOrLarger && (
              <button
                id="next-button"
                className={`font-lexend w-fit text-base font-medium
                ${rightPrimary.disabled ? 'text-gray-400' : 'text-primary-01'}`}
                area-label="next button"
                onClick={() => rightPrimary.action()}
                disabled={rightPrimary.disabled}
              >
                {rightPrimary.label}
              </button>
            )}
            {isTabletOrLarger && (
              <PrimaryButton
                htmlId="next-button"
                label={rightPrimary.label ?? ''}
                onClick={() => rightPrimary.action()}
                buttonStyle="small"
                rightIcon={
                  <RightArrowIcon
                    className={rightPrimary.disabled ? 'fill-gray-400' : 'fill-on-primary-01'}
                  />
                }
                enabled={!rightPrimary.disabled}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
