import { GuideIllustration, Heading3 } from '@stellar-lms-frontend/ui-components';
import { GEARModalGuideImage } from './images';
import { useTranslation } from 'react-i18next';

export const Guide: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'gear-template-modal.guide' });

  return (
    <div className="text-text-02 space-y-6">
      <div className="flex items-center gap-2">
        <GuideIllustration />
        <Heading3 className="text-text-04">{t('title')}</Heading3>
      </div>
      <p className="font-semibold">{t('intro')}</p>
      <p>{t('learning-comparison')}</p>
      <img
        src={GEARModalGuideImage}
        alt=""
        className="h-auto w-full"
      />
      <p>{t('structured-activities')}</p>
      <p>{t('cognitive-overload')}</p>
      <p>{t('recommendation')}</p>
    </div>
  );
};
