import { navigation } from '@stellar-lms-frontend/common-utils';
import { LogoLoader } from '@stellar-lms-frontend/ui-components';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LEARNER_WBA } from '../../constants/query';
import { WBA } from '@stellar-lms-frontend/lms-components';
import { LearnerWorkBasedActionSubmitInput } from '@stellar-lms-frontend/lms-graphql';
import { submitLearnerWorkBasedAction } from '../designer/work-based-action/work-based-action.api';
import { graphQLClient } from '../../lib/graphql';
import { getLearnerWorkBasedAction } from './work-based-actions.api';
import { useCallback } from 'react';

const NAVIGATION_KEY = 'actions-edit';

export type WorkBasedActionEditViewProps = {
  learnerWorkBasedActionId: string;
};
export const WorkBasedActionEditView = ({
  learnerWorkBasedActionId,
}: WorkBasedActionEditViewProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'actions.edit' });

  const { data: action, isLoading } = useQuery(
    [LEARNER_WBA, learnerWorkBasedActionId],
    () => getLearnerWorkBasedAction(graphQLClient(), learnerWorkBasedActionId),
    { refetchOnWindowFocus: false }
  );

  const navigateBack = useCallback(() => navigate(-1), [navigate]);

  const setUpButtonsFunc = useCallback(
    (submitFunc: () => void) => {
      navigation.setLeftPrimary(NAVIGATION_KEY, {
        label: '',
        action: navigateBack,
      });
      navigation.setRightPrimary(NAVIGATION_KEY, {
        label: t('save'),
        action: submitFunc,
      });

      return () => {
        navigation.popRightPrimary(NAVIGATION_KEY);
        navigation.popLeftPrimary(NAVIGATION_KEY);
      };
    },
    [navigateBack, t]
  );

  return isLoading || !action ? (
    <LogoLoader />
  ) : (
    <WBA.WorkBasedActionEdit
      actions={{
        submitLearnerWorkBasedAction: (id: string, input: LearnerWorkBasedActionSubmitInput) =>
          submitLearnerWorkBasedAction(graphQLClient('designer'), id, input),
      }}
      learnerWorkBasedAction={action}
      showWbaDesignerDetails={false}
      showSaveButton={true}
      afterSubmitFunc={navigateBack}
      setUpButtonsFunc={setUpButtonsFunc}
      onCancelFunc={navigateBack}
    />
  );
};
