import { useRef, useEffect } from 'react';
import './content-editable.css';

interface ContentEditableProps {
  placeholder?: string;
  contentEditable?: boolean;
  className?: string;
  onChange: (value: string) => void;
  value: string;
}

export const ContentEditable: React.FC<ContentEditableProps> = ({
  placeholder,
  contentEditable = true,
  className = '',
  onChange,
  value,
}) => {
  const editableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (editableRef.current) {
      editableRef.current.innerHTML = value;
    }
  }, [value]);

  return (
    <div
      ref={editableRef}
      className={`${className} content-editable`}
      contentEditable={contentEditable}
      data-placeholder={placeholder}
      onBlur={() => {
        // Maybe trigger it on onInput to make it real time (but has a performance cost)
        onChange(editableRef.current?.innerHTML ?? '');
      }}
    />
  );
};
