/**
 * Query keys for react query
 */
export const WEBAPP_DESCRIPTIONS = 'webapp-descriptions';
export const USER = 'user';
export const USERS_LIGHT = 'users-light';

export const SURVEY_OVERVIEW = 'survey-overview';

export const COURSE = 'course';
export const COMPANY = 'company';
export const MEMBER = 'member';
export const LEARNER = 'learner';

// For apis that differ per role
export const MENTOR = 'mentor';
export const DESIGNER = 'designer';

export const DISCUSSION_PAGE_SIZE = 10;
