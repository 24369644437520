import React from 'react';
import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import { SecondaryButton, TertiaryButton } from '../../../buttons';
import { AlertTriangle } from '../../../icons';

export interface DocumentViewComponentProps {
  i18n: {
    label: string;
    cta: string;
  };
  onSupportClick: VoidFunc | undefined;
}

export const DocumentNotSupportedComponent: React.FC<DocumentViewComponentProps> = ({
  i18n,
  onSupportClick,
}) => {
  return onSupportClick ? (
    <div className="py-3">
      <div className="bg-warning-03 font-lexend flex items-start gap-4 rounded-xl px-8 py-6">
        <AlertTriangle className="text-warning-01 h-6 w-6 shrink-0" />
        <div className="flex flex-col gap-3">
          <span className="text-text-04">{i18n.label}</span>

          <div>
            <TertiaryButton
              label={i18n.cta}
              onClick={() => onSupportClick()}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
