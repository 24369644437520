import {
  FloatingOptions,
  Modal,
  LinkButton,
  PrimaryButton,
  CopyIcon,
  Edit3Icon,
  TrashIcon,
  Export2Icon,
} from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { Dispatch, ReactNode, useState } from 'react';
import { getDesignerCourseEditRoute } from '../constants';
import { Course, ScormPackage, ScormVersion } from '@stellar-lms-frontend/lms-graphql';
import { ScormExportModal } from '../scorm-export-modal/scorm-export-modal';
import { useNavigateExternal } from '@stellar-lms-frontend/common-utils';

type Actions = {
  deleteCourse: Dispatch<string>;
  duplicateCourse: Dispatch<string>;
  createScormPackage: (courseId: string, version: ScormVersion) => Promise<ScormPackage>;
  getScormPackage: (courseId: string, packageId: string) => Promise<ScormPackage>;
};

type Props = {
  wrappedComponent: ReactNode;
  actions: Actions;
  currentCourse: Course;
};

export const CourseContextMenu: React.FC<Props> = ({
  wrappedComponent,
  actions,
  currentCourse,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { navigateExternal } = useNavigateExternal();

  const { t } = useTranslation('translation');
  const { t: tScorm } = useTranslation('translation', { keyPrefix: 'scorm-modal' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isScormModalOpen, setIsScormModalOpen] = useState(false);

  // TODO translations
  return (
    <>
      <ScormExportModal
        isOpen={isScormModalOpen}
        onClose={() => setIsScormModalOpen(false)}
        currentCourse={currentCourse}
        i18n={{
          title: tScorm('title'),
          hint: tScorm('hint'),
          versionSelection: {
            title: tScorm('version-selection.title'),
            explanation: tScorm('version-selection.explanation'),
            options: {
              '2004': tScorm('version-selection.options.2004'),
              '1.2': tScorm('version-selection.options.1.2'),
            },
          },
          loadingMessage: tScorm('loading-message'),
          success: {
            title: tScorm('success.title'),
            description: tScorm('success.description'),
          },
          buttons: {
            export: tGeneral('export'),
            cancel: tGeneral('cancel'),
            download: tGeneral('download'),
          },
        }}
        actions={{
          createScormPackage: actions.createScormPackage,
          getScormPackage: actions.getScormPackage,
        }}
      />
      <Modal
        isOpen={showDeleteConfirmation}
        title={t('delete-journey.title')}
        onClose={() => setShowDeleteConfirmation(false)}
        buttons={{
          hasShadow: false,
          buttons: [
            <PrimaryButton
              key={1}
              theme="red"
              label={t('delete-journey.cta')}
              onClick={() => {
                setShowDeleteConfirmation(false);
                actions.deleteCourse(currentCourse.id);
              }}
            />,
            <LinkButton
              key={2}
              buttonStyle="fit"
              onClick={() => setShowDeleteConfirmation(false)}
              label={t('general.cancel')}
            />,
          ],
        }}
        className="bg-surface-01 h-full md:h-auto md:w-[510px]"
      >
        <p className="text-text-01 type-body">{t('delete-journey.description')}</p>
      </Modal>
      <FloatingOptions
        wrappedComponent={<button onClick={() => setIsOpen(true)}>{wrappedComponent}</button>}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        options={[
          {
            label: t('general.edit-details'),
            onClick: () => {
              navigateExternal(getDesignerCourseEditRoute(currentCourse.id));
            },
            left: <Edit3Icon className="text-text-01 h-4 w-4" />,
          },
          {
            label: t('course-context-menu.options.scorm'),
            onClick: () => setIsScormModalOpen(true),
            left: <Export2Icon className="text-text-01 h-4 w-4" />,
          },
          {
            label: t('general.duplicate'),
            onClick: () => actions.duplicateCourse(currentCourse.id),
            left: <CopyIcon className="text-text-01 h-4 w-4" />,
          },
          {
            label: t('general.delete'),
            onClick: () => {
              setShowDeleteConfirmation(true);
            },
            className: 'text-negative-01',
            left: <TrashIcon className="text-negative-01 h-4 w-4" />,
          },
        ]}
      />
    </>
  );
};
