import ReactDOM from 'react-dom';
import { ParagraphData } from '../paragraph/paragraph-plugin';
import { Header } from './header-plugin';
import { renderToString } from 'react-dom/server';
import { Heading1Icon } from '../../../icons';
import { HeaderComponent } from './header-component';
import { Levels } from '../types';
import { BlockEditorTypesEnum } from '../../block-editor';

export class H1 extends Header {
  static override get toolbox() {
    return {
      title: BlockEditorTypesEnum.H1,
      icon: renderToString(<Heading1Icon className="text-text-01 !h-4 !w-4" />),
    };
  }

  override updateElement() {
    ReactDOM.render(
      <HeaderComponent
        level={Levels.ONE}
        isReadOnly={this.readOnly}
        data={this.data}
        onChange={(newData: ParagraphData) => (this.data = newData)}
      />,
      this.element
    );
  }

  static get pasteConfig() {
    return {
      tags: ['H1'],
    };
  }
}
