import {
  ContentContainer,
  Heading3,
  HintDescription,
  ScrollContainer,
} from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import {
  SubscriptionType,
  useCurrentCompany,
  useCurrentUser,
} from '@stellar-lms-frontend/lms-graphql';
import { GraphQLClient } from 'graphql-request';
import { IndividualPlanCard, EnterprisePlanCard } from '../../../billing';
import { Stripe } from '@stripe/stripe-js';
import { CurrentIndividualPlanCard } from './current-individual-plan-card';
import { calculateDaysRemaining } from '@stellar-lms-frontend/common-utils';

export const BillingTab = ({
  graphQLClient,
  stripePromise,
}: {
  graphQLClient: GraphQLClient;
  stripePromise: Promise<Stripe | null>;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.org-billing-tab' });

  const {
    query: { data: company },
  } = useCurrentCompany(graphQLClient);

  const {
    query: { data: currentUser },
  } = useCurrentUser(graphQLClient);

  const subscription = company?.subscription;

  if (!subscription) {
    return null;
  }

  const remainingDays = calculateDaysRemaining(new Date(), subscription.endDate);
  const subscriptionType = subscription.type;

  const isPlatformAdmin = currentUser?.company.id !== company?.id;

  return (
    <ScrollContainer scrollOnDesktop>
      <ContentContainer>
        <div className="space-y-10">
          <div className="space-y-6">
            {isPlatformAdmin && (
              <HintDescription
                theme={'warning'}
                title={t('platform-admin-warning')}
                hasCloseButton={false}
              />
            )}
            <div className="space-y-4">
              <Heading3>{t('current-plan.title')}</Heading3>
              <p className="text-text-02 type-body">{t('current-plan.subtitle')}</p>
            </div>
            {company?.subscription?.type === SubscriptionType.Trial && (
              <HintDescription
                theme={'warning'}
                hasCloseButton={false}
                title={t('current-plan.trial.title')}
                description={t('current-plan.trial.description', {
                  count: remainingDays,
                })}
              />
            )}
            {subscriptionType === SubscriptionType.Internal && (
              <HintDescription
                theme={'informative'}
                hasCloseButton={false}
                title={t('current-plan.internal.title')}
              />
            )}
            {subscriptionType === SubscriptionType.StripeManaged && <CurrentIndividualPlanCard />}
            {subscriptionType === SubscriptionType.StellarLabsManaged && (
              <HintDescription
                theme={'informative'}
                hasCloseButton={false}
                title={t('current-plan.stellar-managed.title')}
                description={t('current-plan.stellar-managed.description')}
              />
            )}
          </div>

          {(subscriptionType === SubscriptionType.Trial ||
            subscriptionType === SubscriptionType.StripeManaged) &&
            !isPlatformAdmin && (
              <div className="space-y-8">
                <Heading3>{t('available-plans.title')}</Heading3>
                <div className="flex gap-6">
                  {/* Only for trial do we support self serving stripe. For anything else they need to contact sales, maybe we need a different design for this */}
                  {subscriptionType === SubscriptionType.Trial && (
                    <IndividualPlanCard stripePromise={stripePromise} />
                  )}
                  <EnterprisePlanCard />
                </div>
              </div>
            )}
        </div>
      </ContentContainer>
    </ScrollContainer>
  );
};
