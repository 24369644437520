import { useTranslation } from 'react-i18next';

export type I18N = {
  emptyTitle: string;
  emptySubTitle: string;
};
export const EmptyStateComponent = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'discussion' });

  return (
    <div className="font-lexend font-regular text-text-02 mt-6 h-full w-full space-y-1 px-10 text-center text-sm">
      <span
        role="img"
        className="font-regular text-xl"
        aria-label="message emoji"
      >
        💬
      </span>
      <p>{t('empty-message')}</p>
      <p>{t('empty-sub-message')}</p>
    </div>
  );
};
