import { QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export function StellarQueryClient(): QueryClient {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: (failureCount, error) => {
          // Not the cleanest solution but at least this is working and we are only using Axios
          const axiosError = error as AxiosError;
          if (
            axiosError?.isAxiosError &&
            (axiosError.response?.status === 403 || axiosError.response?.status === 404)
          ) {
            return false;
          }
          return failureCount < 4;
        },
      },
    },
  });
}
