import { PersistedLearnerWorkBasedAction } from '@stellar-lms-frontend/lms-api';
import { useTranslation } from 'react-i18next';
import { ActionListItem } from './action-list-item';

export type OngoingActionListProps = {
  openActions: PersistedLearnerWorkBasedAction[];
  isDesktop: boolean;
  actionId?: string;
  noOpenActionsText?: string;
  showUserName?: boolean;
  baseUrl?: string;
};
export const OngoingActionList = ({
  openActions,
  isDesktop,
  actionId,
  noOpenActionsText,
  showUserName = false,
  baseUrl,
}: OngoingActionListProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'actions' });

  return openActions.length === 0 ? (
    <p className="font-lexend font-regular text-text-03 pr-6 text-base">
      {noOpenActionsText ?? t('no-open-actions')}
    </p>
  ) : (
    <ul
      className="w-full pb-4"
      data-cy="ongoing-actions"
    >
      {openActions.map((a) => (
        <ActionListItem
          key={a.id}
          action={a}
          selectedActionId={actionId}
          isDesktop={isDesktop}
          showUserName={showUserName}
          baseUrl={baseUrl}
        />
      ))}
    </ul>
  );
};
