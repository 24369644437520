import {
  AutoResizeTextArea,
  AutoResizeTextAreaProps,
  Debounced,
  Heading3,
  LinkButton,
  PrimaryButton,
  Sidebar,
} from '@stellar-lms-frontend/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getChangeEventValue } from '@stellar-lms-frontend/common-utils';
import { useQueryClient } from '@tanstack/react-query';
import { ArticleSearchQuerySuggestion } from './components/article-search-query-suggestion';
import { SuggestedArticles } from './components/suggested-articles';
import { Article, SuggestedSearchTerms } from '@stellar-lms-frontend/lms-graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';

export type ArticleAISidebarProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  findArticles: (query: string) => Promise<Article[] | undefined>;
  startArticleSearchTermGeneration: () => Promise<boolean>;
  getSuggestedArticleSearchTerm: () => Promise<SuggestedSearchTerms>;
  onAdd: (articles: Article[]) => void;
};

export const ArticleAISidebar = ({
  isOpen,
  onClose,
  onAdd,
  findArticles,
  startArticleSearchTermGeneration,
  getSuggestedArticleSearchTerm,
}: ArticleAISidebarProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'block-step-view.article-ai-sidebar' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const [selectedArticles, setSelectedArticles] = useState<{ [key: string]: Article }>({});
  const [searchQuery, setSearchQuery] = useState('');

  const queryClient = useQueryClient();

  return (
    <Sidebar
      isOpen={isOpen}
      onClose={onClose}
      position="right"
      contentClassName="md:max-w-[600px] md:w-[60%] font-lexend"
      isDisableScrollEnabled={false}
      hasBackdrop={false}
      header={<Heading3 className="text-text-01">{t('title')}</Heading3>}
      buttons={{
        hasDivider: true,
        buttons: [
          <PrimaryButton
            key="add-button"
            label={tGeneral('add')}
            enabled={Object.keys(selectedArticles).length > 0}
            onClick={() => {
              Object.keys(selectedArticles).length > 0 &&
                onAdd([...Object.values(selectedArticles)]);
              onClose();
              setSelectedArticles({});
              // TODO remove those articles from search results??
            }}
          />,
          <LinkButton
            key="cancel-button"
            label={tGeneral('cancel')}
            onClick={onClose}
          />,
        ],
      }}
    >
      <Debounced<AutoResizeTextAreaProps>
        element={AutoResizeTextArea}
        placeholder={t('search.placeholder')}
        value={searchQuery}
        minRows={1}
        maxRows={3}
        leftIcon={
          <FontAwesomeIcon
            icon={faSearch}
            className="text-text-01 text-xl"
          />
        }
        onChangeDebounced={(e) => {
          setSearchQuery(getChangeEventValue(e));
        }}
        onChange={(e) => {
          queryClient.cancelQueries([{ searchQuery }]);
          setSearchQuery(getChangeEventValue(e));
        }}
      />
      <ArticleSearchQuerySuggestion
        currentSearchTerm={searchQuery}
        onAcceptQuerySuggestion={(suggestion) => setSearchQuery(suggestion)}
        startArticleSearchTermGeneration={startArticleSearchTermGeneration}
        getSuggestedArticleSearchTerm={getSuggestedArticleSearchTerm}
      />
      <SuggestedArticles
        query={searchQuery}
        findArticles={findArticles}
        onSelectArticle={(article) =>
          setSelectedArticles((current) => ({ ...current, [article.url]: article }))
        }
        onDeselectArticle={(article) =>
          setSelectedArticles((current) => {
            const next = { ...current };
            delete next[article.url];
            return next;
          })
        }
      />
    </Sidebar>
  );
};
