import { useCallback } from 'react';
import Cookies from 'universal-cookie';

export const useLogout = (apiUrl: string, callback?: () => void) => {
  const logout = useCallback(() => {
    callback?.();

    const cookies = new Cookies(null, { path: '/' });
    Object.keys(cookies.getAll()).forEach((key) => {
      cookies.remove(key);
    });

    window.location.href =
      apiUrl +
      '/user/auth0/logout?redirectUrl=' +
      encodeURIComponent(new URL('/', window.location.href).href);
  }, [apiUrl]);

  return logout;
};
