import { DataRef, UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import { LearningActivityOutline } from '@stellar-lms-frontend/lms-graphql';

export function getDataRefContainerId(data?: DataRef) {
  if (!data?.current?.['sortable']) {
    return undefined;
  }

  return data.current['sortable'].containerId as string;
}

export function swapInSameLearningActivitiesContainer(
  moduleActivitiesMap: Map<string, LearningActivityOutline[]>,
  activities: LearningActivityOutline[],
  active: { id: UniqueIdentifier },
  moduleId: string,
  newPositionIndex: number
) {
  const newActivitiesMap = new Map(moduleActivitiesMap);
  const activeIndex = activities.findIndex((i) => i.id === active?.id);
  newActivitiesMap.set(moduleId, arrayMove(activities, activeIndex, newPositionIndex));
  return newActivitiesMap;
}

export function swapInNewActivitiesContainer(
  moduleActivitiesMap: Map<string, LearningActivityOutline[]>,
  active: { id: UniqueIdentifier },
  learningActivity: LearningActivityOutline,
  oldModuleId: string,
  newModuleId: string,
  newPositionIndex: number
) {
  const newActivitiesMap = new Map(moduleActivitiesMap);

  // remove activity from old module
  const oldModuleLearningActivities = moduleActivitiesMap
    .get(oldModuleId)
    ?.filter((learningActivity) => learningActivity.id !== active.id);

  // Add activity to new module in the correct position
  const newModuleLearningActivities = [...(newActivitiesMap.get(newModuleId) ?? [])];
  newModuleLearningActivities.splice(newPositionIndex, 0, learningActivity);

  newActivitiesMap.set(oldModuleId, oldModuleLearningActivities ?? []);
  newActivitiesMap.set(newModuleId, newModuleLearningActivities ?? []);

  return newActivitiesMap;
}
