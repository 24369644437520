import { DataCy } from '@stellar-lms-frontend/common-utils';
import { DownArrowIcon, Heading4, UpArrowIcon } from '@stellar-lms-frontend/ui-components';

export type InsightCardProps = DataCy & {
  icon: React.ElementType;
  title: string;
  subtitle: string;
  reports?: { type: 'positive' | 'negative'; message: string }[];
};

export const InsightCard: React.FC<InsightCardProps> = ({
  icon: Icon,
  title,
  subtitle,
  reports,
  'data-cy': dataCy,
}) => {
  return (
    <div
      className="bg-surface-02 flex grow basis-0 items-center rounded-xl p-6 md:h-[225px] md:flex-col md:justify-center md:text-center"
      data-cy={dataCy}
    >
      <Icon className="text-primary-01 mr-4 h-8 w-8 md:mr-0" />
      <div>
        <Heading4 className="text-text-04 md:mt-6">{title}</Heading4>
        <div className="space-y-2 md:mt-2">
          <p className="text-text-02">{subtitle}</p>
          {reports?.map((report) => (
            <div
              className={`flex items-center space-x-1 text-sm font-semibold
            ${report.type === 'positive' && 'text-positive-02 '}
            ${report.type === 'negative' && 'text-negative-02 '}
          `}
            >
              {report.type === 'positive' && <UpArrowIcon className="h-3 w-3 stroke-2" />}
              {report.type === 'negative' && <DownArrowIcon className="h-3 w-3 stroke-2" />}
              <span>{report.message}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
