import { graphql } from '@stellar-lms-frontend/lms-graphql';
import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';

export const getSpacedRepetitionErrorRates = async (courseId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query CourseAdminDashboardSRQErrorRates($courseId: ID!) {
        courseSRQErrorRates(courseId: $courseId) {
          rows {
            category
            count
          }
        }
      }
    `),
    { courseId: courseId }
  );
};

export const getUserProgress = async (courseId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query CourseAdminDashboardUserProgress($courseId: ID!) {
        courseUserProgress(courseId: $courseId) {
          rows {
            category
            count
          }
        }
      }
    `),
    { courseId: courseId }
  );
};

export const getSRQUserLevelDistribution = async (courseId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query CourseAdminDashboardSRQUserLevelDistribution($courseId: ID!) {
        courseSRQUserLevelDistribution(courseId: $courseId) {
          rows {
            category
            items {
              category
              count
            }
          }
        }
      }
    `),
    { courseId: courseId }
  );
};
export const getUserWBAStates = async (courseId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query CourseAdminDashboardUserWBAStates($courseId: ID!) {
        courseUserWBAStates(courseId: $courseId) {
          rows {
            category
            items {
              category
              count
            }
          }
        }
      }
    `),
    { courseId: courseId }
  );
};
export const getCourseOverallWBAStates = async (courseId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query CourseAdminDashboardOverallWBAStates($courseId: ID!) {
        courseOverallWBAStates(courseId: $courseId) {
          rows {
            category
            items {
              category
              count
            }
          }
        }
      }
    `),
    { courseId: courseId }
  );
};
export const getSRQLevelDistribution = async (courseId: string) => {
  return defaultGraphqlClient.request(
    graphql(`
      query CourseAdminDashboardSRQLevelDistribution($courseId: ID!) {
        courseSRQLevelDistribution(courseId: $courseId) {
          rows {
            category
            items {
              category
              count
            }
          }
        }
      }
    `),
    { courseId: courseId }
  );
};
