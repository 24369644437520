import { MutationMarkOnboardingCompletedArgs, graphql } from '@stellar-lms-frontend/lms-graphql';
import { GraphQLClient } from 'graphql-request';

export const markOnboardingCompleted = (
  client: GraphQLClient,
  variables: MutationMarkOnboardingCompletedArgs
) => {
  return client.request(
    graphql(`
      mutation MarkOnboardingComplete($userId: ID!) {
        markOnboardingCompleted(userId: $userId)
      }
    `),
    variables
  );
};
