import { DataCy } from '@stellar-lms-frontend/common-utils';
import { flexRender, Cell, RowData, Row } from '@tanstack/react-table';

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    alignment?: 'center';
    onClick?: (e: any, row: Row<TData>) => void;
  }
}

type TableCellProps<T extends RowData, V> = DataCy & {
  cell: Cell<T, V>;
  rowIndex: number;
  row: Row<T>;
};

export const TableCell = <T extends RowData, V>({
  cell,
  'data-cy': dataCy,
  rowIndex,
  row,
}: TableCellProps<T, V>) => {
  const contentAlignment =
    cell.column.columnDef.meta?.alignment === 'center'
      ? 'flex justify-center items-center'
      : 'px-6 ';

  return (
    <div
      role="cell"
      id={cell.id}
      onClick={(e) => cell.column.columnDef.meta?.onClick?.(e, row)}
      style={{
        maxWidth: cell.column.columnDef.maxSize,
        minWidth: cell.column.columnDef.minSize,
        width: cell.column.columnDef.size,
      }}
      className={`grow overflow-hidden text-ellipsis py-4 [&:first-child]:pl-0 [&:last-child]:pr-0 ${contentAlignment}`}
      data-cy={`${dataCy}-${cell.column.id}-${rowIndex}`}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </div>
  );
};
