import { Duration, DurationI18N } from '@stellar-lms-frontend/ui-components';

export type DescriptionSectionI18N = {
  duration: DurationI18N;
};

type DescriptionSectionProps = {
  duration: number;
  description: string;
  durationClassName: string;
  isLocked: boolean;
  lockedText: string;
  i18n: DescriptionSectionI18N;
};

export const DescriptionSection: React.FC<DescriptionSectionProps> = ({
  duration,
  description,
  durationClassName,
  isLocked,
  lockedText,
  i18n,
}) => {
  return (
    <p
      className="font-regular flex items-start pt-2 text-sm lg:text-base"
      data-cy="learningactivity-description"
    >
      <span className={`shrink-0 grow-0 ${durationClassName}`}>
        {isLocked ? (
          lockedText
        ) : (
          <Duration
            i18n={i18n.duration}
            seconds={duration ?? 0}
          />
        )}
        {duration && description ? '\u00A0-\u00A0' : ''}
      </span>
      <span className="text-text-02 overflow-hidden text-ellipsis">{description}</span>
    </p>
  );
};
