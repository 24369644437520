import {
  getFragmentData,
  graphql,
  LearnerWorkBasedActionFragmentFragment,
  LearnerWorkBasedActionSubmitInput,
  PersistedLearnerWorkBasedActionFragmentFragment,
  QueryCourseArgs,
  WorkBasedActionState,
} from '@stellar-lms-frontend/lms-graphql';
import { GraphQLClient } from 'graphql-request';
import {
  LearnerWorkBasedActionState,
  LearnerWorkBasedActionWithUser,
  PersistedLearnerWorkBasedActionWithUser,
} from '@stellar-lms-frontend/lms-api';

export const getCourseTitle = (client: GraphQLClient, variables: QueryCourseArgs) => {
  return client
    .request(
      graphql(`
        query WBACourseDetails($id: ID!) {
          course(id: $id) {
            id
            title
          }
        }
      `),
      variables
    )
    .then((res) => {
      return res.course?.title;
    });
};

export const getOrCreateLearnerWorkBasedAction = (
  client: GraphQLClient,
  sourceId: string,
  activityId: string
): Promise<LearnerWorkBasedActionWithUser> => {
  return client
    .request(
      graphql(`
        mutation CreateWorkBasedActionForLearner($sourceId: ID!, $activityId: ID!) {
          createWorkBasedActionForLearner(sourceId: $sourceId, activityId: $activityId) {
            ...LearnerWorkBasedActionFragment
          }
        }
      `),
      {
        sourceId,
        activityId,
      }
    )
    .then((data) => {
      if (data.createWorkBasedActionForLearner) {
        return mapWBA(
          getFragmentData(LearnerWorkBasedActionFragment, data.createWorkBasedActionForLearner)
        );
      } else {
        throw new Error('no learner work based action returned');
      }
    });
};

export const getAllLearnerWorkBasedActionsForCourse = (
  client: GraphQLClient,
  courseId: string
): Promise<PersistedLearnerWorkBasedActionWithUser[]> => {
  return client
    .request(
      graphql(`
        query GetAllLearnerWorkBasedActionsForCourseQuery($courseId: ID!) {
          allLearnerWorkBasedActionsForCourse(id: $courseId) {
            ...PersistedLearnerWorkBasedActionFragment
          }
        }
      `),
      {
        courseId,
      }
    )
    .then((data) =>
      data.allLearnerWorkBasedActionsForCourse.map((wba) =>
        mapPersistedWBA(getFragmentData(PersistedLearnerWorkBasedActionFragment, wba))
      )
    );
};

export const submitLearnerWorkBasedAction = (
  client: GraphQLClient,
  id: string,
  input: LearnerWorkBasedActionSubmitInput
): Promise<PersistedLearnerWorkBasedActionWithUser> => {
  return client
    .request(
      graphql(`
        mutation SubmitLearnerWorkBasedAction(
          $id: ID!
          $input: LearnerWorkBasedActionSubmitInput!
        ) {
          submitLearnerWorkBasedAction(id: $id, wbaInput: $input) {
            ...PersistedLearnerWorkBasedActionFragment
          }
        }
      `),
      {
        id,
        input,
      }
    )
    .then((data) => {
      if (data.submitLearnerWorkBasedAction) {
        return mapPersistedWBA(
          getFragmentData(
            PersistedLearnerWorkBasedActionFragment,
            data.submitLearnerWorkBasedAction
          )
        );
      } else {
        throw new Error('no learner work based action returned after submit');
      }
    });
};

export const LearnerWorkBasedActionFragment = graphql(`
  fragment LearnerWorkBasedActionFragment on LearnerWorkBasedAction {
    id
    completed
    completedDate
    courseId
    courseTitle
    description
    history {
      changedAt
      state
      user {
        id
        name
        thumbUri
      }
    }
    submitted
    user {
      id
      name
      thumbUri
    }
    mentor {
      id
      name
      thumbUri
    }
    learnerDescription
    learnerDueDate
    learnerFeedback
    learnerShortDescription
    moduleId
    moduleTitle
    mentorSurveyId
    learnerSurveyId
    sourceId
    activityId
    state
    submitted
    timeSpent
    title
  }
`);

export const PersistedLearnerWorkBasedActionFragment = graphql(`
  fragment PersistedLearnerWorkBasedActionFragment on PersistedLearnerWorkBasedAction {
    id
    completed
    completedDate
    courseId
    courseTitle
    description
    history {
      changedAt
      state
      user {
        id
        name
        thumbUri
      }
    }
    submitted
    user {
      id
      name
      thumbUri
    }
    mentor {
      id
      name
      thumbUri
    }
    learnerDescription
    learnerDueDate
    learnerFeedback
    learnerShortDescription
    moduleId
    moduleTitle
    mentorSurveyId
    learnerSurveyId
    sourceId
    activityId
    state
    submitted
    timeSpent
    title
  }
`);

export const mapWBA = (
  wba: LearnerWorkBasedActionFragmentFragment
): LearnerWorkBasedActionWithUser => {
  return {
    courseId: wba.courseId,
    courseTitle: wba.courseTitle ?? undefined,
    completed: wba.completed,
    completedDate: wba.completedDate ? new Date(wba.completedDate) : undefined,
    description: wba.description,
    id: wba.id ?? undefined,
    learnerDueDate: wba.learnerDueDate ? new Date(wba.learnerDueDate) : undefined,
    learnerFeedback: wba.learnerFeedback ?? undefined,
    learnerShortDescription: wba.learnerShortDescription ?? undefined,
    learnerSurveyId: wba.learnerSurveyId ?? undefined,
    mentor: wba.mentor
      ? {
          id: wba.mentor.id,
          name: wba.mentor.name,
        }
      : undefined,
    mentorSurveyId: wba.mentorSurveyId ?? undefined,
    user: wba.user
      ? {
          id: wba.user.id,
          name: wba.user.name,
          thumbUri: wba.user.thumbUri ?? undefined,
        }
      : undefined,
    history: wba.history?.map((historyItem) => {
      return {
        user: {
          id: historyItem.user.id,
          name: historyItem.user.name,
          thumbUri: historyItem.user.thumbUri ?? undefined,
        },
        changedAt: new Date(historyItem.changedAt),
        state: workbasedActionStateGraphqlToLocalType(historyItem.state),
      };
    }),
    learnerDescription: wba.learnerDescription ?? undefined,
    activityId: wba.activityId,
    sourceId: wba.sourceId,
    title: wba.title,
    moduleId: wba.moduleId,
    moduleTitle: wba.moduleTitle ?? undefined,
    submitted: wba.submitted,
    timeSpent: wba.timeSpent ?? undefined,
    userId: wba.user?.id,
    state: workbasedActionStateGraphqlToLocalType(wba.state),
    headerImage: undefined,
  };
};
const workbasedActionStateGraphqlToLocalType = (
  state: WorkBasedActionState
): LearnerWorkBasedActionState => {
  switch (state) {
    case WorkBasedActionState.Completed:
      return 'completed';
    case WorkBasedActionState.Open:
      return 'open';
    case WorkBasedActionState.Submitted:
      return 'submitted';
    case WorkBasedActionState.Executed:
      return 'executed';
    case WorkBasedActionState.Reviewed:
      return 'reviewed';
  }
};
//TODO technically modeling the different states of a work base action in graphql can avoid a lot of these undefineds
// .   to check if something like discriminated unions are possible.
export const mapPersistedWBA = (
  wba: PersistedLearnerWorkBasedActionFragmentFragment
): PersistedLearnerWorkBasedActionWithUser => {
  return {
    courseId: wba.courseId,
    courseTitle: wba.courseTitle ?? undefined,
    completed: wba.completed,
    completedDate: wba.completedDate ? new Date(wba.completedDate) : undefined,
    description: wba.description,
    id: wba.id,
    learnerDueDate: wba.learnerDueDate ? new Date(wba.learnerDueDate) : undefined,
    learnerFeedback: wba.learnerFeedback ?? undefined,
    learnerShortDescription: wba.learnerShortDescription ?? undefined,
    learnerSurveyId: wba.learnerSurveyId ?? undefined,
    mentor: wba.mentor
      ? {
          id: wba.mentor.id,
          name: wba.mentor.name,
        }
      : undefined,
    mentorSurveyId: wba.mentorSurveyId ?? undefined,
    user: wba.user
      ? {
          id: wba.user.id,
          name: wba.user.name,
          thumbUri: wba.user.thumbUri ?? undefined,
        }
      : undefined,
    history: wba.history?.map((historyItem) => ({
      user: {
        id: historyItem.user.id,
        name: historyItem.user.name,
        thumbUri: historyItem.user.thumbUri ?? undefined,
      },
      changedAt: new Date(historyItem.changedAt),
      state: workbasedActionStateGraphqlToLocalType(historyItem.state),
    })),
    learnerDescription: wba.learnerDescription ?? undefined,
    activityId: wba.activityId,
    sourceId: wba.sourceId,
    title: wba.title,
    moduleId: wba.moduleId,
    moduleTitle: wba.moduleTitle ?? undefined,
    submitted: wba.submitted,
    timeSpent: wba.timeSpent ?? undefined,
    userId: wba.user?.id, //TOCHECK why is this here if we also have a user object?
    state: workbasedActionStateGraphqlToLocalType(wba.state),
    headerImage: undefined,
  };
};
