import { Input } from '../input';
import { VerticalSeparator } from '../../separator/vertical-separator';
import { Switch } from '../switch/switch';
import { ScaleFormErrors, ScaleSettings } from './scale-settings-card';
import { useTranslation } from 'react-i18next';

export type ScaleSettingsFormProps = {
  onChange: (value: ScaleSettings) => void;
  value: ScaleSettings;
  errors?: ScaleFormErrors;
  renderShowToMentorsOption: boolean;
};
export const ScaleSettingsForm: React.FC<ScaleSettingsFormProps> = ({
  value,
  onChange,
  errors,
  renderShowToMentorsOption,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'survey-step-view.question-types.scale',
  });
  const { t: tValidation } = useTranslation('translation', { keyPrefix: 'validation' });

  const updateTitleValue = (title: string) => onChange({ ...value, title });
  const updateLeftLabelValue = (leftLabel: string) => onChange({ ...value, leftLabel });
  const updateRightLabelValue = (rightLabel: string) => onChange({ ...value, rightLabel });
  const updateRequiredValue = (required: boolean) => onChange({ ...value, required });
  const updateShowToMentorsValue = (showToMentors: boolean) =>
    onChange({ ...value, showToMentors });

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="col-span-2">
        <Input
          required
          htmlId="question-title-input"
          label={t('question-input-label')}
          onChange={(event) => updateTitleValue(event.target.value)}
          text={value.title}
          error={errors?.title?.message}
          placeholder={t('question-input-placeholder')}
        />
      </div>
      <div className="col-span-1">
        <Input
          htmlId="scale-left-label-input"
          label={t('left-label-placeholder')}
          onChange={(event) => updateLeftLabelValue(event.target.value)}
          text={value.leftLabel}
          error={errors?.leftLabel?.message}
        />
      </div>
      <div className="col-span-1">
        <Input
          htmlId="scale-right-label-input"
          label={t('right-label-placeholder')}
          onChange={(event) => updateRightLabelValue(event.target.value)}
          text={value.rightLabel}
          error={errors?.rightLabel?.message}
        />
      </div>
      <div className="col-span-2 flex items-center space-x-4">
        <Switch
          label={tValidation('required')}
          value={value.required}
          onChange={(checked) => updateRequiredValue(checked)}
        />
        {renderShowToMentorsOption && (
          <>
            <VerticalSeparator />
            <Switch
              label={t('show-to-mentors')}
              value={value.showToMentors}
              onChange={(checked) => updateShowToMentorsValue(checked)}
            />
          </>
        )}
      </div>
    </div>
  );
};
