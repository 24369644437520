import { ReactNode, ForwardRefRenderFunction, forwardRef } from 'react';

type FontBodyTypes = 'medium' | 'emphasis' | 'default';
type ElementType = 'div' | 'span' | 'p';

export type FontBodyProps = {
  tag?: ElementType;
  children: ReactNode;
  className?: string;
  type: FontBodyTypes;
};

const FontBodyBase: ForwardRefRenderFunction<HTMLDivElement, FontBodyProps> = (
  { children, className, type, tag: Tag = 'p' },
  ref
) => {
  return (
    <Tag
      ref={ref}
      className={`${className ?? ''} font-lexend whitespace-normal text-base
                ${type === 'default' ? 'font-regular' : ''}
                ${type === 'emphasis' ? 'font-semibold' : ''}
                ${type === 'medium' ? 'font-medium' : ''}`}
    >
      {children}
    </Tag>
  );
};

/**
 * @deprecated `FontBody` is deprecated. Use tailwind components instead.
 */
export const FontBody = forwardRef(FontBodyBase);
