import { NavEntry } from '../ui-shell/types';
import { useScreenType } from '@stellar-lms-frontend/common-utils';

export type NavButtonProps = {
  navEntry: NavEntry;
  type: 'icon' | 'icon-text';
  theme?: 'blue' | 'white';
};

export const NavButton: React.FC<NavButtonProps> = ({ navEntry, type, theme }) => {
  const { isTailwindLg } = useScreenType();
  const routeActive = navEntry.isActive;
  const icons = routeActive && !isTailwindLg ? navEntry.filledIcon : navEntry.icon;

  return (
    <button
      onClick={navEntry.action}
      data-cy={
        routeActive
          ? `nav-button-${navEntry.title}-active`
          : `nav-button-${navEntry.title}-inactive`
      }
      className={`font-lexend flex cursor-pointer flex-col items-center space-y-2 rounded-full text-[10px]
        uppercase lg:flex-row lg:space-x-2 lg:space-y-0 lg:py-2 lg:px-4 lg:text-[15px] lg:normal-case
        ${
          theme === 'blue' &&
          `hover:lg:fill-on-primary-01 hover:lg:text-on-primary-01 hover:lg:bg-white/20 ${
            routeActive
              ? 'text-primary-01 fill-primary-01 lg:bg-on-primary-01'
              : 'fill-on-primary-01 text-on-primary-01'
          }`
        }
        ${
          theme === 'white' &&
          `hover:lg:bg-blue-50 ${
            routeActive
              ? 'text-primary-01 fill-primary-01 lg:bg-on-primary-02'
              : 'text-text-03 fill-text-03 lg:text-primary-01 lg:fill-primary-01'
          }`
        }
         `}
    >
      <div className="flex">
        {icons.map((Icon, index) => (
          <Icon
            key={index}
            className={`h-6 w-6 lg:h-5 lg:w-5`}
          />
        ))}
      </div>
      {type === 'icon-text' && navEntry.title && <p>{navEntry.title}</p>}
    </button>
  );
};
