import {
  Comment2AlertIcon,
  HintBox,
  Modal,
  PrimaryButton,
} from '@stellar-lms-frontend/ui-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type TransferObjectivesModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const TransferObjectivesModal: React.FC<TransferObjectivesModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'transfer-objectives-modal' });
  const [isHintOpen, setIsHintOpen] = useState(true);

  useEffect(() => {
    setIsHintOpen(true);
  }, [isOpen]);

  return (
    <Modal
      title={t('title')}
      isOpen={isOpen}
      onClose={onClose}
      className="bg-surface-01 h-full md:h-auto"
    >
      <div className="space-y-6">
        <p className="text-text-01">{t('subtitle')}</p>
        <HintBox
          icon={<Comment2AlertIcon className="text-primary-02" />}
          title={t('hint.title')}
          isOpen={isHintOpen}
          theme={'blue'}
        >
          <ul className="text-text-01 list-disc pl-6 text-sm">
            <li>{t('hint.item1')}</li>
            <li>{t('hint.item2')}</li>
            <li>{t('hint.item3')}</li>
            <li>{t('hint.item4')}</li>
          </ul>
        </HintBox>
        <PrimaryButton
          className="mt-2"
          onClick={onClose}
          label={t('cta')}
        />
      </div>
    </Modal>
  );
};
