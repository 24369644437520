import { KeyboardEvent } from 'react';
import { Color, StatusIcon, Type } from './status-icon';

export type Theme = 'colored' | 'grey' | 'blue';

export type SelectCheckBoxProps = {
  hasAtLeastOneChecked: boolean;
  isMultiSelect: boolean;
  onChange?: (checked: boolean) => void;
  checked: boolean;
  isDisabled?: boolean;
  theme?: Theme;
};

export const SelectCheckBox: React.FC<SelectCheckBoxProps> = ({
  checked,
  isMultiSelect,
  onChange,
  isDisabled,
  theme,
  hasAtLeastOneChecked,
}) => {
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (event.key === ' ') {
      onChange?.(!checked);
    }
  };

  let color: Color;
  let type: Type = 'check';

  if (theme === 'blue') {
    color = 'blue';
    type = checked ? 'check' : 'cross';
  } else if (isDisabled) {
    if (theme === 'grey') {
      color = 'grey';
    } else {
      color = checked ? 'green' : 'red';
    }
    type = checked ? 'check' : 'cross';
  } else if (!hasAtLeastOneChecked) {
    color = 'outline-grey';
  } else if (!checked) {
    color = 'outline-grey';
    type = 'cross';
  } else {
    color = 'blue-gradient';
  }

  return (
    <div
      aria-checked={checked}
      role="checkbox"
      tabIndex={0}
      className="cursor-pointer"
      onClick={() => onChange?.(!checked)}
      onKeyDown={handleKeyDown}
    >
      <StatusIcon
        color={color}
        type={type}
        shape={isMultiSelect ? 'rect' : 'circle'}
      />
    </div>
  );
};
