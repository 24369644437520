import { wizardBg } from '../../../images';
import { useContext } from 'react';
import { WizardStep } from '../types';
import { WizardContext } from '../wizard-context';
import { ProgressBarStepItem } from './progress-bar-step-item';

export type ProgressBarProps = {
  allowStepClick: boolean;
  steps: WizardStep[];
};
export const ProgressBar: React.FC<ProgressBarProps> = ({ allowStepClick, steps }) => {
  const { activeStepIndex, goToStep } = useContext(WizardContext);

  const handleStepClick = (
    e: React.MouseEvent,
    currentStep: WizardStep,
    currentStepIndex: number
  ) => {
    e.preventDefault();
    isStepEnabled(currentStep, activeStepIndex, currentStepIndex) && goToStep(currentStepIndex);
  };

  const isStepEnabled = (step: WizardStep, activeStepIndex: number, currentStepIndex: number) => {
    if (allowStepClick) {
      return step.isCompleted || activeStepIndex >= currentStepIndex;
    }

    return false;
  };

  return (
    <div
      className="relative flex h-[144px] items-center bg-cover bg-center bg-no-repeat px-8"
      style={{ backgroundImage: `url(${wizardBg})` }}
    >
      {steps.map((step, i) => (
        <div
          className="w-full"
          key={step.title}
        >
          <i className="mb-8 h-[2px] grow bg-blue-400 first:bg-transparent"></i>
          <ProgressBarStepItem
            isEnabled={isStepEnabled(step, activeStepIndex, i)}
            title={step.title}
            stepNumber={i + 1}
            isActive={activeStepIndex === i}
            isFirst={i === 0}
            isLast={i === steps.length - 1}
            isCompleted={step.isCompleted}
            onClick={(e) => handleStepClick(e, step, i)}
          />
          <i className="mb-8 h-[2px] grow bg-blue-400 last:bg-transparent"></i>
        </div>
      ))}
    </div>
  );
};
