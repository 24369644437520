import { useQuery } from '@tanstack/react-query';
import { Video } from '@stellar-lms-frontend/lms-graphql';
import { VideoSuggestionCard } from './video-suggestion-card';
import { Heading3, MagnifyingLight } from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';

const MIN_SEARCH_LENGTH = 3;
const VIDEO_SEARCH_QUERY_KEY = 'VIDEO_SEARCH_QUERY';

export const SuggestedVideos = ({
  query,
  onSelectVideo,
  onDeselectVideo,
  findVideos,
}: {
  query: string;
  findVideos: (query: string) => Promise<Video[] | undefined>;
  onSelectVideo: (video: Video) => void;
  onDeselectVideo: (video: Video) => void;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'block-step-view.video-ai-sidebar' });

  const {
    isLoading,
    fetchStatus,
    data: videoSuggestions,
  } = useQuery([VIDEO_SEARCH_QUERY_KEY, query.trim()], () => findVideos(query.trim()), {
    enabled: query.trim().length > MIN_SEARCH_LENGTH,
    refetchOnWindowFocus: false,
  });

  // Rendering
  if (videoSuggestions && videoSuggestions.length > 0) {
    return (
      <>
        {videoSuggestions.map((video) => (
          <VideoSuggestionCard
            key={video.url}
            video={video}
            onDeselectVideo={onDeselectVideo}
            onSelectVideo={onSelectVideo}
          />
        ))}
      </>
    );
  } else if (fetchStatus !== 'idle' && isLoading) {
    return (
      <>
        {[...Array(10)].map((_, index) => {
          return (
            <VideoSuggestionCard
              key={index}
              onDeselectVideo={onDeselectVideo}
              onSelectVideo={onSelectVideo}
            />
          );
        })}
      </>
    );
  } else {
    return (
      <div className="mt-[100px] flex flex-col items-center space-y-6">
        <MagnifyingLight />
        <Heading3 className="text-text-01">{t('empty.title')}</Heading3>
        <p className="type-body text-text-02">{t('empty.description')}</p>
      </div>
    );
  }
};
