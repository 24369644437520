import { ReactNode } from 'react';

export type ItemWithCircleProps = {
  circleChildren: ReactNode;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
};

export const ItemWithCircle: React.FC<ItemWithCircleProps> = ({
  circleChildren,
  children,
  className,
  onClick,
}) => {
  return (
    <li
      onClick={onClick}
      className={`${className} flex items-center gap-2
                  ${onClick ? 'cursor-pointer' : ''}`}
    >
      <span
        className="border-border-01 text-text-02 flex h-6 w-6 items-center justify-center
                    rounded-full border-2 font-semibold"
      >
        {circleChildren}
      </span>
      {children}
    </li>
  );
};
