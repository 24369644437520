import { sortDates, useScreenType } from '@stellar-lms-frontend/common-utils';
import { isWBACompleted, PersistedLearnerWorkBasedAction } from '@stellar-lms-frontend/lms-api';
import {
  ActionEmptyStateIcon,
  Dropdown,
  EmptyState,
  LogoLoader,
  LeftArrowIcon,
} from '@stellar-lms-frontend/ui-components';
import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MENTEE_ACTIONS } from '../../constants/query';
import { MENTOR_ACTIONS_BASE_ROUTE } from '../../constants/routes';
import { ActionDetail } from '../actions/components/action-detail';
import { CompletedActionList } from '../actions/components/completed-action-list';
import { OngoingActionList } from '../actions/components/ongoing-action-list';

import { ActionsUserFilterOption } from './components/actions-user-filter-option';
import { SplitView } from '../shell/split-view';
import { getMenteeActions } from '../actions/actions.api';
import { graphQLClient } from '../../lib/graphql';

export const MenteeActionsOverviewPage = () => {
  const { courseId = '', actionId } = useParams();
  const location = useLocation();
  const [action, setAction] = useState<PersistedLearnerWorkBasedAction>();
  const [showMoreActions, setShowMoreActions] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'mentees-overview' });
  const navigate = useNavigate();
  const { userFilter } = location?.state
    ? (location?.state as { userFilter: string })
    : { userFilter: '' };
  const { isDesktop } = useScreenType();

  const { data: mentees, isLoading } = useQuery([MENTEE_ACTIONS, { courseId }], () =>
    getMenteeActions(graphQLClient(), courseId)
  );

  const redirectCourseId = courseId;

  const { open: openActions, closed: completedActions } = useMemo(() => {
    const actions = (
      mentees
        ?.filter((summary) => userFilter === '' || summary.userId === userFilter)
        .flatMap((summary) => summary.actions) ?? []
    ).reduce(
      (acc, current) => {
        isWBACompleted(current) ? acc.closed.push(current) : acc.open.push(current);
        return acc;
      },
      { open: [], closed: [] } as {
        open: PersistedLearnerWorkBasedAction[];
        closed: PersistedLearnerWorkBasedAction[];
      }
    );

    // Move overdue items to the top
    // TOCLEAN move this date sort to util func
    const sortedOpenActions = actions.open
      .sort((a, b) => sortDates(a.learnerDueDate, b.learnerDueDate))
      .sort((a) => (a.state === 'executed' ? -1 : 0));

    return { open: sortedOpenActions, closed: actions.closed };
  }, [mentees, userFilter]);

  const onUserFilterChange = (event: ChangeEvent<HTMLSelectElement>) => {
    navigate(location.pathname, { state: { userFilter: event.target.value } });
  };

  const userIds = useMemo(
    () =>
      mentees?.filter((summary) => summary.actions.length > 0).map((summary) => summary.userId) ??
      [],
    [mentees]
  );

  // Open a first action if there is a possible action to open
  useEffect(() => {
    if (!actionId && isDesktop) {
      const action = openActions.at(0);
      action &&
        navigate(`${MENTOR_ACTIONS_BASE_ROUTE(redirectCourseId)}/${action.id}`, {
          state: location.state,
        });
    } else {
      const action =
        openActions.find((action) => action.id === actionId) ??
        completedActions.find((action) => action.id === actionId);
      setAction(action);
    }

    if (!isLoading) {
      const isActionInFilteredActions =
        openActions.some((action) => action.id === actionId) ||
        completedActions.some((action) => action.id === actionId);
      if (actionId && !isActionInFilteredActions) {
        // remove actionID from url, it does not exist in our filtered list
        navigate(MENTOR_ACTIONS_BASE_ROUTE(redirectCourseId), {
          replace: true,
          state: location.state,
        });
      }
    }
  }, [
    actionId,
    isDesktop,
    openActions,
    navigate,
    completedActions,
    location.state,
    isLoading,
    redirectCourseId,
  ]);

  return (
    <SplitView
      leftButton={{
        action: () => navigate(-1),
        leftIcon: <LeftArrowIcon className="text-on-primary-01" />,
        label: '',
      }}
      detailSelected={!!actionId}
      detailView={
        openActions.length === 0 && !actionId ? (
          <div className="mt-10 w-full xl:mt-16">
            <EmptyState
              icon={<ActionEmptyStateIcon />}
              title={t('empty-ongoing.title', { count: userIds.length })}
              description={t('empty-ongoing.description', { count: userIds.length })}
            />
          </div>
        ) : action ? (
          <ActionDetail
            key={action.id}
            action={action}
            isUserMentor={true}
          />
        ) : (
          <LogoLoader />
        )
      }
      overview={
        <div className="flex w-full flex-col">
          <div className="w-full pr-6">
            <Dropdown
              htmlId="mentee-actions-user-filter-dropdown"
              value={userFilter}
              onChange={onUserFilterChange}
            >
              <option
                key=""
                value=""
              >
                {t('all-learners-filter-option')}
              </option>
              {userIds.map((id) => (
                <ActionsUserFilterOption
                  key={id}
                  userId={id}
                />
              ))}
            </Dropdown>
          </div>
          <section className="mt-8 w-full">
            <OngoingActionList
              openActions={openActions}
              actionId={actionId}
              isDesktop={true}
              showUserName={userFilter === ''}
              noOpenActionsText={t('no-open-actions', { count: userIds.length })}
              baseUrl={MENTOR_ACTIONS_BASE_ROUTE(redirectCourseId)}
            />
          </section>
          <section className="w-full pt-12">
            <CompletedActionList
              completedActions={completedActions}
              isDesktop={true}
              showUserName={userFilter === ''}
              actionId={actionId}
              showMore={showMoreActions}
              setShowMore={setShowMoreActions}
              baseUrl={MENTOR_ACTIONS_BASE_ROUTE(redirectCourseId)}
            />
          </section>
        </div>
      }
    />
  );
};
