import React from 'react';
import { ReactNode } from 'react';

export type MobileNavFooterProps = {
  entries: ReactNode[];
};

export const MobileNavFooter = ({ entries }: MobileNavFooterProps) => {
  return (
    <nav
      data-cy="mobile-nav-footer"
      className={`text-text-03 bg-on-primary-01 flex min-h-[92px] w-full items-center justify-around lg:hidden`}
    >
      {entries}
    </nav>
  );
};
