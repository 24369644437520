import { Dispatch, useCallback, useState } from 'react';

/**
 * Utility hook to help with storing functions in state.
 *
 * @param initialFunc the initial function to put in the state.
 * @returns
 */
export function useStateForFunction<T>(initialFunc: T): [T, Dispatch<T>] {
  // The initial value needs to be wrapped in a function so that useState
  // does not mistake our func value as an initializer function.

  const setStateWrapper = useCallback((newState: T) => setState(() => newState), []);

  const [state, setState] = useState(() => initialFunc);
  return [state, setStateWrapper];
}
