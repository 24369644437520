import { ReactNode } from 'react';

import { useScreenType } from '@stellar-lms-frontend/common-utils';
import { HeaderNavTheme } from './types';

export type NavHeaderProps = {
  leftNavEntries: ReactNode[];
  centerNavEntries: ReactNode[];
  rightNavEntries: ReactNode[];
  title: ReactNode;
  theme: HeaderNavTheme;
};

export const NavHeader: React.FC<NavHeaderProps> = ({
  leftNavEntries,
  centerNavEntries,
  rightNavEntries,
  title,
  theme,
}) => {
  const { isTailwindLg } = useScreenType();

  if (!isTailwindLg) return null;

  return (
    <nav
      data-cy="nav-header"
      className={`text-on-primary-01 sticky top-0 z-10 hidden w-full shrink-0 items-center py-6 px-10 lg:grid lg:grid-cols-3 lg:px-16
      ${theme === 'white' ? 'bg-on-primary-01 shadow-floating-header' : ''}
      `}
    >
      <div className="flex items-center gap-4 justify-self-start">{leftNavEntries}</div>
      <div className="flex items-center gap-2 justify-self-center">
        {title ? (
          <span className="font-lexend text-base lg:text-xl">{title}</span>
        ) : (
          centerNavEntries
        )}
      </div>
      <div className="flex max-h-9 items-center gap-2 justify-self-end">{rightNavEntries}</div>
    </nav>
  );
};
