import { useRef, useState } from 'react';
import { useOnClickOutside } from '@stellar-lms-frontend/common-utils';
import { ClickableSelectCard } from '../components/clickable-select-card';
import { UniqueIdentifier } from '@dnd-kit/core';
import { TrashIcon, PlusCircleIcon, CheckCircle2Icon } from '../../../icons';
import { DragVerticalList, IconItem } from '../../../list';
import { SelectCheckBox } from './components/select-checkbox';
import { DragHandler } from '../../../drag-handler/drag-handler';
import { AutoResizeTextArea } from '../../text-area';

export type EditableSelectOptionType = {
  id?: string;
  correct?: boolean;
  text: string;
  feedback?: string;
};

export type EditableSelectProps = {
  options: EditableSelectOptionType[];
  onChange: (options: EditableSelectOptionType[]) => void;
  ctaLabel: string;
  placeholder?: string;
  feedbackPlaceholder?: string;
  hasAnswerFeedback?: boolean;
  emptyOptionLabel?: string;
} & (
  | {
      showCorrectAnswerSelectors: true;
      isMultiSelect: boolean;
    }
  | {
      showCorrectAnswerSelectors: false;
      isMultiSelect: undefined;
    }
);

export const EditableSelect: React.FC<EditableSelectProps> = ({
  options,
  onChange,
  ctaLabel,
  placeholder,
  feedbackPlaceholder,
  showCorrectAnswerSelectors = false,
  isMultiSelect = false,
  hasAnswerFeedback,
  emptyOptionLabel,
}) => {
  const [editingIndex, setEditingIndex] = useState<UniqueIdentifier | undefined>(undefined);
  const ref = useRef<HTMLButtonElement>(null);
  useOnClickOutside(() => setEditingIndex(undefined), [ref]);

  const onOrderUpdate = (
    options: EditableSelectOptionType[],
    previousPosition: number,
    newPosition: number
  ) => {
    if (editingIndex === previousPosition) {
      setEditingIndex(newPosition);
    }
    onChange(options);
  };

  const onItemTextChange = (text: string) =>
    onChange(options.map((o, index) => (index === editingIndex ? { ...o, text } : o)));

  const onItemFeedbackChange = (feedback: string) =>
    onChange(options.map((o, index) => (index === editingIndex ? { ...o, feedback } : o)));

  const onItemCorrectChange = (
    correct: boolean,
    currentIndex: number,
    removePreviousSelection: boolean
  ) =>
    onChange(
      options.map((o, index) => {
        if (index === currentIndex) {
          return {
            ...o,
            correct,
          };
        } else {
          return removePreviousSelection
            ? {
                ...o,
                correct: false,
              }
            : o;
        }
      })
    );

  const [numberOfAnswersChecked, setNumberOfAnswersChecked] = useState<number>(
    options.reduce((prev, curr) => prev + (curr.correct ? 1 : 0), 0)
  );

  const answerChecked = (index: number, checked: boolean) => {
    if (!isMultiSelect && numberOfAnswersChecked > 0) {
      onItemCorrectChange(checked, index, true);
      setNumberOfAnswersChecked(checked ? 1 : 0);
    } else {
      onItemCorrectChange(checked, index, false);
      setNumberOfAnswersChecked((current) => current + (checked ? 1 : -1));
    }
  };

  return (
    <div className="font-lexend space-y-4">
      <DragVerticalList
        items={options}
        onChange={onOrderUpdate}
        listClassName="flex flex-col gap-3"
      >
        {({ item: { text, correct, feedback }, setActivatorNodeRef, listeners, index }) => (
          <div className="flex items-center space-x-4">
            <ClickableSelectCard
              className={'text-text-01 flex justify-between gap-4 py-2'}
              onClick={() => setEditingIndex(index)}
              ref={editingIndex === index ? ref : undefined}
            >
              <div className="flex w-full items-center gap-4 whitespace-pre-wrap text-left">
                <DragHandler
                  setActivatorNodeRef={setActivatorNodeRef}
                  listeners={listeners}
                  className="text-text-01 shrink-0"
                />
                {editingIndex === index ? (
                  <div className="w-full space-y-2">
                    <AutoResizeTextArea
                      autoFocus
                      placeholder={placeholder}
                      className="w-full"
                      value={text}
                      minRows={1}
                      maxRows={3}
                      size="small"
                      onChange={(event) => onItemTextChange(event.target.value)}
                    />
                    {hasAnswerFeedback && (
                      <AutoResizeTextArea
                        placeholder={feedbackPlaceholder}
                        className="w-full"
                        value={feedback}
                        leftIcon={<CheckCircle2Icon className="text-text-03 h-5 w-5" />}
                        minRows={3}
                        maxRows={3}
                        size="small"
                        onChange={(event) => onItemFeedbackChange(event.target.value)}
                      />
                    )}
                  </div>
                ) : !text && emptyOptionLabel ? (
                  <p className="text-text-03">{emptyOptionLabel}</p>
                ) : (
                  text
                )}
              </div>
              {editingIndex === index && (
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditingIndex(undefined);
                    onChange(options.filter((_, index) => index !== editingIndex));
                  }}
                >
                  <TrashIcon className="text-text-01" />
                </button>
              )}
            </ClickableSelectCard>
            {showCorrectAnswerSelectors && (
              <SelectCheckBox
                isMultiSelect={isMultiSelect}
                checked={correct ?? false}
                hasAtLeastOneChecked={numberOfAnswersChecked > 0}
                onChange={(checked) => answerChecked(index, checked)}
              />
            )}
          </div>
        )}
      </DragVerticalList>
      <ClickableSelectCard
        className={`text-primary-02 ${showCorrectAnswerSelectors ? 'w-[calc(100%-43px)]' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          onChange([...options, hasAnswerFeedback ? { text: '', feedback: '' } : { text: '' }]);
          setEditingIndex(options.length);
        }}
      >
        <IconItem
          size="no-padding"
          left={<PlusCircleIcon className="text-primary-02" />}
          title={<span>{ctaLabel}</span>}
        />
      </ClickableSelectCard>
    </div>
  );
};
