import ReactDOM from 'react-dom';
import { InlineTool, SanitizerConfig, API } from '@editorjs/editorjs';
import { IconLink, IconUnlink } from '@codexteam/icons';
import { v4 as uuidv4 } from 'uuid';

import { LinkInlineToolButtonComponent } from './components/link-inline-tool-button-component';
import { LinkInlineToolInputComponent } from './components/link-inline-tool-input-component';
import { LinkInlineToolUtils } from './link-inline-tool-utils';
import { addProtocol } from '@stellar-lms-frontend/common-utils';

const TARGET = '_blank';
const REL = 'noopener noreferrer';
export default class LinkInlineTool implements InlineTool {
  static isInline = true;
  static title = 'Link';

  buttonElement: HTMLElement;
  inputElement: HTMLElement;
  api: API;
  linkInlineToolUtils: LinkInlineToolUtils;
  inputOpened = false;
  isLinked = false;
  link = '';

  constructor({ api }: { api: API }) {
    this.api = api;
    this.linkInlineToolUtils = new LinkInlineToolUtils();
    this.buttonElement = this.createElement();
    this.inputElement = this.createElement();

    this.enterPressed = this.enterPressed.bind(this);
  }

  createElement() {
    const rootNode = document.createElement('div');
    rootNode.id = uuidv4();
    return rootNode;
  }

  updateElements() {
    // Calling ReactDOM will generate errors on the console, it should be replaced with createRoot.
    // But doing that will lead to error on EditorJs side (eg. Press Enter won't create a new block)
    ReactDOM.render(
      <LinkInlineToolButtonComponent
        icon={<div dangerouslySetInnerHTML={{ __html: this.isLinked ? IconUnlink : IconLink }} />}
        className={`ce-inline-tool ce-inline-tool--link
                  ${this.isLinked ? 'ce-inline-tool--unlink ce-inline-tool--active' : ''}
        `}
      />,
      this.buttonElement
    );

    ReactDOM.render(
      <LinkInlineToolInputComponent
        onEnter={this.enterPressed}
        placeholder={this.api.i18n.t('Add a link')}
        className={`ce-inline-tool-input ${this.inputOpened ? 'ce-inline-tool-input--showed' : ''}`}
        value={this.link}
        onChange={(link) => {
          this.link = link;
        }}
        isVisible={this.inputOpened}
      />,
      this.inputElement
    );
  }

  render() {
    this.updateElements();
    return this.buttonElement;
  }

  renderActions() {
    this.updateElements();
    return this.inputElement;
  }

  surround(range: Range) {
    if (range) {
      if (!this.inputOpened) {
        this.linkInlineToolUtils.highlightSelection();
        this.linkInlineToolUtils.saveRange();
      } else {
        this.linkInlineToolUtils.restore();
        this.linkInlineToolUtils.removeHighlightSelection();
      }
      const parentAnchor = this.linkInlineToolUtils.getParentTag('A');

      if (parentAnchor) {
        this.linkInlineToolUtils.selectElement(parentAnchor);
        this.linkInlineToolUtils.clean();
        this.closeActions();
        this.checkState();
        this.api.toolbar.close();

        return;
      }
    }

    this.toggleActions();
  }

  checkState() {
    const anchorTag = this.linkInlineToolUtils.getParentTag('A');

    if (anchorTag) {
      this.isLinked = true;
      this.openActions();
      this.link = anchorTag.getAttribute('href') ?? '';
      this.linkInlineToolUtils.saveRange();
    } else {
      this.isLinked = false;
    }

    this.updateElements();
    return !!anchorTag;
  }

  toggleActions() {
    this.inputOpened ? this.closeActions() : this.openActions();
  }

  openActions() {
    this.inputOpened = true;
    this.updateElements();
  }

  closeActions(clearSavedSelection = true) {
    const currentSelection = new LinkInlineToolUtils();

    currentSelection.saveRange();
    this.linkInlineToolUtils.restore();
    this.linkInlineToolUtils.removeHighlightSelection();
    currentSelection.restore();

    this.link = '';

    if (clearSavedSelection) {
      this.linkInlineToolUtils.clearSavedSelection();
    }

    this.inputOpened = false;
    this.updateElements();
  }

  enterPressed() {
    const link = addProtocol(this.link.trim());
    this.linkInlineToolUtils.restore();

    if (link) {
      this.linkInlineToolUtils.removeHighlightSelection();
      this.insertLink(link);
      this.linkInlineToolUtils.moveToEnd();
      this.api.inlineToolbar.close();
    } else {
      this.linkInlineToolUtils.clean();
      this.closeActions();
    }
  }

  insertLink(link: string) {
    const anchorTag = this.linkInlineToolUtils.getParentTag('A');
    const { selection } = this.linkInlineToolUtils;

    if (anchorTag) {
      this.linkInlineToolUtils.selectElement(anchorTag);
    }

    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const anchor = document.createElement('a');
      anchor.href = link;
      anchor.target = TARGET;
      anchor.rel = REL;
      anchor.textContent = range.toString();
      range.deleteContents();
      range.insertNode(anchor);
      range.selectNode(anchor);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  clear() {
    this.closeActions();
  }

  static get sanitize(): SanitizerConfig {
    return {
      a: {
        href: true,
        target: TARGET,
        rel: REL,
      },
    };
  }
}
