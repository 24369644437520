import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { fetchAppVersion } from '../apis';
const REFRESH_INTERVAL = 60000;

export const useAppVersion = () => {
  const [appVersion, setAppVersion] = useState<string>();
  const [appIsUpdated, setAppIsUpdated] = useState<boolean>(false);

  const { data } = useQuery(['version'], fetchAppVersion, {
    cacheTime: REFRESH_INTERVAL,
    staleTime: REFRESH_INTERVAL,
    refetchInterval: REFRESH_INTERVAL,
    refetchIntervalInBackground: true,
  });

  useEffect(() => {
    if (!appVersion && data) {
      setAppVersion(data.version);
      return;
    }
    if (data && appVersion !== data.version) {
      setAppIsUpdated(true);
    }
  }, [data, appVersion]);

  return appIsUpdated;
};

export default useAppVersion;
