export enum RequestStatus {
  NO_ACCESS,
  NOT_FOUND,
  UNKNOWN,
}

export class RequestError extends Error {
  status: RequestStatus;

  constructor(status: RequestStatus, cause: unknown) {
    super('RequestError');
    this.status = status;
    this.cause = cause;
  }
}
