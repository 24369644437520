import { DragItemProps } from '../list';
import { FreeTextEditClosed } from './components/free-text-edit-closed';
import { FreeTextEditForm } from './components/free-text-edit-form';
import { SettingsCard } from '../card';

export type FreeTextFormErrors = {
  questionTitle?: {
    message?: string;
  };
};

export type FreeTextSettingData = {
  id?: string;
  questionTitle?: string;
  isMultiline: boolean;
  visibleToMentors: boolean;
  required: boolean;
};

export type FreeTextEditCardProps = {
  value: FreeTextSettingData;
  onChange: (value: FreeTextSettingData) => void;
  errors?: FreeTextFormErrors;
  onToggleOpenState?: () => void;
  onDelete: () => void;
  isOpen: boolean;
  dragProps?: DragItemProps;
  renderShowToMentorsOption: boolean;
};

export const FreeTextEditCard: React.FC<FreeTextEditCardProps> = ({
  dragProps,
  isOpen,
  onToggleOpenState,
  onDelete,
  onChange,
  value,
  errors,
  renderShowToMentorsOption,
}) => {
  return (
    <SettingsCard
      hasOptionsMenu
      onToggleOpenState={onToggleOpenState}
      onDelete={onDelete}
      isOpen={isOpen}
      dragProps={dragProps}
      openChildren={
        <FreeTextEditForm
          renderShowToMentorsOption={renderShowToMentorsOption}
          onChange={(data) => onChange({ id: value?.id, ...data })}
          value={value}
          errors={errors}
        />
      }
      closedChildren={<FreeTextEditClosed {...value} />}
      hasError={!!errors}
    />
  );
};
