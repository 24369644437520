import { BasicUserSettings } from './types';
import { MutationUpdateUserProfileArgs, graphql } from '@stellar-lms-frontend/lms-graphql';
import { GraphQLClient } from 'graphql-request';

export const fetchUserSettings = (
    client: GraphQLClient
): Promise<BasicUserSettings> => {
    return client.request(
        graphql(`
            query CurrentUserSettings {
                currentUser {
                    settings {
                        enabled {
                            push
                            email
                        }
                        spacedRepetitionContactTime
                        spacedRepetitionNotifications
                    }
                }
            }
        `)
    ).then((data) => {
        return data.currentUser?.settings ? data.currentUser.settings : {
            enabled: {
                push: false,
                email: false
            },
            spacedRepetitionContactTime: '',
            spacedRepetitionNotifications: false
        };
    });
};

export const updateUserSettings = async (
    client: GraphQLClient,
  userId: string,
  settings: BasicUserSettings
): Promise<void> => {
    await client.request(
        graphql(`
            mutation UpdateUserSettings($userId: ID!, $userSettingsInput: UserSettingsInput!) {
                updateUserSettings(userId: $userId, userSettingsInput: $userSettingsInput)
            }
        `), {
            userId,
            userSettingsInput: settings
        }
    )

    return Promise.resolve();
};

export const updateUserProfile = (
  client: GraphQLClient,
  variables: MutationUpdateUserProfileArgs
) => {
  return client.request(
    graphql(`
      mutation UpdateUserProfile($userId: ID!, $userProfileInput: UserProfileInfoInput) {
        updateUserProfile(userId: $userId, userProfileInput: $userProfileInput)
      }
    `),
    variables
  );
};
