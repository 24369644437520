import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import {
  BadgeAI,
  ChevronDownIcon,
  ChevronUpIcon,
  CopyIcon,
  ListCard,
  SpinnerLoader,
  TrashIcon,
} from '@stellar-lms-frontend/ui-components';
import { CourseModuleOutline } from '../store/module/types';
import { convertToPlaceholderTitle } from '../functions';
import { useTranslation } from 'react-i18next';

export type ListItemProps = {
  isHighlighted: boolean;
  onClick: VoidFunc;
  module: CourseModuleOutline;
  setActivatorNodeRef: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
  actions: { moveUp: () => void; moveDown: () => void; duplicate: () => void; delete: () => void };
};

export const EditListItem: React.FC<ListItemProps> = ({
  isHighlighted,
  onClick,
  module,
  setActivatorNodeRef,
  listeners,
  actions,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'general' });

  return (
    <ListCard
      isHighlighted={isHighlighted}
      id={`sidebar-module-${module.id}`}
      onClick={onClick}
      color={module.placeholder ? 'purple' : 'default'}
      dragProps={{
        setActivatorNodeRef,
        listeners,
      }}
      options={[
        ...(module.placeholder ? [] : [duplicateButton(t('duplicate'), actions.duplicate)]),
        moveUpButton(t('move-up'), actions.moveUp),
        moveDownButton(t('move-down'), actions.moveDown),
        ...(module.placeholder ? [] : [deleteButton(t('delete'), actions.delete)]),
      ]}
    >
      <div className="flex min-h-[20px] items-center">
        <span className="grow">
          {module.placeholder ? convertToPlaceholderTitle(module.title) : module.title ?? ''}
        </span>
        {module.learningActivities.some((la) => la.aiGenerated && !la.aiGenerationDone) && (
          <SpinnerLoader
            color={isHighlighted ? 'blue' : 'grey'}
            className="text-[3px]"
          />
        )}
        {module.placeholder && (
          <BadgeAI
            color="dark-purple"
            label={t('ai-badge-label')}
          />
        )}
      </div>
    </ListCard>
  );
};

// CLEANUP
// I think we can provide these functions somewhere else (translations included??), these are pretty much copy pasted all around the app and should have consistent icons.
// to avoid duplicating most of this every time. The only thing that actually changes
// is the function (some labels might include the entity name so might have to change as well..)
const duplicateButton = (label: string, func: VoidFunc) => ({
  label: label,
  onClick: func,
  left: <CopyIcon className="text-text-01 h-4 w-4" />,
});

const deleteButton = (label: string, func: VoidFunc) => ({
  label: label,
  onClick: func,
  left: <TrashIcon className="text-negative-01 h-4 w-4" />,
  className: 'text-negative-01',
});

const moveUpButton = (label: string, func: VoidFunc) => ({
  label: label,
  onClick: func,
  left: <ChevronUpIcon className="text-text-01 h-4 w-4" />,
});

const moveDownButton = (label: string, func: VoidFunc) => ({
  label: label,
  onClick: func,
  left: <ChevronDownIcon className="text-text-01 h-4 w-4" />,
});
