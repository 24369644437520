import { DataCy } from '@stellar-lms-frontend/common-utils';

export type Heading3Props = DataCy & {
  children: React.ReactNode;
  className?: string;
};

export const Heading3 = ({ children, className = '', 'data-cy': dataCy }: Heading3Props) => {
  return (
    <h3
      data-cy={dataCy}
      className={`${className} md:font-regular font-lexend text-xl font-medium leading-snug md:text-2xl`}
    >
      {children}
    </h3>
  );
};

export default Heading3;
