import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  ErrorCard,
  HintDescription,
  LoadingCard,
  RotateSparks,
  ToolTip,
} from '@stellar-lms-frontend/ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus, faSparkles } from '@fortawesome/pro-light-svg-icons';
import {
  SuggestedSearchTerms,
  SuggestionGenerationStatus,
} from '@stellar-lms-frontend/lms-graphql';
import { useEffect, useRef, useState } from 'react';

const SUGGESTED_VIDEO_QUERY_KEY = 'SUGGESTED_VIDEO_SEARCH';
export const VideoSearchQuerySuggestion = ({
  currentSearchTerm,
  onAcceptQuerySuggestion,
  getSuggestedVideoSearchTerm,
  startVideoSearchTermGeneration,
}: {
  currentSearchTerm: string;
  onAcceptQuerySuggestion: (suggestion: string) => void;
  startVideoSearchTermGeneration: () => Promise<boolean>;
  getSuggestedVideoSearchTerm: () => Promise<SuggestedSearchTerms>;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'block-step-view.video-ai-sidebar' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const queryClient = useQueryClient();
  const firstOpen = useRef(true);
  const [status, setStatus] = useState<SuggestionGenerationStatus>(SuggestionGenerationStatus.None);
  const [isError, setIsError] = useState(false);

  const {
    isLoading,
    fetchStatus,
    data: suggestedVideoSearchTerm,
  } = useQuery([SUGGESTED_VIDEO_QUERY_KEY], () => getSuggestedVideoSearchTerm(), {
    refetchInterval: 2000,
    onSuccess(data) {
      setStatus(data.status);
    },
    onError(data) {
      setIsError(true);
    },
    enabled:
      status === SuggestionGenerationStatus.InProgress ||
      status === SuggestionGenerationStatus.None,
  });

  const loading =
    (isLoading && fetchStatus !== 'idle') ||
    status === SuggestionGenerationStatus.InProgress ||
    status === SuggestionGenerationStatus.None;

  const generationHasNoResults =
    status === SuggestionGenerationStatus.Done &&
    (suggestedVideoSearchTerm?.suggestions?.length === 0 || !suggestedVideoSearchTerm?.suggestions);

  useEffect(() => {
    if (firstOpen.current) {
      startVideoSearchTermGeneration().then();
      firstOpen.current = false;
    }
  }, [startVideoSearchTermGeneration]);

  // Rendering
  if (isError) {
    return (
      <ErrorCard
        className="mb-8 mt-4"
        hasIcon={false}
      >
        <div className="flex items-center">
          <span className="font-lexend type-small-medium grow">
            {t('suggested-search-query.error.general-error')}
          </span>
          <div
            className="text-text-01 type-body-medium font-lexend flex cursor-pointer items-center gap-2"
            onClick={() => {
              queryClient.invalidateQueries([SUGGESTED_VIDEO_QUERY_KEY]);
              setIsError(false);
            }}
          >
            <RotateSparks className="text-text-01" />
            <span className="type-body-medium font-lexend underline">{tGeneral('retry')}</span>
          </div>
        </div>
      </ErrorCard>
    );
  } else if (loading) {
    return (
      <LoadingCard
        className={'mb-8 mt-4'}
        color="dark-purple"
        title={<span className="text-sm font-medium">{t('suggested-search-query.loading')}</span>}
      />
    );
  } else if (generationHasNoResults) {
    return (
      <HintDescription
        className={'mb-8 mt-4'}
        title={t('suggested-search-query.error.not-enough-content')}
      />
    );
  } else {
    const suggestedTerm = suggestedVideoSearchTerm?.suggestions?.at(0);

    return suggestedTerm ? (
      <HintDescription
        className={'mb-8 mt-4'}
        icon={
          <FontAwesomeIcon
            icon={faSparkles}
            className="text-2xl"
          />
        }
        title={t('suggested-search-query.title')}
        description={
          <Badge
            className="max-w-full text-ellipsis"
            onClick={() => onAcceptQuerySuggestion(suggestedTerm.term)}
          >
            <ToolTip
              color="dark"
              size="small"
              placement="bottom"
              isEnabled={true}
              childrenClassName="w-[400px] text-wrap"
              wrappedComponent={
                <span className="flex items-center space-x-2">
                  {suggestedTerm.term.trim() === currentSearchTerm.trim() ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-sm"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="text-sm"
                    />
                  )}
                  <span className=" overflow-hidden text-ellipsis">{suggestedTerm.term}</span>
                </span>
              }
            >
              {suggestedTerm.term}
            </ToolTip>
          </Badge>
        }
      />
    ) : null;
  }
};
