import { useEffect, useState } from 'react';

export const useWatchScreenResize = (
  callback?: (measurements: { width: number; height: number }) => void
) => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setScreenHeight(entries[0].contentRect.height);
      setScreenWidth(entries[0].contentRect.width);
      callback?.({ width: entries[0].contentRect.width, height: entries[0].contentRect.height });
    });

    resizeObserver.observe(document.body);

    return () => resizeObserver.disconnect();
  }, [callback]);

  return { screenHeight, screenWidth };
};
