import { ExperimentIllustration, Heading3 } from '@stellar-lms-frontend/ui-components';
import { GEARModalExperimentImage } from './images';
import { useTranslation } from 'react-i18next';

export const Experiment: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'gear-template-modal.experiment' });

  return (
    <div className="text-text-02 space-y-6">
      <div className="flex items-center gap-2">
        <ExperimentIllustration />
        <Heading3 className="text-text-04">{t('title')}</Heading3>
      </div>
      <p className="font-semibold">{t('building-blocks')}</p>
      <p>{t('support-encourage')}</p>
      <img
        src={GEARModalExperimentImage}
        alt=""
        className="h-auto w-full"
      />
      <p>{t('live-workshops')}</p>
      <p>{t('structured-exercises')}</p>
      <p>{t('introduce-scenarios')}</p>
      <p>{t('learning-scenario-steps')}</p>
    </div>
  );
};
