import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityItem } from './activity-item';
import {
  DiscussionItem,
  PersistedLearnerWorkBasedAction,
  LearnerWorkBasedActionHistory,
} from '@stellar-lms-frontend/lms-api';

import { Discussion } from '@stellar-lms-frontend/lms-components';
import { useCurrentCompany } from '@stellar-lms-frontend/lms-graphql';
import { graphQLClient } from '../../../lib/graphql';
import {
  createWorkBasedActionDiscussionItem,
  getDiscussionItems,
  likeWorkBasedActionDiscussionItem,
  unlikeWorkBasedActionDiscussionItem,
} from '../work-based-actions.api';

type ActivitySectionProps = {
  wba: PersistedLearnerWorkBasedAction;
};

type Log = {
  changedAt: Date;
} & (
  | ({
      type: 'history';
    } & LearnerWorkBasedActionHistory)
  | ({
      type: 'comment';
    } & DiscussionItem)
);

export const ActivitySection: React.FC<ActivitySectionProps> = ({ wba }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'actions.activity' });

  const {
    query: { data: company },
  } = useCurrentCompany(graphQLClient('learner'));
  const { data: comments, endOfPageRef } = Discussion.useDiscussion(
    (learningActivityId: string, stepId: string, offset: number) =>
      getDiscussionItems(graphQLClient(), learningActivityId, stepId, offset),
    wba.courseId,
    wba.id
  );

  const parsedHistory = useMemo<LearnerWorkBasedActionHistory[]>(() => {
    const firstSubmitted = wba.history?.findIndex((h) => h.state === 'submitted');
    return (
      wba.history?.map((h, index) =>
        firstSubmitted && index > firstSubmitted && h.state === 'submitted'
          ? { ...h, state: 'edited' }
          : h
      ) ?? []
    );
  }, [wba.history]);

  const logsSorted = useMemo(() => {
    const commentsArr: DiscussionItem[] = comments
      ? Array.prototype.concat.apply([], comments.pages)
      : [];

    return [
      ...parsedHistory.map<Log>((h) => ({ ...h, type: 'history' })),
      ...commentsArr.map<Log>((c) => ({ ...c, type: 'comment', changedAt: c.createdAt })),
    ].sort((a, b) => b.changedAt.getTime() - a.changedAt.getTime());
  }, [comments, parsedHistory]);

  return (
    <div className="font-lexend mt-1">
      <div className="mb-6">
        <h3 className="text-text-04 text-lg font-medium">{t('activity')}</h3>
      </div>
      <Discussion.CommentInputComponent
        createComment={(courseId: string, stepId: string, comment: string) =>
          createWorkBasedActionDiscussionItem(graphQLClient('learner'), courseId, stepId, comment)
        }
        learningActivityId={wba.courseId}
        stepId={wba.id}
      />
      <ul
        className="mt-6 space-y-6"
        data-cy="activity-section-list"
      >
        {company &&
          logsSorted.map((item, index) => (
            <li key={index}>
              {item.type === 'comment' && (
                <Discussion.ItemComponent
                  companyId={company.id}
                  key={item.id}
                  item={item}
                  size="small"
                  actions={{
                    like: (learningActivityId: string, stepId: string, commentId: string) =>
                      likeWorkBasedActionDiscussionItem(
                        graphQLClient('learner'),
                        learningActivityId,
                        stepId,
                        commentId
                      ).then(),
                    unlike: (learningActivityId: string, stepId: string, commentId: string) =>
                      unlikeWorkBasedActionDiscussionItem(
                        graphQLClient('learner'),
                        learningActivityId,
                        stepId,
                        commentId
                      ).then(),
                    reply: (
                      learningActivityId: string,
                      stepId: string,
                      reply: string,
                      commentId: string
                    ) =>
                      createWorkBasedActionDiscussionItem(
                        graphQLClient('learner'),
                        learningActivityId,
                        stepId,
                        reply,
                        commentId
                      ).then(),
                  }}
                />
              )}
              {item.type === 'history' && (
                <ActivityItem
                  user={item.user}
                  changedAt={item.changedAt}
                  state={item.state}
                  wba={wba}
                />
              )}
            </li>
          ))}
        <div
          className="h-[1px]"
          ref={endOfPageRef}
        />
      </ul>
    </div>
  );
};
