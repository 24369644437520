import { DragItemProps } from '../list';
import { QuizMultipleChoiceSettingsForm } from './quiz-multiple-choice-settings-form';
import { QuizMultipleChoiceSettingsPreview } from './quiz-multiple-choice-settings-preview';
import { SettingsCard } from '../card';

export type QuizMultipleChoiceFormErrors = {
  text?: {
    message?: string;
  };
  feedback?: {
    message?: string;
  };
};

export type QuizMultipleChoiceAnswerSetting = {
  id?: string;
  text: string;
  correct: boolean;
};

export type QuizMultipleChoiceSettings = {
  text: string;
  feedback: string;
  multiple: boolean;
  spacedRepetitionEnabled: boolean;
  answers: QuizMultipleChoiceAnswerSetting[];
};

export type QuizMultipleChoiceSettingsCardProps = {
  value: QuizMultipleChoiceSettings;
} & (
  | {
      isPreview: true;
      onClick: (value: QuizMultipleChoiceSettings) => void;
      isHighlighted?: boolean;
      onChange?: never;
      isOpen?: never;
      onDelete?: never;
      onToggleOpenState?: never;
      errors?: never;
      dragProps?: never;
    }
  | {
      isPreview?: false;
      onClick?: never;
      isHighlighted?: never;
      onChange: (data: QuizMultipleChoiceSettings) => void;
      isOpen: boolean;
      onDelete: () => void;
      onToggleOpenState?: () => void;
      errors?: QuizMultipleChoiceFormErrors;
      dragProps?: DragItemProps;
    }
);

export const QuizMultipleChoiceSettingsCard: React.FC<QuizMultipleChoiceSettingsCardProps> = ({
  value,
  isOpen,
  onChange,
  onDelete,
  onToggleOpenState,
  dragProps,
  errors,
  isPreview,
  onClick,
  isHighlighted,
}) => {
  return isPreview ? (
    <SettingsCard
      color={'surface-02'}
      onClick={() => onClick(value)}
      isHighlighted={isHighlighted}
      closedChildren={
        <QuizMultipleChoiceSettingsPreview
          isHighlighted={isHighlighted}
          isPreview={isPreview}
          settings={value}
        />
      }
    />
  ) : (
    <SettingsCard
      hasOptionsMenu
      onToggleOpenState={onToggleOpenState}
      onDelete={onDelete}
      isOpen={isOpen}
      dragProps={dragProps}
      openChildren={
        <QuizMultipleChoiceSettingsForm
          value={value}
          onChange={(newValue) => onChange(newValue)}
          errors={errors}
        />
      }
      closedChildren={<QuizMultipleChoiceSettingsPreview settings={value} />}
      hasError={!!errors}
    />
  );
};
