import React from 'react';
import PrimaryButton, { PrimaryButtonProps } from '../primary-button/primary-button';
import SecondaryButton, { SecondaryButtonProps } from '../secondary-button/secondary-button';
import TertiaryButton, { TertiaryButtonProps } from '../tertiary-button/tertiary-button';

export enum BUTTON_TYPES {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export type ButtonSelectorProps =
  | ({ type: BUTTON_TYPES.PRIMARY } & PrimaryButtonProps)
  | ({ type: BUTTON_TYPES.SECONDARY } & SecondaryButtonProps)
  | ({ type: BUTTON_TYPES.TERTIARY } & TertiaryButtonProps);

export const ButtonSelector: React.FC<ButtonSelectorProps> = (props) => {
  switch (props.type) {
    case BUTTON_TYPES.PRIMARY:
      return <PrimaryButton {...props} />;
    case BUTTON_TYPES.SECONDARY:
      return <SecondaryButton {...props} />;
    case BUTTON_TYPES.TERTIARY:
      return <TertiaryButton {...props} />;
  }
};

export default ButtonSelector;
