import React, { ChangeEventHandler, ReactNode } from 'react';
import { InputError } from '../../error/input-error';
import { Label } from '../../label/label';

export interface DropdownProps {
  label?: ReactNode;
  name?: string;
  value?: string;
  htmlId: string;
  enabled?: boolean;
  error?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  onBlur?: ChangeEventHandler<HTMLSelectElement>;
  children: ReactNode;
  size?: 'inline' | 'normal';
  hint?: string;
  required?: boolean;
  labelRightNode?: ReactNode;
  theme?: 'default' | 'dark';
}

export const Dropdown = React.forwardRef(
  (
    {
      label,
      name,
      value,
      htmlId,
      enabled = true,
      error,
      onChange,
      onBlur,
      children,
      size = 'normal',
      hint,
      required,
      labelRightNode,
      theme = 'default',
    }: DropdownProps,
    ref: React.ForwardedRef<HTMLSelectElement>
  ) => {
    return (
      <div
        id={htmlId + '-container'}
        className="font-lexend w-full space-y-2"
      >
        {label && size !== 'inline' && (
          <Label
            label={label}
            htmlId={htmlId}
            required={!!required}
            rightNode={labelRightNode}
            theme={theme}
          />
        )}
        <div
          className={`text-text-01 relative flex w-full items-center ${
            size !== 'inline' ? 'mt-2' : ''
          }`}
        >
          <select
            name={name ?? htmlId}
            id={htmlId}
            ref={ref}
            value={value}
            className={`
            ${error && 'border-negative-01'}
            ${
              size !== 'inline'
                ? 'h-14 rounded-xl px-6 text-base'
                : 'rounded-md py-1 pl-2 pr-8 text-sm'
            }
            font-lexend text-text-01 border-border-02 outline-on-primary-02 hover:border-border-0 focus:outline-on-primary-02 active:border-border-02 active:outline-on-primary-02
            flex w-full flex-row items-center justify-center border py-0 leading-normal outline-offset-2 focus:outline-4 active:outline-4  disabled:from-gray-200 disabled:to-gray-200 disabled:text-gray-400`}
            disabled={!enabled}
            onChange={onChange}
            onBlur={onBlur}
          >
            {children}
          </select>
        </div>
        {hint && <p className="text-text-02 text-sm">{hint}</p>}
        {size !== 'inline' && <InputError error={error} />}
      </div>
    );
  }
);
