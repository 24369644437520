import React from 'react';

export type UserRole = 'learner' | 'mentor' | 'designer';

export type ModuleListContextType = {
  userRole?: UserRole;
};

export const ModuleListContext = React.createContext<ModuleListContextType>({
  userRole: undefined,
});
