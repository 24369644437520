import { CheckIcon, CrossIcon } from '../../../../icons';

export type Type = 'check' | 'cross';
export type Shape = 'circle' | 'rect';
export type Color = 'red' | 'green' | 'grey' | 'blue-gradient' | 'outline-grey' | 'blue';

type StatusIconProps = {
  color: Color;
  shape: Shape;
  type: Type;
};

const colorClasses: Record<Color, string> = {
  red: 'bg-negative-02',
  green: 'bg-positive-02',
  grey: 'bg-text-02',
  'blue-gradient': 'to-primary-gradient-start from-primary-gradient-end bg-gradient-to-r',
  'outline-grey': 'border-text-02 border-[1.5px]',
  blue: 'bg-primary-gradient-end border-[1px] border-primary-02 opacity-60',
};

export const StatusIcon: React.FC<StatusIconProps> = ({ color, shape, type }) => (
  <div
    className={`flex h-[27px] w-[27px] items-center justify-center 
              ${shape === 'circle' ? 'rounded-full' : 'rounded-md'} 
              ${colorClasses[color]}`}
  >
    {type === 'check' ? (
      <CheckIcon
        className={`h-5 w-5 ${color === 'outline-grey' ? 'text-text-02' : 'text-white'}`}
      />
    ) : (
      <CrossIcon
        className={`h-5 w-5 ${color === 'outline-grey' ? 'text-text-02' : 'text-white'}`}
      />
    )}
  </div>
);
