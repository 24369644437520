import {
  MarkTipViewedMutationVariables,
  SuggestionsStatus,
} from '@stellar-lms-frontend/lms-graphql';
import {
  PlusCircleIcon,
  LoadingCard,
  HintDescription,
  StarIcon,
} from '@stellar-lms-frontend/ui-components';
import { useMemo, useCallback, useState, useEffect, useContext } from 'react';
import { useAISuggestionsButton } from '../learning-activity/hooks/use-ai-suggestions-button';
import {
  AISidebarWrapper,
  AISidebarWrapperI18N,
  AISidebarWrapperBaseSuggestionInput,
  AISidebarWrapperBaseSuggestionOutput,
  AISidebarProps,
  RenderAISuggestion,
} from './ai-sidebar-wrapper';
import { usePrev } from '@stellar-lms-frontend/common-utils';
import { LearningActivityContext } from '../learning-activity';

export type AIStepEditI18N = {
  sidebar: AISidebarWrapperI18N;
  suggestionsHint: {
    title: string;
    link: string | ((count: number) => string);
  };
  loading: string;
};

export type AIStepEditProps<
  SInput extends AISidebarWrapperBaseSuggestionInput,
  SOutput extends AISidebarWrapperBaseSuggestionOutput
> = AISidebarProps<SInput, SOutput> & {
  i18n: AIStepEditI18N;
  setIsAISidebarOpen: (isOpen: boolean) => void;
  renderSuggestion: RenderAISuggestion<SInput, SOutput>;
  markTipViewed?: (variables: MarkTipViewedMutationVariables) => void;
  aiButtonLabel: string | ((count: number) => string);
};

export const AIStepEdit = <
  SInput extends AISidebarWrapperBaseSuggestionInput,
  SOutput extends AISidebarWrapperBaseSuggestionOutput
>({
  i18n,
  setIsAISidebarOpen,
  suggestions,
  suggestionsStatus,
  isOpen,
  onClose,
  fetchMoreSuggestions,
  hasMoreSuggestions,
  onAdd,
  startSuggestionsGeneration,
  renderSuggestion,
  markTipViewed,
  hasConfirmOverwriteModal,
  forceReGenerate = false,
  aiButtonLabel,
}: AIStepEditProps<SInput, SOutput>) => {
  const prevIsOpen = usePrev(isOpen);
  const [isSuggestionsHintDescriptionVisible, setIsSuggestionsHintDescriptionVisible] =
    useState(true);

  useEffect(() => {
    if (isSuggestionsHintDescriptionVisible && prevIsOpen === false && isOpen === true) {
      setIsSuggestionsHintDescriptionVisible(false);
    }
  }, [isOpen, isSuggestionsHintDescriptionVisible, prevIsOpen, suggestions.length]);

  const AIButtonOptions = useMemo(
    () => [
      {
        label:
          typeof aiButtonLabel === 'function' ? aiButtonLabel(suggestions.length) : aiButtonLabel,
        left: <PlusCircleIcon className="text-primary-02 h-6 w-6" />,
        onClick: () => {
          setIsAISidebarOpen(true);
        },
      },
    ],
    [aiButtonLabel, setIsAISidebarOpen, suggestions.length]
  );
  // CLEANUP?: Why is this in a context? Can't we just get this from useCurrentUser
  const { user } = useContext(LearningActivityContext);

  const onClickNewFeatureTip = useCallback(() => {
    if (user?.id) {
      user?.id &&
        markTipViewed?.({
          userId: user.id,
          tip: 'ai-suggestions-button-tip',
        });
    }
  }, [markTipViewed, user?.id]);

  useAISuggestionsButton({
    options: AIButtonOptions,
    isLocked: false,
    hasAlert: suggestions.length > 0,
    wasViewed: user?.viewedTips.includes('ai-suggestions-button-tip'),
    onClickNewFeatureTip,
  });

  const startSuggestionsGenerationHandler = useCallback(async () => {
    setIsSuggestionsHintDescriptionVisible(true);
    return startSuggestionsGeneration();
  }, [startSuggestionsGeneration]);

  const fetchMoreSuggestionsHandler = useCallback(() => {
    fetchMoreSuggestions();
    setIsSuggestionsHintDescriptionVisible(true);
  }, [fetchMoreSuggestions]);

  return (
    <>
      {suggestionsStatus === SuggestionsStatus.IN_PROGRESS && (
        <LoadingCard
          color="dark-purple"
          title={<span className="text-sm font-medium">{i18n.loading}</span>}
        />
      )}
      {suggestions.length > 0 && isSuggestionsHintDescriptionVisible && (
        <HintDescription
          type="center"
          icon={<StarIcon className="text-informative-01 h-8 w-8" />}
          title={i18n.suggestionsHint.title}
          description={
            <button
              className="cursor-pointer text-left text-sm font-medium underline"
              onClick={() => setIsAISidebarOpen(true)}
            >
              {typeof i18n.suggestionsHint.link === 'function'
                ? i18n.suggestionsHint.link(suggestions.length)
                : i18n.suggestionsHint.link}
            </button>
          }
          hasCloseButton
        />
      )}
      <AISidebarWrapper
        isOpen={isOpen}
        onClose={onClose}
        i18n={i18n.sidebar}
        suggestions={suggestions}
        suggestionsStatus={suggestionsStatus}
        fetchMoreSuggestions={fetchMoreSuggestionsHandler}
        onAdd={onAdd}
        startSuggestionsGeneration={startSuggestionsGenerationHandler}
        renderSuggestion={renderSuggestion}
        hasConfirmOverwriteModal={hasConfirmOverwriteModal}
        forceReGenerate={forceReGenerate}
        hasMoreSuggestions={hasMoreSuggestions}
      />
    </>
  );
};
