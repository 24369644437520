import { useState, useEffect, useRef, useMemo } from 'react';
import {
  Debounced,
  ErrorCard,
  Heading3,
  HintDescription,
  Input,
  InputProps,
  LinkButton,
  LoadingCard,
  LogoUnsplashIcon,
  PrimaryButton,
  Sidebar,
  StarIcon,
} from '@stellar-lms-frontend/ui-components';
import { ImageSuggestionCard } from '../image-suggestion-card/image-suggestion-card';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getChangeEventValue, replaceTagsInText } from '@stellar-lms-frontend/common-utils';
import { TagBadge } from '../tag-badge/tag-badge';
import {
  GetLightCourseInfoQuery,
  InputLanguage,
  SuggestedContentEntitiesQuery,
  mapToInputLanguage,
} from '@stellar-lms-frontend/lms-graphql';
import { BLOCK, SUGGESTED_CONTENT_ENTITIES_TAGS } from '../../../learning-activity';
import { useTranslation } from 'react-i18next';

export type ImageSuggestion = {
  id: string;
  fullURL: string;
  thumbURL: string;
  creator: {
    name: string;
    url?: string;
  };
  provider: {
    name: string;
    url: string;
  };
};

export type FetchSuggestedImagesFunc = (variables: {
  courseId: string;
  tags: string[];
  nrPerPage: number;
  cursor?: string;
  language?: InputLanguage;
}) => Promise<ImageSuggestion[]>;

const tagExists = (tags: string[], tag: string): boolean => {
  return tags.map((s) => s.toLocaleLowerCase()).includes(tag.toLocaleLowerCase());
};

export type ImageAISidebarProps = {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (suggestion: ImageSuggestion) => void;
  startContentEntitiesGeneration: () => Promise<boolean>;
  fetchContentEntitiesSuggestions: () => Promise<SuggestedContentEntitiesQuery>;
  fetchSuggestedImages: FetchSuggestedImagesFunc;
  currentCourse?: GetLightCourseInfoQuery['course'];
  courseId: string;
};

export const ImageAISidebar: React.FC<ImageAISidebarProps> = ({
  isOpen,
  onClose,
  onAdd,
  startContentEntitiesGeneration,
  fetchContentEntitiesSuggestions,
  fetchSuggestedImages,
  currentCourse,
  courseId,
}) => {
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const { t: tBlockStepView } = useTranslation('translation', { keyPrefix: 'block-step-view' });

  const [selectedImage, setSelectedImage] = useState<ImageSuggestion | undefined>(undefined);
  const [search, setSearch] = useState<string[]>([]);
  const [provider, setProvider] = useState<{ name: string; url: string }>();
  const refFirstOpen = useRef(true);
  const queryClient = useQueryClient();
  const [loadingTags, setLoadingTags] = useState(false);
  const [failedLoadingTags, setFailedLoadingTags] = useState(false);

  const { data: suggestedImages } = useQuery(
    [{ search }],
    () =>
      fetchSuggestedImages({
        courseId,
        tags: search,
        nrPerPage: 30,
        language: currentCourse?.language ? mapToInputLanguage(currentCourse.language) : undefined,
      }),
    {
      enabled: search.length > 0,
    }
  );

  const { data: tags } = useQuery(
    [BLOCK, SUGGESTED_CONTENT_ENTITIES_TAGS],
    async () => {
      const queryResult = await fetchContentEntitiesSuggestions();
      if (
        queryResult.suggestedContentEntities.suggestions &&
        queryResult.suggestedContentEntities.suggestions.length > 0
      ) {
        setLoadingTags(false);
        return queryResult.suggestedContentEntities.suggestions.map(
          (suggestion) => suggestion.entity
        );
      }
      return [];
    },
    {
      refetchInterval: 10000,
      enabled: !failedLoadingTags && loadingTags,
    }
  );

  useEffect(() => {
    if (isOpen && refFirstOpen.current) {
      setLoadingTags(true);
      refFirstOpen.current = false;
      startContentEntitiesGeneration()
        .then((success) => {
          if (success) {
            setFailedLoadingTags(false);
          }
        })
        .catch((e) => {
          setFailedLoadingTags(true);
          refFirstOpen.current = true;
        });
    }
  }, [isOpen, startContentEntitiesGeneration]);

  const hasImages = (suggestedImages?.length ?? 0) > 0;

  useEffect(() => {
    const providers = new Map<string, { name: string; url: string }>();
    suggestedImages?.forEach((i) => {
      providers.set(i.provider.name, i.provider);
    });

    setProvider(providers.size > 0 ? providers.values().next().value : undefined);
  }, [suggestedImages]);

  const renderTags = useMemo(() => {
    if (failedLoadingTags) {
      return (
        <ErrorCard
          className={'mt-4 mb-8'}
          title={
            <span className="text-sm font-medium">
              {tBlockStepView('image-ai-sidebar.errors.failed-loading-tags')}
            </span>
          }
        />
      );
    }

    if (loadingTags) {
      return (
        <LoadingCard
          className={'mt-4 mb-8'}
          color="dark-purple"
          title={
            <span className="text-sm font-medium">
              {tBlockStepView('image-ai-sidebar.loading')}
            </span>
          }
        />
      );
    }
    if (tags) {
      return (
        <HintDescription
          className={'mt-4 mb-8'}
          icon={<StarIcon />}
          title={tBlockStepView('image-ai-sidebar.suggested-tags.title')}
          description={
            <ul className="mt-2 flex flex-wrap gap-2">
              {tags.map((t, index) => (
                <li key={index}>
                  <TagBadge
                    onClick={() => {
                      if (!tagExists(search, t)) {
                        setSearch([...search, t]);
                      } else {
                        search.splice(search.indexOf(t), 1);
                        setSearch([...search]);
                      }
                    }}
                    tag={t}
                    isAdded={tagExists(search, t)}
                  />
                </li>
              ))}
            </ul>
          }
        />
      );
    }
    return null;
  }, [failedLoadingTags, loadingTags, search, tBlockStepView, tags]);

  return (
    <Sidebar
      isOpen={isOpen}
      onClose={onClose}
      position="right"
      contentClassName="md:max-w-[600px] md:w-[60%] font-lexend"
      isDisableScrollEnabled={false}
      hasBackdrop={false}
      header={
        <Heading3 className="text-text-01">{tBlockStepView('image-ai-sidebar.title')}</Heading3>
      }
      buttons={{
        hasDivider: true,
        buttons: [
          <PrimaryButton
            label={tGeneral('add')}
            enabled={!!selectedImage}
            onClick={() => {
              selectedImage && onAdd(selectedImage);
              onClose();
              setSelectedImage(undefined);
            }}
          />,
          <LinkButton
            label={tGeneral('cancel')}
            onClick={onClose}
          />,
        ],
      }}
    >
      <Debounced<InputProps>
        element={Input}
        label={tBlockStepView('image-ai-sidebar.search.label')}
        htmlId={'search-tags'}
        text={search.join(', ')}
        onChangeDebounced={(e) => {
          const search = getChangeEventValue(e);
          setSearch(
            search
              .split(',')
              .map((s) => s.trim())
              .filter((s) => s.length > 0)
          );
        }}
        onChange={() => {
          queryClient.cancelQueries([{ search }]);
        }}
      />
      {renderTags}
      <div>
        <ul className="grid grid-cols-3 gap-4">
          {hasImages || search.length === 0
            ? suggestedImages?.map((suggestedImage) => (
                <li key={suggestedImage.id}>
                  <ImageSuggestionCard
                    isSkeleton={false}
                    isSelected={selectedImage?.id === suggestedImage.id}
                    onClick={() => setSelectedImage(suggestedImage)}
                    thumbURL={suggestedImage.thumbURL}
                    author={{
                      name: suggestedImage.creator.name,
                      url: suggestedImage.creator.url,
                    }}
                  />
                </li>
              ))
            : Array(6)
                .fill(0)
                .map((_, index) => (
                  <li key={index}>
                    <ImageSuggestionCard isSkeleton />
                  </li>
                ))}
        </ul>
        {provider && (
          <div className="flex items-center gap-2 py-8">
            <LogoUnsplashIcon className="text-text-01 h-4 w-4" />
            <p className="font-lexend type-body-medium text-text-01">
              {/* TODO: get href, name from API */}
              {replaceTagsInText(
                tBlockStepView('image-ai-sidebar.images-by', { name: provider.name }),
                {
                  a: (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                      href={provider.url}
                    />
                  ),
                }
              )}
            </p>
          </div>
        )}
      </div>
    </Sidebar>
  );
};
