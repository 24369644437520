import { Message } from 'ably';
import { useChannel } from 'ably/react';
import { ReactNode } from 'react';

export const AblyChannelSubscriber = ({
  channelId,
  callback,
  children,
}: {
  channelId: string;
  callback: (message: Message) => void;
  children: ReactNode;
}) => {
  useChannel(channelId, callback);

  return children;
};
