import { Form, SendIcon, TextArea } from '@stellar-lms-frontend/ui-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { DISCUSSIONS_QUERY_KEY } from '../query';
import { useParams } from 'react-router-dom';
import { USE_COURSE_OUTLINE } from '../../../hooks';
import { useTranslation } from 'react-i18next';

type FormsFields = {
  comment: string;
};
type CreateCommentFunc = (
  learningActivityId: string,
  stepId: string,
  comment: string
) => Promise<any>;

export type CommentInputComponentProps = {
  learningActivityId: string | undefined;
  stepId: string | undefined;
  createComment: CreateCommentFunc;
};

type CreateCommentVariables = {
  learningActivityId: string;
  stepId: string;
  comment: string;
};

export const CommentInputComponent: React.FC<CommentInputComponentProps> = ({
  learningActivityId,
  stepId,
  createComment,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'discussion' });
  const { register, handleSubmit, reset } = useForm<FormsFields>();
  const queryClient = useQueryClient();
  const { courseId } = useParams();

  const createCommentMutation = useMutation(
    ({ learningActivityId, stepId, comment }: CreateCommentVariables) =>
      createComment(learningActivityId, stepId, comment),
    {
      onSuccess: () => {
        reset();
        queryClient.invalidateQueries([DISCUSSIONS_QUERY_KEY]);
        queryClient.invalidateQueries([USE_COURSE_OUTLINE, courseId]);
      },
    }
  );

  const onSubmit = async (data: FormsFields) => {
    if (data.comment === '') return;
    if (!learningActivityId || !stepId) return;
    createCommentMutation.mutate({ learningActivityId, stepId, comment: data.comment });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TextArea
        htmlId="add-comment-input"
        {...register('comment')}
        heightClassName="min-h-[100px] max-h-[240px]"
        placeholder={t('write-comment')}
        icon={
          <SendIcon
            className="text-primary-01 cursor-pointer"
            data-cy="add-comment-button"
          />
        }
        iconClick={handleSubmit(onSubmit)}
      />
    </Form>
  );
};
