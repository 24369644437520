export const getOnsophicApi = () => {
  const currentDomainParts = window.location.hostname.split('.');
  if (currentDomainParts.at(0) === 'localhost') {
    return 'http://localhost:4300/api';
  }

  const baseDomain = currentDomainParts.slice(1).join('.');

  // This assumes a consistent domain scheme for all the different environments.
  return 'https://api.' + baseDomain + '/onsophic';
};

export const getGraphQLApi = () => {
  const currentDomainParts = window.location.hostname.split('.');
  if (currentDomainParts.at(0) === 'localhost') {
    return 'http://localhost:4300/graphql';
  }

  const baseDomain = currentDomainParts.slice(1).join('.');

  // This assumes a consistent domain scheme for all the different environments.
  return 'https://api.' + baseDomain + '/gateway/graphql';
};
