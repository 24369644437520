import { useEnvironment } from '../use-environment';

import { useEffect } from 'react';
import { clarity } from './clarity-utils';

export const useSessionRecorder = (
  userId: string | undefined,
  companyId: string | undefined,
  isInternal: boolean
) => {
  const { isProduction, isStaging } = useEnvironment();

  const envId = isProduction ? 'hrfd7y8o87' : isStaging ? 'hrfd03u5pr' : '';

  if (envId) {
    clarity.init(envId);
  }

  useEffect(() => {
    if (userId && clarity.hasClarity() && companyId) {
      clarity.identify(userId);
      clarity.set('companyId', companyId);
      clarity.set('isInternal', isInternal.toString());
    }
  }, [companyId, isInternal, userId]);
};
