import { Table as TanStackTable } from '@tanstack/table-core';
import { Dropdown } from '../form/dropdown/dropdown';
import { DefaultRowDataType } from './flex-table/components/table-rows';

export type PageSizeSelectorI18N = {
  showing: string;
  of: (count: number) => string;
};

export type PageSizeSelectorProps<T extends DefaultRowDataType = DefaultRowDataType> = {
  table: TanStackTable<T>;
  i18n: PageSizeSelectorI18N;
  totalRows?: number;
};

export const PageSizeSelector = <T extends DefaultRowDataType = DefaultRowDataType>({
  table,
  i18n,
  totalRows = 0,
}: PageSizeSelectorProps<T>) => {
  return (
    <div className="text-text-02 font-lexend font-regular flex flex-row items-center space-x-2 text-sm">
      <span>{i18n.showing}</span>
      <Dropdown
        htmlId="select-page-size"
        value={`${table.getState().pagination.pageSize}`}
        onChange={(e) => table.setPageSize(Number(e.target.value))}
        size="inline"
      >
        {[20, 40, 80].map((pageSize) => (
          <option
            key={pageSize}
            value={pageSize}
          >
            {pageSize}
          </option>
        ))}
      </Dropdown>
      <span className="shrink-0">
        {i18n.of(
          totalRows > table.getCoreRowModel().rows.length
            ? totalRows
            : table.getCoreRowModel().rows.length
        )}
      </span>
    </div>
  );
};
