import { create } from 'zustand';
import { ReactNode } from 'react';
import { Actions, State } from './types';

export const useStore = create<State & Actions>((set, get) => ({
  elements: new Map(),
  setElement: (id: string, newElement: ReactNode) =>
    set((state) => {
      const newElements = new Map(state.elements);
      newElements.set(id, newElement);
      return { elements: newElements };
    }),
  removeElement: (id: string) =>
    set((state) => {
      const newElements = new Map(state.elements);
      newElements.delete(id);
      return { elements: newElements };
    }),
  addElement: (id: string, newElement: ReactNode, position: number) =>
    set((state) => {
      const elementsArray = Array.from(state.elements.entries());
      const newElementsArray = elementsArray.filter(([key]) => key !== id);
      newElementsArray.splice(position, 0, [id, newElement]);
      return { elements: new Map(newElementsArray) };
    }),
  getElementsAsArray: () => {
    const state = get();
    return Array.from(state.elements.values());
  },
  clearElements: () => set({ elements: new Map() }),
}));
