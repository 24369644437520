import {
  Course,
  graphql,
  MutationUpdateCourseArgs,
  QueryCourseArgs,
} from '@stellar-lms-frontend/lms-graphql';
import { GraphQLClient } from 'graphql-request';

// Local query key for a local query, none of these should be exposed outside of this page.
export const QUERY_KEY = 'DesignerCourseDetail';


export const autocompleteSkills = (
    client: GraphQLClient,
    companyId: string,
    query: string
) => {
  return client
  .request(
      graphql(`
        query AutocompleteSkills($companyId: ID!, $query: String!) {
          autocompleteSkills(companyId: $companyId, query: $query)
        }
      `),
      {
        companyId,
        query
      }
  )
  .then((res) => {
    return res.autocompleteSkills
  });
};

export const getDesignerCourseDetails = (
  client: GraphQLClient,
  variables: QueryCourseArgs,
  signal?: AbortSignal
) => {
  return client
    .request({
      document: graphql(`
        query DesignerCourseDetail($id: ID!) {
          course(id: $id) {
            id
            title
            description
            skills
            language
            objectives {
              id
              name
            }
            transferObjectives {
              id
              name
              description
            }
          }
        }
      `),
      variables,
      signal,
    })
    .then((res) => {
      return res.course as Course;
    });
};

export const updateCourse = (client: GraphQLClient, variables: MutationUpdateCourseArgs) => {
  return client.request(
    graphql(`
      mutation UpdateCourseMutation($course: CourseUpdateInput!) {
        updateCourse(course: $course) {
          id
        }
      }
    `),
    variables
  );
};
