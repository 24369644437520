import {
  BadgeAI,
  DragItemProps,
  SettingsCard,
  SkeletonCard,
} from '@stellar-lms-frontend/ui-components';
import { LearningActivityListI18N } from '../../learning-activity-list';
import { DescriptionSection } from '../description-section';
import { StepsSection } from '../steps-section';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { LearningActivityType, getLearningActivityTypeIcon } from '../../../utils/learningActivity';
import { convertToPlaceholderTitle } from '../../functions';

type EditProps = {
  i18n: LearningActivityListI18N;
  onOpenClick?: () => void;
  onDelete?: () => void;
  onDuplicate?: () => void;
  dragProps?: DragItemProps;
  moveUp?: () => void;
  moveDown?: () => void;
  learningActivity: {
    steps: { id: string; title: string }[];
    id: string;
    type?: LearningActivityType;
    aiGenerated: boolean;
    title: string;
    description: string;
    durationInSeconds: number;
    aiGenerationDone: boolean;
    placeholder: boolean;
  };
};

export const Edit: React.FC<EditProps> = ({
  i18n,
  onOpenClick,
  onDelete,
  onDuplicate,
  dragProps,
  moveDown,
  moveUp,
  learningActivity,
}) => {
  const navigate = useNavigate();
  const onStepClick = useCallback(
    (stepId: string) =>
      learningActivity &&
      navigate(`./learning-activity/${learningActivity.id}/step/${stepId}`, {
        state: { isEditing: true },
      }),
    [learningActivity, navigate]
  );

  const Icon = getLearningActivityTypeIcon(learningActivity?.type);
  const deleteLearningActivity = learningActivity?.placeholder ? undefined : onDelete;
  const duplicateLearningActivity = learningActivity?.placeholder ? undefined : onDuplicate;
  const isAIGenerating = learningActivity?.aiGenerated && !learningActivity?.aiGenerationDone;

  return isAIGenerating ? (
    <SkeletonCard
      className="my-3 w-full"
      barConfigs={[
        { width: '35%', height: '12px' },
        { width: '60%', height: '8px' },
        { width: '15%', height: '6px' },
      ]}
    />
  ) : (
    <SettingsCard
      hasOptionsMenu
      onToggleOpenState={onOpenClick}
      isOpen={false}
      color={learningActivity?.placeholder ? 'purple' : 'default'}
      onDelete={deleteLearningActivity}
      onDuplicate={duplicateLearningActivity}
      dragProps={dragProps}
      moveDown={moveDown}
      moveUp={moveUp}
      openChildren={null}
      closedChildren={
        learningActivity?.placeholder ? (
          <AIPlaceholderContent
            title={learningActivity.title}
            aiBadgeLabel={i18n.aiBadgeLabel}
          />
        ) : (
          <div className="flex h-full w-full items-start gap-2">
            <Icon className="text-primary-02 h-10 w-10 shrink-0" />
            <div>
              <p className="break-words font-semibold">{learningActivity.title}</p>
              <DescriptionSection
                i18n={i18n.descriptionSection}
                duration={learningActivity.durationInSeconds}
                description={learningActivity.description}
                durationClassName={'font-medium text-primary-02'}
                isLocked={false}
                lockedText={''}
              />
              {learningActivity && learningActivity.steps.length > 0 && (
                <StepsSection
                  className="mt-4"
                  steps={learningActivity.steps}
                  onClick={onStepClick}
                />
              )}
            </div>
          </div>
        )
      }
    />
  );
};

const AIPlaceholderContent = ({ title, aiBadgeLabel }: { title: string; aiBadgeLabel: string }) => (
  <div className="flex h-full w-full">
    <p className="grow break-words font-semibold">{convertToPlaceholderTitle(title)}</p>
    <BadgeAI
      color="dark-purple"
      className="shrink-0"
      label={aiBadgeLabel}
    />
  </div>
);

export default Edit;
