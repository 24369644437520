import { LearningActivityStep } from '@stellar-lms-frontend/lms-api';
import {
  CreateContentStepMutation,
  CreateContentStepMutationVariables,
  UpdateContentStepMutation,
  UpdateContentStepMutationVariables,
} from '@stellar-lms-frontend/lms-graphql';
import { useParams } from 'react-router-dom';
import IFrameStepEdit from './iframe-step-edit';
import IFrameStepView, { IFrameStepViewProps } from './iframe-step-view';
import { useCallback } from 'react';
import { STEP_URI_CREATE_CONST } from '../learning-activity';
import { xxHash } from '@stellar-lms-frontend/common-utils';

export type IFrameStepProps = {
  companyId: string;
  isEditing?: boolean;
  step?: LearningActivityStep;
  discussionAllowed: boolean;
  actions?: IFrameActions;
};

export type IFrameActions = {
  iframe?: {
    create?: (variables: CreateContentStepMutationVariables) => Promise<CreateContentStepMutation>;
    update?: (variables: UpdateContentStepMutationVariables) => Promise<UpdateContentStepMutation>;
  };
} & IFrameStepViewProps['actions'];

export const IFrameStep: React.FC<IFrameStepProps> = ({
  companyId,
  isEditing,
  step,
  discussionAllowed,
  actions,
}) => {
  const { learningActivityId, stepId } = useParams();

  const onSave = useCallback(
    async (data: { title: string; url: string }) => {
      if (!learningActivityId) return;

      if (stepId && stepId !== STEP_URI_CREATE_CONST) {
        await actions?.iframe?.update?.({
          learningActivityId,
          stepId,
          input: { title: data.title, url: data.url },
        });
      } else {
        await actions?.iframe?.create?.({
          learningActivityId,
          input: { title: data.title, url: data.url },
        });
      }
    },
    [actions?.iframe, learningActivityId, stepId]
  );

  if (!learningActivityId) {
    return null;
  }

  if (isEditing) {
    return (
      <IFrameStepEdit
        key={`${stepId}-${xxHash(JSON.stringify(step))}`}
        step={step}
        save={onSave}
      />
    );
  } else if (step) {
    return (
      <IFrameStepView
        companyId={companyId}
        step={step}
        discussionAllowed={discussionAllowed}
        actions={actions}
      />
    );
  } else {
    return null;
  }
};
