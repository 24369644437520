import { ScenarioLevelInput, ScenarioQuestionType } from '@stellar-lms-frontend/lms-graphql';
import { LevelForm } from './components/scenario-level-edit/scenario-level-edit';

export const transformScenarioToSubmit = (levels: LevelForm[]): ScenarioLevelInput[] =>
  levels.map((level) => ({
    id: level.id ?? undefined,
    text: level.context,
    type: ScenarioQuestionType.Choice,
    spacedRepetitionEnabled: level.spacedRepetitionEnabled,
    choice: {
      multiple: false,
      answers: level.answers.map((answer) => ({
        correct: answer.correct,
        feedback: answer.feedback,
        id: answer.id ?? undefined,
        text: answer.text,
      })),
    },
  }));
