import { useLogout } from '@stellar-lms-frontend/lms-api';
import {
  LogoLoader,
  SortAndFilterOptions,
  filterData,
  sortData,
  useListFilteringOptions,
} from '@stellar-lms-frontend/ui-components';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { SPACED_REPETITION_QUESTIONS } from '../../constants/query';
import { apiUrl } from '../../context';
import { HomePage, Notifications, cleanUpHubspotChat } from '@stellar-lms-frontend/lms-components';
import { graphQLClient } from '../../lib/graphql';
import { markOnboardingCompleted } from './home-page.api';
import {
  useCourseDashboardList,
  useCourseMutations,
  useCurrentCompany,
  useCurrentUser,
  useMarkTipViewed,
  Types,
} from '@stellar-lms-frontend/lms-graphql';
import {
  getAllNotifications,
  markNotificationAsRead,
  unreadNotificationCount,
} from '../notifications/notifications.api';
import { getSpacedRepetitionQuestions } from '../spaced-repetition/spaced-repetition.api';
import {
  DEFAULT_LANGUAGE,
  transformIncomingLanguageCode,
  translatedLanguages,
} from '../../constants/languages';
import { toast } from 'react-toastify';
import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';
import { useMemo } from 'react';
import Fuse from 'fuse.js';

export const Home = () => {
  // TODO put key back in uishell to make sure it rerenders on page change
  const { key } = useLocation();

  const { t: tDeleteJourney } = useTranslation('translation', { keyPrefix: 'delete-journey' });
  const { t: tDuplicateJourney } = useTranslation('translation', {
    keyPrefix: 'duplicate-journey',
  });
  const { t: tNotifications } = useTranslation('translation', { keyPrefix: 'notifications' });
  const { t: tLanguages } = useTranslation('translation', { keyPrefix: 'languages' });
  const { t: tRoles } = useTranslation('translation', { keyPrefix: 'roles' });
  const { t: tCourseFilters } = useTranslation('translation', { keyPrefix: 'home.course-filters' });

  const navigate = useNavigate();

  const {
    query: { data: company },
  } = useCurrentCompany(defaultGraphqlClient);

  const srqQuery = useQuery([SPACED_REPETITION_QUESTIONS], () =>
    company ? getSpacedRepetitionQuestions(graphQLClient('learner'), company.id) : []
  );

  const {
    query: { data: currentUser },
    setCompletedOnboarding,
  } = useCurrentUser(graphQLClient());

  const completeOnboardingMutation = useMutation(
    (userId: string) => markOnboardingCompleted(graphQLClient('learner'), { userId }),
    {
      onMutate: async () => {
        setCompletedOnboarding(true);
      },
      onError: (err, newUser, context) => {
        setCompletedOnboarding(false);
      },
    }
  );

  // candidate to move to a hook to clean up this component
  const sortAndFilterOptions = useMemo(() => {
    const options: SortAndFilterOptions<Types.CourseDashboardListItem> = {
      sortingOptions: [
        {
          label: tCourseFilters('sort.newest'),
          fieldName: 'createdAt',
          sortDirection: 'desc',
        },
        {
          label: tCourseFilters('sort.oldest'),
          fieldName: 'createdAt',
          sortDirection: 'asc',
        },
        {
          label: tCourseFilters('sort.name'),
          fieldName: 'title',
          sortDirection: 'asc',
        },
      ],
      filterOptions: [
        {
          type: 'dropdown',
          label: tCourseFilters('filter.role.label'),
          hint: tCourseFilters('filter.role.placeholder'),
          fieldName: '__type',
          options: [
            { value: 'LearnerCourse', label: tRoles('learner') },
            { value: 'DesignerCourse', label: tRoles('designer') },
            { value: 'MentorCourse', label: tRoles('mentor') },
          ],
        },
        // TODO author needs to be included in query
        // {
        //   type: 'input-autocomplete',
        //   label: 'Author',
        //   hint: 'Enter author name',
        //   fieldName: '',
        // },
        {
          type: 'dropdown',
          label: tCourseFilters('filter.language.label'),
          hint: tCourseFilters('filter.language.placeholder'),
          fieldName: 'language',
          fieldTransformer: transformIncomingLanguageCode,
          options: translatedLanguages(tLanguages).map((language) => ({
            value: language.value,
            label: language.label,
          })),
        },
      ],
    };
    return options;
  }, [tCourseFilters, tLanguages, tRoles]);

  const courseListFilterOptions =
    useListFilteringOptions<Types.CourseDashboardListItem>(sortAndFilterOptions);
  const { searchQuery, selectedSortOption, selectedFilterOptions } = courseListFilterOptions.state;

  const markTipViewedMutation = useMarkTipViewed(graphQLClient());

  const { query: courseListQuery, invalidateCache: invalidateCourseCache } = useCourseDashboardList(
    graphQLClient,
    company?.id,
    currentUser?.id
  );

  const filteredCourses = useMemo(() => {
    let result = courseListQuery.data ?? [];

    // search
    if (courseListQuery.data && searchQuery.length > 0) {
      const fuse = new Fuse(courseListQuery.data, {
        keys: ['title'],
        ignoreLocation: false,
        distance: 100,
        threshold: 0.2,
        minMatchCharLength: Math.min(searchQuery.length, 3),
      });
      result = fuse.search(searchQuery).map((result) => result.item);
    }

    // filter
    if (selectedFilterOptions.length > 0) {
      result = result.filter((course) => {
        return selectedFilterOptions.every((filter) => filterData(course, filter));
      });
    }

    // sort
    if (selectedSortOption) {
      result.sort((courseA, courseB) => sortData(courseA, courseB, selectedSortOption));
    }

    return result;
  }, [courseListQuery.data, searchQuery, selectedFilterOptions, selectedSortOption]);

  const fetchUnreadNotification = useQuery(
    ['UNREAD_NOTIFICATIONS'], // TODO query key
    () => unreadNotificationCount(graphQLClient('learner')),
    {
      refetchInterval: 60000,
    }
  );

  const logout = useLogout(apiUrl, cleanUpHubspotChat);

  const {
    deleteCourseMutation,
    duplicateCourseMutation,
    getScormPackage,
    createScormPackageMutation,
  } = useCourseMutations(
    {
      successDuplicate: tDuplicateJourney('success'),
      successDelete: tDeleteJourney('success'),
    },
    graphQLClient,
    invalidateCourseCache
  );

  if (!courseListQuery.isSuccess || !courseListQuery.data || !currentUser) {
    return <LogoLoader />;
  }

  // TODO pass the blueUIShell to the homepage component in some way to avoid a bunch of extra props/translations
  return (
    <HomePage
      graphQLClient={graphQLClient()}
      currentUser={currentUser ?? undefined}
      currentCompany={company ?? undefined}
      courseList={{ list: filteredCourses, filterOptions: courseListFilterOptions }}
      spacedRepetitionQuestions={srqQuery.data}
      key={key}
      supportedLanguages={translatedLanguages(tLanguages)}
      defaultLanguage={company?.language ?? DEFAULT_LANGUAGE.toLowerCase()}
      actions={{
        logout,
        duplicateCourse: (courseId) => duplicateCourseMutation.mutate({ courseId }),
        deleteCourse: (courseId) => deleteCourseMutation.mutate(courseId),
        createScormPackage: async (courseId, version) =>
          (await createScormPackageMutation.mutateAsync({ courseId, version })).createScormPackage,
        getScormPackage: async (courseId: string, id: string) =>
          (await getScormPackage(courseId, id)).scormPackage,
        fetchNotifications: getAllNotifications,
        handleNotificationClick: async (notification) => {
          await markNotificationAsRead(notification.id);
          if (Notifications.allowedNotificationTypes.includes(notification.type)) {
            navigate(notification.link);
          } else {
            toast.info(tNotifications('not-supported'));
          }
        },
        unreadNotificationsCount: () => fetchUnreadNotification.data?.unread ?? 0,
        completeOnboarding: () =>
          currentUser ? completeOnboardingMutation.mutate(currentUser.id) : null,
        markTipAsViewed: (id: string) =>
          markTipViewedMutation.mutate({ userId: currentUser.id, tip: id }),
      }}
    />
  );
};
