import { BOX_COLORS } from '../dashboard-stats-box';
import { LearnerCourseBlock } from './components/learner-course-block';
import { Actions } from './types';
import { DesignerCourseBlock } from './components/designer-course-block';
import { MentorCourseBlock } from './components/mentor-course-block';
import { ReactNode, useState } from 'react';
import { LinkButton, Modal, PrimaryButton } from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { Types } from '@stellar-lms-frontend/lms-graphql';

export type CourseListProps = {
  courseList: Types.CourseDashboardListItem[];
  actions: Actions;
};
export const CourseList: React.FC<CourseListProps> = ({ courseList, actions }) => {
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const { t: tDeleteJourney } = useTranslation('translation', { keyPrefix: 'delete-journey' });

  const [deleteCourseId, setDeleteCourseId] = useState<string>('');

  return (
    <>
      <ul className="3xl:grid-cols-2 relative mt-4 grid grid-cols-1 justify-items-center gap-4 lg:gap-10">
        {courseList.map((course, index) => (
          <li
            className="w-full"
            key={`${course.id}-${course.__type}`}
            data-cy={`course-list-course-${course.id}`}
          >
            {courseBlocks[course.__type](course, index, {
              ...actions,
              onDelete: (courseId) => setDeleteCourseId(courseId),
            })}
          </li>
        ))}
      </ul>
      <Modal
        isOpen={deleteCourseId !== ''}
        title={tDeleteJourney('title')}
        onClose={() => setDeleteCourseId('')}
        buttons={{
          hasShadow: false,
          buttons: [
            <PrimaryButton
              key={1}
              theme="red"
              label={tDeleteJourney('cta')}
              onClick={() => {
                actions.onDelete(deleteCourseId);
                setDeleteCourseId('');
              }}
            />,
            <LinkButton
              key={2}
              buttonStyle="fit"
              onClick={() => setDeleteCourseId('')}
              label={tGeneral('cancel')}
            />,
          ],
        }}
        className="bg-surface-01 h-full md:h-auto md:w-[510px]"
      >
        <p className="text-text-01 type-body">{tDeleteJourney('description')}</p>
      </Modal>
    </>
  );
};

// A way to render different components depending on the __type of the course
const courseBlocks: Record<
  Types.CourseTypeNames,
  (course: Types.Course, index: number, actions: Actions) => ReactNode
> = {
  LearnerCourse: (course: Types.Course, index: number) => (
    <LearnerCourseBlock
      course={course as Types.LearnerCourse}
      color={BOX_COLORS[index % BOX_COLORS.length]}
    />
  ),
  MentorCourse: (course: Types.Course, index: number) => (
    <MentorCourseBlock
      course={course as Types.MentorCourse}
      color={BOX_COLORS[index % BOX_COLORS.length]}
    />
  ),
  DesignerCourse: (course: Types.Course, index: number, actions: Actions) => (
    <DesignerCourseBlock
      course={course as Types.DesignerCourse}
      actions={actions}
      color={BOX_COLORS[index % BOX_COLORS.length]}
    />
  ),
};
