import { RequestError, RequestStatus } from '@stellar-lms-frontend/common-utils';
import {
  CourseOutline,
  graphql,
  GraphQLErrorBuilder,
  QueryCourseArgs,
} from '@stellar-lms-frontend/lms-graphql';
import { graphQLClient } from '../../lib/graphql';

export const getLearnerJourneyOutline = (variables: QueryCourseArgs) => {
  return graphQLClient('learner')
    .request(
      graphql(`
        query learnerJourneyPage($id: ID!) {
          course(id: $id) {
            id
            outline {
              title
              modules {
                id
                title
                description
                learningActivities {
                  id
                  title
                  steps {
                    id
                    title
                    duration
                  }
                  dateOpen
                  locked
                  progress
                  duration
                  description
                  commentCount
                  type
                  prerequisites {
                    mandatory
                    module {
                      title
                    }
                  }
                }
              }
            }
          }
        }
      `),
      variables
    )
    .then((response) => {
      return response.course?.outline as CourseOutline;
    })
    .catch((error) => {
      if (error.response.errors?.[0]) {
        throw GraphQLErrorBuilder(error);
      }
      throw new RequestError(RequestStatus.UNKNOWN, error);
    });
};
