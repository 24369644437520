import { User } from '@stellar-lms-frontend/lms-api';
import { CompanyRole } from '@stellar-lms-frontend/lms-graphql';

export const allowedHubspotRoles: CompanyRole[] = Object.values(CompanyRole);

export const buildHubspotSettings = (
  user: User,
  company: { title: string; id: string },
  isProduction: boolean
) => {
  return {
    app_id: isProduction ? 'production' : 'non-production',
    name: user.firstName,
    email: user.email,
    user_id: user.id,
    company: company.title,
    company_id: company.id,
    role: user.role,
  };
};
