import { Question, SurveyAnswerMap, TypeMap } from '@stellar-lms-frontend/lms-api';
import {AssessmentQuestionUserAnswer, SurveyQuestionUserAnswer} from "@stellar-lms-frontend/lms-graphql";

export const getAllQuestions = (
  questions: Question[]
): {
  [key: string]: { id: string; label: string; userInput: string; other: boolean };
} =>
  questions.reduce((acc, currQuestion) => {
    const answers = currQuestion.answers
      .filter((a) => a.userAnswered || currQuestion.type === 'fill_in_the_blanks')
      .map((a) => {
        return {
          id: a.id,
          label: a.userInput ?? '',
          userInput: a.userInput ?? '',
          other: a.other,
        };
      });

    return currQuestion.answered
      ? {
          ...acc,
          [currQuestion.id]: currQuestion.type.match(
            /^(multiple_choice_single_selection|free_text_single|free_text_multiple|scale)$/
          )
            ? answers[0]
            : answers,
        }
      : {
          ...acc,
          // Important to add the unanswered questions here for when we need to set
          // form default values, we need to have all 'input' keys in the defaultValues of the form.
          [currQuestion.id]: undefined,
        };
  }, {});

type Answer = { id: string; userInput: string; [key: string]: string };

export const toSubmissionDTO = (data: {
  [key: string]: Answer | Answer[];
}): TypeMap<SurveyAnswerMap> => {
  return Object.keys(data).reduce((acc, questionId) => {
    const answers = data[questionId];

    if (Array.isArray(answers)) {
      if (answers.filter((answer) => answer?.userInput).length === 0) {
        return { ...acc };
      }
    } else if (!answers || !answers?.userInput) {
      return { ...acc };
    }

    return {
      ...acc,
      [questionId]: Array.isArray(answers)
        ? answers.reduce((acc, answer) => {
            return {
              ...acc,
              [answer?.id]: { userInput: answer?.userInput || '' },
            };
          }, {})
        : { [answers?.id]: { userInput: answers?.userInput || '' } },
    };
  }, {});
};

export const toSurveyQuestionUserAnswers = (data: {
    [key: string]: Answer | Answer[];
}): SurveyQuestionUserAnswer[] => {
    const answers:SurveyQuestionUserAnswer[] = [];

    for (const questionId in data) {
        const dataAnswers = data[questionId];

        if (dataAnswers) {
            if (Array.isArray(dataAnswers)) {
                for (const answer of dataAnswers) {
                    if (answer.id && answer.userInput) {
                        answers.push({
                            questionId,
                            answerId: answer.id,
                            userInput: answer.userInput
                        });
                    }
                }
            } else if (dataAnswers.id && dataAnswers.userInput) {
                // we don't have an array
                answers.push({
                    questionId,
                    answerId: dataAnswers.id,
                    userInput: dataAnswers.userInput
                });

            }
        }
    }

    return answers;
};


export const typemapToSurveyQuestionUserAnswers = (data: TypeMap<SurveyAnswerMap>): SurveyQuestionUserAnswer[] => {
    const answers:SurveyQuestionUserAnswer[] = [];

    for (const questionId in data) {
        const dataAnswers = data[questionId];

        for (const answerId in dataAnswers) {
            answers.push({
                questionId,
                answerId,
                userInput: dataAnswers[answerId].userInput
            });
        }
    }

    return answers;
};

export const typemapToAssessmentQuestionUserAnswers = (data: TypeMap<SurveyAnswerMap>): AssessmentQuestionUserAnswer[] => {
    return typemapToSurveyQuestionUserAnswers(data);
};
