import { VoidFunc, useNavigateExternal } from '@stellar-lms-frontend/common-utils';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  CreateScormPackageMutationVariables,
  MutationCreateScormPackageArgs,
  MutationDeleteCourseArgs,
  MutationDuplicateCourseArgs,
  QueryScormPackageArgs,
  graphql,
} from '../graphql';
import { GraphQLClient } from 'graphql-request';
import { useParams } from 'react-router-dom';

type I18N = {
  successDelete: string;
  successDuplicate: string;
};
// FUTURE: CourseService that can be injected with DI to avoid propdrilling of actions?
export const useCourseMutations = (
  i18n: I18N,
  // FUTURE these contexts can go
  graphQLClient: (context: 'mentor' | 'learner' | 'designer') => GraphQLClient,
  invalidateCacheFunc?: VoidFunc
) => {
  const { courseId } = useParams();
  const { navigateExternal } = useNavigateExternal();

  const duplicateCourseMutation = useMutation(
    (vars: MutationDeleteCourseArgs) => duplicateCourse(vars, graphQLClient('designer')),
    {
      onSuccess: (data) => {
        invalidateCacheFunc?.();
        toast.success(i18n.successDuplicate);

        if (data.duplicateCourse) {
          navigateToCourseAbout(data.duplicateCourse);
        }
      },
    }
  );

  const deleteCourseMutation = useMutation(
    (courseId: string) => deleteCourse({ courseId }, graphQLClient('designer')),
    {
      onSuccess: () => {
        invalidateCacheFunc?.();
        toast.success(i18n.successDelete);

        // if on a course page, navigate to home
        if (courseId) {
          navigateExternal('/');
        }
      },
    }
  );

  const createScormPackageMutation = useMutation((vars: CreateScormPackageMutationVariables) =>
    createScormPackage(graphQLClient('designer'), vars)
  );

  // exposing mutations, is it more interesting to expose plain async functions instead?
  return {
    duplicateCourseMutation,
    deleteCourseMutation,
    createScormPackageMutation,
    getScormPackage: async (courseId: string, packageId: string) =>
      getScormPackage(graphQLClient('designer'), { courseId, id: packageId }),
  };
};

// HACKS to deal with our multiple app situation right now
function navigateToCourseAbout(courseId: string) {
  window.location.href = `/designer/course/${courseId}/about`;
}

const deleteCourse = (variables: MutationDeleteCourseArgs, client: GraphQLClient) => {
  return client.request(
    graphql(`
      mutation designerDeleteCourse($courseId: ID!) {
        deleteCourse(courseId: $courseId)
      }
    `),
    variables
  );
};

const duplicateCourse = (variables: MutationDuplicateCourseArgs, client: GraphQLClient) => {
  return client.request(
    graphql(`
      mutation DesignerHomePageDuplicateCourse($courseId: ID!) {
        duplicateCourse(courseId: $courseId)
      }
    `),
    variables
  );
};

const createScormPackage = (client: GraphQLClient, variables: MutationCreateScormPackageArgs) => {
  return client.request(
    graphql(`
      mutation CreateScormPackage($version: ScormVersion!, $courseId: ID!) {
        createScormPackage(version: $version, courseId: $courseId) {
          downloadURL
          error
          id
          status
          version
        }
      }
    `),
    variables
  );
};

const getScormPackage = (client: GraphQLClient, variables: QueryScormPackageArgs) => {
  return client.request(
    graphql(`
      query ScormPackage($courseId: ID!, $id: ID!) {
        scormPackage(courseId: $courseId, id: $id) {
          downloadURL
          error
          id
          status
          version
        }
      }
    `),
    variables
  );
};
