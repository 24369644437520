import { useNavigateExternal } from '@stellar-lms-frontend/common-utils';
import { CompanyStyling } from '@stellar-lms-frontend/lms-graphql';
import { Logo, LogoProps } from '../logo/logo';

type AppLogoProps = LogoProps & {
  companyStyle?: CompanyStyling;
};
export const AppLogo: React.FC<AppLogoProps> = ({ companyStyle, ...logoProps }) => {
  const { navigateExternal } = useNavigateExternal();

  return (
    <Logo
      {...logoProps}
      logoIcon={companyStyle?.logo ?? undefined}
      logoShortIcon={companyStyle?.icon ?? undefined}
      onClick={() => navigateExternal('/')}
    />
  );
};
