import { ContentContainer, ScrollContainer } from '@stellar-lms-frontend/ui-components';
import { CompanyInsightsDashboard } from './company-insights-dashboard';

export const InsightsTab = ({ companyId }: { companyId: string }) => {
  return (
    <ScrollContainer scrollOnDesktop>
      <ContentContainer>
        <CompanyInsightsDashboard companyId={companyId} />
      </ContentContainer>
    </ScrollContainer>
  );
};
