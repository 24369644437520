import { Section, UsersList } from '@stellar-lms-frontend/lms-components';

import { useTranslation } from 'react-i18next';
import { SecondaryButton } from '@stellar-lms-frontend/ui-components';

import { useState } from 'react';
import { ModalInviteMentor } from './modal-invite-mentor';
import { UserInvite } from '@stellar-lms-frontend/lms-graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons';

export type MentorSectionProps = {
  mentors: { id: string; name: string; thumbUri: string }[];
  invites: Maybe<Array<UserInvite>>;
};

export const MentorSection: React.FC<MentorSectionProps> = ({ mentors, invites }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'learner-course-dashboard.mentors' });
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Section
      title={t('title')}
      data-cy={'mentor-list'}
    >
      {mentors.length > 0 ? (
        <UsersList users={mentors} />
      ) : (
        <div className="space-y-4">
          <p className="text-text-02 text-sm">{t('recommendation')}</p>
          <SecondaryButton
            buttonStyle="small"
            leftIcon={
              <FontAwesomeIcon
                icon={faUserPlus}
                className="text-primary-01 h-6 w-6"
              />
            }
            label={t('cta')}
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      )}
      <ModalInviteMentor
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </Section>
  );
};
