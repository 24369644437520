import { useCurrentCompany, useCurrentUser } from '@stellar-lms-frontend/lms-graphql';
import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';
import { Navigate, Outlet } from 'react-router-dom';
import { LogoLoader } from '@stellar-lms-frontend/ui-components';

export const RequireSubscription = () => {
  const {
    query: { data: company, isLoading: isLoadingCompany },
  } = useCurrentCompany(defaultGraphqlClient);

  const {
    query: { data: currentUser, isLoading: isLoadingUser },
  } = useCurrentUser(defaultGraphqlClient);

  if (isLoadingCompany || isLoadingUser) {
    return <LogoLoader />;
  }

  //CLEANUP We should probably think about moving permission names to a single file
  const userHasBypassPermission =
    currentUser?.permissions?.includes('bypass_subscription') ?? false;

  const isSubscriptionActive = !company?.subscription || company.subscription.active;
  if (!isSubscriptionActive && !userHasBypassPermission) {
    return <Navigate to={'/subscription-expired'} />;
  }

  return <Outlet />;
};
