import { useState } from 'react';
import { FilterOptionConfig, FilterValue, SortOption } from './types';
import { resetFilterOptions, updateFilterOptions } from './filter-functions';

export type SortAndFilterOptions<TDataItem> = {
  sortingOptions?: SortOption<TDataItem>[];
  defaultSortOption?: SortOption<TDataItem>;
  filterOptions?: FilterOptionConfig<TDataItem>[];
};

export const useListFilteringOptions = <TDataItem>({
  sortingOptions = [],
  defaultSortOption,
  filterOptions = [],
}: SortAndFilterOptions<TDataItem>) => {
  const [searchQuery, setSearchQuery] = useState('');

  const [selectedSortOption, setSelectedSortOption] = useState<SortOption<TDataItem> | undefined>(
    defaultSortOption
  );

  const [localFilterOptions, setLocalFilterOptions] = useState<
    Map<keyof TDataItem, FilterOptionConfig<TDataItem>>
  >(() =>
    filterOptions.reduce(
      (map, filterOption) => map.set(filterOption.fieldName, filterOption),
      new Map()
    )
  );

  return {
    state: {
      // search
      searchQuery: searchQuery,

      // sort
      selectedSortOption: selectedSortOption ?? sortingOptions.at(0),
      sortOptions: sortingOptions,

      // filter
      filterOptions: filterOptions,
      selectedFilterOptions: [...localFilterOptions.values()].filter(
        (filterOption) => filterOption.hasSelectedValue
      ),
    },
    actions: {
      onSearchQueryChange: (searchQuery: string) => setSearchQuery(searchQuery),
      onSort: (sortOption?: SortOption<TDataItem>) => setSelectedSortOption(sortOption),
      onFilter: (newFilterValues: FilterValue<TDataItem>[]) =>
        setLocalFilterOptions((previousFilterOptions) =>
          updateFilterOptions(previousFilterOptions, newFilterValues)
        ),
      onResetFilters: () =>
        setLocalFilterOptions((previousFilters) => resetFilterOptions(previousFilters)),
    },
  };
};
