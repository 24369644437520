import { PersistedLearnerWorkBasedAction } from '@stellar-lms-frontend/lms-api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionListItem } from './action-list-item';

export type CompletedActionListProps = {
  completedActions: PersistedLearnerWorkBasedAction[];
  isDesktop: boolean;
  actionId?: string;
  showMore: boolean;
  setShowMore: React.Dispatch<React.SetStateAction<boolean>>;
  showUserName?: boolean;
  baseUrl?: string;
};
const MAX_COMPLETED_TO_SHOW = 3;

export const CompletedActionList = ({
  completedActions,
  isDesktop,
  actionId,
  showMore,
  setShowMore,
  showUserName = false,
  baseUrl,
}: CompletedActionListProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'actions' });

  // show latest first
  const sortedCompletedActions = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => completedActions.sort((a, b) => b.completedDate!.getTime() - a.completedDate!.getTime()),
    [completedActions]
  );

  const displayShowMoreButton = completedActions.length > MAX_COMPLETED_TO_SHOW;
  const amountToShow = showMore ? completedActions.length : MAX_COMPLETED_TO_SHOW;

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return completedActions && completedActions.length > 0 ? (
    <div data-cy="completed-actions">
      <div className="font-lexend text-text-04 flex flex-row items-center justify-between text-base font-medium leading-normal">
        <span>{t('recently-completed')}</span>
        <span
          onClick={toggleShowMore}
          className={`${displayShowMoreButton ? 'inline' : 'hidden'} ${
            isDesktop ? 'text-text-01 mr-6 underline' : 'text-primary-01'
          } cursor-pointer`}
        >
          {showMore ? t('show-less') : t('show-all')}
        </span>
      </div>
      <ul className="mt-2">
        {sortedCompletedActions.slice(0, amountToShow).map((a) => (
          <ActionListItem
            key={a.id}
            action={a}
            selectedActionId={actionId}
            isDesktop={isDesktop}
            titleClassName="text-text-03"
            showUserName={showUserName}
            baseUrl={baseUrl}
          />
        ))}
      </ul>
    </div>
  ) : null;
};
