import { ProgressBar } from '@stellar-lms-frontend/ui-components';

export type ProgressCellProps = {
  id: string;
  progress: number;
};

export const ProgressCell: React.FC<ProgressCellProps> = ({ id, progress }) => {
  return (
    <div className="flex items-center">
      <span className="w-10 text-sm font-medium">{progress}%</span>
      <ProgressBar
        className="w-full max-w-[130px]"
        htmlId={`mentee-${id}-progress`}
        progress={[progress]}
        roundedClassName="rounded-2xl"
      />
    </div>
  );
};
