import { GraphQLClient } from 'graphql-request';
import {
  AnsweredOption,
  Evaluation,
  Question,
  SpacedRepetitionOverview,
  SurveyAnswerMap,
  SurveyAnswerOption,
  TypeMap,
} from '@stellar-lms-frontend/lms-api';
import { graphql, SpacedRepetitionQuestionEvaluation } from '@stellar-lms-frontend/lms-graphql';
import { sanitizeHtml } from '@stellar-lms-frontend/common-utils';

export const evaluateSpacedRepetitionQuestion = (
  client: GraphQLClient,
  companyId: string,
  assessmentId: string,
  questionId: string,
  answers: TypeMap<SurveyAnswerMap>
) => {
  const input: SpacedRepetitionQuestionEvaluation = {
    userAnswers: [],
  };

  for (const answerId in answers[questionId]) {
    input.userAnswers.push({
      answerId: answerId,
      userInput: answers[questionId][answerId].userInput,
    });
  }

  return client
    .request(
      graphql(`
        mutation EvaluateSpacedRepetitionQuestion(
          $companyId: ID!
          $assessmentId: ID!
          $questionId: ID!
          $input: SpacedRepetitionQuestionEvaluation!
        ) {
          evaluateSpacedRepetitionQuestion(
            companyId: $companyId
            assessmentId: $assessmentId
            questionId: $questionId
            input: $input
          ) {
            questionId
            evaluation
            feedback
            text
            type
            score
            totalPoints
            earnedPoints
            answered
            remainingRepetitionsForQuestion
            answers {
              answerId
              label
              mediaType
              answerCorrect
              answerText
              userAnswered
              userAnsweredCorrect
              userInput
            }
          }
        }
      `),
      {
        companyId,
        assessmentId,
        questionId,
        input,
      }
    )
    .then((data) => {
      return {
        ...data.evaluateSpacedRepetitionQuestion,
        id: 'random',
        assessmentId: assessmentId,
        questionId: questionId,
        leftLabel: '',
        rightLabel: '',
        inputMaxLength: 0,
        label: '',
        answerOptions: [],
        type: data.evaluateSpacedRepetitionQuestion.type.toLowerCase(),
        answers: data.evaluateSpacedRepetitionQuestion.answers.map((answer) => {
          return {
            ...answer,
            id: 'random',
            text: '',
            other: false,
          } as AnsweredOption;
        }),
        feedback: data.evaluateSpacedRepetitionQuestion.feedback
          ? sanitizeHtml(data.evaluateSpacedRepetitionQuestion.feedback)
          : null,
        text: data.evaluateSpacedRepetitionQuestion.text
          ? sanitizeHtml(data.evaluateSpacedRepetitionQuestion.text)
          : null,
      } as Evaluation;
    });
};

export const getSpacedRepetitionOverview = (
  client: GraphQLClient,
  userId: string,
  companyId: string
): Promise<SpacedRepetitionOverview> => {
  return client
    .request(
      graphql(`
        query SpacedRepetitionOverview($companyId: ID!, $userId: ID!) {
          srOverview(companyId: $companyId, userId: $userId) {
            bucket
            count
          }
        }
      `),
      {
        companyId,
        userId,
      }
    )
    .then((data) => {
      const overview: SpacedRepetitionOverview = {
        bucketsOverview: {},
      };

      for (const bucketOverview of data.srOverview) {
        overview.bucketsOverview[Number(bucketOverview.bucket)] = bucketOverview.count;
      }

      return overview;
    });
};

export const getSpacedRepetitionQuestions = (
  client: GraphQLClient,
  companyId: string
): Promise<Question[]> => {
  return client
    .request(
      graphql(`
        query SpacedRepetitionQuestions($companyId: ID!) {
          spacedRepetitionQuestions(companyId: $companyId) {
            id
            assessmentId
            answerOptions
            answers {
              id
              label
              text
              mediaType
            }
            inputMaxLength
            text
            type
          }
        }
      `),
      {
        companyId,
      }
    )
    .then((data) => {
      return data.spacedRepetitionQuestions.map((srq: any) => {
        return {
          id: srq.id,
          questionId: '',
          assessmentId: srq.assessmentId,
          answerOptions: srq.answerOptions,
          answered: false,
          leftLabel: '',
          rightLabel: '',
          label: '',
          answers: srq.answers.map((a: any) => {
            return {
              id: a.id,
              label: a.text,
              text: a.text,
              mediaType: a.mediaType,
              answerId: '',
              other: false,
            } as SurveyAnswerOption;
          }),
          inputMaxLength: srq.inputMaxLength,
          text: sanitizeHtml(srq.text),
          type: srq.type.toLowerCase(),
        } as Question;
      });
    });
};

export const putQuestionLevelOnProficient = (
  client: GraphQLClient,
  companyId: string,
  questionId: string
): Promise<void> => {
  return client
    .request(
      graphql(`
        mutation MarkSpacedRepetitionQuestionAsProficient($companyId: ID!, $questionId: ID!) {
          markSpacedRepetitionQuestionAsProficient(companyId: $companyId, questionId: $questionId)
        }
      `),
      {
        companyId,
        questionId,
      }
    )
    .then((data) => {
      return;
    });
};
