import { faBuilding, faAngleUp, faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCurrentCompany, useCompanies, createCompany } from '@stellar-lms-frontend/lms-graphql';
import { FloatingBox, Badge, SearchableSelect } from '@stellar-lms-frontend/ui-components';
import { GraphQLClient } from 'graphql-request';
import { useState } from 'react';
import { AddCompanyModal } from '../add-company-modal/add-company-modal';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { SupportedLanguagesOptions } from '../types/types';
import { transformOutgoingLanguageCode } from '../organization-page/components/users-tab/utils/transformers';
import { useTranslation } from 'react-i18next';

export type CompanySelectorProps = {
  client: GraphQLClient;
  color: 'light-blue' | 'dark-blue';
  defaultLanguage: string;
  supportedLanguages: SupportedLanguagesOptions;
};

export const CompanySelector: React.FC<CompanySelectorProps> = ({
  client,
  color,
  defaultLanguage,
  supportedLanguages,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'add-company-modal' });

  const [isSelectingCompany, setIsSelectingCompany] = useState(false);
  const [companySearch, setCompanySearch] = useState<string>('');
  const { query: companyQuery, onSelectCompany } = useCurrentCompany(client);
  const [isCreateCompanyModalOpen, setIsCreateCompanyModalOpen] = useState(false);

  const { query: companiesQuery, isSuperAdmin } = useCompanies(client, {
    search: companySearch,
    max: 10,
  });

  const { mutate: createCompanyMutate } = useMutation(
    (data: {
      name: string;
      industry: string;
      language: string;
      license: string;
      trialEndDate: string;
    }) =>
      createCompany(client, {
        input: {
          name: data.name,
          industry: data.industry,
          language: transformOutgoingLanguageCode(data.language.toLowerCase()),
          trial: data.license === 'trial',
          subscriptionEndDate: data.license === 'trial' ? data.trialEndDate : undefined,
        },
      }),
    {
      onSuccess: (data) => {
        toast.success(t('toast.success'));
        setIsCreateCompanyModalOpen(false);
        setIsSelectingCompany(false);
        data.createCompany?.id && onSelectCompany(data.createCompany.id);
      },
    }
  );

  if (!isSuperAdmin) return null;

  return (
    <>
      <FloatingBox
        onClose={() => {
          setIsSelectingCompany(false);
          setCompanySearch('');
        }}
        placement={'bottom-start'}
        isOpen={isSelectingCompany}
        wrappedComponent={
          <Badge
            onClick={() => setIsSelectingCompany(true)}
            className="flex items-center gap-2"
            color={color}
            size="xl"
          >
            <FontAwesomeIcon
              icon={faBuilding}
              className="h-4 w-4 text-inherit"
            />
            <p className="type-small-medium">{companyQuery.data?.name}</p>
            <FontAwesomeIcon
              icon={isSelectingCompany ? faAngleUp : faAngleDown}
              className="h-4 w-4 text-inherit"
            />
          </Badge>
        }
      >
        <div className="w-[300px] p-2">
          <SearchableSelect
            options={companiesQuery.data?.map((c) => ({
              id: c.id,
              label: c.name ?? '',
            }))}
            onSelect={(s) => {
              if (!s.id) {
                setIsCreateCompanyModalOpen(true);
              } else {
                onSelectCompany(s.id);
                setIsSelectingCompany(false);
              }
            }}
            isOpen
            onChangeSearch={setCompanySearch}
            regularOptionClassName="px-4 text-text-01"
            selectType={'search-query'}
            addOptionClassName="px-4 text-primary-02"
          />
        </div>
      </FloatingBox>
      <AddCompanyModal
        name={companySearch}
        isOpen={isCreateCompanyModalOpen}
        onClose={() => setIsCreateCompanyModalOpen(false)}
        onSubmit={createCompanyMutate}
        defaultLanguage={defaultLanguage}
        supportedLanguages={supportedLanguages}
      />
    </>
  );
};
