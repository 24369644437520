import {
  ApplyIcon,
  ExperimentIcon,
  GuideIcon,
  MiscellaneousIcon,
  RetainIcon,
} from '@stellar-lms-frontend/ui-components';

export enum LearningActivityType {
  Apply = 'APPLY',
  Experiment = 'EXPERIMENT',
  Guide = 'GUIDE',
  Retain = 'RETAIN',
  Unknown = 'UNKNOWN',
}

export const getLearningActivityTypeIcon = (type?: LearningActivityType) => {
  switch (type) {
    case LearningActivityType.Apply:
      return ApplyIcon;
    case LearningActivityType.Experiment:
      return ExperimentIcon;
    case LearningActivityType.Guide:
      return GuideIcon;
    case LearningActivityType.Retain:
      return RetainIcon;
    default:
      return MiscellaneousIcon;
  }
};
