/**
 * All react query constants
 */

// SPECIFIC page queries
export const LEARNER_COURSE_DASHBOARD = 'learner-course-dashboard';
export const MENTOR_COURSE_DASHBOARD = 'mentor-course-dashboard';

// Other
export const COURSES = 'courses';

export const LEARNER_WBA = 'learner-wba';

export const MENTEE_ACTIONS = 'mentee-actions';

export const SURVEY = 'survey';
export const PUBLISHED_SURVEY = 'published-survey';

// We name these FETCH, but it is important to realize that calling useQuery might not always refetch.
// This is dependent on the staleTime configuration of useQuery, if the data was just fetched another call to
//  useQuery will not result to another http call.
export const FETCH_UNREAD_NOTIFICATIONS = 'fetchUnreadNotifications';
export const FETCH_ACTIONS = 'fetchActions'; // Technically these are just WBAs right now, but in the future they might not.

export const COURSE = 'course';
export const SURVEY_RESULT = 'survey-result';

export const STATS = 'stats';

export const SPACED_REPETITION_OVERVIEW = 'getSpacedRepetitionOverview';
export const SPACED_REPETITION_QUESTIONS = 'spaced-repetition-questions';
