import { DataCy } from '@stellar-lms-frontend/common-utils';
import { IconItem } from '../../../list';
import { FloatingOptionsOption } from '../floating-options';

export const OptionItem = ({
  item,
  'data-cy': dataCy,
  index,
  hasDivider,
}: DataCy & {
  item: FloatingOptionsOption;
  index: number;
  hasDivider: boolean;
}) =>
  ({
    option: (
      <IconItem
        key={item.key}
        data-cy={dataCy ? `${dataCy}-${index}` : undefined}
        title={item.label}
        onClick={item.onClick}
        left={item.left}
        subtitleList={
          item.subtitle ? [<span className="text-text-02">{item.subtitle}</span>] : undefined
        }
        className={`
              hover:bg-surface-02 text-text-01 w-full px-6 py-3 text-start
                ${item.className ?? ''}
                ${hasDivider ? 'border-b-1 border-border-02 last:border-none' : ''}`}
      />
    ),

    divider: (
      <div
        key={`divider-${index}`}
        className={`${item.className ?? ''} border-b-1 border-border-02`}
      ></div>
    ),

    title: (
      <p
        key={`title-${item.label}-${index}`}
        className="text-text-02 type-small-medium px-6 py-2"
      >
        {item.label}
      </p>
    ),

    // There is actual use for the fragment. Label is defined as a react node, while a react element is expected as return value...
    // eslint-disable-next-line react/jsx-no-useless-fragment
    custom: <>{item.label}</>,
  }[item.type ?? 'option']);
