import { ArticlePreview, BaseCard, CheckBox } from '@stellar-lms-frontend/ui-components';
import { ChangeEvent, useState } from 'react';
import { Article } from '@stellar-lms-frontend/lms-graphql';

export const ArticleSuggestionCard = ({
  article,
  onSelectArticle,
  onDeselectArticle,
}: {
  article: Article;
  onSelectArticle: (article: Article) => void;
  onDeselectArticle: (article: Article) => void;
}) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <BaseCard
      color="surface-02"
      className="my-2"
      childrenClassName="p-4"
      isHighlighted={isChecked}
      hasShadow={false}
    >
      <div className="mt-[2px] flex items-center gap-4">
        <div className="mt-[2px] self-start">
          <CheckBox
            value={isChecked}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.checked) {
                setIsChecked(true);
                onSelectArticle(article);
              } else {
                setIsChecked(false);
                onDeselectArticle(article);
              }
            }}
          />
        </div>

        <ArticlePreview data={{ ...article, link: article.url }} />
      </div>
    </BaseCard>
  );
};
