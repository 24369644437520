import { faCheck, faHourglass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type StatusType = 'pending' | 'signed-in';

type StatusCellProps = {
  status: StatusType;
};

export const StatusCell: React.FC<StatusCellProps> = ({ status }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organization.members-tab.status-cell',
  });
  const { icon, color, text } = useMemo(() => {
    switch (status) {
      case 'signed-in':
        return {
          icon: faCheck,
          color: '',
          text: t('signed-in'),
        };
      case 'pending':
        return {
          icon: faHourglass,
          color: 'text-warning-01',
          text: t('pending'),
        };
    }
  }, [t, status]);

  return (
    <div className={`${color} flex items-center gap-2 text-sm`}>
      <FontAwesomeIcon
        icon={icon}
        className="h-4 w-4"
      />
      <span className="text-xs">{text}</span>
    </div>
  );
};
