import { getChangeEventValue, useEventListener } from '@stellar-lms-frontend/common-utils';
import { useState, useCallback } from 'react';
import { PlusCircleIcon } from '../../../icons';
import { Debounced, InputProps, Input } from '../../input';
import { IconItem } from '../../../list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';

export type SearchableSelectOptionType = {
  id: string;
  label: string;
};

export type SearchableSelectProps = {
  className?: string;
  options?: SearchableSelectOptionType[];
  onSelect: (selected: SearchableSelectOptionType) => void;
  isOpen: boolean;
  onChangeSearch: (search: string) => void;
  selected?: SearchableSelectOptionType;
  inputClassName?: string;
  placeholder?: string;
  type?: 'select' | 'input';
  autoFocus?: boolean;
  regularOptionClassName?: string;
} & (
  | {
      selectType: 'add';
      addText: string;
      addOptionClassName?: string;
    }
  | {
      selectType: 'search-query';
      addText?: never;
      addOptionClassName?: string;
    }
  | {
      selectType: 'read-only';
      addText?: never;
      addOptionClassName?: never;
    }
);

export const SearchableSelect: React.FC<SearchableSelectProps> = ({
  className = '',
  options = [],
  onSelect,
  isOpen,
  onChangeSearch,
  selected,
  inputClassName = '',
  addOptionClassName = 'text-text-01',
  regularOptionClassName = 'text-primary-01',
  selectType,
  addText,
  placeholder,
  type = 'select',
  autoFocus,
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const onPressEnter = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        !!searchQuery && onSelect({ id: '', label: searchQuery });
      }
    },
    [searchQuery, onSelect]
  );

  useEventListener('keydown', onPressEnter, true);

  if (!isOpen) return null;

  return (
    <div className={`${className} font-lexend h-full w-full`}>
      <div className={inputClassName}>
        <Debounced<InputProps>
          element={Input}
          leftIcon={<FontAwesomeIcon icon={faSearch} />}
          autoFocus={autoFocus}
          htmlId={'input'}
          onChangeDebounced={(e) => {
            onChangeSearch(getChangeEventValue(e));
          }}
          onChange={(e) => {
            setSearchQuery(getChangeEventValue(e));
          }}
          text={selected && type === 'input' ? selected.label : searchQuery}
          placeholder={placeholder}
        />
      </div>
      <ul className="mt-2 flex flex-col items-start">
        {options.map((o) => (
          <IconItem
            key={o.id}
            onClick={() => onSelect(o)}
            className={`hover:bg-surface-02 w-full
              ${selected?.id === o.id && type === 'select' && 'bg-surface-02-hover'}
              ${regularOptionClassName}`}
            title={<p className="font-regular">{o.label}</p>}
            size="small"
          />
        ))}
        {(selectType === 'add' || (selectType === 'search-query' && searchQuery)) && (
          <IconItem
            onClick={() => onSelect({ id: '', label: searchQuery })}
            className={`${addOptionClassName} w-full font-medium`}
            title={selectType === 'search-query' ? searchQuery : addText}
            left={<PlusCircleIcon className="text-primary-01 h-4 w-4" />}
            size="small"
          />
        )}
      </ul>
    </div>
  );
};
