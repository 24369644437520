import { DataCy, VoidFunc } from '@stellar-lms-frontend/common-utils';

export type ItemProps = DataCy & {
  id: string;
  title: string;
  subtitle?: string;
  icon: React.ReactNode;
  color?: string;
  onClick?: VoidFunc;
  className?: string;
};

export const Item = ({
  title,
  subtitle,
  icon,
  color,
  onClick,
  className = '',
  'data-cy': dataCy,
}: ItemProps) => {
  return (
    <button
      data-cy={`more-item-${dataCy}`}
      onClick={onClick}
      className={`border-border-01 font-lexend -mt-[1px] flex h-full justify-between break-all
      border-b py-4 pr-8 ${color ? color : 'text-text-04'}
      ${className}`}
    >
      {subtitle ? (
        <div className="flex flex-col justify-start text-start">
          <p className="font-medium">{title}</p>
          <p className="text-sm">{subtitle}</p>
        </div>
      ) : (
        <p>{title}</p>
      )}
      {icon}
    </button>
  );
};
