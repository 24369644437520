export class LinkInlineToolUtils {
  savedRange?: Range = undefined;
  highlightedSpans: HTMLElement[] = [];

  get selection(): Selection | undefined {
    return window.getSelection() || undefined;
  }

  anchorNode(): Node | undefined {
    return this.selection?.anchorNode ?? undefined;
  }

  getSelectionRange() {
    const sel = this.selection;
    return sel?.rangeCount ? sel.getRangeAt(0) : undefined;
  }

  saveRange() {
    this.savedRange = this.getSelectionRange();
  }

  restore() {
    const selection = this.selection;
    if (this.savedRange && selection) {
      selection.removeAllRanges();
      selection.addRange(this.savedRange);
    }
  }

  clearSavedSelection() {
    this.savedRange = undefined;
  }

  highlightSelection() {
    const selection = this.selection;

    if (!selection || selection.rangeCount === 0) return;

    const range = selection.getRangeAt(0);
    const span = document.createElement('span');
    span.style.backgroundColor = '#a8d6ff';
    range.surroundContents(span);
    this.highlightedSpans.push(span);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  removeHighlightSelection() {
    this.highlightedSpans.forEach((span) => {
      const parent = span.parentNode;
      if (!parent) return;

      while (span.firstChild) {
        parent.insertBefore(span.firstChild, span);
      }
      parent.removeChild(span);
    });

    this.highlightedSpans = [];
  }

  getParentTag(tagName: string, className = '', searchDepth = 10): Element | undefined {
    const selection = this.selection;
    if (!selection?.anchorNode || !selection.focusNode) {
      return undefined;
    }

    const boundNodes: Node[] = [selection.anchorNode, selection.focusNode];

    for (const node of boundNodes) {
      const matchedElement = this.searchForParentElement(node, tagName, searchDepth, className);
      if (matchedElement) {
        return matchedElement;
      }
    }

    return undefined;
  }

  searchForParentElement(
    node: Node,
    tagName: string,
    searchDepth: number,
    className = ''
  ): Element | null {
    let parent: Element | null = node.parentElement;
    let depth = 0;

    while (parent && depth < searchDepth) {
      if (this.isMatchingElement(parent, tagName, className)) {
        return parent;
      }
      parent = parent.parentElement;
      depth++;
    }

    return null;
  }

  isMatchingElement(element: Element, tagName: string, className = ''): boolean {
    if (element.tagName !== tagName) {
      return false;
    }
    if (className && !element.classList.contains(className)) {
      return false;
    }
    return true;
  }

  setRange(target?: Node, shouldCollapse?: boolean) {
    if (!target) return;

    const selection = this.selection;

    const range = document.createRange();
    range.selectNodeContents(target);

    if (shouldCollapse) {
      range.collapse(false);
    }

    selection?.removeAllRanges();
    selection?.addRange(range);
  }

  moveToEnd() {
    const sel = this.selection;
    this.setRange(sel?.focusNode ?? undefined, true);
  }

  selectElement(element: Element) {
    this.setRange(element);
  }

  clean() {
    document.execCommand('unlink');
  }
}
