import {searchUsersLightById, USERS_LIGHT} from '@stellar-lms-frontend/lms-api';
import { useQuery } from '@tanstack/react-query';
import {useCurrentCompany} from "@stellar-lms-frontend/lms-graphql";
import {graphQLClient} from "../../../lib/graphql";

export type ActionUserFilterOptionProps = {
  userId: string;
};
export const ActionsUserFilterOption = ({ userId }: ActionUserFilterOptionProps) => {
  const {
    query: { data: company },
  } = useCurrentCompany(graphQLClient('learner'));

  const { data: user } = useQuery(
    [USERS_LIGHT, userId],
    () => company ? searchUsersLightById(graphQLClient(), company.id, [userId]) : [],
    {
      select: (users) => users.at(0),
    }
  );
  return (
    <option
      id={`${userId}-option`}
      key={userId}
      value={userId}
    >
      {user?.name ?? ''}
    </option>
  );
};
