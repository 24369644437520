import { GraphQLClient } from 'graphql-request';
import { MarkTipViewedMutationVariables, graphql } from '../graphql';
import { useMutation } from '@tanstack/react-query';
import { useCurrentUser } from './use-current-user';

export const useMarkTipViewed = (client: GraphQLClient) => {
  const { invalidateCache: invalidateUserQuery } = useCurrentUser(client);

  const mutation = useMutation(
    (variables: MarkTipViewedMutationVariables) => markTipViewed(client, variables),
    {
      onSuccess: () => {
        invalidateUserQuery();
      },
    }
  );

  return mutation;
};

const markTipViewed = (client: GraphQLClient, variables: MarkTipViewedMutationVariables) => {
  return client.request(
    graphql(
      `
        mutation MarkTipViewed($userId: ID!, $tip: String!) {
          markTipViewed(userId: $userId, tip: $tip)
        }
      `
    ),
    variables
  );
};
