import { ConditionalWrapper } from '@stellar-lms-frontend/ui-components';
import { UIShell } from '../shell/ui-shell';

export type ErrorWrapperType = 'paged' | 'boxed';

export type ErrorWrapperProps = {
  type: ErrorWrapperType;
  pageTitle: string;
  pageSubTitle: string;
  children: JSX.Element;
};

export const ErrorWrapper = ({
  type = 'paged',
  pageTitle,
  pageSubTitle,
  children,
}: ErrorWrapperProps) => {
  return (
    <ConditionalWrapper
      hasWrapper={type === 'paged'}
      wrapper={(content) => (
        <UIShell
          subNavTitle={pageTitle}
          subNavSubtitle={pageSubTitle}
        >
          {content}
        </UIShell>
      )}
    >
      {children}
    </ConditionalWrapper>
  );
};
