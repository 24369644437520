import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { Badge } from '../badge/badge';
import { PlusIcon, CrossIcon } from '../icons';
import { Label } from '../label/label';
import {
  SearchableSelect,
  SearchableSelectOptionType,
} from '../form/select/searchable-select/searchable-select';
import { FontBody, FontSmall } from '../text';
import { FloatingBox, MobileFullScreen } from '../wrappers';

import { useScreenType } from '@stellar-lms-frontend/common-utils';
import { BadgeSkill } from '../badge-skill/badge-skill';

type I18NBadgeAutoComplete = {
  addLabel: string;
  fieldLabel: string;
  selectLabel?: string;
  placeholder?: string;
};

export type AutoCompleteFuncRet = Promise<{
  options: SearchableSelectOptionType[];
  defaultOptions?: SearchableSelectOptionType[];
}>;

export type AutoCompleteFunc = (query: string) => AutoCompleteFuncRet;

export type BadgeAutoCompleteProps = {
  value: SearchableSelectOptionType[];
  onChange: (selected: SearchableSelectOptionType[]) => void;
  maxToShow?: number;
  allowAdditions?: boolean;
  autoCompleteFunc: AutoCompleteFunc;
  i18n: I18NBadgeAutoComplete;
};

export const BadgeAutoComplete: React.FC<BadgeAutoCompleteProps> = ({
  value = [],
  onChange,
  maxToShow = 5,
  allowAdditions = true,
  autoCompleteFunc,
  i18n,
}) => {
  const [isSelectingValue, setIsSelectingValue] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { isTabletOrLarger } = useScreenType();

  const { data: autoCompleteData } = useQuery(
    ['badge-autocomplete-selector', { searchQuery }],
    () => autoCompleteFunc(searchQuery),
    {
      cacheTime: 0,
    }
  );

  const SearchableListConfigured = useCallback(
    ({
      options,
      onSelect,
    }: {
      options?: SearchableSelectOptionType[];
      onSelect: (selected: SearchableSelectOptionType) => void;
    }) => {
      return (
        <SearchableSelect
          options={options}
          onSelect={onSelect}
          isOpen
          onChangeSearch={(s) => setSearchQuery(s)}
          regularOptionClassName="px-4 text-text-01"
          selectType={allowAdditions ? 'search-query' : 'read-only'}
          placeholder={i18n.placeholder}
        />
      );
    },
    [allowAdditions, i18n.placeholder]
  );

  const beforeClose = useCallback(() => {
    setIsSelectingValue(false);
    setSearchQuery('');
  }, []);

  const onSelect = useCallback(
    (selected: SearchableSelectOptionType) => {
      if (!value.find((v) => v.label.toLocaleLowerCase() === selected.label.toLocaleLowerCase())) {
        onChange([selected, ...value]);
      }
      beforeClose();
    },
    [onChange, value, beforeClose]
  );

  const selectOptions =
    (autoCompleteData?.options ?? []).length > 0
      ? autoCompleteData?.options
      : autoCompleteData?.defaultOptions;

  return (
    <>
      {isSelectingValue && !isTabletOrLarger && (
        <MobileFullScreen
          center={[
            <FontBody
              key={1}
              type="emphasis"
            >
              {i18n.selectLabel}
            </FontBody>,
          ]}
          right={[
            <button
              key={1}
              onClick={beforeClose}
            >
              <CrossIcon className="text-text-01 h-6 w-6" />
            </button>,
          ]}
        >
          <SearchableListConfigured
            options={selectOptions}
            onSelect={onSelect}
          />
        </MobileFullScreen>
      )}
      <div>
        <Label
          required={false}
          label={i18n.fieldLabel}
          labelClassName="mb-4"
        />
        <ul className="flex flex-wrap items-center gap-2 break-all">
          <FloatingBox
            onClose={beforeClose}
            placement={'bottom-start'}
            wrappedComponent={
              <li>
                <button
                  type="button"
                  onClick={() => setIsSelectingValue(true)}
                  className="h-4"
                >
                  <Badge
                    color={'outline-grey'}
                    size="lg"
                    className="flex items-center"
                    isActive={isSelectingValue}
                  >
                    <PlusIcon className="h-4 w-4" />
                    <FontSmall type="default">{i18n.addLabel}</FontSmall>
                  </Badge>
                </button>
              </li>
            }
            isOpen={isSelectingValue && isTabletOrLarger}
          >
            <div className="w-[300px] p-2">
              <SearchableListConfigured
                options={selectOptions}
                onSelect={onSelect}
              />
            </div>
          </FloatingBox>
          {value.map((s) => (
            <li
              key={s.id}
              className="overflow-hidden"
            >
              <BadgeSkill
                skill={s.label}
                onDelete={() => onChange(value.filter((v) => v !== s))}
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
