import { SpacedRepetitionOverview } from '@stellar-lms-frontend/lms-api';
import {
  Badge,
  CountBox,
  Heading3,
  IconItem,
  NewReleaseIcon,
  RepeatIcon,
  TrophyIcon,
} from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';

type BadgeWrapperProps = {
  text: string;
};
const BadgeWrapper: React.FC<BadgeWrapperProps> = ({ text }) => (
  <Badge
    size="lg"
    color="grey"
  >
    <p className="text-text-01 text-base">{text}</p>
  </Badge>
);

export type Stats = {
  remaining: number;
  correct: number;
  wrong: number;
};

export type SidebarProps = {
  stats: Stats;
  overview: SpacedRepetitionOverview;
};

export const SRQStats: React.FC<SidebarProps> = ({ stats, overview }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'spaced-repetition.sidebar' });

  const getBucketSize = (key: number): string => {
    return overview.bucketsOverview?.[key].toString() || '0';
  };

  return (
    <div className="m-auto w-full lg:max-w-[400px]">
      <Heading3
        data-cy="srq-stats-today-title"
        className="text-text-04"
      >
        {t('today-questions')}
      </Heading3>
      <div className="mt-6 flex gap-4">
        <CountBox
          className="grow"
          data-cy={'srq-stats-correct'}
          text={t('correct')}
          count={stats.correct}
          color="text-positive-02"
        />
        <CountBox
          className="grow"
          data-cy={'srq-stats-wrong'}
          text={t('wrong')}
          count={stats.wrong}
          color="text-negative-01"
        />
      </div>

      <div className="mt-16">
        <Heading3
          data-cy="srq-stats-all-title"
          className="text-text-04"
        >
          {t('all-questions')}
        </Heading3>
        <div className="text-text-01 mt-8">
          <IconItem
            data-cy={'srq-stats-all-level-1'}
            icon={<BadgeWrapper text={getBucketSize(1)} />}
            title={<p className="text-xl">{t('level-1')}</p>}
            left={<NewReleaseIcon className="text-primary-01" />}
          />
          <IconItem
            data-cy={'srq-stats-all-level-2'}
            icon={<BadgeWrapper text={getBucketSize(2)} />}
            title={<p className="text-xl">{t('level-2')}</p>}
            left={<RepeatIcon className="text-primary-01" />}
          />
          <IconItem
            data-cy={'srq-stats-all-level-3'}
            icon={<BadgeWrapper text={getBucketSize(3)} />}
            title={<p className="text-xl">{t('level-3')}</p>}
            left={<RepeatIcon className="text-primary-01" />}
          />
          <IconItem
            data-cy={'srq-stats-all-level-4'}
            icon={<BadgeWrapper text={getBucketSize(4)} />}
            title={<p className="text-xl">{t('level-4')}</p>}
            left={<RepeatIcon className="text-primary-01" />}
          />
          <IconItem
            data-cy={'srq-stats-all-level-5'}
            icon={<BadgeWrapper text={getBucketSize(5)} />}
            title={<p className="text-xl">{t('level-5')}</p>}
            left={<NewReleaseIcon className="text-primary-01" />}
          />
          <IconItem
            data-cy={'srq-stats-all-level-6'}
            icon={<BadgeWrapper text={getBucketSize(6)} />}
            title={<p className="text-xl">{t('level-6')}</p>}
            left={<TrophyIcon className="text-primary-01" />}
          />
        </div>
      </div>
    </div>
  );
};
