import { LogoLoader } from '@stellar-lms-frontend/ui-components';
import { useState } from 'react';

export type IFrameProps = {
  title: string;
  url: string;
  className?: string;
};

export const IFrame: React.FC<IFrameProps> = ({ url, title, className = '' }) => {
  const pattern = /^(http:\/\/|https:\/\/)/;
  const [isLoading, setIsLoading] = useState(true);
  const finalUrl = pattern.test(url) ? url : `https://${url}`;

  return (
    <div className={className}>
      {isLoading && <LogoLoader />}
      <iframe
        title={title}
        onLoad={() => setIsLoading(false)}
        src={finalUrl}
        className={`
        ${isLoading ? 'opacity-0' : 'opacity-100'}
         h-full w-full transition-opacity duration-300 ease-in`}
      />
    </div>
  );
};
