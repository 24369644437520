import { faArrowTurnDownRight, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Sidebar,
  ChevronLeftIcon,
  ChevronRightIcon,
  HintDescription,
  LinkButton,
} from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';

export type ResendMemberInviteSidebarProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onNavigateLeft: VoidFunction;
  onNavigateRight: VoidFunction;
  onResendInvite: VoidFunction;
  email: string;
};

export const ResendMemberInviteSidebar: React.FC<ResendMemberInviteSidebarProps> = ({
  isOpen,
  onClose,
  onNavigateLeft,
  onNavigateRight,
  onResendInvite,
  email,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organization.members-tab.options-menu.resend-member-invite-sidebar',
  });

  return (
    <Sidebar
      isOpen={isOpen}
      position={'right'}
      onClose={onClose}
      contentClassName="md:max-w-[600px] md:w-[60%] font-lexend"
      hasBackdrop={false}
      header={
        <div className="flex items-center pr-4">
          <div className="flex shrink-0 gap-2">
            <button onClick={onNavigateLeft}>
              <ChevronLeftIcon className="text-text-02 h-8 w-8" />
            </button>
            <button onClick={onNavigateRight}>
              <ChevronRightIcon className="text-text-02 h-8 w-8" />
            </button>
          </div>
        </div>
      }
    >
      <div className="space-y-8">
        <h2 className="text-text-04 type-headline-2 min-h-[36px] break-words">{email}</h2>
        <HintDescription
          theme="warning"
          icon={
            <FontAwesomeIcon
              className="h-6 w-6"
              icon={faTriangleExclamation}
            />
          }
          title={<p className="type-large-emphasis">{t('pending-sign-in')}</p>}
          description={
            <div className="space-y-1">
              <p className="text-text-02 type-small-medium">{t('message', { email })}</p>
              <div className="flex gap-1">
                <LinkButton
                  buttonStyle="small"
                  label={t('resend-invite')}
                  leftIcon={<FontAwesomeIcon icon={faArrowTurnDownRight} />}
                  onClick={() => {
                    onResendInvite();
                    onClose();
                  }}
                />
              </div>
            </div>
          }
        />
      </div>
    </Sidebar>
  );
};
