import {
  CommentLikeNotificationIcon,
  CourseRegistrationNotificationIcon,
  Moment,
  NotificationIcon,
  SparklesNotificationIcon,
  UserNotificationIcon,
} from '@stellar-lms-frontend/ui-components';
import { notificationTypesWithCourseName } from './constants';
import { Notification } from './types';
import { NotificationType } from '@stellar-lms-frontend/lms-graphql';

interface NotificationItemProps {
  notification: Notification;
  onClick: (notification: Notification) => void;
}

export const NotificationItem = ({ notification, onClick }: NotificationItemProps) => {
  const courseName = notification.properties?.get('courseName');
  const renderCourse =
    notificationTypesWithCourseName.includes(notification.type) && courseName !== undefined;

  const notificationIconRender = () => {
    const iconColor = notification.read ? 'fill-text-03' : 'fill-primary-01';
    switch (notification.type) {
      case NotificationType.ForumComment:
      case NotificationType.ForumCommentReply:
      case NotificationType.WbaComment:
      case NotificationType.WbaCommentReply:
        return <UserNotificationIcon className={`m-1 h-6 w-6 space-y-1 ${iconColor} shrink-0`} />;
      case NotificationType.ForumCommentLike:
      case NotificationType.WbaCommentLike:
        return (
          <CommentLikeNotificationIcon className={`m-1 h-6 w-6 space-y-1 ${iconColor} shrink-0`} />
        );
      case NotificationType.CourseEnrollment:
        return (
          <CourseRegistrationNotificationIcon
            className={`m-1 h-6 w-6 space-y-1 ${iconColor} shrink-0`}
          />
        );
      case NotificationType.BehaviourGenerated:
        return (
          <SparklesNotificationIcon className={`m-1 h-6 w-6 space-y-1 ${iconColor} shrink-0`} />
        );
      default:
        return <NotificationIcon className={`m-1 h-6 w-6 ${iconColor} shrink-0`} />;
    }
  };

  return (
    <div
      data-testid="notification"
      className="flex cursor-pointer flex-row items-center space-x-6"
      onClick={() => onClick(notification)}
    >
      {notificationIconRender()}
      <div className="border-border-01 flex w-full flex-col space-y-1 border-b-[1px] py-2">
        <span
          data-testid="notification-message"
          className={`font-lexend text-base font-medium ${
            notification.read ? 'text-text-03' : 'text-text-04'
          }`}
        >
          {notification.message}
        </span>
        <span
          data-testid="notification-time"
          className={`font-lexend font-regular text-sm ${
            notification.read ? 'text-text-03' : 'text-text-02'
          }`}
        >
          <Moment date={notification.date} />
          {renderCourse && <span> — {courseName}</span>}
        </span>
      </div>
    </div>
  );
};

export default NotificationItem;
