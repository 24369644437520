import { truncate } from '@stellar-lms-frontend/common-utils';
import { useCallback } from 'react';
import { QuestionType, SurveyResult } from '@stellar-lms-frontend/lms-api';

type QuestionItemI18N = {
  question: {
    type: (type: QuestionType) => string;
  };
};

type QuestionItemProps = {
  active: boolean;
  onClick: (id: string) => void;
  question: SurveyResult;
  answerText: string;
  i18n: QuestionItemI18N;
};

export const QuestionItem: React.FC<QuestionItemProps> = ({
  active,
  onClick,
  question,
  answerText,
  i18n,
}) => {
  const handleClick = useCallback(() => {
    onClick(question.questionId);
  }, [onClick, question.questionId]);

  return (
    <div
      className={`border-b-1 font-lexend cursor-pointer py-4 px-6 ${
        active
          ? 'text-primary-01 bg-blue-selected-gradient border-r-primary-01 border-r-2 px-6 pr-6'
          : 'text-text-01 pr-[26px]'
      }`}
      onClick={handleClick}
    >
      <p>{truncate(question.questionTitle, 100)}</p>
      <p className="text-text-03 mt-1 flex items-center space-x-2 text-sm">
        {answerText} &#183; {i18n.question.type(question.questionType)}
      </p>
    </div>
  );
};
