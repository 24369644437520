import { useState } from 'react';
import useEventListener from './use-event-listener';

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  const media = window.matchMedia(query);
  if (media.matches !== matches) {
    setMatches(media.matches);
  }
  const listener = () => setMatches(media.matches);
  useEventListener('resize', listener, true);

  return matches;
};
