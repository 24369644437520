import { HeaderGroup, flexRender } from '@tanstack/react-table';
import { UpArrowIcon, DownArrowIcon } from '../../../icons';
import { DefaultRowDataType } from './table-rows';
import { DataCy } from '@stellar-lms-frontend/common-utils';

type TableHeaderProps<T = DefaultRowDataType> = DataCy & {
  headerGroups: HeaderGroup<T>[];
};

export const TableHeader = <T extends DefaultRowDataType = DefaultRowDataType>({
  headerGroups,
  'data-cy': dataCy,
}: TableHeaderProps<T>) => (
  <div className="w-full">
    {headerGroups.map((headerGroup) => (
      <div
        role="row"
        key={headerGroup.id}
        className="text-text-02 relative flex w-full flex-row items-center text-sm font-medium"
      >
        <div
          aria-hidden
          className="w-4 shrink-0"
        />

        <div className="flex grow flex-row items-center">
          {headerGroup.headers.map((header) => {
            const contentAlignment =
              header.column.columnDef.meta?.alignment === 'center'
                ? 'flex justify-center items-center'
                : 'px-6 ';
            return (
              <div
                key={header.id}
                role="rowheader"
                style={{
                  maxWidth: header.column.columnDef.maxSize,
                  minWidth: header.column.columnDef.minSize,
                  width: header.column.columnDef.size,
                }}
                className={`grow [&:first-child]:pl-0 [&:last-child]:pr-0 ${contentAlignment}`}
              >
                <div
                  className={`flex flex-row items-center py-3 text-left
              ${header.column.getIsSorted() ? 'text-primary-01' : ''}
              ${header.column.getCanSort() ? 'cursor-pointer select-none' : ''}`}
                  onClick={(event) => {
                    header.column.getToggleSortingHandler()?.(event);
                  }}
                  data-cy={`${dataCy}-header-${header.id}`}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                  {header.column.getIsSorted() === 'asc' && (
                    <UpArrowIcon className="fill-primary-01 ml-1 h-3 w-3 stroke-2" />
                  )}
                  {header.column.getIsSorted() === 'desc' && (
                    <DownArrowIcon className="fill-primary-01 ml-1 h-3 w-3 stroke-2" />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div
          aria-hidden
          className="w-4 shrink-0"
        />
      </div>
    ))}
  </div>
);
