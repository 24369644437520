import { Bar, XAxis, YAxis, BarChart as RechartsBarChart, LabelList } from 'recharts';
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '../shadcn';
import { LoaderWrapper } from './loader-wrapper';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';

type RowType<ColumnTypes extends string> = {
  [key in ColumnTypes]: number;
} & {
  category: string;
};

const percentageFormatter = (value: ValueType): string => {
  if (Array.isArray(value)) {
    return value.map(percentageFormatter).join(', ');
  } else {
    const numberValue = +value;
    return numberValue > 0 ? `${(numberValue * 100).toFixed(0)}%` : '';
  }
};
export const BarChart = <ColumnType extends string>({
  isLoading,
  rows,
  dataColumns,
  chartConfig,
  stacked,
  showPercentages = false,
  showValueInBar = false,
  showXaxis = true,
  showYaxis = true,
  showTooltipIndicator = true,
  showTooltipLabel = true,
  showTooltip = true,
}: {
  chartConfig: ChartConfig;
  isLoading: boolean;
  stacked: boolean;
  showPercentages?: boolean;
  showValueInBar?: boolean;
  showXaxis?: boolean;
  showYaxis?: boolean;
  showTooltipIndicator?: boolean;
  showTooltipLabel?: boolean;
  showTooltip?: boolean;
  rows: RowType<ColumnType>[] | undefined;
  dataColumns: ColumnType[];
}) => (
  <LoaderWrapper
    isLoading={isLoading}
    hasValue={rows !== undefined && rows.length > 0}
  >
    <ChartContainer
      className="min-h-[100%] "
      style={{ aspectRatio: 'unset !important' }}
      config={chartConfig}
    >
      <RechartsBarChart
        accessibilityLayer
        data={rows}
        height={200}
        margin={{
          top: 50,
          bottom: 50,
          right: 50,
        }}
      >
        {showXaxis && (
          <XAxis
            dataKey="category"
            tickLine={false}
            axisLine={false}
            tickMargin={8}
            tickCount={rows?.length ?? 0}
          />
        )}
        {showYaxis && (
          <YAxis
            allowDecimals={false}
            domain={[0, 'dataMax']}
            tickFormatter={(props) => (showPercentages ? percentageFormatter(props) : props)}
          />
        )}
        {showTooltip && (
          <ChartTooltip
            cursor={false}
            content={
              <ChartTooltipContent
                indicator={'line'}
                hideIndicator={!showTooltipIndicator}
                hideLabel={!showTooltipLabel}
                formatter={
                  showPercentages
                    ? (value, payload) => `progress: ${percentageFormatter(value)}`
                    : undefined
                }
              />
            }
          />
        )}

        {dataColumns.map((column) => {
          return (
            <Bar
              key={column}
              dataKey={column}
              stackId={stacked ? 'a' : undefined}
              type="linear"
              stroke={`var(--color-${column})`}
              fill={`var(--color-${column})`}
              strokeWidth={1}
              radius={3}
            >
              {showValueInBar && (
                <LabelList
                  dataKey={column}
                  formatter={showPercentages ? percentageFormatter : undefined}
                  position="top"
                  offset={8}
                  className="text-on-primary-01"
                  fontSize={12}
                />
              )}
            </Bar>
          );
        })}
      </RechartsBarChart>
    </ChartContainer>
  </LoaderWrapper>
);
