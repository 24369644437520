import { useDroppable } from '@dnd-kit/core';

export const DROP_CONTAINER_PREFIX = 'drop-container-';

export type DropContainerProps = {
  containerId: string;
  children: React.ReactNode;
};
export const DropContainer: React.FC<DropContainerProps> = ({ children, containerId }) => {
  const { setNodeRef: setDropContainerNodeRef } = useDroppable({
    id: `${DROP_CONTAINER_PREFIX}${containerId}`,
    data: { sortable: { containerId: containerId } },
  });

  return (
    <div
      ref={setDropContainerNodeRef}
      className="w-full"
    >
      {children}
    </div>
  );
};
