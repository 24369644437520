import { ReactNode } from 'react';

export type DashboardLayoutProps = {
  left: ReactNode;
  center: ReactNode;
  right: ReactNode;
};

export const DashboardLayout: React.FC<DashboardLayoutProps> = ({ left, center, right }) => {
  return (
    <div className="font-lexend grid w-full grid-cols-12 gap-10">
      <div className="col-span-12 space-y-10 lg:col-span-3">{left}</div>
      <div className="col-span-12 space-y-10 lg:col-span-6">{center}</div>
      <div className="col-span-12 space-y-10 lg:col-span-3">{right}</div>
    </div>
  );
};
