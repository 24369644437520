import { Badge } from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';

export type AccessType = 'course_creator' | 'user' | 'admin';

export type AccessCellProps = {
  access: AccessType;
};

export const AccessCell: React.FC<AccessCellProps> = ({ access }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organization.members-tab.access-cell',
  });

  let label = t('user');
  switch (access) {
    case 'course_creator':
      label = t('course-creator');
      break;
    case 'user':
      label = t('user');
      break;
    case 'admin':
      label = t('admin');
      break;
  }

  return (
    <Badge
      color="grey"
      className="flex w-fit items-center gap-1"
    >
      <span className="text-xs">{label}</span>
    </Badge>
  );
};
