import { Types, Language } from '@stellar-lms-frontend/lms-graphql';

export const courseData: Types.CourseDashboardListItem[] = [
  {
    __type: 'DesignerCourse' as const,
    id: '4c397d6f-32d4-4603-83f6-1811a8220284',
    title: 'Artificial Intelligence',
    createdAt: new Date(),
    updatedAt: new Date(),
    language: Language.En,
    stats: {
      numberOfActiveStudents: 0,
      numberOfStudents: 0,
      averageTimeSpentInSeconds: 0,
      averageCompletionRate: 0,
    },
  },
  {
    __type: 'DesignerCourse' as const,
    id: '088fabad-65fe-4e19-af6a-79454c5d593f',
    title: 'React',
    createdAt: new Date(),
    updatedAt: new Date(),
    language: Language.En,
    stats: {
      numberOfActiveStudents: 0,
      numberOfStudents: 1,
      averageTimeSpentInSeconds: 0,
      averageCompletionRate: 0,
    },
  },
  {
    __type: 'DesignerCourse' as const,
    id: 'd2967635-f7bc-4be8-b4d1-5d8749c66971',
    title: 'Flutter',
    createdAt: new Date(),
    updatedAt: new Date(),
    language: Language.En,
    stats: {
      numberOfActiveStudents: 0,
      numberOfStudents: 1,
      averageTimeSpentInSeconds: 0,
      averageCompletionRate: 0,
    },
  },
  {
    __type: 'DesignerCourse' as const,
    id: 'c978b344-6e7e-4f26-804c-ba44bf821a60',
    title: 'Vue',
    createdAt: new Date(),
    updatedAt: new Date(),
    language: Language.En,
    stats: {
      numberOfActiveStudents: 0,
      numberOfStudents: 0,
      averageTimeSpentInSeconds: 0,
      averageCompletionRate: 0,
    },
  },
  {
    __type: 'DesignerCourse' as const,
    id: '95fefcf3-f176-45c9-999f-5be1b404fa09',
    title: 'Svelte',
    createdAt: new Date(),
    updatedAt: new Date(),
    language: Language.En,
    stats: {
      numberOfActiveStudents: 0,
      numberOfStudents: 0,
      averageTimeSpentInSeconds: 0,
      averageCompletionRate: 0,
    },
  },
];
