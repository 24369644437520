import {
  LearningActivity,
  typemapToSurveyQuestionUserAnswers,
} from '@stellar-lms-frontend/lms-components';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import {
  evaluateAssessmentQuestion,
  fetchBlockContentStep,
  fetchScenarioStep,
  getAssessmentById,
  getDiscussionItems,
  getLastAssessmentSubmission,
  getLastSurveySubmission,
  getSurveyById,
  submitAnswers,
  submitPartialAssessment,
  trackCourseEvent,
} from './learner-learning-activity-detail-page.api';
import { SurveyAnswerMap } from '@stellar-lms-frontend/lms-api';
import {
  createDiscussionItem,
  getCourseOutline,
  LearnerWorkBasedActionSubmitInput,
  likeDiscussionItem,
  unlikeDiscussionItem,
  useCurrentCompany,
  useCurrentCourse,
  getLightCourseInfo,
} from '@stellar-lms-frontend/lms-graphql';
import { graphQLClient } from '../../lib/graphql';
import { TypeMap } from '../../types';
import {
  getOrCreateLearnerWorkBasedAction,
  submitLearnerWorkBasedAction,
} from '../designer/work-based-action/work-based-action.api';
import { getLearningActivityStepById } from './learning-activity.api';
import { unimplementedActionError } from '@stellar-lms-frontend/common-utils';
import { useParams } from 'react-router-dom';

const getDummyAssessmentSubmission = (assessmentId: string, answers: TypeMap<SurveyAnswerMap>) => {
  return {
    assessment: { id: assessmentId, title: '', href: '' },
    answers,
  };
};
export const MentorLearningActivityDetailPage = () => {
  const { t } = useTranslation('translation');
  const { t: tError403 } = useTranslation('translation', { keyPrefix: '403' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const { courseId } = useParams();

  const {
    query: { data: currentCourse },
  } = useCurrentCourse(
    (courseId: string) => getLightCourseInfo(graphQLClient(), { courseId }),
    courseId
  );

  const {
    query: { data: company },
  } = useCurrentCompany(graphQLClient());

  const actions: LearningActivity.Actions = useMemo(() => {
    const graphqlClient = graphQLClient('mentor');

    return {
      upload: {
        getUploadInformation: unimplementedActionError,
        uploadFile: unimplementedActionError,
        getFileUrl: unimplementedActionError,
      },
      edit: {
        markTipViewed: unimplementedActionError,
        onLeave: unimplementedActionError,
        onNavigateModule: unimplementedActionError,
      },
      course: {
        getCourseOutline: (input: { courseId: string }) => getCourseOutline(graphqlClient, input),
        fetchRelatedStepSuggestions: unimplementedActionError,
        startRelatedStepSuggestionGeneration: unimplementedActionError,
        startRelatedContentStepGeneration: unimplementedActionError,
      },
      step: {
        getLearningActivityStep: (learningActivityId: string, stepId: string) =>
          getLearningActivityStepById(graphQLClient('mentor'), learningActivityId, stepId),
      },
      tracking: {
        saveCourseEvent: (data) => trackCourseEvent(graphQLClient('mentor'), data),
      },
      publishedSurvey: {
        getLastSurveySubmission: (learningActivityId: string) =>
          getLastSurveySubmission(graphQLClient('mentor'), learningActivityId),
        getSurveyById: (sourceId: string, stepId: string) =>
          getSurveyById(graphQLClient('mentor'), sourceId, stepId),
        saveAnswers: (
          learningActivityStepId: string,
          publishedSurveyId: string,
          answers: TypeMap<SurveyAnswerMap>
        ) =>
          submitAnswers(
            graphQLClient('mentor'),
            learningActivityStepId,
            publishedSurveyId,
            typemapToSurveyQuestionUserAnswers(answers)
          ).then(),
      },
      publishedAssessment: {
        evaluate: (
          learningActivityStepId: string,
          assessmentId: string,
          questionId: string,
          answer: TypeMap<SurveyAnswerMap>
        ) =>
          evaluateAssessmentQuestion(
            graphQLClient('mentor'),
            learningActivityStepId,
            questionId,
            assessmentId,
            getDummyAssessmentSubmission(assessmentId, answer)
          ),
        getAssessmentById: (assessmentId: string, learningActivityId: string) =>
          getAssessmentById(graphQLClient('mentor'), assessmentId, learningActivityId),
        getLastAssessmentSubmission: (learningActivityStepId: string, assessmentId: string) =>
          getLastAssessmentSubmission(graphQLClient('mentor'), {
            learningActivityStepId,
            assessmentId,
          }),
        submitPartialAnswers: (
          learningActivityStepId: string,
          answers: TypeMap<SurveyAnswerMap>,
          assessmentId: string
        ) =>
          submitPartialAssessment(
            graphQLClient('mentor'),
            learningActivityStepId,
            getDummyAssessmentSubmission(assessmentId, answers)
          ).then(),
      },
      scenario: {
        fetch: fetchScenarioStep,
      },
      publishedScenario: {
        evaluate: (
          learningActivityStepId: string,
          scenarioId: string,
          levelId: string,
          answer: TypeMap<SurveyAnswerMap>
        ) =>
          evaluateAssessmentQuestion(
            graphQLClient('mentor'),
            learningActivityStepId,
            levelId,
            scenarioId,
            getDummyAssessmentSubmission(scenarioId, answer)
          ),
        getScenarioById: (scenarioId: string, learningActivityId: string) =>
          getAssessmentById(graphQLClient('mentor'), scenarioId, learningActivityId),
        getLastScenarioSubmission: (learningActivityStepId: string, scenarioId: string) =>
          getLastAssessmentSubmission(graphQLClient('mentor'), {
            learningActivityStepId,
            assessmentId: scenarioId,
          }),
        submitPartialAnswers: (
          learningActivityStepId: string,
          answers: TypeMap<SurveyAnswerMap>,
          scenarioId: string
        ) =>
          submitPartialAssessment(
            graphQLClient('mentor'),
            learningActivityStepId,
            getDummyAssessmentSubmission(scenarioId, answers)
          ).then(),
      },
      learnerWba: {
        fetchLearnerWorkBasedActionById: (sourceId: string, stepId: string) =>
          getOrCreateLearnerWorkBasedAction(graphQLClient('mentor'), sourceId, stepId),
        submitLearnerWorkBasedAction: (id: string, input: LearnerWorkBasedActionSubmitInput) =>
          submitLearnerWorkBasedAction(graphQLClient('mentor'), id, input),
      },
      discussion: {
        createComment: (learningActivityId: string, stepId: string, comment: string) =>
          createDiscussionItem(graphQLClient('mentor'), learningActivityId, stepId, comment),
        getDiscussionItems: (learningActivityId: string, stepId: string, offset: number) =>
          getDiscussionItems(graphQLClient('mentor'), learningActivityId, stepId, offset),
        like: (learningActivityId: string, stepId: string, commentId: string) =>
          likeDiscussionItem(graphQLClient('mentor'), learningActivityId, stepId, commentId).then(),
        unlike: (learningActivityId: string, stepId: string, commentId: string) =>
          unlikeDiscussionItem(
            graphQLClient('mentor'),
            learningActivityId,
            stepId,
            commentId
          ).then(),
        reply: (learningActivityId: string, stepId: string, reply: string, commentId: string) =>
          createDiscussionItem(
            graphQLClient('mentor'),
            learningActivityId,
            stepId,
            reply,
            commentId
          ).then(),
      },
      block: {
        fetch: fetchBlockContentStep,
        createItem: unimplementedActionError,
        updateItem: unimplementedActionError,
        deleteItem: unimplementedActionError,
        createStep: unimplementedActionError,
        updateStep: unimplementedActionError,
        deleteStep: unimplementedActionError,
        analyseArticleUrl: unimplementedActionError,
        searchStepVideos: unimplementedActionError,
        startVideoSearchTermGeneration: unimplementedActionError,
        getSuggestedVideoSearchTerm: unimplementedActionError,
        searchStepArticles: unimplementedActionError,
        startArticleSearchTermGeneration: unimplementedActionError,
        getSuggestedArticleSearchTerm: unimplementedActionError,
      },
      openSupport: undefined,
    };
  }, []);

  const props: LearningActivity.LearningActivityOverviewProps = {
    i18n: {
      error403ButtonCta: tError403('button-cta'),
      error403Description: tError403('description'),
      error403Title: tError403('title'),
      laStepOverview: {
        nextLearningActivityButton: {
          next: t('learning-activity-overview.next-activity'),
        },
        duration: {
          timeHourShort: tGeneral('time-hour-short'),
          timeMinuteShort: tGeneral('time-minute-short'),
          hour: (count) => tGeneral('hour', { count }),
          min: (count) => tGeneral('min', { count }),
        },
      },
    },
    discussionAllowed: false,
    companyId: company ? company.id : '',
    documentsEnabled: company?.documentConfiguration?.enabled ?? false,
    actions,
    currentCourse,
  };

  return <LearningActivity.LearningActivityRoutes {...props} />;
};
