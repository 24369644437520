import {
  Divider,
  Heading1,
  Heading4,
  HintDescription,
  SecondaryButton,
  UserIcon,
} from '@stellar-lms-frontend/ui-components';
import {
  createCustomerPortalSession,
  transferToInputLanguage,
  useCurrentCompany,
  useCurrentUser,
} from '@stellar-lms-frontend/lms-graphql';
import { defaultGraphqlClient, formatDateLong } from '@stellar-lms-frontend/common-utils';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const CurrentIndividualPlanCard = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.individual-plan-card' });

  const {
    query: { data: currentCompany },
  } = useCurrentCompany(defaultGraphqlClient);

  const {
    query: { data: currentUser },
  } = useCurrentUser(defaultGraphqlClient);

  const startPortalSession = useMutation({
    mutationFn: (companyId: string) =>
      createCustomerPortalSession(
        companyId,
        transferToInputLanguage(currentUser?.language ?? undefined)
      ).then((res) => res.createStripeCustomerPortalSession),
  });

  useEffect(() => {
    if (currentCompany) {
      startPortalSession.mutate(currentCompany.id);
    }

    // We don't want to re-run this effect if the mutation changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany?.id]);

  const isPlatformAdmin = currentUser?.company.id !== currentCompany?.id;
  const subscription = currentCompany?.subscription;
  const billing = subscription?.billing;

  return isPlatformAdmin ? (
    <HintDescription
      theme={'informative'}
      hasCloseButton={false}
      title={t('title')}
    />
  ) : (
    <div className="bg-surface-01 space-y-6 rounded-xl px-6 py-8 shadow-md">
      <div className="space-y-2">
        <Heading1 className="gradient-text">{t('title')}</Heading1>
        <Heading4 className="text-primary-01">{t('subTitle', { cost: '€50' })}</Heading4>
      </div>
      <Divider />
      {billing && (
        <div>
          <div className="font-lexend type-small-medium text-text-02 pb-2">
            {t('current-price-header')}
          </div>
          <div className="flex gap-4">
            <Heading4 className="text-text-01 font-lexend inline">
              €{billing.totalCost / 100} {t('per-month')}
            </Heading4>
            <div className="flex items-center gap-2">
              <UserIcon className={'h-4 w-4'} />
              <span className="type-medium font-lexend text-text-02">
                x{billing.memberCount} {t('members')}
              </span>
            </div>
          </div>
          <div className="font-lexend type-small-medium text-text-02 pt-4">
            {subscription.endDate
              ? t('plan-ending-soon', { date: formatDateLong(subscription.endDate, 'en') })
              : billing.renewalDate
              ? t('plan-renewal-date', {
                  date: formatDateLong(billing.renewalDate, currentUser?.language ?? 'en'),
                })
              : ''}
          </div>
        </div>
      )}

      <SecondaryButton
        label={t('manage-subscription')}
        onClick={() => window.open(startPortalSession.data?.portalUrl, '_blank')}
      />
    </div>
  );
};
