import { EmptyState } from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';

import questionMark from '../../assets/images/question-mark-404.png';
import { REDIRECT_HOME_URI } from '../../constants/routes';
import { ErrorWrapper, ErrorWrapperType } from './error-wrapper';

export type Error404Props = {
  type?: ErrorWrapperType;
};

export const Error404 = ({ type = 'paged' }: Error404Props) => {
  const { t } = useTranslation('translation', { keyPrefix: '404' });

  return (
    <ErrorWrapper
      type={type}
      pageTitle={t('page-title')}
      pageSubTitle={t('page-subtitle')}
    >
      <EmptyState
        icon={
          <img
            className="h-[200px] w-[200px]"
            src={questionMark}
            alt="404 question mark"
          />
        }
        title={t('title')}
        description={t('description')}
        actionText={t('button-cta')}
        verticalCenter={true}
        onActionClick={() => {
          window.location.href = REDIRECT_HOME_URI;
        }}
      />
    </ErrorWrapper>
  );
};
