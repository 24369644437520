import { ReactNode } from 'react';

export const IfDefinedWrapper = <T,>({
  children,
  variable,
  wrapper,
}: {
  variable: T | undefined;
  wrapper: (children: ReactNode, variable: T) => ReactNode;
  children: ReactNode;
}) => {
  return variable && wrapper ? wrapper(children, variable) : children;
};
