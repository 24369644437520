import React, { ChangeEvent, ReactElement, useCallback, useRef, ChangeEventHandler } from 'react';

// Improvement: Infer P based on the "element" props
type DebouncedProps<P> = {
  element: React.ElementType;
  onChange?: ChangeEventHandler;
  onChangeDebounced?: ChangeEventHandler;
  debounce?: number;
} & P;

function DebouncedInner<P>(props: DebouncedProps<P>, ref: React.ForwardedRef<HTMLElement>) {
  const { element: Element, onChange, debounce = 500, onChangeDebounced, ...rest } = props;

  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const onChangeInner = useCallback(
    (e: ChangeEvent) => {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        onChangeDebounced?.(e);
      }, debounce);
    },
    [debounce, onChangeDebounced]
  );

  return (
    <Element
      {...rest}
      ref={ref}
      onChange={(e: ChangeEvent) => {
        onChangeInner(e);
        onChange?.(e);
      }}
    />
  );
}

export const Debounced = React.forwardRef(DebouncedInner) as <P>(
  props: DebouncedProps<P> & { ref?: React.ForwardedRef<HTMLElement> }
) => ReactElement;
