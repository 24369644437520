import {
  AutoResizeTextArea,
  AutoResizeTextAreaProps,
  Debounced,
  Heading4,
  Input,
  InputProps,
} from '@stellar-lms-frontend/ui-components';
import { ModuleProgressBar } from '../course/module/module-progress-bar';
import { ModuleStatistic, MutationUpdateCourseModuleArgs } from '@stellar-lms-frontend/lms-graphql';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getChangeEventValue } from '@stellar-lms-frontend/common-utils';
import { useQueryClient } from '@tanstack/react-query';
import { COURSE } from '@stellar-lms-frontend/lms-api';
import { useTranslation } from 'react-i18next';

export type ModuleHeaderActions = {
  module: {
    update?: (variables: MutationUpdateCourseModuleArgs) => void;
  };
};

export const MODULE_HEADER_INPUT_ID = (id: string) => `module-header-input-${id}`;
export const MODULE_DESCRIPTION_INPUT_ID = (id: string) => `module-description-input-${id}`;

export type ModuleHeaderProps = {
  totalLearners: number;
  module: {
    title: string;
    description?: string;
    stats?: ModuleStatistic | null;
    id: string;
  };
  courseId: string;
  isEditing?: boolean;
  setIsEditing?: (isEditing: boolean) => void;
  actions?: ModuleHeaderActions;
};

export const ModuleHeader: React.FC<ModuleHeaderProps> = ({
  totalLearners,
  module,
  isEditing,
  actions,
  courseId,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'module-overview' });
  const { t: tValidation } = useTranslation('translation', { keyPrefix: 'validation' });

  const [title, setTitle] = useState(module.title);
  const [description, setDescription] = useState(module.description);
  const queryClient = useQueryClient();

  const saveCourseModule = useCallback(
    (title: string, description?: string) => {
      actions?.module.update?.({
        input: {
          courseId,
          moduleId: module.id,
          title,
          description: description ?? '',
        },
      });
    },
    [courseId, module.id, actions?.module]
  );

  useEffect(() => {
    setTitle(module.title);
  }, [isEditing, module.title]);

  const editMode = useMemo(() => {
    return (
      <div className="space-y-2">
        <Debounced<InputProps>
          element={Input}
          htmlId={MODULE_HEADER_INPUT_ID(module.id)}
          className="font-lexend w-full text-2xl leading-snug"
          onChangeDebounced={(e) => {
            const title = getChangeEventValue(e);
            title && saveCourseModule(title, description);
          }}
          onChange={(e) => {
            queryClient.cancelQueries([COURSE, courseId]);
            setTitle(getChangeEventValue(e));
          }}
          text={title}
          placeholder={t('module-title-placeholder')}
          error={!title ? tValidation('required') : undefined}
          label={t('module-title-label')}
        />
        <Debounced<AutoResizeTextAreaProps>
          element={AutoResizeTextArea}
          onChangeDebounced={(e) => {
            const description = getChangeEventValue(e);
            description !== undefined && saveCourseModule(title, description);
          }}
          onChange={(e) => {
            queryClient.cancelQueries([COURSE, courseId]);
            setDescription(getChangeEventValue(e));
          }}
          value={description}
          placeholder={t('module-description-placeholder')}
        />
      </div>
    );
  }, [module.id, title, t, tValidation, description, saveCourseModule, queryClient, courseId]);

  const viewMode = useMemo(() => {
    return (
      <>
        <div className="flex flex-row justify-between">
          <Heading4 className="text-text-04">{module.title}</Heading4>
          {module.stats && totalLearners !== 0 && (
            <div className="flew flex-col pl-6">
              <div className="font-lexend text-primary-01 text-end text-xl font-semibold">
                {(((module.stats.learnersCompleted ?? 0) / totalLearners) * 100 || 0).toFixed(1)}%
              </div>
              <div className="font-lexend text-text-02 font-regular text-sm">
                {t('progress.completed')}
              </div>
            </div>
          )}
        </div>
        <p className="font-lexend text-text-02 type-small whitespace-pre-wrap">
          {module.description}
        </p>
        {module.stats && totalLearners !== 0 && (
          <ModuleProgressBar
            startedPercentage={((module.stats.learnersStarted ?? 0) / totalLearners) * 100 || 0}
            completedPercentage={((module.stats.learnersCompleted ?? 0) / totalLearners) * 100 || 0}
            className="pt-4"
          />
        )}
      </>
    );
  }, [module.stats, module.title, module.description, totalLearners, t]);

  return isEditing ? editMode : viewMode;
};
