import { PlusCircleIcon } from '../../icons';
import { BaseCard } from '../base-card/base-card';

import React, { forwardRef } from 'react';

type AddCardProps = {
  isEnabled: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  label: string;
  rightIcon?: React.ReactNode;
  className?: string;
};

export const AddCard = forwardRef<HTMLDivElement, AddCardProps>(
  ({ isEnabled, onClick, label, rightIcon, className = '' }, ref) => {
    return (
      <BaseCard
        ref={ref}
        className={`bg-on-primary-01 font-lexend w-full ${className}`}
        onClick={(e) => {
          isEnabled && onClick(e);
        }}
        childrenClassName={`flex ${
          isEnabled ? 'cursor-pointer' : 'cursor-default'
        } items-center px-8 py-5 w-full gap-2`}
      >
        <PlusCircleIcon className={`shrink-0 ${isEnabled ? 'text-primary-02' : 'text-text-03'}`} />
        <p className={`type-body-medium grow ${isEnabled ? 'text-primary-02' : 'text-text-03'}`}>
          {label}
        </p>
        {!!rightIcon && rightIcon}
      </BaseCard>
    );
  }
);
