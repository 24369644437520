import { VoidFunc } from '@stellar-lms-frontend/common-utils';

export const Container = ({
  children,
  onClick,
  containerClassName = '',
}: {
  children: React.ReactNode;
  onClick?: VoidFunc;
  containerClassName?: string;
}) => (
  <div
    className={`${containerClassName} flex w-full flex-col justify-between overflow-hidden rounded-xl text-left ${
      onClick ? 'cursor-pointer' : ''
    }`}
    onClick={onClick}
  >
    {children}
  </div>
);
