import { VoidFunc } from '@stellar-lms-frontend/common-utils';
import { ReactNode } from 'react';
import { PrimaryButton, SecondaryButton, TertiaryButton } from '../buttons';
import { BaseButtonProps, ButtonTypes } from '../buttons/base-button/base-button';
import { Heading4 } from '../heading';

export type EmptyStateProps = {
  title: string;
  description: string;
  icon?: ReactNode;
  verticalCenter?: boolean;
  buttonType?: ButtonTypes;
  actionText?: string;
  onActionClick?: () => void;
  className?: string;
  buttonConfig?: Partial<BaseButtonProps>;
};

export const EmptyState = ({
  icon,
  title,
  description,
  actionText,
  verticalCenter = false,
  onActionClick,
  buttonType = 'tertiary',
  className = 'max-w-[530px]',
  buttonConfig,
}: EmptyStateProps) => {
  const selectCTA = (actionText: string, onActionClick: VoidFunc) => {
    switch (buttonType) {
      case 'primary':
        return (
          <PrimaryButton
            htmlId="empty-state-cta"
            label={actionText}
            fullWidth
            onClick={onActionClick}
            {...buttonConfig}
          />
        );
      case 'secondary':
        return (
          <SecondaryButton
            htmlId="empty-state-cta"
            label={actionText}
            fullWidth
            onClick={onActionClick}
            {...buttonConfig}
          />
        );
      case 'tertiary':
        return (
          <TertiaryButton
            htmlId="empty-state-cta"
            label={actionText}
            fullWidth
            onClick={onActionClick}
            {...buttonConfig}
          />
        );
    }
  };

  return (
    <div
      className={`${className} mx-auto flex h-full w-full justify-center text-center`}
      data-cy="empty-state"
    >
      <div
        className={`flex flex-col ${
          verticalCenter ? 'justify-center' : 'justify-start'
        } items-center space-y-8`}
      >
        {icon}
        <div className="space-y-4 px-8">
          {title && <Heading4 className="text-text-04">{title}</Heading4>}
          {description && <p className="text-text-02">{description}</p>}
        </div>
        {actionText && onActionClick && (
          <div className="flex w-full shrink-0 justify-center">
            {selectCTA(actionText, onActionClick)}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmptyState;
