import { HeaderNavTheme } from '@stellar-lms-frontend/ui-components';
import { LogoImage } from './components/logo-image';
import { ShortDefaultIcon } from './components/short-default-icon';
import { DefaultIcon } from './components/default-icon';

export interface LogoProps {
  type?: 'icon' | 'short-icon';
  className?: string;
  onClick?: () => void;
  logoIcon?: string;
  logoShortIcon?: string;
  theme: HeaderNavTheme;
}

export const Logo: React.FC<LogoProps> = ({
  className = '',
  type = 'icon',
  onClick,
  theme,
  logoIcon,
  logoShortIcon,
}) => {
  return (
    <div
      className="white-label-logo"
      onClick={onClick}
    >
      {
        {
          icon: logoIcon ? (
            <LogoImage
              className={className}
              src={logoIcon}
            />
          ) : (
            <DefaultIcon
              className={className}
              theme={theme}
            />
          ),
          'short-icon': logoShortIcon ? (
            <LogoImage
              className={className}
              src={logoShortIcon}
            />
          ) : (
            <ShortDefaultIcon
              className={className}
              theme={theme}
            />
          ),
        }[type]
      }
    </div>
  );
};
