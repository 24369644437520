import {
  getGraphQLApi,
  getGraphQLClient,
  getOnsophicApi,
} from '@stellar-lms-frontend/common-utils';
import { GraphQLClient } from 'graphql-request';

let learnerGraphQLClient: GraphQLClient;
let mentorGraphQLClient: GraphQLClient;
let designerGraphQLClient: GraphQLClient;

const graphQLApiUrl = getGraphQLApi();
const apiUrl = getOnsophicApi();

export const graphQLClient = (context?: 'mentor' | 'learner' | 'designer') => {
  switch (context) {
    case 'learner':
      if (!learnerGraphQLClient) {
        learnerGraphQLClient = getGraphQLClient(graphQLApiUrl, 'learner', apiUrl);
      }
      return learnerGraphQLClient;
    case 'mentor':
      if (!mentorGraphQLClient) {
        mentorGraphQLClient = getGraphQLClient(graphQLApiUrl, 'mentor', apiUrl);
      }
      return mentorGraphQLClient;
    case 'designer':
      if (!designerGraphQLClient) {
        designerGraphQLClient = getGraphQLClient(graphQLApiUrl, 'designer', apiUrl);
      }
      return designerGraphQLClient;
    default:
      return getGraphQLClient(
        graphQLApiUrl,
        window.location.pathname.includes('mentor') ? 'mentor' : 'learner',
        apiUrl
      );
  }
};
