import { DiscussionItem } from '@stellar-lms-frontend/lms-api';
import { ScrollContainer } from '@stellar-lms-frontend/ui-components';
import { ReactNode } from 'react';

import { ItemComponent, ItemComponentProps } from './item-component';

export type ListComponentProps = {
  items: DiscussionItem[];
  endOfPage: ReactNode;
  companyId: string;
  actions: ItemComponentProps['actions'];
};

export const ListComponent: React.FC<ListComponentProps> = ({
  actions,
  companyId,
  items,
  endOfPage,
}) => {
  return (
    <ScrollContainer
      scrollOnDesktop
      addPadding={false}
    >
      <div className="mx-6 flex flex-col space-y-4 lg:mx-10">
        {items.map((item) => (
          <div key={item.id}>
            <ItemComponent
              companyId={companyId}
              key={item.id}
              actions={actions}
              item={item}
            />
          </div>
        ))}
      </div>
      {endOfPage}
    </ScrollContainer>
  );
};
