import { forwardRef } from 'react';
import BaseButton, { BaseButtonProps } from '../base-button/base-button';

export interface BlackButtonProps extends BaseButtonProps {
  active?: boolean;
}

export const BlackButton = forwardRef<HTMLButtonElement, BlackButtonProps>(
  ({ display = 'normal', active = false, className = '', ...baseProps }, ref) => {
    return (
      <BaseButton
        {...baseProps}
        display={display}
        className={`border-1 rounded-xl border-white
         text-white shadow-sm disabled:shadow-none
         ${className}
        ${
          active
            ? 'bg-primary-03'
            : `focus:outline-primary-02 disabled:border-border-03 disabled:text-text-03 hover:border-gray-200
               hover:text-gray-200 focus:border-transparent focus:text-white focus:outline focus:outline-2`
        }`}
        ref={ref}
      />
    );
  }
);

BlackButton.displayName = 'BlackButton';

export default BlackButton;
