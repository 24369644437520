import React, { useEffect, useMemo, useState } from 'react';

export interface TypewriterProps {
  children: React.ReactNode;
  typingInterval?: number;
  onFinishTyping?: () => void;
  isEnabled?: boolean;
}

const countChar = (children: React.ReactNode): number => {
  let totalChar = 0;

  React.Children.forEach(children, (child) => {
    if (typeof child === 'string') {
      totalChar += child.length;
    } else if (React.isValidElement(child)) {
      totalChar += countChar(child.props.children);
    }
  });

  return totalChar;
};

export const Typewriter: React.FC<TypewriterProps> = ({
  children,
  typingInterval = 30,
  onFinishTyping,
  isEnabled = true,
}) => {
  const [visibleChar, setVisibleChar] = useState(0);

  const totalChar = useMemo(() => countChar(children), [children]);

  useEffect(() => {
    if (visibleChar < totalChar && isEnabled) {
      const timer = setTimeout(() => {
        setVisibleChar(visibleChar + 1);
      }, typingInterval);

      return () => clearTimeout(timer);
    } else {
      setVisibleChar(totalChar);
      onFinishTyping?.();
    }

    return;
  }, [isEnabled, onFinishTyping, totalChar, typingInterval, visibleChar]);

  let nCharUntilLastWord = 0;

  // TODO: ideally we should only get the next character and not re-generate the whole string.
  const createVisibleChildren = (child: React.ReactNode): React.ReactNode => {
    if (typeof child === 'string') {
      const wordVisibleText = child.slice(0, Math.max(visibleChar - nCharUntilLastWord, 0));
      nCharUntilLastWord += child.length;
      return wordVisibleText;
    } else if (React.isValidElement(child)) {
      const newChildren = React.Children.map(child.props.children, createVisibleChildren);
      return React.cloneElement(child, child.props, newChildren);
    }

    return child;
  };

  return <>{React.Children.map(children, createVisibleChildren)}</>;
};
