import { MouseEvent } from 'react';
import { Badge, CheckIcon, PlusIcon } from '@stellar-lms-frontend/ui-components';

type TagBadgeProps = {
  tag: string;
  isAdded: boolean;
  onClick: (event: MouseEvent<HTMLElement>) => void;
};

export const TagBadge: React.FC<TagBadgeProps> = ({ tag, isAdded, onClick }) => {
  return (
    <Badge
      onClick={onClick}
      color="outline-grey"
      className="text-text-01 flex items-center space-x-1"
    >
      {isAdded ? (
        <CheckIcon className="text-text-01 h-3 w-3" />
      ) : (
        <PlusIcon className="text-text-01 h-3 w-3" />
      )}
      <span>{tag}</span>
    </Badge>
  );
};
