import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { DragIcon } from '../icons';

export type DragHandlerProps = {
  setActivatorNodeRef: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
  className?: string;
};

export const DragHandler: React.FC<DragHandlerProps> = ({
  setActivatorNodeRef,
  listeners,
  className = '',
}) => {
  return (
    <div
      ref={setActivatorNodeRef}
      {...listeners}
      className={`-m-2 cursor-grab p-2
      ${className}`}
    >
      <DragIcon className="h-4 w-4" />
    </div>
  );
};
