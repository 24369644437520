import { DragItemProps } from '../list';
import { MultipleChoiceSettingsForm } from './multiple-choice-settings-form';
import { MultipleChoiceSettingsPreview } from './multiple-choice-settings-preview';
import { SettingsCard } from '../card';

export type MultipleChoiceFormErrors = {
  text?: {
    message?: string;
  };
};

export type MultipleChoiceAnswerSetting = {
  id?: string;
  text: string;
};

export type MultipleChoiceSettings = {
  text: string;
  multiple: boolean;
  required: boolean;
  visibleToMentors: boolean;
  answers: MultipleChoiceAnswerSetting[];
};

export type MultipleChoiceSettingsCardProps = {
  value: MultipleChoiceSettings;
  onChange: (data: MultipleChoiceSettings) => void;
  dragProps?: DragItemProps;
  isOpen: boolean;
  onDelete: () => void;
  onToggleOpenState?: () => void;
  errors?: MultipleChoiceFormErrors;
  renderShowToMentorsOption: boolean;
};

export const MultipleChoiceSettingsCard: React.FC<MultipleChoiceSettingsCardProps> = ({
  value,
  isOpen,
  onChange,
  onDelete,
  onToggleOpenState,
  dragProps,
  errors,
  renderShowToMentorsOption,
}) => {
  return (
    <SettingsCard
      hasOptionsMenu
      onToggleOpenState={onToggleOpenState}
      onDelete={onDelete}
      isOpen={isOpen}
      dragProps={dragProps}
      openChildren={
        <MultipleChoiceSettingsForm
          renderShowToMentorsOption={renderShowToMentorsOption}
          value={value}
          onChange={(newValue) => onChange(newValue)}
          errors={errors}
        />
      }
      closedChildren={<MultipleChoiceSettingsPreview settings={value} />}
      hasError={!!errors}
    />
  );
};
