import { useMemo } from 'react';
import { MultiSelect, SingleSelect } from '../form';
import { FontBody } from '../text';
import { MultipleChoiceSettings } from './multiple-choice-settings-card';
import { useTranslation } from 'react-i18next';

export type MultipleChoiceSettingsPreviewProps = {
  settings: MultipleChoiceSettings;
};

// As with other preview cards we mess a bit with some properties to get a kind of working preview for designers.
// This should never be used to actually pick up answers/values
export const MultipleChoiceSettingsPreview: React.FC<MultipleChoiceSettingsPreviewProps> = ({
  settings,
}) => {
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const answers = useMemo(
    () =>
      settings.answers.map((a, index) => ({
        id: a.id ?? index.toString(), // index is normally not a good idea, but for this preview component it is fine
        label: a.text,
        shortcutKey: String.fromCharCode(65 + index),
        other: false, //TODO what to do here?
      })),
    [settings.answers]
  );

  return (
    <div className="space-y-4">
      {settings.text && (
        <FontBody
          type="emphasis"
          className="text-text-02"
        >
          {settings.text}&nbsp;
          {settings.required ? (
            <span className="text-negative-01">*</span>
          ) : (
            `(${tGeneral('optional')})`
          )}
        </FontBody>
      )}
      {settings.multiple ? (
        <MultiSelect
          isDisabled
          options={answers}
        />
      ) : (
        <SingleSelect
          isDisabled
          options={answers}
        />
      )}
    </div>
  );
};
