import { ReactNode } from 'react';
import { cn } from '@stellar-lms-frontend/common-utils';

export const ChartCard = ({
  title,
  className,
  children,
}: {
  title: string;
  children: ReactNode;
  className: string;
}) => {
  return (
    <div className={cn('ol-span-2 h-[250px] rounded-md border bg-gray-100 p-4', className)}>
      <div className="flex h-full w-full flex-col ">
        <p className="type-body-emphasis text-text-02">{title}</p>
        {children}
      </div>
    </div>
  );
};
