import { API } from '@editorjs/editorjs';
import { ArticleData } from './article-plugin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinesLeaning, faLink } from '@fortawesome/pro-light-svg-icons';
import { Dispatch, useEffect, useState } from 'react';
import { Input } from '../../../form';
import { PrimaryButton } from '../../../buttons';
import { HintDescription } from '../../../hint';
import { EditorJsArticleBlock } from '@stellar-lms-frontend/lms-graphql';
import { ArticlePreview } from '../../../article/article-preview';
import { ArticleLoading } from '../../../article/article-loading';
import { BaseCard } from '../../../card';

export type ArticleComponentProps = {
  id: string;
  state: State;
  onChange: (newData: ArticleData) => void;
  isReadOnly: boolean;
  api: API;
  analyseUrlFunc: (url: string) => Promise<EditorJsArticleBlock | undefined>;
};

type InputState = { state: 'input'; url?: string | undefined; errorMessage?: string | undefined };
type LoadingState = { state: 'loading'; url: string };
type DisplayState = { state: 'display'; data: ArticleData };

type State = InputState | LoadingState | DisplayState;

export const ArticleComponent = ({
  id,
  state,
  isReadOnly,
  api,
  onChange,
  analyseUrlFunc,
}: ArticleComponentProps) => {
  const [internalState, setInternalState] = useState<State>(state);

  useEffect(() => {
    const fetchData = async (url: string) => {
      try {
        const result = await analyseUrlFunc(url);
        if (!result) {
          setInternalState({
            state: 'input',
            url: url,
            errorMessage: api.i18n.t('error-message'),
          });
          return;
        }

        const mappedResult: ArticleData = {
          title: result.title,
          description: result.description ?? undefined,
          source: result.source,
          iconUrl: result.iconUrl ?? undefined,
          link: result.link,
        };

        setInternalState({ state: 'display', data: mappedResult });
        onChange(mappedResult);
      } catch (e) {
        setInternalState({
          state: 'input',
          url: url,
          errorMessage: api.i18n.t('error-message'),
        });
      }
    };

    if (internalState.state === 'loading') {
      fetchData(internalState.url);
    }
  }, [internalState, onChange, analyseUrlFunc, api.i18n]);

  // rendering
  switch (internalState.state) {
    case 'input':
      return isReadOnly ? null : (
        <ArticleSettings
          id={id}
          url={internalState.url}
          api={api}
          errorMessage={internalState.errorMessage}
          updateUrl={(newUrl) => setInternalState({ state: 'loading', url: newUrl })}
        />
      );
    case 'loading':
      return isReadOnly ? null : <ArticleLoading />;
    case 'display':
      return (
        <BaseCard
          className="my-2"
          childrenClassName="p-4"
          onClick={() =>
            isReadOnly
              ? window.open(internalState.data.link, '_blank')
              : setInternalState({ state: 'input', url: internalState.data.link })
          }
        >
          <ArticlePreview data={internalState.data} />
        </BaseCard>
      );
  }
};

const ArticleSettings = ({
  url,
  errorMessage,
  id,
  api,
  updateUrl,
}: {
  id: string;
  url?: string;
  api: API;
  errorMessage?: string;
  updateUrl: Dispatch<string>;
}) => {
  const [urlValue, setUrlValue] = useState(url ?? '');
  const [error, setError] = useState(errorMessage ?? undefined);

  return (
    <div className="bg-surface-01 my-2 space-y-2 rounded-lg p-2 shadow-xl">
      <div className="space-x-2">
        <FontAwesomeIcon
          icon={faLinesLeaning}
          className="text-md text-text-02"
        />
        <span className="type-body-medium text-text-02 font-lexend">
          {api.i18n.t('add-article-title')}
        </span>
      </div>
      <div className="flex items-center space-x-2">
        <Input
          leftIcon={
            <FontAwesomeIcon
              icon={faLink}
              className="text-md"
            />
          }
          htmlId={`${id}-article-url-input`}
          placeholder={api.i18n.t('input-placeholder')}
          value={urlValue}
          onChange={(event) => {
            setUrlValue(event.target.value);
            setError(undefined);
          }}
        />
        <PrimaryButton
          label={api.i18n.t('add-article-button')}
          onClick={() => urlValue?.trim() && updateUrl(urlValue)}
        />
      </div>
      {error && <div className="type-small text-warning-01 font-lexend">{error}</div>}
      <HintDescription description={api.i18n.t('add-article-hint-description')} />
    </div>
  );
};
