import { DocumentIllustration } from '../../../icons';
import { faClose } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

type FileInputInitProps = {
  fileError: string | null;
};

export const FileInputInit: React.FC<FileInputInitProps> = ({ fileError }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.file-input' });
  return (
    <div className="flex flex-col items-center justify-center space-y-2">
      <DocumentIllustration className="h-[92px] w-[92px]" />
      <span className="text-primary-02 grow">{t('label')}</span>
      {fileError && (
        <div className="mt-6 flex items-center gap-2">
          <FontAwesomeIcon
            icon={faClose}
            className="text-negative-01 h-5 w-5"
          />
          <p className="text-negative-01 type-small">{fileError}</p>
        </div>
      )}
    </div>
  );
};
