import { BlurHomePage } from '@stellar-lms-frontend/lms-components';
import { ExpiredTrialModal } from './components/expired-trial-modal';
import { defaultGraphqlClient } from '@stellar-lms-frontend/common-utils';
import { useCurrentCompany } from '@stellar-lms-frontend/lms-graphql';
import { Navigate } from 'react-router-dom';
import { Stripe } from '@stripe/stripe-js';

export const SubscriptionExpiredPage = ({
  stripePromise,
}: {
  stripePromise: Promise<Stripe | null>;
}) => {
  const {
    query: { data: company },
  } = useCurrentCompany(defaultGraphqlClient);

  const isSubscriptionActive = !company?.subscription || company.subscription.active;

  return isSubscriptionActive ? (
    <Navigate to={'/'} />
  ) : (
    <>
      <BlurHomePage />
      <ExpiredTrialModal stripePromise={stripePromise} />
    </>
  );
};
