import { useLogout } from '@stellar-lms-frontend/lms-api';
import { PrimaryButton } from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { cleanUpHubspotChat, Billing } from '@stellar-lms-frontend/lms-components';
import { apiUrl } from '../../../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { Stripe } from '@stripe/stripe-js';

export const ExpiredTrialModal = ({ stripePromise }: { stripePromise: Promise<Stripe | null> }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'expired-trial' });

  const logout = useLogout(apiUrl, cleanUpHubspotChat);

  return (
    <div className="modal-bg font-lexend md:flex md:items-center md:justify-center">
      <PrimaryButton
        theme="dark"
        leftIcon={
          <FontAwesomeIcon
            icon={faArrowRightFromBracket}
            className="text-text-01 text-xl"
          />
        }
        className="text-text-01 absolute top-6 right-8"
        label={t('logout-cta')}
        onClick={() => logout()}
      />
      <div
        className={`text-surface-01 bg-primary-01 w-[877px] overflow-y-scroll p-12 md:max-h-[90%] md:max-w-[90%] md:rounded-3xl`}
      >
        <div className="space-y-6 text-center">
          <p className="type-headline-1">{t('title')}</p>
          <p className="type-large">{t('subtitle-v2')}</p>
          <div className="flex gap-6">
            <Billing.IndividualPlanCard stripePromise={stripePromise} />
            <Billing.EnterprisePlanCard />
          </div>
        </div>
      </div>
    </div>
  );
};
