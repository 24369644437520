import { useTranslation } from 'react-i18next';
import { AIStepEdit, AIStepEditI18N, AIStepEditProps } from '../../../ai-step-edit/ai-step-edit';
import { WbaSuggestionCard } from '../wba-suggestion-card/wba-suggestion-card';

export type WBASuggestion = {
  id: string;
  title: string;
  description: string;
  learnerShortDescription: string;
  image?: string;
};

export type WbaAIStepEditI18N = AIStepEditI18N;

export type WbaAIStepEditProps = Omit<
  AIStepEditProps<WBASuggestion, WBASuggestion>,
  'renderSuggestion' | 'aiButtonLabel' | 'i18n'
>;

export const WbaAIStepEdit: React.FC<WbaAIStepEditProps> = (props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'work-based-actions' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  return (
    <AIStepEdit
      {...props}
      i18n={{
        suggestionsHint: {
          title: t('edit.suggestions-available'),
          link: (count: number) => t('edit.available-suggestions', { count }),
        },
        loading: t('edit.suggestions-loading-message'),
        sidebar: {
          title: t('edit.suggestions-title'),
          hint: t('edit.suggestions-hint'),
          load: {
            more: t('edit.load-more-suggestions'),
            message: t('edit.suggestions-loading-message'),
          },
          buttons: {
            add: tGeneral('add'),
            cancel: tGeneral('cancel'),
          },
          confirmOverwriteModal: {
            title: t('edit.overwrite-modal.title'),
            description: t('edit.overwrite-modal.description'),
            confirm: tGeneral('confirm'),
            cancel: tGeneral('cancel'),
          },
          retryMessage: {
            title: t('edit.retry-message.title'),
            description: t('edit.retry-message.description'),
            retry: tGeneral('retry'),
          },
        },
      }}
      aiButtonLabel={t('edit.floating.actions')}
      renderSuggestion={({
        suggestion: s,
        isSelected,
        setAddedSuggestions,
        setAcceptedSuggestions,
        onRemoveSuggestion,
      }) => (
        <WbaSuggestionCard
          key={s.id}
          title={s.title}
          isHighlighted={isSelected}
          description={s.description}
          image={s.image}
          // The user can select only one WBA suggestion, so it's an array with a single element
          onClick={() => {
            if (isSelected) {
              onRemoveSuggestion(s.id);
            } else {
              setAcceptedSuggestions(() => new Set([s.id]));
              setAddedSuggestions(() => [s]);
            }
          }}
        />
      )}
    />
  );
};
