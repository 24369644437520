import { TFunction } from 'i18next';
import { Language } from '@stellar-lms-frontend/lms-graphql';

// CLEANUP mix between uppercase and lowercase is confusing
export const DEFAULT_LANGUAGE = 'EN';
type SupportedLanguageType = 'de' | 'en' | 'es' | 'fr' | 'it' | 'nl' | 'pt';
type UCSupportedLanguageType = 'DE' | 'EN' | 'ES' | 'FR' | 'IT' | 'NL' | 'PT';
export const supportedLanguages: SupportedLanguageType[] = [
  'de',
  'en',
  'es',
  'fr',
  'it',
  'nl',
  'pt',
];

// CLEANUP: move this and other functions to graphql or common utils
export const transformIncomingLanguageCode = (language: Language): SupportedLanguageType => {
  switch (language) {
    case Language.De:
      return 'de';
    case Language.En:
      return 'en';
    case Language.Es:
      return 'es';
    case Language.Fr:
      return 'fr';
    case Language.It:
      return 'it';
    case Language.Nl:
      return 'nl';
    case Language.Pt:
      return 'pt';
    default:
      return 'en';
  }
};

// A hacky way of dealing with the type safety of 'allowed' string values, mostly used for translation keys that are based on the supported language
// There are better solutions, but rabbit hole
export const upperSupportedLanguage = (supportedLanguage: SupportedLanguageType) =>
  supportedLanguage.toUpperCase() as UCSupportedLanguageType;

export const translatedLanguages = (tLanguages: TFunction<'translation', 'languages'>) => {
  return supportedLanguages.map<{
    label: string;
    value: string;
  }>((l) => ({
    label: tLanguages(upperSupportedLanguage(l)),
    value: l,
  }));
};

export function findLanguageByValue(value: string | undefined): Language {
  if (value && Object.values(Language).find((v) => v.toUpperCase() === value.toUpperCase())) {
    return value.toUpperCase() as Language;
  }
  return Language.En;
}
