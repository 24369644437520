import { User } from '../types';
import { graphql, Language } from '@stellar-lms-frontend/lms-graphql';
import { GraphQLClient } from 'graphql-request';

export const getLoggedInUser = (client: GraphQLClient): Promise<User> => {
  return client
    .request(
      graphql(`
        query GetLoggedInUser {
          currentUser {
            id
            email
            language
            name
            firstName
            lastName
            thumbUri
            company {
              id
              name
            }
            role
            jobTitle
            hasCompletedOnboarding
          }
        }
      `)
    )
    .then((data) => {
      if (!data.currentUser) {
        throw new Error('Could not find logged in user');
      }

      return {
        id: data.currentUser.id,
        email: data.currentUser.email,
        firstName: data.currentUser.firstName ?? '',
        lastName: data.currentUser.lastName ?? '',
        language: data.currentUser?.language
          ? data.currentUser.language
          : Language.En.toLowerCase(),
        name: data.currentUser.name ?? '',
        thumbUri: data.currentUser.thumbUri ?? '',
        jobTitle: data.currentUser.jobTitle ?? '',
        companyReference: data.currentUser.company
          ? {
              id: data.currentUser.company.id,
              title: data.currentUser.company.name ?? '',
              href: '',
            }
          : {
              id: '',
              title: '',
              href: '',
            },
        role: data.currentUser.role,
        hasCompletedOnboarding: data.currentUser.hasCompletedOnboarding ?? false,
      };
    });
};

export const searchUsersLightById = (
  client: GraphQLClient,
  companyId: string,
  userIds: string[]
): Promise<User[]> => {
  return client
    .request(
      graphql(`
        query SearchUsersLightById($companyId: ID!, $userIds: [ID!]!) {
          usersByIdsOfCompany(companyId: $companyId, userIds: $userIds) {
            id
            email
            name
            language
            thumbUri
            role
            jobTitle
            status
          }
        }
      `),
      {
        companyId,
        userIds,
      }
    )
    .then((data) => {
      return data.usersByIdsOfCompany.map((user) => {
        return {
          id: user.id,
          name: user.name ?? '',
          thumbUri: user.thumbUri ?? '',
          language: user.language?.toLowerCase() ?? Language.En.toLowerCase(),
          role: user.role,
          jobTitle: user.jobTitle,
          email: user.email,
          firstName: '',
          lastName: '',
          companyReference: { id: '', href: '', title: '' },
          hasCompletedOnboarding: false,
        };
      });
    });
};
